<div class="drag-bar" cdkDragHandle cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <fa-icon icon="arrows-alt"></fa-icon>
    <span class="spacer"></span>
    <fa-icon class="close-button" icon="times" size="2x" matDialogClose></fa-icon>
</div>
<h1 mat-dialog-title class="dialog-header">
<!--    <h1 mat-dialog-title>-->
        {{ (eventId$ | async) ? 'Aufgabe bearbeiten' : 'Aufgabe Erstellen' }}
<!--    </h1>-->
    <div class="header-button-wrapper">
        <ng-template #smallLoading><app-loading [Size]="24"></app-loading></ng-template>
        <button *ngIf="Bookmark$ | async as bookmarkEntity; else bookmarkEntity" matTooltip="Lesezeichen entfernen" aria-label="Lesezeichen entfernen" (click)="HandleBookmark(EventId, bookmarkEntity?.Id)" class="bookmark"><fa-icon [icon]="'bookmark'"> </fa-icon></button>

        <!--Wir verwendet , wenn es eine Aufgabe gibt und kein Lesezeichen gesetzt wurde-->
        <ng-template #bookmarkEntity>
            <button *ngIf="eventId$ | async as eventId; else noTask" class="bookmark" (click)="HandleBookmark(eventId, null)" matTooltip="Lesezeichen hinzufügen" aria-label="Lesezeichen hinzufügen">
                <fa-icon [icon]="['far', 'bookmark']"> </fa-icon>
            </button>
        </ng-template>
        <!--Wir verwendet , wenn es noch keine Aufgabe gibt und diese noch angelegt werden muss-->
        <ng-template #noTask>
            <button (click)="HandleBookmark(null, null)" class="bookmark" [matTooltip]="BookmarkSet === true ? 'Lesezeichen entfernen' : 'Lesezeichen hinzufügen'" aria-label="{{BookmarkSet === true ? 'Lesezeichen entfernen' : 'Lesezeichen hinzufügen'}}">
                <fa-icon [icon]="BookmarkSet === true ? 'bookmark' : ['far', 'bookmark']"> </fa-icon>
            </button>
        </ng-template>
        <div *ngIf="Email$ | async as email" class="theme-override" [class]="EmailPageMeta.Theme">
            <button app-round-button matTooltip="Text der E-Mail anzeigen" aria-label="Text der E-Mail anzeigen" (click)="OpenMailDialog(email)">
                <fa-icon [icon]="EmailPageMeta.Icon"></fa-icon>
            </button>
        </div>
        <div *ngIf="PS.Has$(PS.Permissions.CreateLedgerImports) | async" class="theme-override {{ReportsPageMeta.Theme}}">
            <button app-round-button matTooltip="Dokument hinzufügen" (click)="onAddLedgerImportClick()"><app-double-icon [Icon]="ReportsPageMeta.Icon" Icon2="plus"></app-double-icon></button>
        </div>
        <ng-container *ngIf="PS.Has$(PS.Permissions.GetLedgerImports) | async">
            <ng-container *ngIf="assignedLedgerImports$ | async as ledgerImports; else smallLoading">
                <div class="theme-override {{ReportsPageMeta.Theme}} ledger-import-button-wrapper" *ngFor="let li of ledgerImports">
                    <a app-button class="wide-button" [matTooltip]="LedgerImportDocumentTypeNames.get(li.DocumentType) + ' bearbeiten'" [routerLink]="LedgerImportEditorRoute(li.Id)" [mat-dialog-close]=""><fa-icon [icon]="ReportsPageMeta.Icon"></fa-icon></a>
                </div>
            </ng-container>
        </ng-container>
        <ng-container >
            <div class="admin-button-wrapper link-wrapper" *ngIf="assignedCustomer$ | async as customer ">
                <a app-button Lighter class="wide-button" matTooltip="Kunde" [routerLink]="['/', CustomerPath, customer?.Id]" [mat-dialog-close]="">
                    <fa-icon [icon]="CustomerAdministrationMeta.Icon"></fa-icon>
                </a>
            </div>
            <div class="commission-button-wrapper link-wrapper" *ngIf="assignedCommission$ | async as commission">
                <a app-button Lighter class="wide-button" [matTooltip]="cls.getSingle$('Commission') | async" [routerLink]="['/', CommissionMeta.Path, commission.CustomerId ? commission.CustomerId : AllCommissionPath, commission.Id]" [mat-dialog-close]="">
                    <fa-icon [icon]="CommissionMeta.Icon"></fa-icon>
                </a>
            </div>
        </ng-container>
    </div>
</h1>
<mat-dialog-content style="max-height: unset; flex-basis: 0; flex-grow: 1;">
    <ng-container *ngIf="Data$ | async as data; else loading">
        <div *ngIf="!(BS.MobileQuery | async)" class="desktop-wrapper">
            <div class="base-data-scroll-container">
                <ng-container [ngTemplateOutlet]="baseDataTemplate" [ngTemplateOutletContext]="{ data: data }"> </ng-container>
            </div>
            <mat-divider vertical></mat-divider>
            <ng-container [ngTemplateOutlet]="commentsTemplate" [ngTemplateOutletContext]="{ view: CommentsViewConfig, eventId: eventId$ | async, visible: true }"> </ng-container>
        </div>
        <mat-tab-group *ngIf="BS.MobileQuery | async" [(selectedIndex)]="SelectedTabIndex" mat-align-tabs="center" class="mobile-tab-group">
            <mat-tab label="Stammdaten">
                <div class="base-data-scroll-container">
                    <ng-container [ngTemplateOutlet]="baseDataTemplate" [ngTemplateOutletContext]="{ data: data }"> </ng-container>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="tab-header">
                        {{ CommentsViewConfig.headline }}
                        <span class="unseen" *ngIf="UnseenComments$ | async as count">{{ count }}</span>
                    </div>
                </ng-template>
                <ng-container [ngTemplateOutlet]="commentsTemplate" [ngTemplateOutletContext]="{ view: CommentsViewConfig, eventId: eventId$ | async, visible: SelectedTabIndex == 1 }"> </ng-container>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="card-actions">
    <button Color="cancel" app-button mat-dialog-close Inverted>Abbrechen</button>
    <button app-round-button [Color]="'red'" *ngIf="CanDelete$ | async" matTooltip="Löschen" aria-label="Löschen" (click)="Delete()">
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button app-button Color="green" [Disabled]="Form.pristine || Form.untouched|| Form.invalid" [IsLoading]="ls.IsLoading$ | async" (click)="Save()">Speichern</button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
<ng-template #taskNotFound>
    <p>Aufgabe nicht gefunden, bitte versuchen Sie es erneut.</p>
</ng-template>
<ng-template #noFolder>
    <div class="file-list">
        <app-small-file-card
            *ngFor="let file of FilesToAssign$ | async"
            (FileClick)="openFilePreviewFromSmallFileCard($event.Id)"
            (RemoveClick)="OnRemoveFile(file)"
            RemoveButton
            [FileId]="file.Id"
        ></app-small-file-card>
    </div>
</ng-template>
<ng-template #dropZoneTemplate>
    <app-dropzone #Drpzone
                  (dropzoneUploadSuccess)="onDropzoneUploadSuccess($event)"
    [autoProcess]="!!(eventId$ | async)" clearOnSuccess [fileUploadParams]="uploadParamsObj">

    </app-dropzone>
<!--    <dropzone-->
<!--        #Drpzone-->
<!--        *ngIf="DropzoneConfig$ | async as config"-->
<!--        [config]="config"-->
<!--        [message]="'Klicken oder Dateien hinein ziehen'"-->
<!--        (error)="OnUploadError($event)"-->
<!--        (queueComplete)="QueComplete($event)"-->
<!--        (addedFile)="OnSelect($event)"-->
<!--        (removedFile)="OnRemove($event)"-->
<!--        (uploadProgress)="onDropzoneUploadProgress($event)"-->
<!--        (success)="onDropzoneSuccess($event)"-->
<!--    >-->
<!--    </dropzone>-->
</ng-template>
<ng-template #commentsTemplate let-view="view" let-eventId="eventId" let-visible="visible">
    <app-comment-list
        #commentListComponent
        [ViewConfig]="view"
        [EntityId]="eventId"
        [EntityType]="CommentEntityTypeEnum.Event"
        [GetEntityIdBeforeSaveComment]="SaveTaskToGenerateComment.bind(this)"
        [GetFileUploadParamsBeforeSaveComment]="GetUploadParamsAfterSave.bind(this)"
        [Expandable]="false"
        [Inline]="true"
        [Visible]="visible"
        (UnseenCommentCount)="UnseenComments$.next($event)"
        [FileUploadParams]="CommentFileUploadParams$ | async"
    ></app-comment-list>
</ng-template>
<ng-template #baseDataTemplate let-data="data">
    <ng-container *ngIf="!TaskNotFound; else taskNotFound">
        <app-select-event-type [formControl]="Form.controls.EventType"></app-select-event-type>
        <app-detail-list-template [Editing]="true" [Data]="data" Inline="true" (Submit)="Save()" [AutofocusIndex]="0"></app-detail-list-template>
        <app-detail-list-template [Editing]="true" [Data]="CommissionForm$ | async" Inline="true" (Submit)="Save()" [AutofocusIndex]="-1"></app-detail-list-template>
        <app-detail-list-template [Editing]="true" [Data]="milestonesForm" Inline="true" (Submit)="Save()" [AutofocusIndex]="-1"></app-detail-list-template>
        <div class="headline">
            <h3><fa-icon icon="paperclip"></fa-icon> Anhänge</h3>
            <span class="spacer"></span>
            <mat-slide-toggle [disabled]="!(Folder$ | async)" [(ngModel)]="DMSNotMinimalistic">DMS</mat-slide-toggle>
            <button app-round-button (click)="OpenFileUpload()" matTooltip="Dateien hinzufügen">
                <app-double-icon [Icon]="DMSMeta.Icon" Icon2="upload"></app-double-icon>
            </button>
        </div>
        <ng-container *ngIf="!DMSNotMinimalistic" [ngTemplateOutlet]="dropZoneTemplate"></ng-container>
        <app-file-explorer
            PreventFileClick
            [FileUploadButton]="true"
            [FileUploadConfig]="{ EventId: eventId$ | async }"
            (FileClicked)="OpenFilePreview($event)"
            *ngIf="Folder$ | async as folder; else noFolder"
            [DisableBackButtonFolderId]="folder.Id"
            [SelectedFolderId]="folder.Id"
            [DisableRoutingParams]="true"
            [Minimalistic]="!DMSNotMinimalistic"
            (fileExplorerContext) = "ImageIds = $event"
        ></app-file-explorer>
    </ng-container>
</ng-template>
