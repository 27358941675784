<div class="timeline-events" *ngIf="Events?.length" #eventsWrapper>
    <section class="timeline-event-card" [class.ready]="conf.ready" *ngFor="let conf of Events" (click)="EventClick.emit(conf.entity)">
        <div class="timeline-event-card-body">
            <div class="color-corner"></div>
            <div class="flex flex-col mr-3">
                <h1 class="m-0">{{ conf.label }}</h1>
                <fa-icon class="text-center" size="lg" *ngIf="conf.subEventIcon as icon" [icon]="icon"></fa-icon>
                <div class="text-center" *ngIf="conf.subEventCount != null && conf.subEventCountTarget != null">{{conf.subEventCount}}/{{conf.subEventCountTarget}}</div>
            </div>
            <p>
                <strong>{{ conf.tooltip }}</strong>
                <br />
                <label>{{ conf.desc }}</label>
                <br />
                <span
                >Frist: <strong>{{ conf.date | date }}</strong></span
                >
            </p>
            <fa-icon *ngIf="conf.ready" class="check-icon" icon="check"></fa-icon>
        </div>

        <ng-container *ngIf="conf.progress != null">
            <span *ngIf="conf.progressLabel" class="progress-label"> {{conf.progressLabel}}</span>
            <app-progress-card-progress-bar class="progress-bar" [progress]="conf.progress" [progressTarget]="conf.progressTarget"></app-progress-card-progress-bar>
<!--            <div class="progress-bar" [class.error]="conf.progress > conf.progressTarget">-->
<!--                <div [style.width.%]="conf.progress > 100 ? (conf.progressTarget / conf.progress) * 100 : (conf.progress / conf.progressTarget) * 100" class="timeline-fill-success"></div>-->
<!--            </div>-->
        </ng-container>
    </section>
</div>
<div *ngIf="From != null && To != null" class="timeline" [class.no-pointer]="Events?.length === 0">
    <div [style.width.%]="state" class="timeline-fill"></div>
    <span class="state" [style.left.%]="state">{{ state | number: '1.0-0' }}%</span>
    <span class="start-date">{{ From | date: 'shortDate' }}</span>
    <span class="end-date">{{ To | date: 'shortDate' }}</span>
    <div class="date-legend-container">
        <span class="date-legend" *ngFor="let date of legendDates" [style.left.%]="date.state">{{ date.label }}</span>
    </div>
    <ng-container *ngFor="let conf of Events">
        <div
            *ngIf="conf.date"
            class="pointer"
            [class.ready]="conf.ready"
            [style.left.%]="getState(conf.date)"
            [matTooltipPosition]="'above'"
            [matTooltip]="conf.tooltip + '\n' + (conf.date | date)"
            (click)="EventClick.emit(conf.entity)"
        >
            {{ conf.label }}
            <div class="arrow"></div>
        </div>
    </ng-container>
</div>
