<!--<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">-->
<!--    <div class="example-box" *ngFor="let movie of movies" cdkDrag>{{movie}}</div>-->
<!--</div>-->
<mat-list cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-list-item cdkDragPreviewContainer="parent" cdkDrag *ngFor="let file of files" class="list-item">
        <fa-icon cdkDragHandle mat-list-icon icon="bars"></fa-icon>
        <div mat-line>{{file.Name}}</div>
        <div mat-line>{{file.Description}}</div>
        <button mat-icon-button matTooltip="Bearbeiten" aria-label="Bearbeiten" (click)="onEditFileClick(file)"><fa-icon icon="pencil-alt"></fa-icon></button>
    </mat-list-item>
</mat-list>
<mat-dialog-actions>
    <button app-button matDialogClose Color="cancel" Inverted>Abbrechen</button>
    <button app-button (click)="onSaveClick()" Color="green">Speichern</button>
</mat-dialog-actions>
<ng-template #fileUploadTemplate let-formControl="formControl">
    <button app-round-button [matMenuTriggerFor]="uploadMenu" matTooltip="neue Version" class="top-right-button"><fa-icon icon="upload"></fa-icon></button>
    <mat-menu #uploadMenu>
        <button mat-menu-item (click)="versionUpload.emit(formControl.value)">
            <span>Vom Computer</span>
        </button>
        <button mat-menu-item (click)="versionUploadDms.emit(formControl.value)">
            <span>Aus dem DMS</span>
        </button>
    </mat-menu>
</ng-template>
