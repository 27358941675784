import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, exhaustMap, of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { PartnerEntity, PartnerEntityFromBackend } from '../../entities/partner.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { PartnersActionTypes } from '../actions/partners.actions';
import { State } from '../index';
import { getTokenPayload } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'Partner Abrufen fehlgeschlagen',
    SetMandateReference = 'Partner Mandatereference erzugen fehlgeschlagen',
}

@Injectable()
export class PartnerEffects {
    constructor(
        private actions$: Actions<DaveActions>,
        private store$: Store<State>,
        private gatewayHttpService: HttpService,
    ) {}


    SetMandatsreferenz$ = createEffect(() => this.actions$.pipe(
        ofType(PartnersActionTypes.SetMandateReferencePartner),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation{
                setMandateReference(partnerId: ${Payload.partnerId}){
                    ${PartnerEntity.GqlFields.join(',')}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res =>
                    res && res.setMandateReference
                        ? PartnersActionTypes.UpdateSinglePartner({
                              Payload: PartnerEntityFromBackend(res.setMandateReference),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.SetMandateReference } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.SetMandateReference,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    LoadPartner$ = createEffect(() => combineLatest([
        this.actions$.pipe(ofType(PartnersActionTypes.LoadPartner)),
        this.store$.select(getTokenPayload).pipe(filter(isNotNullOrUndefined)),
    ]).pipe(
        withLatestFrom(this.store$),
        exhaustMap(([[action, tokenPayload], store]) => {
            const queryString = `
            query {
                partner(id: ${tokenPayload.PartnerId == null ? -1 : store.base.tokenPayload.PartnerId}) {
                    ${PartnerEntity.GqlFields.join(',')}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token, retry: true }).pipe(
                map(res =>
                    res && res.partner && res.partner.length === 1
                        ? PartnersActionTypes.UpdatePartner({
                              Payload: PartnerEntityFromBackend(res.partner[0]),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }),
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));
}
