<h1 mat-dialog-title>
    <fa-icon icon="history"></fa-icon>
   Ereignis {{(IsEditing$ | async) ? 'bearbeiten': 'anlegen'}}
</h1>
<mat-dialog-content>
    <form [formGroup]="NewEventForm" *ngIf="ChipListData$ | async as chipListData">

        <mat-form-field class="hover-form full-width" *ngIf="customerOptions$ | async as options; else loading">
            <mat-label>{{ CS.GetSingle$()|async }}</mat-label>
            <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="NewEventForm.controls.Customer.setValue(null);$event.stopPropagation();"></fa-icon>
            <app-select-search [formControl]="NewEventForm.controls.Customer" [Options]="options" [CompareOptions]="compareById"></app-select-search>
        </mat-form-field>

        <div class="contactPersonWrapper">
            <mat-form-field class="type">

                <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="NewEventForm.controls?.PersonId.setValue(null);$event.stopPropagation();"></fa-icon>

                <app-select-search
                    [placeholder]="this.Customer ? this.Customer?.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter'"
                    [formControl]="NewEventForm.controls.PersonId"
                    [Options]="PersonList"
                    [CompareOptions]="compareById"
                ></app-select-search>

                <mat-error *ngIf="NewEventForm.controls.PersonId?.invalid">
                    {{ GetErrorMessage(NewEventForm.controls.PersonId) }}
                </mat-error>
            </mat-form-field>
            <span class="button">
                <button
                    type="button"
                    app-round-button
                    class="button"
                    [Disabled]="Customer?.Id == null"
                    [matTooltip]="(this.Customer ? this.Customer?.AnsprechpartnerText() : 'Ansprechpartner / Sachbearbeiter') + ' hinzufügen'"
                    (click)="NewContactPerson()"
                >
                <fa-icon icon="plus"></fa-icon>
            </button>
        </span>
        </div>
        <app-detail-list-template [Data]="chipListData" [Editing]="true" [Inline]="true"></app-detail-list-template>
        <mat-form-field class="hover-form full-width" *ngIf="processOptions$ | async as processes; else loading">
            <mat-label>Prozess</mat-label>
            <fa-icon matSuffix icon="times" style="margin-left: 3px; cursor: pointer;" (click)="NewEventForm.controls.Process.setValue(null);$event.stopPropagation();"></fa-icon>
            <app-select-search [formControl]="NewEventForm.controls.Process" [Options]="processes" [CompareOptions]="compareById"></app-select-search>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="buttons">
    <button
        Inverted
        type="button"
        app-button
        class="button"
        mat-dialog-close
        Color="cancel"
        [IsLoading]="LS.IsLoading$ | async"
    >
        Abbrechen
    </button>

    <button
        app-button
        class="button"
        Color="green"
        [Disabled]="NewEventForm.invalid"
        (click)="Submit()"
        [IsLoading]="LS.IsLoading$ | async"
    >
        Speichern
    </button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
