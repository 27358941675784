<!--Floating Buttons oben rechts "bearbeiten", "löschen", etc.-->
<div class="buttons" *ngIf="!IsMobile || ShowButtons">
    <ng-container [ngTemplateOutlet]="BeforeButtons">

    </ng-container>
    <app-burger-button-wrapper *ngIf="Buttons" InvertedBurgerButton>
        <ng-container [ngTemplateOutlet]="Buttons"></ng-container>
    </app-burger-button-wrapper>
</div>

<!--<div class="scrollbar full-height wrapper">-->
    <div class="placeholder" *ngIf="ShowPlaceholder"></div>

    <div class="top">
        <ng-container [ngTemplateOutlet]="TopContent"></ng-container>
    </div>
<!--<div class="resizer" *ngIf="Resizable" #resizer2></div>-->

    <div class="bottom" *ngIf="LeftContent || RightContent" [class.resizable]="Resizable">
        <div class="left" [class.scrollbar]="Resizable">
            <ng-container [ngTemplateOutlet]="LeftContent"></ng-container>
        </div>
        <div class="resizer" *ngIf="Resizable" #resizer></div>
        <div class="right" [class.resizable.scrollbar]="Resizable">
            <ng-container [ngTemplateOutlet]="RightContent"></ng-container>
        </div>
    </div>
<!--</div>-->
