import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '../State';
import { firstValueFrom } from 'rxjs';
import { getToken } from '../State/selectors/base.selectors';
import { environment } from '../../../environments/environment';

export const API_METRICS = false;
export const API_TRACING = false;

@Injectable({
    providedIn: 'root',
})
export class ProtoApiService {
    constructor(protected router: Router, protected store: Store<State>) {
    }
    Request<K,T>(body: K, decode: (v: Uint8Array) => T, encode: (v: K) =>  Uint8Array, path: string, resolverResponseRaw = false): Promise<T> {
        return firstValueFrom(this.store.select(getToken)).then((token) => {
            return new Promise((resolve, reject) => {
                const req = new XMLHttpRequest();
                req.onload = (e) => {
                    if (req.status === 200) {
                        // console.log(req)
                        // console.log(req.response)
                        if (resolverResponseRaw) {
                            resolve(req.response);
                        } else {
                            if (window["env"].useProtobuf) {
                                const response: T = decode(new Uint8Array(req.response));
                                resolve(response);
                            } else {
                                resolve(JSON.parse(req.response));
                            }
                        }
                    } else {
                        reject(req.response);
                    }
                };
                req.onerror = (err) => {
                    reject(err);
                };
                req.open('POST', environment.gatewayApiUrl + path);
                req.setRequestHeader('Authorization', 'Bearer ' + token);
                if (window["env"].useProtobuf) {
                    req.responseType = 'arraybuffer';
                    req.setRequestHeader('Content-Type', 'application/protobuf');
                    req.send(encode(body));
                } else {
                    req.responseType = 'text';
                    req.setRequestHeader('Content-Type', 'application/json');
                    req.send(JSON.stringify(body));
                }
            });
        });
    }
}
