import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { getErrorMessage } from '../../../../../helper/validation.helper';
import { BehaviorSubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';

export type MaterialColor = 'primary' | 'accent' | 'warn';

@Component({
    selector: 'app-textfield',
    templateUrl: './textfield.component.html',
    styleUrls: ['./textfield.component.scss']
})
export class TextfieldComponent implements OnChanges, OnInit{
    @ViewChild('textField') textField: HTMLElement;
    @Input() Key = '';
    @Input() Hint: string = null;
    @Input() Value: UntypedFormControl | FormControl; // | AbstractControlTyped<string>;
    @Input() Required = false;
    @Input() CustomError = '';
    @Input() Color: MaterialColor = 'primary';
    @Input() Type:
        'color'
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'month'
        | 'number'
        | 'password'
        | 'search'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week'
        = 'text';
    public ErrorMessage$ = new BehaviorSubject<string>('');

    ngOnInit() {
        if (this.Value) {
            this.Value.valueChanges.subscribe(() => this.ErrorMessage$.next(this.CustomError || getErrorMessage(this.Value)));
        }
    }

    focusInput() {
        if (this.textField) {
            this.textField.focus()
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ErrorMessage$.next(this.CustomError || getErrorMessage(this.Value));
    }
}
