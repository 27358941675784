<app-dave-list-card
    [Headline]="cls.getMultiple$('Supplier') | async"
    EmptyText="Keine Zuordnungen vorhanden"
    [Data]="MaterialToSupplierTableData$ | async"
    [DisplayedCollums]="['SupplierName', 'Date', 'Cost', 'ArticleNumber']"
    [TableHeaderCollumsMap]="{
        SupplierName: cls.getSingle$('Supplier') | async,
        Date: 'Aktualisiert am',
        Cost: 'EK-Preis',
        ArticleNumber: 'Artikelnummer'
    }"
    [Clickable]="true"
    (EntryClicked$)="onAddSupplier($event.entry)"
    style="max-height: 50vh"
    [IsLoading]="MaterialToSupplierTableDataLoading$ | async"
>
    <button app-round-button headerButton onlyExpanded (click)="onAddSupplier(null)">
        <fa-icon [icon]="'plus'"></fa-icon>
    </button>
</app-dave-list-card>
