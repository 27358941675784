import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { Subject } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class GermanPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  firstPageLabel = `Erste Seite`;
  itemsPerPageLabel = `Einträge pro Seite:`;
  lastPageLabel = `Letzte Seite`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Vorherige Seite';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    // if (length === 0) {
    //   return `Seite 1 von 1`;
    // }
    // const amountPages = Math.ceil(length / pageSize);
    // return `Seite ${page + 1} von ${amountPages}`;
    if (length === 0) {
      return `0 von 0`;
    }
    return `${(page * pageSize) + 1} - ${(page * pageSize) + pageSize < length ? (page * pageSize) + pageSize : length} von ${length}`;
  }
}
