import { CommonModule,formatCurrency,formatDate } from '@angular/common';
import { Component,Inject,Input,OnDestroy,OnInit,Optional,TemplateRef,ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog,MatDialogConfig,MatDialogModule,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationStart,Router,RouterEvent } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { Moment } from 'moment/moment';
import { BehaviorSubject,combineLatest,firstValueFrom,Observable,Subscription,switchMap } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, tap } from 'rxjs/operators';
import {
BusinessVolumeCostStateEnum,
BusinessVolumeEntity,BusinessVolumeStatusEnumNameMap
} from '../../../dave-data-module/entities/business-volume.entity';
import { BVCustomNameResolver } from '../../../dave-data-module/guards/b-v-custom-name.resolver';
import { BusinessVolumeResolver } from '../../../dave-data-module/guards/business-volume.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getBVCustomNameDictionary,getBVCustomNameFetched,getBVCustomNamesActive } from '../../../dave-data-module/State/selectors/b-v-custom-name.selector';
import { getBusinessVolume,getBusinessVolumeById,getBusinessVolumeFetched } from '../../../dave-data-module/State/selectors/business-volume.selector';
import {
    getCommissionById,
getCommissionDictionary,
getCommissions,
getCommissionsFetched
} from '../../../dave-data-module/State/selectors/commission.selector';
import {
    getCustomerById,
getCustomerDictionary,
getCustomers,
getCustomersFetched
} from '../../../dave-data-module/State/selectors/customers.selectors';
import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../dave-utils-module/app-filter-module/app-filter.module';
import {
FilterOption,
FILTER_TYPE_SEARCH_MULTI_SELECT,
FILTER_TYPE_SELECT,FILTER_TYPE_TIMESPAN,IFilterTypeSearchMultiSelectValue
} from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import {
DaveSharedComponentsModule
} from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import {
PermissionService
} from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import {
BusinessVolumeDialogComponent,
BusinessVolumeDialogComponentDialogData
} from '../../../detail-list-template-dialog/business-volume-dialog/business-volume-dialog.component';
import { appMatDialogDefaultConfig,isNotNullOrUndefined } from '../../../helper/helper';
import { ReportsPageMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { FilterTypes } from '../../../services/default-filter.service';
import {
    SelectBusinessVolumesDataDialogComponent, SelectBusinessVolumesDataDialogComponentData,
} from '../../../detail-list-template-dialog/export-business-volumes-dialog/select-business-volumes-data-dialog/select-business-volumes-data-dialog.component';
import {
    getChartOfAccountDictionary,
    getChartOfAccountsFetched,
} from '../../../dave-data-module/State/selectors/chartOfAccount.selectors';
import { ChartOfAccountResolver } from '../../../dave-data-module/guards/chartOfAccount.resolver';
import { CustomerEntity } from 'src/app/dave-data-module/entities/customer.entity';
import { CommissionEntity } from 'src/app/dave-data-module/entities/commission.entity';

interface BvFilterValues {
    type: BusinessVolumeCostStateEnum;
    status: IFilterTypeSearchMultiSelectValue[];
    customNames: IFilterTypeSearchMultiSelectValue[];
}
export interface CommissionBusinesVolumeListComponentDialogData {
    commissionId?: number;
    customerId?: number;
    defaultFilter?: Partial<BvFilterValues>;
}
type tableRow = {
    amountNumber: number;
    amount: string;
    payedAmountNumber: number;
    payedAmount: string;
    label: string;
    id: number;
    bvEntity: BusinessVolumeEntity;
    cssClass?: string;
    businessVolumeNo: string;
    name: string;
    status: string;
    bVCustomNames: string;
    description: string;
    createdAt: string;
    customerName: string;
    customerNr_KontoNr:string;
};
type tableColumns = 'customerName' | 'customerNr_KontoNr'| 'name'| 'bVCustomNames'| 'description'| 'businessVolumeNo'| 'status'| 'amount'| 'payedAmount'| 'createdAt' | 'commissionLabel';

@Component({
    selector: 'app-commission-busines-volume-list',
    standalone: true,
    imports: [CommonModule, AppButtonModule, AppFilterModule, FontAwesomeModule, DaveListCardModule, MatDialogModule, MatTooltipModule, MatBadgeModule, DaveSharedComponentsModule],
    templateUrl: './commission-busines-volume-list.component.html',
    styleUrls: ['./commission-busines-volume-list.component.scss'],
})
export class CommissionBusinesVolumeListComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-without-padding', 'custom-dialog-class-overflow-visible'],
        minWidth: '72vw', //steht auch im scss
        width: '60rem', //steht auch im scss
        maxWidth: '100vw',
        closeOnNavigation: true,
    };
    @Input() set CommissionId(id: number) {
        this.CommissionId$.next(id);
    }
    get CommissionId() {
        return this.CommissionId$.value;
    }
    @Input() set CustomerId(id: number) {
        this.CustomerId$.next(id);
    }
    get CustomerId() {
        return this.CustomerId$.value;
    }

    @Input() Expandable = false;
    @Input() Expanded = true;
    @Input() tableLayoutFixed = false;

    protected availableColumns: tableColumns[] = ['customerName', 'customerNr_KontoNr', 'commissionLabel', 'name', 'bVCustomNames', 'description', 'businessVolumeNo', 'status', 'amount', 'payedAmount', 'createdAt'];
    protected displayedColumns: tableColumns[] = this.availableColumns.slice();
    @Input() set ColumnCount(v: number) {
        this.displayedColumns = this.availableColumns.slice(0, v);
    }
    @Input() set Columns(v: tableColumns[]) {
        this.displayedColumns = v.slice();
    }
    @ViewChild('lIButton') lIButton: TemplateRef<any>;

    public CommissionId$ = new BehaviorSubject<number>(null);
    public CustomerId$ = new BehaviorSubject<number>(null);
    public Commission$: Observable<CommissionEntity> = this.CommissionId$.pipe(switchMap((id) => this.store.select(getCommissionById({ id: id }))));
    public Customer$: Observable<CustomerEntity>  = combineLatest([
        this.CustomerId$,
        this.Commission$,
    ]).pipe(
        switchMap(([customerId, commission]) => {
            if(isNotNullOrUndefined(customerId)) {
                return this.store.select(getCustomerById({ id: customerId }));
            }
            else if(isNotNullOrUndefined(commission)){
                return this.store.select(getCustomerById({ id: commission.CustomerId }));
            }
        }),
    );
    protected ledgerImportPath = '';
    public BvFilterSettings: Observable<FilterOption<keyof BvFilterValues>[]> = combineLatest([
        this.store.select(getBVCustomNameFetched).pipe(
            filter((f) => f),
            switchMap(() => this.store.select(getBVCustomNamesActive)),
        ),
        getFetched$(this.store, getCommissionsFetched, getCommissions),
        getFetched$(this.store, getCustomersFetched, getCustomers),
        this.CommissionId$,
        this.CustomerId$,
        this.cls.getMultiple$('Commission'),
        this.cls.getMultiple$('Customer'),
    ]).pipe(
        map(([customNames, commissions, customers, commissionId, customerId, commissionLabel, customerLabel]) => {
            const filters = [];
            if (!customerId) {
                const filterValues: IFilterTypeSearchMultiSelectValue[] = customers.map((r) => ({
                    label: r.DisplayName,
                    id: r.Id,
                }));
                filters.push({
                    Name: FilterTypes.Customers,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: customerLabel,
                    Values: filterValues,
                });
            }
            if (!commissionId) {
                const filterValues: IFilterTypeSearchMultiSelectValue[] = commissions.map((r) => ({
                    label: r.DisplayName,
                    id: r.Id,
                }));
                filters.push({
                    Name: FilterTypes.Commissions,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: commissionLabel,
                    Values: filterValues,
                });
            }
            filters.push(
                {
                    Name: FilterTypes.Date,
                    Type: FILTER_TYPE_TIMESPAN,
                    Label: 'Erstellt',
                },
                {
                    Name: 'type',
                    Type: FILTER_TYPE_SELECT,
                    Label: 'Typ',
                    Values: {
                        [BusinessVolumeCostStateEnum.Income]: 'Einnahmen',
                        [BusinessVolumeCostStateEnum.Costs]: 'Kosten',
                    },
                },
                {
                    Name: 'status',
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Status',
                    Values: Array.from(BusinessVolumeStatusEnumNameMap.keys()).map<IFilterTypeSearchMultiSelectValue<string>>((v) => ({
                        id: v,
                        label: BusinessVolumeStatusEnumNameMap.get(v),
                    })),
                },
                {
                    Name: 'customNames',
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Art',
                    Values: customNames.map((r) => ({
                        label: r.Name,
                        id: r.Id,
                    })),
                },
            );
            return filters;
        }),
    );
    public BvFilterValues$: BehaviorSubject<BvFilterValues> = new BehaviorSubject({
        type: BusinessVolumeCostStateEnum.Income,
        status: [],
        customNames: [],
    });
    public FilterAmount$ = this.BvFilterValues$.pipe(
        map((filter) => {
            let amount = 0;
            if (filter.status?.length) {
                amount += 1;
            }
            if (filter.customNames?.length) {
                amount += 1;
            }
            if (filter.type) {
                amount += 1;
            }
            return amount;
        }),
    );
    private routerSubscription: Subscription;
    private bvsByCommission$ = this.store.select(getBusinessVolumeFetched).pipe(
        distinctUntilChanged(),
        tap((fetched) => {
            if (!fetched) {
                this.businessVolumeResolver.resolve();
            }
        }),
        filter((v) => !!v),
        switchMap(() => combineLatest([this.CommissionId$, this.CustomerId$])),
        filter(isNotNullOrUndefined),
        switchMap(([commissionId, customerId]) =>
            this.store.select(getBusinessVolume).pipe(
                map((bvs) => {
                    if (commissionId) {
                        return bvs.filter((bv) => bv.CommissionId === commissionId);
                    }
                    if (customerId) {
                        return bvs.filter((bv) => bv.CustomerId === customerId);
                    }
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    Data$: Observable<MatTableDataSource<tableRow>> = combineLatest([
        this.bvsByCommission$,
        this.store.select(getBVCustomNameDictionary),
        getFetched$(this.store, getCustomersFetched, getCustomerDictionary),
        getFetched$(this.store, getCommissionsFetched, getCommissionDictionary),
        getFetched$(this.store, getChartOfAccountsFetched, getChartOfAccountDictionary )
    ]).pipe(
        map(([bvs, customNames, customers, commissions, chartDic]) =>
            bvs.map<tableRow>((b) => {
                const customer = customers ? customers[b.CustomerId] : null;
                const customerNr = customer ? (customer.CustomerNo !== null && customer.CustomerNo !== undefined ? customer.CustomerNo : '') : '';
                const kontoNr = customer ? ( (customer.KontoCOAId === null || chartDic[customer.KontoCOAId] === null) ||
                (customer.KontoCOAId === undefined || chartDic[customer.KontoCOAId] === undefined) ? '' : chartDic[customer.KontoCOAId]?.Number ) : '';
                const separator =  kontoNr !== '' ? ' / ' : '';
                const customerNr_KontoNr = customerNr + separator + kontoNr;

                return {
                    id: b.Id,
                    label: b.Name,
                    amount: b.Amount ? formatCurrency(b.Amount / 100, 'de-DE', '€', 'EUR') : '',
                    amountNumber: b.Amount,
                    payedAmount: b.PayedAmount ? formatCurrency(b.PayedAmount / 100, 'de-DE', '€', 'EUR') : '',
                    payedAmountNumber: b.PayedAmount,
                    cssClass: undefined,
                    bvEntity: b,
                    businessVolumeNo: b.BusinessVolumeNo,
                    name: b.Name,
                    status: b.Status ? BusinessVolumeStatusEnumNameMap.get(b.Status) : undefined,
                    bVCustomNames: b.BVCustomNamesIds.map((id) => customNames[id]?.Name).join(', '),
                    description: b.Description,
                    createdAt: formatDate(b.CreatedAt, 'dd.MM.yyyy', 'de-DE'),
                    customerName: customer ? customer.Name : '',
                    customerNr_KontoNr: customerNr_KontoNr,
                    commissionLabel: b.CommissionId ? commissions[b.CommissionId]?.GetDisplayName() : '',
                };
            }),
        ),
        switchMap((data) =>
            this.BvFilterValues$.pipe(
                map((filterValues) =>
                    data.filter(
                        (d) =>
                            (!filterValues.type || filterValues.type === d.bvEntity.BVType) &&
                            (!filterValues.status?.length || filterValues.status.some((s) => s.id === d.bvEntity.Status)) &&
                            (!filterValues.customNames?.length || filterValues.customNames.some((s) => d.bvEntity.BVCustomNamesIds?.includes(+s.id))) &&
                            (!filterValues[FilterTypes.Commissions]?.length || (d.bvEntity.CommissionId && filterValues[FilterTypes.Commissions].some((f) => f.id === d.bvEntity.CommissionId))) &&
                            (!filterValues[FilterTypes.Customers]?.length || (d.bvEntity.CustomerId && filterValues[FilterTypes.Customers].some((f) => f.id === d.bvEntity.CustomerId))) &&
                            (!filterValues[FilterTypes.Date]?.from || (filterValues[FilterTypes.Date].from as Moment).isSameOrBefore(d.bvEntity.CreatedAt)) &&
                            (!filterValues[FilterTypes.Date]?.to || (filterValues[FilterTypes.Date].to as Moment).isSameOrAfter(d.bvEntity.CreatedAt)),
                    ),
                ),
            ),
        ),
        map((data) => new MatTableDataSource<tableRow>(data)),
        tap(
            (dataSource) =>
                (dataSource.sortingDataAccessor = (object, key) => {
                    switch (key) {
                        case 'payedAmount':
                            return object.payedAmountNumber;
                        case 'amount':
                            return object.amountNumber;
                        default:
                            return object[key];
                    }
                }),
        ),
    );
    protected hasCreatePermission$ = this.ps.Has$(this.ps.Permissions.CreateBusinessVolume);
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public DialogData: CommissionBusinesVolumeListComponentDialogData,
        private router: Router,
        @Optional() protected dialogRef: MatDialogRef<CommissionBusinesVolumeListComponent>,
        private store: Store<State>,
        private dialog: MatDialog,
        private bVCustomNameResolver: BVCustomNameResolver,
        private businessVolumeResolver: BusinessVolumeResolver,
        protected commissionResolver: CommissionResolver,
        protected cls: CustomLabelService,
        private ps: PermissionService,
        protected customerResolver: CustomerResolver,
        protected chartOfAccountResolver: ChartOfAccountResolver,
    ) {
        firstValueFrom(this.store.select(getChartOfAccountsFetched)).then((fetched) => {
            if (!fetched) {
                chartOfAccountResolver.resolve();
            }
        });
    }
    ngOnInit(): void {
        this.Customer$.pipe(filter(isNotNullOrUndefined), first()).subscribe(customer => {
            var BvFilterType = BusinessVolumeCostStateEnum.Income;
            if(isNotNullOrUndefined(customer)) {
                if(customer.IsSupplier) {
                    BvFilterType = BusinessVolumeCostStateEnum.Costs;
                }
                else {
                    BvFilterType = BusinessVolumeCostStateEnum.Income;
                }
            }
            const values: BvFilterValues = {
                type: BvFilterType,
                status: [],
                customNames: [],
            };
            this.BvFilterValues$.next(values);
        });
        firstValueFrom(this.store.select(getBVCustomNameFetched)).then((f) => {
            if (!f) {
                this.bVCustomNameResolver.resolve();
            }
        });
        if (this.DialogData) {
            if (this.DialogData.defaultFilter) {
                this.BvFilterValues$.next({ ...this.BvFilterValues$.value, ...this.DialogData.defaultFilter });
            }
            if (this.DialogData.commissionId) {
                this.CommissionId$.next(this.DialogData.commissionId);
                this.displayedColumns = this.availableColumns.slice().filter((c) => c !== 'commissionLabel');
            }
            if (this.DialogData.customerId) {
                this.CustomerId$.next(this.DialogData.customerId);
                this.displayedColumns = this.availableColumns.slice().filter((c) => c !== 'customerNr_KontoNr' && c !== 'customerName');
            }
            this.routerSubscription = this.router.events
                .pipe(
                    filter((event: RouterEvent) => event instanceof NavigationStart),
                    filter(() => !!this.dialogRef),
                )
                .subscribe(() => {
                    this.dialogRef.close();
                });
        }
        // this.BvFilterValues$.next({ ...this.BvFilterValues$.value, type, status: status ? [{ id: status, label: BusinessVolumeStatusEnumNameMap.get(status) }] : [] });
    }
    ngOnDestroy() {
        this.routerSubscription?.unsubscribe();
    }
    async openEditDialog(id?: number) {
        const customerId = this.CustomerId;
        const commissionId = this.CommissionId;

        let customer: CustomerEntity;
        if (customerId) {
            customer = await firstValueFrom(getFetched$(this.store, getCustomersFetched, getCustomerById({id: customerId}), this.customerResolver));
        } else if (commissionId) {
            const commission = await firstValueFrom(getFetched$(this.store, getCommissionsFetched, getCommissionById({id: commissionId}), this.commissionResolver))
            if (commission?.CustomerId) {
                customer = await firstValueFrom(getFetched$(this.store, getCustomersFetched, getCustomerById({id: commission.CustomerId}), this.customerResolver));
            }
        }
        this.dialog.open<BusinessVolumeDialogComponent, BusinessVolumeDialogComponentDialogData>(BusinessVolumeDialogComponent, {
            ...BusinessVolumeDialogComponent.DefaultConfig,
            data: {
                BvId: id,
                ShowCommissionForm: !commissionId,
                ShowCustomerForm: !customerId,
                AddBusinessVolumeDefaultValues: { commissionId, customerId, sachkontoCOAId: customer?.SachkontoCOAId },
            },
            autoFocus: !id,
        });
    }
    EntryClick(event: { entry: any }) {
        this.openEditDialog(event.entry.id);
    }

    addBV() {
        this.openEditDialog();
    }

    protected readonly ReportsPageMeta = ReportsPageMeta;
    protected readonly BusinessVolumeCostStateEnum = BusinessVolumeCostStateEnum;
    protected tableHeaders$ = combineLatest([this.cls.getSingle$('Commission'), this.cls.getLabels$('Customer')]).pipe(
        map(([commissionLabel, customerLabels]) => {
            return {
                label: 'Re-Nr. / Art-Nr',
                customerName: customerLabels.Nominativ.singular.value + '/Lieferant',
                customerNr_KontoNr: customerLabels.wordBinding + '/-KontoNr',
                amount: 'Summe',
                payedAmount: 'Bezahlte Summe',
                businessVolumeNo: 'Nummer',
                name: 'Name',
                status: 'Status',
                description: 'Beschreibung',
                createdAt: 'Erstellt',
                bVCustomNames: 'Art',
                commissionLabel,
            };
        }),
    );

    openDialog() {
        this.dialog.open<CommissionBusinesVolumeListComponent, CommissionBusinesVolumeListComponentDialogData>(CommissionBusinesVolumeListComponent, {
            ...CommissionBusinesVolumeListComponent.DefaultConfig,
            data: {
                commissionId: this.CommissionId,
                customerId: this.CustomerId,
                defaultFilter: this.BvFilterValues$.value,
            },
        });
    }

    exportBusinessVolumes() {
        this.dialog.open<SelectBusinessVolumesDataDialogComponent,SelectBusinessVolumesDataDialogComponentData>(SelectBusinessVolumesDataDialogComponent, {
            ...SelectBusinessVolumesDataDialogComponent.DefaultConfig,
            data: {
                CommissionId: this.CommissionId$.value,
                CustomerId: this.CustomerId$.value,
                DisableCommissionForm: !!this.CommissionId$.value,
                DisableCustomerForm: !!this.CustomerId$.value,
            }
        });
    }
}
