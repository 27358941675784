import { Injectable } from '@angular/core';
import { GetTimestampFromTime } from '@dave/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, EMPTY, exhaustMap, firstValueFrom, interval, merge, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { ProcessEntityFromBackend } from '../../entities/process.entity';
import { AppGatewayService } from '../../services/app-gateway.service';
import { BaseActionTypes } from '../actions/base.actions';
import { ProcessActions } from '../actions/process.actions';
import { State } from '../index';
import { getProcessDictionary } from '../selectors/process.selector';
import { FolderActionTypes } from '../actions/folder.actions';
import { getFiles } from '../selectors/files.selectors';
import { ProcessResolver } from '../../guards/process.resolver';

enum ErrorCodes {
    Load = 'Prozess Abrufen fehlgeschlagen',
    Modify = 'Prozess Bearbeiten fehlgeschlagen',
    AddUser = 'Benutzer hinzufügen fehlgeschlagen',
    RemoveUser = 'Benutzer entfernen fehlgeschlagen',
    Remove = 'Prozess Löschen fehlgeschlagen',
    Add = 'Prozess Hinzufügen fehlgeschlagen',
}

@Injectable()
export class ProcessEffects {
    GetProcess = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProcessActions.load),
                exhaustMap(({ Payload }) =>
                    this.gatewayService
                        .Request({ ProcessGet: isNotNullOrUndefined(Payload?.updatedSince) ? { UpdatedAt: Payload.updatedSince } : {} })
                        // .then((res) => firstValueFrom(this.store$.select(getProcessDictionary).pipe(map((processes) => ({ res, processes })))))
                        .then(res => {
                            if (Object.keys(res?.Errors || {}).length === 0) {
                                if (!isNotNullOrUndefined(Payload.updatedSince)) {
                                    this.store$.dispatch(ProcessActions.updateAll({ Payload: res.ProcessGet.Processes.map(ProcessEntityFromBackend), updateLatestUpdatedAt: true }));
                                } else if (res.ProcessGet.Processes.length) {
                                    firstValueFrom(this.store$.select(getProcessDictionary)).then(processes => {
                                        const fRes = res.ProcessGet.Processes.filter((p) => {
                                            const fromState = processes[p.Id];
                                            return !fromState || GetTimestampFromTime(fromState.UpdatedAt).toString() !== p.UpdatedAt;
                                        });
                                        if (fRes.length) {
                                            this.store$.dispatch(ProcessActions.updateMany({ Payload: fRes.map(ProcessEntityFromBackend), updateLatestUpdatedAt: true }));
                                        }
                                    });
                                }
                            } else {
                                throw res.Errors;
                            }
                        })
                        .catch((err) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                ),
            ),
        { dispatch: false },
    );
    EditProcess = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.change),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ProcessEdit: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ProcessActions.updateOne({ Payload: ProcessEntityFromBackend(res.ProcessEdit) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    DeleteProcess = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.delete),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ProcessDelete: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ProcessActions.removeOne({ Payload: +Payload.Id });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    AddUser = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.addUser),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ProcessAddUser: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ProcessActions.updateOne({ Payload: ProcessEntityFromBackend(res.ProcessAddUser) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.AddUser,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    RemoveUser = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.removeUser),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ProcessRemoveUser: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return ProcessActions.updateOne({ Payload: ProcessEntityFromBackend(res.ProcessRemoveUser) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.RemoveUser,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    StartProcess = createEffect(() =>
        this.actions$.pipe(
            ofType(ProcessActions.start),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ProcessStart: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            this.store$.dispatch(FolderActionTypes.Poll());
                            if (res.ProcessStart.DocumentId) {
                                firstValueFrom(merge(
                                    this.store$.select(getFiles).pipe(filter(files => files?.some(f => f.LinkedDocumentId === +res.ProcessStart.DocumentId))),
                                    interval(5 * 60 * 1000)
                                )).then(() => this.processResolver.pollUpdated());
                            }
                            return ProcessActions.updateOne({ Payload: ProcessEntityFromBackend(res.ProcessStart) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );
    constructor(private actions$: Actions, private store$: Store<State>, private gatewayService: AppGatewayService, private processResolver: ProcessResolver) {}
}
