import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { EntityType } from '@dave/types';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, scan, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeListEntryComponent } from '../../change-list-entry/change-list-entry.component';
import { ChangeEntity } from '../../dave-data-module/entities/change.entity';
import { CommissionEntity } from '../../dave-data-module/entities/commission.entity';
import { EventEntity } from '../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../dave-data-module/entities/file.entity';
import { FolderEntity } from '../../dave-data-module/entities/folder.entity';
import { GeneratedDocumentsEntity } from '../../dave-data-module/entities/generatedDocuments.entity';
import { LedgerImportEntity } from '../../dave-data-module/entities/ledger-import.entity';
import { ChangesDataService } from '../../dave-data-module/services/changes-data.service';
import { FileDataService } from '../../dave-data-module/services/file-data.service';
import { State } from '../../dave-data-module/State';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { DaveListCardModule } from '../../dave-list-card/dave-list-card.module';
import { AppButtonModule } from '../../dave-utils-module/app-button-module/app-button.module';
import { IconPipeModule } from '../../dave-utils-module/icon-pipe/icon-pipe.module';
import { IconPipe } from '../../dave-utils-module/icon-pipe/icon.pipe';
import { isNotNullOrUndefined, uniqArray } from '../../helper/helper';
import { AllEventsMeta, AllGeneratedDocumentsMeta, AllReportsMeta, ChangesMetaIcon, DMSPageMeta, DocumentEditorMeta, GeneratedDocumentsPageMeta, HistoryMeta, InvoiceEditorMeta, ReportsPageMeta } from '../../helper/page-metadata';
import { GermanPaginatorIntl } from '../../services/german-paginator-intl.service';

// enum EntityChangeType {
//     created,
//     updated,
// }
// interface TableData {
//     object: {
//         icon: IconProp;
//         label: EntityChangeType;
//     };
//     type: string;
//     date: string;
//     onClick: () => void;
//     highlight: boolean;
// }
const initialPageEvent = new PageEvent();
initialPageEvent.pageSize = 50;
initialPageEvent.pageIndex = 0;
@Component({
    selector: 'app-entity-history',
    templateUrl: './entity-history.component.html',
    styleUrls: ['./entity-history.component.scss'],
    standalone: true,
    imports: [DaveListCardModule, AsyncPipe, FontAwesomeModule, NgIf, IconPipeModule, MatBadgeModule, DatePipe, ChangeListEntryComponent, AppButtonModule, MatPaginatorModule],
    providers: [{ provide: MatPaginatorIntl, useClass: GermanPaginatorIntl }, IconPipe],
})
export class EntityHistoryComponent implements OnDestroy {
    @Input() set EntityId(EntityId: number) {
        this.entityId$.next(EntityId);
    }

    @Input() set EntityType(EntityType: EntityType) {
        this.entityType$.next(EntityType);
    }
    public ChangesMetaIcon = ChangesMetaIcon;
    protected _expanded = false;
    private _wasExpanded = false;
    @Input() set Expanded(v: boolean) {
        this._expanded = v;
        this._wasExpanded = this._wasExpanded || v;
    }
    get Expanded() {
        return this._expanded;
    }
    // @Output() FolderClicked = new EventEmitter<number>();

    // public EntityChangeTypeLabelMap: Map<EntityChangeType, string> = new Map([
    //     [EntityChangeType.created, 'erstellt'],
    //     [EntityChangeType.updated, 'bearbeitet'],
    // ]);
    // public EntityChangeTypeIconMap: Map<EntityChangeType, IconProp> = new Map([
    //     [EntityChangeType.created, 'plus'],
    //     [EntityChangeType.updated, 'pencil-alt'],
    // ]);
    @ViewChild('paginator') private matPaginator: MatPaginator;

    protected page$ = new BehaviorSubject<PageEvent>(initialPageEvent);
    protected initialPageSize = initialPageEvent.pageSize;

    private entityId$ = new BehaviorSubject<number>(null);
    private entityType$ = new BehaviorSubject<EntityType>(null);
    public Changes$: Observable<ChangeEntity[]> = this.entityType$.pipe(
        filter(isNotNullOrUndefined),
        distinctUntilChanged(),
        switchMap((type) => {
            switch (type) {
                case EntityType.Commission:
                    return this.entityId$.pipe(
                        filter(isNotNullOrUndefined),
                        distinctUntilChanged(),
                        switchMap((id) => this.changesService.getCommissionChanges$(id)),
                        map((changes) => changes.sort((a, b) => b.CreatedAt.getTime() - a.CreatedAt.getTime())),
                        // distinctUntilChanged((a, b) => a.every((c, i) => c.Id === b.)),
                    );
            }
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    protected pagedChanges$ = this.Changes$.pipe(
        distinctUntilChanged((a, b) => a.every((c, i) => c.Id === b[i]?.Id)),
        tap(() => this.matPaginator?.firstPage()),
        switchMap(() =>
            this.page$.pipe(
                withLatestFrom(this.Changes$),
                map(([page, value]) => {
                    return value.slice(page.pageIndex * page.pageSize, page.pageIndex * page.pageSize + page.pageSize);
                }),
                scan<ChangeEntity[], ChangeEntity[] | null>((acc, curr) => {
                    if (acc) {
                        this.changesService.setChangesSeen(acc.map((c) => c.Id));
                    }
                    return curr;
                }, null),
                tap((changes) => {
                    const fileIds = uniqArray(changes.filter((c) => c.Entity === EntityType.Document).map((c) => c.EntityId));
                    if (fileIds.length) {
                        firstValueFrom(this.fileDataService.GetFilesById(fileIds));
                    }
                }),
            ),
        ),
    );
    // public HighlightCountBadge$ = this.Changes$.pipe(map((data) => data.data.reduce((previousValue, currentValue) => (currentValue.highlight ? previousValue + 1 : previousValue), 0) || undefined));
    // D277-4220
    // unseenCount$ = this.Changes$.pipe(map((c) => c.filter(({ LastSeenAt }) => !LastSeenAt).length || undefined));
    unseenCount$ = of(undefined);
    constructor(private dialog: MatDialog, private router: Router, private changesService: ChangesDataService, private store: Store<State>, private fileDataService: FileDataService) {}

    ngOnDestroy(): void {
        if (this._wasExpanded) {
            firstValueFrom(this.pagedChanges$).then((currChanges) => this.changesService.setChangesSeen(currChanges.map((c) => c.Id)));
        }
    }
    // entryClicked({ entry, index }: { entry: ChangeEntity; index: number }) {
    //     // entry.onClick();
    // }

    // das ist die alte logik, wird ggf wieder implementiert
    clickHandler(v: CommissionEntity | FolderEntity | FileEntity | LedgerImportEntity | GeneratedDocumentsEntity | EventEntity) {
        if (v instanceof EventEntity) {
            if (v.IsTask) {
                this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
                    ...DetailTasksComponent.DefaultConfig,
                    data: {
                        EventId: v.Id,
                    },
                });
            } else {
                this.router.navigate([HistoryMeta.Path, AllEventsMeta.Path, v.Id + '']);
            }
        } else if (v instanceof LedgerImportEntity) {
            this.router.navigate([ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, v.Id + '']);
        } else if (v instanceof GeneratedDocumentsEntity) {
            this.router.navigate([GeneratedDocumentsPageMeta.Path, AllGeneratedDocumentsMeta.Path, DocumentEditorMeta.Path, v.Id + '']);
        } else if (v instanceof FolderEntity) {
            this.router.navigate([DMSPageMeta.Path], { queryParams: { folderId: v.Id } });
        } else if (v instanceof FileEntity) {
            this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
                ...DaveFilePreviewComponent.DefaultConfig,
                data: {
                    fileId: v.Id,
                },
            });
        }
    }
}
