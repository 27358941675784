<app-dave-list-card
    [Headline]="cls.getMultiple$('Costs') | async"
    EmptyText="Keine Preise vorhanden"
    [Data]="MaterialPriceHistoryTableData$ | async"
    [IsLoading]="MaterialPriceHistoryTableDataLoading$ | async"
    [DisplayedCollums]="['Costs', 'CreatedAt', 'SupplierName', 'User', 'Icon']"
    [TableHeaderCollumsMap]="{
                Costs: cls.getSingle$('Costs') | async,
                CreatedAt: 'erstellt am',
                SupplierName: 'Lieferant',
                User: 'Nutzer'
              }"
    [CustomCellTemplates]="{
                Icon: iconcollum
            }"
    DefaultSortActive="CreatedAt"
    DefaultSortDirection="desc"
    style="max-height: 50vh"
>
</app-dave-list-card>
<ng-template #iconcollum let-data="data">
    <fa-icon matTooltip="Dieser Preis wurde per Datanorm Import angelegt." icon="gear" *ngIf="data.IsAutomatic"></fa-icon>
</ng-template>
