<h1 matDialogTitle>{{ (material$ | async)?.DisplayName }}</h1>
<mat-dialog-content>
    <div class="flex justify-between px-4">
        <div class="py-4 text-center" *ngIf="supplierId">
            EK-Preis des Lieferanten:
            <br />
            <strong>{{ cost$ | async | currency }}</strong>
        </div>
        <div class="py-4 text-center" *ngIf="!supplierId">
            EK-Preis:
            <br />
            <strong>{{ costFromMaterial$ | async | currency }}</strong>
        </div>
        <div class="py-4 text-center" *ngIf="!supplierId">
            Standard Zuschlagssatz:
            <br />
            <strong>{{ defaultSurcharge$ | async | percent }}</strong>
        </div>
        <div class="py-4 text-center" *ngIf="supplierId">
            Errechnete Marge
            <br />
            <strong>{{ marge$ | async | percent }}</strong>
        </div>
        <div class="py-4 text-center" *ngIf="!supplierId">
            Errechnete Marge
            <br />
            <strong>{{ margeFromEntity$ | async | percent }}</strong>
        </div>
        <div class="py-4 text-center">
            VK-Preis:
            <br />
            <strong>{{ sellPrice$ | async | currency }}</strong>
        </div>
        <div class="py-4 text-center" *ngIf="supplierId">
            Listenpreis des Lieferanten:
            <br />
            <strong>{{ listPrice$ | async | currency }}</strong>
        </div>
    </div>
    <div class="px-2">
        <app-material-ledger-import-list-card class="px-2" [title]="cardTitle" [ledgerImportDocumentType]="ledgerImportDocumentType" [materialId]="materialId" [supplierId]="supplierId"></app-material-ledger-import-list-card>
        <app-material-to-supplier-list-card class="px-2" style="display: block" [materialId]="materialId" *ngIf="showOtherSuppliersList"></app-material-to-supplier-list-card>
    </div>
</mat-dialog-content>
