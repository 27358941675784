import { CommonModule,formatDate } from '@angular/common';
import { AfterViewInit,ChangeDetectionStrategy,ChangeDetectorRef,Component,ElementRef,EventEmitter,Input,Output,ViewChild } from '@angular/core';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { isNotNullOrUndefined } from "../helper/helper";
import {
ProgressCardProgressBarComponent
} from "./components/progress-card-progress-bar/progress-card-progress-bar.component";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const MAX_LEGEND_DATES = 6;

export interface ProgressBarEvent<T = any> {
    date: Date;
    ready: boolean;
    tooltip: string;
    label: number;
    entity: T;
    desc: string;
    progress?: number;
    progressTarget?: number; // default 100
    progressLabel?: string;
    subEventIcon?: IconProp;
    subEventCount?: number;
    subEventCountTarget?: number;
}

@Component({
    selector: 'app-progress-timeline[From][To][State]',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, MatTooltipModule, MatProgressBarModule, ProgressCardProgressBarComponent],
    templateUrl: './progress-timeline.component.html',
    styleUrls: ['./progress-timeline.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressTimelineComponent implements AfterViewInit {
    @ViewChild('eventsWrapper') EventsWrapper: ElementRef<HTMLDivElement>;

    scrollEvents() {
        if (this._Events.length == 0) return;

        let finishedCount = 0;
        let check = true;
        this._Events.forEach((event) => {
            if (event.ready) {
                if (check) {
                    finishedCount++;
                }
            } else {
                check = false;
            }
        });
        if (finishedCount != 0) {
            //fix to wait for init
            setTimeout(() => {
                if (this.EventsWrapper) {
                    let childElement = this.EventsWrapper.nativeElement.children.item(finishedCount);
                    childElement?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
                }
            }, 500);
        }
    }
    _Events: ProgressBarEvent[] = [];
    @Input() set Events(e: ProgressBarEvent[]) {
        if (isNotNullOrUndefined(e)) {
            this._Events = e.map((e) => ({ ...e, progressTarget: isNotNullOrUndefined(e.progressTarget) ? e.progressTarget : 100 }));
            this.scrollEvents();
            this.cdr.detectChanges();
        } else {
            throw Error('Events cant be null or undefined');
        }
    }

    get Events(): ProgressBarEvent[] {
        return this._Events;
    }

    _From: Date;
    @Input() set From(d: Date) {
        this._From = d;
        this.calcLegendDates();
        this.setState();
    }

    get From(): Date {
        return this._From;
    }

    _To: Date;
    @Input() set To(d: Date) {
        this._To = d;
        this.calcLegendDates();
        this.setState();
    }

    get To(): Date {
        return this._To;
    }

    state: number;
    _State: Date;
    @Input() set State(d: Date) {
        this._State = d;
        this.setState();
    }

    get State(): Date {
        return this._State;
    }

    @Output() EventClick = new EventEmitter();

    protected legendDates: { label: string; state: number }[];

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.scrollEvents();
    }

    private setState() {
        this.state = this.getState(this._State);
        this.cdr.detectChanges();
    }

    protected getState = (date: Date): number => {
        if (!this.From || !this.To || !date) {
            return -1;
        }
        const v = 100 - ((this.To.getTime() - date.getTime()) / (this.To.getTime() - this.From.getTime())) * 100;
        const out = v > 100 ? 100 : v < 0 ? 0 : v;
        return out;
    };

    protected calcLegendDates() {
        const d = new Date(this.From);
        const months: Date[] = [];
        if (this.From && this.To && this.From.getTime() < this.To.getTime()) {
            while (d.getTime() < this.To.getTime()) {
                d.setMonth(d.getMonth() + 1);
                if (d.getTime() < this.To.getTime()) {
                    months.push(new Date(d.getFullYear(), d.getMonth()));
                }
            }
        }
        const filteredMonths: Date[] = [];
        if (months.length > MAX_LEGEND_DATES) {
            let distance = Math.floor(months.length / (MAX_LEGEND_DATES + 1));
            if (months.length % (MAX_LEGEND_DATES + 1) !== 0) {
                distance++;
            }
            console.log({ distance, months: months.length });
            for (let i = distance; i < months.length; i += distance) {
                console.log(i);
                filteredMonths.push(months[i]);
            }
        } else {
            filteredMonths.push(...months);
        }
        const dateFormat = months.length > 12 ? 'MMM yy' : 'MMM';
        this.legendDates = filteredMonths.map((date) => ({
            label: formatDate(date, dateFormat, 'de-DE'),
            state: this.getState(date),
        }));
    }
}
