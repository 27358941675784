<app-list-layout-with-routing [Path]="PageMeta.Path" TabLabel="Ressourcen">
    <mat-drawer-container>
        <mat-drawer #drawer position="end">
            <app-table-config-form [columns]="TableColumnsSorted$ | async" [selectedColumns]="selectedColumns$ | async" (selectedColumnsChange)="selectedColumnsChange($event)"></app-table-config-form>
        </mat-drawer>
    <app-dave-list
        [ColumnConfig]="TableColumns"
        [Columns]="selectedColumns$ | async"
        (ColumnsChange)="selectedColumnsChange($event)"
        [ColumnsSmall]="selectedColumns$ | async"
        [DataSource$]="DataSource$"
        [Headline]="PageMeta.Breadcrumb"
        [HeadlineIcon]="PageMeta.Icon"
        [NewEntryUrl]="'./' + NewPageMeta.Path"
        NewEntryToolTip="Ressource Hinzufügen"
        [EmptyText]="'Keine ' + PageMeta.Breadcrumb + ' Vorhanden'"
        [FilterValues]="FilterValues$ | async"
        (FilterValuesChange)="FilterValues$.next($event)"
        [FilterSettings]="FilterSettings$ | async"
        [FilterCount]="FilterCount$ | async"
        [DefaultSortActive]="'Name'"
    >
        <ng-container *appDetailViewButtons>
            <button class="header-button" app-round-button Inverted [matBadge]="disabledColumns$ | async" matTooltip="Tabelle anpassen" matTooltipPosition="left" (click)="drawer.toggle()">
                <fa-icon icon="cog"></fa-icon>
            </button>
        </ng-container>
    </app-dave-list>
    </mat-drawer-container>
</app-list-layout-with-routing>
