import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { DropzoneConfig } from 'ngx-dropzone-wrapper';
import { combineLatest, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ConstructionDiaryEntity, ConstructionDiaryEntityAdditionalData, ConstructionDiaryStatusEnum } from '../../../../dave-data-module/entities/construction-diary.entity';
import { AdditionalDataEvent, EventEntity, EventStateEnum, EventStateEnumColorMap, EventStateEnumNameMap } from '../../../../dave-data-module/entities/event.entity';
import { FileEntity } from '../../../../dave-data-module/entities/file.entity';
import { FolderEntity, FolderTypes } from '../../../../dave-data-module/entities/folder.entity';
import { UserToEventEntity, UserToEventEntityAdditionalData } from '../../../../dave-data-module/entities/user-to-event.entity';
import { FrontendDate } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { ConstructionDiaryActionTypes } from '../../../../dave-data-module/State/actions/construction-diary.actions';
import { EventsActionTypes } from '../../../../dave-data-module/State/actions/events.actions';
import { FilesActionTypes } from '../../../../dave-data-module/State/actions/files.actions';
import { UserToEventActionTypes } from '../../../../dave-data-module/State/actions/user-to-event.action';
import { getToken } from '../../../../dave-data-module/State/selectors/base.selectors';
import { getConstructionDiaries } from '../../../../dave-data-module/State/selectors/construction-diary.selectors';
import { getEventById } from '../../../../dave-data-module/State/selectors/events.selectors';
import { getFiles } from '../../../../dave-data-module/State/selectors/files.selectors';
import { getUserToEventFetched, getUserToEvents } from '../../../../dave-data-module/State/selectors/user-to-event.selector';
import { getUsersFetched, getUsersWithMyPartnerId } from '../../../../dave-data-module/State/selectors/users.selectors';

import { UserResolver } from 'src/app/dave-data-module/guards/user.resolver';
import { EventTypeEntity } from '../../../../dave-data-module/entities/event-type.entity';
import { DaveMutationCreateConstructionDiaryArgs } from '../../../../dave-data-module/graphql-types';
import { UserToEventResolver } from '../../../../dave-data-module/guards/user-to-event.resolver';
import { ConstructionDiaryDataService } from '../../../../dave-data-module/services/construction-diary-data.service';
import { FileDataService } from '../../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../../dave-data-module/services/http.service';
import { getCommissionById } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypeById } from '../../../../dave-data-module/State/selectors/commissionType.selectors';
import { getEventTypeById } from '../../../../dave-data-module/State/selectors/event-type.selector';
import { PdfEditorComponent } from '../../../../dave-dms-module/dave-dms/pdf-editor/components/pdf-editor.component';
import {
    DaveSelectFileFromDmsComponent,
    DaveSelectFileFromDmsComponentDialogData,
    DaveSelectFileFromDmsComponentReturnData,
} from '../../../../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { SelectSearchData } from '../../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, sameDay } from '../../../../helper/helper';
import { DMSPageMeta } from '../../../../helper/page-metadata';
import { getErrorMessage } from '../../../../helper/validation.helper';
import { LeafletWrapperComponent } from '../../../../leaflet-wrapper/leaflet-wrapper.component';
import { LoadingService } from '../../../../services/loading.service';
import { AppConfirmationDialogComponent, AppConfirmationDialogData } from 'src/app/dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';
import { getFetched$ } from '../../../../dave-data-module/helper/helper';
import {
    getUserToCommissionByCommissionId,
    getUserToCommissionFetched,
} from '../../../../dave-data-module/State/selectors/user-to-commission.selector';
import { UserToCommissionResolver } from '../../../../dave-data-module/guards/user-to-commission.resolver';

export interface AppAddEventDialogData {
    eventId: number;
    headline?: string;
    hideDate?: boolean;
    canDelete?: boolean;
}

interface TableData {
    name: string;
    timeForm: FormControlTyped<string>;
    u2e: UserToEventEntity;
}

export type AppAddEventDialogDataReturn = 'save' | 'abort';

@Component({
    selector: 'app-add-event-dialog',
    templateUrl: './add-event-dialog.component.html',
    styleUrls: ['./add-event-dialog.component.scss'],
})
export class AddEventDialogComponent implements OnInit, OnDestroy {
    @ViewChild('appPdfEditor') pdfEditor: PdfEditorComponent;
    // @ViewChild('leafletMap') leafletMap: LeafletWrapperComponent;
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '50vw',
        width: '100rem',
        maxWidth: '96vw',
        maxHeight: '96vh',
        height: '80rem',
        minHeight: '80vh',
        closeOnNavigation: false,
        autoFocus: false,
    };
    private mapMarkerBuffer: {
        [key: number]: {
            _latlng: {
                lat: number;
                lng: number;
            };
        }[];
    } = {};
    public planForm = new FormControl<{ documentId: number; documentName: string; optionLabel: string; imageUrl: string } | null>(null);
    public planFiles$: Observable<Array<FileEntity>>;
    public plans$: Observable<Array<{ documentId: number; documentName: string; optionLabel: string; imageUrl: string }>>;

    public DMSNotMinimalistic = false;
    private fileDialog: MatDialogRef<any>;
    public Event$: Observable<EventEntity>;
    public EventType$: Observable<EventTypeEntity>;
    public Diary$: Observable<ConstructionDiaryEntity>;
    public Folder$: Observable<FolderEntity>;
    public DMSMeta = DMSPageMeta;
    public DropzoneConfig$: Observable<DropzoneConfig>;
    constructor(
        @Inject(MAT_DIALOG_DATA) public Data: AppAddEventDialogData,
        u2eResolver: UserToEventResolver,
        userResolver: UserResolver,
        private dialog: MatDialog,
        private store: Store<State>,
        private dialogRef: MatDialogRef<AddEventDialogComponent>,
        private actions$: Actions,
        public LS: LoadingService,
        private fileDataService: FileDataService,
        private constructionDiaryDataService: ConstructionDiaryDataService,
        private api: HttpService,
        private folderDataService: FolderDataService,
        u2cResolver: UserToCommissionResolver,
    ) {
        firstValueFrom(this.store.select(getUsersFetched)).then((fetched) => {
            if (!fetched) {
                userResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getUserToEventFetched)).then((fetched) => {
            if (!fetched) {
                u2eResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getUserToCommissionFetched)).then((fetched) => {
            if (!fetched) {
                u2cResolver.resolve();
            }
        });
    }

    private fileUploadUIds = [];
    EventStateOptions = Array.from(EventStateEnumNameMap.keys()).map((k) => ({ label: EventStateEnumNameMap.get(k), value: k }));
    public DialogFormGroup = new FormGroup({
        eventName: new FormControl<string | null>(null),
        eventText: new FormControl<string | null>(null),
        state: new FormControl<EventStateEnum>(EventStateEnum.New, Validators.required),
        date: new FormControl<Moment | null>(moment()),
    });
    public NewUserToEventFormGroup = new FormGroup({
        user: new FormControl<SelectSearchData>(null, Validators.required),
        time: new FormControl<string>(null, Validators.required),
    });
    public getErrorMessage = getErrorMessage;
    public Diaries: ConstructionDiaryEntity[];
    public Users$ = this.store.select(getUsersWithMyPartnerId).pipe(
        map((u) => u?.filter((us) => !us.Deleted)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public UsersForSelectSearch$: Observable<SelectSearchData[]>;
    private subs: Subscription[] = [];
    public UserToEvents$: Observable<TableData[]>;
    DateClass = (d: Moment): MatCalendarCellCssClasses => {
        const date = d.toDate();
        const diary = this.Diaries.find((d) => sameDay(d.Date, date));
        return diary?.GeneratedFileId ? 'blue' : diary?.Status === ConstructionDiaryStatusEnum.Newly ? 'yellow' : diary?.Status === ConstructionDiaryStatusEnum.Done ? 'green' : '';
    };

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        this.UserToEvents$ = combineLatest([
            this.store.select(getUserToEvents).pipe(
                filter(isNotNullOrUndefined),
                map((u2es) => u2es.filter((u2e) => u2e.EventId === this.Data.eventId)),
            ),
            this.Users$,
        ]).pipe(
            map(([u2es, users]) => {
                return u2es.map((u2e) => {
                    const timeForm: FormControlTyped<string> = new UntypedFormControl(u2e.AdditionalData?.timespan);
                    return {
                        name: users.find((u) => u.Id === u2e.UserId).DisplayName,
                        timeForm,
                        u2e,
                    };
                });
            }),
        );
        this.UsersForSelectSearch$ = combineLatest([
            this.Users$,
            this.store.select(getEventById({ id: this.Data.eventId })).pipe(switchMap((e) => (e?.CommissionId ? getFetched$(this.store, getUserToCommissionFetched, getUserToCommissionByCommissionId({commissionId: e.CommissionId})) : of(null)))),
        ]).pipe(
            map(([users, userToCommissions]) =>
                users
                    ?.filter((u) => {
                        const u2cs = userToCommissions.filter(u2c => u2c.UserId === u.Id);
                        return u2cs.length && u2cs.every(u2c => !u2c.Assignee)
                    })
                    .map<SelectSearchData>((u) => ({
                        Name: u.DisplayName,
                        Id: u.Id,
                        SearchContent: u.Email,
                    })),
            ),
        );
        this.Diary$ = this.store.select(getConstructionDiaries).pipe(map((diaries) => diaries?.find((d) => d.EventIds?.includes(this.Data.eventId))));
        this.Event$ = this.store.select(getEventById({ id: this.Data.eventId })).pipe(filter(isNotNullOrUndefined));
        this.planFiles$ = this.Event$.pipe(
            switchMap((event) => (event.CommissionId ? this.store.select(getCommissionById({ id: event.CommissionId })) : of(null))),
            map((commission) => commission?.PlanIds),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap((ids) => (ids.length ? this.fileDataService.GetFilesById(ids) : of([]))),
            map((files) => files.filter(isNotNullOrUndefined)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.plans$ = this.planFiles$.pipe(
            withLatestFrom(this.store.select(getToken)),
            map(([files, token]) => files.map((f) => ({ documentId: f.Id, documentName: f.Name, optionLabel: f.Name, imageUrl: this.api.GetUrl(f.GetLastVersion().GetDownloadLink(token), 'file') }))),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        // this.eventMapMarker$ = this.Event$.pipe(switchMap(e => this.MockPlan.pipe(map(p => e.AdditionalData?.mapMarker[p.Id]))));
        this.EventType$ = this.Event$.pipe(switchMap((e) => this.store.select(getEventTypeById({ id: e.EventTypeId }))));
        const diarysByCommission$ = combineLatest([this.Event$, this.store.select(getConstructionDiaries)]).pipe(map(([event, diaries]) => diaries?.filter((d) => d.CommissionId === event.CommissionId)));

        this.Folder$ = this.folderDataService.getFolderFromEntity(this.Data.eventId, FolderTypes.event).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
        this.subs.push(
            this.Folder$.subscribe(),
            diarysByCommission$.subscribe((diaries) => (this.Diaries = diaries)),
            combineLatest([this.Event$, this.Diary$])
                .pipe(
                    tap(([event, diary]) => {
                        if (event) {
                            this.DialogFormGroup.setValue(
                                {
                                    eventName: event.Name || this.DialogFormGroup.value.eventName,
                                    eventText: event.Description,
                                    date: diary ? moment(diary.Date) : moment(event.EventDate),
                                    state: event.State,
                                },
                                { emitEvent: true },
                            );
                            if (event.AdditionalData?.mapMarker && Object.keys(event.AdditionalData.mapMarker)?.length) {
                                firstValueFrom(this.plans$).then((plans) => {
                                    const pId = Object.keys(event.AdditionalData.mapMarker).find((k) => plans.some((p) => p.documentId.toString() === k));
                                    const p = plans.find((p) => p.documentId.toString() === pId);
                                    console.log({ plans, pId, p, keys: Object.keys(event.AdditionalData.mapMarker) });
                                    this.planForm.setValue(p);
                                });
                            }
                        }
                    }),
                )
                .subscribe(),
        );
        this.DropzoneConfig$ = combineLatest([this.Event$, this.Diary$, this.store.select(getToken)]).pipe(
            map(([event, diary, token]) => {
                const params = [];

                if (diary?.EventIds.includes(event.Id)) {
                    params['construction_diary'] = diary.Id;
                }
                params['commission_id'] = JSON.stringify([event.CommissionId]);
                params['event_id'] = JSON.stringify([this.Data.eventId]);
                params['auto_set_folder'] = true;
                return new DropzoneConfig({
                    autoProcessQueue: true,
                    uploadMultiple: false,
                    paramName: () => 'file',
                    parallelUploads: 100,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params,
                });
            }),
        );
    }

    public async Delete() {
        if(this.Data.canDelete) {
            const eventId = await firstValueFrom(this.Event$.pipe(map((e) => e.Id)));
            const eventName = await firstValueFrom(this.Event$.pipe(map((e) => e.Name)));
            this.dialog
            .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                data: {
                    heading: 'Störung wirklich löschen?',
                    paragraph: 'Sind sie sicher, das Sie die Störung ' + eventName + ' löschen möchten?',
                    styleDelete: true,
                },
            })
            .afterClosed()
            .pipe(
                take(1),
                filter(([response]) => response),
                tap(() => this.store.dispatch(EventsActionTypes.DeleteEvent({ Payload: eventId }))),
                tap(() => this.dialogRef.close()),
            )
            .subscribe();
        }
    }

    public async Save() {
        this.LS.isLoading = true;
        const eventId = await firstValueFrom(this.Event$.pipe(map((e) => e.Id)));
        this.SaveNewUserToEvent(true);
        this.store
            .select(getConstructionDiaries)
            .pipe(take(1), withLatestFrom(this.Diary$, this.Event$))
            .subscribe(([diaries, oldDiary, event]) => {
                if (!oldDiary || !sameDay(oldDiary.Date, this.DialogFormGroup.value.date.toDate())) {
                    if (oldDiary) {
                        this.store.dispatch(
                            ConstructionDiaryActionTypes.Modify({
                                Payload: {
                                    id: oldDiary.Id,
                                    eventIds: oldDiary.EventIds.filter((id) => id !== this.Data.eventId),
                                },
                            }),
                        );
                    }
                    const date = this.DialogFormGroup.value.date.toDate();
                    const newDiary = diaries.find((d) => d.CommissionId === event.CommissionId && sameDay(d.Date, date));
                    // todo create diary if not exists
                    if (newDiary) {
                        this.store.dispatch(
                            ConstructionDiaryActionTypes.Modify({
                                Payload: {
                                    id: newDiary.Id,
                                    eventIds: [...newDiary.EventIds, this.Data.eventId],
                                },
                            }),
                        );
                    } else {
                        firstValueFrom(this.store.select(getCommissionById({ id: event.CommissionId })).pipe(switchMap((c) => this.store.select(getCommissionTypeById({ id: c.CommissionTypeId }))))).then((commissionType) => {
                            const addData: ConstructionDiaryEntityAdditionalData = {
                                weather: { description: '', temp: null },
                                leistungsergebnisseTemplateIds: null,
                            };
                            const values: DaveMutationCreateConstructionDiaryArgs = {
                                leistungsergebnisse: '',
                                additionalData: JSON.stringify(addData),
                                leistungsaenderungen: '',
                                besondereVorkommnisse: '',
                                commissionId: event.CommissionId,
                                eventIds: [this.Data.eventId],
                            };
                            const dayliTodos = commissionType?.AdditionalData?.ConstructionDiary.dailyTodos || [];
                            const tasks: EventEntity[] = dayliTodos.map((dailyTodo) => {
                                const date = new Date(FrontendDate(this.DialogFormGroup.value.date?.toDate(), true));
                                date.setHours(dailyTodo.time.hour);
                                date.setMinutes(dailyTodo.time.minute);

                                return new EventEntity().Clone({
                                    Name: dailyTodo.name,
                                    State: EventStateEnum.New,
                                    AdditionalData: { ...dailyTodo.additionalData, UnicId: dailyTodo.unicId },
                                    EventDate: date,
                                });
                            });
                            this.constructionDiaryDataService.saveDiaryEntry({ Date: this.DialogFormGroup.value.date?.toDate() }, tasks, values, event.CommissionId);
                        });
                    }
                }
                let additionalEventData: AdditionalDataEvent = JSON.parse(JSON.stringify(event.AdditionalData || {}));
                // console.log(this.leafletMap.LeafletInstance)
                const mapMarker: {
                    [key: number]: {
                        _latlng: {
                            lat: number;
                            lng: number;
                        };
                    }[];
                } = this.mapMarkerBuffer; //[]// this.leafletMap.getMarker()
                console.log({ mapMarker });

                // const additionalEventData: AdditionalDataEvent = annotations?.length === 1 ? {
                //     mapMarker: {156: [{
                //         left: annotations[0].bounds.left as number,
                //         top: annotations[0].bounds.top as number,
                //         }]}
                // }: {}

                /**
                 * jedes event darf nur noch auf einem plan einen marker haben
                 */
                const m = /*additionalEventData.mapMarker ||*/ {};
                Object.keys(mapMarker)
                    /**
                     * jedes event darf nur noch auf einem plan einen marker haben
                     */
                    .filter((k) => k === this.planForm.value?.documentId?.toString())
                    .forEach((k) => {
                        m[k] = mapMarker[k].map((m) => ({
                            top: m._latlng.lat,
                            left: m._latlng.lng,
                        }));
                    });

                additionalEventData.mapMarker = m;
                this.store.dispatch(
                    EventsActionTypes.ModifyEvent({
                        Payload: {
                            id: eventId,
                            name: this.DialogFormGroup.value.eventName,
                            description: this.DialogFormGroup.value.eventText,
                            eventDate: FrontendDate(this.DialogFormGroup.value.date?.toDate(), true),
                            additionalData: JSON.stringify(additionalEventData),
                            state: this.DialogFormGroup.value.state,
                        },
                    }),
                );
            });

        this.actions$.pipe(ofType(EventsActionTypes.UpdateEvent), take(1)).subscribe((action) => {
            this.LS.isLoading = false;
            this.dialogRef.close('save');
        });
    }

    public SaveNewUserToEvent(preventLoading = false) {
        if (this.NewUserToEventFormGroup.valid) {
            if (!preventLoading) {
                this.LS.isLoading = true;
            }
            this.actions$.pipe(ofType(UserToEventActionTypes.UpdateOne, BaseActionTypes.ErrorAction), take(1)).subscribe((action) => {
                if (action.type === UserToEventActionTypes.UpdateOne.type) {
                    this.NewUserToEventFormGroup.reset();
                }
                if (!preventLoading) {
                    this.LS.isLoading = false;
                }
            });
            const additionalData: UserToEventEntityAdditionalData = {
                timespan: this.NewUserToEventFormGroup.value.time,
            };
            this.store.dispatch(
                UserToEventActionTypes.Create({
                    Payload: {
                        eventId: this.Data.eventId,
                        userId: this.NewUserToEventFormGroup.value.user.Id,
                        additionalData: JSON.stringify(additionalData),
                    },
                }),
            );
        }
    }

    public SaveChangeEventToUser(element: TableData) {
        this.LS.isLoading = true;
        this.actions$.pipe(ofType(UserToEventActionTypes.UpdateOne, BaseActionTypes.ErrorAction), take(1)).subscribe(() => {
            this.LS.isLoading = false;
        });
        const additionalData: UserToEventEntityAdditionalData = {
            ...element.u2e.AdditionalData,
            timespan: element.timeForm.value,
        };
        this.store.dispatch(
            UserToEventActionTypes.Change({
                Payload: { id: element.u2e.Id, additionalData: JSON.stringify(additionalData) },
            }),
        );
    }

    public DeleteEventToUser(id: number) {
        this.store.dispatch(UserToEventActionTypes.Delete({ Payload: { id } }));
    }

    public OpenFileUpload(CommissionId: number) {
        const files$ = this.Folder$.pipe(switchMap((folder) => (isNotNullOrUndefined(folder) ? this.fileDataService.GetFilesStateFromFolder$(folder?.Id) : of([] as FileEntity[]))));
        const folder$ = this.folderDataService.getFolderFromEntity(CommissionId, FolderTypes.commission);

        combineLatest([files$, folder$])
            .pipe(take(1))
            .subscribe(([files, folder]) => {
                if (this.fileDialog?.getState() === MatDialogState.OPEN) {
                    this.fileDialog.close();
                }
                this.fileDialog = this.dialog.open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                    ...DaveSelectFileFromDmsComponent.DefaultConfig,
                    data: {
                        moveWarning: '',
                        preSelectedFiles: [...files.map((f) => f.Id)],
                        folderId: folder?.Id,
                    },
                });
                this.fileDialog
                    .afterClosed()
                    .pipe(
                        take(1),
                        switchMap(({ documents }) =>
                            this.folderDataService.getFolderFromEntity(this.Data.eventId, FolderTypes.event).pipe(
                                take(1),
                                withLatestFrom(this.store.select(getFiles)),
                                tap(([folder, allfiles]) => {
                                    if (documents) {
                                        const selectedFiles = allfiles.filter((f) => documents.includes(f.Id));
                                        const filesToSet = selectedFiles.filter((f) => !f.EventIds?.includes(f.Id));
                                        const filesToUnSet = files.filter((f) => !documents.includes(f.Id));
                                        const folderId = folder?.Id;
                                        if (folderId) {
                                            filesToSet.forEach((f) => {
                                                this.store.dispatch(
                                                    // FilesActionTypes.ModifyFileRequest({
                                                    //     Payload: {
                                                    //         DocumentId: f.Id.toString(),
                                                    //         FolderId: folderId?.toString(),
                                                    //         EventIds: [this.Data.eventId, ...f.EventIds].map(e => e.toString()),
                                                    //     },
                                                    // }),
                                                    FilesActionTypes.CreateLinkRequest({
                                                        Payload: {
                                                            DocumentId: f.Id.toString(),
                                                            EventId: this.Data.eventId.toString(),
                                                            FolderId: folderId?.toString(),
                                                        },
                                                    }),
                                                );
                                            });
                                        } else {
                                            filesToSet.forEach((f) => {
                                                this.store.dispatch(
                                                    FilesActionTypes.ModifyFileRequest({
                                                        Payload: {
                                                            DocumentId: f.Id.toString(),
                                                            AutoSetFolder: true,
                                                            EventIds: [this.Data.eventId, ...f.EventIds].map((e) => e.toString()),
                                                        },
                                                    }),
                                                );
                                            });
                                        }
                                        filesToUnSet.forEach((f) => {
                                            this.store.dispatch(
                                                FilesActionTypes.ModifyFileRequest({
                                                    Payload: {
                                                        DocumentId: f.Id.toString(),
                                                        EventIds: f.EventIds.filter((id) => id !== this.Data.eventId).map((e) => e.toString()),
                                                    },
                                                }),
                                            );
                                        });
                                    }
                                }),
                            ),
                        ),
                    )
                    .subscribe();
            });
    }

    public OnUploadError([file, message]) {
        console.error({ file, message });
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
    }

    public QueComplete() {
        this.Folder$.pipe(take(1)).subscribe((folder) => {
            const folderId = folder?.Id;
            console.log({ folderId });
            if (folderId) {
                this.store.dispatch(
                    FilesActionTypes.LoadFilesFromFolder({
                        Payload: folderId,
                    }),
                );
            } else {
                this.store.dispatch(
                    FilesActionTypes.LoadFilesAfter({
                        Payload: 10,
                    }),
                );
            }
        });
    }

    public OnSelect(event) {
        this.fileUploadUIds.push(event.upload.uuid);
    }

    public OnRemove(event) {
        this.fileUploadUIds.splice(this.fileUploadUIds.indexOf(event.upload.uuid), 1);
    }

    setEvents2(leafletWrapperComponent: LeafletWrapperComponent, planId: number) {
        firstValueFrom(this.Event$).then((e) => {
            e.AdditionalData?.mapMarker[planId]?.forEach((m) => leafletWrapperComponent.addMarker(m.top, m.left, { draggable: true }, e.State ? EventStateEnumColorMap.get(e.State) : undefined, null, e.Id + ''));
            // this.mapMarkerBuffer[planId] = leafletWrapperComponent.getMarker();
            if (leafletWrapperComponent.getMarker().length === 0) {
                leafletWrapperComponent.startAddMarkerMode({ draggable: true }, e.State ? EventStateEnumColorMap.get(e.State) : undefined);
            }
            this.refreshMarker(leafletWrapperComponent, planId);
        });
    }

    refreshMarker(leafletWrapperComponent: LeafletWrapperComponent, planId: number) {
        const marker = leafletWrapperComponent.getMarker();
        this.mapMarkerBuffer[planId] = marker;
        /**
         * jedes event darf nur noch einen ort auf jeder Karte haben
         */
        if (marker?.length) {
            leafletWrapperComponent.stopAddMarkerMode();
        }
    }
    CompareByDocumentId(a: { documentId: number }, b: { documentId: number }) {
        return a.documentId === b.documentId;
    }

    removeProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress].indeterminate-progress')) {
            node.classList.remove('indeterminate-progress');
        }
    }
    addProgressClass(previewElement: Element) {
        for (let node of previewElement.querySelectorAll('[data-dz-uploadprogress]')) {
            node.classList.add('indeterminate-progress');
        }
    }

    onDropzoneSuccess([file]) {
        if (file.previewElement) {
            this.removeProgressClass(file.previewElement);
        }
    }

    onDropzoneUploadProgress([file, progress, bytesSent]) {
        if (progress === 100 && file.previewElement) {
            this.addProgressClass(file.previewElement);
        }
    }
}
