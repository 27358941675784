import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ResolveData, Router } from '@angular/router';
import { MaterialToSupplier } from '@dave/types';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AppConfirmationDialogComponent, AppConfirmationDialogData } from 'src/app/dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { LedgerImportDocumentTypes } from '../../../dave-data-module/entities/ledger-import.entity';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { GeneratedDocumentsResolver } from '../../../dave-data-module/guards/generatedDocuments.resolver';
import { GeneratedDocumentsTypeResolver } from '../../../dave-data-module/guards/generatedDocumentsType.resolver';
import { MaterialGroupResolver } from '../../../dave-data-module/guards/material-group.resolver';
import { MaterialListResolver } from '../../../dave-data-module/guards/material-list.resolver';
import { MaterialResolver } from '../../../dave-data-module/guards/material.resolver';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { MaterialActionTypes } from '../../../dave-data-module/State/actions/material.actions';
import { generatedDocumentsFeatureKey } from '../../../dave-data-module/State/reducers/generatedDocuments.reducer';
import { generatedDocumentsTypesFeatureKey } from '../../../dave-data-module/State/reducers/generatedDocumentsType.reducer';
import { MATERIAL_GROUP_KEY } from '../../../dave-data-module/State/reducers/material-group.reducers';
import { Material_KEY } from '../../../dave-data-module/State/reducers/material.reducer';
import { getCustomersFetched } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getMaterialListsFetched } from '../../../dave-data-module/State/selectors/material-list.selector';
import { getMaterialById, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { getUsersFetched } from '../../../dave-data-module/State/selectors/users.selectors';
import { CommissionMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { MaterialMainDataComponent } from '../material-main-data/material-main-data.component';

interface MaterialListTableData {
    Amount: number;
    CommissionName: string;
    Date: string;
    CreatedAt: Date;
    commissionId: number;
}

@Component({
    selector: 'app-material-detail-view',
    templateUrl: './material-detail-view.component.html',
    styleUrls: ['./material-detail-view.component.scss'],
})
export class MaterialDetailViewComponent {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        [Material_KEY]: MaterialResolver,
        [MATERIAL_GROUP_KEY]: MaterialGroupResolver,
        [generatedDocumentsFeatureKey]: GeneratedDocumentsResolver,
        [generatedDocumentsTypesFeatureKey]: GeneratedDocumentsTypeResolver,
    };
    @ViewChild('materialData') materialData: MaterialMainDataComponent;
    public Editing = false;
    public CommissionMeta = CommissionMeta;

    public MaterialId$ = this.route.paramMap.pipe(
        map((paramMap) => paramMap.get('materialId')),
        map((id) => +id || null),
        distinctUntilChanged(),
        tap((id) => (this.Editing = !id)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Material$ = this.store.select(getMaterialsFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.MaterialId$),
        distinctUntilChanged(),
        switchMap((id) => (id ? this.store.select(getMaterialById({ id })) : of(null))),
    );
    public fileExplorerLoading = new BehaviorSubject<boolean>(true);
    public FolderId$ = this.MaterialId$.pipe(
        switchMap((mId) => (mId ? this.folderDataService.getFolderFromEntity(mId, FolderTypes.resource) : of(null))), // folderTypes.material ??????
        map((folder) => folder?.Id),
        tap(() => this.fileExplorerLoading.next(false)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(
        private actions$: Actions<DaveActions>,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<State>,
        public LS: LoadingService,
        protected dialog: MatDialog,
        protected cls: CustomLabelService,
        private folderDataService: FolderDataService,
        materialListResolver: MaterialListResolver,
        userResolver: UserResolver,
        customerResolver: CustomerResolver,
    ) {
        firstValueFrom(this.store.select(getMaterialListsFetched)).then((fetched) => {
            if (!fetched) {
                materialListResolver.resolve();
            }
        });

        firstValueFrom(this.store.select(getCustomersFetched)).then((fetched) => {
            if (!fetched) {
                customerResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getUsersFetched)).then((fetched) => {
            if (!fetched) {
                userResolver.resolve();
            }
        });
    }

    onSave() {
        this.actions$
            .pipe(
                ofType(MaterialActionTypes.UpdateOne, BaseActionTypes.ErrorAction),
                first(),
                tap(() => this.LS.endLoading('saveMaterial')),
                ofType(MaterialActionTypes.UpdateOne),
            )
            .subscribe((event) => {
                this.router.navigate(['../', event.Payload.Id], { relativeTo: this.route });
            });
        this.materialData.SaveForm();
        this.LS.startLoading('saveMaterial');
    }
    onUndo() {
        this.materialData.ResetForm();
        firstValueFrom(this.MaterialId$).then((id) => {
            if (id) {
                this.Editing = false;
            }
        });
    }
    async onDelete() {
        const materialId = await firstValueFrom(this.MaterialId$.pipe(map((id) => id)));
        const materialName = await firstValueFrom(this.Material$.pipe(map((m) => m.Name)));
        this.dialog
            .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                data: {
                    heading: 'Material wirklich löschen?',
                    paragraph: 'Sind sie sicher, das Sie das Material ' + materialName + ' löschen möchten?',
                    styleDelete: true,
                },
            })
            .afterClosed()
            .subscribe(([res]) => {
                if (res) {
                    this.actions$.pipe(ofType(MaterialActionTypes.RemoveOne), first()).subscribe(() => {
                        this.LS.endLoading('deleteMaterial');
                        this.materialData.ResetForm();
                        this.Editing = false;
                        this.router.navigate(['../'], { relativeTo: this.route });
                    });
                    this.store.dispatch(MaterialActionTypes.Delete({ Payload: { Id: String(materialId) } }));
                    this.LS.startLoading('deleteMaterial');
                }
                return;
            });
    }
    protected readonly MaterialToSupplier = MaterialToSupplier;
    protected readonly LedgerImportDocumentTypes = LedgerImportDocumentTypes;
}
