<app-list-layout-with-routing   [Path]="PageMetaMaterial.Path" TabLabel="Materialien">
    <mat-drawer-container>
        <mat-drawer #drawer position="end">
            <app-table-config-form [columns]="TableColumnsSorted$ | async" [selectedColumns]="selectedColumns$ | async" (selectedColumnsChange)="selectedColumnsChange($event)"></app-table-config-form>
        </mat-drawer>
    <app-dave-list
        [ColumnConfig]="TableColumns"
        [Columns]="selectedColumns$ | async"
        (ColumnsChange)="selectedColumnsChange($event)"
        [ColumnsSmall]="selectedColumns$ | async"
        [DataSource$]="MaterialDataSource$"
        [Headline]="PageMetaMaterial.Breadcrumb"
        [HeadlineIcon]="PageMetaMaterial.Icon"
        [NewEntryUrl]="'./' + NewMaterialPageMeta.Path"
        NewEntryToolTip="Material Hinzufügen"
        [EmptyText]="'Keine ' + PageMetaMaterial.Breadcrumb + ' Vorhanden'"
        [FilterValues]="FilterValues$ | async"
        (FilterValuesChange)="FilterValues$.next($event)"
        [FilterSettings]="FilterSettings$ | async"
        [DefaultSortActive]="'Name'"
    >
        <ng-container *appDetailViewButtons>
            <button app-round-button (click)="onDatanormClick()" matTooltip="Datanorm V4 importieren" Inverted style="margin: 0.25rem"><fa-icon icon="upload"></fa-icon></button>
            <button class="header-button" app-round-button Inverted [matBadge]="disabledColumns$ | async" matTooltip="Tabelle anpassen" matTooltipPosition="left" (click)="drawer.toggle()">
                <fa-icon icon="cog"></fa-icon>
            </button>
        </ng-container>
    </app-dave-list>
    </mat-drawer-container>
</app-list-layout-with-routing>





