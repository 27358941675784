import { CommonModule } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LedgerImportDocumentTypeNames, LedgerImportDocumentTypes, LedgerImportEntity } from '../../../dave-data-module/entities/ledger-import.entity';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes, LedgerImportActionTypes } from '../../../dave-data-module/State/actions/accounting.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { appMatDialogDefaultConfig } from '../../../helper/helper';
import { LoadingService } from '../../../services/loading.service';
import { LedgerImportSelectTableComponent } from '../ledger-import-select-table/ledger-import-select-table.component';
export interface NewClosingInvoiceComponentDialogData {
    FilterLedgerImports?: {
        CommissionId?: number;
        CustomerId?: number;
    };
}
export type NewClosingInvoiceComponentDialogReturnData =
    | undefined
    | {
          LedgerImportCreated: number;
      };
@Component({
    selector: 'app-new-closing-invoice',
    standalone: true,
    imports: [CommonModule, AppButtonModule, LedgerImportSelectTableComponent, MatDialogModule],
    templateUrl: './new-closing-invoice.component.html',
    styleUrls: ['./new-closing-invoice.component.scss'],
})
export class NewClosingInvoiceComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        height: '95vh',
    };
    title = LedgerImportDocumentTypeNames.get(LedgerImportDocumentTypes.ClosingInvoice) + ' erstellen';
    selectedRootLedgerImport: number | undefined;
    selectedLedgerImports: number[] = [];

    selectableRootLedgerImports$: Observable<number[]>;
    selectablePartialLedgerImports$: Observable<number[]>;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) dialogData: NewClosingInvoiceComponentDialogData,
        private dialogRef: MatDialogRef<NewClosingInvoiceComponent, NewClosingInvoiceComponentDialogReturnData>,
        private store: Store<State>,
        protected ls: LoadingService,
        private actions$: Actions,
    ) {
        console.log(dialogData?.FilterLedgerImports)
        this.selectableRootLedgerImports$ = this.store.select(getLedgerImports).pipe(
            map((lis) => lis.filter((l) => l.DocumentType === LedgerImportDocumentTypes.Offer || l.DocumentType === LedgerImportDocumentTypes.OfferConfirmation)),
            map((lis) => {
                let ret = lis;
                if (dialogData?.FilterLedgerImports?.CommissionId) {
                    ret = ret.filter((li) => li.CommissionId === dialogData.FilterLedgerImports.CommissionId);
                }
                console.log(ret.length);

                if (dialogData?.FilterLedgerImports?.CustomerId) {
                    ret = ret.filter((li) => li.CustomerId === dialogData.FilterLedgerImports.CustomerId);
                }
                console.log(ret.length);

                return ret;
            }),
            map((lis) => lis.map((l) => l.Id)),
        );
        this.selectablePartialLedgerImports$ = this.store.select(getLedgerImports).pipe(
            map((lis) => lis.filter((l) => l.DocumentType === LedgerImportDocumentTypes.Invoice || l.DocumentType === LedgerImportDocumentTypes.PartialInvoice || l.DocumentType === LedgerImportDocumentTypes.Cancellation || l.DocumentType === LedgerImportDocumentTypes.Credit)),
            map((lis) => {
                let ret = lis;
                if (dialogData?.FilterLedgerImports?.CommissionId) {
                    ret = ret.filter((li) => li.CommissionId === dialogData.FilterLedgerImports.CommissionId);
                }
                if (dialogData?.FilterLedgerImports?.CustomerId) {
                    ret = ret.filter((li) => li.CustomerId === dialogData.FilterLedgerImports.CustomerId);
                }
                return ret;
            }),
            map((lis) => lis.map((l) => l.Id)),
        );
    }

    submit() {
        if (this.selectedRootLedgerImport) {
            this.ls.startLoading('app-new-closing-invoice-submit');
            firstValueFrom(this.actions$.pipe(ofType(LedgerImportActionTypes.CreateClosingInvoiceSuccess, LedgerImportActionTypes.CreateClosingInvoiceFailure))).then((action) => {
                if (action.type === LedgerImportActionTypes.CreateClosingInvoiceSuccess.type) {
                    firstValueFrom(this.actions$.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, BaseActionTypes.ErrorAction))).then((arlAction) => {
                        this.ls.endLoading('app-new-closing-invoice-submit');
                        if (arlAction.type === AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger.type) {
                            const { Id } = action.Payload as LedgerImportEntity;
                            this.dialogRef.close({
                                LedgerImportCreated: Id,
                            });
                        }
                    });
                } else {
                    this.ls.endLoading('app-new-closing-invoice-submit');
                }
            });

            this.store.dispatch(
                LedgerImportActionTypes.CreateClosingInvoiceRequest({
                    Payload: {
                        rootId: this.selectedRootLedgerImport,
                        ledgerImportIds: this.selectedLedgerImports,
                    },
                }),
            );
        }
    }
}
