/**
 * @deprecated use  Permissions  from '@dave/types';
 */
export enum Permission {
    GetCustomer = 'GetCustomer',
    GetStatistics = 'GetStatistics',
    GetGlobalStatistics = 'GetGlobalStatistics',
    GetEmployees = 'GetEmployees',
    GetEvents = 'GetEvents',
    CreateCustomer = 'CreateCustomer',
    EditCustomer = 'EditCustomer',
    DeleteCustomer = 'DeleteCustomer',
    CreateEmployee = 'CreateEmployee',
    EditEmployee = 'EditEmployee',
    DeleteEmployee = 'DeleteEmployee',
    CreateEvents = 'CreateEvents',
    EditEvents = 'EditEvents',
    DeleteEvents = 'DeleteEvents',
    ApproveVacation = 'ApproveVacation',
    DeleteVacation = 'DeleteVacation',
    ChangeVacation = 'ChangeVacation',
    CreateVacation = 'CreateVacation',
    GetVacation = 'GetVacation',
    EditRoleEmployeeManager = 'EditRoleEmployeeManager', // zuweisen von nutzern
    EditRoleCustomerManager = 'EditRoleCustomerManager', // zuweisen von nutzern
    BookProductManager = 'BookProductManager', // lizensen buchen
    GetUser = 'GetUser',
    GetPartner = 'GetPartner',
    CreatePartner = 'CreatePartner',
    EditPartner = 'EditPartner',
    DeletePartner = 'DeletePartner',
    // CreatePartnerType = '',
    CreateUser = 'CreateUser',
    EditUser = 'EditUser',
    // EditUserFromOtherPartner = '',
    // ShowUserFromOtherPartner = '',
    DeleteUser = 'DeleteUser',
    DaveAdministration = 'DaveAdministration', // timo fragen
    ShareFiles = 'ShareFiles',
    CanShareWithPartner = 'CanShareWithPartner',
    GetLedgerImports = 'GetLedgerImports',
    CreateLedgerImports = 'CreateLedgerImports',
    ChangeLedgerImports = 'ChangeLedgerImports',
    GetAccountsReceivableLedgers = 'GetAccountsReceivableLedgers',
    CreateAccountsReceivableLedgers = 'CreateAccountsReceivableLedgers',
    ChangeAccountsReceivableLedgers = 'ChangeAccountsReceivableLedgers',
    DeleteAccountsReceivableLedgerTemplates = 'DeleteAccountsReceivableLedgerTemplates',
    // DeleteAccountsReceivableLedger = 'DeleteAccountsReceivableLedger',
    GetAccountsReceivableLedgerTemplates = 'GetAccountsReceivableLedgerTemplates',
    CreateAccountsReceivableLedgerTemplates = 'CreateAccountsReceivableLedgerTemplates',
    ChangeAccountsReceivableLedgerTemplates = 'ChangeAccountsReceivableLedgerTemplates',
    CreateCommission = 'CreateCommission',
    GetCommission = 'GetCommission',
    EditCommission = 'EditCommission',
    DeleteCommission = 'DeleteCommission',
    EditRoleCommissionManagement = 'EditRoleCommissionManagement', // zuweisen von nutzern
    DownloadLedgerImportAsCSV = 'DownloadLedgerImportAsCSV',
    CreateClockIn = 'CreateClockIn',
    GetClockIn = 'GetClockIn',
    EditClockIn = 'EditClockIn',
    DeleteClockIn = 'DeleteClockIn',
    CreateWorkDay = 'CreateWorkDay',
    GetWorkDay = 'GetWorkDay',
    EditWorkDay = 'EditWorkDay',
    DeleteWorkDay = 'DeleteWorkDay',
    GetFiles = 'GetFiles',
    CanShowAllMonteurEmployeesInTimeTracker = 'CanShowAllMonteurEmployeesInTimeTracker',
    EditAkkord = 'EditAkkord',
    GetAkkord = 'GetAkkord',
    EditExportedClockIn = 'EditExportedClockIn',
    CreateNews = 'CreateNews',
    GetGeneratedDocuments = 'GetGeneratedDocuments',
    ApproveAbsent = 'ApproveAbsent',
    GetAbsent = 'GetAbsent',
    CreateAbsent = 'CreateAbsent',
    ChangeAbsent = 'ChangeAbsent',
    GetServiceHistory = 'GetServiceHistory',
    CreateCustomerType = 'CreateCustomerType',
    DeleteCustomerType = 'DeleteCustomerType',
    DeleteRoleCommissionManagement = 'DeleteRoleCommissionManagement',
    EditEmployeeresourceSchedule = 'EditEmployeeresourceSchedule',
    DeleteEmployeeresourceSchedule = 'DeleteEmployeeresourceSchedule',
    CanSeeOvertime = 'CanSeeOvertime',
}
