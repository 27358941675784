<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
    <h2>Aus welchem Beleg wollen Sie die Positionen übernehmen?</h2>
    <app-ledger-import-select-table [selectableLedgerImportIds]="selectableRootLedgerImports$ | async" (selectedLedgerImports)="selectedRootLedgerImport = $event.length ? $event[0] : undefined"></app-ledger-import-select-table>
    <h2>Aus welchen Belegen wollen Sie die Schlussrechnung erstellen?</h2>
    <app-ledger-import-select-table multiple [selectableLedgerImportIds]="selectablePartialLedgerImports$ | async" (selectedLedgerImports)="selectedLedgerImports = $event"></app-ledger-import-select-table>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" app-button Color="cancel" [mat-dialog-close]="undefined" Inverted>abbrechen</button>
    <button type="button" app-button Color="green" [IsLoading]="ls.IsLoading$ | async" [Disabled]="!selectedRootLedgerImport" (click)="submit()">Speichern</button>
</mat-dialog-actions>
