import { DataSource } from '@angular/cdk/collections';
import { formatCurrency, formatDate, getCurrencySymbol } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { combineLatest, BehaviorSubject, merge, Observable, switchMap } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getLedgerImports, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import {
    LedgerImportDocumentTypeNames,
    LedgerImportDocumentTypes,
    LedgerImportStatusNames,
} from '../../../dave-data-module/entities/ledger-import.entity';
import { Mehrwertsteuer } from '../../../helper/helper';


export interface LedgerImportSelectTableItem {
    ConsolidatedInvoiceId: string;
    ConsolidatedAmount: string;
    ConsolidatedAmountWithoutTax: string;
    notes: string;
    CommissionId: number;
    isSelected$: Observable<boolean>;
    Id: number;
    DueDate: string;
    Type: string;
}

/**
 * Data source for the LedgerImportSelectTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class LedgerImportSelectTableDataSource extends DataSource<LedgerImportSelectTableItem> {
    // maybe use SelectionModel instead
    selectedLedgerImports$ = new BehaviorSubject<number[]>([]);
    data$: Observable<LedgerImportSelectTableItem[]> = getFetched$(this.store, getLedgerImportsFetched, getLedgerImports).pipe(
        switchMap((lis) =>
            this.selectableLedgerImportIds$.pipe(
                map((selectableLedgerImportIds) => {
                    if (selectableLedgerImportIds) {
                        return lis.filter((l) => selectableLedgerImportIds.includes(l.Id));
                    } else {
                        return lis;
                    }
                }),
            ),
        ),
        map((lis) =>
            lis.map((l) => {
                return {
                    ConsolidatedInvoiceId: l.ConsolidatedInvoiceId,
                    ConsolidatedAmount: formatCurrency(l.ConsolidatedAmount, this.locale, getCurrencySymbol(l.ConsolidatedCurrencyCode, 'narrow', this.locale), l.ConsolidatedCurrencyCode),
                    ConsolidatedAmountWithoutTax: formatCurrency((l.ConsolidatedNettoAmount || 0) / 100, this.locale, getCurrencySymbol(l.ConsolidatedCurrencyCode, 'narrow', this.locale), l.ConsolidatedCurrencyCode),
                    notes: l.AdditionalData?.notes || '',
                    CommissionId: l.CommissionId,
                    Id: l.Id,
                    isSelected$: this.selectedLedgerImports$.pipe(map(selected => selected.includes(l.Id))),
                    DueDate: l.DueDate ? formatDate(l.DueDate, 'shortDate', this.locale) : '',
                    Type: l.DocumentType === LedgerImportDocumentTypes.PartialInvoice ? (l.PartialInvoiceCounter + '. ' + LedgerImportDocumentTypeNames.get(l.DocumentType)) : LedgerImportDocumentTypeNames.get(l.DocumentType)
                };
            }),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    paginator: MatPaginator | undefined;
    sort: MatSort | undefined;
    allSelected$ = combineLatest([this.data$, this.selectedLedgerImports$]).pipe(
        map(([data, selected]) => data.length === selected.length),
        distinctUntilChanged(),
    );
    someSelected$ = combineLatest([this.data$, this.selectedLedgerImports$]).pipe(
        map(([data, selected]) => selected.length > 0 && data.length !== selected.length),
        distinctUntilChanged(),
    );
    constructor(private store: Store<State>, private locale: string, private selectableLedgerImportIds$: BehaviorSubject<number[]>) {
        super();
    }

    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    connect(): Observable<LedgerImportSelectTableItem[]> {
        if (this.paginator && this.sort) {
            // Combine everything that affects the rendered data into one update
            // stream for the data-table to consume.
            return merge(this.data$, this.paginator.page, this.sort.sortChange).pipe(
                withLatestFrom(this.data$),
                map(([_, data]) => {
                    return this.getPagedData(this.getSortedData([...data]));
                }),
            );
        } else {
            throw Error('Please set the ledgerImportFilterByCommissionId, paginator and sort on the data source before connecting.');
        }
    }

    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    disconnect(): void {}

    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getPagedData(data: LedgerImportSelectTableItem[]): LedgerImportSelectTableItem[] {
        if (this.paginator) {
            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            return data.splice(startIndex, this.paginator.pageSize);
        } else {
            return data;
        }
    }

    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getSortedData(data: LedgerImportSelectTableItem[]): LedgerImportSelectTableItem[] {
        if (!this.sort || !this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort?.direction === 'asc';
            switch (this.sort?.active as keyof LedgerImportSelectTableItem) {
                case 'ConsolidatedInvoiceId':
                    return compare(a.ConsolidatedInvoiceId.toLowerCase(), b.ConsolidatedInvoiceId.toLowerCase(), isAsc);
                case 'ConsolidatedAmount':
                    return compare(a.ConsolidatedAmount.toLowerCase(), b.ConsolidatedAmount.toLowerCase(), isAsc);
                case 'notes':
                    return compare(a.notes.toLowerCase(), b.notes.toLowerCase(), isAsc);
                case 'CommissionId':
                    return compare(+a.CommissionId, +b.CommissionId, isAsc);
                default:
                    return 0;
            }
        });
    }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
