import { GetTimeFromTimestamp, MaterialPriceHistory } from '@dave/types';


export enum MaterialPriceHistoryUpdateTypeEnum {
    PRICEUPDATE_NORMAL = "Normal",
    PRICEUPDATE_AUTOMATIC = "Automatisch"
}

export class MaterialPriceHistoryEntity {
    constructor(
        public Id: number = null,
        public MaterialId: number = null,
        public SupplierId: number = null,
        public Cost: number = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public CreatedAt: Date = null,
        public DeletedAt: Date = null,
        public CreateType: MaterialPriceHistoryUpdateTypeEnum = null,
    ) {}

    public static EntityName = 'MaterialPriceHistory';
    public Clone(override: Partial<MaterialPriceHistoryEntity> = {}): MaterialPriceHistoryEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MaterialPriceHistoryEntity(), attr);
    }

}

export function MaterialPriceHistoryEntityFromBackend(res: MaterialPriceHistory): MaterialPriceHistoryEntity {
    const statusUpdateTypeValue = res.CreateType ? MaterialPriceHistoryUpdateTypeEnum[res?.CreateType] : null;

    return new MaterialPriceHistoryEntity(
        res.Id && +res.Id,
        res.MaterialId && +res.MaterialId,
        res.SupplierId && +res.SupplierId,
        res.Cost && +res.Cost,
        res.PartnerId && +res.PartnerId,
        res.UserId && +res.UserId,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
        statusUpdateTypeValue
    );
}
