import { Injectable } from '@angular/core';
import { MaterialPriceHistory, MaterialPriceHistoryGetRequest } from '@dave/types';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subject } from 'rxjs';
import { MaterialPriceHistoryEntity, MaterialPriceHistoryEntityFromBackend } from '../entities/materialPriceHistory.entity';
import { State } from '../State';
import { BaseActionTypes } from '../State/actions/base.actions';
import { AppGatewayService } from './app-gateway.service';

enum ErrorCodes {
    Add = 'MaterialPriceHistory Hinzufügen fehlgeschlagen',
    Load = 'MaterialPriceHistory Abrufen fehlgeschlagen',
    Modify = 'MaterialPriceHistory Bearbeiten fehlgeschlagen',
    Remove = 'MaterialPriceHistory Löschen fehlgeschlagen',
}

@Injectable({
    providedIn: 'root',
})
export class MaterialPriceHistoryDataService {
    private requestByMaterialIdCache = new Map<number, Subject<MaterialPriceHistoryEntity[]>>();

    constructor(private store$: Store<State>, private gatewayService: AppGatewayService) {}

    getMaterialPriceHistoryByMaterialId$(materialId: number, reload = false) {
        console.log('getMaterialPriceHistoryByMaterialId$', {materialId, reload})
        if (!this.requestByMaterialIdCache.has(materialId) || reload) {
            if (!this.requestByMaterialIdCache.has(materialId)) {
                this.requestByMaterialIdCache.set(materialId, new ReplaySubject<MaterialPriceHistoryEntity[]>());
            }
            this.getRequest(materialId).then((response) => {
                this.requestByMaterialIdCache.get(materialId).next(response);
            });
        }
        return this.requestByMaterialIdCache.get(materialId);
    }
    private async pullIfNecessary(m2s: MaterialPriceHistory) {
        if (m2s.MaterialId && this.requestByMaterialIdCache.has(+m2s.MaterialId)) {
            const response = await this.getRequest(+m2s.MaterialId);
            this.requestByMaterialIdCache.get(+m2s.MaterialId).next(response);
        }
    }
    private getRequest(materialId: number): Promise<MaterialPriceHistoryEntity[]> {
        const requestPayload: Omit<MaterialPriceHistoryGetRequest, 'Page'> = {
            WithDeleted: true,
            MaterialId: materialId+'',
        };
        return this.gatewayService
            .Request({
                ErpMaterialPriceHistoryGet: requestPayload,
            })
            .then((res) => {
                if (Object.keys(res?.Errors || {}).length === 0) {
                    return res.ErpMaterialPriceHistoryGet.MaterialPriceHistorys.map(MaterialPriceHistoryEntityFromBackend);
                } else {
                    throw res.Errors;
                }
            })
            .catch((err) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Load,
                            Err: err,
                        },
                    }),
                );
                return [];
            });
    }
}
