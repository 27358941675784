
import { createFeatureSelector,createSelector } from '@ngrx/store';
import { AccountsReceivableLedgerTypeEnum } from '../../entities/accounts-receivable-ledger.entity';
import {
selectAllSurchargeRate,selectSurchargeRateEntities,selectSurchargeRateFetched,SurchargeRateState,SURCHARGE_RATE_KEY
} from '../reducers/surcharge-rate.reducer';
import { SurchargeRateNameEnum } from '../../entities/surcharge-rate.entity';



const selectState = createFeatureSelector<SurchargeRateState>(SURCHARGE_RATE_KEY);

export const getSurchargeRate = createSelector(selectState, selectAllSurchargeRate);
export const getSurchargeRateDictionary = createSelector(selectState, selectSurchargeRateEntities);
export const getSurchargeRateByArlType = (props: { arlType: AccountsReceivableLedgerTypeEnum }) => createSelector(getSurchargeRateDictionary, (dictionary) => {
    if (props.arlType) {
        switch (props.arlType) {
            case AccountsReceivableLedgerTypeEnum.EmployeeArl:
                return dictionary[SurchargeRateNameEnum.Lohn]
            case AccountsReceivableLedgerTypeEnum.ResourceArl:
                return dictionary[SurchargeRateNameEnum.Ressource]
            case AccountsReceivableLedgerTypeEnum.Arl:
                return dictionary[SurchargeRateNameEnum.Leistung]
            case AccountsReceivableLedgerTypeEnum.ExternalService:
                return dictionary[SurchargeRateNameEnum.ExternalService]
            case AccountsReceivableLedgerTypeEnum.Other:
                return dictionary[SurchargeRateNameEnum.Other]
            case AccountsReceivableLedgerTypeEnum.MaterialArl:
                return dictionary[SurchargeRateNameEnum.Material]
        }
        throw 'getSurchargeRateByArlType for arlType ' + props.arlType + ' not implemented';
    }
    return undefined;
});
export const getSurchargeRateFetched = createSelector(selectState, selectSurchargeRateFetched);
