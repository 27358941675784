<section class="wrapper">
    <header class="header">
        <fa-icon class="icon" icon="file-contract"></fa-icon>
        <div class="dark-theme title">
            <h3 class="heading">{{ cls.getMultiple$('Commission') | async }}</h3>
        </div>
        <span class="spacer" *ngIf="!searchVisible"></span>
        <mat-form-field appearance="standard" *ngIf="searchVisible" class="search-form" (click)="$event.stopPropagation()">
            <!--            <mat-label>Suche</mat-label>-->
            <input matInput type="search" placeholder="Suche" (keydown.Space)="$event.stopImmediatePropagation()" [formControl]="searchForm" #searchInput />
            <button *ngIf="searchForm.value" mat-icon-button aria-label="Clear" matSuffix (click)="searchForm.setValue('')">
                <fa-icon icon="times" class="clear-search-suffix"></fa-icon>
            </button>
        </mat-form-field>
        <button app-round-button [Active]="searchVisible" matTooltip="Suche" aria-label="Suche" (click)="onSearchBtnClick()"><fa-icon icon="search"></fa-icon></button>
    </header>

    <div class="wrapper">
        <mat-tab-group class="tab-group">
            <mat-tab *ngFor="let tab of Tabs$ | async">
                <ng-template mat-tab-label
                    >{{ tab.label }} &nbsp; <span *ngIf="(tab.dataSource$ | async)?.filteredData as data"> <ng-container *ngIf="data">({{ data.length }})</ng-container></span></ng-template
                >
                <ng-container [ngTemplateOutlet]="tableTemplate" [ngTemplateOutletContext]="{ dataSource$: tab.dataSource$, displayedColumns: tab.displayedColumns }"></ng-container>
            </mat-tab>
        </mat-tab-group>
    </div>
</section>
<ng-template let-dataSource$="dataSource$" let-displayedColumns="displayedColumns" #tableTemplate>
    <ng-container *ngIf="entitiesFetched$ | async; else loading">
        <div class="clickable-list-container scrollbar" *ngIf="(dataSource$ | async)?.data.length > 0">
            <table mat-table [dataSource]="dataSource$ | async">
                <ng-container *ngFor="let column of Columns.filter(FilterCustomColumns)" [matColumnDef]="column.name">
                    <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
                    <td mat-cell *matCellDef="let rowData">
                        <div>
                            {{ rowData[column.name] }}
                        </div>
                    </td>
                    <td mat-cell *matCellDef="let rowData"></td>
                </ng-container>

                <ng-container *ngFor="let column of Columns.filter(FilterDateColumns)" [matColumnDef]="column.name">
                    <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
                    <td mat-cell *matCellDef="let rowData">
                        <div>
                            {{ rowData[column.name] | date }}
                        </div>
                    </td>
                    <td mat-cell *matCellDef="let rowData"></td>
                </ng-container>

                <ng-container matColumnDef="chevron">
                    <th mat-header-cell *matHeaderCellDef style="width: 30px"></th>
                    <td mat-cell *matCellDef="let rowData">
                        <div class="table-cell-wrapper">
                            <fa-icon icon="chevron-right"></fa-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let rowData; columns: displayedColumns"
                    [routerLink]="rowData.RouterLink"
                    routerLinkActive="active"
                    class="app-clickable-background"
                    [style]="'--app-clickable-background-color: ' + rowData.backgroundColor"
                    [matTooltip]="rowData.tooltip"
                ></tr>
            </table>
        </div>
        <table *ngIf="!((dataSource$ | async)?.data.length > 0)">
            <tr>
                <td class="empty-table-text">Keine {{ cls.getMultiple$('Commission') | async }} vorhanden</td>
            </tr>
        </table>
    </ng-container>
</ng-template>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
