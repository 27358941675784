import { Component,Inject,OnDestroy,OnInit } from '@angular/core';
import { FormControl,FormGroup,UntypedFormBuilder,UntypedFormControl,Validators } from '@angular/forms';
import { MatDialog,MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import moment,{ Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject,combineLatest,merge,Observable,of,Subject,Subscription } from 'rxjs';
import { distinctUntilChanged,filter,first,map,shareReplay,skip,switchMap,take,takeUntil,tap,withLatestFrom } from 'rxjs/operators';
import { CommissionEntity } from '../../../../dave-data-module/entities/commission.entity';
import { EmployeeToVacationEntity,VacationTypeEnum } from '../../../../dave-data-module/entities/employee-to-vacation.entity';
import { EntityRoleEntity,EntityTypesEnum } from '../../../../dave-data-module/entities/entity-role.entity';
import { UserToCommissionEntity } from '../../../../dave-data-module/entities/user-to-commission.entity';
import { UserEntity } from '../../../../dave-data-module/entities/user.entity';
import { User2CommissionShiftEntity } from '../../../../dave-data-module/entities/user2CommissionShift.entity';
import { EmployeeResolver } from '../../../../dave-data-module/guards/employee.resolver';
import { EntityRoleResolver } from '../../../../dave-data-module/guards/entity-role.resolver';
import { User2CommissionShiftResolver } from '../../../../dave-data-module/guards/user-to-commission-shift.resolver';
import { UserResolver } from '../../../../dave-data-module/guards/user.resolver';
import { FrontendDate } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../../dave-data-module/State';
import { CommissionActionTypes } from '../../../../dave-data-module/State/actions/commission.actions';
import { UserToCommissionActionTypes } from '../../../../dave-data-module/State/actions/user-to-commission.action';
import { UserToCommissionShiftActionTypes } from '../../../../dave-data-module/State/actions/user2CommissionShift.action';
import { getCommissionById,getCommissions,getCommissionsActive } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeToVacations } from '../../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployees,getEmployeesFetched } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole } from '../../../../dave-data-module/State/selectors/entity-role.selector';
import { getUserToCommissions } from '../../../../dave-data-module/State/selectors/user-to-commission.selector';
import { getUserToCommissionShifts } from '../../../../dave-data-module/State/selectors/users-to-commission-shift.selectors';
import { getUser,getUsersActive } from '../../../../dave-data-module/State/selectors/users.selectors';
import { AppDialogService } from '../../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData } from '../../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { InfoDialogComponent,InfoDialogData } from '../../../../dave-utils-module/dave-shared-components-module/components/dialogs/info-dialog/info-dialog.component';
import { BreakpointObserverService } from '../../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { SelectSearchData } from '../../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { isNotNullOrUndefined,sameDay,uniqArray } from '../../../../helper/helper';
import { AllCommissionMeta,CommissionMeta,NewEmployeeMeta,UserAdministrationMeta } from '../../../../helper/page-metadata';
import { getErrorMessage } from '../../../../helper/validation.helper';
import { CustomLabelService } from '../../../../services/custom-label.service';

interface DisplayUser {
    vacation: EmployeeToVacationEntity[];
    absent: EmployeeToVacationEntity[];
    illness: EmployeeToVacationEntity[];
    User: UserEntity;
    commissions: { commission: CommissionEntity; user2commissionShifts: User2CommissionShiftEntity[] }[];
    e2v: EmployeeToVacationEntity[];
    user2commissionShifts: User2CommissionShiftEntity[];
    ItsMe: boolean;
    roles$: Observable<string[]>;
    formData$: Observable<{
        rolesForm: FormControlTyped<EntityRoleEntity[]>;
        isAssignedForm: FormControlTyped<boolean>;
        roleTemplateData: IDetailListTemplateData;
    }>;
}

export interface SelectUserCommissionLegacyComponentDialogData {
    commissionId: number;
    onlyOneUser?: number;
    preFilledStartDate?: Date;
    preFilledEndDate?: Date;
}

@Component({
    selector: 'app-select-user-commission-legacy',
    templateUrl: './select-user-commission-legacy.component.html',
    styleUrls: ['./select-user-commission-legacy.component.scss'],
})
export class SelectUserCommissionLegacyComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        width: '600px',
        maxWidth: '98vw',
        autoFocus: false,
        panelClass: ['custom-dialog-class-mobile-fullscreen'],
    };
    public GetError = getErrorMessage;
    public DisplayUsers$: Observable<DisplayUser[]>;

    private onDestroy$ = new Subject<void>();
    public CommissionsLink = [CommissionMeta.Path, AllCommissionMeta.Path];
    public ExpandedPanelUserId: number;

    public TimespanFormDefaultValue: {
        StartDate: Moment;
        EndDate: Moment;
    };

    public timespanForm = new FormGroup({
        StartDate: new FormControl<Moment>(moment(), Validators.required),
        EndDate: new FormControl<Moment>(moment(), Validators.required),
        StartTimeString: new FormControl<string>(moment().get('hour') + ':' + moment().get('minute'), {
            validators: Validators.required,
            updateOn: 'blur',
        }),
        EndTimeString: new FormControl<string>(moment().get('hour') + ':' + moment().get('minute'), {
            validators: Validators.required,
            updateOn: 'blur',
        }),
    });

    public u2csTimespanForm = new FormGroup({
        StartDate: new FormControl<Moment>(moment(), Validators.required),
        EndDate: new FormControl<Moment>(moment(), Validators.required),
        StartTimeString: new FormControl<string>(moment().get('hour') + ':' + moment().get('minute'), {
            validators: Validators.required,
            updateOn: 'blur',
        }),
        EndTimeString: new FormControl<string>(moment().get('hour') + ':' + moment().get('minute'), {
            validators: Validators.required,
            updateOn: 'blur',
        }),
    });

    public Commission$: Observable<CommissionEntity>;
    public ShowCollissions = new BehaviorSubject<boolean>(true);
    public Commissions$: Observable<SelectSearchData[]> = this.store.select(getCommissionsActive).pipe(map((commissions) => commissions?.map((c) => ({ Name: c.DisplayName, Id: c.Id }))));
    public CommissionForm: FormControlTyped<SelectSearchData> = new UntypedFormControl(null, Validators.required);
    private userToCommissions$: Observable<UserToCommissionEntity[]>;
    private subs: Subscription[] = [];

    constructor(
        public DialogRef: MatDialogRef<SelectUserCommissionLegacyComponent>,
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Data: SelectUserCommissionLegacyComponentDialogData,
        private dialog: MatDialog,
        private appDialog: AppDialogService,
        private fb: UntypedFormBuilder,
        public BS: BreakpointObserverService,
        usersResolver: UserResolver,
        usersCSResolver: User2CommissionShiftResolver,
        employeeResolver: EmployeeResolver,
        entityRoleResolver: EntityRoleResolver,
        private apiToasterService: ToastrService,
        protected cls: CustomLabelService,
    ) {
        usersCSResolver.resolve();
        usersResolver.resolve();
        entityRoleResolver.resolve();
        this.store
            .select(getEmployeesFetched)
            .pipe(first())
            .subscribe((fetched) => {
                if (!fetched) {
                    employeeResolver.resolve();
                }
            });
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
        this.onDestroy$.next();
    }

    ngOnInit(): void {
        this.Commission$ = merge(of({ Id: this.Data.commissionId }), this.CommissionForm.valueChanges).pipe(
            filter(({ Id }) => isNotNullOrUndefined(Id)),
            switchMap(({ Id }) => this.store.select(getCommissionById({ id: Id }))),
            filter(isNotNullOrUndefined),
            distinctUntilChanged(
                (a, b) =>
                    JSON.stringify({
                        Id: a.Id,
                        StartDate: a.StartDate,
                        PlannedStartDate: a.PlannedStartDate,
                        EndDate: a.EndDate,
                        PlannedEndDate: a.PlannedEndDate,
                    }) ===
                    JSON.stringify({
                        Id: b.Id,
                        StartDate: b.StartDate,
                        PlannedStartDate: b.PlannedStartDate,
                        EndDate: b.EndDate,
                        PlannedEndDate: b.PlannedEndDate,
                    }),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.userToCommissions$ = combineLatest([this.store.select(getUserToCommissions), this.Commission$]).pipe(
            map(([u2cs, commission]) => u2cs.filter((u2c) => u2c.CommissionId === commission.Id)),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        this.subs.push(
            this.Commission$.pipe().subscribe((commission) => {
                this.TimespanFormDefaultValue = {
                    StartDate: moment(this.Data.preFilledStartDate || commission.StartDate.getDate() || commission.PlannedStartDate || new Date()),
                    EndDate: moment(this.Data.preFilledEndDate || commission.EndDate.getDate() || commission.PlannedEndDate || null),
                };
                this.ResetFormControls();
            }),
        );
        this.DisplayUsers$ = combineLatest([
            this.store.select(getUsersActive).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getUser),
            this.store.select(getUserToCommissionShifts).pipe(
                filter(isNotNullOrUndefined),
                switchMap((u2cs) => this.Commission$.pipe(map((c) => u2cs.filter((u) => u.CommissionId === c.Id && u.DeletedAt === null)))),
            ),
            this.Commission$,
            this.ShowCollissions,
        ]).pipe(
            tap(([users, user, u2cs, commission, col]) => {
                if (!users || users.filter((u) => u.PartnerId === commission.PartnerId).length < 1) {
                    this.dialog
                        .open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
                            data: {
                                heading: 'Keine Mitarbeiter Vorhanden',
                                paragraph:
                                    'Es sind keine Mitarbeiter vorhanden die ausgewählt werden könnten. Bitte legen sie zuerst welche an. Dafür gehen Sie in "Personal & Urlaub" und legen einen neuen Mitarbeiter an. Im Anschluss weisen Sie diesem Mitarbeiter eine Lizenz zu.',
                                routingButtonRoute: '/' + UserAdministrationMeta.Path + '/' + NewEmployeeMeta.Path,
                                routingButtonText: 'Hier geht es zu "Personal & Urlaub"',
                            },
                            maxWidth: '50rem',
                        })
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe(() => this.DialogRef.close());
                }
            }),
            withLatestFrom(this.store.select(getEmployeeToVacations), this.store.select(getUserToCommissionShifts), this.store.select(getCommissions), this.store.select(getEntityRole), this.store.select(getEmployees)),
            map(([[users, user, u2cs, commission, col], e2v, allu2cs, commissions, er, employees]) => {
                return users
                    .filter((u) => u.PartnerId === commission.PartnerId)
                    .filter((u) => !this.Data.onlyOneUser || this.Data.onlyOneUser === u.Id)
                    .map<DisplayUser>((u) => {
                        const commissionStartDate = commission.StartDate ? commission.StartDate : commission.PlannedStartDate;
                        const commissionEndDate = commission.EndDate ? commission.EndDate : commission.PlannedEndDate;
                        const user2CommissionShifts = allu2cs?.filter((u2c) => {
                            return (
                                u2c.CommissionId !== commission.Id &&
                                u2c.UserId === u.Id &&
                                ((u2c.StartDate <= commissionEndDate && u2c.StartDate >= commissionStartDate) ||
                                    (u2c.EndDate <= commissionEndDate && u2c.EndDate >= commissionStartDate) ||
                                    (u2c.StartDate <= commissionEndDate && u2c.EndDate >= commissionEndDate) ||
                                    (u2c.StartDate <= commissionStartDate && u2c.EndDate >= commissionStartDate))
                            );
                        });

                        const commissions2: {
                            commission: CommissionEntity;
                            user2commissionShifts: User2CommissionShiftEntity[];
                        }[] = [];
                        user2CommissionShifts.forEach((u2c) => {
                            const i = commissions2.findIndex((c) => c.commission.Id === u2c.CommissionId);
                            if (i >= 0) {
                                commissions2[i] = {
                                    commission: commissions2[i].commission,
                                    user2commissionShifts: [...commissions2[i].user2commissionShifts, u2c],
                                };
                            } else {
                                commissions2.push({
                                    commission: commissions.find((c) => c.Id === u2c.CommissionId),
                                    user2commissionShifts: [u2c],
                                });
                            }
                        });
                        const employee = employees.find((e) => e.UserId === u.Id);
                        return {
                            user2commissionShifts: u2cs.filter((u2c) => u2c.UserId === u.Id),
                            User: u,
                            vacation: e2v?.filter((e) => {
                                if (e.Type === VacationTypeEnum.Vacation) {
                                    return (
                                        e.EmployeeId === employee?.Id &&
                                        ((e.StartDate <= commissionEndDate && e.StartDate >= commissionStartDate) ||
                                            (e.EndDate <= commissionEndDate && e.EndDate >= commissionStartDate) ||
                                            (e.StartDate <= commissionEndDate && e.EndDate >= commissionEndDate) ||
                                            (e.StartDate <= commissionStartDate && e.EndDate >= commissionStartDate))
                                    );
                                }
                            }),
                            absent: e2v?.filter((e) => {
                                if (e.Type === VacationTypeEnum.Absent) {
                                    return (
                                        e.EmployeeId === employee?.Id &&
                                        ((e.StartDate <= commissionEndDate && e.StartDate >= commissionStartDate) ||
                                            (e.EndDate <= commissionEndDate && e.EndDate >= commissionStartDate) ||
                                            (e.StartDate <= commissionEndDate && e.EndDate >= commissionEndDate) ||
                                            (e.StartDate <= commissionStartDate && e.EndDate >= commissionStartDate))
                                    );
                                }
                            }),
                            illness: e2v?.filter((e) => {
                                if (e.Type === VacationTypeEnum.Illness) {
                                    return (
                                        e.EmployeeId === employee?.Id &&
                                        ((e.StartDate <= commissionEndDate && e.StartDate >= commissionStartDate) ||
                                            (e.EndDate <= commissionEndDate && e.EndDate >= commissionStartDate) ||
                                            (e.StartDate <= commissionEndDate && e.EndDate >= commissionEndDate) ||
                                            (e.StartDate <= commissionStartDate && e.EndDate >= commissionStartDate))
                                    );
                                }
                            }),
                            illnesswithoutcontinuedpay: e2v?.filter((e) => {
                                if (e.Type === VacationTypeEnum.Illnesswithoutcontinuedpay) {
                                    return (
                                        e.EmployeeId === employee?.Id &&
                                        ((e.StartDate <= commissionEndDate && e.StartDate >= commissionStartDate) ||
                                            (e.EndDate <= commissionEndDate && e.EndDate >= commissionStartDate) ||
                                            (e.StartDate <= commissionEndDate && e.EndDate >= commissionEndDate) ||
                                            (e.StartDate <= commissionStartDate && e.EndDate >= commissionStartDate)
                                        )
                                    )
                                }
                            }),
                            commissions: commissions2,
                            e2v,
                            ItsMe: u.Id === user.Id,
                            roles$: this.store.select(getUserToCommissions).pipe(map((u2c) => u2c.filter((u2) => u2.UserId === u.Id && commission.Id === u2.CommissionId).map((item) => er?.find((x) => x?.Id === item?.RoleId)?.DisplayName))),
                            formData$: this.store.select(getUserToCommissions).pipe(
                                map((u2c) => {
                                    const rolesForm: FormControlTyped<EntityRoleEntity[]> = new UntypedFormControl(
                                        u2c
                                            .filter((u2) => u2.UserId === u.Id && commission.Id === u2.CommissionId)
                                            .map((item) => er?.find((x) => x?.Id === item?.RoleId))
                                            .filter(isNotNullOrUndefined),
                                    );
                                    const isAssignedForm: FormControlTyped<boolean> = new UntypedFormControl(u2c.some((u2) => u2.UserId === u.Id && commission.Id === u2.CommissionId && u2.Assignee));

                                    rolesForm.valueChanges
                                        .pipe(
                                            takeUntil(this.onDestroy$),
                                            skip(1),
                                            distinctUntilChanged((a, b) => JSON.stringify(a.map((v) => v.Id).sort()) === JSON.stringify(b.map((v) => v.Id).sort())),
                                        )
                                        .subscribe((v) => {
                                            this.SaveRoles(u.Id, v);
                                        });
                                    isAssignedForm.valueChanges.pipe(takeUntil(this.onDestroy$), distinctUntilChanged()).subscribe((v) => {
                                        combineLatest([this.store.select(getCommissionById({ id: commission.Id })), this.userToCommissions$])
                                            .pipe(take(1))
                                            .subscribe(([c, _u2cs]) => {
                                                // const u2csByUser = _u2cs.filter(u2c => u2c.UserId === u.Id);
                                                // console.log({u2csByUser})
                                                // if (v) {
                                                //     this.store.dispatch(UserToCommissionActionTypes.Change({Payload: {id: 1, }}))
                                                // }
                                                const oldAssignees = _u2cs.filter((_u2c) => _u2c.Assignee).map((_u2c) => _u2c.UserId);
                                                const oldUsers = _u2cs.filter((_u2c) => !_u2c.Assignee).map((_u2c) => _u2c.UserId);
                                                this.store.dispatch(
                                                    CommissionActionTypes.SetCommissionUser({
                                                        Payload: {
                                                            CommissionId: c.Id,
                                                            UserIds: v ? uniqArray(oldUsers.filter((i) => i !== u.Id)) : uniqArray([...oldUsers, u.Id]),
                                                            AssigneeUserIds: v ? uniqArray([...oldAssignees, u.Id]) : uniqArray(oldAssignees.filter((i) => i !== u.Id)),
                                                        },
                                                    }),
                                                );
                                            });
                                    });
                                    return {
                                        rolesForm,
                                        isAssignedForm,
                                        roleTemplateData: {
                                            Properties: [
                                                {
                                                    key: 'Rolle',
                                                    formControl: rolesForm,
                                                    options: {
                                                        specialInput: {
                                                            chipAutocomplete: {
                                                                MapFn: (option: EntityRoleEntity) => option.DisplayName,
                                                                Options: er.filter((role) => role.Entity === EntityTypesEnum.Commission),
                                                                initialPatchDefaultValue: true,
                                                            },
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                    };
                                }),
                            ),
                        };
                    })
                    .filter((u) => !u.User.Deleted)
                    .filter((u) => {
                        if (!col) {
                            return u.commissions.length < 1 && u.vacation.length < 1;
                        } else {
                            return true;
                        }
                    });
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    public RemoveUser(userId: number) {
        combineLatest([this.Commission$, this.userToCommissions$])
            .pipe(take(1))
            .subscribe(([commission, u2cs]) => {
                const oldAssignees = u2cs.filter((u2c) => u2c.Assignee).map((u2c) => u2c.UserId);
                const oldUsers = u2cs.filter((u2c) => !u2c.Assignee).map((u2c) => u2c.UserId);
                this.store.dispatch(
                    CommissionActionTypes.SetCommissionUser({
                        Payload: {
                            CommissionId: commission.Id,
                            UserIds: uniqArray(oldUsers.filter((i) => i !== userId)),
                            AssigneeUserIds: uniqArray(oldAssignees.filter((i) => i !== userId)),
                        },
                    }),
                );
            });
    }

    public Close() {
        this.DialogRef.close();
    }

    public OpenU2CsChangTimeDialog(u2cs: User2CommissionShiftEntity) {
        const { StartDate, EndDate, StartTimeString, EndTimeString } = this.timespanForm.value;

        const eventStartDate = StartDate?.toDate();
        const tempEndDate = EndDate?.toDate();

        if (eventStartDate.getDate() === tempEndDate.getDate()) {
            const eventEndDate = new Date(tempEndDate.getFullYear(), tempEndDate.getMonth(), tempEndDate.getDate(), +EndTimeString.split(':')[0], +EndTimeString.split(':')[1], tempEndDate.getSeconds(), tempEndDate.getMilliseconds());
            eventStartDate.setHours(+StartTimeString.split(':')[0]);
            eventStartDate.setMinutes(+StartTimeString.split(':')[1]);
            eventStartDate.setSeconds(0);
            eventStartDate.setMilliseconds(0);

            this.Commission$.pipe(take(1)).subscribe((c) =>
                this.store.dispatch(
                    UserToCommissionShiftActionTypes.ChangeUser2CommissionShift({
                        Payload: {
                            id: u2cs.Id,
                            startDate: FrontendDate(eventStartDate),
                            endDate: FrontendDate(eventEndDate),
                        },
                    }),
                ),
            );
        } else {
            eventStartDate.setHours(8, 0, 0, 0);
            tempEndDate.setHours(17, 0, 0, 0);
            this.Commission$.pipe(take(1)).subscribe((c) =>
                this.store.dispatch(
                    UserToCommissionShiftActionTypes.ChangeUser2CommissionShift({
                        Payload: {
                            id: u2cs.Id,
                            startDate: FrontendDate(eventStartDate),
                            endDate: FrontendDate(tempEndDate),
                        },
                    }),
                ),
            );
        }
    }

    public DeleteU2C(user2cId: number) {
        if (user2cId) {
            this.appDialog
                .OpenConfirmationDialog({
                    paragraph: `Soll der Eintrag wirklich gelöscht werden?`,
                    styleDelete: true,
                })
                .pipe(take(1))
                .subscribe(([res]) => {
                    if (res) {
                        this.store.dispatch(
                            UserToCommissionShiftActionTypes.DeleteOne({
                                Payload: user2cId,
                            }),
                        );
                    }
                });
        } else {
            return;
        }
    }

    public SetTimespanFormValues(u2cs: User2CommissionShiftEntity) {
        this.timespanForm.controls.StartDate.setValue(u2cs.StartDate ? moment(u2cs.StartDate.getTime()) : moment());
        this.timespanForm.controls.EndDate.setValue(u2cs.EndDate ? moment(u2cs.EndDate.getTime()) : moment());
        const startTime = moment(u2cs.StartDate.getTime());
        const formattedTime = startTime.format('HH:mm');
        this.timespanForm.controls.StartTimeString.setValue(formattedTime);
        const endTime = moment(u2cs.EndDate.getTime());
        const formattedEndTime = endTime.format('HH:mm');
        this.timespanForm.controls.EndTimeString.setValue(formattedEndTime);
    }

    public AddU2CS(userId: number) {
        const { StartDate, EndDate, StartTimeString, EndTimeString } = this.u2csTimespanForm.value;

        const eventStartDate = StartDate?.toDate();
        const tempEndDate = EndDate?.toDate();

            const eventEndDate = new Date(tempEndDate.getFullYear(), tempEndDate.getMonth(), tempEndDate.getDate(), +EndTimeString.split(':')[0], +EndTimeString.split(':')[1], tempEndDate.getSeconds(), tempEndDate.getMilliseconds());
            eventStartDate.setHours(+StartTimeString.split(':')[0]);
            eventStartDate.setMinutes(+StartTimeString.split(':')[1]);
            eventStartDate.setSeconds(0);
            eventStartDate.setMilliseconds(0);

            this.Commission$.pipe(take(1)).subscribe((c) =>
                this.store.dispatch(
                    UserToCommissionShiftActionTypes.AddUser2CommissionShift({
                        Payload: {
                            userId,
                            commissionId: c.Id,
                            startDate: FrontendDate(eventStartDate),
                            endDate: FrontendDate(eventEndDate),
                        },
                    }),
                ),
            );
    }

    public ResetFormControls() {

        const eventStartDate = this.TimespanFormDefaultValue.StartDate?.toDate();
        const tempEndDate = this.TimespanFormDefaultValue.EndDate?.toDate();
        const startTime = moment(eventStartDate.getTime());
        const endTime = moment(tempEndDate.getTime());
        if (eventStartDate.getDate() !==  tempEndDate.getDate()){
            this.u2csTimespanForm.reset({
                StartDate: moment(this.TimespanFormDefaultValue.StartDate),
                EndDate: moment(this.TimespanFormDefaultValue.StartDate),
                StartTimeString: '08:00',
                EndTimeString: '17:00',
            });
        }
        else {
            this.u2csTimespanForm.reset({
                StartDate: moment(this.TimespanFormDefaultValue.StartDate),
                EndDate: moment(this.TimespanFormDefaultValue.EndDate),
                StartTimeString: startTime.format('HH:mm'),
                EndTimeString: endTime.format('HH:mm'),
            });
        }
    }

    public SaveRoles(userId: number, rolls: EntityRoleEntity[]) {
        combineLatest([this.userToCommissions$, this.Commission$])
            .pipe(take(1))
            .subscribe(([u2cs, c]) => {
                const old = u2cs.filter((u2c) => u2c.UserId === userId);
                const add = rolls.filter((role) => !old.some((u2c) => u2c.RoleId === role.Id));
                const remove = old.filter((u2c) => !rolls.some((role) => u2c.RoleId === role.Id));
                remove.forEach((u2c) => this.store.dispatch(UserToCommissionActionTypes.Delete({ Payload: { id: u2c.Id } })));
                add.forEach((role) =>
                    this.store.dispatch(
                        UserToCommissionActionTypes.Create({
                            Payload: {
                                userId,
                                commissionId: c.Id,
                                roleId: role.Id,
                            },
                        }),
                    ),
                );
                this.apiToasterService.success('Speichern erfolgreich!');
            });
    }

    protected readonly sameDay = sameDay;
}
