import { GetTimeFromTimestamp } from '@dave/types';
import { Process, ProcessPropertyConfig } from '@dave/types/dist/proto/process/process';
import { ProcessStepEntity, ProcessStepEntityFromBackend } from './process-step.entity';
import { ProcessTemplateEntity } from './process-template.entity';
import { UserToEntityEntity, UserToEntityEntityFromBackend } from './user-to-entity.entity';
import {
    SelectSearchOption
} from "../../dave-utils-module/select-search/components/select-search/select-search.component";
export enum ProcessState {
    ProcessStateNew = "ProcessStateNew",
    ProcessStateInProgress = "ProcessStateInProgress",
    ProcessStateDone = "ProcessStateDone",
    ProcessStateCanceled = "ProcessStateCanceled",
    ProcessStateArchived = "ProcessStateArchived"
}
const notReadyStates = [ProcessState.ProcessStateInProgress, ProcessState.ProcessStateNew];

export const ProcessStateColorClassMap: Map<ProcessState, string> = new Map<ProcessState, string>([
    [ProcessState.ProcessStateDone, 'app-color-approved'],
    [ProcessState.ProcessStateArchived, 'app-color-approved'],
]);
export const ProcessStateNames = new Map<ProcessState, string>([
    [ProcessState.ProcessStateArchived, 'Archiviert'],
    [ProcessState.ProcessStateCanceled, 'Abgebrochen'],
    [ProcessState.ProcessStateDone, 'Erledigt'],
    [ProcessState.ProcessStateInProgress, 'In Bearbeitung'],
    [ProcessState.ProcessStateNew, 'Neu'],
]);
export class ProcessEntity implements SelectSearchOption {
    constructor(
        public Id: number = null,
        public PartnerId: number = null,
        public StartDate: Date = null,
        public EndDate: Date = null,
        public Steps: ProcessStepEntity[] = null,
        public State: ProcessState = null,
        public EmailIds: number[] = null,
        public Description: string = null,
        public Description2: string = null,
        public CommissionId: number = null,
        public CustomerId: number = null,
        public DocumentId: number = null,
        public PersonId: number = null,
        public ActiveStep: number = null,
        public CustomFields: string = null,
        public Users: UserToEntityEntity[] = null,
        public AdditionalData: string = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public ProcessTemplateId: number = null,
        public Name: string = null,
        public PropertyConfig: ProcessPropertyConfig[] = null,
    ) {}
    public static EntityName = 'Prozess';
    public static EntityPropertyNames: Map<keyof ProcessEntity, string> = new Map([
        ['Name', 'Prozessname'],
        ['State', 'Status'],
        ['CustomerId', 'Kunde'],
        ['CommissionId', 'Auftrag'],
        ['ProcessTemplateId', ProcessTemplateEntity.EntityName],
        ['Description', 'Beschreibung'],
        ['Description2', 'Notiz'],
        ['StartDate', 'Start'],
        ['EndDate', 'Frist'],
        ['ActiveStep', 'Prozessschritt'],
        ['CreatedAt', 'Erstellt Am'],
        ['Steps', 'Prozessschritte'],
    ]);
    public get DisplayName(): string {
        return this.Name;
    }
    public get IsActive() {
        return notReadyStates.includes(this.State);
    }
    public get optionLabel(): string {
        return this.DisplayName;
    }
    public Clone(override: Partial<ProcessEntity> = {}): ProcessEntity {
        const attr = { ...this, ...override };
        return Object.assign(new ProcessEntity(), attr);
    }
    public GetBackgroundColorClass() {
        return notReadyStates.includes(this.State) && this.EndDate <= new Date() ? 'app-color-late' : ProcessStateColorClassMap.get(this.State);
    }
}
export function ProcessEntityFromBackend(res: Process): ProcessEntity {
    if (!res.ProcessTemplateId || !+res.ProcessTemplateId) {
        throw Error('invalid process entity: ' + res?.Id)
    }
    return new ProcessEntity(
        res.Id && +res.Id,
        res.PartnerId && +res.PartnerId,
        res.StartDate && GetTimeFromTimestamp(+res.StartDate),
        res.EndDate && GetTimeFromTimestamp(+res.EndDate),
        res.Steps && res.Steps.map(ProcessStepEntityFromBackend),
        res.State,
        res.EmailIds && res.EmailIds.map((id) => +id),
        res.Description,
        res.Description2,
        res.CommissionId && +res.CommissionId,
        res.CustomerId && +res.CustomerId,
        res.DocumentId && +res.DocumentId,
        res.PersonId && +res.PersonId,
        res.ActiveStep && +res.ActiveStep,
        res.CustomFields,
        res.Users && res.Users.map(UserToEntityEntityFromBackend),
        res.AdditionalData,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
        res.ProcessTemplateId && +res.ProcessTemplateId,
        res.Name,
        res.PropertyConfig,
    );
}
