<h1 mat-dialog-title>Neuen Bericht anlegen</h1>
<mat-dialog-content class="wrapper">
    <app-dave-list-card
        class="list-card"
        Headline="Vorlage auswählen"
        [Data]="LITemplatesTableData$"
        [DisplayedCollums]="['ConsolidatedInvoiceId', 'DisplayType', 'DisplayCreatedAt']"
        [TableHeaderCollumsMap]="{ DisplayType: 'Berichtart', ConsolidatedInvoiceId: 'Bezeichnung', DisplayCreatedAt: 'Erstellt' }"
        (EntryClicked$)="SelectedTemplate$.next($event.entry.NoTemplate ? null : $event.entry); SelectedDocumentType$.next($event.entry.NoTemplate ? SelectedDocumentType$.value : $event.entry.DocumentType)"
        [Clickable]="true"
        [Selectable]="true"
        [SelectedIndex]="0"
        [HeaderIcon]="ReportsIcon"
        [HasSuffixButton]="true"
    >
        <fa-icon headerButton icon="question-circle" size="2x" style="margin: 0.5rem" [matTooltip]="'Vorlagen können im Editor erstellt werden. Wählen Sie zunächst eine Berichtart aus.'"></fa-icon>
    </app-dave-list-card>
    <h3>Berichtart auswählen</h3>
    <mat-form-field>
        <mat-label>Berichtart auswählen</mat-label>
        <mat-select [value]="SelectedDocumentType$.value" (valueChange)="SelectedDocumentType$.next($event)">
            <!--                    <mat-option [value]="''">Leeres Dokument</mat-option>-->
            <ng-container *ngIf="(HasAccountingRole$ | async) && (HasAccountingLicense$ | async)">
                <mat-option *ngFor="let type of LedgerImportDocumentTypes" [value]="type">
                    {{ LedgerImportDocumentTypeNames.get(type) }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button app-button mat-dialog-close Inverted Color="cancel">Abbrechen</button>
    <button app-button Color="green" (click)="SelectedTemplate$.value ? CreateDocumentFromTemplate(SelectedTemplate$.value) : CreateDocumentFromType$.next(SelectedDocumentType$.value)" [IsLoading]="LS.IsLoading$ | async">Speichern</button>
</mat-dialog-actions>
