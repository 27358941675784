import { DataSource } from '@angular/cdk/collections';
import { formatNumber, getCurrencySymbol } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import {
    CalcAccountsReceivableLedgerAmount,
    CalcAccountsReceivableLedgerTaxAmount,
    NettoCost,
} from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getAccountsReceivableLedgerById, getAccountsReceivableLedgersFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getLedgerImportById, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { compareEqualityAsJsonString, isNotNullOrUndefined, sortByDate } from '../../../helper/helper';
import { ArlFormDataService, arlTableDataArlForm } from '../arl-calculation/arl-form-data.service';

export interface SimpleArlTableItem {
    form: arlTableDataArlForm;
    // InvoiceDate?: Observable<Date>;
    Amount$: Observable<number>;
    Tax$: Observable<number>;
    expanded$: Observable<boolean>;
    arlId: number;
    currencyCode: string;
    currencySymbol: string;
}
export type simpleArlTableItemAvailableSortKeys = 'Information' | 'AlreadyPayed' | 'InvoiceDate' | 'BaseCost' | 'Tax' | 'Amount';

/**
 * Data source for the SimpleArlTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class SimpleArlTableDataSource extends DataSource<SimpleArlTableItem> {
    expandedData$ = new BehaviorSubject<null | number>(null);
    paginator: MatPaginator | undefined;
    sort: MatSort | undefined;
    data$: Observable<SimpleArlTableItem[]> = this.arlIds$.pipe(
        distinctUntilChanged((a, b) => a?.join('-') === b?.join('-')),
        switchMap((arlIds) => {
            if (arlIds && arlIds.length > 0) {
                return combineLatest(
                    arlIds.map((arlId) =>
                        getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerById({ id: arlId })).pipe(
                            map((arl) => {
                                if (!arl || arl.DeletedAt) {
                                    console.error('arl is deleted');
                                    return undefined;
                                }
                                if (!this.arlFormDataService.getForm(arl.Id)) {
                                    this.arlFormDataService.setFormByEntity(arl);
                                }
                                const form = this.arlFormDataService.getForm(arl.Id);

                                return <SimpleArlTableItem>{
                                    form,
                                    // InvoiceDate$: getFetched$(this.store, getLedgerImportsFetched, getLedgerImportById({ id: ledgerImportId })).pipe(map((li) => li?.ConsolidatedDate)),
                                    Amount$: form.valueChanges.pipe(
                                        startWith(null),
                                        map((values) => calcAmount(form.getRawValue())),
                                        distinctUntilChanged(),
                                    ),
                                    Tax$: form.valueChanges.pipe(
                                        startWith(null),
                                        map((values) => calcTaxAmount(form.getRawValue())),
                                        distinctUntilChanged(),
                                    ),
                                    arlId: arl.Id,
                                    expanded$: this.expandedData$.pipe(map((expandedId) => expandedId === arl.Id)),
                                    currencyCode: arl.CurrencyCode,
                                    currencySymbol: getCurrencySymbol(arl.CurrencyCode, 'narrow', this.locale),
                                };
                            }),
                        ),
                    ),
                ).pipe(
                    // gelöschten raus filtern
                    map((arls) => arls.filter(isNotNullOrUndefined)),
                    );
            }
            return of([] as SimpleArlTableItem[]);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(private store: Store<State>, private locale: string, private arlFormDataService: ArlFormDataService, private arlIds$: Observable<number[]>) {
        super();
    }

    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    connect(): Observable<SimpleArlTableItem[]> {
        if (this.paginator && this.sort) {
            // Combine everything that affects the rendered data into one update
            // stream for the data-table to consume.
            return this.data$.pipe(
                switchMap((data) =>
                    this.sort.sortChange.pipe(
                        startWith(null),
                        map(() => this.getSortedData([...data])),
                    ),
                ),
                tap(() => {
                    this.paginator.firstPage();
                    // this.paginator.getNumberOfPages();
                }),
                switchMap((data) =>
                    this.paginator.page.pipe(
                        startWith(null),
                        map(() => this.getPagedData(data)),
                    ),
                ),
            );
        } else {
            throw Error('Please set the paginator and sort on the data source before connecting.');
        }
    }

    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    disconnect(): void {
        this.expandRowToggle(null);
    }

    expandRowToggle(data: SimpleArlTableItem | null): void {
        const newVal = data?.arlId || null;
        this.expandedData$.next(this.expandedData$.value === newVal ? null : newVal);
    }
    expandRow(data: SimpleArlTableItem) {
        const newVal = data?.arlId || null;
        if (this.expandedData$.value !== newVal) {
            this.expandedData$.next(newVal);
        }
    }
    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getPagedData(data: SimpleArlTableItem[]): SimpleArlTableItem[] {
        if (this.paginator) {
            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            return data.splice(startIndex, this.paginator.pageSize);
        } else {
            return data;
        }
    }

    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getSortedData(data: SimpleArlTableItem[]): SimpleArlTableItem[] {
        if (!this.sort || !this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort?.direction === 'asc';
            switch (this.sort?.active as simpleArlTableItemAvailableSortKeys) {
                case 'Information':
                    return compare((a.form.value.Information || '').toLowerCase(), (b.form.value.Information || '').toLowerCase(), isAsc);
                // case 'BusinessVolumeSum': return compare(a.BusinessVolumeSum, a.BusinessVolumeSum, isAsc);
                case 'InvoiceDate':
                    return sortByDate(a.form.value.Date?.toDate(), b.form.value.Date?.toDate(), isAsc);
                case 'BaseCost':
                    return compare(a.form.value.BaseCost, b.form.value.BaseCost, isAsc);
                case 'Tax':
                    return compare(a.form.value.Tax, b.form.value.Tax, isAsc);
                case 'Amount':
                    return compare(calcAmount(a.form.getRawValue()), calcAmount(b.form.getRawValue()), isAsc);
                default:
                    return 0;
            }
        });
    }
    public getAmountSumForArls$(arlIds: number[]) {
        const getVals$ = () => {
            const forms = arlIds.map(id => ({id, form: this.arlFormDataService.getForm(id)}))
            if (forms.some(f => !f.form)) {
                console.error('form not found')
            }
            if (forms.some(f => !f.form) || forms.length === 0) {
                return of([] as  {id: number; Quantity: number; BaseCost: number; Tax: number}[]);
            }
            return combineLatest(forms.map(f => f.form.valueChanges.pipe(
                startWith(null),
                map(() => {
                    const calues = f.form.getRawValue()
                    return {id: f.id, Quantity: calues.Quantity, BaseCost: calues.BaseCost, Tax: calues.Tax, };
                }),
                distinctUntilChanged(compareEqualityAsJsonString),
            )))
        }
        return this.data$.pipe(switchMap(() => getVals$().pipe(
            map((formValues) => {
                if (!formValues.length) {
                    return {
                        netto: '0,00',
                        brutto: '0,00',
                        tax: '0,00',
                        netto_number: null,
                        brutto_number: null,
                        tax_number: null,
                    }
                }
                const arlDict = {};
                formValues.forEach((curr) => arlDict[curr.id] = curr)
                // calculateConsolidatedAmount(arlDict, formValues.map(f => f.id), this.InfoiceFormGroup.getRawValue().DiscountPercentage)
                const brutto = formValues.reduce((prev, curr) => prev + CalcAccountsReceivableLedgerAmount(curr.Quantity, curr.Tax, curr.BaseCost), 0);
                const tax = formValues.reduce((prev, curr) => prev + CalcAccountsReceivableLedgerTaxAmount(curr.Quantity, curr.Tax, curr.BaseCost), 0);
                const netto = formValues.reduce((prev, curr) => prev + NettoCost(curr.Quantity, curr.BaseCost), 0);

                return {
                    netto: formatNumber(netto, this.locale, '1.2-2'),
                    brutto: formatNumber(brutto, this.locale, '1.2-2'),
                    tax: formatNumber(tax, this.locale, '1.2-2') + ' (' + formatNumber(parseFloat(((tax/netto)*100).toFixed(4)), this.locale, '1.0-2')+' %)',
                    netto_number: netto,
                    brutto_number: brutto,
                    tax_number: tax,
                };
            }),
        )));
    }
}
function calcAmount({ BaseCost, Tax, Quantity }: { BaseCost: number; Tax: number; Quantity: number }) {
    return CalcAccountsReceivableLedgerAmount(Quantity, Tax, BaseCost);
}

function calcTaxAmount({ BaseCost, Tax, Quantity }: { BaseCost: number; Tax: number; Quantity: number }) {
    return CalcAccountsReceivableLedgerTaxAmount(Quantity, Tax, BaseCost);
}
/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
