export const getLatestUpdatedAt = (entities: Array<{ UpdatedAt: Date }>, startValue?: Date): Date => {
    if (entities.length || startValue) {
        return entities.reduce((prev, curr) => (prev ? (curr.UpdatedAt > prev ? curr.UpdatedAt : prev) : curr.UpdatedAt), startValue);
    } else {
        return new Date(0); // fix for resolver when there are no entities
    }
};

export const getLatestCreatedAt = (entities: Array<{ CreatedAt: Date }>, startValue?: Date): Date => {
    if (entities.length || startValue) {
        return entities.reduce((prev, curr) => (prev ? (curr.CreatedAt > prev ? curr.CreatedAt : prev) : curr.CreatedAt), startValue);
    } else {
        return new Date(0); // fix for resolver when there are no entities
    }
};
