<button mat-button class="wrapper-button" [class.active]="active" [disabled]="disabled">
    <div class="step-wrapper step-state-{{state}}">
        <div>{{label}}</div>
        <div class="image-wrapper">
            <ng-container [ngSwitch]="state">
                <fa-icon *ngSwitchCase="'edit'" icon="pencil-alt"></fa-icon>
                <fa-icon *ngSwitchCase="'done'" icon="check"></fa-icon>
                <fa-icon *ngSwitchCase="'error'" icon="exclamation"></fa-icon>
                <div *ngSwitchCase="'number'">{{index + 1}}</div>
                <div *ngSwitchDefault>{{state}}</div>
            </ng-container>
        </div>
    </div>
</button>
