import { Injectable } from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { props } from '@ngrx/store';

interface sortProps extends Sort {

}
const DEFAULT_DIRECTION: SortDirection = '';
const LOCAL_STORAGE_KEY = 'default_table_sorting';
interface ISortCache {
    commissionList: sortProps;
    invoiceList: sortProps;
};
@Injectable({
    providedIn: 'root',
})
export class DefaultTableSortingService {
    private sortCache: Partial<ISortCache>;

    constructor() {
        this.initSortCache();
    }
    private initSortCache() {
        const storage = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storage) {
            try {
                this.sortCache = JSON.parse(storage);
            } catch (e) {
                this.sortCache = {};
            }
        } else {
            this.sortCache = {};
        }
    }
    submitSortCache() {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.sortCache))
    }
    getSort(key: keyof ISortCache): sortProps {
        const cache = this.sortCache[key];
        return cache || {direction: DEFAULT_DIRECTION, active: ''}
    }
    setSort(key: keyof ISortCache, props: sortProps) {
        this.sortCache[key] = props;
        this.submitSortCache();
    }
}
