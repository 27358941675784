import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { LoginService } from '../../../services/login.service';
import { WeatherHttpService } from '../../../services/weather-http.service';
import { iconCodes } from '../../../../libs/open-weather-map/open-weather-map-to-weather-icons';
import { PartnerOfficeEntity } from '../../../dave-data-module/entities/partnerOffice.entity';
import { WeatherEntity } from '../../../dave-data-module/entities/weather.entity';
import { State } from '../../../dave-data-module/State';
import { getPartnerMainOffice } from '../../../dave-data-module/State/selectors/partners.selectors';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';

interface WeatherResponse {
    weather: [{ id: number; description: string }];
    main: { temp: number };
    name: string;
}

@Component({
    selector: 'app-weather',
    templateUrl: './weather.component.html',
    styleUrls: ['./weather.component.scss'],
})
export class WeatherComponent implements OnInit, OnDestroy {
    @Input() Clock = true;

    private partnerOffice$: Observable<PartnerOfficeEntity>;
    private subscriptions: Subscription[] = [];
    private partnerOffice: PartnerOfficeEntity;

    private geolocationSupported = true;
    private interval: number;

    public CurrentWeather: WeatherEntity;
    public Now: string;
    public Mobile = false;

    constructor(
        private weatherHttpService: WeatherHttpService,
        private store: Store<State>,
        private breakpointObserver: BreakpointObserverService,
    ) {
        this.partnerOffice$ = this.store.select(getPartnerMainOffice);
    }

    ngOnInit() {
        this.subscriptions.push(
            this.breakpointObserver.MobileQuery.subscribe(value => (this.Mobile = value)),
            this.partnerOffice$.subscribe(value => {
                this.partnerOffice = value;
                console.log(value)
                if (this.geolocationSupported && value.City) {
                    this.callApi(null, null, value.City);
                }
            }),
        );
        this.interval = window.setInterval(() => {
            const time = new Date();
            this.Now =
                ('0' + time.getHours()).substr(-2) +
                ('0' + time.getMinutes()).substr(-2) +
                ('0' + time.getSeconds()).substr(-2);
        }, 1000);
        // this.getCurrentWeather();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(value => value.unsubscribe());
        clearInterval(this.interval);
    }

    // private getCurrentWeather() {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             position => {
    //                 this.geolocationSupported = false;
    //                 this.callApi(position.coords.longitude, position.coords.latitude);
    //             },
    //             () => (this.geolocationSupported = true),
    //         );
    //     } else {
    //         console.warn('No support for geolocation');
    //         this.geolocationSupported = true;
    //     }
    // }

    private callApi(Longitude: number, Latitude: number, city: string = null) {
        let url = '';
        if (!Longitude || !Latitude) {
            const countryCode = 'de';
            url = `data/2.5/weather?q=${city},${countryCode}&units=metric&lang=de&APPID=84ff67896b8e733e9e744f1cdddc9386`;
        } else {
            url = `data/2.5/weather?lat=${Latitude}&lon=${Longitude}&units=metric&lang=de&APPID=84ff67896b8e733e9e744f1cdddc9386`;
        }
        return this.weatherHttpService.Jsonp(url, 'callback').subscribe((value: WeatherResponse) => {
            this.CurrentWeather = new WeatherEntity(
                value.weather[0].description,
                this.getWeatherImageUrl(value),
                !Longitude || !Latitude ? this.partnerOffice.City : value.name,
                value.main.temp,
            );
        });
    }

    private getWeatherImageUrl(resp: WeatherResponse) {
        const prefix = 'wi wi-';
        const code = resp.weather[0].id;
        let icon = iconCodes[code].icon;

        // If we are not in the ranges mentioned above, add a day/night prefix.
        if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
            icon = 'day-' + icon;
        }
        // Finally tack on the prefix.
        return prefix + icon;
    }

    public Round(num: number) {
        return (Math.ceil(num * 2) / 2).toFixed(1);
    }
}
