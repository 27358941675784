import { ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, CurrencyPipe, DatePipe, formatDate, KeyValuePipe, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, Inject, Input, Optional, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, switchMap } from 'rxjs';
import { filter, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { MaterialListResolver } from '../../../dave-data-module/guards/material-list.resolver';
import { MaterialResolver } from '../../../dave-data-module/guards/material.resolver';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getQuantityTypes, getQuantityTypesFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getMaterialLists, getMaterialListsFetched } from '../../../dave-data-module/State/selectors/material-list.selector';
import { getMaterialDictionary, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { getUserDictionary, getUsersFetched } from '../../../dave-data-module/State/selectors/users.selectors';
import { DaveDoubleIconModule } from '../../../dave-double-icon/dave-double-icon.module';
import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';
import { DaveListModule } from '../../../dave-list/dave-list.module';
import { DaveLoadingModule } from '../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { MaterialPageMeta, ResourcePageMeta } from '../../../helper/page-metadata';
import { ResourceMainDataPopupComponent, ResourceMainDataPopupComponentDialogData } from '../resource-main-data-popup/resource-main-data-popup.component';
import { SelectMaterialPopupComponent, SelectMaterialPopupComponentDialogData } from '../select-material-popup/select-material-popup.component';

interface MaterialListData {
    MaterialName?: string;
    MaterialId?: number;
    QuantityType?: string;
    Quantity?: number;
    CreatedAt?: string;
    Lieferant?: string;
    User?: string;
    Status?: string;
}

export interface MaterialListComponentDialogData {
    CommissionId: number;
}

@Component({
    selector: 'app-material-list',
    templateUrl: './material-list.component.html',
    styleUrls: ['./material-list.component.scss'],
    standalone: true,
    imports: [
        AppButtonModule,
        FontAwesomeModule,
        MatTabsModule,
        AsyncPipe,
        DaveDoubleIconModule,
        DaveListCardModule,
        MatTooltipModule,
        CurrencyPipe,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        DaveListModule,
        MatDialogModule,
        MatTableModule,
        NgIf,
        DaveLoadingModule,
        ScrollingModule,
        MatSortModule,
        NgForOf,
        TableVirtualScrollModule,
        DaveSharedComponentsModule,
        MatExpansionModule,
        DatePipe,
        KeyValuePipe,
        MatCardModule,
        NgTemplateOutlet,
        MatButtonModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatMenuModule,
    ],
})
export class MaterialListComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        panelClass: 'no-padding-dialog',
        width: '55rem',
        maxHeight: '90vh',
    };
    private commissionId$ = new BehaviorSubject<number | null>(null);
    @Input() set CommissionId(id: number) {
        this.commissionId$.next(id);
    }

    @Input() Expanded = true;
    @Input() Expandable = false;
    protected searchForm = new FormControl<string>('');
    protected searchVisible = false;
    @ViewChild('searchInput') searchInput: ElementRef;
    public MaterialColumns: Array<keyof MaterialListData> = ['MaterialName', 'Quantity', 'QuantityType', 'CreatedAt'];
    public visibility = { Lieferant: true, Status: true, User: true };

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public DialogData: MaterialListComponentDialogData,
        @Optional() protected dialogRef: MatDialogRef<MaterialListComponent>,
        private store: Store<State>,
        private dialog: MatDialog,
        quantityTypeResolver: QuantityTypeResolver,
        materialListResolver: MaterialListResolver,
        materialResolver: MaterialResolver,
    ) {
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then((fetched) => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getMaterialsFetched)).then((fetched) => {
            if (!fetched) {
                materialResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getMaterialListsFetched)).then((fetched) => {
            if (!fetched) {
                materialListResolver.resolve();
            }
        });
        if (DialogData) {
            this.CommissionId = DialogData.CommissionId;
        }
    }

    public scheduledMaterials$ = this.commissionId$.pipe(
        filter(isNotNullOrUndefined),
        switchMap((commissionId) =>
            combineLatest([
                //combineLatest([
                //     getFetched$(this.store, getLedgerImportsFetched, getLedgerImports).pipe(
                //         switchMap((ledgerImports) => {
                //             const filteredLedgerImports = ledgerImports.filter((l) => l.CommissionId === commissionId && l.DocumentType === LedgerImportDocumentTypes.Offer && l.Status === LedgerImportStatusEnum.AngebotAkzeptiert);
                //             if (!filteredLedgerImports?.length) {
                //                 return of([] as AccountsReceivableLedgerEntity[][]);
                //             }
                //             return combineLatest(filteredLedgerImports.map((li) => this.arlDataService.getArlsFromLedgerImport$(li.Id)));
                //         }),
                //         map((arls) => arls.flat().filter((arl) => arl.ResourceId && arl.Type === AccountsReceivableLedgerTypeEnum.ResourceArl)),
                //     ),
                //     getFetched$(this.store, getResourcesFetched, getResources),
                //     //getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary),
                // ]).pipe(
                //     map(([filteredArls, resources]) => {
                //         const validResourceIds = resources.filter((res) => filteredArls.some((arl) => arl.ResourceId === res.Id)).map((res) => res.Id);
                //         const ret = filteredArls.filter((arl) => validResourceIds.includes(arl.ResourceId));
                //
                //         return ret;
                //     }),
                // ),
                // getFetched$(this.store, getResourcesFetched, getResourceDictionary),
                this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
                //getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary),
                getFetched$(this.store, getUsersFetched, getUserDictionary),
                combineLatest([
                    this.store.select(getMaterialLists),
                    this.store.select(getMaterialDictionary),
                    // getFetched$(this.store, getResourceTypesFetched, getResourceTypeDictionary),
                ]).pipe(
                    map(([filteredSchedules, materials]) =>
                        filteredSchedules.filter((schedule) => {
                            if (schedule.CommissionId !== commissionId) {
                                return false;
                            }
                            const material = materials[schedule?.MaterialId] && !schedule.DeletedAt;
                            const materialType = material;
                            return materialType;
                        }),
                    ),
                ),
                this.store.select(getMaterialDictionary),
            ]),
        ),

        map(([quantityTypes, users, ScheduledMaterials, materialDic]) => {
            const groupedArls = (entries: MaterialListData[]) => {
                return entries.reduce((acc, entry) => {
                    const resourceName = entry.MaterialName;
                    if (!acc[resourceName]) {
                        acc[resourceName] = {
                            MaterialName: resourceName,
                            MaterialId: entry.MaterialId,
                            Quantity: 0,
                            QuantityType: entry.QuantityType,
                            CreatedAt: entry.CreatedAt,
                            Status: entry.Status,
                            User: entry.User,
                            // Lieferant: 'entry.Lieferant',
                        };
                    }
                    acc[resourceName].Quantity += entry.Quantity;

                    return acc;
                }, {});
            };
            // const materialArlsEntries = materialARLs.map((r) => {
            //     const resource = resources[r?.ResourceId];
            //     return {
            //         ResourceName: resource?.Name?.trim() || '',
            //         ResourceId: resource ? resource.Id : null,
            //         Quantity: r.Quantity ? (r.ParentId ? r.Quantity * arlDictionary[r.ParentId]?.Quantity : r.Quantity) : null,
            //         QuantityType: resource ? quantityTypes.find((q) => q.Id === resource.QuantityTypeId)?.Name : null,
            //         CreatedAt: formatDate(r.CreatedAt, 'shortDate', 'de-DE'),
            //         Status: r.Status,
            //         User: users[r.UserId]?.DisplayName,
            //         Lieferant: 'Lieferanten[r.LieferantId]',
            //     };
            // });

            const materialSchedulesEntries = ScheduledMaterials.map((r) => {
                const material = materialDic[r?.MaterialId];
                return {
                    MaterialName: material?.Name?.trim(),
                    MaterialId: material ? material.Id : null,
                    Quantity: r.Amount,
                    QuantityType: material ? quantityTypes.find((q) => q.Id === material.QuantityTypeId)?.Name : null,
                    CreatedAt: formatDate(r.CreatedAt, 'shortDate', 'de-DE'),
                    Status: r?.Status,
                    User: users[r.UserId]?.DisplayName,
                    //Lieferant: 'Lieferanten[r.LieferantId]',
                };
            });
            // const allEntries = [...materialSchedulesEntries , ...materialArlsEntries];

            const groupedEntries = groupedArls(materialSchedulesEntries);
            const dataSourceEntries = Object.values(groupedEntries);
            return new MatTableDataSource(dataSourceEntries);
        }),
        tap(
            (dataSource) =>
                (dataSource.sortingDataAccessor = (object, key) => {
                    switch (key) {
                        case 'ResourceName':
                            return object.ResourceName || '';
                        case 'CreatedAt':
                            return object.CreatedAt ? object.CreatedAt : null;
                        case 'Quantity':
                            return object[key] || null;
                        default:
                            return object[key] || '';
                    }
                }),
        ),
        switchMap((tableData: MatTableDataSource<MaterialListData>) =>
            this.searchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.searchForm.value || '').toLowerCase()),
                map((searchTerm) => {
                    if (searchTerm) {
                        const filteredData = tableData?.data?.filter((data) => data?.MaterialName?.trim().toLowerCase().includes(searchTerm));
                        return new MatTableDataSource(filteredData);
                    } else {
                        return tableData;
                    }
                }),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    toggleSearch() {
        this.searchVisible = !this.searchVisible;
        this.searchForm.setValue('');
        if (this.searchVisible) {
            setTimeout(() => {
                this.searchInput.nativeElement.focus();
            }, 0);
        }
    }
    entryClicked(row: MaterialListData) {
        this.dialog.open<ResourceMainDataPopupComponent, ResourceMainDataPopupComponentDialogData>(ResourceMainDataPopupComponent, {
            ...ResourceMainDataPopupComponent.DefaultConfig,
            data: {
                ResourceId: row ? row.MaterialId : null,
            },
        });
    }
    openDialog() {
        this.dialog.open<MaterialListComponent, MaterialListComponentDialogData>(MaterialListComponent, {
            ...MaterialListComponent.DefaultConfig,
            data: {
                CommissionId: this.commissionId$.value,
            },
        });
    }

    toggleColumn(column: keyof MaterialListData) {
        if (this.visibility[column]) {
            this.MaterialColumns.push(column);
        } else {
            this.MaterialColumns = this.MaterialColumns.filter((col) => col !== column);
        }
        this.visibility[column] = !this.visibility[column];
    }

    protected readonly ResourcePageMeta = ResourcePageMeta;
    protected readonly MaterialPageMeta = MaterialPageMeta;

    openNewPopup() {
        this.dialog.open<SelectMaterialPopupComponent, SelectMaterialPopupComponentDialogData>(SelectMaterialPopupComponent, {
            ...SelectMaterialPopupComponent.DefaultConfig,
            data: {
                CommissionId: this.commissionId$.value,
            },
        });
    }
}
