import { animate,state,style,transition,trigger } from '@angular/animations';
import { DatePipe,formatDate } from '@angular/common';
import { Component, Inject, Injector, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog,MatDialogConfig,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ResolveData } from '@angular/router';
import { Permissions } from "@dave/types/dist/proto/global/permissions";
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { isArray } from "chart.js/helpers";
import * as moment from 'moment/moment';
import { BehaviorSubject,combineLatest,firstValueFrom,Observable,of,switchMap,tap } from "rxjs";
import {
    distinctUntilChanged,
    filter, first,
    map,
    shareReplay,
    skip,
    startWith,
    take,
    takeUntil,
    withLatestFrom
} from "rxjs/operators";
import {
    getPartnerMainOffice,
    getPartnerOfficesFetched
} from "src/app/dave-data-module/State/selectors/partners.selectors";
import { EnumClockInTypeSlug } from '../../../dave-data-module/entities/clock-in-type.entity';
import { ClockInEntity,ClockInTypeNames,ClockInTypes } from '../../../dave-data-module/entities/clock-in.entity';
import { EmployeeToVacationEntity,VacationTypeEnumNameMap } from '../../../dave-data-module/entities/employee-to-vacation.entity';
import {
EmployeeStatusEnum,SalaryTypes
} from "../../../dave-data-module/entities/employee.entity";
import { WorkDayEntity } from '../../../dave-data-module/entities/work-day.entity';
import { ClockInTypeResolver } from '../../../dave-data-module/guards/clock-in-type.resolver';
import { ClockInResolver } from '../../../dave-data-module/guards/clock-in.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { EmployeeToVacationResolver } from '../../../dave-data-module/guards/employee-to-vacation.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { StatusFromBackofficeResolver } from "../../../dave-data-module/guards/statusFromBackoffice.resolver";
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { WorkDayResolver } from '../../../dave-data-module/guards/work-day.resolver';
import { WorkedTimesResolver } from '../../../dave-data-module/guards/worked-times.resolver';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { ClockInActionTypes } from '../../../dave-data-module/State/actions/clock-in.actions';
import { clockInTypesFeatureKey } from '../../../dave-data-module/State/reducers/clock-in-type.reducer';
import { clockInsFeatureKey } from '../../../dave-data-module/State/reducers/clock-in.reducer';
import { commissionsFeatureKey } from '../../../dave-data-module/State/reducers/commission.reducer';
import { employeesFeatureKey } from '../../../dave-data-module/State/reducers/employees.reducer';
import { employeeToVacationFeatureKey } from '../../../dave-data-module/State/reducers/employeeToVacation.reducer';
import { StatusFromBackofficeFeatureKey } from "../../../dave-data-module/State/reducers/statusFromBackoffice.reducer";
import { usersFeatureKey } from '../../../dave-data-module/State/reducers/users.reducer';
import { workDaysFeatureKey } from '../../../dave-data-module/State/reducers/work-day.reducer';
import { workedTimesFeatureKey } from '../../../dave-data-module/State/reducers/worked-times.reducer';
import { getClockInTypeDictionary,getClockInTypesFetched } from '../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getClockIns,getClockInsWithoutDeleted } from '../../../dave-data-module/State/selectors/clock-in.selectors';
import {
    getCommissionById,
    getCommissionDictionary,
    getCommissions,
    getCommissionsActive, getCommissionsFetched
} from "../../../dave-data-module/State/selectors/commission.selector";
import { getEmployeeToVacationDictionary } from '../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployeeById,getEmployeeDictionary,getEmployees } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getUser,getUserDictionary } from '../../../dave-data-module/State/selectors/users.selectors';
import { getWorkDays } from '../../../dave-data-module/State/selectors/work-day.selectors';
import { getWorkedTimes,getWorkedTimesFetched } from '../../../dave-data-module/State/selectors/worked-times.selectors';
import { AppConfirmationDialogComponent,AppConfirmationDialogData } from '../../../dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';
import {
FilterOption,FILTER_TYPE_SEARCH_MULTI_SELECT,IFilterTypeSearchMultiSelectValue
} from "../../../dave-utils-module/app-filter-module/app-filter/app-filter.component";
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import {
    appMatDialogDefaultConfig,
    isNotNullOrUndefined,
    sameDay, sortClockIns, sortByDateProperty,
    TableColumnConfig,
    uniqArray
} from "../../../helper/helper";
import { CommissionMeta,UserAdministrationMeta } from "../../../helper/page-metadata";
import { CustomLabelService } from '../../../services/custom-label.service';
import { FilterTypes } from '../../../services/default-filter.service';
import { LoadingService } from '../../../services/loading.service';
import { NewClockinPopupComponent,NewClockinPopupComponentDialogData } from '../new-clockin-popup/new-clockin-popup.component';
import { EmployeeSalaryTypeEntity } from "../../../dave-data-module/entities/employee-salary-type.entity";
import {
    getSalaryTypeDictionary,
    getSalaryTypeFetched,
    getSalaryTypesActive
} from "../../../dave-data-module/State/selectors/salary-type.selector";
import {
    SelectSearchOption
} from "../../../dave-utils-module/select-search/components/select-search/select-search.component";
import { EmployeeSalaryTypeResolver } from "../../../dave-data-module/guards/employee-salary-type.resolver";
import { PartnerOfficeResolver } from "../../../dave-data-module/guards/partner-office.resolver";
import { SalaryTypeResolver } from "../../../dave-data-module/guards/salary-type.resolver";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    getEmployeeSalaryTypeById,
    getEmployeeSalaryTypes
} from "../../../dave-data-module/State/selectors/employee-salary-type.selector";
import {
    getStatusFromBackofficeFetched
} from "../../../dave-data-module/State/selectors/statusFromBackoffice.selectors";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Moment } from "moment";
import { CommissionEntity } from "../../../dave-data-module/entities/commission.entity";
import {
    DetailListDialogReturn,
    DetailListTemplateDialogComponent, DetailListTemplateDialogData
} from "../../../detail-list-template-dialog/components/detail-list-template-dialog.component";
import { SalaryTypeEntity } from "../../../dave-data-module/entities/salary-type.entity";
import { SalaryTypeActions } from "../../../dave-data-module/State/actions/salary-type.actions";
import { EmployeeSalaryTypeActions } from "../../../dave-data-module/State/actions/employee-salary-type.actions";
import { FrontendDate } from "../../../dave-data-module/helper/backend-frontend-conversion.helper";
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import {
    getUserToCommissionByCommissionId,
    getUserToCommissionFetched,
} from '../../../dave-data-module/State/selectors/user-to-commission.selector';
import { UserToCommissionResolver } from '../../../dave-data-module/guards/user-to-commission.resolver';
interface HourListFilter {
    [FilterTypes.Employees]: IFilterTypeSearchMultiSelectValue<number>[];
    [FilterTypes.Commissions]: IFilterTypeSearchMultiSelectValue<number>[];
}

export interface HourListComponentDialogData {
    EmployeeId?: number;
    CommissionId?: number;
}

interface ClockInTableData {
    ClockInEntity: ClockInEntity;
    Type: string;
    TimeStamp: string;
    Commission: string;
    Note: string;
    DrivingTime?: string;
    CanEdit$: Observable<boolean>;
}

interface WorkDayTableData {
    EveryValid: boolean;
    TableData: MatTableDataSource<ClockInTableData>;
    DateData: Date;
    WorkDayIds: number[];
    Date: string;
    WorkTime: string;
    OverTime: string;
    BreakTime: string;
    ForcedBreakTime: string;
    DrivingTime: string;
    SomeExported: boolean;
    EmployeeListData$: Observable<EmployeeListTableData[]>;
    CanDeleteAll$: Observable<boolean>;
    IsHoliday: boolean;
    CommissionNames: string;
    EmployeeToVacations$: Observable<EmployeeToVacationEntity[]>;
    SalaryTypes$: Observable<{ salaryType: EmployeeSalaryTypeEntity; commissionLabel$: Observable<string> }[]>;
}
interface EmployeeListTableData {
    IsValid: boolean;
    ClockInTableData: MatTableDataSource<ClockInTableData>;
    DisplayName: string;
    WorkTime: string;
    WorkDayId: number;
    DrivingTime: string;
    EmployeeId: number;
    Date: Date;
    OtherClockInsExist?: boolean;
    Identifier: string;
}

interface PanelData {
    TableData: MatTableDataSource<WorkDayTableData>;
    WeekOfYear: number;
    WorkTimeSum: number;
    OverTimeSum: number;
    WeekAndYear: string;
    WeekStart?: string;
    WeekEnd?: string;
}

@Component({
    selector: 'app-hour-list',
    templateUrl: './hour-list.component.html',
    styleUrls: ['./hour-list.component.scss'],
    providers: [DatePipe],
    animations: [trigger('detailExpand', [state('collapsed', style({ height: '0px', minHeight: '0' })), state('expanded', style({ height: '*' })), transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))])],
})
export class HourListComponent implements OnInit {

    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '100%',
        width: '100rem',
        maxHeight: 'calc(100vh - 3.5rem)',
    };
    @ViewChild('selectSalaryTypeForm') selectSalaryTypeForm: TemplateRef<any>;
    public static readonly RequiredResolvers: ResolveData = {
        [clockInTypesFeatureKey]: ClockInTypeResolver,
        [workedTimesFeatureKey]: WorkedTimesResolver,
        [employeesFeatureKey]: EmployeeResolver,
        [employeeToVacationFeatureKey]: EmployeeToVacationResolver,
        [clockInsFeatureKey]: ClockInResolver,
        [workDaysFeatureKey]: WorkDayResolver,
        [commissionsFeatureKey]: CommissionResolver,
        [usersFeatureKey]: UserResolver,
        [StatusFromBackofficeFeatureKey]: StatusFromBackofficeResolver,
    };
    public GlobalClockInTypeId = 1;
    public ExpandedPanel: string;
    public ExpandedWorkDay: string;
    public ExpandedWorkDayFromEmployee: string;
    public ClockInColumns$: Observable<TableColumnConfig<ClockInTableData>[]> = this.cls.getSingle$('Commission').pipe(
        map((commissionLabel) => [
            { header: 'Art', name: 'Type' },
            { header: 'Uhrzeit', name: 'TimeStamp' },
            { header: commissionLabel, name: 'Commission' },
            { header: 'Anmerkung', name: 'Note' },
            { header: 'Fahrzeit', name: 'DrivingTime' },
        ]),
    );
    public WorkDayColumns: TableColumnConfig<WorkDayTableData>[] = [
        { header: 'Datum', name: 'Date' },
        { header: 'Arbeitsstunden', name: 'WorkTime' },
        { header: 'Überstunden', name: 'OverTime' },
        { header: 'Gesamtpausenzeit', name: 'BreakTime' },
        { header: 'Automatische Pausenzeit', name: 'ForcedBreakTime' },
        { header: 'Fahrzeit', name: 'DrivingTime' },
    ];
    public EmployeeListColumns: TableColumnConfig<EmployeeListTableData>[] = [
        { header: 'Mitarbeiter', name: 'DisplayName' },
        { header: 'Arbeitsstunden', name: 'WorkTime' },
        { header: 'Fahrzeit', name: 'DrivingTime' },
    ];
    private onInit$ = new BehaviorSubject<boolean>(false);
    public ShowCommissions$ = new BehaviorSubject(true);
    public ShowEmployeeSalaryTypesForEmployeeId$ = new BehaviorSubject<number | null>(null);
    public ShowSalaryTypes$ = this.store.select(getPartnerOfficesFetched).pipe(
        filter(f => f),
        switchMap(() => this.store.select(getPartnerMainOffice).pipe(map(po => !(po?.AdditionalData?.hideEmployeeSalaryType === true))))
    )
    public ShowOvertime$ = new BehaviorSubject(false);
    public ShowBreakTime$ = new BehaviorSubject(true);
    public showEmptyWorkDays$ = new BehaviorSubject(true);
    public DisplayedWorkDayColumns$ = combineLatest([this.BP.MobileQuery, this.onInit$, this.ShowOvertime$, this.ShowBreakTime$, this.ShowCommissions$]).pipe(
        map(([mq, , ShowOvertime, ShowBreakTime, showCommissions]) => {
            const ret: Array<keyof WorkDayTableData | 'icon' | 'delete'> = ['Date', 'WorkTime'];
            if (!mq) {
                if (ShowOvertime) {
                    ret.push('OverTime');
                }
                if (ShowBreakTime) {
                    ret.push('BreakTime', 'ForcedBreakTime');
                }
                ret.push('DrivingTime');
            }
            if (showCommissions) {
                ret.push('CommissionNames');
            }
            ret.push('icon', 'delete');
            return ret;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public DisplayedClockInColumns$ = this.BP.MobileQuery.pipe(map((mq) => (mq ? ['line', 'Type', 'TimeStamp', /*'DrivingTime', */ 'button'] : ['line', 'Type', 'TimeStamp', 'Commission', 'Note', /*'DrivingTime', */ 'button'])));
    public DisplayedEmployeeListColumns$ = this.BP.MobileQuery.pipe(map((mq) => (mq ? ['DisplayName', 'WorkTime', 'DrivingTime', 'icon'] : ['DisplayName', 'WorkTime', 'DrivingTime', 'icon'])));
    public PanelData: Observable<PanelData[]>;
    public ClockInTypes = ClockInTypes;
    // public Commission: CommissionEntity;
    // public CurrentWorkDay: BehaviorSubject<WorkDayTableData> = new BehaviorSubject(null);
    protected filterSettings$: Observable<FilterOption[]> = combineLatest([
        this.store.select(getCommissionsActive),
        this.store.select(getEmployees),
        this.cls.getMultiple$('Commission'),
    ]).pipe(
        tap(v => console.log({v})),
        filter(v => v.every(isNotNullOrUndefined)),
        map(([commissions, employees, commissionLabel]) => {
            let employeeValues: IFilterTypeSearchMultiSelectValue<number>[] = employees.map((c) => ({
                label: c.DisplayName,
                id: c.Id,
            }));
            let commissionValues: IFilterTypeSearchMultiSelectValue<number>[] = commissions.map((c) => ({
                label: c.DisplayName,
                id: c.Id,
            }));

            return [
                {
                    Name: FilterTypes.Commissions,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: commissionLabel,
                    Icon: CommissionMeta.Icon,
                    Values: commissionValues,
                },
                {
                    Name: FilterTypes.Employees,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Mitarbeiter',
                    Icon: UserAdministrationMeta.Icon,
                    Values: employeeValues,
                },
            ];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected filterValues$ = new BehaviorSubject<HourListFilter>({
        [FilterTypes.Employees]: [],
        [FilterTypes.Commissions]: [],
    });
    protected filterAmount$ = this.filterValues$.pipe(map(filter => Object.values(filter).reduce((prev, curr) => {
        if (curr) {
            if (isArray(curr)) {
                if (curr.length) {
                    return prev + 1;
                }
            } else {
                return prev + 1
            }
        }
        return prev;
    },0)))
    protected filteredCommissionIds$ = this.filterValues$.pipe(
        map((filter) => filter[FilterTypes.Commissions]?.map((c) => c.id)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );
    protected filteredEmployeeIds$ = this.filterValues$.pipe(
        map((filter) => filter[FilterTypes.Employees]?.map((e) => e.id)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );
    @Input() set ShowCommissions(value: boolean) {
        this.ShowCommissions$.next(value);
    }
    @Input() set ShowOvertime(value: boolean) {
        this.ShowOvertime$.next(value);
    }
    @Input() set ShowBreakTime(value: boolean) {
        this.ShowBreakTime$.next(value);
    }
    @Input() set ShowEmptyWorkDays(value: boolean) {
        this.showEmptyWorkDays$.next(value);
    }
    protected CompareById(a, b) {
        return a.Id === b.Id;
    }
    protected salaryTypeOptions$ = this.store.select(getSalaryTypesActive).pipe(
        map((salaryTypes) =>
            salaryTypes
                .sort(sortByDateProperty('CreatedAt'))
                .reverse()
                .map<SelectSearchOption>((p) => ({
                    Id: p.Id,
                    optionLabel: p.Name,
                })),
        ),
    );

    @Input() showEmployeeHierarchie = true;
    @Input() showFilter = true;

    @Input() ignoreSeePermissions = false;

    protected dataFetched = false;
    constructor(
        private store: Store<State>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA)
        Dialogdata: HourListComponentDialogData,
        private datePipe: DatePipe,
        public BP: BreakpointObserverService,
        public PS: PermissionService,
        public LS: LoadingService,
        private actions$: Actions,
        clockInTypeResolver: ClockInTypeResolver,
        workedTimesResolver: WorkedTimesResolver,
        employeeSalaryTypeResolver: EmployeeSalaryTypeResolver,
        poeResolver: PartnerOfficeResolver,
        u2cResolver: UserToCommissionResolver,
        private salaryTypeResolver: SalaryTypeResolver,
        private commissionResolver: CommissionResolver,
        private statusBackofficeResolver: StatusFromBackofficeResolver,
        protected cls: CustomLabelService,
        private injector: Injector,
    ) {
        poeResolver.resolve();
        clockInTypeResolver.resolve();
        workedTimesResolver.resolve();
        employeeSalaryTypeResolver.resolve();
        firstValueFrom(this.store.select(getUserToCommissionFetched)).then(f => {
            if (!f) {
                u2cResolver.resolve();
            }
        })
        firstValueFrom(combineLatest(Object.values(HourListComponent.RequiredResolvers).map((resolver) => this.injector.get(resolver).resolve())).pipe(filter((res) => res.every((f) => f)))).then(() => (this.dataFetched = true));
        if (Dialogdata) {
            this.ShowCommissions$.next(!Dialogdata.CommissionId);
            this.showEmptyWorkDays$.next(!Dialogdata.CommissionId);
            this.showEmployeeHierarchie = !Dialogdata.EmployeeId;
            this.ignoreSeePermissions = !! Dialogdata.CommissionId;
            if (Dialogdata.EmployeeId || Dialogdata.CommissionId) {
                this.showFilter = false;
                this.filterValues$.next({
                    ...this.filterValues$.value,
                    [FilterTypes.Commissions]: Dialogdata.CommissionId ? [{ id: Dialogdata.CommissionId, label: 'todo' }] : this.filterValues$.value[FilterTypes.Commissions],
                    [FilterTypes.Employees]: Dialogdata.EmployeeId ? [{ id: Dialogdata.EmployeeId, label: 'todo' }] : this.filterValues$.value[FilterTypes.Employees],
                });
            }
            this.ShowEmployeeSalaryTypesForEmployeeId$.next(Dialogdata.EmployeeId || null);
            if (!Dialogdata.EmployeeId) {
                this.ShowOvertime$.next(!Dialogdata.CommissionId);
                this.ShowBreakTime$.next(!Dialogdata.CommissionId);
            } else {
                firstValueFrom(this.store.select(getEmployeeById({ id: Dialogdata.EmployeeId }))).then((e) => this.ShowOvertime$.next(e.SalaryType != SalaryTypes.PerHour && !Dialogdata.CommissionId));
            }
        }
    }
    EmployeeSalaryTypeLabel = EmployeeSalaryTypeEntity.EntityName;
    EmployeeSalaryTypeIcon: IconProp = 'comments-dollar';
    public Identifyer = (index: number, item: PanelData) => item.WeekAndYear;

    // @ts-ignore
    public FilterCustomColumns = (c: TableColumnConfig<ClockInTableData | WorkDayTableData>) => c.name !== 'Note' && c.name !== 'CommissionNames';

    private sameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
    }

    ngOnInit() {
        // 'OverTime', 'BreakTime', muss in der commission ansicht raus da hier nicht alle clockins eines workdays angezeigt werden und mann die überstunden so nicht ausrechnen kann
        this.onInit$.next(true);
        // if (this.Dialogdata.CommissionId) {
        //     this.store
        //         .select(getCommissions)
        //         .pipe(concatMap(cl => cl.filter(c => c.Id === this.Dialogdata.CommissionId)))
        //         .subscribe(val => {
        //             this.Commission = val;
        //         });
        // }

        this.PanelData = combineLatest([
            this.store.select(getWorkDays).pipe(switchMap((wd) => this.filteredEmployeeIds$.pipe(map((employeeIds) => (employeeIds?.length ? wd?.filter((w) => employeeIds.includes(w.EmployeeId)) : wd))))),
            this.store.select(getClockInsWithoutDeleted).pipe(
                // map((cl) => cl.filter((c) => !this.Dialogdata.CommissionId || c.CommissionId === this.Dialogdata.CommissionId)),
                // map((cl) => (this.Dialogdata?.EmployeeId ? cl?.filter((c) => this.Dialogdata.EmployeeId === c.EmployeeId) : cl)),
                filter(isNotNullOrUndefined),
                switchMap((clockIns) =>
                    combineLatest([this.filteredEmployeeIds$, this.filteredCommissionIds$]).pipe(
                        map(([employeeIds, commissionIds]) => {
                            let filteredClockIns = clockIns.slice();
                            if (commissionIds?.length) {
                                filteredClockIns = filteredClockIns.filter((c) => commissionIds.includes(c.CommissionId));
                            }
                            if (employeeIds?.length) {
                                filteredClockIns = filteredClockIns.filter((c) => employeeIds.includes(c.EmployeeId));
                            }
                            return filteredClockIns;
                        }),
                    ),
                ),
            ),
            this.store.select(getCommissions).pipe(
                filter(isNotNullOrUndefined),
                switchMap((commissions) => this.filteredCommissionIds$.pipe(map((ids) => (ids?.length ? commissions.filter((c) => ids.includes(c.Id)) : commissions)))),
            ),
            this.store.select(getCommissionDictionary),
            this.store.select(getEmployeeDictionary),
            this.store.select(getClockInTypesFetched).pipe(
                filter((fetched) => !!fetched),
                switchMap(() => this.store.select(getClockInTypeDictionary)),
            ),
            this.store.select(getWorkedTimesFetched).pipe(
                filter((fetched) => !!fetched),
                switchMap(() => this.store.select(getWorkedTimes)),
            ),
            combineLatest([
                this.showEmptyWorkDays$,
                this.filterValues$.pipe(map(v => !v[FilterTypes.Commissions]?.length), distinctUntilChanged()),
            ]).pipe(
               map(([showEmpty, showEmptyByCommissionFilter]) => showEmpty && showEmptyByCommissionFilter)
            ),
            this.filteredCommissionIds$,
        ]).pipe(
            map(([allWorkDaysf, clockInsf, commissionsf, commissionsDictionary, employeesDict, clockInTypes, workedTimes, showEmptyWorkDays, filteredCommissionIds]) => {
                const drivingTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.drivingTime)?.Id;
                const globalTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.workTime)?.Id;
                const groups: {
                    [key: string]: {
                        workDays: WorkDayEntity[];
                        workDayClockIns: ClockInEntity[];
                        date: Date;
                    }[];
                } = allWorkDaysf
                    .filter(wd => this.ignoreSeePermissions || employeesDict[wd.EmployeeId]?.PermissionsFromEntityRoles.includes(Permissions.EditClockIn))
                    .reduce((acc: { [key: string]: { workDays: WorkDayEntity[]; workDayClockIns: ClockInEntity[]; date: Date }[] }, workDay) => {
                    const workDayClockIns = clockInsf.filter((c) => c.WorkDayId === workDay?.Id);
                    const date = workDay.Timestamp;
                    if (showEmptyWorkDays || workDayClockIns.length > 0) {
                        const yearWeek = `${moment(date).year()}-${moment(date).isoWeek()}`;
                        if (!acc[yearWeek]) {
                            acc[yearWeek] = [];
                        }
                        const workdayGroup = acc[yearWeek].find((g) => this.sameDay(date, g.date));

                        if (workdayGroup) {
                            workdayGroup.workDays.push(workDay);
                            workdayGroup.workDayClockIns.push(...workDayClockIns);
                        } else {
                            acc[yearWeek].push({ workDays: [workDay], workDayClockIns, date });
                        }
                    }
                    return acc;
                }, {});

                return Object.values(groups)
                    .sort((a, b) => {
                        return b[0].date.getTime() - a[0].date.getTime();
                    })
                    .map<PanelData>((g) => {
                        const date = new Date(g[0].date);
                        const momentDate = moment(date);
                        const year = momentDate.year();
                        const weekNumber = momentDate.isoWeek();
                        return {
                            WeekOfYear: g[0] ? moment(g[0].date).isoWeek() : null,
                            WeekAndYear: g[0] ? moment(g[0].date).year() + '-' + moment(g[0].date).isoWeek() : null,
                            WeekStart: moment().year(year).isoWeek(weekNumber).startOf('isoWeek').format('DD.MM.YYYY'),
                            WeekEnd:moment().year(year).isoWeek(weekNumber).endOf('isoWeek').format('DD.MM.YYYY'),
                            WorkTimeSum: g
                                .map((wd) => {
                                    return workedTimes
                                        .filter((w) =>
                                                wd.workDays.some((x) => x.Id === w.WorkDayId) &&
                                                clockInTypes[w.ClockInTypeId].IsWorkingTime &&
                                                (!filteredCommissionIds || filteredCommissionIds.length === 0 || filteredCommissionIds.includes(w.CommissionId)),
                                        )
                                        .reduce((previousValue, currentValue) => previousValue + (currentValue.Timespan || 0), 0);
                                })
                                .reduce((acc, wd) => acc + wd, 0),
                            OverTimeSum: g.map((wd) => wd.workDays.reduce((acc, wd) => acc + (wd.Overtime || 0), 0)).reduce((acc, wd) => acc + wd, 0),
                            TableData: new MatTableDataSource(
                                g
                                    .sort((b, a) => a.date.getTime() - b.date.getTime())
                                    .map<WorkDayTableData>((wd) => {
                                        const e2vIds = wd.workDays.reduce((prev, curr) => [...prev, ...curr.Employee2VacationIds], []);
                                        return {
                                            EveryValid: wd.workDays.every(w => w.IsValid || sameDay(w.Timestamp, new Date())),
                                            SalaryTypes$: this.ShowEmployeeSalaryTypesForEmployeeId$.pipe(
                                                switchMap(employeeId => employeeId ? this.store.select(getEmployeeSalaryTypes).pipe(map((salarys) =>
                                                    salarys.filter(s => s.EmployeeId === employeeId && sameDay(s.Date, wd.date))
                                                        .map((salaryType) => {
                                                            return { salaryType, commissionLabel$: salaryType.CommissionId && this.store.select(getCommissionById({ id: salaryType.CommissionId })).pipe(map((c) => c?.DisplayName)) };
                                                        }),
                                                )) : of(null)),
                                                  ),
                                            Date: this.datePipe.transform(wd.date),
                                            DateData: wd.date,
                                            IsHoliday: wd.workDays.some((w) => w.IsHoliday),
                                            EmployeeToVacations$: e2vIds.length ? this.store.select(getEmployeeToVacationDictionary).pipe(map((e2v) => e2vIds.map((id) => e2v[id]).filter(isNotNullOrUndefined))) : of([]),
                                            WorkTime: this.GetHourStringFromMilliseconds(
                                                workedTimes
                                                    .filter((w) =>
                                                            wd.workDays.some((x) => x.Id === w.WorkDayId) &&
                                                            clockInTypes[w.ClockInTypeId].IsWorkingTime &&
                                                            (!filteredCommissionIds || filteredCommissionIds.length === 0 || filteredCommissionIds.includes( w.CommissionId)),
                                                    )
                                                    .reduce((previousValue, currentValue) => previousValue + (currentValue.Timespan || 0), 0),
                                            ),
                                            OverTime: this.GetHourStringFromMilliseconds(wd.workDays.map((w) => w.Overtime || 0).reduce((acc, wd) => acc + wd, 0)),
                                            BreakTime: this.GetHourStringFromMilliseconds(wd.workDays.reduce((acc, w) => acc + w.BreakTime, 0)),
                                            ForcedBreakTime: this.GetHourStringFromMilliseconds(wd.workDays.reduce((acc, w) => acc + w.ForcedBreakTime, 0)),
                                            WorkDayIds: wd.workDays.map((w) => w.Id),
                                            CommissionNames: uniqArray(wd.workDayClockIns.map((c) => c.CommissionId).filter(isNotNullOrUndefined))
                                                .map((cId) => (commissionsDictionary[cId] ? commissionsDictionary[cId].Auftragsnummer || 'unbenannt' : 'unbekannt'))
                                                .join(', '),
                                            SomeExported: wd.workDayClockIns.some((c) => c.ExportedAt),
                                            EmployeeListData$: this.getEmployeeListData$(
                                                wd.workDays.map((w) => w.Id),
                                                wd.date,
                                                !!showEmptyWorkDays,
                                            ),
                                            CanDeleteAll$: this.store.select(getEmployeeDictionary).pipe(map(employees => uniqArray(wd.workDayClockIns.map(c => c.EmployeeId)).every(eId => employees[eId]?.PermissionsFromEntityRoles.includes(Permissions.DeleteClockIn)))),
                                            TableData: new MatTableDataSource(
                                                wd.workDayClockIns
                                                    .sort(sortClockIns(globalTypeId))
                                                    .map<ClockInTableData>((c) => {
                                                        const commission = commissionsf.find((commission) => commission.Id === c.CommissionId);
                                                        const forcedBreakTime = isNotNullOrUndefined(c.ForcedBreakTime) ? this.GetHourStringFromMilliseconds(c.ForcedBreakTime) : null;
                                                        const note =
                                                            isNotNullOrUndefined(c.ForcedBreakTime) && c.Type == this.ClockInTypes.Start && c.ForcedBreakTime > 0
                                                                ? (isNotNullOrUndefined(c.WorkDescription) ? c.WorkDescription + '\n\n' : '') + '*Es wurde eine Pausenzeit von ' + forcedBreakTime + ' automatisch abgezogen'
                                                                : c.WorkDescription;

                                                        return {
                                                            ClockInEntity: c,
                                                            Type: clockInTypes[c.ClockInTypeId].Name + (c.ClockInTypeId === this.GlobalClockInTypeId ? '' : ' - ' + ClockInTypeNames.get(c.Type)),
                                                            TimeStamp: moment(c.TimeStamp).format('HH:mm:ss'),
                                                            Commission: commission?.DisplayName,
                                                            Note: note,
                                                            CanEdit$: this.store.select(getEmployeeById({id: c.EmployeeId})).pipe(map(e => e?.PermissionsFromEntityRoles.includes(Permissions.EditClockIn)))
                                                            // DrivingTime: this.GetHourStringFromMilliseconds(wd.workDayClockIns.filter(c => c.Type === ClockInTypes.Start).reduce((acc, w) => acc + w.DrivingTime, 0))
                                                        };
                                                    }),
                                            ),
                                            DrivingTime:
                                                this.GetHourStringFromMilliseconds(workedTimes.filter((w) => wd.workDays.some((x) => x.Id === w.WorkDayId && w.ClockInTypeId === drivingTypeId)).reduce((acc, w) => acc + w.Timespan, 0)) || '',
                                        };
                                    }),
                            ),
                        };
                    });
            }),
            tap(panelData => console.log({panelData}))
        );
    }

    public EditingClockIn(clockInEntity: ClockInEntity) {
        this.dialog.open(NewClockinPopupComponent, {
            ...NewClockinPopupComponent.DefaultConfig,
            data: {
                New: false,
                ClockInEntity: clockInEntity,
                SelectDate: true,
                SelectDateEnd: false,
                Close: true,
            } as NewClockinPopupComponentDialogData,
        });
    }
    CreateEmployeeSalaryType(employeeId?: number, date?: Date) {
        console.log('CreateEmployeeSalaryType', employeeId, date)
        this.openEmployeeSalaryDialog(null, employeeId, date);
    }
    ChangeEmployeeSalaryType(id: number) {
        this.openEmployeeSalaryDialog(id);
    }
    async openEmployeeSalaryDialog(id?: number, employeeId?: number, date?: Date) {
        let employeeSalaryType: EmployeeSalaryTypeEntity | undefined;
        if (id) {
            employeeSalaryType = await firstValueFrom(this.store.select(getEmployeeSalaryTypeById({ id })));
        }
        if (id && !employeeSalaryType) {
            throw Error('employeeSalaryType ' + id + ' not found');
        }
        firstValueFrom(this.store.select(getSalaryTypeFetched)).then((fetched) => {
            if (!fetched) {
                this.salaryTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getCommissionsFetched)).then((fetched) => {
            if (!fetched) {
                this.commissionResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getStatusFromBackofficeFetched)).then((fetched) => {
            if (!fetched) {
                this.statusBackofficeResolver.resolve();
            }
        });
        const commissionLabel = await firstValueFrom(this.cls.getSingle$('Commission'))
        const commission = employeeSalaryType?.CommissionId ? await firstValueFrom(this.store.select(getCommissionsFetched).pipe(
            filter(f => f),
            switchMap(() => this.store.select(getCommissionById({id: employeeSalaryType.CommissionId})))
        )) : null
        const form = new FormGroup({
            name: new FormControl<string>(employeeSalaryType?.Name || null, [Validators.required]),
            date: new FormControl<Moment>(moment(date || employeeSalaryType?.Date || null), [Validators.required]),
            salaryType: new FormControl<{ Id: number }>(null),
            salaryTypeNumber: new FormControl<number>(employeeSalaryType?.SalaryTypeNumber || null),
            bs: new FormControl<number>(employeeSalaryType?.Bearbeitungsschluessel || null),
            amount: new FormControl<number>(employeeSalaryType?.Amount || null),
            percentAmount: new FormControl<number>(employeeSalaryType?.PercentAmountFromSalary || null),
            description: new FormControl<string>(employeeSalaryType?.Description || null),
            commission: new FormControl<CommissionEntity>(commission)
        });
        if (employeeSalaryType?.CommissionId && !commission) {
            // maybe commission is not available for this user
            form.controls.commission.disable();
        }
        const dialogRef = this.dialog.open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
            ...DetailListTemplateDialogComponent.DefaultConfig,
            data: {
                DisableSaveButton$: form.statusChanges.pipe(
                    startWith(form.status),
                    map((state) => state !== 'VALID'),
                ),
                Data: {
                    HeaderIcon: this.EmployeeSalaryTypeIcon,
                    Headline: this.EmployeeSalaryTypeLabel,
                    Properties: [
                        {
                            key: EmployeeSalaryTypeEntity.EntityPropertyNames.get('Date'),
                            formControl: form.controls.date,
                            options: {
                                specialInput: {
                                    date: true,
                                },
                            },
                        },
                        {
                            key: commissionLabel,
                            formControl: form.controls.commission,
                            options: {
                                specialInput: {
                                    singleSelectSearch: {
                                        options$: this.store.select(getCommissionsActive).pipe(
                                            filter(isNotNullOrUndefined),
                                            map(commissions => [commission, ...commissions.filter(c => c.Id !== commission?.Id)].filter(isNotNullOrUndefined))
                                        ),
                                        compareOptions: (a, b) => a?.Id === b?.Id
                                    }
                                },
                            },
                        },
                        {
                            key: SalaryTypeEntity.EntityName,
                            formControl: form.controls.salaryType,
                            options: {
                                specialInput: {
                                    customTemplate: this.selectSalaryTypeForm,
                                },
                            },
                        },
                        {
                            key: EmployeeSalaryTypeEntity.EntityPropertyNames.get('Name'),
                            formControl: form.controls.name,
                        },
                        {
                            key: EmployeeSalaryTypeEntity.EntityPropertyNames.get('SalaryTypeNumber'),
                            formControl: form.controls.salaryTypeNumber,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: EmployeeSalaryTypeEntity.EntityPropertyNames.get('Bearbeitungsschluessel'),
                            formControl: form.controls.bs,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: EmployeeSalaryTypeEntity.EntityPropertyNames.get('Amount'),
                            formControl: form.controls.amount,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                                suffix: '€',
                            },
                        },
                        {
                            key: EmployeeSalaryTypeEntity.EntityPropertyNames.get('PercentAmountFromSalary'),
                            formControl: form.controls.percentAmount,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                                suffix: '%',
                                showHint: 'Dieser prozentuale Wert wird dem errechneten Tageslohn aus Stunden und Stundenlohn in der Export Datei hinzugefügt',
                            },
                        },
                        {
                            key: EmployeeSalaryTypeEntity.EntityPropertyNames.get('Description'),
                            formControl: form.controls.description,
                            options: {
                                specialInput: {
                                    textArea: {
                                        Fill: true,
                                    },
                                },
                            },
                        },
                    ],
                },
                Editing: true,
                DeleteButton: !!id,
            },
        });

        this.actions$
            .pipe(
                takeUntil(dialogRef.beforeClosed()),
                ofType(SalaryTypeActions.add),
                switchMap(() => this.actions$.pipe(ofType(SalaryTypeActions.updateOne, BaseActionTypes.ErrorAction), first())),
            )
            .subscribe((action) => {
                if (action.type === SalaryTypeActions.updateOne.type) {
                    form.controls.salaryType.setValue({ Id: action.Payload.Id });
                }
            });
        form.controls.salaryType.valueChanges.pipe(takeUntil(dialogRef.beforeClosed()), withLatestFrom(this.store.select(getSalaryTypeDictionary))).subscribe(([value, salaryTypes]) => {
            if (value) {
                const type = salaryTypes[value.Id];
                if (type) {
                    form.setValue({
                        name: type.Name,
                        date: form.value.date,
                        salaryType: value,
                        salaryTypeNumber: type.SalaryTypeNumber,
                        bs: type.Bearbeitungsschluessel,
                        amount: type.Amount,
                        percentAmount: type.PercentAmountFromSalary,
                        description: type.Description,
                        commission: form.controls.commission.getRawValue(),
                    });
                }
            }
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res?.Action === 'delete') {
                this.store.dispatch(EmployeeSalaryTypeActions.delete({ Payload: { id } }));
            } else if (res?.Action === 'save') {
                if (id) {
                    this.store.dispatch(
                        EmployeeSalaryTypeActions.change({
                            Payload: {
                                id,
                                name: form.value.name,
                                amount: form.value.amount,
                                bearbeitungsschluessel: form.value.bs,
                                commissionId: form.controls.commission.getRawValue()?.Id || null,
                                date: FrontendDate(form.value.date.toDate()),
                                description: form.value.description,
                                percentAmountFromSalary: form.value.percentAmount,
                                salaryTypeNumber: form.value.salaryTypeNumber,
                            },
                        }),
                    );
                } else {
                    this.store.dispatch(
                        EmployeeSalaryTypeActions.add({
                            Payload: {
                                name: form.value.name,
                                amount: form.value.amount,
                                bearbeitungsschluessel: form.value.bs,
                                commissionId: form.controls.commission.getRawValue()?.Id || null,
                                employeeId,
                                date: FrontendDate(form.value.date.toDate()),
                                description: form.value.description,
                                percentAmountFromSalary: form.value.percentAmount,
                                salaryTypeNumber: form.value.salaryTypeNumber,
                            },
                        }),
                    );
                }
            }
        });
    }
    onAddSalaryTypeClick() {
        const form = new FormGroup({
            name: new FormControl<string>(null, [Validators.required]),
            salaryTypeNumber: new FormControl<number>(null),
            bs: new FormControl<number>(null),
            amount: new FormControl<number>(null),
            percentAmount: new FormControl<number>(null),
            description: new FormControl<string>(null),
        });
        const dialogRef = this.dialog.open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
            ...DetailListTemplateDialogComponent.DefaultConfig,
            data: {
                DisableSaveButton$: form.statusChanges.pipe(
                    startWith(form.status),
                    map((state) => state !== 'VALID'),
                ),
                Data: {
                    HeaderIcon: this.EmployeeSalaryTypeIcon,
                    Headline: 'Lohnart anlegen',
                    Properties: [
                        {
                            key: SalaryTypeEntity.EntityPropertyNames.get('Name'),
                            formControl: form.controls.name,
                        },
                        {
                            key: SalaryTypeEntity.EntityPropertyNames.get('SalaryTypeNumber'),
                            formControl: form.controls.salaryTypeNumber,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: SalaryTypeEntity.EntityPropertyNames.get('Bearbeitungsschluessel'),
                            formControl: form.controls.bs,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: SalaryTypeEntity.EntityPropertyNames.get('Amount'),
                            formControl: form.controls.amount,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: SalaryTypeEntity.EntityPropertyNames.get('PercentAmountFromSalary'),
                            formControl: form.controls.percentAmount,
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: SalaryTypeEntity.EntityPropertyNames.get('Description'),
                            formControl: form.controls.description,
                            options: {
                                specialInput: {
                                    textArea: {
                                        Fill: true,
                                    },
                                },
                            },
                        },
                    ],
                },
                Editing: true,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res?.Action === 'save') {
                this.store.dispatch(
                    SalaryTypeActions.add({
                        Payload: {
                            name: form.value.name,
                            amount: form.value.amount,
                            bearbeitungsschluessel: form.value.bs,
                            description: form.value.description,
                            percentAmountFromSalary: form.value.percentAmount,
                            salaryTypeNumber: form.value.salaryTypeNumber,
                        },
                    }),
                );
            }
        });
    }
    CreateClockIn(employeeId?: number, date?: Date) {
        firstValueFrom(combineLatest([this.filteredCommissionIds$, this.filteredEmployeeIds$])).then(([commissionIds, employeeIds]) => {
            if (employeeId || employeeIds?.length === 1) {
                this.dialog.open<NewClockinPopupComponent, NewClockinPopupComponentDialogData>(NewClockinPopupComponent, {
                    ...NewClockinPopupComponent.DefaultConfig,
                    data: {
                        EmployeeId: employeeId || employeeIds[0],
                        Date: date,
                        New: true,
                        SelectDate: true,
                        SelectDateEnd: true,
                        Close: true,
                        CommissionId: commissionIds?.length === 1 ? commissionIds[0] : null,
                        UsersOfCommission: [],
                    },
                });
            } else if (commissionIds?.length === 1) {
                combineLatest([this.store.select(getUser), this.store.select(getEmployees), this.store.select(getUserDictionary), this.store.select(getCommissionById({ id: commissionIds[0] })), getFetched$(this.store, getUserToCommissionFetched, getUserToCommissionByCommissionId({commissionId: commissionIds[0]}))])
                    .pipe(take(1))
                    .subscribe(([user, employees, users, commission, userToCommissions]) => {
                        const userIds = uniqArray(userToCommissions.map(u2c => u2c?.UserId).filter(isNotNullOrUndefined));
                        const availableEmployees = employees.filter((e) => e.UserId && userIds.includes(e.UserId) && e.EmployeeStatus === EmployeeStatusEnum.Aktiv);
                        this.dialog.open<NewClockinPopupComponent, NewClockinPopupComponentDialogData>(NewClockinPopupComponent, {
                            ...NewClockinPopupComponent.DefaultConfig,
                            data: {
                                EmployeeId: employees.find((e) => e.UserId === user.Id).Id,
                                Date: date,
                                New: true,
                                SelectDate: true,
                                SelectDateEnd: true,
                                Close: true,
                                CommissionId: commission.Id,
                                ShowEmployeeList: true,
                                UsersOfCommission: availableEmployees.map((e) => users[e.UserId]).filter(isNotNullOrUndefined),
                            },
                        });
                    });
            } else {
                combineLatest([this.store.select(getUser), this.store.select(getEmployees), this.store.select(getUserDictionary)])
                    .pipe(take(1))
                    .subscribe(([user, employees, users]) => {
                        const availableEmployees = employees.filter((e) => e.UserId && e.EmployeeStatus === EmployeeStatusEnum.Aktiv);

                        this.dialog.open<NewClockinPopupComponent, NewClockinPopupComponentDialogData>(NewClockinPopupComponent, {
                            ...NewClockinPopupComponent.DefaultConfig,
                            data: {
                                EmployeeId: employees.find((e) => e.UserId === user.Id).Id,
                                Date: date,
                                New: true,
                                SelectDate: true,
                                SelectDateEnd: true,
                                ShowEmployeeList: true,
                                UsersOfCommission: availableEmployees.map((e) => users[e.UserId]).filter(isNotNullOrUndefined),
                                Close: true,
                            },
                        });
                    });
            }
        });
    }

    DeleteClockIn(clockInId: number) {
        this.store.dispatch(
            ClockInActionTypes.DeleteClockIn({
                Payload: {
                    id: clockInId,
                },
            }),
        );
    }
    GetHourStringFromMilliseconds(ms: number): string {
        if (!ms) {
            return '';
        }
        const neg = ms < 0;
        const mSeconds = neg ? ms * -1 : ms;
        const hours = Math.trunc(mSeconds / (1000 * 60 * 60));
        const minutes = Math.round((mSeconds / (1000 * 60 * 60) - hours) * 60);
        return (neg ? '- ' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + 'h';
    }
    DeleteWorkDay(workDayId: number, event: any) {
        event.stopPropagation();
        const dialogRef = this.dialog.open<AppConfirmationDialogComponent, AppConfirmationDialogData, [boolean, string]>(AppConfirmationDialogComponent, {
            autoFocus: false,
            data: {
                paragraph: `Wollen Sie die Arbeitszeiten wirklich löschen?`,
                styleDelete: true,
            },
            maxWidth: '400px',
            width: '90%',
            disableClose: true,
        });
        dialogRef.backdropClick().subscribe(() => dialogRef.close([false, '']));
        dialogRef.componentInstance.SaveClick$.pipe(withLatestFrom(this.store.select(getClockIns))).subscribe(([, clockIns]) => {
            const clockinsToDelete = clockIns.filter((c) => c.WorkDayId === workDayId);
            if (clockinsToDelete.length) {
                dialogRef.componentInstance.IsLoading$.next(true);
                this.LS.startLoading('delete-clock-in');
                firstValueFrom(this.actions$.pipe(ofType(ClockInActionTypes.UpdateMany, BaseActionTypes.ErrorAction), skip(clockinsToDelete.length - 1))).then((action) => {
                    this.LS.endLoading('delete-clock-in');
                    dialogRef.componentInstance.IsLoading$.next(false);
                    if (action.type !== BaseActionTypes.ErrorAction.type) {
                        dialogRef.close();
                    }
                });
                clockinsToDelete.forEach((cl) => {
                    this.DeleteClockIn(cl.Id);
                });
            }
        });
    }
    getEmployeeListData$ = (WorkDayIds: number[], DateData: Date, sohwEmpty: boolean): Observable<EmployeeListTableData[]> =>
        combineLatest([
            this.store.select(getEmployeeDictionary),
            this.store.select(getClockInsWithoutDeleted).pipe(switchMap((cls) => this.filteredCommissionIds$.pipe(map((commissionIds) => (commissionIds?.length ? cls.filter((cl) => commissionIds.includes(cl.CommissionId)) : cls))))),
            this.store.select(getWorkDays).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getCommissions).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getClockInTypesFetched).pipe(
                filter((fetched) => !!fetched),
                switchMap(() => this.store.select(getClockInTypeDictionary)),
            ),
            this.store.select(getWorkedTimesFetched).pipe(
                filter((fetched) => !!fetched),
                switchMap(() => this.store.select(getWorkedTimes)),
            ),
        this.filteredCommissionIds$,]).pipe(
            map(([allEmployees, clockIns, workDays, commissions, clockInTypes, workedTimes, filteredCommissionIds]) => {
                const drivingTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.drivingTime)?.Id;
                const globalTypeId = Object.values(clockInTypes).find((ci) => ci.Slug === EnumClockInTypeSlug.workTime)?.Id;

                return workDays
                    .filter((wd) => WorkDayIds.includes(wd.Id))
                    .sort((a,b) => a.EmployeeId - b.EmployeeId)
                    .map<EmployeeListTableData>((wd, index) => {
                        const clockInsFromWorkday = clockIns
                            .filter((cl) => cl.WorkDayId === wd.Id)
                            .sort(sortClockIns(globalTypeId));
                        if (!sohwEmpty &&clockInsFromWorkday.length === 0) {
                            return null;
                        }
                        // let DrivingTime = 0;
                        let calcTime = 0;
                        workedTimes
                            .filter((w) => wd.Id === w.WorkDayId && w.ClockInTypeId === drivingTypeId && (!filteredCommissionIds || filteredCommissionIds.length === 0 || filteredCommissionIds.includes( w.CommissionId))
                            )
                            .forEach((item) => {
                                calcTime += !!item.Timespan ? item.Timespan : 0;
                            });
                        // DrivingTime = calcTime / 1000 / 60 / 60;

                        return {
                            ClockInTableData: new MatTableDataSource(
                                clockInsFromWorkday.map<ClockInTableData>((c) => {
                                    const commission = commissions.find((co) => co.Id === c.CommissionId);
                                    const forcedBreakTime = isNotNullOrUndefined(c.ForcedBreakTime) ? this.GetHourStringFromMilliseconds(c.ForcedBreakTime) : null;
                                    const note =
                                        isNotNullOrUndefined(c.ForcedBreakTime) && c.Type == this.ClockInTypes.Start && c.ForcedBreakTime > 0
                                            ? (isNotNullOrUndefined(c.WorkDescription) ? c.WorkDescription + '\n\n' : '') + '*Es wurde eine Pausenzeit von ' + forcedBreakTime + ' automatisch abgezogen'
                                            : c.WorkDescription;
                                    return {
                                        ClockInEntity: c,
                                        Type: clockInTypes[c.ClockInTypeId].Name + (c.ClockInTypeId === this.GlobalClockInTypeId ? '' : ' - ' + ClockInTypeNames.get(c.Type)),
                                        TimeStamp: moment(c.TimeStamp).format('HH:mm:ss'),
                                        Commission: commission?.DisplayName,
                                        Note: note,
                                        DrivingTime: null,
                                    CanEdit$: this.store.select(getEmployeeById({id: c.EmployeeId})).pipe(map(e => e?.PermissionsFromEntityRoles.includes(Permissions.EditClockIn)))};
                                }),
                            ),
                            // OtherClockInsExist: clockIns.some(c => c.WorkDayId === wd.Id && !clockInsFromWorkday.some(cl => cl.Id === c.Id)),
                            DisplayName: allEmployees[wd.EmployeeId] ? allEmployees[wd.EmployeeId].DisplayName : 'unbekannter mitarbeiter',
                            EmployeeId: wd.EmployeeId,
                            Date: DateData,
                            Identifier: formatDate(DateData, 'dd.MM.yyyy', 'de-DE') + '-index' + index,
                            WorkTime: this.GetHourStringFromMilliseconds(
                                workedTimes
                                    .filter((w) => wd.Id === w.WorkDayId && clockInTypes[w.ClockInTypeId].IsWorkingTime && (!filteredCommissionIds || filteredCommissionIds.length === 0 || filteredCommissionIds.includes(w.CommissionId)))
                                    .reduce((previousValue, currentValue) => previousValue + (currentValue.Timespan || 0), 0),
                            ),
                            WorkDayId: wd.Id,
                            DrivingTime: this.GetHourStringFromMilliseconds(calcTime),
                        IsValid: wd.IsValid || sameDay(wd.Timestamp, new Date()),
                        };
                    })

                    .filter(isNotNullOrUndefined);
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    protected readonly VacationTypeEnumNameMap = VacationTypeEnumNameMap;
}
