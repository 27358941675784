import { DEFAULT_CURRENCY_CODE, Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { Store } from "@ngrx/store";
import { State } from "../../../dave-data-module/State";
import {
    getARLTemplateTypes, getARLTemplateTypesFetched,
    getQuantityTypeById,
    getQuantityTypeDictionary,
    getQuantityTypes,
    getQuantityTypesFetched
} from "../../../dave-data-module/State/selectors/accounting.selector";
import { getFetched$ } from "../../../dave-data-module/helper/helper";
import { map, shareReplay, takeUntil } from "rxjs/operators";
import { combineLatest, of, Subject } from 'rxjs';
import { getResourcesActive } from "../../../dave-data-module/State/selectors/resource-dispo/resource.selectors";
import {
    getJobSpecification,
    getJobSpecificationFetched,
} from '../../../dave-data-module/State/selectors/job-specification.selector';
import { getMaterials, getMaterialsActive } from '../../../dave-data-module/State/selectors/material.selector';
import { isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { MaterialToSupplierDataService } from '../../../dave-data-module/services/material-to-supplier-data.service';

@Injectable({
    providedIn: 'root',
})
export class ArlCalculationDataService implements OnDestroy {
    private readonly _destroy$ = new Subject<void>();

    public quantityTypes$ = getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes).pipe(takeUntil(this._destroy$), shareReplay(1));
    public arlTemplateTypes$ = getFetched$(this.store, getARLTemplateTypesFetched, getARLTemplateTypes).pipe(takeUntil(this._destroy$), shareReplay(1));
    public getQuantityTypeById$(id: number) {
        return getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeById({ id }));
    }
    public getQuantityTypeNameById$(id: number) {
        if (!id) {
            return of('');
        }
        return this.getQuantityTypeById$(id).pipe(map((entity) => entity.Name));
    }
    public resourceOptions$ = this.store.select(getResourcesActive).pipe(
        takeUntil(this._destroy$),
        map((resources) => resources.map((r) => ({ searchValues: [r.Name, r.ArticleNumber, r.DisplayName].filter((v) => v), optionLabel: r.Name, value: r.Name, entityId: r.Id }))),
        shareReplay(1),
    );

    public employeeOptions$ = getFetched$(this.store, getJobSpecificationFetched, getJobSpecification).pipe(
        takeUntil(this._destroy$),
        map((jobs) => jobs.map((r) => ({ optionLabel: r.Name, value: r.Name, entityId: r.Id }))),
        shareReplay(1),
    );

    constructor(private store: Store<State>, @Inject(LOCALE_ID) private local: string, @Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string, private materialToSupplierDataService: MaterialToSupplierDataService) {
    }
    public getCurrencyString(num: number): string {
        if (isNotNullOrUndefined(num)) {
            return formatCurrency(num / 100, this.local, getCurrencySymbol(this.currencyCode, 'narrow'), this.currencyCode)
        }
        return num;
    }
    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
    materialOptionsBySupplierId$ = (supplierId: number, currentMaterial: number) => {
        return combineLatest([
            this.store.select(getMaterials),
            supplierId ? this.materialToSupplierDataService.getMaterialToSupplierBySupplierId$(supplierId).pipe(
                map(m2s => uniqArray([currentMaterial, ...m2s.filter(m => m.SupplierId === supplierId).map(m => m.MaterialId)].filter(isNotNullOrUndefined))),
            ) : of(null),
        ]).pipe(
            map(([materials, materialIds]) => {
                const notDeletedMaterials = materials.filter(m => !m.DeletedAt || m.Id === currentMaterial);
                return supplierId ? notDeletedMaterials.filter((m) => materialIds.includes(m.Id)) : notDeletedMaterials
            }),
            map((materials) =>
                materials
                    .map((m) => ({
                        searchValues: [m.Name, m.ArticleNumber].filter((v) => v),
                        optionLabel: [m.ArticleNumber, m.Name, this.getCurrencyString(m.ListPrice), this.getCurrencyString(m.Cost), this.getCurrencyString(m.SellPrice)].filter((v) => !!v).join(', '),
                        value: m.Name,
                        entityId: m.Id,
                    })),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
}
