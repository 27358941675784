<mat-card class="mat-card" *ngIf="!IsExpandable">
    <div class="card-header">
        <fa-stack matCardAvatar *ngIf="HeaderIcon">
            <fa-icon *ngIf="HeaderIcon as icon" [icon]="icon" stackItemSize="1x"></fa-icon>
            <fa-icon *ngIf="HeaderIcon2 as icon" [inverse]="true" stackItemSize="1x" [icon]="icon"></fa-icon>
        </fa-stack>
        <ng-container [ngTemplateOutlet]="CustomHeaderTemplate ? CustomHeaderTemplate : header"></ng-container>
    </div>

    <div class="top-shadow"></div>
    <div class="scroll-container">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
</mat-card>

<mat-expansion-panel class="panel" *ngIf="IsExpandable" #panel [expanded]="Expanded" (expandedChange)="ExpandedChange.emit($event)" (afterExpand)="setMatSort()">
    <mat-expansion-panel-header class="card-header card-header-expandable" collapsedHeight="HeaderHeight">
        <fa-stack matCardAvatar *ngIf="HeaderIcon">
            <fa-icon *ngIf="HeaderIcon as icon" [icon]="icon" stackItemSize="1x"></fa-icon>
            <fa-icon *ngIf="HeaderIcon2 as icon" [inverse]="true" stackItemSize="1x" [icon]="icon"></fa-icon>
        </fa-stack>
        <ng-container [ngTemplateOutlet]="CustomHeaderTemplate ? CustomHeaderTemplate : header"></ng-container>
    </mat-expansion-panel-header>

    <div class="top-shadow"></div>
    <ng-container [ngTemplateOutlet]="content" *ngIf="panel.expanded"></ng-container>
</mat-expansion-panel>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-template #content>
<!--    <div class="top-shadow"></div>-->
<!--    <div class="scroll-container" *ngIf="!IsLoading; else loading" #scrollContainer>-->
    <ng-container *ngIf="!IsLoading; else loading">
        <table *ngIf="TableData" mat-table [dataSource]="TableData" style="width: 100%;" [class.table-layout-fixed]="tableLayoutFixed" matSort [matSortActive]="DefaultSortActive" [matSortDirection]="DefaultSortDirection">
            <ng-container *ngFor="let collumName of AutoDisplayedColumns" [matColumnDef]="collumName">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="collumName">
                    {{ TableHeaderCollumsMap ? TableHeaderCollumsMap[collumName] : '' }}
                </th>
                <td mat-cell *matCellDef="let rowData" [ngStyle]="TableHeaderCollumsMap ? { width: 'unset' } : {}">
                    {{ rowData[collumName] }}
                </td>
                <td mat-footer-cell *matFooterCellDef><ng-container *ngTemplateOutlet="listCardFooter"></ng-container></td>
            </ng-container>
            <ng-container *ngFor="let template of CustomCellTemplatesArray" [matColumnDef]="template.columnKey">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="template.columnKey">
                    {{ TableHeaderCollumsMap ? TableHeaderCollumsMap[template.columnKey] : '' }}
                </th>
                <td mat-cell *matCellDef="let rowData" [ngStyle]="TableHeaderCollumsMap ? { width: 'unset' } : {}">
                    <ng-container [ngTemplateOutlet]="template.template" [ngTemplateOutletContext]="{ data: rowData }"></ng-container>
                </td>
                <!--                <td mat-footer-cell *matFooterCellDef></td>-->
                <td mat-footer-cell *matFooterCellDef>
                    <ng-container *ngIf="CustomFooterCellTemplates[template.columnKey] as templ" [ngTemplateOutlet]="templ"></ng-container>
                    <ng-container *ngTemplateOutlet="listCardFooter"></ng-container>
                </td>
            </ng-container>
            <ng-container [matColumnDef]="'dave-list-entry-suffix-button'">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="'dave-list-entry-suffix-button'"></th>
                <td mat-cell *matCellDef="let rowData; let i = index" style="width: 3rem">
                    <ng-container *ngIf="rowData.suffixButton as btn">
                        <ng-container *ngIf="btn.icon">
                            <ng-template #icon>
                                <fa-icon *ngIf="!btn.icon2" [icon]="btn.icon"></fa-icon>
                                <app-double-icon *ngIf="btn.icon2" [Icon]="btn.icon" [Icon2]="btn.icon2"></app-double-icon>
                            </ng-template>
                            <a app-round-button mat-dialog-close *ngIf="btn.routerLink" [routerLink]="btn.routerLink(rowData)" [matTooltip]="btn.disabled ? btn.disabledTooltip : btn.tooltip" [Disabled]="btn.disabled">
                                <ng-container [ngTemplateOutlet]="icon"></ng-container>
                            </a>
                            <button app-round-button *ngIf="btn.onClick" [matTooltip]="btn.disabled ? btn.disabledTooltip : btn.tooltip" (click)="btn.onClick(rowData); $event.stopPropagation()" [Disabled]="btn.disabled">
                                <ng-container [ngTemplateOutlet]="icon"></ng-container>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="btn.buttonText">
                            <a app-button mat-dialog-close *ngIf="btn.routerLink" [routerLink]="btn.routerLink(rowData)" [matTooltip]="btn.disabled ? btn.disabledTooltip : btn.tooltip" [Disabled]="btn.disabled">{{ btn.buttonText }}</a>
                            <button app-button *ngIf="btn.onClick" [matTooltip]="btn.disabled ? btn.disabledTooltip : btn.tooltip" (click)="btn.onClick(rowData); $event.stopPropagation()" [Disabled]="btn.disabled">
                                {{ btn.buttonText }}
                            </button>
                        </ng-container>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef><ng-container *ngTemplateOutlet="listCardFooter"></ng-container></td>
            </ng-container>

            <tr [class.hide]="!TableHeaderCollumsMap" mat-header-row *matHeaderRowDef="DisplayedCollums"></tr>
            <tr
                #row
                [style]="rowData?.cssStyle || '' +
                    'outline: 0; ' + rowData?.customBackgroundColorForListEntry
                        ? '--custom-background-color: ' + rowData.customBackgroundColorForListEntry?.default + '; ' + '--custom-background-color-hover: ' + rowData.customBackgroundColorForListEntry?.hover
                        : ''
                "
                [class.clickable-list-item]="Clickable"
                [class.not-clickable-list-item]="!Clickable || (DisableListEntryByButton && rowData?.suffixButton.disabled)"
                [class.selected-clickable-list-item]="SelectedIndex === i"
                [class.custom-background-color]="rowData?.customBackgroundColorForListEntry"
                mat-row
                [class]="rowData?.cssClass || ''"
                [style]="rowData?.cssStyle || ''"
                *matRowDef="let rowData; columns: HasSuffixButton ? DisplayedColumnsWithButton : DisplayedCollums; let i = index"
                (click)="RowClick(rowData, i, $event)"
            ></tr>

            <tr mat-footer-row class="bottom-shadow" *matFooterRowDef="DisplayedCollums; sticky: true"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell empty-table-text" [attr.colspan]="DisplayedCollums.length">
                    {{ EmptyText }}
                </td>
            </tr>
        </table>
<!--        <table *ngIf="NoContent$ | async" style="width: 100%">-->
<!--            <tr>-->
<!--                <td class="empty-table-text">-->
<!--                    {{ EmptyText }}-->
<!--                </td>-->
<!--            </tr>-->
<!--        </table>-->
    </ng-container>
    <ng-template #listCardFooter>
        <ng-content select="[listCardFooter]"></ng-content>
    </ng-template>
</ng-template>

<ng-template #header>
    <ng-container *ngIf="!searchVisible">
        <h3 class="headline">{{ Headline }}<ng-container *ngIf="!HideCount"> ({{ Length$ | async }})</ng-container></h3>
        <fa-icon *ngIf="Hint" size="2x" icon="question-circle" [matTooltip]="Hint" style="margin: auto 0"></fa-icon>
    </ng-container>
    <mat-form-field appearance="standard" *ngIf="searchVisible" class="search-form" (click)="$event.stopPropagation()">
        <mat-label>Suche</mat-label>
        <input matInput type="search" (keydown.Space)="$event.stopImmediatePropagation();" [formControl]="searchForm" #searchInput />
        <button *ngIf="searchForm.value" mat-icon-button aria-label="Clear" matSuffix (click)="searchForm.setValue('')">
            <fa-icon icon="times" class="clear-search-suffix"></fa-icon>
        </button>
    </mat-form-field>

    <div class="header-button-wrapper" (click)="$event.stopPropagation()">
        <button app-round-button headerButton onlyExpanded [Active]="searchVisible" matTooltip="Suche" aria-label="Suche" (click)="onSearchBtnClick()" *ngIf="!HideSearchButton && (CustomSearchCompareFunction || AutoDisplayedColumns?.length)"><fa-icon icon="search"></fa-icon></button>
        <ng-content select="[headerButton]"></ng-content>
    </div>
</ng-template>
