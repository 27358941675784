<div *ngIf="Editing" class="image-editing-wrapper">
    <button app-round-button><fa-icon icon="upload" (click)="logoUpload.click()"></fa-icon></button>
    <div *ngIf="!(File$ | async) && !(FileCache$ | async)" class="full-screen-image file-placeholder">
        <fa-icon icon="camera" size="5x"></fa-icon>
    </div>
    <input id="file" accept="image/*" type="file" hidden #logoUpload (change)="OnFileChange($event)" />
</div>
<ng-container *ngIf="ImageSource$ | async as imageSrc">
    <ng-container *ngIf="imageSrc">
        <div class="no-image" *ngIf="!FileIsLoaded">
            <app-loading></app-loading>
        </div>
        <img
            class="full-screen-image"
            [class.hide]="!FileIsLoaded"
            [src]="imageSrc"
            (load)="FileIsLoaded = true"
        />
    </ng-container>
</ng-container>
<div class="button-wrapper">
    <div class="theme-override {{ DMSMeta.Theme }}" *ngIf="FolderId as folderId">
        <a app-button class="wide-button" [routerLink]="['/', DMSMeta.Path]" [queryParams]="{ folderId }"><fa-icon [icon]="DMSMeta.Icon"></fa-icon></a>
    </div>
    <div class="theme-override {{ MaterialPageMeta.Theme }}"  *ngIf="ShowMaterialButton">
        <a app-button class="wide-button" [routerLink]="[MaterialPageMeta.Path, (Material$ | async)?.Id]"><fa-icon [icon]="MaterialPageMeta.Icon"></fa-icon></a>
    </div>
</div>
<app-loading *ngIf="Loading"></app-loading>
<app-detail-list-template [Data]="DetailList$ | async" [Editing]="Editing" [Inline]="true"> </app-detail-list-template>
<div class="custom-field-wrapper" *ngIf="Editing">
    <div class="header">
        <h4>Zusätzliche Felder</h4>
        <button app-round-button (click)="OpenCustomFieldPopup()"><fa-icon icon="plus"></fa-icon></button>
    </div>
    <div class="field" *ngFor="let field of CustomFields; let i = index">
        <mat-form-field *ngIf="field.type === CustomFieldTypes.text">
            <input matInput [formControl]="field.formControl" type="text" [placeholder]="field.name" />
        </mat-form-field>
        <mat-form-field *ngIf="field.type === CustomFieldTypes.number">
            <input matInput [formControl]="field.formControl" type="number" [placeholder]="field.name" />
        </mat-form-field>
        <mat-form-field  *ngIf="field.type === CustomFieldTypes.date">
            <input matInput [matDatepicker]="picker" [placeholder]="field.name" [formControl]="field.formControl" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button app-round-button (click)="OpenCustomFieldPopup(i)"><fa-icon icon="pencil-alt"></fa-icon></button>
    </div>
</div>
<ng-template #mainProductGroup>
    <div class="selectMaterialGroup">
        <mat-form-field>
            <app-select-search
                [placeholder]="'Hauptwarengruppe'"
                [formControl]="MaterialForm.controls.MainProductGroupId"
                [Options]="mainMaterialGroupOptions$ | async"
                [CompareOptions]="CompareById"
            ></app-select-search>
        </mat-form-field>
        <button app-round-button (click)="NewMaterialGroupPopUp(true)"><fa-icon icon="plus"></fa-icon></button>
    </div>
</ng-template>

<ng-template #ProductGroup>
    <div class="selectMaterialGroup">
        <mat-form-field>
            <app-select-search
                [placeholder]="'Warengruppe'"
                [formControl]="MaterialForm.controls.ProductGroupId"
                [Options]="subMaterialGroupOptions$ | async"
                [CompareOptions]="CompareById"
            ></app-select-search>
        </mat-form-field>
        <button app-round-button (click)="NewMaterialGroupPopUp(false)"><fa-icon icon="plus"></fa-icon></button>
    </div>
</ng-template>

<ng-template #SellPrice let-options="options" let-key="key" let-formControl="formControl">
    <app-numberfield *ngIf="!this.MaterialForm.controls.UseSurchargeRate.value" [Hint]="options?.showHint" [Key]="key" [FormControl]="formControl"
    ><div *ngIf="options.suffix" textfieldSuffix>
        {{ options.suffix }}
    </div></app-numberfield>
</ng-template>

