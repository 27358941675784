import { CommonModule,formatCurrency,formatDate,getCurrencySymbol } from '@angular/common';
import { Component,DEFAULT_CURRENCY_CODE,Inject,Input,LOCALE_ID } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject,combineLatest } from 'rxjs';
import { distinctUntilChanged,filter,map,switchMap,tap } from 'rxjs/operators';
import { MaterialPriceHistoryUpdateTypeEnum } from '../../../dave-data-module/entities/materialPriceHistory.entity';
import { MaterialPriceHistoryDataService } from '../../../dave-data-module/services/material-price-history-data.service';
import { State } from '../../../dave-data-module/State';
import { getCustomerDictionary,getCustomersFetched } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getUserDictionary,getUsersFetched } from '../../../dave-data-module/State/selectors/users.selectors';
import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { CustomLabelService } from '../../../services/custom-label.service';

interface MaterialPriceHistoryTableData {
    Costs: string;
    CreatedAt: string;
    SupplierName: string;
    User: string;
    Icon?: boolean;
    costsForSort: number;
    createdAtForSort: number;
}

@Component({
    selector: 'app-material-price-history-list-card',
    standalone: true,
    imports: [CommonModule, DaveListCardModule, FontAwesomeModule, MatTooltipModule],
    templateUrl: './material-price-history-list-card.component.html',
    styleUrls: ['./material-price-history-list-card.component.scss'],
})
export class MaterialPriceHistoryListCardComponent {
    @Input() set materialId(id: number) {
        if (this.MaterialId$.value !== id) {
            this.MaterialId$.next(id);
        }
    }

    private MaterialId$ = new BehaviorSubject<number>(null);
    public MaterialPriceHistoryTableDataLoading$ = new BehaviorSubject(false);
    public MaterialPriceHistoryTableData$ = this.MaterialId$.pipe(
        filter(isNotNullOrUndefined),
        distinctUntilChanged(),
        tap(() => this.MaterialPriceHistoryTableDataLoading$.next(true)),
        switchMap((mId) =>
            combineLatest([
                this.materialPriceHistoryDataService.getMaterialPriceHistoryByMaterialId$(mId, true).pipe(tap((v) => console.log('service dropped value', v) )),
                this.store.select(getCustomersFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getCustomerDictionary)),
                ),
                this.store.select(getUsersFetched).pipe(
                    filter((v) => !!v),
                    switchMap(() => this.store.select(getUserDictionary)),
                ),
            ]).pipe(
                tap(() => this.MaterialPriceHistoryTableDataLoading$.next(false)),
                map(([materialPriceHistoryEntries, customers, users]) => {
                    if (!materialPriceHistoryEntries.length) {
                        return new MatTableDataSource<MaterialPriceHistoryTableData>([]);
                    }

                    return new MatTableDataSource<MaterialPriceHistoryTableData>(
                        materialPriceHistoryEntries.map<MaterialPriceHistoryTableData>((s) => ({
                            Costs: formatCurrency(s.Cost / 100, this.locale, getCurrencySymbol(this.defaultCurrencyCode, 'narrow')),
                            costsForSort: s.Cost,
                            CreatedAt: formatDate(s.CreatedAt, 'shortDate', this.locale),
                            createdAtForSort: s.CreatedAt.getTime(),
                            SupplierName: s.SupplierId ? customers[s.SupplierId]?.DisplayName || 'Unbekannt' : '',
                            User: s.UserId ? this.formatUserName(users[s.UserId]?.DisplayName) || 'Unbekannt' : '',
                            IsAutomatic: s.CreateType === MaterialPriceHistoryUpdateTypeEnum.PRICEUPDATE_AUTOMATIC,
                        })),
                    );
                }),
                tap(
                    (dataSource) =>
                        (dataSource.sortingDataAccessor = (object, key) => {
                            switch (key) {
                                case 'CreatedAt':
                                    return object.createdAtForSort;
                                case 'Cost':
                                    return object.costsForSort;
                                default:
                                    return object[key].trim().toLowerCase();
                            }
                        }),
                ),
            ),
        ),
    );
    constructor(
        private store: Store<State>,
        protected cls: CustomLabelService,
        private materialPriceHistoryDataService: MaterialPriceHistoryDataService,

        @Inject(LOCALE_ID) private locale: string,
        @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
    ) {}

    formatUserName(displayName: string): string {
        if (!displayName) {
            return '';
        }

        const [lastName, firstName] = displayName.split(',').map((namePart) => namePart.trim());

        if (!lastName || !firstName) {
            return displayName;
        }

        const formattedName = `${firstName.charAt(0)}${lastName.substring(0, 3)}`;

        return formattedName;
    }
}
