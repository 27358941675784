import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { compareArrays, TableColumnConfig } from '../helper/helper';

@Component({
    selector: 'app-table-config-form[columns]',
    standalone: true,
    imports: [CommonModule, MatListModule],
    templateUrl: './table-config-form.component.html',
    styleUrls: ['./table-config-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableConfigFormComponent<T extends {}, K extends PropertyKey = keyof T> implements AfterViewInit {
    @Input() columns: TableColumnConfig<T, K>[];
    protected _selectedColumns: K[] = [];
    @Input() set selectedColumns(string: K[]) {
        if (!compareArrays(this._selectedColumns, string)) {
            this._selectedColumns = string;
        }
    }
    get selectedColumns() {
        return this._selectedColumns;
    }
    @Output() selectedColumnsChange = new EventEmitter<Array<K>>();
    @ViewChild(MatSelectionList) columnSelectionList: MatSelectionList;

    ngAfterViewInit(): void {
        this.columnSelectionList.selectionChange.subscribe((selection) => {
            let curr = this._selectedColumns.slice();
            selection.options.forEach((o) => {
                if (o.selected) {
                    curr.push(o.value);
                } else {
                    curr = curr.filter((co) => co !== o.value);
                }
            });
            this._selectedColumns = curr.sort((a, b) => this.columns.findIndex(v => v.name === a) - this.columns.findIndex(v => v.name === b));
            this.selectedColumnsChange.emit(this._selectedColumns);
        });
    }
    identify(index: number, item: TableColumnConfig<T>) {
        return item.name;
    }
}
