import { StatusFromBackofficeType } from '../graphql-types';
export enum StatusFromBackofficeNames {
    ZurPlanung = 'Zur Planung',
}
export class StatusFromBackofficeEntity {
    constructor(
        public Id: number = null,
        public Name: StatusFromBackofficeNames = null,
        public PartnerId: number = null,
        public Color: string = null,
        public DisplayOrder: number = null,
        public IsCompleted: boolean = null,
        public IsDefault: boolean = null,
    ) {}

    public Clone(override: Partial<StatusFromBackofficeEntity> = {}): StatusFromBackofficeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new StatusFromBackofficeEntity(), attr);
    }
    public static readonly GQLFields: Array<keyof StatusFromBackofficeType> = ['id', 'name', 'partnerId', 'color', 'displayOrder', 'isCompleted', 'isDefault'];
}

export function StatusFromBackofficeEntityFromBackend(res: StatusFromBackofficeType): StatusFromBackofficeEntity {
    return new StatusFromBackofficeEntity(
        res.id,
        res.name as StatusFromBackofficeNames,
        res.partnerId,
        res.color,
        res.displayOrder,
        res.isCompleted,
        res.isDefault);
}
