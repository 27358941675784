<mat-card class="card">
    <div class="data-wrapper">
        <div class="info-wrapper">
            <h4 *ngIf="!Editing && Subtitle">{{ Subtitle }}</h4>
            <h3 *ngIf="!Editing && (Title || Data?.Icon)">
                <fa-icon
                    *ngIf="Data?.Icon as icon"
                    [icon]="icon"
                    style="margin-right: 1rem"
                ></fa-icon>
                <span>{{ Title }}</span>
            </h3>

            <ul class="property-list" *ngIf="DataWithValues?.Properties && !Editing">
                <li [class.padding-left]="!!property.icon" *ngFor="let property of DataWithValues?.Properties">
                    <div *ngIf="property.icon">
                        <fa-icon [icon]="property.icon"></fa-icon>
                    </div>
                    <p
                        class="content-text"
                        *ngIf="
                            !property.options ||
                            !(property.options.type > -1) ||
                            property.options.type === PropertyType.Boolean ||
                            property.options.type === PropertyType.Datum
                        "
                    >
                        {{ property.icon ? property.value : property.key + ': ' + property.value }}
                    </p>
                    <p class="content-text" *ngIf="property.options?.type === PropertyType.Percentage">
                        {{
                            property.icon
                                ? (property.value | percent)
                                : property.key + ': ' + (property.value | percent)
                        }}
                    </p>
                    <a
                        *ngIf="property.options?.type === PropertyType.Link"
                        [href]="property.value?.includes('//') ? property.value : ('//' + property.value)"
                        class="link content-text"
                        target="_blank"
                        rel="noreferrer noopener"
                        >{{ property.value }}</a
                    >
                    <a
                        *ngIf="property.options?.type === PropertyType.RouterLink"
                        [routerLink]="property.options.routerLinkPayload.routerLink"
                        [queryParams]="property.options.routerLinkPayload.queryParams"
                        class="link content-text"
                    >
                    {{ property.value }}
                    </a>
<!--                    <a *ngIf="property.options?.type === PropertyType.Mailto" [href]="'mailto:' + property.value" class="link content-text">{{ property.value }}</a>-->
                    <a *ngIf="property.options?.type === PropertyType.Mailto" (click)="OpenMailDialog([property.value])" class="link content-text">{{ property.value }}</a>

                    <a
                        *ngIf="property.options?.type === PropertyType.Tel"
                        [href]="'tel:' + property.value"
                        class="link content-text"
                        >{{ property.value }}</a
                    >
                    <a
                        *ngIf="property.options?.type === PropertyType.Location"
                        [href]=""
                        (click)="OpenMapDialog(property.options.specialInput.location.value)"
                        class="link content-text"
                        >{{ property.value }}</a
                    >
                </li>
            </ul>
        </div>
        <ng-content></ng-content>
    </div>

    <ng-container *ngIf="Editing">
        <ng-container *ngFor="let property of EditableProperties$ | async">
            <form [formGroup]="FormGroup" (ngSubmit)="Submit.emit()" *ngIf="!property.hideFormControl">
                <ng-container *ngIf="property.options?.specialInput; else normalInput">
                    <ng-container *ngIf="property.options.specialInput.customTemplate" [ngTemplateOutlet]="property.options.specialInput.customTemplate"></ng-container>
                    <mat-form-field *ngIf="property.options.specialInput.select" class="form-field hover-form">
                        <mat-label>{{ property.key }}</mat-label>

                        <mat-select [formControl]="property.formControl">
                            <mat-option [value]="null">--</mat-option>

                            <mat-option
                                *ngFor="let option of property.options.specialInput.select"
                                [value]="option.optionValue"
                            >
                                {{ option.optionLabel }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <app-select-search-legacy
                        class="form-field hover-form"
                        *ngIf="property.options.specialInput.selectSearch"
                        [Control]="property.formControl"
                        [Placeholder]="property.key"
                        [Values]="MapSelectInputToSelectSearchInput(property.options.specialInput.selectSearch)"
                    >
                    </app-select-search-legacy>
                    <app-chip-autocomplete
                        [initialPatchDefaultValue]="true"
                        class="hover-form"
                        *ngIf="property.options.specialInput.chipAutocomplete"
                        [formControl]="property.formControl"
                        [MapFn]="property.options.specialInput.chipAutocomplete.MapFn"
                        [Options]="property.options.specialInput.chipAutocomplete.Options"
                        [Placeholder]="property.key"
                        [ErrorMessage]="property.formControl.invalid ? GetErrorMessage(property.formControl) : ''"
                    ></app-chip-autocomplete>
                    <app-numberfield
                        *ngIf="property.options?.specialInput?.number"
                        [Pattern]="property.options.pattern"
                        [Key]="property.key"
                        [FormControl]="property.formControl"
                        class="hover-form"
                        ><div *ngIf="property.options.suffix" textfieldSuffix>
                            {{ property.options.suffix }}
                        </div></app-numberfield
                    >
                    <app-textfield
                        class="hover-form"
                        *ngIf="property.options?.specialInput?.location?.formGroup"
                        Key="Straße"
                        [Value]="property.options.specialInput.location.formGroup.controls.Street"
                    ></app-textfield>
                    <app-textfield
                        class="hover-form"
                        *ngIf="property.options?.specialInput?.location?.formGroup"
                        Key="Postleitzahl"
                        [Value]="property.options.specialInput.location.formGroup.controls.PostalCode"
                    ></app-textfield>
                    <app-textfield
                        class="hover-form"
                        *ngIf="property.options?.specialInput?.location?.formGroup"
                        Key="Ort"
                        [Value]="property.options.specialInput.location.formGroup.controls.City"
                    ></app-textfield>
                    <app-textfield
                        class="hover-form"
                        *ngIf="property.options?.specialInput?.location?.formGroup"
                        Key="Land"
                        [Value]="property.options.specialInput.location.formGroup.controls.Country"
                    ></app-textfield>
                    <ng-container *ngIf="property.options?.specialInput?.weekDaySelector && property.formControl.value">
                        <span>{{ property.key }}</span>
                        <ul style="list-style-type: none;">
                            <li *ngFor="let name of WeekDayNames; let i = index">
                                <mat-checkbox
                                    *ngIf="property.options?.specialInput?.weekDaySelector === 'checkbox'"
                                    color="primary"
                                    [checked]="property.formControl.value[i]"
                                    (change)="UpdateArrayValue(property.formControl, i, $event.checked)"
                                >
                                    {{ name }}
                                </mat-checkbox>
                                <mat-checkbox
                                    *ngIf="property.options?.specialInput?.weekDaySelector === 'number'"
                                    color="primary"
                                    [checked]="property.formControl.value[i]"
                                    (change)="UpdateArrayValue(property.formControl, i, $event.checked ? 8 : null)"
                                >
                                    <div style="min-width: 2rem;">
                                        <span>{{ name }}</span>
                                    </div>
                                </mat-checkbox>
                                <mat-form-field>
                                    <input
                                        *ngIf="property.options?.specialInput?.weekDaySelector === 'number'"
                                        type="number"
                                        matInput
                                        [value]="property.formControl.value[i]"
                                        (input)="UpdateArrayValue(property.formControl, i, $event.target.value)"
                                    />
                                    <div *ngIf="property.options.suffix" matSuffix>{{ property.options.suffix }}</div>
                                </mat-form-field>
                            </li>
                        </ul>
                    </ng-container>
                    <mat-form-field *ngIf="property.options?.type === CustomPropertyType.Datum" class="hover-form">
                        <mat-label>{{ property.key }}</mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" [formControl]="property.formControl" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-slide-toggle
                        class="hover-form"
                        *ngIf="property.options?.type === CustomPropertyType.Boolean"
                        (toggleChange)="property.formControl.setValue(!property.formControl.value)"

                        [checked]="property.formControl.value"
                    >
                        <mat-label>{{ property.key }}</mat-label>
                    </mat-slide-toggle>
                </ng-container>
                <ng-container *ngIf="property.options?.showHint != null" (click)="tooltip.toggle()">
                    <fa-icon
                        icon="question-circle"
                        [matTooltip]="property.options?.showHint"
                        style="margin: 1rem"
                        #tooltip="matTooltip"
                    >
                    </fa-icon>
                </ng-container>
                <ng-template #normalInput>
                    <app-textfield
                        [Key]="property.key"
                        [Value]="property.formControl"
                        class="hover-form"
                    ></app-textfield>
                </ng-template>
            </form>
        </ng-container>
    </ng-container>
</mat-card>
