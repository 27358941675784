<!--<button mat-button class="headline" (click)="clacAmountViewExpanded = !clacAmountViewExpanded">Aufmaß {{clacAmountViewExpanded ? '-' : '+'}}</button>-->
<!--<app-arl-amount-calculation-view [arl]="_arl"></app-arl-amount-calculation-view>-->
<button mat-button class="headline" [disabled]="disabled" (click)="clacViewExpanded = !clacViewExpanded">Kalkulation {{ clacViewExpanded ? '-' : '+' }}</button>
<app-arl-calculation-view
    [ZuschlaegeResourcen]="ZuschlaegeResourcen"
    [ZuschlaegeMaterials]="ZuschlaegeMaterials"
    [ZuschlaegeDefault]="ZuschlaegeDefault"
    [ZuschlaegeEmployee]="ZuschlaegeEmployee"
    [ZuschlaegeExternalService]="ZuschlaegeExternalService"
    [ZuschlaegeOther]="ZuschlaegeOther"
    [childrenVisibleForm]="childrenVisibleForm"
    [expanded]="clacViewExpanded && !disabled"
    [arl]="_arl"
    [positionPrefix]="_positionPrefix"
    [inheritFromChildrenForm]="_inheritFromChildrenForm"
    [FilterMaterialsByCustomerId]="FilterMaterialsByCustomerId"
    [UseAmountFromMaterialToSupplier]="UseAmountFromMaterialToSupplier"
    [isOrder]="isOrder"
    (calculatedAmount)="calculatedAmount.emit($event)"
></app-arl-calculation-view>
<div class="action-row">
    <button app-button Color="cancel" [IsLoading]="ls.IsLoading$ | async" Inverted (click)="onAbortClick()">abbrechen</button>
    <span class="spacer"></span>
<!--    <button app-button Color="red" [Disabled]="disabled" [IsLoading]="ls.IsLoading$ | async" (click)="onDeleteClick()">löschen</button>-->
<!--    <span class="spacer"></span>-->
<!--    <button app-button Color="green" [IsLoading]="ls.IsLoading$ | async" [Disabled]="someFormDirty$ | async" (click)="onDuplicateClick()">duplizieren</button>-->
<!--    <button app-button Color="green" [IsLoading]="ls.IsLoading$ | async" [Disabled]="!(someFormDirty$ | async) || (someFormInvalid$ | async)" (click)="onSaveClick()">speichern</button>-->
</div>
