<mat-drawer-container style="height: 100%">
    <mat-drawer #drawer [opened]="ShowTree" class="example-sidenav" [mode]="(Small$ | async) ? 'over' : 'side'">
        <button *ngIf="Small$ | async" type="button" mat-icon-button class="toggle-view-button"
                (click)="ToggleSidenav()">
            <fa-icon [icon]="!drawer.opened ? 'chevron-right' : 'chevron-left'"></fa-icon>
        </button>
        <cdk-virtual-scroll-viewport
            #scrollableTree
            style="height: 100%"
            itemSize="48"
            minBufferPx="400"
            maxBufferPx="800"
            class="scrollbar"
            *ngIf="drawer.opened">
            <!--            <ng-container *cdkVirtualFor="let node of dynamicTreeDataAsArray$ | async">-->
            <ng-container *cdkVirtualFor="let node of dynamicTreeDataSource">
                <div [style.padding-left]="node.level * 20 + 'px'"
                     class="explorer-tree-node"
                     [class.selected]="node.item.isFolder && ((PathIds$ | async)?.includes(node.item.entityId) || !node.item.entityId)">
                    <app-dynamic-folder-node
                        style="height: 48px"
                        [expandable]="node.expandable"
                        [expanded]="dynamicTreeControl.isExpanded(node)"
                        [loading]="node.isLoading"
                        (click)="ClickNode2(node); dynamicTreeControl.toggle(node)">
                        <fa-icon [icon]="'folder'"></fa-icon>
                        {{ node.item.name$ | async }}
                    </app-dynamic-folder-node>
                    <!--                    <app-folder-node-->
                    <!--                        style="height: 48px"-->
                    <!--                        [Node]="node"-->
                    <!--                        [TreeControl]="treeControl"-->
                    <!--                        [Expanded]="treeControl.isExpanded(node)"-->
                    <!--                        (click)="ClickNode(node);-->
                    <!--                         treeControl.toggle(node)">-->
                    <!--                    </app-folder-node>-->
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>

        <!--        <mat-tree [dataSource]="dynamicTreeDataSource" [treeControl]="dynamicTreeControl">-->
        <!--            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>-->
        <!--                <button mat-icon-button disabled></button>-->
        <!--                {{node.item.name}}-->
        <!--            </mat-tree-node>-->
        <!--            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>-->
        <!--                <button mat-icon-button-->
        <!--                        [attr.aria-label]="'Toggle ' + node.item.name" matTreeNodeToggle>-->
        <!--                    <fa-icon [icon]="dynamicTreeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"></fa-icon>-->
        <!--                </button>-->
        <!--                {{node.item.name}}-->
        <!--                <mat-progress-bar *ngIf="node.isLoading"-->
        <!--                                  mode="indeterminate"-->
        <!--                                  class="example-tree-progress-bar"></mat-progress-bar>-->
        <!--            </mat-tree-node>-->
        <!--        </mat-tree>-->

    </mat-drawer>

    <div class="example-container">
        <div class="header" *ngIf="!(Minimalistic$ | async)">
            <div class="top-header">
                <button type="button" mat-icon-button class="toggle-view-button" (click)="ToggleSidenav()">
                    <fa-icon [icon]="!drawer.opened ? 'chevron-right' : 'chevron-left'"></fa-icon>
                </button>
                <ng-container *ngIf="!(Small$ | async)" [ngTemplateOutlet]="backButton"
                              [ngTemplateOutletContext]="{path : (Path$ | async)}"></ng-container>
                <ng-container *ngIf="!(Small$ | async)" [ngTemplateOutlet]="scrollableFilePath"></ng-container>
                <app-burger-button-wrapper class="header-buttons" style="margin-left:auto" #buttonWrapper>
                    <mat-form-field floatLabel="never" class="search-bar"
                                    [ngStyle]="{ display: ShowDataSearch ? 'inline-block' : 'none' }" color="primary">
                        <mat-label>Suche</mat-label>
                        <input type="text" [formControl]="DataSearchForm" matInput #searchInput />
                        <button class="clear-search" (click)="DataSearchForm.setValue('')" mat-icon-button matSuffix
                                *ngIf="true">
                            <fa-icon icon="times"></fa-icon>
                        </button>
                    </mat-form-field>
                    <ng-container *ngIf="!(Multiselect$ | async)">
                        <button app-round-button matTooltip="Mehrfachauswahl" aria-label="Mehrfachauswahl"
                                (click)="ToggleMultiselect()">
                            <fa-icon icon="check-square"></fa-icon>
                        </button>
                        <button *ngIf="FileUploadButton" app-round-button matTooltip="Dateien hochladen"
                                aria-label="Dateien hochladen" (click)="UploadClick(SelectedFolderId$.value)"
                                [Disabled]="!HasFolderEditPermission">
                            <fa-icon icon="upload"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="Multiselect$ | async">
                        <mat-divider vertical></mat-divider>
                        <button app-round-button Inverted matTooltip="Abbrechen" aria-label="Abbrechen"
                                (click)="ToggleMultiselect()">
                            <fa-icon icon="times"></fa-icon>
                        </button>
                        <button
                            app-round-button
                            matTooltip="Dateien herunterladen"
                            aria-label="Dateien herunterladen"
                            [Disabled]="!(FolderMultisilect$ | async).length && !(FileMultisilect$ | async).length && !HasFolderEditPermission"
                            (click)="ClickDownload$.next()"
                        >
                            <fa-icon icon="download"></fa-icon>
                        </button>
                        <button
                            app-round-button
                            matTooltip="Verschieben"
                            aria-label="Verschieben"
                            [Disabled]="!(FolderMultisilect$ | async).length && !(FileMultisilect$ | async).length"
                            (click)="ClickMove$.next()"
                        >
                            <fa-icon icon="share"></fa-icon>
                        </button>
                        <button
                            app-round-button
                            matTooltip="PDFs zusammenführen"
                            aria-label="PDFs zusammenführen"
                            [Disabled]="mergePdfDisabled$ | async"
                            (click)="onMergePdfClick()"
                        >
                            <fa-icon icon="copy"></fa-icon>
                        </button>

                        <button
                            app-round-button (click)="OpenMailDialog$.next()"
                            [Disabled]="!(FileMultisilect$ | async).length" matTooltip="Dateien per E-Mail senden"
                            aria-label="E-Mail senden">
                            <app-double-icon Icon="paper-plane"></app-double-icon>
                        </button>
                        <button
                            app-round-button
                            matTooltip="Löschen"
                            aria-label="Löschen"
                            Color="red"
                            [Disabled]="(!(FolderMultisilect$ | async).length && !(FileMultisilect$ | async).length) || (FolderWithTypeSelected$ | async)"
                            (click)="ClickDelete()"
                        >
                            <fa-icon icon="trash"></fa-icon>
                        </button>

                    </ng-container>
                    <button app-round-button matTooltip="Suchen" aria-label="Suchen"
                            (click)="ShowDataSearch = !ShowDataSearch; DataSearchForm.setValue(''); SetFocus(searchInput)">
                        <fa-icon [icon]="ShowDataSearch ? 'times' : 'search'"></fa-icon>
                    </button>
                    <button app-round-button matTooltip="Filtern" aria-label="Filtern" (click)="filter.Open()">
                        <fa-icon icon="filter"></fa-icon>
                    </button>
                    <ng-container *ngIf="!(Multiselect$ | async)">
                        <button app-round-button matTooltip="Ordner erstellen" aria-label="Ordner erstellen"
                                (click)="ClickAddFolder()" [Disabled]="!HasFolderEditPermission">
                            <fa-icon icon="plus"></fa-icon>
                        </button>
                    </ng-container>
                    <button app-round-button *ngIf="ShowScan2DaveButton"
                            (click)="Scan2DaveClicked$.emit(SelectedFolderId$.value)" matTooltip="Scan2go"
                            aria-label="Scan2go">
                        <app-double-icon [Icon]="'file'" Icon2="cogs"></app-double-icon>
                    </button>
                    <button app-round-button (click)="SetViewStyle(ViewStyles.List)"
                            *ngIf="!(ViewStyleOverride$ | async) && (ViewStyle$ | async) !== ViewStyles.List"
                            matTooltip="Listenansicht" aria-label="Listenansicht">
                        <fa-icon icon="list"></fa-icon>
                    </button>
                    <button app-round-button (click)="SetViewStyle(ViewStyles.Tile)"
                            *ngIf="!(ViewStyleOverride$ | async) && (ViewStyle$ | async) !== ViewStyles.Tile"
                            matTooltip="Kachelansicht" aria-label="Kachelansicht">
                        <fa-icon icon="th-large"></fa-icon>
                    </button>

                    <ng-container *ngIf="(SelectedFolderId$ | async) as FolderId">
                        <button app-round-button (click)="Options($event, FolderId, false)" matTooltip="Details"
                                aria-label="Details">
                            <fa-icon icon="info-circle"></fa-icon>
                        </button>
                    </ng-container>
                </app-burger-button-wrapper>
            </div>

            <div *ngIf="Small$ | async" class="bottom-header">
                <ng-container [ngTemplateOutlet]="backButton"
                              [ngTemplateOutletContext]="{path : (Path$ | async)}"></ng-container>

                <ng-container [ngTemplateOutlet]="scrollableFilePath"></ng-container>
            </div>
            <ng-template #scrollableFilePath>
                <div class="scrollbar-wrapper">
                    <ngx-simplebar #breadcrumbScrollbar>
                        <div class="breadcrumbs">
                            <button class="root-button" mat-icon-button (click)="SelectedFolderId$.next(null)">
                                <fa-icon icon="folder" size="2x"></fa-icon>
                                {{ (ShownElements$ | async)?.length }}
                                <!-- Folder Icon at the top bar -->
                            </button>
                            <ng-container *ngFor="let folder of Path$ | async">
                                <fa-icon icon="chevron-right"></fa-icon>
                                <button mat-button *ngIf="(SelectedFolderId$ | async) !== folder.Id"
                                        (click)="SelectedFolderId$.next(folder.Id)">
                                    {{ folder.Name }}
                                </button>
                                <ng-container
                                    *ngIf="(SelectedFolderId$ | async) && (SelectedFolderId$ | async) === folder.Id">
                                    <p style="margin-left: .5rem">{{ folder.Name }}</p>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ngx-simplebar>
                </div>
            </ng-template>
        </div>
        <mat-progress-bar *ngIf="showProgressBar$ | async" mode="indeterminate"></mat-progress-bar>
        <div class="minimalistic-header" *ngIf="Minimalistic$ | async">
            <!-- <button mat-icon-button class="back-button"><fa-icon icon="arrow-left"></fa-icon></button> -->
            <ng-container [ngTemplateOutlet]="backButton"
                          [ngTemplateOutletContext]="{path : (Path$ | async)}"></ng-container>

        </div>
        <ng-container *ngIf="(ViewStyle$ | async) === ViewStyles.Tile">
            <div *ngIf="ShownElements$ | async as data" class="content" [class.minimalistic]="Minimalistic$ | async"
                 #wrapper>
                <div *ngIf="data.length == 0 && data.length == 0" class="no-files tile">
                    Es sind keine Dateien order Ordner vorhanden.
                </div>
                <cdk-virtual-scroll-viewport #scrollViewportForTile class="scrollbar tile-wrapper" style="height: 100%"
                                             [itemSize]="ItemHeight / Math.floor(wrapper.clientWidth / ItemWidth)"
                                             [minBufferPx]="ItemHeight * 2" [maxBufferPx]="ItemHeight * 4">
                    <button class="explorer-item" [class.prev-selected-Item]="file.prevSelected$ | async"
                            *cdkVirtualFor="let file of data" mat-button (click)="CilickItem(file)">
                            <span *ngIf="file.isLink" class="link-icon">
                                <ng-container [ngTemplateOutlet]="linkIcon"></ng-container>
                            </span>
                        <div *ngIf="file.thumbnailPath" class="image-wrapper">
                            <div #button>
                                <fa-icon [icon]="file.icon || (file.isFolder ? 'folder' : 'file')"
                                         [size]="file.isFolder ? '5x' : '6x'"></fa-icon>
                            </div>
                            <img #img *ngIf="file.thumbnailPath" [class.hide]="!img.id"
                                 (load)="img.id = '1'; file.thumbnailPath && button.remove()"
                                 [src]="file.thumbnailPath" />
                        </div>
                        <div *ngIf="!file.thumbnailPath" class="image-wrapper">
                            <fa-icon [icon]="file.icon || (file.isFolder ? 'folder' : 'file')"
                                     [size]="file.isFolder ? '5x' : '6x'"></fa-icon>
                        </div>
                        <fa-icon *ngIf="file.isChecked && !file.isFolder" icon="check-circle"
                                 class="overlay-icon"></fa-icon>
                        <p class="button-text">{{ file.name }}</p>
                        <fa-icon
                            [icon]="
                                    (file.isFolder && (FolderMultisilect$ | async).includes(file.id)) || (!file.isFolder && (FileMultisilect$ | async).includes(file.id))
                                        ? ['far', 'check-square']
                                        : ['far', 'square']
                                "
                            class="badge"
                            *ngIf="Multiselect$ | async"
                        ></fa-icon>
                    </button>
                </cdk-virtual-scroll-viewport>
                <div *ngIf="FileMultisilect$?.value?.length || FolderMultisilect$?.value?.length"
                     class="selected-elements-amount">
                    Ausgewählte Dateien:&nbsp;
                    {{ FileMultisilect$?.value?.length + FolderMultisilect$?.value?.length }}
                </div>
            </div>
        </ng-container>
        <div *ngIf="(ViewStyle$ | async) === ViewStyles.List" class="content"
             [class.minimalistic]="Minimalistic$ | async">
            <cdk-virtual-scroll-viewport #scrollViewportForList class="scrollbar list-wrapper" tvsItemSize="48" headerHeight="56"
                                         style="height: 100%"
                                         *ngIf="ShownElementsTableDataSource$ | async as dataSource">
                <table mat-table [dataSource]="dataSource" matSort (matSortChange)="SortTableData($event, dataSource)"
                       class="mat-elevation-z8 table">
                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef style="width: 2rem">
                            <mat-checkbox
                                (change)="$event ? MultiselectMasterToggle(dataSource.data) : null"
                                [checked]="IsAllSelected$ | async"
                                [indeterminate]="(FileMultisilect$.value.length > 0 || FolderMultisilect$.value.length > 0) && !(IsAllSelected$ | async)"
                                color="primary"
                            >
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? CilickItem(row) : null"
                                          [checked]="IsSelected(row)" color="primary">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [ngStyle]="{'width': (Small$ | async) ? (Multiselect$ | async) ? 'calc(100%-12rem)': 'calc(100%-10rem)' : (Multiselect$ | async) ? 'calc(60%-4rem)': 'calc(60%-2rem)'}">
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <fa-icon *ngIf="!element.isFolder && element.isChecked"
                                     icon="circle-check" style="color: #02ad79 ; margin-right: 1px">
                            </fa-icon>
                            <fa-icon [icon]="element.icon || (element.isFolder ? 'folder' : 'file')">
                            </fa-icon> {{ element.name }} <ng-container *ngIf="element.isLink" [ngTemplateOutlet]="linkIcon"></ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [style.width]="(Small$ | async) ? '4rem' : '20%'">Erstellt
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'dd.MM.yy' }}
                            <ng-container
                                *ngIf="!(Small$ | async)">{{ ', ' }}{{ element.createdAt | date: 'mediumTime' }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="updatedAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [style.width]="(Small$ | async) ? '4rem' : '20%'">Änderungsdatum
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.updatedAt | date: 'dd.MM.yy' }}
                            <ng-container
                                *ngIf="!(Small$ | async)">{{ ', ' }}{{ element.updatedAt | date: 'mediumTime' }}
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="size">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;">Größe</th>
                        <td mat-cell *matCellDef="let element">
                            {{ !element.isFolder ? (element.size | fileSize) : '' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="fileType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            [style.width]="(Small$ | async) ? '4rem' : '10%'">Dateityp
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.fileType }}</td>
                    </ng-container>

                    <ng-container matColumnDef="options">
                        <th mat-header-cell *matHeaderCellDef style="width: 2rem"></th>
                        <td mat-cell *matCellDef="let element" (click)="Options($event, element.id, !element.isFolder)">
                            <fa-icon *ngIf="element.isFolder" icon="ellipsis-h" class="options-button"></fa-icon>
                        </td>
                    </ng-container>

                    <tr mat-header-row class="table-header" *matHeaderRowDef="DisplayedColumns$ | async"></tr>
                    <tr mat-row [class.prev-selected-Item]="row.prevSelected | async" tabindex="0"
                        *matRowDef="let row; columns: DisplayedColumns$ | async" class="list-item"
                        (click)="CilickItem(row)"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="(DisplayedColumns$ | async)?.length">
                            <span>Es sind keine Dateien oder Ordner vorhanden.</span>
                        </td>
                    </tr>
                </table>

                <!--                <div *ngIf="dataSource.data.length == 0" class="no-files">-->
                <!--                    Es sind keine Dateien oder Ordner vorhanden.-->
                <!--                </div>-->
            </cdk-virtual-scroll-viewport>
            <div *ngIf="FileMultisilect$?.value?.length || FolderMultisilect$?.value?.length"
                 class="selected-elements-amount">
                Ausgewählte Dateien:&nbsp;
                {{ FileMultisilect$?.value?.length + FolderMultisilect$?.value?.length }}
            </div>
        </div>
    </div>
</mat-drawer-container>

<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async"
                 (ValuesChange)="FilterValues$.next($event)"></app-filter-card>

<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
<ng-template #backButton let-path="path">
    <button class="back-button" type="button" matTooltip="Vorheriger Ordner"  aria-label="Vorheriger Ordner" app-round-button
            (click)="SelectedFolderId$.next(path.length === 1 ? null : path[path.length - 2].Id)"
            [Disabled]="!path || ((SelectedFolderId$ | async) === DisableBackButtonFolderId)">
        <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="['fas', 'folder']"></fa-icon>
            <fa-icon class="inverted-arrow" [icon]="['fas', 'arrow-left']" transform="shrink-6"
                     [style]="path ? '' : 'color: #e5e7e6' "></fa-icon>
        </fa-layers>
    </button>
</ng-template>
<ng-template #linkIcon>
    <fa-icon matTooltip="dies ist eine Verknüpfung" [icon]="FileLinkIcon"></fa-icon>
</ng-template>
