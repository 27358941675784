import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, map, merge, Observable, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, skip, tap } from 'rxjs/operators';
import { CustomerTypeResolver } from '../../../../dave-data-module/guards/customer-type.resolver';
import { CustomerResolver } from '../../../../dave-data-module/guards/customer.resolver';
import { State } from '../../../../dave-data-module/State';
import { getCustomers, getCustomersFetched, getCustomerTypeFetched, getCustomerTypes } from '../../../../dave-data-module/State/selectors/customers.selectors';
import { DaveDoubleIconModule } from '../../../../dave-double-icon/dave-double-icon.module';
import { DaveLoadingModule } from '../../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../../dave-utils-module/app-filter-module/app-filter.module';
import { FilterOption, FILTER_TYPE_MULTI_SELECT } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, stringSearch } from '../../../../helper/helper';
import { CustomerAdministrationMeta } from '../../../../helper/page-metadata';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { DefaultFilterService, FilterApps } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import { DaveSelectUserModule } from '../../../dave-select-user.module';
import { ISelectedElement, ITableConfig, TableContentType } from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';

interface ITableData {
    customerId: number;
    selectedForm: FormControl<boolean>;
    specificationTypeId: number;
    name: TableContentType<any> & { __typename: 'string' };
    number: TableContentType<any> & { __typename: 'string' };
}
interface SelectedCustomerType extends ISelectedElement {
    customerId: number;
}
enum filterNames {
    customerType = 'customerType',
}
interface IFilter {
    [filterNames.customerType]: string[];
}
@Component({
    selector: 'app-select-customer',
    templateUrl: './select-customer.component.html',
    styleUrls: ['./select-customer.component.scss'],
    standalone: true,
    imports: [CommonModule, AppButtonModule, DaveSelectUserModule, DaveDoubleIconModule, MatBadgeModule, MatTooltipModule, FontAwesomeModule, AppFilterModule, DaveLoadingModule],
})
export class SelectCustomerComponent<T extends SelectedCustomerType> implements OnInit, OnDestroy {
    public static readonly DefaultConfig: MatDialogConfig = SelectUserDialogBase.DefaultConfig;
    public FilterSettings$: Observable<FilterOption[]> = this.store.select(getCustomerTypeFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getCustomerTypes)),
        map((customerTypes) => {
            const ctValues = {};
            customerTypes.forEach((c) => {
                ctValues[c.Id.toString()] = c.Name;
            });
            return [
                {
                    Name: filterNames.customerType,
                    Type: FILTER_TYPE_MULTI_SELECT,
                    Label: 'Kundenart',
                    Values: ctValues,
                },
            ];
        }),
    );
    public FilterValues$: BehaviorSubject<IFilter> = new BehaviorSubject({
        [filterNames.customerType]: [],
    });
    public FilterAmount$ = this.FilterValues$.pipe(map((v) => (v[filterNames.customerType]?.length ? 1 : 0)));
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    selectedCustomers$ = new BehaviorSubject<T[] | null>(null);
    @Input() set selectedCustomers(value: T[]) {
        this.selectedCustomers$.next(value);
    }
    get selectedCustomers() {
        return this.selectedCustomers$.value;
    }
    @Output() deleteClick = new EventEmitter<T>();
    @Output() entryClick = new EventEmitter<T>();

    TableDataConfig: ITableConfig[] = [
        {
            header: 'Kundennummer',
            id: 'number',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Name',
            id: 'name',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
    ];
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    public dirty = false;
    constructor(
        private store: Store<State>,
        public LS: LoadingService,
        private defaultFilter: DefaultFilterService,
        customerRes: CustomerResolver,
        customerTypeRes: CustomerTypeResolver,
        protected cls: CustomLabelService,
    ) {
        this.ContentResolved$ = combineLatest([
            this.store.select(getCustomersFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        customerRes.resolve();
                    }
                }),
            ),
            this.store.select(getCustomerTypeFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        customerTypeRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        // this.store.select(getCustomerDictionary).pipe(map((customers) => (this.Dialogdata.selectedCustomers || []).map((id) => ({ label: customers[id]?.DisplayName, customerId: id }))));
        this.TableData$ = combineLatest([
            this.store.select(getCustomerTypeFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCustomerTypes)),
            ),
            this.store.select(getCustomersFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCustomers)),
                map((customers) => customers.filter((c) => !c.Deleted)),
            ),
        ]).pipe(
            map(([types, customers]) => {
                return customers.map<ITableData>((c) => {
                    // const type = c.CustomerTypeIds && types.find((ct) => ct.Id === c.CommissionTypeId);
                    return {
                        customerId: c.Id,
                        specificationTypeId: c.CustomerSpecificationTypeId,
                        selectedForm: new FormControl<boolean>(false),
                        name: {
                            __typename: 'string',
                            content: c.DisplayName,
                        },
                        number: {
                            __typename: 'string',
                            content: c.CustomerNo,
                        },
                    };
                });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(map((filter) => (filter[filterNames.customerType]?.length ? rows.filter((row) => row.specificationTypeId && filter[filterNames.customerType].includes(row.specificationTypeId.toString())) : rows))),
            ),
            switchMap((rows) => {
                return this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.name.content, r.number.content].filter(isNotNullOrUndefined).some((s) => stringSearch(s, searchString)))),
                );
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
    ngOnInit(): void {
        firstValueFrom(this.defaultFilter.GetFilterByApp$(FilterApps.SelectCommissionPopup)).then((filterValues) => {
            let temp = {
                [filterNames.customerType]: isNotNullOrUndefined(filterValues[filterNames.customerType]) ? filterValues[filterNames.customerType] : [],
            };
            this.FilterValues$.next(temp);
        });

        this.subscriptions.push(
            this.FilterValues$.pipe(skip(2)).subscribe((val) => {
                if (Object.keys(val).length !== 0) {
                    this.defaultFilter.SetFilterByApp(FilterApps.SelectCommissionPopup, val);
                }
            }),
            this.TableData$.pipe(
                // tap(() => this.dirty = false),
                switchMap((td) => merge(...td.map((t) => t.selectedForm.valueChanges.pipe(map(() => t.selectedForm.dirty))))),
            ).subscribe((v) => {
                if (v) {
                    this.dirty = true;
                }
            }),
            this.TableData$.pipe(
                switchMap((data) =>
                    this.selectedCustomers$.pipe(
                        tap((selectedCustomers) =>
                            data.forEach((d) => {
                                const isSelected = selectedCustomers.some((u) => u.customerId === d.customerId);

                                if (isSelected && (d.selectedForm.enabled || !d.selectedForm.value)) {
                                    d.selectedForm.setValue(true, { emitEvent: false });
                                    d.selectedForm.disable();
                                } else if (!isSelected && (d.selectedForm.disabled || d.selectedForm.value)) {
                                    d.selectedForm.setValue(false, { emitEvent: false });
                                    d.selectedForm.enable();
                                }
                            }),
                        ),
                    ),
                ),
            ).subscribe(),
        );
    }
    RemoveSelected(entity: T) {
        this.deleteClick.emit(entity);
    }
    clickSelected(entity: T) {
        this.entryClick.emit(entity);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    protected readonly CustomerAdministrationMeta = CustomerAdministrationMeta;
}
