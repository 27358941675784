<app-expansion-panel [(Expanded)]="Expanded" [Headline]="Headline" [ScrollableStyle]="true">
    <div *ngIf="ledgerPath" headerSuffix class="reports-theme theme-override ledger-path">
        <a app-button  [href]="ledgerPath" matTooltip="zur Rechnung">
            <fa-icon [icon]="ReportsPageMeta.Icon"></fa-icon>
        </a>
    </div>
    <button app-round-button headerSuffix (click)="$event.stopPropagation() ; onFileDownloadClick() " [Disabled]=" SelectedFiles?.length === 0 || !multiselect"  matTooltip="Herunterladen" aria-label="Herunterladen">
        <fa-icon icon="download"></fa-icon>
    </button>
    <button app-round-button headerSuffix (click)="$event.stopPropagation() ; onFileMoveClick() "  [Disabled]="SelectedFiles?.length === 0 || !multiselect" matTooltip="Verschieben" aria-label="Verschieben">
        <fa-icon icon="share"></fa-icon>
    </button>
    <button app-round-button headerSuffix (click)="$event.stopPropagation(); UploadClick.emit()" *ngIf="!multiselect && UploadMenu" [matMenuTriggerFor]="UploadMenu" matTooltip="Dateien hochladen" aria-label="Dateien hochladen"><fa-icon icon="upload"></fa-icon></button>
    <button app-round-button headerSuffix (click)="$event.stopPropagation(); onEmailClick()" [Disabled]="!SelectedFiles?.length" *ngIf="multiselect" matTooltip="Dateien per Email senden" aria-label="Dateien per Email senden"><fa-icon icon="paper-plane"></fa-icon></button>
    <button *ngIf="multiselect" app-round-button headerSuffix matTooltip="PDFs zusammenführen" aria-label="PDFs zusammenführen" [Disabled]="getMergePdfDisabled$() | async" (click)="onMergePdfClick()">
        <fa-icon icon="copy"></fa-icon>
    </button>
    <button app-round-button headerSuffix (click)="$event.stopPropagation(); multiselect = !multiselect" [Active]="multiselect"><fa-icon icon="check-square" matTooltip="Mehrfachauswahl" aria-label="Mehrfachauswahl"></fa-icon></button>

    <div class="container">
        <!--        <div class="element" *ngFor="let id of valid_fileIds" [matBadgeOverlap]="true" [matBadge]="1" matBadgeColor="primary" matBadgeSize="large" matBadgePosition="center">-->
        <!--            <app-small-file-card [FileId]="id"></app-small-file-card>-->
        <!--        </div>-->
        <p *ngIf="!valid_fileIds || !valid_fileIds.length" class="empty-text">{{ EmptyText }}</p>
        <app-small-file-card
            [Checkbox]="multiselect"
            [CheckboxSelected]="SelectedFiles.includes(id)"
            [RemoveButton]="removeButton && isNotExcludedFromRemove(id)"
            (RemoveClick)="RemoveClick.emit($event)"
            (FileClick)="onFileClick($event)"
            [FileId]="id"
            [setCheckedButton] = "true"
            class="element"
            *ngFor="let id of valid_fileIds"
        ></app-small-file-card>
    </div>
</app-expansion-panel>
