import { DatePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, skip, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { getCommissionEmailHtml } from '../../../dave-commission-module/components/detail-commission/detail-commission.component';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { EmailEntity } from '../../../dave-data-module/entities/email.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../dave-data-module/entities/event.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { ProcessEntity } from '../../../dave-data-module/entities/process.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { ProcessResolver } from '../../../dave-data-module/guards/process.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { EmailDataService } from '../../../dave-data-module/services/email-data.service';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { State } from '../../../dave-data-module/State';
import { BookmarkActionTypes } from '../../../dave-data-module/State/actions/bookmarks.actions';
import {
    DetailEventClearStore,
    DetailEventSetCity,
    DetailEventSetEditing,
    DetailEventSetEventTypeId,
    DetailEventSetNote,
    DetailEventSetPostalCode,
    DetailEventSetPrivate,
    DetailEventSetStreet,
} from '../../../dave-data-module/State/actions/detail-event.actions';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { Person2EntityActionTypes } from '../../../dave-data-module/State/actions/person2entity.action';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getBookmarks } from '../../../dave-data-module/State/selectors/bookmarks.selectors';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomerById, getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEmailConnections } from '../../../dave-data-module/State/selectors/email-connection.selectors';
import {
    getActiveEmployeesSortedByNameStartWithMe,
    getEmployeeDictionary,
    getEmployees,
} from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getEventFormData, getEvents } from '../../../dave-data-module/State/selectors/events.selectors';
import { getOffices } from '../../../dave-data-module/State/selectors/offices.selectors';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getPersonTypes } from '../../../dave-data-module/State/selectors/personType.selectors';
import { getProcessById, getProcessesActive, getProcessFetched } from '../../../dave-data-module/State/selectors/process.selector';
import { getUserToEvents } from '../../../dave-data-module/State/selectors/user-to-event.selector';
import { getUser, getUsers } from '../../../dave-data-module/State/selectors/users.selectors';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { IcsService } from '../../../dave-history-module/services/ics.service';
import { SelectPersonsComponent, SelectPersonsComponentDialogData } from '../../../dave-person-module/components/select-persons/select-persons.component';
import { SelectUserEventDialogComponent, SelectUserEventDialogComponentDialogData } from '../../../dave-select-user/components/select-user-dialogs/select-user-event-dialog/select-user-event-dialog.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { MapDialogComponent } from '../../../dave-utils-module/dave-shared-components-module/components/dialogs/map-dialog/map-dialog.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { Address, DaveHeaderHeight, isNotNullOrUndefined, linkify, OpenHTMLInputPicker, programmaticDownloadAnchor, sameDay, uniqArray } from '../../../helper/helper';
import { AllCommissionMeta, AllProcessMeta, CommissionMeta, ContactBookMeta, CustomerAdministrationMeta, DMSPageMeta, ProcessMeta, UserAdministrationMeta } from '../../../helper/page-metadata';
import { getErrorMessage } from '../../../helper/validation.helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData } from '../../templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { EmailDialogComponent, EmailDialogData } from './email-dialog/email-dialog.component';

@Component({
    selector: 'app-detail-event',
    templateUrl: './detail-event.component.html',
    styleUrls: ['./detail-event.component.scss'],
    providers: [DatePipe],
})
export class DetailEventComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    @ViewChild('personListTemplate') private personListTemplate;
    public OpenHTMLInputPicker = OpenHTMLInputPicker;

    public HeaderHeight = DaveHeaderHeight;
    public ContactBookMeta = ContactBookMeta;
    public UserAdministrationMeta = UserAdministrationMeta;
    public Commissions$: Observable<SelectSearchData[]>;
    public Customers$: Observable<SelectSearchData[]>;
    public ContactPersons$: Observable<SelectSearchData[]>;
    public OpenEmailEditor$ = new Subject<EmailEditorComponentDialogData>();
    public Customer: CustomerEntity = null;
    public ExpandRecievers: boolean = false;
    private changeCustomer$ = new BehaviorSubject<number | null>(null);
    private persons$ = combineLatest([this.store.select(getPersons), this.store.select(getPerson2Entities).pipe(map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer))), this.changeCustomer$]).pipe(
        tap(([, , customerId]) => {
            if (customerId) {
                this.PersonFormControl.setValue('');
                this.CommissionForm.setValue(null);
            }
        }),
        map(([persons, personEntities, customerId]) => persons),
    );

    /** (hot, behavior, NN) Das anzuzeigende Ereignis */
    public Event$: Observable<EventEntity | null> = this.route.paramMap.pipe(
        map((paramMap) => +paramMap.get('eventId')),
        switchMap((eventId) =>
            this.store.select(getEvents).pipe(
                filter(isNotNullOrUndefined),
                map((events) => events.find((event) => event.Id === eventId)),
                tap((event) => {
                    if (!event) {
                        console.error('Could not find EventEntity');
                    }
                }),
                filter(isNotNullOrUndefined),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Folder$ = this.Event$.pipe(
        switchMap((event) => this.folderDataService.getFolderFromEntity(event.Id, FolderTypes.event)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Email$ = this.Event$.pipe(
        switchMap((event) => (event.EmailId ? this.emailDataService.GetEmailById$(event.EmailId) : of(null))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    /** (behavior, NN) Der Nutzer, der das Ereignis angelegt hat */
    private eventUser$ = this.Event$.pipe(
        switchMap((event) =>
            combineLatest([this.store.select(getUsers), this.PS.Has(this.PS.Permission.GetUser)]).pipe(
                filter(isNotNullOrUndefined),
                map(([users, hasPermission]) => (hasPermission ? users.find((user) => user.Id === event.UserId) : new UserEntity())),
                tap((user) => !user && console.error('Could not find UserEntity')),
                filter(isNotNullOrUndefined),
            ),
        ),
    );

    /** (behavior, NN) Der Name des Nutzers, der das Ereignis angelegt hat */
    private eventUserDisplayName$ = this.eventUser$.pipe(
        map((user) => {
            this.displayUser = user;
            return user.DisplayName;
        }),
    );

    /** (behavior, NN) Formatierter String der Zeitspanne zwischen Start- und Enddatum */
    private eventDurationString$ = this.Event$.pipe(
        map((event) => {
            if (!event.EventEndDate) {
                return '';
            }

            const millisecondDiff = event.EventEndDate.getTime() - event.EventDate.getTime();
            const minuteDiff = millisecondDiff / 1000 / 60;
            const hours = Math.floor(minuteDiff / 60);
            const minutes = Math.floor(minuteDiff % 60);

            return hours ? ` + ${hours} Stunde${hours === 1 ? '' : 'n'} und ${minutes} Minute${minutes === 1 ? '' : 'n'}` : minutes ? ` + ${minutes} Minute${minutes === 1 ? '' : 'n'}` : '';
        }),
    );

    /** (hot, behavior) Das Lesezeichen zu diesem Ereignis, wenn vorhanden */
    private bookmark$ = this.Event$.pipe(
        switchMap((event) =>
            this.store.select(getBookmarks).pipe(
                filter(isNotNullOrUndefined),
                map((bookmarks) => bookmarks.find((bookmark) => bookmark.EventId === event.Id)),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    /** (behavior, NN) Ob zu diesem Ereignis ein Lesezeichen existiert */
    private isBookmarked$ = this.bookmark$.pipe(map((bookmark) => !!bookmark));

    /** (behavior, NN) Der Ansprechpartner, zu dem dieses Ereignis angelegt wurde */
    private person$ = this.Event$.pipe(
        switchMap((event) =>
            this.store.select(getPersons).pipe(
                filter(isNotNullOrUndefined),
                map((persons) => persons.find((person) => person.Id === event.PersonId)),
                tap((contactPerson) => !contactPerson && console.log('Could not find PersonEntity')),
                // filter(isNotNullOrUndefined),
            ),
        ),
    );

    /** (behavior, NN) Der Kunde zu dessen Ansprechpartner dieses Ereignis angelegt wurde */
    private customer$ = this.Event$.pipe(
        switchMap((event) => this.store.select(getCustomerById({ id: event.CustomerId }))),
        tap((customer) => !customer && console.log('Could not find CustomerEntity')),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    /** (behavior, NN) Der aktuell eingeloggte Nutzer */
    private user$ = this.store.select(getUser).pipe(filter(isNotNullOrUndefined));

    /** (behavior, NN) Ob das Ereignis vom aktuell eingeloggten Nutzer erstellt wurde */
    private isOwnEvent$ = combineLatest([this.Event$, this.user$]).pipe(map(([event, user]) => event.UserId === user.Id));

    /** (behavior, NN) Ob das Ereignis vom aktuell eingeloggten Nutzer erstellt wurde */
    private commission$ = combineLatest([this.Event$, this.store.select(getCommissions)]).pipe(map(([event, commissions]) => commissions.find((c) => event.CommissionId === c.Id) || null));

    /** (behavior, NN) Alle Ereignisarten */
    private eventTypes$ = this.store.select(getEventTypes).pipe(filter(isNotNullOrUndefined));
    protected process$ = this.Event$.pipe(
        switchMap((event) => (event?.ProcessId ? this.store.select(getProcessById({ id: event.ProcessId })) : of(null))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    public Data$: Observable<{
        contactPerson: PersonEntity;
        customer: CustomerEntity;
        event: EventEntity;
        email: EmailEntity;
        eventDurationString: string;
        eventTypes: EventTypeEntity[];
        eventUserDisplayName: string;
        isBookmarked: boolean;
        isMobile: boolean;
        isOwnEvent: boolean;
        commission: CommissionEntity;
    }> = combineLatest([
        this.person$,
        this.customer$,
        this.Event$,
        this.Email$,
        this.eventDurationString$,
        combineLatest([this.eventTypes$, this.eventUserDisplayName$, this.isBookmarked$, this.breakpoints.MobileQuery, this.isOwnEvent$, this.commission$]),
    ]).pipe(
        map(([contactPerson, customer, event, email, eventDurationString, [eventTypes, eventUserDisplayName, isBookmarked, isMobile, isOwnEvent, commission]]) => ({
            contactPerson,
            customer,
            event,
            email,
            eventDurationString,
            eventTypes,
            eventUserDisplayName,
            isBookmarked,
            isMobile,
            isOwnEvent,
            commission,
        })),
    );

    public CustomerPath = CustomerAdministrationMeta.Path;
    public CustomerIcon = CustomerAdministrationMeta.Icon;
    public CommissionPath = CommissionMeta.Path;
    public AllCommissionPath = AllCommissionMeta.Path;
    public CommissionIcon = CommissionMeta.Icon;
    public DMSPath = DMSPageMeta.Path;
    public DMSIcon = DMSPageMeta.Icon;
    // Eventhandler
    public BookmarkClicked: Subject<void> = new Subject<void>();

    /** Gibt einmalig undefined aus, wenn die Komponente zerstört wird */
    private onDestroy$: Subject<void> = new Subject<void>();

    private eventTypeList: EventTypeEntity[] = null;
    public Editing = false;
    private event: EventEntity;
    private displayUser: UserEntity;
    private displayPerson: PersonEntity;
    // 'updateOn blur' sodass nicht permanent Events geworfen werden
    public TmpDescription = new UntypedFormControl('', { updateOn: 'blur' });
    public CustomerFormControl = new UntypedFormControl('', { updateOn: 'blur' });
    public PersonFormControl = new UntypedFormControl('', { updateOn: 'blur' });
    public TmpEventDate: Moment = null;
    public TmpEventEndDate: Moment = null;
    public TmpEventType: EventTypeEntity = null;
    public EventStartTimeString: string = null;
    public EventEndTimeString: string = null;
    public Private: boolean = null;
    test = null;
    test2 = null;
    // NgRx Observables
    private events$: Observable<EventEntity[]>;
    public UploadDialog: MatDialogRef<any>;
    public MatDialogStateOpen = MatDialogState.OPEN;
    private formData$: Observable<ReturnType<typeof getEventFormData>>;
    // NgRx Data
    private eventList: EventEntity[] = null;
    // Other Data
    private subscriptions: Subscription[] = [];
    private formData: ReturnType<typeof getEventFormData> = null;
    public StyleTagRegEx = new RegExp('<style>(.|\\n)*<\\/style>', 'gi');
    public PreviewOpen = false;
    public PreviewedFileId$ = new BehaviorSubject<number | null>(null);
    public ImageIds: number[];
    public OpenMailDialog$: Subject<void> = new Subject<void>();
    public EventForm = new FormGroup({
        Private: new FormControl<boolean>(false, Validators.required),
        Commission: new FormControl<SelectSearchData>(null),
        Name: new FormControl<string>(''),
        Process: new FormControl<ProcessEntity | null>(null),
    });

    public SearchLocationForm = new UntypedFormControl(null);
    public AddressForm = new FormGroup({
        Street: new FormControl(''),
        PostalCode: new FormControl(''),
        City: new FormControl(''),
        Country: new FormControl(''),
    });
    public AddressTemplateData$: Observable<IDetailListTemplateData> = combineLatest([this.store.select(getOffices).pipe(map((o) => o.filter((o) => o.Street))), this.store.select(getCustomers)]).pipe(
        map(([offices, customers]) => {
            return {
                Headline: 'Adresse',
                HeaderIcon: 'map-marker-alt',
                Properties: [
                    {
                        key: 'Adressen durchsuchen',
                        formControl: this.SearchLocationForm,
                        options: {
                            specialInput: {
                                selectSearch: offices.map((o) => ({
                                    optionValue: o.Id,
                                    optionLabel: [customers.find((c) => c.Id === o.CustomerId)?.Name, '-', o.Street, o.PostalCode, o.City, o.Country].join(' '),
                                })),
                            },
                        },
                    },
                    {
                        key: 'Adresse',
                        options: {
                            type: CustomPropertyType.Location,
                            specialInput: {
                                location: {
                                    value: this.AddressForm.value,
                                    formGroup: this.AddressForm,
                                },
                            },
                        },
                    },
                ],
            };
        }),
    );
    protected ProcessList$ = this.store.select(getProcessesActive);

    @ViewChild(MatTabGroup) private tabGroup?: MatTabGroup;
    constructor(
        private appDialog: AppDialogService,
        private store: Store<State>,
        private router: Router,
        private breakpoints: BreakpointObserverService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        public PS: PermissionService,
        private dialog: MatDialog,
        public CS: CustomerNameService,
        private personResolver: PersonResolver,
        private datePipe: DatePipe,
        protected ics: IcsService,
        private fileDataService: FileDataService,
        private emailDataService: EmailDataService,
        protected cls: CustomLabelService,
        processResolver: ProcessResolver,
        private folderDataService: FolderDataService,
    ) {
        firstValueFrom(this.store.select(getProcessFetched)).then((f) => {
            if (!f) {
                processResolver.resolve();
            }
        });
        this.subscriptions.push(
            this.SearchLocationForm.valueChanges.pipe(skip(1), filter(isNotNullOrUndefined), withLatestFrom(this.store.select(getOffices))).subscribe(([v, o]) => {
                const address = o.find((o) => o.Id === v.Id);
                this.AddressForm.setValue({
                    Street: address.Street,
                    PostalCode: address.PostalCode,
                    City: address.City,
                    Country: address.Country,
                });
            }),
            this.AddressForm.controls.Street.valueChanges.subscribe((Street) => this.store.dispatch(new DetailEventSetStreet({ Street }))),
            this.AddressForm.controls.City.valueChanges.subscribe((City) => this.store.dispatch(new DetailEventSetCity({ City }))),
            this.AddressForm.controls.PostalCode.valueChanges.subscribe((PostalCode) => this.store.dispatch(new DetailEventSetPostalCode({ PostalCode }))),
            this.OpenEmailEditor$.pipe(
                tap((dialogData) => {
                    this.dialog.open(EmailEditorComponent, {
                        ...EmailEditorComponentDialogConfig,
                        data: dialogData,
                    });
                }),
            ).subscribe(),
        );
        this.Commissions$ = combineLatest([this.CustomerFormControl.valueChanges.pipe(startWith(this.CustomerFormControl.value)), store.select(getCommissions)]).pipe(
            map(([customer, commissions]) =>
                commissions
                    .filter((c) => !customer || c.CustomerId === customer.Id)
                    .map((c) => ({
                        Id: c.Id,
                        Name: c.GetDisplayName(),
                    })),
            ),
        );
        this.Customers$ = store.select(getCustomers).pipe(
            map((customers) => customers.filter((c) => !c.Deleted).map((c) => ({ Id: c.Id, Name: c.Name }))),
            map((c) => [{ Id: null, Name: '--' }, ...c]),
        );
        this.ContactPersons$ = combineLatest([
            this.persons$,
            this.store.select(getPerson2Entities).pipe(map((p2es) => p2es?.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Customer))),
            this.store.select(getPerson2Entities).pipe(map((p2es) => p2es?.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Commission))),
            this.Event$.pipe(switchMap((event) => this.store.select(getPerson2Entities).pipe(map((p2es) => p2es?.filter((p2e) => p2e.EntityId === event?.Id && p2e.EntityType === Person2EntityEntityTypeEnum.Event))))),
        ]).pipe(
            switchMap(([persons, p2esCustomer, p2esCommission, p2eEvent]) =>
                combineLatest([this.CustomerFormControl.valueChanges.pipe(startWith(this.CustomerFormControl.value)), this.EventForm.controls.Commission.valueChanges.pipe(startWith(this.EventForm.controls.Commission.value))]).pipe(
                    map(([customer, commission]) => {
                        const contactPersonIds = customer?.Id ? p2esCustomer.filter((p2e) => p2e.EntityId === customer.Id).map((p2e) => p2e.PersonId) : [];
                        const eventPersonIds = p2eEvent.map((p2e) => p2e.PersonId);
                        const commissionPersonIds = commission?.Id ? p2esCommission.filter((p2e) => p2e.EntityId === commission.Id).map((p2e) => p2e.PersonId) : [];
                        return [
                            { Id: null, Name: '--' },
                            ...persons
                                .sort((a, b) => {
                                    if (contactPersonIds.includes(a.Id) !== contactPersonIds.includes(b.Id)) {
                                        return contactPersonIds.includes(a.Id) ? -1 : 1;
                                    }
                                    if (commissionPersonIds.includes(a.Id) !== commissionPersonIds.includes(b.Id)) {
                                        return commissionPersonIds.includes(a.Id) ? -1 : 1;
                                    }
                                    if (eventPersonIds.includes(a.Id) !== eventPersonIds.includes(b.Id)) {
                                        return eventPersonIds.includes(a.Id) ? -1 : 1;
                                    }
                                    return a.CreatedAt.getTime() - b.CreatedAt.getTime();
                                })
                                .map((c) => ({
                                    Id: c.Id,
                                    Name: c.DisplayName,
                                })),
                        ];
                    }),
                ),
            ),
        );
        this.PreviewedFileId$.subscribe((id) => {
            if (id && id > -1) {
                this.PreviewOpen = true;
                this.UploadDialog?.close();
            } else if (id === null) {
                this.PreviewOpen = false;
            }
        });
        this.events$ = this.store.select(getEvents);
        this.formData$ = this.store.select(getEventFormData);

        // Seiteneffekt - Fügt für dieses Ereignis ein Lesezeichen hinzu oder entfernt es
        const bookmarkClicked$ = this.BookmarkClicked.pipe(
            withLatestFrom(this.bookmark$, this.Event$),
            tap(([, bookmark, event]) =>
                bookmark
                    ? store.dispatch(
                          BookmarkActionTypes.RemoveBookmark({
                              BookmarkIds: [bookmark.Id],
                          }),
                      )
                    : store.dispatch(
                          BookmarkActionTypes.AddBookmark({
                              EventId: event.Id,
                          }),
                      ),
            ),
        );

        // Seiteneffekt - Lädt Dateien
        const loadFiles$ = this.Event$.pipe(
            take(1),
            // tap(event => store.dispatch(FilesActionTypes.LoadAllFiles())),
        );

        merge(bookmarkClicked$, loadFiles$).pipe(takeUntil(this.onDestroy$)).subscribe();
    }
    public ShowBeteiligteList$: Observable<PersonEntity[]> = combineLatest([this.store.select(getPersons), this.Event$, this.store.select(getPerson2Entities)]).pipe(
        map(([persons, event, p2e]) => {
            const pIds = p2e?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Event && p.EntityId === event.Id).map((p) => p.PersonId);
            return persons?.filter((p) => pIds.includes(p.Id) && !p.Deleted);
        }),
    );

    public ShowBearbeiterList$: Observable<{ label: string; Id: number }[]> = this.Event$.pipe(
        distinctUntilChanged((a, b) => a.Id === b.Id),
        switchMap((event) => this.store.select(getUserToEvents).pipe(map((u2es) => uniqArray(u2es.filter((u2e) => u2e.EventId === event.Id).map((u2e) => u2e.UserId))))),
        switchMap((userIds) => this.store.select(getActiveEmployeesSortedByNameStartWithMe).pipe(map((employees) => employees.filter((e) => userIds.includes(e.UserId))))),
        map((employees) =>
            employees.map((e) => ({
                label: [e.DisplayName, e.Email].filter((v) => !!v).join(' | '),
                Id: e.Id,
            })),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    get CommissionForm() {
        return this.EventForm.get('Commission');
    }

    get NameForm() {
        return this.EventForm.get('Name');
    }

    ngOnInit() {
        combineLatest([this.Event$, this.store.select(getUser)])
            .pipe(take(1))
            .subscribe(([event, user]) => {
                this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: [event.Id], userId: user.Id } }));
            });
        this.subscriptions.push(
            this.OpenMailDialog$.pipe(
                switchMap(() => combineLatest([this.personResolver.resolve()])),
                withLatestFrom(this.store.select(getPersons), this.store.select(getEventTypes), this.Data$, this.store.select(getToken), this.store.select(getEmailConnections), this.Email$),
                tap(([, persons, eventTypes, data, token, emailConnections, email]) => {
                    const targetEmail = persons?.find((cp) => cp.Id === this.event.PersonId)?.Email;
                    const eventType = eventTypes?.find((et) => et.Id === this.event.EventTypeId);
                    const emailAddress = email?.EmailConnectionId ? emailConnections?.find((ec) => ec.Id === email.EmailConnectionId)?.Username : null;
                    let targetEmails = email?.To?.length > 0 ? email.To.slice() : [];
                    if (targetEmail != null) {
                        targetEmails.push(targetEmail);
                    }
                    if (emailAddress != null) {
                        targetEmails = targetEmails.filter((te) => !(te.toLowerCase() == emailAddress.toLowerCase()));
                    }
                    const emailAdressCC = emailAddress != null ? email?.CarbonCopy?.filter((te) => !(te.toLowerCase() == emailAddress.toLowerCase())) : email?.CarbonCopy;
                    const emailAdressBCC = emailAddress != null ? email?.BlindCarbonCopy?.filter((te) => !(te.toLowerCase() == emailAddress.toLowerCase())) : email?.BlindCarbonCopy;

                    firstValueFrom(combineLatest([this.store.select(getCommissions), this.store.select(getPersons), this.store.select(getPersonTypes), this.store.select(getPerson2Entities), this.cls.getLabels$('Commission')])).then(
                        ([Commissions, Persons, PersonTypes, p2e, commissionLabels]) => {
                            const ptId = PersonTypes.find((pt) => pt.Name.toLowerCase().includes('versicherungsnehmer'))?.Id;
                            this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                                ...EmailEditorComponentDialogConfig,
                                data: {
                                    ReferredEventId: this.event.Id,
                                    TargetEmails: targetEmails,
                                    EmailSubject: this.datePipe.transform(this.event.EventDate) + ' ' + eventType?.Name || '',
                                    // FileAttachmntFileIds: [action.Payload.Id],
                                    CustomerId: this.event.CustomerId,
                                    CommissionId: this.event.CommissionId,
                                    CCEmails: emailAdressCC != null ? emailAdressCC : [],
                                    BCCEmails: emailAdressBCC != null ? emailAdressBCC : [],
                                    EmailHtml: this.event.CommissionId
                                        ? getCommissionEmailHtml(
                                              Commissions.find((c) => c.Id === this.event.CommissionId),
                                              commissionLabels,
                                              Persons.find((p) => {
                                                  const pId = p2e?.find((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === this.event.CommissionId)?.PersonId;
                                                  return p.Id === pId && p.PersonTypeId === ptId;
                                              }),
                                          )
                                        : '',
                                },
                            });
                        },
                    );
                }),
            ).subscribe(),
            this.CustomerFormControl.valueChanges.subscribe((v) => this.changeCustomer$.next(v.Id)),
            this.CustomerFormControl.valueChanges
                .pipe(
                    withLatestFrom(this.store.select(getCustomers)),
                    tap(([value, customers]) => (this.Customer = customers.find((c) => c.Id === value.Id))),
                )
                .subscribe(),
            // siehe NewEventComponent
            this.router.events
                .pipe(
                    filter((event) => event instanceof NavigationStart),
                    filter((event: NavigationStart) => event.url !== this.router.url),
                    tap(() => this.store.dispatch(new DetailEventClearStore())),
                )
                .subscribe(),
            this.TmpDescription.valueChanges.subscribe((value) => this.store.dispatch(new DetailEventSetNote({ Note: value }))),
            this.formData$.subscribe((value) => (this.formData = value)),

            this.eventTypes$.subscribe((value) => {
                this.eventTypeList = value;
                this.isReadyToShow();
            }),
            this.events$.subscribe((value) => {
                this.eventList = value;
                this.isReadyToShow();
            }),
            this.PrivateForm.valueChanges.subscribe((value) => {
                this.store.dispatch(new DetailEventSetPrivate({ Private: value }));
                this.Private = value;
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.onDestroy$.next();
    }
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        const pendingChanges = this.Editing;
        // && ( ToDo: allle inpudfelder mit form-Controls/Groups realisieren und dann auf dirty checken
        // this.TmpDescription.dirty ||
        // this.CustomerFormControl.dirty ||
        // this.ContactPersonFormControl.dirty ||
        // this.EventForm.dirty);
        return !pendingChanges;
    }
    public Sanitize(data: string | IconDefinition) {
        if (data === null) {
            return '';
        }
        return this.sanitizer.bypassSecurityTrustUrl(data.toString());
    }

    public OpenMapDialog(location?: Address) {
        const address = location || {
            Street: this.event.Street,
            PostalCode: this.event.PostalCode,
            City: this.event.City,
            Country: this.event.Country,
        };
        this.dialog.open(MapDialogComponent, {
            ...MapDialogComponent.DefaultConfig,
            data: address,
        });
    }

    OpenMailText(email: EmailEntity) {
        this.dialog.open(EmailDialogComponent, {
            ...EmailDialogComponent.DefaultConfig,
            data: {
                email,
                event: this.event,
            } as EmailDialogData,
        });
    }

    public OpenEdit() {
        // 'emitEvent: false' sodass mit der .valueChanges Subscription keine
        // Endlosschleife entsteht
        firstValueFrom(this.process$).then((process) => {
            this.TmpDescription.setValue(this.formData.Note || this.event.Description, {
                emitEvent: false,
            });
            this.CustomerFormControl.setValue({ Name: '', Id: this.formData.CustomerId || this.event.CustomerId }, { emitEvent: false });
            this.PersonFormControl.setValue({ Name: '', Id: this.formData.Person?.Id || this.event.PersonId }, { emitEvent: false });
            this.TmpEventDate = (this.formData.EventDate && moment(this.formData.EventDate)) || (this.event.EventDate && moment(this.event.EventDate));
            this.TmpEventEndDate = (this.formData.EventEndDate && moment(this.formData.EventEndDate)) || (this.event.EventEndDate && moment(this.event.EventEndDate));
            this.AddressForm.reset({
                Street: this.event.Street,
                PostalCode: this.event.PostalCode,
                City: this.event.City,
                Country: this.event.Country,
            });
            this.TmpEventType = this.formData.EventType || this.eventTypeList?.find((value) => value.Id === this.event.EventTypeId);
            let h = this.event.EventDate.getHours();
            let m = this.event.EventDate.getMinutes();
            this.EventStartTimeString = this.formData.StartTime || `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}`;

            h = this.event.EventEndDate?.getHours() || h;
            m = this.event.EventEndDate?.getMinutes() || m;
            this.EventEndTimeString = this.formData.EndTime || `${h > 9 ? h : '0' + h}:${m > 9 ? m : '0' + m}`;
            this.EventForm.setValue({
                Private: this.formData.Private || this.event.Private,
                Name: this.formData.Name || this.event.Name,
                Commission: {
                    Id: this.formData.CommissionId || this.event.CommissionId,
                    Name: null,
                },
                Process: process || null,
            });
            this.Private = this.formData.Private;
            this.store.dispatch(new DetailEventSetEditing({ Editing: true }));
            this.Editing = true;
        });
    }

    protected sameDay = sameDay;
    public Save() {
        if (
            this.TmpDescription.errors ||
            this.CustomerFormControl.errors ||
            this.PersonFormControl.errors ||
            !this.TmpEventDate ||
            // !this.TmpEventEndDate ||
            !this.TmpEventType ||
            !this.isTimeValid()
        ) {
            return;
        }

        const eventDate = this.TmpEventDate?.toDate();
        const startTimeSplit = this.EventStartTimeString.split(':');
        eventDate.setHours(+startTimeSplit[0]);
        eventDate.setMinutes(+startTimeSplit[1]);

        const eventEndDate = new Date(JSON.parse(JSON.stringify(this.TmpEventEndDate?.toDate() || this.TmpEventDate?.toDate())));
        const endTimeSplit = this.EventEndTimeString.split(':');
        eventEndDate.setHours(+endTimeSplit[0]);
        eventEndDate.setMinutes(+endTimeSplit[1]);

        if (this.TmpEventType.Id !== null && eventDate !== null) {
            this.store.dispatch(
                EventsActionTypes.ModifyEvent({
                    Payload: {
                        id: this.event.Id,
                        customerId: this.CustomerFormControl.value?.Id,
                        personId: this.PersonFormControl.value?.Id,
                        street: this.AddressForm.value.Street,
                        postalCode: this.AddressForm.value.PostalCode,
                        city: this.AddressForm.value.City,
                        country: this.AddressForm.value.Country,
                        hint: null,
                        commissionId: this.CommissionForm.value?.Id,
                        eventTypeId: this.TmpEventType.Id,
                        eventDate: FrontendDate(eventDate),
                        eventEndDate: FrontendDate(eventEndDate),
                        description: this.TmpDescription.value,
                        private: this.Private,
                        processId: this.EventForm.value.Process?.Id || null,
                        name: this.NameForm.value,
                    },
                }),
            );
        }
        this.TmpEventType = this.eventTypeList?.find((value) => value.Id === this.event.EventTypeId);
        this.CloseEdit();
    }

    public CloseEdit() {
        this.store.dispatch(new DetailEventSetEditing({ Editing: false }));
        this.Editing = false;
        this.isReadyToShow();
    }

    DeleteEvent(): void {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: 'Dieses Ereignis und alle dazugehörigen Dateien wirklich löschen?',
                styleDelete: true,
            })
            .subscribe(([result]) => result && this.delete());
    }

    private delete() {
        this.store.dispatch(EventsActionTypes.DeleteEvent({ Payload: this.event.Id }));
        this.events$
            .pipe(skip(1), first()) // Erst aus der Komponente navigieren,
            .subscribe(() => {
                // nachdem die neue Eventliste vom Server zurückgekommen ist
                this.router.navigate(['../'], { relativeTo: this.route });
            });
    }

    // public DeleteFile(file: FileEntity) {
    //     this.appDialog
    //         .OpenConfirmationDialog({
    //             paragraph: `Die Datei ${file.Name} wirklich löschen?`,
    //         })
    //         .subscribe(([result]) => result && this.store.dispatch(FilesActionTypes.DeleteFile({ Payload: file.Id })));
    // }
    //
    // public OnUploadError(event: any) {
    //     console.log(event);
    // }
    //
    // public OnDateChange(date: Date) {
    //     this.store.dispatch(new DetailEventSetDate({ Date: date }));
    //     this.TmpEventDate = date;
    //     this.OnEndDateChange(date);
    // }
    //
    // public OnEndDateChange(date: Date) {
    //     this.store.dispatch(new DetailEventSetEndDate({ EndDate: date }));
    //     this.TmpEventEndDate = date;
    // }

    public OnEventTypeChange(eventType: EventTypeEntity) {
        this.store.dispatch(new DetailEventSetEventTypeId({ EventTypeId: eventType.Id }));
        this.TmpEventType = eventType;
    }

    public Linkify(text: string) {
        return linkify(text);
    }

    get PrivateForm() {
        return this.EventForm.get('Private');
    }

    private isTimeValid() {
        if (this.EventStartTimeString && this.EventEndTimeString && this.EventStartTimeString.length === 5 && this.EventEndTimeString.length === 5) {
            return true;
        }
    }

    private isReadyToShow() {
        if (this.eventList && this.eventList.length > 0 && this.eventTypeList?.length) {
            this.setEventContentByUrl();
            if (!this.Editing) {
                this.TmpEventType = this.eventTypeList?.find((value) => value.Id === this.event.EventTypeId);
            }
            if (this.formData.Editing) {
                this.OpenEdit();
            }
        }
    }

    private setEventContentByUrl() {
        const eventId: number = +this.route.snapshot.paramMap.get('eventId');
        this.event = this.eventList?.find((value) => value.Id === eventId);
    }

    protected getICSFile(): Promise<File> {
        return this.ics.GetICSFileFromEvent(this.event);
    }

    DownloadICS() {
        this.getICSFile().then(
            (file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    programmaticDownloadAnchor(reader.result as string, file.name);
                };
                reader.onerror = (error) => throwError(error);
            },
            (e) => console.error(e),
        );
    }

    CreateTaskDialog() {
        combineLatest([
            this.store.select(getPerson2Entities),
            this.ShowBearbeiterList$,
            this.folderDataService.getFolderFromEntity(this.event.Id, FolderTypes.event).pipe(
                switchMap((folder) => {
                    return folder ? this.fileDataService.GetFilesStateFromFolder$(folder.Id) : of([]);
                }),
            ),
            this.store.select(getEmployeeDictionary),
        ])
            .pipe(take(1))
            .subscribe(([p2e, bearbeiterEmployees, files, employees]) => {
                this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
                    ...DetailTasksComponent.DefaultConfig,
                    data: {
                        InitialValues: {
                            PersonIds: p2e.filter((e) => e.EntityType === Person2EntityEntityTypeEnum.Event && this.event.Id === e.EntityId).map((e) => e.PersonId),
                            DocumentIds: files,
                            EndDate: this.event.EventEndDate,
                            Description: this.event.Description,
                            EmailId: this.event.EmailId,
                            ParentEventId: this.event.Id,
                            CommissionId: this.event.CommissionId,
                            EventTypeId: this.event.EventTypeId,
                            UserIds: uniqArray(bearbeiterEmployees.map((u) => employees[u.Id]?.UserId)),
                        },
                    },
                });
            });
    }

    public OpenPersonDialog(buttonText: string) {
        this.store
            .select(getPerson2Entities)
            .pipe(
                take(1),
                withLatestFrom(this.Event$),
                map(([pes, event]) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Event && pe.EntityId === event.Id).map((pe) => pe.PersonId)),
                withLatestFrom(this.Event$),
            )
            .subscribe(([selectedIds, event]) => {
                this.dialog
                    .open<SelectPersonsComponent, SelectPersonsComponentDialogData, PersonEntity[]>(SelectPersonsComponent, {
                        ...SelectPersonsComponent.DefaultConfig,
                        data: {
                            EntityId: event.Id,
                            ButtonText: buttonText,
                            SelectedPersonIds: selectedIds,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter(isNotNullOrUndefined),
                        withLatestFrom(
                            this.store.select(getPerson2Entities).pipe(
                                filter(isNotNullOrUndefined),
                                map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Event && pe.EntityId === event.Id)),
                            ),
                        ),
                    )
                    .subscribe(([selectedPersons, personEntities]) => {
                        let addIds: number[] = [];
                        let delIds: number[] = [];
                        selectedPersons.forEach((p) => {
                            if (personEntities.filter((pe) => pe.PersonId === p.Id).length === 0) {
                                addIds.push(p.Id);
                            }
                        });

                        personEntities.forEach((pe) => {
                            if (selectedPersons.filter((p) => p.Id === pe.PersonId).length === 0) {
                                delIds.push(pe.Id);
                            }
                        });

                        addIds.forEach((pId) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.AddPerson2Entity({
                                    Payload: {
                                        personId: pId,
                                        entityId: event.Id,
                                        entityType: Person2EntityEntityTypeEnum.Event,
                                    },
                                }),
                            );
                        });

                        delIds.forEach((id) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.DeletePerson2Entity({
                                    Payload: {
                                        id: id,
                                    },
                                }),
                            );
                        });
                    });
            });
    }

    public OpenUserSelect() {
        firstValueFrom(this.Event$).then(({ Id }) => {
            this.dialog.open<SelectUserEventDialogComponent, SelectUserEventDialogComponentDialogData>(SelectUserEventDialogComponent, {
                ...SelectUserEventDialogComponent.DefaultConfig,
                data: {
                    eventId: Id,
                },
            });
        });
    }
    public OpenUploadDialog(FolderId?: number) {
        combineLatest([this.Event$, this.customer$])
            .pipe(take(1))
            .subscribe(([event, customer]) => {
                this.UploadDialog = this.dialog.open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
                    ...DaveFileUploadDialogComponent.DefaultConfig,
                    data: {
                        EventId: event.Id,
                        FolderId,
                    },
                });
            });
    }
    public GetEmployeeRouterLink({ Id }) {
        return ['/', UserAdministrationMeta.Path, Id];
    }
    public GetPersonRouterLink({ Id }) {
        return ['/', ContactBookMeta.Path, Id];
    }

    protected readonly getErrorMessage = getErrorMessage;
    protected compareById(a, b) {
        return a.Id === b.Id;
    }

    protected readonly ProcessMeta = ProcessMeta;
    protected readonly AllProcessMeta = AllProcessMeta;

    OnTimeChange($event: any) {
        console.log('OnTimeChange');
        console.log($event);
        this.EventEndTimeString = $event?.end;
        this.EventStartTimeString = $event?.start;
    }
}
