import { Injectable } from '@angular/core';
import {
    GatewayResponse,
    MaterialToSupplierDeleteRequest,
    MaterialToSupplierEditRequest,
    MaterialToSupplierGetRequest,
} from '@dave/types';
import { MaterialToSupplier, MaterialToSupplierAddRequest } from '@dave/types/dist/proto/erp/materialToSupplier';
import { Store } from '@ngrx/store';
import { ReplaySubject, Subject } from 'rxjs';
import { MaterialToSupplierEntity, MaterialToSupplierEntityFromBackend } from '../entities/materialToSupplier.entity';
import { State } from '../State';
import { BaseActionTypes } from '../State/actions/base.actions';
import { AppGatewayService } from './app-gateway.service';

enum ErrorCodes {
    Add = 'MaterialToSupplier Hinzufügen fehlgeschlagen',
    Load = 'MaterialToSupplier Abrufen fehlgeschlagen',
    Modify = 'MaterialToSupplier Bearbeiten fehlgeschlagen',
    Remove = 'MaterialToSupplier Löschen fehlgeschlagen',
}

@Injectable({
    providedIn: 'root',
})
export class MaterialToSupplierDataService {
    private requestByMaterialIdCache = new Map<number, Subject<MaterialToSupplierEntity[]>>();
    private requestBySupplierIdCache = new Map<number, Subject<MaterialToSupplierEntity[]>>();

    constructor(private store$: Store<State>, private gatewayService: AppGatewayService) {}

    add(addRequest: MaterialToSupplierAddRequest) {
        return this.gatewayService
            .Request({ ErpMaterialToSupplierAdd: addRequest })
            .then((res) => {
                if (Object.keys(res?.Errors || {}).length === 0) {
                    return this.pullIfNecessary(res.ErpMaterialToSupplierAdd.MaterialToSupplier);
                } else {
                    throw res.Errors;
                }
            })
            .catch((err) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Add,
                            Err: err,
                        },
                    }),
                );
            });
    }
    change(request: MaterialToSupplierEditRequest) {
        return this.gatewayService
            .Request({ ErpMaterialToSupplierEdit: request })
            .then((res) => {
                if (Object.keys(res?.Errors || {}).length === 0) {
                    return this.pullIfNecessary(res.ErpMaterialToSupplierEdit.MaterialToSupplier);
                } else {
                    throw res.Errors;
                }
            })
            .catch((err) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Modify,
                            Err: err,
                        },
                    }),
                );
            });
    }
    delete(request: MaterialToSupplierDeleteRequest) {
        return this.gatewayService
            .Request({ ErpMaterialToSupplierDelete: request })
            .then((res) => {
                if (Object.keys(res?.Errors || {}).length === 0) {
                    return this.pullIfNecessary(res.ErpMaterialToSupplierDelete.MaterialToSupplier);
                } else {
                    throw res.Errors;
                }
            })
            .catch((err) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Remove,
                            Err: err,
                        },
                    }),
                );
            });
    }
    getMaterialToSupplierByMaterialId$(materialId: number) {
        if (!this.requestByMaterialIdCache.has(materialId)) {
            this.requestByMaterialIdCache.set(materialId, new ReplaySubject<MaterialToSupplierEntity[]>());
            this.getRequest(materialId).then((response) => {
                this.requestByMaterialIdCache.get(materialId).next(response);
            });
        }
        return this.requestByMaterialIdCache.get(materialId);
    }
    getMaterialToSupplierBySupplierId$(supplierId: number) {
        if (!this.requestBySupplierIdCache.has(supplierId)) {
            this.requestBySupplierIdCache.set(supplierId, new ReplaySubject<MaterialToSupplierEntity[]>());
            this.getRequest(null, supplierId).then((response) => {
                this.requestBySupplierIdCache.get(supplierId).next(response);
            });
        }
        return this.requestBySupplierIdCache.get(supplierId);
    }
    private async pullIfNecessary(m2s: MaterialToSupplier) {
        if (m2s.MaterialId && this.requestByMaterialIdCache.has(+m2s.MaterialId)) {
            const response = await this.getRequest(+m2s.MaterialId);
            this.requestByMaterialIdCache.get(+m2s.MaterialId).next(response);
        }
    }
    private getRequest(materialId?: number, supplierId?: number): Promise<MaterialToSupplierEntity[]> {
        const requestPayload: Omit<MaterialToSupplierGetRequest, 'Page'> = {
            WithDeleted: false,
            PageSize: 10000,
            MaterialId: materialId && materialId+'',
            SupplierId: supplierId && supplierId+'',
        };

        const requests$ = new Promise<{ ErpMaterialToSupplierGet: Partial<GatewayResponse['ErpMaterialToSupplierGet']>; Errors: GatewayResponse['Errors'] }>(async (resolve, reject) => {
            let page = 1;
            const results: GatewayResponse['ErpMaterialToSupplierGet']['MaterialToSuppliers'] = [];
            let errors = {};
            let lastPageReached = false;

            const getPage = async (page: number) => {
                const res = await this.gatewayService.Request({
                    ErpMaterialToSupplierGet: {
                        ...requestPayload,
                        Page: page,
                    },
                });
                if (res.ErpMaterialToSupplierGet?.MaterialToSuppliers) {
                    results.push(...res.ErpMaterialToSupplierGet.MaterialToSuppliers);
                }
                if (res.Errors) {
                    errors = { ...errors, ...res.Errors };
                }
                if (page >= res.ErpMaterialToSupplierGet.PageCount) {
                    lastPageReached = true;
                }
                return res;
            };
            while (!lastPageReached) {
                await getPage(page);
                page++;
            }
            resolve({ ErpMaterialToSupplierGet: { MaterialToSuppliers: results }, Errors: errors });
        });
        return requests$
            .then((res) => {
                if (Object.keys(res?.Errors || {}).length === 0) {
                    return res.ErpMaterialToSupplierGet.MaterialToSuppliers.map(MaterialToSupplierEntityFromBackend);
                } else {
                    throw res.Errors;
                }
            })
            .catch((err) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Load,
                            Err: err,
                        },
                    }),
                );
                return [];
            });
    }
}
