import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl,FormGroup,Validators } from '@angular/forms';
import { MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import moment,{ Moment } from 'moment';
import { BehaviorSubject,firstValueFrom,Observable,of,Subject,Subscription,switchMap } from "rxjs";
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { debounceTime,filter,first,map,shareReplay,startWith,take,takeUntil,tap,withLatestFrom } from 'rxjs/operators';
import { EnumClockInTypeSlug } from '../../../../dave-data-module/entities/clock-in-type.entity';
import { ClockInTypes } from '../../../../dave-data-module/entities/clock-in.entity';
import { CommissionEntity } from '../../../../dave-data-module/entities/commission.entity';
import { VacationStatusEnum } from '../../../../dave-data-module/entities/employee-to-vacation.entity';
import { EmployeeEntity } from '../../../../dave-data-module/entities/employee.entity';
import { EntityRoleSlugEnum,EntityTypesEnum } from '../../../../dave-data-module/entities/entity-role.entity';
import { PartnerTypeEnum } from '../../../../dave-data-module/entities/partner.entity';
import { WorkedTimesEntity } from '../../../../dave-data-module/entities/worked-times.entity';
import {
    DaveMutationChangeClockInArgs,
    DaveMutationDeleteClockInArgs
} from "../../../../dave-data-module/graphql-types";
import { ClockInTypeResolver } from '../../../../dave-data-module/guards/clock-in-type.resolver';
import { ClockInResolver } from '../../../../dave-data-module/guards/clock-in.resolver';
import { CommissionResolver } from '../../../../dave-data-module/guards/commission.resolver';
import { EmployeeToVacationResolver } from '../../../../dave-data-module/guards/employee-to-vacation.resolver';
import { UserToCommissionResolver } from '../../../../dave-data-module/guards/user-to-commission.resolver';
import { WorkDayResolver } from '../../../../dave-data-module/guards/work-day.resolver';
import { WorkedTimesResolver } from '../../../../dave-data-module/guards/worked-times.resolver';
import { FrontendDateTimestamp } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../../dave-data-module/State';
import { getClockInTypes,getClockInTypesFetched } from '../../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getClockIns } from '../../../../dave-data-module/State/selectors/clock-in.selectors';
import { getCommissionById,getCommissions } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeToVacations,getEmployeeToVacationsFetched } from '../../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployeesSortedByNameStartWithMe } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole } from '../../../../dave-data-module/State/selectors/entity-role.selector';
import { getPartner } from '../../../../dave-data-module/State/selectors/partners.selectors';
import { getStatusFromSV } from '../../../../dave-data-module/State/selectors/status-from-sv.selectors';
import { getUserToCommissions } from '../../../../dave-data-module/State/selectors/user-to-commission.selector';
import { getWorkDays } from '../../../../dave-data-module/State/selectors/work-day.selectors';
import {
getWorkedTimes,
getWorkedTimesFetched
} from '../../../../dave-data-module/State/selectors/worked-times.selectors';
import {
    appMatDialogDefaultConfig,
    DEFAULT_START_TIME,
    isNotNullOrUndefined,
    OpenHTMLInputPicker,
    SearchQueriesDebounceTime,
    stringSearch
} from "../../../../helper/helper";
import { CustomLabelService } from "../../../../services/custom-label.service";
import { LoadingService } from '../../../../services/loading.service';
import { ClockInHelperService } from "../../../../services/clock-in-helper.service";
import { CommissionSvStatusCodes } from '../../../../dave-data-module/entities/statusFromSV.entity';
import {
    ComponentCanDeactivate,
    PENDING_CHANGES_DEFAULT_MESSAGE,
} from '../../../../dave-data-module/guards/pending-changes.guard';

export interface TimeTrackerKagetecDialogData {
    DefaultValues?: {
        CommissionId: number;
        CommissionName: string;
        Date: Date;
    };
}
type EmployeeData = EmployeeEntity & { isOnHoliday$: Observable<boolean>; workTimeForm: FormControl<number>; drivingTimeForm: FormControl<number>; badWeatherForm: FormControl<number>; startTimeForm: FormControl<string> };
@Component({
    selector: 'app-time-tracker-kagetec',
    templateUrl: './time-tracker-kagetec.component.html',
    styleUrls: ['./time-tracker-kagetec.component.scss'],
})
export class TimeTrackerKagetecComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '80vw',
        maxHeight: '80vh',
        width: '80vw',
        height: '80vh',
        disableClose: true,
    };
    public OpenHTMLInputPicker = OpenHTMLInputPicker;
    public StartTimeForm = new FormControl<string>(DEFAULT_START_TIME);
    public Page: 1 | 2 = 1;
    public SearchVisible = false;
    public SearchForm = new FormControl<string>('');
    public NewClockinForm = new FormGroup({
        CommissionId: new FormControl<number>(null),
        Note: new FormControl<string>('', {
            updateOn: 'blur',
        }),
        Day: new FormControl<Moment>(moment(new Date()), Validators.required),
    });

    public CommissionName$ = this.NewClockinForm.controls.CommissionId.valueChanges.pipe(
        startWith(null),
        map((v) => v || this.NewClockinForm.controls.CommissionId.value),
        filter(isNotNullOrUndefined),
        switchMap((id) => this.store.select(getCommissionById({ id }))),
        map((c) => c.GetDisplayName()),
    );
    public Commissions$: Observable<CommissionEntity[]> = combineLatest([this.store.select(getCommissions), this.store.select(getStatusFromSV)]).pipe(
        map(([commissions, svStatus]) => {
            const stateId = svStatus?.find((s) => s.Name === CommissionSvStatusCodes.erledigt)?.Id;
            const openCommissions = commissions?.filter((c) => !c.Deleted && c.StatusfromSVId !== stateId).sort((a, b) => b.CreatedAt?.getTime() - a.CreatedAt?.getTime());
            const closedCommissions = commissions?.filter((c) => !c.Deleted && c.StatusfromSVId === stateId).sort((a, b) => b.CreatedAt?.getTime() - a.CreatedAt?.getTime());
            return [...openCommissions, ...closedCommissions]; /*.map<SelectSearchData>((c) => ({
                Name: (c.InterneNummer || '--') + ' | ' + (c.Description || '--') + ' | ' + (c.Auftragsnummer || '--'),
                Id: c.Id,
                SearchContent: c.Description,
            }));*/
        }),
        switchMap((commissions) =>
            this.SearchForm.valueChanges.pipe(
                startWith(this.SearchForm.value),
                debounceTime(SearchQueriesDebounceTime),
                map((searchquery) => (searchquery ? commissions.filter((c) => stringSearch(c.DisplayName, searchquery)) : commissions)),
            ),
        ),
    );

    public DrivingTimeIsIncluded$: Observable<boolean> = this.store.select(getClockInTypes).pipe(
        map((clockInTypes) => {
            return clockInTypes.find((clt) => clt.Slug === EnumClockInTypeSlug.drivingTime)?.IsWorkingTime;
        })
    );

    public IsKagetec$ = this.store.select(getPartner).pipe(
        map((p) => p?.PartnerTypeId === PartnerTypeEnum.Kagetec),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    private onDestroy$ = new Subject<void>();

    public Employees$: Observable<EmployeeData[]> = combineLatest([
        this.store.select(getEmployeesSortedByNameStartWithMe).pipe(filter(isNotNullOrUndefined)),
        this.store.select(getCommissions).pipe(filter(isNotNullOrUndefined)),
        /*        combineLatest([this.store.select(getUser).pipe(filter(isNotNullOrUndefined)), this.store.select(getUserToCommissions).pipe(filter(isNotNullOrUndefined))]).pipe(
            map(([user, userToCommissions]) => userToCommissions.filter(u2c => u2c.Id === user.Id)),
        ),
        this.store.select(getUser),*/
        this.NewClockinForm.controls.CommissionId.valueChanges.pipe(startWith(this.NewClockinForm.controls.CommissionId.value)),
        combineLatest([this.store.select(getEntityRole), this.store.select(getUserToCommissions)]),
    ]).pipe(
        map(([employees, commissions, /*userToCommissionsByUser, user,*/ commission, [entityRoles, allUserToCommissions]]) => {
            const cs: CommissionEntity = commissions.find((c) => c.Id === this.NewClockinForm.controls.CommissionId.value);
            const vorarbeiterRole = entityRoles.find((r) => r.Entity === EntityTypesEnum.Commission && r.Name /*r.Slug*/ === EntityRoleSlugEnum.Vorarbeiter);
            const monteurRole = entityRoles.find((r) => r.Entity === EntityTypesEnum.Commission && r.Name /*r.Slug*/ === EntityRoleSlugEnum.Monteur);
            // const isVorarbeiter = userToCommissionsByUser.some(u2c => u2c.CommissionId === cs.Id && u2c.RoleId === vorarbeiterRole.Id);
            if (!vorarbeiterRole) {
                console.error('Vorarbeiter Rolle nicht gefunden')!;
            }
            if (!monteurRole) {
                console.error('Monteur Rolle nicht gefunden')!;
            }
            const userToCommissionByCommission = allUserToCommissions.filter((u2c) => u2c.CommissionId === cs?.Id);
            return (
                cs
                    ? employees.filter(
                          (e) =>
                              userToCommissionByCommission.some((u2c) => u2c.UserId === e.UserId && (u2c.RoleId === monteurRole.Id || u2c.RoleId === vorarbeiterRole.Id)),
                      )
                    : []
            ).map((e) =>
                Object.assign(e.Clone(), {
                    isOnHoliday$: this.store.select(getEmployeeToVacationsFetched).pipe(
                        filter((v) => !!v),
                        switchMap(() => this.store.select(getEmployeeToVacations)),
                        map((e2vs) => e2vs.filter((e2v) => e2v.EmployeeId === e.Id && e2v.VacationStatus === VacationStatusEnum.Approved)),
                        switchMap((e2vs) =>
                            this.NewClockinForm.controls.Day.valueChanges.pipe(
                                startWith(this.NewClockinForm.controls.Day.value),
                                map((day: Moment) => e2vs.some((e2v) => day.isBetween(e2v.StartDate, e2v.EndDate))),
                            ),
                        ),
                        shareReplay({ refCount: true, bufferSize: 1 }),
                    ),
                    drivingTimeForm: new FormControl(null),
                    badWeatherForm: new FormControl(null),
                    workTimeForm: new FormControl(null),
                    startTimeForm: new FormControl(DEFAULT_START_TIME),
                }),
            );
        }),
        takeUntil(this.onDestroy$),
        shareReplay({ refCount: false, bufferSize: 1 }),
    );
    public SaveDisabled$ = this.Employees$.pipe(
        switchMap((employees) => !employees || employees.length === 0 ? of(true) : combineLatest([
            combineLatest(employees.map(e => [
                e.drivingTimeForm.statusChanges.pipe(startWith(e.drivingTimeForm.status)),
                e.badWeatherForm.statusChanges.pipe(startWith(e.badWeatherForm.status)),
                e.workTimeForm.statusChanges.pipe(startWith(e.workTimeForm.status)),
                e.startTimeForm.statusChanges.pipe(startWith(e.startTimeForm.status)),
            ]).flat()),
            combineLatest(employees.map(e => combineLatest([
                e.workTimeForm.valueChanges.pipe(startWith(e.workTimeForm.value)),
                e.startTimeForm.valueChanges.pipe(startWith(e.startTimeForm.value)),
            ]).pipe(map(requiredValues => requiredValues.every(v => !!v)))))
            ]).pipe(map(([states, employeeRequiredValues]) => states.some(s => s !== 'VALID') || employeeRequiredValues.every(v => !v)))
        ),
    );
    private subs: Subscription[] = [];
    public Loading = false;
    public LoadingResolvers$ = new BehaviorSubject(true);
    constructor(
        private store: Store<State>,
        commissionResolver: CommissionResolver,
        userToCommissionResolver: UserToCommissionResolver,
        e2vResolver: EmployeeToVacationResolver,
        clockInResolver: ClockInResolver,
        workDayResolver: WorkDayResolver,
        workedTimesResolver: WorkedTimesResolver,
        private clockInTypeResolver: ClockInTypeResolver,
        private dialogRef: MatDialogRef<TimeTrackerKagetecComponent>,
        public LoadingService: LoadingService,
        protected cls: CustomLabelService,
        private ClockInHelperService: ClockInHelperService,
        @Inject(MAT_DIALOG_DATA) public DialogData: TimeTrackerKagetecDialogData,
    ) {
        if (this.dialogRef) {
            this.subs.push(this.dialogRef.backdropClick().subscribe(() => {
                this.closeDialog();
            }))
        }
        this.Loading = true;
        combineLatest([commissionResolver.resolve(), userToCommissionResolver.resolve(), e2vResolver.resolve(), clockInTypeResolver.resolve(), clockInResolver.resolve(), workDayResolver.resolve(), workedTimesResolver.resolve()])
            .pipe(take(1))
            .subscribe(() => {
                this.LoadingResolvers$.next(false);
                this.Loading = false
            });
        if (DialogData?.DefaultValues) {
            this.NewClockinForm.setValue({
                CommissionId: DialogData.DefaultValues.CommissionId,
                Note: '',
                Day: moment(DialogData.DefaultValues.Date),
            });
            this.SetPageTo2();
        }
    }

    ngOnInit(): void {
        this.subs.push(
            this.StartTimeForm.valueChanges.pipe().subscribe((startTime) => firstValueFrom(this.Employees$).then((employees) => employees.forEach((e) => e.startTimeForm.setValue(startTime)))),
            // this.Employees$.subscribe(),
        );
    }

    public SetPageTo2() {
        if (this.NewClockinForm.valid) {
            this.LoadingService.startLoading('switch-page-to-2');
            firstValueFrom(this.LoadingResolvers$.pipe(filter((f) => !f))).then(() => {
                this.LoadingService.endLoading('switch-page-to-2');
                // das ist nötig damit das Employee$ Observable zuerst triggert wenn die resolver noch nicht fertig sind würde das sonst gleichzeitig passieren
                setTimeout(() => {
                    firstValueFrom(
                        combineLatest([
                            this.store.select(getWorkedTimesFetched).pipe(filter(f=>f), switchMap(() => this.store.select(getWorkedTimes))),
                            this.store.select(getWorkDays).pipe(map((workdays) => workdays.filter((w) => this.NewClockinForm.controls.Day.value.isSame(w.Timestamp, 'day')))),
                            this.Employees$,
                            this.store.select(getClockInTypes),
                            this.getClockIns$(),
                            this.DrivingTimeIsIncluded$,

                        ]),
                    ).then(([workedTimes, workDays, employees, clockInTypes, clockIns, drivingTimeIsIncluded]) => {
                        const employeeIdWorktimeMap = new Map<number, WorkedTimesEntity[]>();
                        workedTimes.forEach((wt) => {
                            if (wt.CommissionId === this.NewClockinForm.controls.CommissionId.value) {
                                const wd = workDays.find((w) => w.Id === wt.WorkDayId);
                                if (wd) {
                                    const employeeId = employees.find((e) => e.Id === wd.EmployeeId)?.Id;
                                    if (employeeId) {
                                        employeeIdWorktimeMap.set(employeeId, [...(employeeIdWorktimeMap.get(employeeId) || []), wt]);
                                    }
                                }
                            }
                        });
                        const cTypesDriving = [clockInTypes.find((cit) => cit.Slug === EnumClockInTypeSlug.drivingTime).Id, clockInTypes.find((cit) => cit.Slug === EnumClockInTypeSlug.businessTrip).Id];
                        const cTypesWork = [...(drivingTimeIsIncluded ? cTypesDriving : []), clockInTypes.find((cit) => cit.Slug === EnumClockInTypeSlug.workTime).Id, clockInTypes.find((cit) => cit.Slug === EnumClockInTypeSlug.commissionWorkTime).Id, clockInTypes.find((cit) => cit.Slug === EnumClockInTypeSlug.break).Id];
                        const cTypesBadWeather = [clockInTypes.find((cit) => cit.Slug === EnumClockInTypeSlug.badWeather).Id];
                        employees.forEach((e) => {
                            e.workTimeForm.setValue(
                                (employeeIdWorktimeMap
                                    .get(e.Id)
                                    ?.filter((wt) => cTypesWork.includes(wt.ClockInTypeId))
                                    .reduce((acc, wt) => acc + wt.RealTimespan, 0) || 0) /
                                1000 /
                                60 /
                                60 || null
                            );
                            e.drivingTimeForm.setValue(
                                (employeeIdWorktimeMap
                                    .get(e.Id)
                                    ?.filter((wt) => cTypesDriving.includes(wt.ClockInTypeId))
                                    .reduce((acc, wt) => acc + wt.RealTimespan, 0) || 0) /
                                1000 /
                                60 /
                                60 || null
                            );
                            e.badWeatherForm.setValue(
                                (employeeIdWorktimeMap
                                    .get(e.Id)
                                    ?.filter((wt) => cTypesBadWeather.includes(wt.ClockInTypeId))
                                    .reduce((acc, wt) => acc + wt.RealTimespan, 0) || 0) /
                                1000 /
                                60 /
                                60 || null
                            );
                            const firstClockIn = clockIns.filter((ci) => ci.CommissionId === this.NewClockinForm.controls.CommissionId.value && ci.EmployeeId === e.Id && ci.Type === ClockInTypes.Start).sort((a, b) => a.TimeStamp.getTime() - b.TimeStamp.getTime())[0];
                            e.startTimeForm.setValue(firstClockIn ? moment(firstClockIn.TimeStamp).format('HH:mm') : DEFAULT_START_TIME);
                        });
                        this.Page = 2;
                    });
                }, 1);
            });
        }
    }
    getClockIns$() {
        return combineLatest([
            this.NewClockinForm.controls.Day.valueChanges.pipe(startWith(this.NewClockinForm.controls.Day.value)),
            this.NewClockinForm.controls.CommissionId.valueChanges.pipe(startWith(this.NewClockinForm.controls.CommissionId.value)),
        ]).pipe(
            switchMap(([day, commissionId]) => this.store.select(getWorkDays).pipe(map((workdays) => workdays.filter((w) => day.isSame(w.Timestamp, 'day'))))),
            switchMap((workdays) => this.store.select(getClockIns).pipe(map((clockins) => clockins.filter((c) => workdays.some((w) => w.Id === c.WorkDayId))))),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    public Save() {
        this.Loading = true;
        firstValueFrom(this.Employees$).then((employees) =>
            firstValueFrom(
                combineLatest([this.store.select(getClockInTypesFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.clockInTypeResolver.resolve();
                        }
                    }),
                    filter((fetched) => !!fetched),
                    switchMap(() => this.store.select(getClockInTypes)),
                ),
                this.DrivingTimeIsIncluded$
                ])
            ).then(([types, drivingTimeIsIncluded]) => {
                const clockInsToDelete: DaveMutationDeleteClockInArgs[] = [];
                const emps = employees
                    .filter(e => e.startTimeForm.value && e.workTimeForm.value)
                    .map<Observable<DaveMutationChangeClockInArgs[]>>((employee) => {
                        return employee.isOnHoliday$.pipe(first()).pipe(
                            withLatestFrom(this.getClockIns$()),
                            map(([isOnHoliday, clockIns]) => {
                                if (!isOnHoliday) {
                                    const Start = this.NewClockinForm.controls.Day.value.toDate();
                                    const start = employee.startTimeForm.value.split(':');
                                    Start.setHours(+start[0], +start[1], 0, 0);
                                    const End = new Date(Start);
                                    if (employee.drivingTimeForm.value && !drivingTimeIsIncluded) {
                                        End.setTime(End.getTime() + (employee.workTimeForm.value + employee.drivingTimeForm.value) * 60 * 60 * 1000);
                                    } else {
                                        End.setTime(End.getTime() + employee.workTimeForm.value * 60 * 60 * 1000);
                                    }
                                    const clockInIds = clockIns.filter((c) => c.EmployeeId === employee.Id && c.CommissionId === this.NewClockinForm.value.CommissionId).map((c) => c.Id);
                                    clockInIds.reverse();
                                    const payload = {
                                        employeeId: employee.Id,
                                        commissionId: this.NewClockinForm.value.CommissionId,
                                    };
                                    const ret = [];
                                    if (employee.workTimeForm.value || employee.drivingTimeForm.value || employee.badWeatherForm.value) {
                                        ret.push(
                                            {
                                                ...payload,
                                                clockInTypeId: types.find((t) => t.Slug === EnumClockInTypeSlug.workTime)?.Id,
                                                type: ClockInTypes.Start,
                                                timeStamp: FrontendDateTimestamp(Start),
                                                id: clockInIds.pop(),
                                            },
                                            {
                                                ...payload,
                                                clockInTypeId: types.find((t) => t.Slug === EnumClockInTypeSlug.workTime)?.Id,
                                                type: ClockInTypes.End,
                                                timeStamp: FrontendDateTimestamp(End),
                                                workDescription: this.NewClockinForm.value.Note,
                                                id: clockInIds.pop(),
                                            },
                                        );
                                    }
                                    if (employee.drivingTimeForm.value) {
                                        const oneWay = employee.drivingTimeForm.value / 2;
                                        const clockInTypeId = types.find((t) => t.Slug === EnumClockInTypeSlug.drivingTime)?.Id;
                                        ret.push(
                                            {
                                                ...payload,
                                                clockInTypeId,
                                                type: ClockInTypes.Start,
                                                timeStamp: FrontendDateTimestamp(Start),
                                                id: clockInIds.pop(),
                                            },
                                            {
                                                ...payload,
                                                clockInTypeId,
                                                type: ClockInTypes.End,
                                                timeStamp: FrontendDateTimestamp(moment(Start).add(oneWay, 'hour').toDate()),
                                                id: clockInIds.pop(),
                                            },
                                            {
                                                ...payload,
                                                clockInTypeId,
                                                type: ClockInTypes.Start,
                                                timeStamp: FrontendDateTimestamp(moment(End).add(-oneWay, 'hour').toDate()),
                                                id: clockInIds.pop(),
                                            },
                                            {
                                                ...payload,
                                                clockInTypeId,
                                                type: ClockInTypes.End,
                                                timeStamp: FrontendDateTimestamp(End),
                                                id: clockInIds.pop(),
                                            },
                                        );
                                    }
                                    if (employee.badWeatherForm.value) {
                                        const oneWay = employee.drivingTimeForm.value ? employee.drivingTimeForm.value / 2 : 0;
                                        const clockInTypeId = types.find((t) => t.Slug === EnumClockInTypeSlug.badWeather)?.Id;
                                        const badWeatherStart = oneWay == 0 ? FrontendDateTimestamp(Start) : FrontendDateTimestamp(moment(Start).add(oneWay, 'hour').toDate());
                                        const badWeatherEnd =
                                            oneWay == 0
                                                ? FrontendDateTimestamp(moment(Start).add(employee.badWeatherForm.value, 'hour').toDate())
                                                : FrontendDateTimestamp(
                                                      moment(Start)
                                                          .add(oneWay + employee.badWeatherForm.value, 'hour')
                                                          .toDate(),
                                                  );
                                        ret.push(
                                            {
                                                ...payload,
                                                clockInTypeId,
                                                type: ClockInTypes.Start,
                                                timeStamp: badWeatherStart,
                                                id: clockInIds.pop(),
                                            },
                                            {
                                                ...payload,
                                                clockInTypeId,
                                                type: ClockInTypes.End,
                                                timeStamp: badWeatherEnd,
                                                id: clockInIds.pop(),
                                            },
                                        );
                                    }
                                    // clockInIds.forEach((id) => this.store.dispatch(ClockInActionTypes.DeleteClockIn({ Payload: { id } })));
                                    clockInsToDelete.push(...clockInIds.map(id => ({id})));
                                    return ret;
                                } else {
                                    return [];
                                }
                            }),
                        );
                    });
                if (emps.flat().length) {
                    combineLatest(emps.flat())
                        .pipe(first())
                        .subscribe((payload) => {
                            this.ClockInHelperService.dispatchClockInActions(payload.flat().filter((p) => !p.id), payload.flat().filter((p) => !!p.id), clockInsToDelete).subscribe(({loaded, total}) => {
                                if (loaded >= total) {
                                    this.Loading = false;
                                    this.dialogRef.close();
                                }
                            })
                            // this.store.dispatch(ClockInActionTypes.AddClockIns({ Payload: payload.flat().filter((p) => !p.id) }));
                            // this.store.dispatch(ClockInActionTypes.ModifyClockIns({ Payload: payload.flat().filter((p) => !!p.id) }));
                            // // payload
                            // //     .flat()
                            // //     .filter((p) => !!p.id)
                            // //     .forEach((p) => this.store.dispatch(ClockInActionTypes.ModifyClockIn({ Payload: p })));
                            // this.Loading = false;
                            // this.dialogRef.close();
                        });
                } else {
                    this.Loading = false;
                    this.dialogRef.close();
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.subs.forEach((s) => s.unsubscribe());
    }


    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> {
        return this.Employees$.pipe(
            map(emps => {
                return !(this.NewClockinForm.dirty ||
                    emps.some(e => e.workTimeForm.dirty ||
                        e.drivingTimeForm.dirty ||
                        e.badWeatherForm.dirty ||
                        e.startTimeForm.dirty
                    ));
            }
        ))
    }
    closeDialog() {
        if (this.dialogRef) {
            firstValueFrom(this.canDeactivate()).then(canDeactivate => {
                if (canDeactivate  || confirm(PENDING_CHANGES_DEFAULT_MESSAGE)) {
                    this.dialogRef.close();
                }
            });
        }
    }
}
