<h1 mat-dialog-title>Lieferantenzuweisung anpassen/erstellen</h1>
<div class="content" mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field>
            <app-select-search placeholder="Lieferant" required [formControl]="form.controls.customer" [Options]="customers$ | async" [CompareOptions]="compareById"></app-select-search>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="EK-Preis" type="number" [formControl]="form.controls.cost" />
            <span matSuffix>€</span>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Artikelnummer" type="string" [formControl]="form.controls.articlenumber" />
        </mat-form-field>
        <mat-form-field>
            <app-select-search
                placeholder="Hauptwarengruppe"
                required
                [formControl]="form.controls.mainProductGroup"
                [Options]="mainProductGroups$ | async"
                [CompareOptions]="compareById">
            </app-select-search>
        </mat-form-field>
        <mat-form-field>
            <app-select-search
                placeholder="Warengruppe"
                required
                [formControl]="form.controls.productGroup"
                [Options]="productGroups$ | async"
                [CompareOptions]="compareById">
            </app-select-search>
        </mat-form-field>
    </form>
</div>
<mat-dialog-actions>
    <button app-button Inverted Color="cancel" mat-dialog-close="">abbrechen</button>

  <button
    *ngIf="data.customer"
    app-round-button
    (click)="onDelete()"
    mat-dialog-close
    Color="red"
    matTooltip="Lieferantenzuweisung löschen"
  >
    <fa-icon icon="trash"></fa-icon>
  </button>
    <button app-button Color="green" (click)="onSubmitClick()" [Disabled]="form.invalid" [IsLoading]="ls.IsLoading$ | async">Speichern</button>
</mat-dialog-actions>


