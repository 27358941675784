import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { AddReportButtonComponent } from '../add-report-button/add-report-button.component';
import { DaveDoubleIconModule } from '../dave-double-icon/dave-double-icon.module';
import { DaveFileExplorerModule } from '../dave-file-explorer/dave-file-explorer.module';
import { DaveListCardModule } from '../dave-list-card/dave-list-card.module';
import { DaveListModule } from '../dave-list/dave-list.module';
import { DaveLoadingModule } from '../dave-loading/dave-loading.module';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DaveSharedComponentsModule } from '../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { SelectSearchModule } from "../dave-utils-module/select-search/select-search.module";
import { LedgerImportListCardComponent } from "../ledger-import-list-card/ledger-import-list-card.component";
import { TableConfigFormComponent } from '../table-config-form/table-config-form.component';
import { EditMaterialToSupplierDialogComponent } from './components/edit-material-to-supplier-dialog/edit-material-to-supplier-dialog.component';
import { MaterialDetailViewComponent } from './components/material-detail-view/material-detail-view.component';
import {
MaterialLedgerImportListCardComponent
} from './components/material-ledger-import-list-card/material-ledger-import-list-card.component';
import { MaterialListViewComponent } from './components/material-list-view/material-list-view.component';
import { MaterialMainDataPopupComponent } from './components/material-main-data-popup/material-main-data-popup.component';
import { MaterialMainDataComponent } from './components/material-main-data/material-main-data.component';
import {
MaterialPriceHistoryListCardComponent
} from './components/material-price-history-list-card/material-price-history-list-card.component';
import {
MaterialToSupplierListCardComponent
} from './components/material-to-supplier-list-card/material-to-supplier-list-card.component';
import { ResourceDetailViewComponent } from './components/resource-detail-view/resource-detail-view.component';
import { ResourceListComponent } from './components/resource-list/resource-list.component';
import { ResourceMainDataPopupComponent } from './components/resource-main-data-popup/resource-main-data-popup.component';
import { ResourceMainDataComponent } from './components/resource-main-data/resource-main-data.component';
import { ResourceScheduleListCardComponent } from './components/resource-schedule-list-card/resource-schedule-list-card.component';
import { SelectResourcePopupComponent } from './components/select-resource-popup/select-resource-popup.component';
import { SelectResourceComponent } from './components/select-resource/select-resource.component';
import { ResourceDispoRoutingModule } from './resource-dispo-routing.module';


@NgModule({
    declarations: [
        ResourceMainDataComponent,
        ResourceMainDataPopupComponent,
        ResourceListComponent,
        ResourceDetailViewComponent,
        ResourceScheduleListCardComponent,
        SelectResourcePopupComponent,
        SelectResourceComponent,
        MaterialMainDataComponent,
        MaterialDetailViewComponent,
        MaterialMainDataPopupComponent,
        MaterialListViewComponent,
    ],
    imports: [
        CommonModule,
        ResourceDispoRoutingModule,
        DaveSharedComponentsModule,
        AppButtonModule,
        FontAwesomeModule,
        DaveLoadingModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatDialogModule,
        DaveListModule,
        MatCardModule,
        DaveListCardModule,
        DaveFileExplorerModule,
        RouterModule,
        DaveDoubleIconModule,
        MatTooltipModule,
        MatChipsModule,
        ScrollingModule,
        MatTableModule,
        MatSortModule,
        TableVirtualScrollModule,
        MatCheckboxModule,
        MatListModule,
        MatSelectModule,
        AddReportButtonComponent,
        SelectSearchModule,
        LedgerImportListCardComponent,
        MatTabsModule,
        MatButtonModule,
        MatExpansionModule,
        MatIconModule,
        EditMaterialToSupplierDialogComponent,
        MaterialPriceHistoryListCardComponent,
        MaterialLedgerImportListCardComponent,
        MaterialToSupplierListCardComponent,
        MatSidenavModule,
        TableConfigFormComponent,
    ],
    exports: [ResourceScheduleListCardComponent, SelectResourceComponent, ResourceListComponent, MaterialListViewComponent],
})
export class ResourceDispoModule {}
