import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { Moment } from 'moment';
import { Observable, ReplaySubject } from 'rxjs';
import { Address } from '../../../../../helper/helper';
import { getErrorMessage, hasRequiredValidator } from "../../../../../helper/validation.helper";
import { SelectSearchData } from '../../../../select-search/components/select-search-legacy/select-search-legacy.component';
import { SelectSearchOption } from '../../../../select-search/components/select-search/select-search.component';
import { AbstractControlTyped, FormGroupTyped } from '../../../../typings';
import { BreakpointObserverService } from '../../../services/breakpoint-observer.service';
import { CommissionNameService } from '../../../services/commission-name.service';
import { MapDialogComponent } from '../../dialogs/map-dialog/map-dialog.component';
import { CustomPropertyType, IProfileTemplateData } from '../profile-template/profile-template.component';
import { QueryParamsHandling } from '@angular/router';
import {
    EmailEditorComponent, EmailEditorComponentDialogConfig,
    EmailEditorComponentDialogData,
} from '../../../../../dave-email-module/components/email-editor/email-editor.component';

export interface DetailListTemplatePropertySpecialInputType<T = any> {
    select?: { optionValue: any; optionLabel: string; optionIcon?: { iconProp: IconProp; color?: string } }[];
    /**
     * @deprecated use {@link singleSelectSearch} instead
     */
    selectSearch?: { optionValue: number; optionLabel: string }[];
    singleSelectSearch?: {
        optionTemplate?: TemplateRef<any>;
        options?: SelectSearchOption<T>[];
        options$?: Observable<SelectSearchOption<T>[]>;
        compareOptions?: (a: SelectSearchOption<T>, b: SelectSearchOption<T>) => boolean;
        searchFunction?: (search: string, option: SelectSearchOption<T>) => boolean;
    };
    location?: {
        value: Partial<Address>;
        formGroup?: FormGroupTyped<Address> | AbstractControlTyped<Address> | FormGroup | FormControl<Address> ;
        options?: SelectSearchOption<{ searchValues?: string[]; value: Address }>[]
    };
    chipAutocomplete?: {
        MapFn: (option: T) => string;
        CompareFn?: (a: T, b: T) => boolean;
        Options?: ReadonlyArray<any>;
        Options$?: Observable<ReadonlyArray<any>>;
        onClick?: (option) => void;
        onUnknownOptionSubmitted?: (string: string) => void;
        OptionTemplate?: TemplateRef<any>;
        initialPatchDefaultValue?: boolean;
    };
    autocomplete?: {
        MapFn: (option) => string;
        FilterFn: (option, searchString: string) => boolean;
        DeleteFn?: (option) => void;
        Options: ReadonlyArray<any>;
    };
    customTemplate?: TemplateRef<any>;
    date?: boolean | { matDatepickerFilter?: (date) => boolean; dateClass?: (date: moment.Moment) => MatCalendarCellCssClasses };
    boolean?: boolean;
    number?: boolean;
    weekDaySelector?: 'checkbox' | 'number';
    textArea?: { Fill: boolean, MinRows?: number };
    timeSpan?: { formControlFrom: FormControl<Moment>; formControlTo: FormControl<Moment> };
    colorPicker?: boolean;
    fileName?: boolean;
}
export interface IDetailListTemplateDataProperty<T = any> {
    onlyIcon?: IconProp;
    key: string;
    value?: string | Date | boolean | number;
    formControl?: FormControl<T> | UntypedFormControl | AbstractControlTyped<T>;
    hideFormControl?: boolean;
    options?: {
        type?: CustomPropertyType;
        specialInput?: DetailListTemplatePropertySpecialInputType<T>;
        showHint?: string;
        detailDialogData?: DetailDialogData;
        suffix?: string;
        wordBreak?: boolean;
        routerLinkPayload?: {
            routerLink?: string | any[];
            queryParams?: {[key: string]: any};
            queryParamsHandling?: QueryParamsHandling | null;
        }
    };
}
export interface IDetailListTemplateData {
    HeaderIcon?: IconProp;
    ObjectData?: any;
    Headline?: string;
    Subline?: string;
    Properties?: IDetailListTemplateDataProperty[];
}
export const hasRequiredField = hasRequiredValidator;
@Component({
    selector: 'app-detail-list-template',
    templateUrl: './detail-list-template.component.html',
    styleUrls: ['./detail-list-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
})
export class DetailListTemplateComponent implements OnChanges, AfterViewInit {
    protected data: IDetailListTemplateData;
    protected onlyIcons: boolean;
    @Input() set Data(value: IDetailListTemplateData) {
        this.data = value;
        this.onlyIcons = value?.Properties?.every((p) => p?.onlyIcon);
    }
    @Input() Editing = false;
    @Input() Wrapped = true;
    @Input() Inline = false;
    @Input() AutofocusIndex = 0;
    @Output() Submit = new EventEmitter<void>();
    // nur für das html form
    public FormGroup = new UntypedFormGroup({});
    public PropertyType = CustomPropertyType;
    public EditableProperties$ = new ReplaySubject<IDetailListTemplateDataProperty[]>(1);
    public CustomPropertyType = CustomPropertyType;
    public GetErrorMessage = getErrorMessage;
    public HasRequiredField = hasRequiredField;
    public WeekDayNames = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
    constructor(public Dialog: MatDialog, public CNS: CommissionNameService, private rootElement: ElementRef, public BS: BreakpointObserverService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.Data?.currentValue?.Properties) {
            this.EditableProperties$.next(
                (changes.Data.currentValue as DetailListTemplateComponent['Data']).Properties?.filter((property) => (property.formControl || property.options?.specialInput?.location?.formGroup) && property.key).map((v) => {
                    return {
                        ...v,
                        searchSelect: v.options?.specialInput?.selectSearch ? this.MapSelectInputToSelectSearchInput(v.options?.specialInput?.selectSearch) : null,
                    };
                }),
            );
        }
    }

    public OpenMapDialog(location: Partial<Address>) {
        this.Dialog.open(MapDialogComponent, {
            ...MapDialogComponent.DefaultConfig,
            data: location,
        });
    }
    public OpenMailDialog(targets: string[]) {
        this.Dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
            ...EmailEditorComponentDialogConfig,
            data: {
                TargetEmails:targets.filter(v => !!v),
            },
        });
    }

    public autoCompleteValues(property: IDetailListTemplateDataProperty, searchString: string) {
        // searchString kann durch einen unbekannten Bug auch truthy aber kein string sein D277-2006 https://glitchtip.dave-cc.com/dave/issues/41169?project=5
        return typeof searchString === 'string'
            ? property.options?.specialInput.autocomplete.Options.filter((o) => property.options?.specialInput.autocomplete.FilterFn(o, searchString))
            : property.options?.specialInput.autocomplete.Options;
    }

    public OpenDetailDialog(property: IDetailListTemplateDataProperty) {
        this.Dialog.open(ProfileTemplateDetailDialogComponent, {
            data: property.options?.detailDialogData,
            // panelClass: 'popup-dialog',
            autoFocus: false,
            panelClass: 'custom-dialog-class-without-padding',
            minWidth: '20rem',
        });
    }

    public MapSelectInputToSelectSearchInput(values: { optionValue: any; optionLabel: string }[]) {
        return values.map((v) => {
            return {
                Name: v.optionLabel,
                Id: typeof v.optionValue === 'number' ? v.optionValue : -1,
            } as SelectSearchData;
        });
    }
    public SelectOptionIcon = (p: IDetailListTemplateDataProperty) => p.options.specialInput.select.find((s) => s.optionValue === p.formControl.value)?.optionIcon;
    public UpdateArrayValue(formControl: UntypedFormControl, index: number, value: any) {
        const arr: any[] = formControl.value.slice();
        arr[index] = value;
        formControl.setValue(arr);
    }

    ngAfterViewInit(): void {
        const autofocusElements = this.rootElement.nativeElement.getElementsByClassName('autofocus');
        const autoFocusInputs = autofocusElements?.length ? autofocusElements[0].getElementsByTagName('input') : null;
        if (autoFocusInputs?.length) {
            autoFocusInputs[0].focus();
        }
    }

    protected readonly hasRequiredField = hasRequiredField;
}
interface DetailDialogData {
    content?: IProfileTemplateData;
    routingButtonRoute?: any[] | string | null | undefined;
}

@Component({
    selector: 'app-profile-template-detail-dialog',
    template: `
        <app-profile-template [Editing]="false" [Data]="Data.content"></app-profile-template>
        <a *ngIf="Data?.routingButtonRoute" app-round-button mat-dialog-close [routerLink]="Data.routingButtonRoute">
            <fa-icon icon="share"></fa-icon>
        </a>
    `,
    styles: [
        `
            :host {
                display: block;
                position: relative;
            }
            a {
                position: absolute;
                right: 1rem;
                bottom: 1rem;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileTemplateDetailDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public Data: DetailDialogData) {}
}
