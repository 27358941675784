import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppGatewayService } from '../../services/app-gateway.service';
import { DaveActions } from '../actions/actions';
import { State } from '../index';

@Injectable()
export class MaterialPriceHistoryEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayService: AppGatewayService) {}
}
