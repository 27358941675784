<div class="list-icon">
    <span class="unseen" *ngIf="unseen$ | async"></span>
    <fa-icon [icon]="icon" size="lg"></fa-icon>
</div>
<div class="content">
    <div>{{ (changeEntity$ | async)?.CreatedAt | date }}, {{ typeLabel$ | async }} <a *ngIf="user$ | async as user">({{user.DisplayName}})</a></div>
    <strong>{{ entityName$ | async }}</strong>
    <ul *ngIf="(changedData$ | async)?.length" class="changes-list">
        <li *ngFor="let change of changedData$ | async">
            {{ change.label }}
            <ng-container *ngIf="change.newValue$ && change.oldValue$">
                : <ng-container *ngTemplateOutlet="dataLabel; context: { loading$: change.oldValueLoading$, value$: change.oldValue$ }"></ng-container> ->
                <ng-container *ngTemplateOutlet="dataLabel; context: { loading$: change.newValueLoading$, value$: change.newValue$ }"></ng-container>
            </ng-container>
        </li>
    </ul>
</div>

<ng-template #dataLabel let-loading$="loading$" let-value$="value$">
    <app-loading *ngIf="loading$ && loading$ | async; else ready" [Size]="16" style="display: inline-block"></app-loading>
    <ng-template #ready>
        <strong *ngIf="value$ | async as text; else emptyLabel">{{ text }}</strong>
    </ng-template>
</ng-template>

<ng-template #emptyLabel>
    <I>leer</I>
</ng-template>
