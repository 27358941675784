<!--[enableDesktopMode] tut auf desktop nicht da dann das hochladen von Bildern im Unterschrift dialog nicht mehr funktioniert-->
<ejs-pdfviewer
    #pdfViewer
    [serviceUrl]="Service"
    [toolbarSettings]="ToolbarSettings"
    [inkAnnotationSettings]="inkAnnotationSettings"
    [enableDesktopMode]="BS.TouchQuery | async"
    [enableToolbar]="true"
    (documentLoad)="documentLoad()"
    (annotationAdd)="onAnnotationAdd($event)"
    style="height:100%;width:100%;display:block"
></ejs-pdfviewer>
<div class="save-button">
    <button app-round-button *ngIf="showSetCheckedButton$ | async" matTooltip="geprüft setzen" aria-label="Geprüft setzen" (click)="setChecked()" [IsLoading]="LS.IsLoading$ | async"><fa-icon icon="check-to-slot"></fa-icon></button>
    <button app-round-button *ngIf="Editing" matTooltip="Unterschrift hinzufügen" aria-label="Unterschrift hinzufügen" (click)="OpenSignaturePopup();" [Disabled]="checkModeActive"><fa-icon icon="signature"></fa-icon></button>
    <button app-round-button *ngIf="Editing" (click)="toggleAddCheckAnnotations();"><fa-icon [icon]="checkModeActive ? 'times' : 'check'"></fa-icon></button>
    <button app-round-button *ngIf="SaveButton" matTooltip="Speichern" aria-label="Speichern" (click)="Save()" [IsLoading]="LS.IsLoading$ | async"><fa-icon icon="save"></fa-icon></button>
    <button app-round-button matTooltip="Bearbeiten" aria-label=" Bearbeiten" (click)="Edit()" ><fa-icon [icon]="this.Editing ? 'chevron-down' : 'pencil-alt'"></fa-icon></button>
</div>
