import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommentResolver } from '../dave-data-module/guards/comment.resolver';
import { EmployeeResolver } from '../dave-data-module/guards/employee.resolver';
import { EventResolver } from '../dave-data-module/guards/event.resolver';
import { Person2EntityResolver } from '../dave-data-module/guards/person2Entity.resolver';
import { StatusFromBackofficeResolver } from '../dave-data-module/guards/statusFromBackoffice.resolver';
import { ValidTokenGuard } from '../dave-data-module/guards/valid-token.guard';
import { TaskPageMeta } from '../helper/page-metadata';
import { BookmarkResolver } from './../dave-data-module/guards/bookmark.resolver';
import { CommissionResolver } from './../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from './../dave-data-module/guards/customer.resolver';
import { EventTypeResolver } from './../dave-data-module/guards/event-type.resolver';
import { PartnerResolver } from './../dave-data-module/guards/partner.resolver';
import { PersonResolver } from './../dave-data-module/guards/person.resolver';
import { UserResolver } from './../dave-data-module/guards/user.resolver';
import { TaskPageComponent } from './components/task-page/task-page.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [ValidTokenGuard],
        canActivateChild: [ValidTokenGuard],
        data: {
            breadcrumb: TaskPageMeta.Breadcrumb,
            breadcrumbIcon: TaskPageMeta.Icon,
        },
        resolve: {
            ...TaskPageComponent.RequiredResolvers,
        },
        children: [
            {
                path: ':taskId',
                data: { breadcrumb: 'none' },
                component: TaskPageComponent,
            },
            {
                path: '',
                data: { breadcrumb: 'none' },
                component: TaskPageComponent,
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
})
export class DaveTasksRoutingModule {}
