import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Injector, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { isArray } from 'chart.js/helpers';
import moment from 'moment/moment';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, skip, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ITaskBoardSort } from 'src/app/dave-tasks/components/tasks-board/tasks-board.component';
import { BookmarkEntity } from '../../../../dave-data-module/entities/bookmark.entity';
import { EmployeeToVacationEntity, VacationStatusEnum, VacationTypeEnum, VacationTypeEnumNameMap } from '../../../../dave-data-module/entities/employee-to-vacation.entity';
import { EmployeeEntity } from '../../../../dave-data-module/entities/employee.entity';
import { EventTypeNamesEnum } from '../../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../../dave-data-module/entities/event.entity';
import { EmployeeToVacationResolver } from '../../../../dave-data-module/guards/employee-to-vacation.resolver';
import { EmployeeResolver } from '../../../../dave-data-module/guards/employee.resolver';
import { StatusFromBackofficeResolver } from '../../../../dave-data-module/guards/statusFromBackoffice.resolver';
import { State } from '../../../../dave-data-module/State';
import { BookmarkActionTypes } from '../../../../dave-data-module/State/actions/bookmarks.actions';
import { EmployeeToVacationActionTypes } from '../../../../dave-data-module/State/actions/employee-to-vacation.actions';
import { EventsActionTypes } from '../../../../dave-data-module/State/actions/events.actions';
import { getBookmarkFetched, getBookmarks, getUnseenBookmarks } from '../../../../dave-data-module/State/selectors/bookmarks.selectors';
import { getEmployeeDictionary, getEmployees } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getEventTypes } from '../../../../dave-data-module/State/selectors/event-type.selector';
import { getUser, getUsers } from '../../../../dave-data-module/State/selectors/users.selectors';
import { NewVacationComponent, NewVacationComponentDialogData } from '../../../../dave-employee-administration-module/components/vacation/new-vacation/new-vacation.component';
import { EventListEntryComponent } from '../../../../dave-event-list-entry/components/event-list-entry/event-list-entry.component';
import { TaskFilterService } from '../../../../dave-tasks/services/task-filter.service';
import { clacEventFilterAmount, getEventFilterValues, IEventFilterType } from '../../../../helper/event.helper';
import { isNotNullOrUndefined } from '../../../../helper/helper';
import { AbsentMetaIcon, BookmarkIcon, TaskPageMeta, VacationMetaIcon } from '../../../../helper/page-metadata';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../../services/default-filter.service';
import { AppDialogService } from '../../../app-dialog-module/app-dialog.service';
import { FilterCardComponent } from '../../../app-filter-module/filter-card/filter-card.component';
import { BookmarkService } from '../../services/bookmark.service';
import { CustomerNameService } from '../../services/customer-name.service';
import { PermissionService } from '../../services/permission.service';
import { getEmailSettingsTypes } from '../../../../dave-data-module/State/selectors/emailSettingsType.selector';
import { Permission } from '../../../../helper/permission.helper';

interface TabData {
    icon: IconProp;
    searchLabel: string;
    headerButtons?: {
        checkAction?: () => void;
        checkLabel?: string;
        checkIcon?: IconProp;
        disableCheck$?: Observable<boolean>;
        matMenu?: ElementRef<any>;
        matBadge$?: Observable<string | number | undefined | null>;
    }[];
}

interface VacationTableData {
    vacation: EmployeeToVacationEntity;
    employee: EmployeeEntity;
    acceptedList: string[];
    declinedList: string[];
    disableApprove: boolean;
    disableDecline: boolean;
}

@Component({
    selector: 'app-history-bookmarks',
    templateUrl: './history-bookmarks.component.html',
    styleUrls: ['./history-bookmarks.component.scss'],
    providers: [DecimalPipe],
})
export class HistoryBookmarksComponent implements OnDestroy, AfterViewInit {
    @Input() HeaderButtonInverted = true;

    public TaskPageMeta = TaskPageMeta;
    public BookmarkIcon = BookmarkIcon;
    public VacationMetaIcon = VacationMetaIcon;
    public AbsentMetaIcon = AbsentMetaIcon;

    public VacationTypeEnumNameMap = VacationTypeEnumNameMap;
    // Searchbar
    public SearchBarActive = false;
    public SearchForm = new FormControl<string>('');
    public IsSearching = false;
    /** Daten der Tabelle */
    public AbsentDataSource$: Observable<MatTableDataSource<VacationTableData>>;
    private bookmarks$ = this.store.select(getBookmarkFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.store.select(getBookmarks)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public TaskSort$: BehaviorSubject<ITaskBoardSort> = new BehaviorSubject({ sortBy: 'endDate', direction: 'dsc' });
    public DataSource$: Observable<EventEntity[]> = combineLatest([this.bookmarks$, this.store.select(getEventTypes).pipe(filter(isNotNullOrUndefined)),
        this.store.select(getUsers).pipe(filter(isNotNullOrUndefined))]).pipe(
        map(([bookmarks, eventTypes, users]) =>
            bookmarks
                .map((bookmark) => bookmark.Event)
                // .filter((value) => !this.personId || value.PersonId === this.personId)
                .sort((a, b) => {
                    const aDate = +a.EventDate.getTime();
                    const bDate = +b.EventDate.getTime();
                    return bDate - aDate;
                })
                .map((task) => {
                    const userNames = task.UserIds?.map((u) => users.find((p) => p.Id === u)?.DisplayName)
                        .filter((un) => un !== '')
                        .join('; ');

                    const eventType = eventTypes.find((event) => event.Id === task.EventTypeId);

                    return {
                        userNames: userNames ? userNames : '', // eMail?.From,
                        EventType: eventType,
                        Event: task,
                    };
                }),
        ),
        // tap((v) => this.UnseenBookmarkCount$.next(v.filter((e) => !e.Event.LastSeenAt || e.Event.LastSeenAt < e.Event.UpdatedAt).length)),
        switchMap((bookmarks) =>
            this.SearchForm.valueChanges.pipe(
                startWith(''),
                map(() => (this.SearchForm.value || '').toLowerCase()),
                map((searchTerm) =>
                    (searchTerm
                        ? bookmarks.filter((data) => [data.Event?.From, ...(data.Event?.To || []), data.userNames, data.Event?.Hint || '', data.Event?.Description || '', data.Event?.Name || '']
                                .map((value) => value?.trim().toLowerCase())
                                .some((value) => value?.includes(searchTerm)))
                        : bookmarks
                    ).map((data) => data.Event),
                ),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public UnseenBookmarkCount$ = this.store.select(getUnseenBookmarks).pipe(map((b) => b.length));
    public UnseenTaskCount$ = this.bookmarkService.UnseenTasksCount$;
    public VacationDataSource$: Observable<MatTableDataSource<VacationTableData>>;
    public Bookmarks$: Observable<BookmarkEntity[]>;
    private onDestroy$ = new Subject<void>();
    public Permissions = Permission;
    public TaskDataSource$: Observable<number[]>;
    /** Der `MatSort` der Tabelle */
    @ViewChild(MatSort) private matSort?: MatSort;

    /** Das Suchfeld-Element */
    @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;
    @ViewChild('sortMenu') private menuRef?: ElementRef<any>;
    @ViewChild('taskFilterComponent') private taskFilterComponent?: FilterCardComponent;

    public Loaded$: Observable<boolean>;
    public EventTypesEnum = EventTypeNamesEnum;
    public Tabs: TabData[];
    public SelectedTab: TabData;
    public TaskFilterSettings$ = this.taskFilterService.filterSettings$;
    public TaskFilterValues$: BehaviorSubject<IEventFilterType> = getEventFilterValues();
    protected TaskFilterAmount$ = this.TaskFilterValues$.pipe(map(clacEventFilterAmount), shareReplay({ refCount: true, bufferSize: 1 }));

    constructor(
        private taskFilterService: TaskFilterService,
        private store: Store<State>,
        protected router: Router,
        public CS: CustomerNameService,
        private dialog: MatDialog,
        public PS: PermissionService,
        public appDialog: AppDialogService,
        statusFromBackofficeResolver: StatusFromBackofficeResolver,
        employeeToVacationsResolver: EmployeeToVacationResolver,
        employeesResolver: EmployeeResolver,
        private injector: Injector,
        protected bookmarkService: BookmarkService,
        private defaultFilterService: DefaultFilterService,
    ) {
        this.TaskFilterValues$.pipe(takeUntil(this.onDestroy$), skip(2)).subscribe((val) => {
            if (Object.keys(val).length !== 0) {
                this.defaultFilterService.SetFilterByApp(FilterApps.HistoryBookmarkComponentTasks, val);
            }
        });
        combineLatest([this.TaskFilterSettings$, this.defaultFilterService.GetFilterByApp$(FilterApps.HistoryBookmarkComponentTasks)])
            .pipe(take(1))
            .subscribe(([val, filterValues]) => {
                this.TaskFilterValues$.next({
                    [FilterTypes.Priority]: isArray(filterValues[FilterTypes.Priority]) ? filterValues[FilterTypes.Priority] : null,
                    [FilterTypes.Date]:
                        filterValues[FilterTypes.Date] && isArray(filterValues[FilterTypes.Date])
                            ? { from: filterValues[FilterTypes.Date].from && moment(filterValues[FilterTypes.Date].from), to: filterValues[FilterTypes.Date].to && moment(filterValues[FilterTypes.Date].to) }
                            : null,
                    [FilterTypes.CommissionId]: isArray(filterValues[FilterTypes.CommissionId]) ? filterValues[FilterTypes.CommissionId] : null,
                    [FilterTypes.UserId]: isArray(filterValues[FilterTypes.UserId]) ? filterValues[FilterTypes.UserId] : null,
                    [FilterTypes.AutorId]: isArray(filterValues[FilterTypes.AutorId]) ? filterValues[FilterTypes.AutorId] : null,
                    [FilterTypes.EndDate]: filterValues[FilterTypes.EndDate]
                        ? { from: filterValues[FilterTypes.EndDate].from && moment(filterValues[FilterTypes.EndDate].from), to: filterValues[FilterTypes.EndDate].to && moment(filterValues[FilterTypes.EndDate].to) }
                        : null,
                    [FilterTypes.Persons]: null,
                    [FilterTypes.EventType]: null,
                    [FilterTypes.Milestones]: null,
                });
            });
        this.Bookmarks$ = this.store.select(getBookmarks);
        // müssen hier resolved werden da das BE kaputt geht wenn man sie abruft, ohne die Berechtigung dafür zu haben
        this.Loaded$ = combineLatest([
            ...Object.values(EventListEntryComponent.RequiredResolvers).map((r) => this.injector.get(r).resolve()),
            statusFromBackofficeResolver.resolve(),
            employeeToVacationsResolver.resolve(),
            employeesResolver.resolve(),
        ]).pipe(
            take(1),
            map(() => true),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.TaskDataSource$ = combineLatest([
            this.bookmarkService.Tasks$.pipe(
                switchMap((tasks) =>
                    this.TaskFilterValues$.pipe(
                        map((filter) => {
                            console.log({ filter });
                            return tasks.filter((t) => {
                                if (filter[FilterTypes.Date]?.from && (!t.EventDate || filter[FilterTypes.Date].from.isAfter(t.EventDate))) {
                                    return false;
                                }
                                if (filter[FilterTypes.Date]?.to && (!t.EventDate || filter[FilterTypes.Date].to.isBefore(t.EventDate))) {
                                    return false;
                                }
                                if (filter[FilterTypes.EndDate]?.from && (!t.EventEndDate || filter[FilterTypes.EndDate].from.isAfter(t.EventEndDate))) {
                                    return false;
                                }
                                if (filter[FilterTypes.EndDate]?.to && (!t.EventEndDate || filter[FilterTypes.EndDate].to.isBefore(t.EventEndDate))) {
                                    return false;
                                }
                                if (filter[FilterTypes.AutorId]?.length && !filter[FilterTypes.AutorId].some((e) => e.id === t.UserId)) {
                                    return false;
                                }
                                if (filter[FilterTypes.CommissionId]?.length && !filter[FilterTypes.CommissionId].some((e) => e.id === t.CommissionId)) {
                                    return false;
                                }
                                if (filter[FilterTypes.UserId]?.length && !filter[FilterTypes.UserId].some((e) => t.UserIds.includes(+e.id))) {
                                    return false;
                                }
                                if (filter[FilterTypes.Priority]?.length && !filter[FilterTypes.Priority].some((e) => e.id === t.AdditionalData.Priority)) {
                                    return false;
                                }
                                return true;
                            });
                        }),
                    ),
                ),
            ),
            this.store.select(getUsers).pipe(filter(isNotNullOrUndefined)),
        ]).pipe(
            map(([tasks, users]) =>
                tasks
                    // .filter((value) => !this.personId || value.PersonId === this.personId)
                    .sort((a, b) => {
                        const aDate = a.EventEndDate;
                        const bDate = b.EventEndDate;
                        if (aDate === null && bDate === null) {
                            return 0;
                        }
                        if (aDate === null && bDate !== null) {
                            return 1;
                        }
                        if (aDate !== null && bDate === null) {
                            return -1;
                        }
                        return aDate.getTime() - bDate.getTime();
                    })
                    .map((task) => {
                        const userNames = task.UserIds?.map((u) => users.find((p) => p.Id === u)?.DisplayName)
                            .filter((un) => un !== '')
                            .join('; ');

                        return {
                            userNames: userNames ? userNames : '', // eMail?.From,
                            Event: task,
                        };
                    }),
            ),
            // tap((v) => this.UnseenTaskCount$.next(v.filter((e) => !e.Event.LastSeenAt || e.Event.LastSeenAt < e.Event.UpdatedAt).length)),
            switchMap((tasks) =>
                this.TaskSort$.pipe(
                    distinctUntilChanged((a, b) => a.sortBy === b.sortBy && a.direction === b.direction),
                    map((sort) => {
                        switch (sort.sortBy) {
                            case 'endDate':
                                return sort.direction === 'dsc' ? tasks : tasks.slice().reverse();
                            case 'priority':
                                const sortTask = tasks.slice().sort((a, b) => {
                                    if (a.Event.AdditionalData?.Priority === b.Event.AdditionalData?.Priority) {
                                        return 0;
                                    }
                                    if (!a.Event.AdditionalData?.Priority) {
                                        return -1;
                                    }
                                    if (!b.Event.AdditionalData?.Priority) {
                                        return 1;
                                    }
                                    return a.Event.AdditionalData.Priority - b.Event.AdditionalData.Priority;
                                });
                                return sort.direction === 'asc' ? sortTask : sortTask.reverse();
                        }
                    }),
                ),
            ),
            switchMap((tasks) =>
                this.SearchForm.valueChanges.pipe(
                    startWith(''),
                    map(() => (this.SearchForm.value || '').toLowerCase()),
                    map((searchTerm) =>
                        (searchTerm
                            ? tasks.filter((data) => [data.userNames, data.Event?.Hint || '', data.Event?.Description || '', data.Event?.Name || ''].map((value) => value?.trim().toLowerCase()).some((value) => value?.includes(searchTerm)))
                            : tasks
                        ).map((data) => data.Event.Id),
                    ),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        this.VacationDataSource$ = combineLatest([this.store.select(getEmployeeDictionary).pipe(filter(isNotNullOrUndefined)), this.bookmarkService.getVacations(), this.store.select(getUser)]).pipe(
            map(([employees, e2vAbsent, user]) => {
                return new MatTableDataSource<VacationTableData>(
                    e2vAbsent.map((e2v) => {
                        const employee: EmployeeEntity = employees[e2v.EmployeeId];
                        const acceptedList = e2v.UserIdListApproved?.map((ua) => {
                            const e = Object.values(employees).find((e) => e.UserId === ua);
                            return e ? e.Firstname + ' ' + e.Lastname : '';
                        });
                        const declinedList =
                            e2v.Type === VacationTypeEnum.Vacation
                                ? (e2v.UserIdListDeclined || []).map((ua) => {
                                      const e = Object.values(employees).find((e) => e.UserId === ua);
                                      return e ? e.Firstname + ' ' + e.Lastname : '';
                                  })
                                : null;
                        return {
                            vacation: e2v,
                            employee,
                            acceptedList,
                            declinedList,
                            disableApprove: e2v.UserIdListApproved?.includes(user?.Id) || e2v.VacationStatus === VacationStatusEnum.Approved,
                            disableDecline: e2v.UserIdListDeclined?.includes(user?.Id) || e2v.VacationStatus === VacationStatusEnum.Declined,
                        };
                    }),
                );
            }),
            tap((dataSource) => (dataSource.filterPredicate = (data, searchTerm) => [data.employee?.Firstname, data.employee?.Lastname].map((value) => value?.trim().toLowerCase()).some((value) => value?.includes(searchTerm)))),
            switchMap((dataSource) =>
                this.SearchForm.valueChanges.pipe(
                    startWith(''),
                    map( () => (this.SearchForm.value || '').toLowerCase() ),
                    tap((searchTerm) => (dataSource.filter = searchTerm.trim().toLowerCase())),
                    map(() => dataSource),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.AbsentDataSource$ = combineLatest([this.store.select(getEmployees).pipe(filter(isNotNullOrUndefined)), this.bookmarkService.getAbsents(), this.store.select(getUser)]).pipe(
            map(([employees, e2v, user]) => {
                return new MatTableDataSource<VacationTableData>(
                    e2v.map((e) => {
                        const acceptedList = e.UserIdListApproved?.map((ua) => {
                            const e = employees.find((e) => e.UserId === ua);
                            return e ? e.Firstname + ' ' + e.Lastname : '';
                        });
                        const declinedList = null;
                        return {
                            vacation: e,
                            employee: employees.find((em) => em.Id === e.EmployeeId),
                            acceptedList,
                            declinedList,
                            disableApprove: e.UserIdListApproved?.includes(user?.Id),
                            disableDecline: e.UserIdListDeclined?.includes(user?.Id),
                        };
                    }),
                );
            }),
            tap((dataSource) => (dataSource.filterPredicate = (data, searchTerm) => [data.employee?.Firstname, data.employee?.Lastname].map((value) => value?.trim().toLowerCase()).some((value) => value?.includes(searchTerm)))),
            switchMap((dataSource) =>
                this.SearchForm.valueChanges.pipe(
                    startWith(''),
                    map( () => (this.SearchForm.value || '').toLowerCase()),
                    tap((searchTerm) => (dataSource.filter = searchTerm.trim().toLowerCase())),
                    map(() => dataSource),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
    ngAfterViewInit(): void {
        this.Tabs = [
            {
                icon: TaskPageMeta.Icon,
                searchLabel: 'Meine Aufgaben durchsuchen',
                headerButtons: [
                    {
                        checkLabel: 'Sortieren',
                        checkIcon: 'sort',
                        matMenu: this.menuRef,
                        checkAction: () => {},
                    },
                    {
                        checkAction: () => this.clearTasks(),
                        checkLabel: 'Alle Aufgaben gesehen',
                        checkIcon: 'eye',
                        disableCheck$: this.UnseenTaskCount$.pipe(map((t) => !t)),
                    },
                    {
                        checkAction: () => this.taskFilterComponent?.Toggle(),
                        checkLabel: 'Filter',
                        checkIcon: 'filter',
                        matBadge$: this.TaskFilterAmount$.pipe(map((count) => count || null)),
                    },
                ],
            },
            {
                icon: 'bookmark',
                searchLabel: 'Meine Lesezeichen durchsuchen',
                headerButtons: [
                    {
                        checkAction: () => this.clearBookmarks(),
                        checkLabel: 'Alle Lesezeichen gesehen',
                        checkIcon: 'eye',
                        disableCheck$: this.DataSource$.pipe(map((d) => d.every((b) => b.LastSeenAt?.getTime() >= b.UpdatedAt.getTime()))),
                    },
                    {
                        checkAction: () => this.deleteBookmarks(),
                        checkLabel: 'Alle Lesezeichen entfernen',
                        checkIcon: 'check',
                        disableCheck$: this.DataSource$.pipe(map((d) => !d.length)),
                    },
                ],
            },
            {
                icon: VacationMetaIcon,
                searchLabel: 'Urlaubsanträge durchsuchen',
            },
            {
                icon: AbsentMetaIcon,
                searchLabel: 'Abwesenheiten durchsuchen',
            },
        ];
        this.SelectedTab = this.Tabs[0];
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
    public deleteBookmarks() {
        this.bookmarks$.pipe(first()).subscribe((bookmarks) => this.store.dispatch(BookmarkActionTypes.RemoveBookmark({ BookmarkIds: bookmarks.map((d) => d.Id) })));
    }
    private clearTasks() {
        this.TaskDataSource$.pipe(first()).subscribe((tasks) => this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: tasks } })));
    }
    private clearBookmarks() {
        this.bookmarks$.pipe(first()).subscribe((bookmarks) => this.store.dispatch(EventsActionTypes.SetEventSeen({ Payload: { eventIds: bookmarks.map((b) => b.EventId) } })));
    }
    public OpenVacationDialog(id: number, approved: boolean) {
        this.appDialog.OpenVacationConfirmationDialog(id, approved).subscribe();
    }
    public SetAbsentToRead(id: number, approved: boolean) {
        this.store.dispatch(EmployeeToVacationActionTypes.Approve({ Payload: { id, approved } }));
    }
    public SearchButtonClicked() {
        if (!this.SearchBarActive) {
            this.SearchBarActive = true;
            setTimeout(() => this.inputRef.nativeElement.focus(), 30);
        } else {
            if (this.SearchForm.value.length === 0) {
                this.SearchBarActive = false;
            }
        }
    }
    public OpenVacationPopUp(entry: { vacation: EmployeeToVacationEntity } = null) {
        this.dialog.open<NewVacationComponent, NewVacationComponentDialogData>(NewVacationComponent, {
            ...NewVacationComponent.DefaultConfig,
            data: {
                employeeId: entry?.vacation.EmployeeId,
                employeeToVacationId: entry?.vacation.Id,
            },
        });
    }
}
