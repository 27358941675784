import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { Moment } from 'moment/moment';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import SimpleBar, { KnownClassNamesOptions } from 'simplebar';
import { SimplebarAngularComponent } from 'simplebar-angular';
import { BookmarkEntity } from '../../../../dave-data-module/entities/bookmark.entity';
import { EventTypeEntity } from '../../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../../dave-data-module/entities/event.entity';
import { Person2EntityEntityTypeEnum } from '../../../../dave-data-module/entities/person2entity.entity';
import { State } from '../../../../dave-data-module/State';
import { BookmarkActionTypes } from '../../../../dave-data-module/State/actions/bookmarks.actions';
import { getBookmarkByEventId } from '../../../../dave-data-module/State/selectors/bookmarks.selectors';
import { getCustomerById } from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getEventTypes } from '../../../../dave-data-module/State/selectors/event-type.selector';
import { getAllEvents } from '../../../../dave-data-module/State/selectors/events.selectors';
import { getPersons } from '../../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../../dave-data-module/State/selectors/person2entity.selectors';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { IFilterTypeSearchMultiSelectValue } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { CustomerNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { isNotNullOrUndefined } from '../../../../helper/helper';
import { FilterTypes } from '../../../../services/default-filter.service';
export type TimeLineFilter = {
    [FilterTypes.Persons]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.Commissions]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.Users]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.EventTypes]: IFilterTypeSearchMultiSelectValue[];
    [FilterTypes.IsEvent]: boolean;
    [FilterTypes.IsTask]: boolean;
    [FilterTypes.Timespan]: { from: Moment; to: Moment };
};
export type TimelineViewScrollEvent = Event & { target: HTMLElement };

interface EventGroup {
    yearHeadline?: string;
    dayHeadline: string;
    key: string;
    events: Array<EventEntity & { bookmark$: Observable<BookmarkEntity>; eventType: EventTypeEntity; personName: string }>;
}
const queryParamFilterKey = 'filter';
@Component({
    selector: 'app-timeline-view',
    templateUrl: './timeline-view.component.html',
    styleUrls: ['./timeline-view.component.scss'],
})
export class TimelineViewComponent implements AfterViewInit, OnDestroy, OnInit {
    public static getFilterQueryParam(filter: Partial<TimeLineFilter>): Params {
        return {[queryParamFilterKey]: encodeURIComponent(JSON.stringify(filter))};
    }
    public static getFilterFromParams(params: Params): Partial<TimeLineFilter> | null {
        const filter = params[queryParamFilterKey] && decodeURIComponent(params['filter']);
        return filter ? JSON.parse(filter) : null;
    }
    public GroupedEvents$: Observable<EventGroup[]>;
    private page$ = new BehaviorSubject(1);
    private customerId$ = this.route.paramMap.pipe(
        map((params: ParamMap) => +params.get('customerId')),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private filter$: BehaviorSubject<TimeLineFilter> = new BehaviorSubject(null);
    public CanEditCustomer$ = this.customerId$.pipe(switchMap((id) => (id ? this.store.select(getCustomerById({ id })).pipe(map((customer) => customer.CanEdit)) : of(true))));
    @Input() ListView = false;

    @Input() set EventFilter(v: TimeLineFilter) {
        this.filter$.next(v);
    }

    @ViewChild(SimplebarAngularComponent) private simplebar?: SimplebarAngularComponent;
    public Collapsed = [];
    public EventGroupHeights = [];

    private onDestroy$ = new Subject<void>();

    constructor(private store: Store<State>, public CS: CustomerNameService, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
    }

    ngAfterViewInit(): void {
        let scrollHeight;
        fromEvent((this.simplebar.SimpleBar as SimpleBar).getScrollElement(), 'scroll')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((e: TimelineViewScrollEvent) => {
                if (scrollHeight !== e.target.scrollHeight && e.target.clientHeight + e.target.scrollTop >= e.target.scrollHeight - window.innerHeight) {
                    scrollHeight = e.target.scrollHeight;
                    this.page$.next(this.page$.value + 1);
                }
            });
        /**
         * dirty fix to load more pages if initial page is smaller than the wrapper limit 10 pages
         */
        let contentHeightBuffer = -1;
        this.GroupedEvents$.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(1),
        ).subscribe(() => {
            const simplebar = this.simplebar.SimpleBar;
            const contentHeight: number = (simplebar?.contentEl as HTMLElement)?.clientHeight;
            const contentWrapperHeight: number = (simplebar?.contentWrapperEl as HTMLElement)?.clientHeight;
            console.log( { contentWrapperHeight, contentHeight });

            if (contentHeight != null && contentWrapperHeight != null && contentHeight <= contentWrapperHeight && contentHeightBuffer !== contentHeight && this.page$.value < 10) {
                contentHeightBuffer = contentHeight;
                this.page$.next(this.page$.value + 1)
            }
        })
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    ngOnInit(): void {
        this.GroupedEvents$ = combineLatest([
            this.filter$,
            this.customerId$.pipe(distinctUntilChanged()),
            this.store.select(getAllEvents).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getPerson2Entities).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getEventTypes).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getPersons).pipe(filter(isNotNullOrUndefined)),
        ]).pipe(
            map(([eventFilter, customerId, events, p2e, eventTypes, persons]) => {
                const p2eByCustomer = customerId ? p2e.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === customerId) : p2e;
                const p2eEvent = p2e.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Event);
                let filteredEvents = events.filter((event) => !!event.EventDate);
                if (customerId) {
                    filteredEvents = filteredEvents.filter((event) => event.CustomerId === customerId || p2eByCustomer.find((pe) => pe.PersonId === event.PersonId));
                }
                if (eventFilter) {
                    if (eventFilter[FilterTypes.Persons]?.length) {
                        const ids = eventFilter[FilterTypes.Persons].map((u) => u.id);
                        filteredEvents = filteredEvents.filter((e) => ids.includes(e.PersonId) || p2eEvent.some((pe) => pe.EntityId === e.Id && ids.includes(pe.PersonId)));
                    }
                    if (eventFilter[FilterTypes.Users]?.length) {
                        const ids = eventFilter[FilterTypes.Users].map((u) => u.id);
                        filteredEvents = filteredEvents.filter((e) => [...e.UserIds, e.UserId].some((id) => ids.includes(id)));
                    }
                    if (eventFilter[FilterTypes.EventTypes]?.length) {
                        const ids = eventFilter[FilterTypes.EventTypes].map((u) => u.id);
                        filteredEvents = filteredEvents.filter((e) => ids.includes(e.EventTypeId));
                    }
                    if (eventFilter[FilterTypes.Timespan]?.from) {
                        filteredEvents = filteredEvents.filter((e) => eventFilter[FilterTypes.Timespan].from.isSameOrBefore(e.EventDate, 'day'));
                    }
                    if (eventFilter[FilterTypes.Timespan]?.to) {
                        filteredEvents = filteredEvents.filter((e) => eventFilter[FilterTypes.Timespan].to.isSameOrAfter(e.EventDate, 'day'));
                    }
                    if (eventFilter[FilterTypes.Commissions]?.length) {
                        const ids = eventFilter[FilterTypes.Commissions].map((u) => u.id);
                        filteredEvents = filteredEvents.filter((e) => ids.includes(e.CommissionId));
                    }
                    if (!eventFilter[FilterTypes.IsEvent]) {
                        filteredEvents = filteredEvents.filter((e) => e.IsTask);
                    }
                    if (!eventFilter[FilterTypes.IsTask]) {
                        filteredEvents = filteredEvents.filter((e) => !e.IsTask);
                    }
                }
                let lastYear;

                let filterd = filteredEvents
                    .sort((a, b) => b.EventDate.getTime() - a.EventDate.getTime())
                    .map((event) =>
                        Object.assign(event.Clone(), {
                            bookmark$: this.store.select(getBookmarkByEventId({ id: event.Id })),
                            eventType: eventTypes.find((e) => e.Id === event.EventTypeId),
                            personName: persons.find((value) => value.Id === event.PersonId)?.DisplayName,
                        }),
                    );

                let outMap = new Map<string, EventGroup>();

                filterd.forEach((e) => {
                    let k = e.EventDate.toDateString();
                    if (outMap.has(k)) {
                        outMap.get(k).events.push(e);
                    } else {
                        let yearHeadline;
                        if (e.EventDate.getFullYear() < lastYear || !lastYear) {
                            lastYear = e.EventDate.getFullYear();
                            yearHeadline = lastYear;
                        }
                        outMap.set(k, {
                            key: k,
                            events: [e],
                            dayHeadline: this.GetDateString(e.EventDate),
                            yearHeadline,
                        });
                    }
                });

                return Array.from(outMap.values());
            }),
            tap(() => this.page$.next(1)),
            switchMap((groupedEvents) => {
                // sehr simples paging
                return this.page$.pipe(
                    distinctUntilChanged(),
                    map((page) => groupedEvents.filter((g, i, a) => i < page * 6)),
                );
            }),
            takeUntil(this.onDestroy$),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    public Collapse(id: number) {
        if (this.Collapsed[id]) {
            this.Collapsed[id] = null;
        } else {
            this.Collapsed[id] = true;
        }
    }

    public GetDateString(date: Date) {
        switch (date.getMonth()) {
            case 0:
                return '' + date.getDate() + '. Jan';
            case 1:
                return '' + date.getDate() + '. Feb';
            case 2:
                return '' + date.getDate() + '. Mär';
            case 3:
                return '' + date.getDate() + '. Apr';
            case 4:
                return '' + date.getDate() + '. Mai';
            case 5:
                return '' + date.getDate() + '. Jun';
            case 6:
                return '' + date.getDate() + '. Jul';
            case 7:
                return '' + date.getDate() + '. Aug';
            case 8:
                return '' + date.getDate() + '. Sep';
            case 9:
                return '' + date.getDate() + '. Okt';
            case 10:
                return '' + date.getDate() + '. Nov';
            case 11:
                return '' + date.getDate() + '. Dez';
        }
    }

    public RemoveBookmark(bookmarkId: number) {
        this.store.dispatch(
            BookmarkActionTypes.RemoveBookmark({
                BookmarkIds: [bookmarkId],
            }),
        );
    }

    public AddBookmark(EventId: number) {
        this.store.dispatch(BookmarkActionTypes.AddBookmark({ EventId }));
    }

    OpenDetailTaskDialog(taskId?: number) {
        this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                EventId: taskId || null,
            },
        });
    }

    RouteTo(eventId) {
        this.router.navigate([eventId + ''], { relativeTo: this.route });
    }
}
