import { Moment } from 'moment/moment';
import { LedgerImportDocumentTypes } from '../dave-data-module/entities/ledger-import.entity';
import { Address, getAddressString } from './helper';

export function calcLedgerImportDueDate(docType: LedgerImportDocumentTypes, invoiceDate: Moment, paymentTargetFromCustomer?: number) {
    if (invoiceDate && !paymentTargetFromCustomer && (docType === LedgerImportDocumentTypes.Offer || docType === LedgerImportDocumentTypes.Invoice)) {
        return invoiceDate.clone().add(30, 'day');
    }
    return null;
}

export function getLedgerImportTitle(title: string, commissionAddress?: Address) {
    if (commissionAddress) {
        return getAddressString(commissionAddress, false);
    }
    return title;
}
