import { StepState } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy,Component,Input,OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-pseudo-step-header',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, MatButtonModule],
    templateUrl: './pseudo-step-header.component.html',
    styleUrls: ['./pseudo-step-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PseudoStepHeaderComponent {
    constructor() {}

    /** State of the given step. */
    @Input() state: StepState;
    @Input() index: number;
    @Input() disabled = false;

    /** Label of the given step. */
    @Input() label: string;
    @Input() active: boolean;
}
