<div class="wrapper" [class.mobile]="IsMobile$ | async">
    <ng-container *ngIf="!(IsMobile$ | async)" [ngTemplateOutlet]="navList"></ng-container>
    <div class="content-wrapper" *ngIf="!(IsMobile$ | async); else mobile">
        <ng-container *ngTemplateOutlet="listView"></ng-container>
        <ng-container *ngTemplateOutlet="emailView"></ng-container>
    </div>
</div>

<ng-template #noMail>
    <div class="center-content">
        <p>Bitte wählen sie eine Email aus</p>
    </div>
</ng-template>

<ng-template #searchBar>
    <div class="search-bar-title" *ngIf="ShowSearchBar$ | async">
        <mat-form-field floatLabel="never" color="accent">
            <mat-label>Suche nach Emails</mat-label>
            <input type="text" [formControl]="SearchForm" matInput #input/>
            <button class="search-bar-clear-search" (click)="SearchForm.setValue('')" mat-icon-button
                    *ngIf="SearchForm.value">
                <fa-icon icon="times"></fa-icon>
            </button>
        </mat-form-field>
    </div>

    <button
        app-round-button
        Inverted
        class="search-bar-button"
        matTooltipPosition="below"
        [matTooltip]="(ShowSearchBar$ | async) ? 'Ausblenden' : 'Suche'"
        (click)="ShowSearchBar$.next(!ShowSearchBar$.value)"
    >
        <fa-icon [icon]="(ShowSearchBar$ | async) ? 'times' : 'search'"></fa-icon>
    </button>
    <button app-round-button Inverted class="search-bar-button" matTooltipPosition="below" matTooltip="Filtern"
            [matMenuTriggerFor]="filterMenu">
        <fa-icon icon="filter"></fa-icon>
    </button>
    <mat-menu #filterMenu="matMenu">
        <button mat-menu-item (click)="FilterSeen$.next(null)">
            <span>Alle</span>
        </button>
        <button mat-menu-item (click)="FilterSeen$.next(true)">
            <span>Gelesen</span>
        </button>
        <button mat-menu-item (click)="FilterSeen$.next(false)">
            <span>Ungelesen</span>
        </button>
    </mat-menu>

    <mat-divider [vertical]="true" style="height: 100%"></mat-divider>
    <button *ngIf="Multiselect" Color="red" app-round-button Inverted class="search-bar-button"
            matTooltipPosition="below" matTooltip="Löschen" (click)="MultiDelete()">
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button *ngIf="Multiselect" app-round-button Inverted class="search-bar-button" matTooltipPosition="below"
            matTooltip="Auf gelesen setzen" (click)="MultiRead(true)">
        <fa-icon icon="eye"></fa-icon>
    </button>
    <button *ngIf="Multiselect" app-round-button Inverted class="search-bar-button" matTooltipPosition="below"
            matTooltip="Auf ungelesen setzen" (click)="MultiRead(false)">
        <fa-icon icon="eye-slash"></fa-icon>
    </button>
    <button app-round-button Inverted class="search-bar-button" matTooltipPosition="below" matTooltip="Mehrfachauswahl"
            (click)="Multiselect = !Multiselect; SelectedMailIds = []">
        <fa-icon [icon]="Multiselect ? 'times' : 'check-square'"></fa-icon>
    </button>
</ng-template>

<ng-template #listView>
    <mat-card class="email-list">
        <div class="card-header search-bar-header">
            <fa-icon *ngIf="ListViewIcon" class="icon" [icon]="ListViewIcon"></fa-icon>
            <h3 *ngIf="!(ShowSearchBar$ | async)">{{ ListViewText }}</h3>
            <ng-container *ngTemplateOutlet="searchBar"></ng-container>
        </div>
        <ng-container *ngIf="!(IsSearching$ | async)">
            <div class="top-line" *ngIf="(UnseenCount$ | async) != null">
                <div>
                    <p>Ungelesene Nachrichten:</p>
                    <span>{{ UnseenCount$ | async }}</span>
                </div>
            </div>
        </ng-container>
        <div class="full-height clickable-list-container">
            <ng-container *ngIf="(DataSources$ | async)?.get(this.FolderId$.value) as dataSource">
                <ng-container *ngIf="dataSource.emails$ | async as emails">
                    <mat-progress-bar style="width: 100%" mode="indeterminate"
                                      *ngIf="SearchIsPending$ | async"></mat-progress-bar>
                    <cdk-virtual-scroll-viewport
                        (scroll)="ListScrolled()"
                        #scrollViewport
                        *ngIf="emails.length !== 0"
                        itemSize="96"
                        minBufferPx="400"
                        maxBufferPx="700"
                        class="full-height"
                    >
                        <div
                            (click)="!Multiselect ? SetPreviewedEmail(rowData) : null"
                            [class.current]="(PreviewedEmailId$ | async) === rowData?.email?.Id"
                            *cdkVirtualFor="let rowData of emails; let last = last"
                            class="full-width email-list-entry clickable-list-item"
                        >
                            <ng-container *ngIf="Multiselect">
                                <mat-checkbox
                                    #checkbox
                                    (change)="SelectUnselect(rowData, $event.checked)"
                                    [checked]="SelectedMailIds.includes(rowData.email?.Id)"
                                    color="primary"
                                ></mat-checkbox>
                                <div (click)="SelectUnselect(rowData, !checkbox.checked); checkbox.toggle()"
                                     class="click-wrapper"></div>
                            </ng-container>
                            <div class="unseen" *ngIf="!rowData?.email.Seen"></div>
                            <div class="flex-row space-between email-preview-header">
                                <div class="bold cut-text">
                                    {{ dataSource.folderType === 'inbox' ? rowData?.from : rowData?.toEmail }}
                                </div>
                                <div>
                                    <fa-icon [icon]="HistoryMeta.Icon" *ngIf="rowData?.event$ | async"></fa-icon>
                                    <fa-icon icon="paperclip" *ngIf="rowData?.email.HasAttachment"></fa-icon>
                                    <fa-icon icon="triangle-exclamation" *ngIf="rowData?.email.ErrorMessage" matTooltip="E-Mail senden fehlgeschlagen"></fa-icon>
                                    <app-loading *ngIf="rowData?.email.IsSending" [Size]="16" style="display: inline-block;" matTooltip="E-Mail wird versendet"></app-loading>
                                    <ng-container *ngIf="rowData?.email.SendDate">
                                        {{ rowData?.email.SendDate | customDay: null:'shortDate':'shortTime' }}
                                    </ng-container>
                                </div>
                            </div>
                            <div class="subject">{{ rowData?.email.Subject }}</div>
                            <div class="preview">{{ rowData?.email.PreviewText }}</div>
                        </div>
                    </cdk-virtual-scroll-viewport>

                    <table style="width: 100%" *ngIf="emails.length === 0">
                        <tr>
                            <td class="empty-table-text">
                                {{
                                (IsSearching$ | async)
                                    ? SearchForm.value?.length < MinSearchChars
                                        ? 'Mindestens ' + MinSearchChars + ' Zeichen eingeben'
                                        : 'Keine Emails gefunden'
                                    : 'Keine Emails vorhanden'
                                }}
                            </td>
                        </tr>
                    </table>
                </ng-container>
            </ng-container>
            <app-loading *ngIf="!(DataSources$ | async)"></app-loading>
            <app-loading class="lasy-loading-spinner" *ngIf="IsLoadingEmails" Size="75"></app-loading>
        </div>
    </mat-card>
</ng-template>

<ng-template #emailView>
    <mat-card class="email-preview" *ngIf="EventsResolved$ | async else loading">
        <app-email-preview [EmailId]="(PreviewedEmailId$ | async)"></app-email-preview>
    </mat-card>
</ng-template>

<ng-template #mobile>
    <mat-tab-group
        #mobileTabGroup
        mat-align-tabs="center"
        animationDuration="500ms"
        preserveContent
        [selectedIndex]="1"
        (selectedTabChange)="$event.index != 2 ? PreviewedEmailId$.next(null) : null"
    >
        <mat-tab label="Ordner">
            <ng-container *ngTemplateOutlet="navList"></ng-container>
        </mat-tab>
        <mat-tab label="Liste">
            <ng-container *ngTemplateOutlet="listView"></ng-container>
        </mat-tab>
        <mat-tab label="E-Mail">
            <ng-container *ngTemplateOutlet="emailView"></ng-container>
        </mat-tab>
    </mat-tab-group>
</ng-template>
<ng-template #navList>
    <div class="nav-bar full-height" [class.open]="!Hide || (IsMobile$ | async)"
         [class.closed]="Hide && !(IsMobile$ | async)">
        <ngx-simplebar class="full-height">
            <mat-action-list>
                <div style="display: flex" *ngIf="!(IsMobile$ | async)">
                    <button mat-list-item (click)="Hide = !Hide">
                        <fa-icon matListIcon icon="bars"></fa-icon>
                    </button>
                </div>
                <mat-divider></mat-divider>
                <mat-tree [dataSource]="NavList" [treeControl]="NavListTreeControl" class="nav-tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                        <button mat-list-item class="small normal-item" [matTooltip]="Hide ? node.textMenu : ''"
                                matTooltipPosition="right" (click)="node.onClick(node)">
                            <fa-icon [icon]="node.icon"></fa-icon>
                            <div
                                class="content">{{ node.textMenu }}{{ (node.emailUnreadCount$ | async) ? ' (' + (node.emailUnreadCount$ | async) + ')' : '' }}</div>
                        </button>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: NavFolderHasChild">
                        <button mat-list-item class="small" [matTooltip]="Hide ? node.textMenu : ''"
                                matTooltipPosition="right" (click)="node.onClick(node)">
                            <button matTreeNodeToggle mat-icon-button style="height: 2rem; width: 2rem"
                                    *ngIf="!Hide || (IsMobile$ | async)">
                                <fa-icon
                                    [icon]="NavListTreeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"></fa-icon>
                            </button>
                            <fa-icon [icon]="node.icon"></fa-icon>
                            <div class="content" *ngIf="NavListTreeControl.isExpanded(node)">
                                {{ node.textMenu }}{{ (node.emailUnreadCount$ | async) ? ' (' + (node.emailUnreadCount$ | async) + ')' : '' }}
                            </div>
                            <div class="content" *ngIf="!NavListTreeControl.isExpanded(node)">
                                {{ node.textMenu }}{{ (node.childrenUnreadCount$ | async) || (node.emailUnreadCount$ | async) ? ' (' + ((node.childrenUnreadCount$ | async) + (node.emailUnreadCount$ | async)) + ')' : '' }}
                            </div>
                        </button>
                        <mat-action-list *ngIf="NavListTreeControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </mat-action-list>
                    </mat-nested-tree-node>
                </mat-tree>
            </mat-action-list>
        </ngx-simplebar>
    </div>
</ng-template>
<mat-card *ngIf="PreviewOpen" class="new-document-overlay">
    <button app-round-button class="back-button" (click)="PreviewOpen = false">
        <fa-icon icon="chevron-right"></fa-icon>
    </button>
</mat-card>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
