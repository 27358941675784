import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { FolderEntity, FolderEntityFromBackend, FolderEntityFromFileBackend, FolderTypeFromFileBackend } from '../../entities/folder.entity';
import { retryWithBackoff } from '../../helper/http-service.helper';
import { FolderDataService } from '../../services/folder-data.service';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { CommissionActionTypes } from '../actions/commission.actions';
import { CustomerActionTypes } from '../actions/customers.actions';
import { EventsActionTypes } from '../actions/events.actions';
import { FilesActionTypes } from '../actions/files.actions';
import { FolderActionTypes } from '../actions/folder.actions';
import { ResourceActionTypes } from '../actions/resource-dispo/resource.actions';
import { TransmissionActionTypes } from '../actions/transmission.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getFolders } from '../selectors/folder.selectors';
import { ConstructionDiaryActionTypes } from '../actions/construction-diary.actions';

enum ErrorCodes {
    Load = 'Ordner Abrufen fehlgeschlagen',
    Remove = 'Ordner Löschen fehlgeschlagen',
    Modify = 'Ordner ändern fehlgeschlagen',
    Add = 'Ordner hinzufügen fehlgeschlagen',
}

@Injectable()
export class FolderEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private http: HttpClient, private folderResolver: FolderDataService) {}

    PollFolders$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    FolderActionTypes.Poll,
                    EventsActionTypes.AddEvent,
                    EventsActionTypes.DeleteEvent,
                    EventsActionTypes.ModifyEvent,
                    CustomerActionTypes.AddCustomer,
                    CustomerActionTypes.AddCustomerAndOffice,
                    CustomerActionTypes.DeleteCustomer,
                    CustomerActionTypes.ModifyCustomer,
                    CommissionActionTypes.AddCommission,
                    CommissionActionTypes.DeleteCommission,
                    CommissionActionTypes.ModifyCommission,
                    TransmissionActionTypes.AddTransmission,
                    TransmissionActionTypes.DeleteTransmission,
                    TransmissionActionTypes.ChangeTransmission,
                    ResourceActionTypes.Delete,
                    ResourceActionTypes.Create,
                    ResourceActionTypes.Change,
                    FilesActionTypes.ModifyFileSuccess,
                    ConstructionDiaryActionTypes.Add,
                ),
                debounceTime(3000),
                tap(() => {
                    const pollUpdatedAtOffset = new Date().getTime() - 3000 - 30000; // now - debounceTime - 30sec

                    this.folderResolver.pollFolderSince(new Date(pollUpdatedAtOffset));
                    setTimeout(() => {
                        this.folderResolver.pollFolderSince(new Date(pollUpdatedAtOffset));
                    }, 3000);
                    setTimeout(() => {
                        this.folderResolver.pollFolderSince(new Date(pollUpdatedAtOffset));
                    }, 17000);
                }),
            ),
        { dispatch: false },
    );

    GetAllFoldersQuery$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(FolderActionTypes.Load),
                withLatestFrom(this.store$),
                switchMap(([{ Payload }, store]) => {
                    let headers = new HttpHeaders();
                    headers = headers.set('Content-Type', 'application/json');
                    headers = headers.set('Authorization', 'Bearer ' + store.base.token);
                    const params: { [key: string]: string } = Object.create(null);

                    if (Payload.updatedSince) {
                        params['since'] = Payload.updatedSince;
                    }
                    return this.http
                        .get<FolderTypeFromFileBackend[]>(
                            this.gatewayHttpService.GetUrl('folders', 'file') +
                                `${
                                    Object.keys(params).length
                                        ? '?' +
                                          Object.keys(params)
                                              .map((k) => k + '=' + params[k])
                                              .join('&')
                                        : ''
                                }`,
                            {
                                headers,
                            },
                        )
                        .pipe(
                            retryWithBackoff(),
                            map((res) => {
                                if (res && res.length && Payload?.updatedSince) {
                                    return res?.filter((f) => moment(f.updated_at).isAfter(Payload.updatedSince));
                                }
                                return res;
                            }),
                            tap((res) => {
                                if (!Payload?.updatedSince || res.length) {
                                    this.store$.dispatch(
                                        res
                                            ? Payload?.updatedSince
                                                ? FolderActionTypes.UpdateMany({
                                                      Payload: res.map((val) => FolderEntityFromFileBackend(val)),
                                                      updateLatestUpdatedAt: true,
                                                  })
                                                : FolderActionTypes.UpdateAll({
                                                      Payload: res.map((val) => FolderEntityFromFileBackend(val)),
                                                      updateLatestUpdatedAt: true,
                                                  })
                                            : BaseActionTypes.ErrorAction({
                                                  Payload: { ToasterMessage: ErrorCodes.Load },
                                              }),
                                    );
                                }
                            }),
                            catchError((err, caught) => {
                                this.store$.dispatch(
                                    BaseActionTypes.ErrorAction({
                                        Payload: {
                                            ToasterMessage: ErrorCodes.Load,
                                            Err: err,
                                            Caught: caught,
                                        },
                                    }),
                                );
                                return EMPTY;
                            }),
                        );
                }),
            ),
        { dispatch: false },
    );

    GetAllFoldersFromFolderQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActionTypes.LoadFoldersFromFolder),
            withLatestFrom(this.store$),
            switchMap(([action, store]) => {
                let headers = new HttpHeaders();
                headers = headers.set('Content-Type', 'application/json');
                headers = headers.set('Authorization', 'Bearer ' + store.base.token);

                return this.http
                    .get<FolderTypeFromFileBackend[]>(this.gatewayHttpService.GetUrl('folders', 'file') + '?parent_folder=' + action.Payload, {
                        headers,
                    })
                    .pipe(
                        retryWithBackoff(),
                        map((res) => FolderActionTypes.UpdateMany({ Payload: res.map((f) => FolderEntityFromFileBackend(f)) })),
                        catchError((err, caught) =>
                            of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            ),
                        ),
                    );
            }),
        ),
    );

    ModifyFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActionTypes.ModifyFolder),
            withLatestFrom(this.store$.select(getToken), this.store$.select(getFolders)),
            concatMap(([{ Payload }, token, folders]) => {
                const parentId = Payload.parentId === undefined ? folders.find((f) => f.Id === Payload.id).ParentId : Payload.parentId;
                const queryString = `
        mutation {
            changeFolders(
                id: ${JSON.stringify(Payload.id)}
                ${stringifyIfNotNullOrUndefined(Payload, 'name')}
                parentId: ${JSON.stringify(parentId)}
                ${stringifyIfNotNullOrUndefined(Payload, 'entityId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'type')}
                ${stringifyIfNotNullOrUndefined(Payload, 'icon')}
                ${stringifyIfNotNullOrUndefined(Payload, 'shared')}
            ) {
            ${FolderEntity.GqlFields}
          }
        }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: token }).pipe(
                    map((res) =>
                        res && res.changeFolders
                            ? FolderActionTypes.UpdateOne({
                                  Payload: FolderEntityFromBackend(res.changeFolders),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Modify,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DeleteFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActionTypes.DeleteFolder),
            withLatestFrom(this.store$.select(getToken), this.store$.select(getFolders)),
            concatMap(([action, token, folders]) => {
                const queryString = `
            mutation {
                deleteFolders(id: ${action.Payload})
        }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: token }).pipe(
                    map((res) =>
                        res && res.deleteFolders
                            ? FolderActionTypes.RemoveOne({
                                  Payload: action.Payload,
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    AddFolder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FolderActionTypes.AddFolder),
            withLatestFrom(this.store$.select(getToken), this.store$.select(getFolders)),
            concatMap(([{ Payload }, token, folders]) => {
                const queryString = `
        mutation {
            createFolders(
                ${stringifyIfNotNullOrUndefined(Payload, 'name')}
                parentId: ${JSON.stringify(Payload.parentId || null)}
                ${stringifyIfNotNullOrUndefined(Payload, 'entityId')}
                ${stringifyIfNotNullOrUndefined(Payload, 'type')}
                ${stringifyIfNotNullOrUndefined(Payload, 'icon')}
                ${stringifyIfNotNullOrUndefined(Payload, 'shared')}
            ) {
            ${FolderEntity.GqlFields}
          }
        }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: token }).pipe(
                    map((res) =>
                        res && res.createFolders
                            ? FolderActionTypes.UpdateOne({
                                  Payload: FolderEntityFromBackend(res.createFolders),
                              })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );
}
