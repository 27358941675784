import { IconName } from '@fortawesome/fontawesome-common-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FoldersType } from '../graphql-types';
import { BackendDateTimestamp } from '../helper/backend-frontend-conversion.helper';
import { DocumentUserEntityFromBackend } from './document-user.entity';
import { FolderUserEntity, FolderUserEntityFromBackend } from './folder-user.entity';

export enum FolderTypes {
    employee_template,
    employee_root,
    employee,
    customer_root,
    customer_template,
    customer,
    commission_root,
    commission_template,
    commission,
    transmission_root,
    transmission_template,
    transmission,
    event_root,
    event_template,
    event,
    invoice_root,
    root_template,
    offer_root,
    order_root,
    contract_root,
    delivery_note_root,
    assessment_root,
    order_confirmation_root,
    audit_report_root,
    protocol_root,
    document_root,
    construction_diary_root,
    construction_diary_template,
    construction_diary,
    year_template,
    month_template,
    year,
    month,
    email_attachment,
    scans,
    payslip_root,
    medical_certificates_root,
    resource_root,
    resource_template,
    resource,
    process,
    process_root,
}
export const getFolderTypeString = (type: FolderTypes) => Object.keys(FolderTypes)[Object.values(FolderTypes).indexOf(type)];
export const FolderEntityFromBackend = (res: FoldersType): FolderEntity =>
    new FolderEntity(
        res.id,
        res.name,
        res.parent_Id,
        res.icon ? (res.icon as IconName) : null,
        res.entity_Id,
        res.type in FolderTypes ? FolderTypes[res.type] : null,
        res.user_Id,
        res.partner_Id,
        res.created_at && BackendDateTimestamp(res.created_at),
        res.updated_at && BackendDateTimestamp(res.updated_at),
        res.deleted_at && BackendDateTimestamp(res.deleted_at),
        res.users.map(u => FolderUserEntityFromBackend(u)),
        res.can_edit,
    );
export const FolderEntityFromFileBackend = (res: FolderTypeFromFileBackend): FolderEntity =>
    new FolderEntity(
        res.id,
        res.name,
        res.parent_id,
        res.icon ? (res.icon as IconName) : null,
        res.entity_id,
        // @ts-ignore
        res.type in FolderTypes ? FolderTypes[res.type] : null,
        res.user_id,
        res.partner_id,
        res.created_at && BackendDateTimestamp(res.created_at),
        res.updated_at && BackendDateTimestamp(res.updated_at),
        res.deleted_at && BackendDateTimestamp(res.deleted_at),
        res.users? res.users.map(u => FolderUserEntityFromBackend(u)) : null,
        res.can_edit,
        res.type === FolderTypes[FolderTypes.email_attachment]
    );
export class FolderEntity {
    public static EntityPropertyNames: Map<keyof FolderEntity, string> = new Map([
    ['Name', 'Name'],
])
    constructor(
        public Id: number = null,
        public Name: string = null,
        public ParentId: number = null,
        public Icon: IconProp | null = null,
        public EntityId: number = null,
        public Type: FolderTypes = null,
        public UserId: number = null,
        public PartnerId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
        public Users: FolderUserEntity[] | null = null,
        public CanEdit: boolean = false,
        public Hidden: boolean = false,
    ) {}
    public static readonly GqlFields =`
        created_at
        deleted_at
        entity_Id
        icon
        id
        name
        parent_Id
        partner_Id
        type
        updated_at
        user_Id
        users {
            ${FolderUserEntity.GqlFields}
        }
        can_edit
    `;

    public Clone(override: FolderEntityAttributes = {}): FolderEntity {
        const attr = { ...this, ...override };
        return Object.assign(new FolderEntity(), attr);
    }
}

export interface FolderEntityAttributes {
    Id?: number;
    Name?: string;
    ParentId?: number;
    Icon?: IconProp;
    EntityId?: number;
    Type?: FolderTypes;
    UserId?: number;
    PartnerId?: number;
    CreatedAt?: Date;
    UpdatedAt?: Date;
    DeletedAt?: Date;
    Users?: FolderUserEntity[] | null;
    CanEdit?: boolean;
    Hidden?: boolean;
}
export interface FolderTypeFromFileBackend {
    can_edit: boolean;
    created_at: string;
    deleted_at: string | null;
    entity_id: number | null;
    icon: string | null;
    id: number;
    name: string;
    parent_id: number | null;
    partner_id: number | null;
    type: string | null;
    updated_at: string;
    user_id: number | null;
    users: any;
}
