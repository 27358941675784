import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ResolveData, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { map, share, skip, take, tap, withLatestFrom } from 'rxjs/operators';
import { NewOnSiteEventComponent, NewOnSiteEventComponentDialogData } from 'src/app/dave-commission-module/components/new-on-site-event/new-on-site-event.component';
import { PersonEntity, Salutation } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { BookmarkResolver } from '../../../dave-data-module/guards/bookmark.resolver';
import { CommentResolver } from '../../../dave-data-module/guards/comment.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { ComplaintTypeResolver } from '../../../dave-data-module/guards/complaintType.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { EventTypeResolver } from '../../../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../../../dave-data-module/guards/event.resolver';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { PermissionsResolver } from '../../../dave-data-module/guards/permissions.resolver';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../../../dave-data-module/guards/person2Entity.resolver';
import { PersonTypeResolver } from '../../../dave-data-module/guards/personType.resolver';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { State } from '../../../dave-data-module/State';
import { PersonActionTypes } from '../../../dave-data-module/State/actions/person.actions';
import { getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { SelectCustomerPersonDialogComponent, SelectCustomerDialogComponentDialogData } from '../../../dave-select-user/components/select-user-dialogs/select-customer-person-dialog/select-customer-person-dialog.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { CommissionNameService } from '../../../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { RoutingHistoryService } from '../../../dave-utils-module/dave-shared-components-module/services/routing-history.service';
import { AllCommissionMeta, CommissionMeta, CustomerAdministrationMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { PersonDetailFormComponent } from '../person-detail-form/person-detail-form.component';

@Component({
    selector: 'app-detail-person',
    templateUrl: './detail-person.component.html',
    styleUrls: ['./detail-person.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailPersonComponent implements ComponentCanDeactivate, OnInit, OnDestroy {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        permissions: PermissionsResolver,
        persons: PersonResolver,
        personTypes: PersonTypeResolver,
        complaintTypes: ComplaintTypeResolver,
        commissions: CommissionResolver,
        customers: CustomerResolver,
        person2Entity: Person2EntityResolver,
        events: EventResolver,
        eventTypes: EventTypeResolver,
        bookmarkByEvent: BookmarkResolver,
        users: UserResolver,
        comments: CommentResolver,
    };

    public Editing$ = new Subject<boolean>();
    public Person$: Observable<PersonEntity> = combineLatest([this.route.paramMap, this.store.select(getPersons)]).pipe(
        map(([paramMap, persons]) => {
            if (paramMap.has('personId')) {
                return persons.find((p) => p.Id === +paramMap.get('personId'));
            } else {
                let p = new PersonEntity();
                return p;
            }
        }),
        tap((person) => this.Editing$.next(person.Id === null)),
        tap(() => this.cdr.detectChanges()),
    );
    public FormDirty = false;
    public FormEmpty = false;
    public FormInvalid = false;
    private personToEntitys$ = combineLatest([this.Person$, this.store.select(getPerson2Entities)]).pipe(
        map(([person, p2e]) => p2e?.filter((p) => p.PersonId === person.Id)),
        share(),
    );
    public Commissions$ = combineLatest([this.store.select(getCommissions), this.personToEntitys$]).pipe(
        map(([commissions, p2e]) => p2e?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission).map((p) => commissions?.find((c) => c.Id === p.EntityId))),
        map((commissions) => commissions.filter((c) => c !== undefined)),
    );
    public Customers$ = combineLatest([this.store.select(getCustomers), this.personToEntitys$]).pipe(
        map(([customers, p2e]) => p2e?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Customer).map((p) => customers?.find((c) => c.Id === p.EntityId))),
        map((customers) => customers.filter((c) => c !== undefined)),
    );
    public CommissionMeta = CommissionMeta;
    public CustomerMeta = CustomerAdministrationMeta;
    public OpenMailDialog$: Subject<void> = new Subject<void>();
    @ViewChild('Form') protected Form: PersonDetailFormComponent;

    constructor(
        private appDialog: AppDialogService,
        private route: ActivatedRoute,
        private routingHistoryService: RoutingHistoryService,
        private store: Store<State>,
        private router: Router,
        private cdr: ChangeDetectorRef,
        public CNS: CommissionNameService,
        public CS: CustomerNameService,
        private dialog: MatDialog,
        protected ps: PermissionService,
        protected cls: CustomLabelService,
    ) {}
    private subscriptions: Subscription[] = [];
    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.OpenMailDialog$.pipe(
                withLatestFrom(this.Person$),
                tap(([, person]) => {
                    this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                        ...EmailEditorComponentDialogConfig,
                        data: {
                            TargetEmails: [person.Email, person.SecondMail].filter(v => !!v),
                        },
                    });
                }),
            ).subscribe(),
        );
    }

    Save() {
        this.Form.Save();
    }

    public CreateEvent() {
        this.Person$.pipe(take(1)).subscribe((p) => {
            this.dialog.open(NewOnSiteEventComponent, {
                ...NewOnSiteEventComponent.DefaultConfig,
                data: {
                    IsNotOrtstermin: true,
                    PersonId: p.Id,
                    Street: p.Street,
                    PostalCode: p.PostalCode,
                    City: p.City,
                    Country: p.Country,
                } as NewOnSiteEventComponentDialogData,
            });
        });
    }
    public Saved(p: PersonEntity) {
        this.router.navigate(['../', p.Id], { relativeTo: this.route });
    }
    public RouteToCommission(event) {
        this.router.navigate(['/', CommissionMeta.Path, AllCommissionMeta.Path, event.entry.Id]);
    }
    public RouteToCustomer(event) {
        this.router.navigate(['/', CustomerAdministrationMeta.Path, event.entry.Id]);
    }

    public DeletePerson(person: PersonEntity) {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `${person.DisplayName} wirklich löschen?`,
                styleDelete: true,
            })
            .pipe(take(1))
            .subscribe(([result]) => {
                if (result) {
                    this.store
                        .select(getPersons)
                        .pipe(
                            skip(1),
                            take(1),
                            tap(() => this.routingHistoryService.NavigateBackwards()),
                        )
                        .subscribe();
                    this.store.dispatch(PersonActionTypes.DeletePerson({ Payload: person.Id }));
                }
            });
    }

    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        return !this.FormDirty;
    }

    onAddCustomerClick() {
        firstValueFrom(this.Person$).then((person) => {
            const bothNames = person.Salutation === Salutation.life_companions && (person.SecondFirstname || person.SecondLastname)
            this.dialog.open<SelectCustomerPersonDialogComponent, SelectCustomerDialogComponentDialogData>(SelectCustomerPersonDialogComponent, {
                ...SelectCustomerPersonDialogComponent.DefaultConfig,
                data: {
                    preventNewPersonDialogWhileCreateCustomer: true,
                    personId: person.Id,
                    newCustomerDefaultValues: {
                        name: person.CompanyName || person.DisplayName || undefined,
                        firstName: bothNames ? [person.Firstname, person.Lastname].filter(v => !!v).join(' ') : person.Firstname,
                        lastName: bothNames ? [person.SecondFirstname, person.SecondLastname].filter(v => !!v).join(' ') : person.Lastname,
                        customer: {
                            Description: person.Description || undefined,
                        },
                        office: {
                            Email: person.Email || undefined,
                            PostalCode: person.PostalCode || undefined,
                            City: person.City || undefined,
                            MobileNumber: person.MobileNumber || undefined,
                            FaxNumber: person.FaxNumber || undefined,
                            PhoneNumber: person.PhoneNumber || undefined,
                            Street: person.Street || undefined,
                            Country: person.Country || undefined,
                        },
                    },
                },
            });
        });
    }
}
