import { AddUserRequest, EditProcessRequest, GetProcessesRequest, RemoveUserRequest, StartProcessesRequest } from '@dave/types/dist/proto/process/process';
import { createActionGroup, props } from '@ngrx/store';
import { ProcessEntity } from '../../entities/process.entity';
import { IdRequest } from "@dave/types/dist/proto/main";
import { UploadActionProps } from "../../guards/resolver.helper";

export const ProcessActions = createActionGroup({
    source: 'Process',
    events: {
        'Update All': props<{ Payload: ProcessEntity[]; updateLatestUpdatedAt?: boolean }>(),
        'Update Many': props<{ Payload: ProcessEntity[]; updateLatestUpdatedAt?: boolean }>(),
        'Update One': props<{ Payload: ProcessEntity }>(),
        'Remove One': props<{ Payload: number }>(),

        Load: props< UploadActionProps >(),
        Start: props<{ Payload: StartProcessesRequest }>(),
        Change: props<{ Payload: Omit<EditProcessRequest, 'DocumentIds'> }>(),

        'Delete': props<{ Payload: IdRequest }>(),

        'Add User': props<{ Payload: AddUserRequest }>(),
        'Remove User': props<{ Payload: RemoveUserRequest }>(),
    },
});
