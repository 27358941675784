import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { getFetched$ } from '../../dave-data-module/helper/helper';
import { State } from '../../dave-data-module/State';
import { getCommissionsActive } from '../../dave-data-module/State/selectors/commission.selector';
import { getMilestones, getMilestonesFetched } from '../../dave-data-module/State/selectors/milestone.selector';
import { getUsers } from '../../dave-data-module/State/selectors/users.selectors';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT } from '../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { getFilterSettings } from '../../helper/event.helper';
import { isNotNullOrUndefined } from '../../helper/helper';
import { CustomLabelService } from '../../services/custom-label.service';
import { FilterTypes } from '../../services/default-filter.service';

type taskFilters = FilterTypes.Priority | FilterTypes.EndDate | FilterTypes.CommissionId | FilterTypes.UserId | FilterTypes.AutorId;
type taskFiltersWithMilestone = taskFilters | FilterTypes.Milestones;

@Injectable({
    providedIn: 'root',
})
export class TaskFilterService {
    constructor(private store: Store<State>, private cls: CustomLabelService) {}
    filterSettings$: Observable<FilterOption<taskFilters>[]> = combineLatest([this.store.select(getUsers), this.store.select(getCommissionsActive), this.cls.getMultiple$('Commission')]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([users, commissions, commissionLabel]) =>
            getFilterSettings<taskFilters>(
                [FilterTypes.Priority, FilterTypes.EndDate, FilterTypes.CommissionId, FilterTypes.UserId, FilterTypes.AutorId],
                users.filter((u) => !u.Deleted),
                users,
                commissions,
                null,
                null,
                commissionLabel,
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    filterSettingsWithMilestones$ = (commissionId$: Observable<number | null>): Observable<FilterOption<taskFiltersWithMilestone | taskFilters>[]> => {
        return this.filterSettings$.pipe(
            switchMap((filterSettings) =>
                combineLatest([getFetched$(this.store, getMilestonesFetched, getMilestones), commissionId$.pipe(distinctUntilChanged())]).pipe(
                    map(([milestones, commissionId]) => {
                        if (!commissionId) {
                            return filterSettings;
                        } else {
                            const milestoneFilter = {
                                Name: FilterTypes.Milestones,
                                Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                                Label: 'Meilensteine',
                                // Icon: CommissionMeta.Icon,
                                Values: milestones
                                    .filter((m) => m.CommissionId === commissionId)
                                    .map((m) => ({
                                        label: m.Name,
                                        id: m.Id,
                                    })),
                            };
                            const commissionFilterIndex = filterSettings.findIndex((f) => f.Name === FilterTypes.CommissionId);
                            if (commissionFilterIndex > -1) {
                                return <FilterOption<taskFiltersWithMilestone>[]>[...filterSettings.slice(0, commissionFilterIndex + 1), milestoneFilter, ...filterSettings.slice(commissionFilterIndex + 1)];
                            }
                            return <FilterOption<taskFiltersWithMilestone>[]>[...filterSettings, milestoneFilter];
                        }
                    }),
                ),
            ),
            filter(isNotNullOrUndefined),
        );
    };
}
