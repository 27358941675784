import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, DEFAULT_CURRENCY_CODE, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MaterialAddRequest } from '@dave/types';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subject, Subscription, switchMap, tap } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, startWith, withLatestFrom } from 'rxjs/operators';
import { CustomFields, CustomFieldTypes, CustomFieldTypesDisplayValueMap, CustomFieldTypesNamesMap } from '../../../custom-form-fields/custom-form-fields.helper';
import { MaterialGroupEntity } from '../../../dave-data-module/entities/material-group.entity';
import { PublicFileTypeEnum } from '../../../dave-data-module/entities/public-file.entity';
import { PublicVersionEntity } from '../../../dave-data-module/entities/public-version.entity';
import { MaterialGroupResolver } from '../../../dave-data-module/guards/material-group.resolver';
import { PublicFileResolver } from '../../../dave-data-module/guards/public-file.resolver';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { MaterialGroupActionTypes } from '../../../dave-data-module/State/actions/material-group.actions';
import { MaterialActionTypes } from '../../../dave-data-module/State/actions/material.actions';
import { PublicFileActionTypes, PublicFileUploadParams } from '../../../dave-data-module/State/actions/public-file.actions';
import { getQuantityTypeDictionary, getQuantityTypes, getQuantityTypesFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getMaterialGroupById, getMaterialGroupDictionary, getMaterialGroups, getMaterialGroupsFetched } from '../../../dave-data-module/State/selectors/material-group.selectors';
import { getMaterialById, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { getPublicFilesActive, getPublicFilesFetched } from '../../../dave-data-module/State/selectors/public-file.selectors';
import { IDetailListTemplateData, IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { DetailListDialogReturn, DetailListTemplateDialogComponent, DetailListTemplateDialogData } from '../../../detail-list-template-dialog/components/detail-list-template-dialog.component';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { CommissionMeta, DMSPageMeta, MaterialPageMeta } from '../../../helper/page-metadata';
import { decimalPriceValidator } from '../../../helper/validation.helper';
import { MaterialDataService } from '../../../dave-data-module/services/material-data.service';

const materialGroupEntityToSelectSearchData = (g: MaterialGroupEntity) => g ? Object.assign(g.Clone(), { optionLabel: g.Name }) : null;

@Component({
    selector: 'app-material-main-data',
    templateUrl: './material-main-data.component.html',
    styleUrls: ['./material-main-data.component.scss'],
})
export class MaterialMainDataComponent implements OnDestroy, AfterViewInit {
    @Input() Editing = false;
    @Input() ShowMaterialButton = true;
    @Input() FolderId: number;
    @Output() EditingChange = new EventEmitter<boolean>();

    @Input() set MaterialId(v: number) {
        this.materialId$.next(v);
    }

    @ViewChild('mainProductGroup') mainProductGroup: TemplateRef<any>;
    @ViewChild('ProductGroup') ProductGroup: TemplateRef<any>;
    @ViewChild('SellPrice') SellPrice: TemplateRef<any>;
    private materialId$ = new BehaviorSubject<number | null>(null);
    public FileCache$: BehaviorSubject<Blob | null> = new BehaviorSubject<Blob | null>(null);
    public DMSMeta = DMSPageMeta;
    public CommissionMeta = CommissionMeta;
    public MaterialPageMeta = MaterialPageMeta;
    public Material$ = this.materialId$.pipe(
        switchMap((id) =>
            id
                ? this.store.select(getMaterialsFetched).pipe(
                      filter((v) => !!v),
                      switchMap(() => this.store.select(getMaterialById({ id }))),
                  )
                : of(null),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected materialDescription$ = this.Material$.pipe(
        switchMap(material => material ? this.materialDataService.getDescription$(material.Id).pipe(shareReplay({refCount: true, bufferSize: 1})) : of(null)),
    );
    public MaterialForm = new FormGroup({
        Name: new FormControl<string>(null, Validators.required),
        // Description: new FormControl<string>(null),
        ArticleNumber: new FormControl<string>(null),
        Kostenstelle: new FormControl<string>(null),
        Amount: new FormControl<number>(null),
        Cost: new FormControl<number>(null, [Validators.min(0), decimalPriceValidator()]),
        ListPrice: new FormControl<number>(null, [Validators.min(0), decimalPriceValidator()]),
        SellPrice: new FormControl<number>(null, [Validators.min(0), decimalPriceValidator()]),
        MainProductGroupId: new FormControl<MaterialGroupEntity & { optionLabel: string }>(null),
        ProductGroupId: new FormControl<MaterialGroupEntity & { optionLabel: string }>(null),
        QuantityType: new FormControl<{ Id: number }>(null),
        SellQuantityType: new FormControl<{ Id: number }>(null),
        InventoryNumber: new FormControl<string>(null),
        AutomaticInventoryNumber: new FormControl<boolean>(null),
        UseSurchargeRate: new FormControl<boolean>(null),
        StorageLocation: new FormControl<string>(null),
        GlobalTradeItemNr: new FormControl<string>(null),
    });
    public CustomFieldTypes = CustomFieldTypes;
    public CustomFields: Array<
        CustomFields & {
            formControl: FormControl<any>;
        }
    > = [];

    public CompareById = (a: SelectSearchOption<{ Id: number }>, b: SelectSearchOption<{ Id: number }>) => a?.Id === b?.Id;
    FileIsLoaded = false;
    Loading: boolean = false;
    public File$ = this.materialId$.pipe(
        switchMap((materialId) =>
            this.store.select(getPublicFilesFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getPublicFilesActive)),
                map((files) => files.find((f) => f.EntityId === materialId && f.Type === PublicFileTypeEnum.ResourceEntityProfileImage)?.LastVersion),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public ImageSource$ = combineLatest([this.File$, this.FileCache$, this.store.select(getToken)]).pipe(
        map(([publicFile, cachedFile, token]) => {
            if (publicFile?.MimeType.indexOf('image/') > -1) {
                return this.Api.GetUrl(publicFile.DownloadPath + '?token=' + token, 'file');
            } else if (cachedFile) {
                return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(cachedFile));
            }
        }),
    );
    protected materialGroupOptions$: Observable<Array<MaterialGroupEntity & { optionLabel: string }>> = this.store.select(getMaterialGroups).pipe(
        map((rt) => rt.map(materialGroupEntityToSelectSearchData)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected mainMaterialGroupOptions$: Observable<Array<MaterialGroupEntity & { optionLabel: string }>> = this.materialGroupOptions$.pipe(
        map((gt) => gt.filter((g) => !g.ParentId)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected subMaterialGroupOptions$: Observable<Array<MaterialGroupEntity & { optionLabel: string }>> = combineLatest([
        this.materialGroupOptions$.pipe(map((gt) => gt.filter((g) => !!g.ParentId))),
        this.MaterialForm.controls.MainProductGroupId.valueChanges.pipe(
            startWith(null),
            map(() => this.MaterialForm.controls.MainProductGroupId.value),
        ),
    ]).pipe(map(([matGroups, parentGroup]) => (parentGroup ? matGroups.filter((g) => g.ParentId === parentGroup.Id) : matGroups)));
    public DetailList$: Observable<IDetailListTemplateData>;
    private resetFormSubject$ = new Subject<void>();
    public CustomFieldsValid$$ = new BehaviorSubject<Observable<boolean>>(of(false));
    public FormsValid$ = combineLatest([this.CustomFieldsValid$$.pipe(switchMap((v) => v)), this.MaterialForm.statusChanges.pipe(startWith(this.MaterialForm.status))]).pipe(
        map(([customFieldsValid, matFormStatus]) => {
            return customFieldsValid && matFormStatus === 'VALID';
        }),
    );
    private subscriptions: Subscription[] = [
        this.MaterialForm.controls.UseSurchargeRate.valueChanges.pipe(
            startWith(null),
            map(() => this.MaterialForm.getRawValue().UseSurchargeRate)
        ).subscribe((value) => {
            this.updateSellPriceEnable()
        }),
        this.MaterialForm.controls.ProductGroupId.valueChanges
            .pipe(
                startWith(null),
                map(() => this.MaterialForm.controls.ProductGroupId.value),
            )
            .subscribe((v) => {
                if (v && v.ParentId !== this.MaterialForm.value.MainProductGroupId?.Id) {
                    firstValueFrom(this.store.select(getMaterialGroupById({ id: v.ParentId }))).then((g) => {
                        if (g) {
                            this.MaterialForm.controls.MainProductGroupId.setValue(materialGroupEntityToSelectSearchData(g));
                        } else {
                            this.MaterialForm.controls.MainProductGroupId.setValue(null);
                        }
                    });
                }
            }),
        this.MaterialForm.controls.MainProductGroupId.valueChanges
            .pipe(
                startWith(null),
                map(() => this.MaterialForm.controls.MainProductGroupId.value),
            )
            .subscribe((v) => {
                if (v && this.MaterialForm.value.ProductGroupId && v.Id !== this.MaterialForm.value.ProductGroupId.ParentId) {
                    this.MaterialForm.controls.ProductGroupId.setValue(null);
                }
            }),
        this.MaterialForm.controls.AutomaticInventoryNumber.valueChanges.pipe(withLatestFrom(this.Material$)).subscribe(([v, material]) => {
            if (v && this.MaterialForm.controls.InventoryNumber.enabled) {
                this.MaterialForm.controls.InventoryNumber.disable();
                this.MaterialForm.controls.InventoryNumber.setValue(null);
            } else if (!v && this.MaterialForm.controls.InventoryNumber.disabled) {
                this.MaterialForm.controls.InventoryNumber.enable();
                this.MaterialForm.controls.InventoryNumber.setValue(material?.InventoryNumber || '');
            }
        }),
        // merge(this.materialDescription$, this.resetFormSubject$).pipe(withLatestFrom(this.materialDescription$)).subscribe(([_, description]) => {
        //     this.MaterialForm.controls.Description.reset(description || null);
        // }),
        merge(this.Material$, this.resetFormSubject$, this.store.select(getMaterialGroupsFetched).pipe(filter(v => v), first()))
            .pipe(withLatestFrom(this.Material$, this.store.select(getMaterialGroupDictionary)))
            .subscribe(([, material, matGroupDictionary, ]) => {
                if (material) {
                    const { InventoryNumber, Name, ArticleNumber, Kostenstelle, Amount, ProductGroupId, Cost, ListPrice,  SellQuantityTypeId, CustomFields, QuantityTypeId, StorageLocation, GlobalTradeItemNr, SellPrice } =
                        material;

                    this.setCustomFields(CustomFields || []);
                    const { mainGroup, group } = getGroups(ProductGroupId, matGroupDictionary);

                    this.MaterialForm.reset(
                        {
                            Name,
                            ArticleNumber,
                            // Description: this.MaterialForm.value.Description,
                            Kostenstelle,
                            Amount,
                            Cost: isNotNullOrUndefined(Cost) ? Cost / 100 : null,
                            ListPrice: isNotNullOrUndefined(ListPrice) ? ListPrice / 100 : null,
                            SellPrice: isNotNullOrUndefined(SellPrice) ? SellPrice / 100 : null,
                            InventoryNumber,
                            MainProductGroupId: materialGroupEntityToSelectSearchData(mainGroup),
                            ProductGroupId: materialGroupEntityToSelectSearchData(group),
                            AutomaticInventoryNumber: false,
                            QuantityType: QuantityTypeId ? { Id: QuantityTypeId } : null,
                            SellQuantityType: SellQuantityTypeId ? { Id: SellQuantityTypeId } : null,
                            StorageLocation,
                            GlobalTradeItemNr,
                        },
                        { emitEvent: true },
                    );
                } else {
                    this.setCustomFields([]);
                    this.MaterialForm.reset();
                }
                this.updateSellPriceEnable();
            }),
    ];

    constructor(
        private actions$: Actions<DaveActions>,
        private dialog: MatDialog,
        private store: Store<State>,
        public Api: HttpService,
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        publicFileResolver: PublicFileResolver,
        quantityTypeResolver: QuantityTypeResolver,
        private materialGroupResolver: MaterialGroupResolver,
        @Inject(LOCALE_ID) private local: string,
        @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
        private materialDataService: MaterialDataService,
    ) {

        firstValueFrom(this.store.select(getMaterialGroupsFetched)).then((fetched) => {
            if (!fetched) {
                materialGroupResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then((fetched) => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        });

        firstValueFrom(this.store.select(getPublicFilesFetched)).then((fetched) => {
            if (!fetched) {
                publicFileResolver.resolve();
            }
        });
    }

    ngAfterViewInit(): void {
        this.DetailList$ = this.Material$.pipe(
            switchMap((material) =>
                this.store.select(getMaterialGroupsFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.Loading = true;
                            this.materialGroupResolver.resolve();
                        }
                    }),
                    filter((v) => !!v),
                    switchMap(() =>
                        combineLatest([
                            this.store.select(getMaterialGroupDictionary),
                            getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeDictionary),
                            this.MaterialForm.controls.ProductGroupId.valueChanges.pipe(startWith(this.MaterialForm.value.ProductGroupId)),
                            // this.ResourceForm.controls.QuantityType.valueChanges.pipe(startWith(this.ResourceForm.value.QuantityType)),
                            // this.sellPriceFromEntity$,
                        ]),
                    ),
                    map(([groupTypes, quantityTypes, materialGroup, /*sellPrice*/]) => {
                        const { mainGroup, group } = getGroups(material?.ProductGroupId, groupTypes);

                        const Properties: IDetailListTemplateDataProperty[] = [
                            {
                                key: 'Hauptwarengruppe',
                                value: mainGroup?.Name,
                                formControl: this.MaterialForm.controls.MainProductGroupId,
                                options: {
                                    specialInput: {
                                        customTemplate: this.mainProductGroup,
                                    },
                                },
                            },
                            {
                                key: 'Warengruppe',
                                value: group?.Name,
                                formControl: this.MaterialForm.controls.ProductGroupId,
                                options: {
                                    specialInput: {
                                        customTemplate: this.ProductGroup,
                                    },
                                },
                            },
                            {
                                key: 'Name',
                                value: material?.Name || null,
                                formControl: this.MaterialForm.controls.Name,
                            },
                            {
                                key: 'Artikelnummer',
                                value: material?.ArticleNumber || null,
                                formControl: this.MaterialForm.controls.ArticleNumber,
                            },
                            {
                                key: 'Inventarnummer',
                                value: material?.InventoryNumber || null,
                                formControl: this.MaterialForm.controls.InventoryNumber,
                            },

                            {
                                key: 'Inventarnummer automatisch vergeben',
                                formControl: this.MaterialForm.controls.AutomaticInventoryNumber,
                                options: {
                                    type: CustomPropertyType.Boolean,
                                    specialInput: { boolean: true },
                                    showHint: 'Erzeugt eine fortlaufende Inventarnummer.',
                                },
                            },
                            {
                                key: 'Kostenstelle',
                                value: material?.Kostenstelle || null,
                                formControl: this.MaterialForm.controls.Kostenstelle,
                            },
                            {
                                key: 'Menge',
                                value: material?.Amount != null ? material.Amount : null,
                                formControl: this.MaterialForm.controls.Amount,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                key: 'Verkaufseinheit',
                                value: material?.QuantityTypeId ? quantityTypes[material.QuantityTypeId]?.Name : null,
                                formControl: this.MaterialForm.controls.QuantityType,
                                options: {
                                    specialInput: {
                                        singleSelectSearch: {
                                            options$: getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes),
                                            compareOptions: (a, b) => a?.Id === b?.Id,
                                        },
                                    },
                                },
                            },
                            {
                                key: 'Einkaufspreis',
                                value: isNotNullOrUndefined(material?.Cost) && formatCurrency(material.Cost / 100, 'de-DE', '€'),
                                formControl: this.MaterialForm.controls.Cost,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                    suffix: '€',
                                },
                            },
                            {
                                key: 'Listenpreis',
                                value: isNotNullOrUndefined(material?.ListPrice) && formatCurrency(material.ListPrice / 100, this.local, getCurrencySymbol(this.defaultCurrencyCode, 'narrow', this.local), this.defaultCurrencyCode),
                                formControl: this.MaterialForm.controls.ListPrice,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                    suffix: '€',
                                },
                            },
                            {
                                key: 'Verkaufspreis',
                                value: isNotNullOrUndefined(material?.SellPrice) && formatCurrency(material.SellPrice / 100, this.local, getCurrencySymbol(this.defaultCurrencyCode, 'narrow', this.local), this.defaultCurrencyCode),
                                formControl: this.MaterialForm.controls.SellPrice,
                                options: {
                                    specialInput: {
                                        customTemplate: this.SellPrice,
                                    },
                                    suffix: '€',
                                },
                            },
                            {
                                key: 'Verkaufspreis automatisch berechnen',
                                formControl: this.MaterialForm.controls.UseSurchargeRate,
                                options: {
                                    type: CustomPropertyType.Boolean,
                                    specialInput: { boolean: true },
                                    // showHint: 'Erzeugt eine fortlaufende Inventarnummer.',
                                },
                            },
                            {
                                key: 'Lagerplatz',
                                value: material?.StorageLocation || null,
                                formControl: this.MaterialForm.controls.StorageLocation,
                            },
                            {
                                key: 'EAN',
                                value: material?.GlobalTradeItemNr || null,
                                formControl: this.MaterialForm.controls.GlobalTradeItemNr,
                            },
                            ...(material?.CustomFields || []).map((field) => ({
                                key: field.name,
                                value: CustomFieldTypesDisplayValueMap.get(field.type)(field.value),
                            })),
                        ];
                        return {
                            Properties,
                        };
                    }),
                    tap(() => (this.Loading = false)),
                ),
            ),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    private updateSellPriceEnable() {
        if (!this.MaterialForm.controls.UseSurchargeRate.value && this.MaterialForm.controls.SellPrice.disabled) {
            this.MaterialForm.controls.SellPrice.enable();
        } else if (this.MaterialForm.controls.UseSurchargeRate.value && this.MaterialForm.controls.SellPrice.enabled) {
            this.MaterialForm.controls.SellPrice.disable();
        }
    }
    private setCustomFields(fields: CustomFields[]) {
        this.CustomFields = fields.map((f) => {
            switch (f.type) {
                case CustomFieldTypes.text:
                    return {
                        ...f,
                        formControl: new FormControl<string>(f.value),
                    };
                case CustomFieldTypes.number:
                    return { ...f, formControl: new FormControl<number>(f.value) };
                case CustomFieldTypes.date:
                    return {
                        ...f,
                        formControl: new FormControl<Moment>(f.value ? moment(f.value) : null),
                    };
            }
        });
        this.CustomFieldsValid$$.next(this.CustomFields.length ? combineLatest(this.CustomFields.map((c) => c.formControl.statusChanges.pipe(startWith(c.formControl.status)))).pipe(map((v) => v.every((s) => s === 'VALID'))) : of(true));
    }

    private uploadImage(file: Blob, resourceId: number, oldFile?: PublicVersionEntity) {
        const params: PublicFileUploadParams = new Map([
            ['entity_id', resourceId + ''],
            ['type', PublicFileTypeEnum.ResourceEntityProfileImage],
        ]);
        if (oldFile) {
            params.set('file_id', oldFile.FileId + '');
        }
        this.store.dispatch(
            PublicFileActionTypes.Upload({
                Payload: {
                    file,
                    params,
                },
            }),
        );
    }

    public OnFileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                fetch(reader.result as string)
                    .then((res) => res.blob())
                    .then((file) => {
                        firstValueFrom(combineLatest([this.Material$, this.File$])).then(([material, oldFile]) => {
                            if (material) {
                                this.uploadImage(file, material.Id, oldFile);
                            } else {
                                this.FileCache$.next(file);
                            }
                        });
                    });
            };
        }
    }

    public ResetForm() {
        this.resetFormSubject$.next();
    }

    public SaveForm() {
        if (this.MaterialForm.invalid) {
            return;
        }
        const {
            Name,
            // Description,
            ArticleNumber,
            Kostenstelle,
            Amount,
            ProductGroupId,
            Cost,
            ListPrice,
            SellPrice,
            QuantityType,
            SellQuantityType,
            InventoryNumber,
            StorageLocation,
            GlobalTradeItemNr,
            AutomaticInventoryNumber,
            UseSurchargeRate,
            MainProductGroupId,
        } = this.MaterialForm.value;
        const payload: MaterialAddRequest = {
            ProductGroupId: ProductGroupId?.Id ? String(ProductGroupId.Id) : MainProductGroupId?.Id ? String(MainProductGroupId.Id) : null,
            Amount: Amount ? String(Amount) : null,
            Cost: isNotNullOrUndefined(Cost) ? String(Math.round(Cost * 100)) : null,
            ListPrice: isNotNullOrUndefined(ListPrice) ? String(Math.round(ListPrice * 100)) : null,
            SellPrice: isNotNullOrUndefined(SellPrice) ? String(Math.round(SellPrice * 100)) : null,
            Name: Name,
            Kostenstelle: Kostenstelle,
            ArticleNumber: ArticleNumber,
            InventoryNumber: InventoryNumber,
            CustomFields: JSON.stringify(this.CustomFields.map(({ name, type, formControl }) => ({ name, type, value: formControl.value }))),
            AutomaticInventoryNumber: AutomaticInventoryNumber,
            QuantityTypeId: QuantityType ? String(QuantityType.Id) : null,
            SellQuantityTypeId: SellQuantityType ? String(SellQuantityType.Id) : null,
            StorageLocation: StorageLocation,
            GlobalTradeItemNr: GlobalTradeItemNr,
            UseSurchargeRate,
        };
        firstValueFrom(this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, MaterialActionTypes.UpdateOne))).then(action => {
            if (action.type === MaterialActionTypes.UpdateOne.type) {
                this.EditingChange.emit(false);
            }
        })

        if (this.materialId$.value) {
            this.store.dispatch(
                MaterialActionTypes.Change({
                    Payload: {
                        ...payload,
                        Id: String(this.materialId$.value),
                    },
                }),
            );
        } else {
            if (this.FileCache$.value) {
                this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, MaterialActionTypes.UpdateOne), first(), ofType(MaterialActionTypes.UpdateOne)).subscribe((material) => {
                    this.uploadImage(this.FileCache$.value, material.Payload.Id);
                });
            }

            this.store.dispatch(
                MaterialActionTypes.Create({
                    Payload: {
                        ...payload,
                    },
                }),
            );
        }
    }

    public OpenCustomFieldPopup(index?: number) {
        const form = new FormGroup({
            name: new FormControl<string>(isNotNullOrUndefined(index) ? this.CustomFields[index].name : '', Validators.required),
            type: new FormControl<CustomFieldTypes>(isNotNullOrUndefined(index) ? this.CustomFields[index].type : CustomFieldTypes.text, Validators.required),
        });
        this.dialog
            .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                ...DetailListTemplateDialogComponent.DefaultConfig,
                data: {
                    Editing: true,
                    DeleteButton: index != null,
                    DisableSaveButton$: form.statusChanges.pipe(
                        startWith(form.status),
                        map((state) => state !== 'VALID'),
                    ),
                    Data: {
                        Properties: [
                            {
                                key: 'Name',
                                formControl: form.controls.name,
                            },
                            {
                                key: 'Art',
                                formControl: form.controls.type,
                                options: {
                                    specialInput: {
                                        select: Object.values(CustomFieldTypes).map((type) => ({
                                            optionValue: type,
                                            optionLabel: CustomFieldTypesNamesMap.get(type),
                                        })),
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                if (ret?.Action === 'save') {
                    if (index != null) {
                        if (this.CustomFields[index].type !== form.value.type) {
                            this.CustomFields[index].value = null;
                        }
                        this.CustomFields[index].type = form.value.type;
                        this.CustomFields[index].name = form.value.name;
                    } else {
                        this.CustomFields.push({
                            type: form.value.type,
                            name: form.value.name,
                            value: null,
                            formControl: new FormControl<any>(null),
                            options: [],
                        });
                    }
                    this.cdr.markForCheck();
                } else if (ret?.Action === 'delete') {
                    this.CustomFields.splice(index, 1);
                    this.cdr.markForCheck();
                }
            });
    }

    NewMaterialGroupPopUp(isMainProductGroup: boolean) {
        const forms = new FormGroup({
            cName: new FormControl<string>('', Validators.required),
            cMainGroups: new FormControl<MaterialGroupEntity & { optionLabel: string }>(this.MaterialForm.value.MainProductGroupId),
        });
        if (!isMainProductGroup) {
            forms.controls.cMainGroups.addValidators(Validators.required);
        }
        firstValueFrom(this.mainMaterialGroupOptions$).then((mainGroups) => {
            firstValueFrom(
                this.dialog
                    .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                        ...DetailListTemplateDialogComponent.DefaultConfig,
                        data: {
                            DisableSaveButton$: forms.statusChanges.pipe(
                                startWith(forms.status),
                                map((state) => state !== 'VALID'),
                            ),
                            DeleteButton: true,
                            Editing: true,
                            Data: {
                                Headline: isMainProductGroup ? 'Hauptwarengruppe anlegen' : 'Warengruppe anlegen',
                                Properties: [
                                    {
                                        key: 'Bezeichnung',
                                        formControl: forms.controls.cName,
                                    },
                                    isMainProductGroup
                                        ? null
                                        : {
                                              key: 'Hauptwarengruppe',
                                              formControl: forms.controls.cMainGroups,
                                              options: {
                                                  specialInput: {
                                                      singleSelectSearch: {
                                                          options: mainGroups,
                                                          compareOptions: this.CompareById,
                                                      },
                                                  },
                                              },
                                          },
                                ].filter(isNotNullOrUndefined),
                            },
                        },
                    })
                    .afterClosed(),
            ).then((ret) => {
                if (ret.Action === 'save' && forms.valid) {
                    this.store.dispatch(
                        MaterialGroupActionTypes.CreateRequest({
                            Payload: {
                                Name: forms.value.cName,
                                ParentId: !isMainProductGroup ? String(forms.value.cMainGroups.Id) : undefined
                            },
                        }),
                    );
                    firstValueFrom(this.actions$.pipe(ofType(MaterialGroupActionTypes.CreateFailure, MaterialGroupActionTypes.CreateSuccess))).then((action) => {
                        if (action.type === MaterialGroupActionTypes.CreateSuccess.type) {
                            const payload: MaterialGroupEntity = action.Payload as MaterialGroupEntity;
                            if (payload.ParentId) {
                                this.MaterialForm.patchValue({
                                    // MainProductGroupId: payload.ParentId,
                                    ProductGroupId: materialGroupEntityToSelectSearchData(payload),
                                });
                            } else {
                                this.MaterialForm.patchValue({
                                    MainProductGroupId: materialGroupEntityToSelectSearchData(payload),
                                    ProductGroupId: null,
                                });
                            }
                        }
                    });
                }
            });
        });
    }

    protected readonly FormGroup = FormGroup;
}
function getGroups(groupId: number, materialGroups: Dictionary<MaterialGroupEntity>) {
    let productGroupFromEntity = groupId && materialGroups[groupId];
    let productGroupParentFromEntity = productGroupFromEntity && productGroupFromEntity.ParentId && materialGroups[productGroupFromEntity.ParentId];

    if (productGroupFromEntity && !productGroupParentFromEntity && !productGroupFromEntity.ParentId) {
        productGroupParentFromEntity = productGroupFromEntity;
        productGroupFromEntity = null;
    }
    return {
        mainGroup: productGroupParentFromEntity,
        group: productGroupFromEntity,
    };
}
