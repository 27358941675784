import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, tap } from 'rxjs/operators';
import { EmployeeEntity, EmployeeStatusEnum } from "../../../../dave-data-module/entities/employee.entity";
import { UserToEventEntity } from '../../../../dave-data-module/entities/user-to-event.entity';
import { EmployeeResolver } from '../../../../dave-data-module/guards/employee.resolver';
import { JobSpecificationResolver } from '../../../../dave-data-module/guards/job-specification.resolver';
import { UserToEventResolver } from '../../../../dave-data-module/guards/user-to-event.resolver';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { EventsActionTypes } from '../../../../dave-data-module/State/actions/events.actions';
import { UserToEventActionTypes } from '../../../../dave-data-module/State/actions/user-to-event.action';
import { getEmployees, getEmployeesFetched } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getJobSpecificationDictionary, getJobSpecificationFetched } from '../../../../dave-data-module/State/selectors/job-specification.selector';
import { getUserToEventFetched, getUserToEvents } from '../../../../dave-data-module/State/selectors/user-to-event.selector';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, stringSearch, uniqArray } from '../../../../helper/helper';
import { LoadingService } from '../../../../services/loading.service';
import {
    ISelectedElement,
    ITableConfig,
    TableContentType,
} from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../../services/default-filter.service';
import { FilterOption } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';

export interface SelectUserEventDialogComponentDialogData {
    eventId: number;
}
interface ITableData {
    userId: number;
    selectedForm: FormControl<boolean>;
    name: TableContentType<any> & { __typename: 'string' };
    jobSpecification: TableContentType<any> & { __typename: 'string' };
    jobSpecificationId: number | null;
    employeeStaus: EmployeeStatusEnum | null;
}
interface SelectedUserType extends ISelectedElement {
    userToEvent: UserToEventEntity;
}
@Component({
    selector: 'app-select-user-event-dialog',
    templateUrl: './select-user-event-dialog.component.html',
    styleUrls: ['./select-user-event-dialog.component.scss'],
})
export class SelectUserEventDialogComponent extends SelectUserDialogBase implements OnInit, OnDestroy {
    public _FilterSettings$: Observable<FilterOption[]> = this.FilterSettings$.pipe(map(filters => filters.filter(f => f.Name !== FilterTypes.HideKollision)));
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedUsers$: Observable<SelectedUserType[]>;

    TableDataConfig: ITableConfig[] = [
        {
            header: 'Name',
            id: 'name',
            sortAccessor: (cell: TableContentType<any>&{__typename: "string"}) => cell.content || '',
        },
        {
            header: 'Jobbezeichnung',
            id: 'jobSpecification',
            sortAccessor: (cell: TableContentType<any>&{__typename: "string"}) => cell.content || '',
        },
    ];
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectUserEventDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        defaultFilter: DefaultFilterService,
        employeeRes: EmployeeResolver,
        jobRes: JobSpecificationResolver,
        userToEventRes: UserToEventResolver,
    ) {
        super(store, defaultFilter, FilterApps.SelectUserForEventPopup);
        this.ContentResolved$ = combineLatest([
            this.store.select(getEmployeesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        employeeRes.resolve();
                    }
                }),
            ),
            this.store.select(getJobSpecificationFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        jobRes.resolve();
                    }
                }),
            ),
            this.store.select(getUserToEventFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        userToEventRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );

        const employeesWithUserId$ = this.store.select(getEmployeesFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getEmployees)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        const activeEmployeesWithUserId$ = employeesWithUserId$.pipe(map(employees => employees.filter(e => e.EmployeeStatus === EmployeeStatusEnum.Aktiv && !e.Deleted)))
        this.SelectedUsers$ = combineLatest([
            this.store.select(getUserToEventFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getUserToEvents)),
                map((u2e) => u2e.filter((u) => u.EventId === this.Dialogdata.eventId)),
            ),
            employeesWithUserId$.pipe(map((employees) => new Map<number, EmployeeEntity>(employees.map((e) => [e.UserId, e])))),
        ]).pipe(
            map(([u2e, employeeMap]) => [
                ...u2e
                    .filter((u) => u.EventId === this.Dialogdata.eventId)
                    .map((u) => ({
                        userToEvent: u,
                        label: employeeMap.get(u.UserId)?.DisplayName || '',
                    })),
            ]),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.TableData$ = combineLatest([
            activeEmployeesWithUserId$,
            this.store.select(getJobSpecificationFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getJobSpecificationDictionary)),
            ),
        ]).pipe(
            map(([employees, jobs]) => {
                return employees
                    .filter((e) => e.UserId)
                    .map<ITableData>((e) => {
                        const job = e.JobSpecificationId && jobs[e.JobSpecificationId];
                        return {
                            userId: e.UserId,
                            selectedForm: new FormControl<boolean>(false),
                            name: {
                                __typename: 'string',
                                content: e.DisplayName,
                            },
                            jobSpecification: {
                                __typename: 'string',
                                content: job?.Name || '',
                            },
                            jobSpecificationId: e.JobSpecificationId,
                            employeeStaus: e.EmployeeStatus,
                        };
                    });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(
                    map((filter) => {
                        return rows.filter(r => (!filter[FilterTypes.JobSpecificationIds]?.length || r.jobSpecificationId && filter[FilterTypes.JobSpecificationIds].includes(r.jobSpecificationId + '')) && !filter[FilterTypes.Status]?.length || filter[FilterTypes.Status].some(f => f.id === r.employeeStaus))
                        },
                    ),
                ),
            ),
            switchMap((rows) =>
                this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.name.content, r.jobSpecification.content].filter(isNotNullOrUndefined).some(s => stringSearch(s, searchString)))),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
    ngOnInit(): void {
        this.subscriptions.push(
            this.TableData$.pipe(
                switchMap((data) =>
                    this.SelectedUsers$.pipe(
                        tap((u2es) =>
                            data.forEach((d) => {
                                const u2e = u2es.find((u) => d.userId === u.userToEvent.UserId);

                                if (u2e && (d.selectedForm.enabled || !d.selectedForm.value)) {
                                    d.selectedForm.setValue(true);
                                    d.selectedForm.disable();
                                } else if (!u2e && (d.selectedForm.disabled || d.selectedForm.value)) {
                                    d.selectedForm.setValue(false);
                                    d.selectedForm.enable();
                                }
                            }),
                        ),
                    ),
                ),
            ).subscribe(),
        );
    }
    RemoveSelected(entity: SelectedUserType) {
        this.LS.startLoading('delete-user-to-event');
        firstValueFrom(this.actions$.pipe(ofType(UserToEventActionTypes.RemoveOne, BaseActionTypes.ErrorAction))).then(() => {
            this.LS.endLoading('delete-user-to-event');
        });
        this.store.dispatch(
            UserToEventActionTypes.Delete({
                Payload: {
                    id: entity.userToEvent.Id,
                },
            }),
        );
    }
    Submit() {
        this.LS.startLoading('set-user-to-event');
        firstValueFrom(combineLatest([this.TableData$, this.SelectedUsers$])).then(([data, selected]) => {
            firstValueFrom(this.actions$.pipe(ofType(UserToEventActionTypes.UpdateMany, BaseActionTypes.ErrorAction))).then(() => {
                this.LS.endLoading('set-user-to-event');
            });
            this.store.dispatch(
                EventsActionTypes.SetUserToEvent({
                    Payload: {
                        userIds: uniqArray([...selected.map((s) => s.userToEvent.UserId), ...data.filter((d) => d.selectedForm.value).map((d) => d.userId)]),
                        eventId: this.Dialogdata.eventId,
                    },
                }),
            );
            this.LS.endLoading('set-user-to-event');
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
}
