<div class="mat-elevation-z8">
    <table mat-table class="full-width-table" matSort aria-label="Elements">
        <ng-container [matColumnDef]="colName" *ngFor="let colName of autoDisplayedColumns">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ columnHeaders[colName] }}</th>
            <td mat-cell *matCellDef="let row">{{ row[colName] }}</td>
        </ng-container>
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox *ngIf="multiple$ | async" [checked]="dataSource.allSelected$ | async" [indeterminate]="dataSource.someSelected$ | async" (change)="selectAll($event.checked)"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox [disabled]="(disableUncheckedCheckboxes$ | async) && !(row.isSelected$ | async)" (click)="$event.stopPropagation()" [checked]="row.isSelected$ | async" (change)="toggleSelection(row.Id)"></mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="toggleSelection(row.Id)"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell empty-table-text" [attr.colspan]="displayedColumns.length">
                {{ emptyText }}
            </td>
        </tr>
    </table>

    <mat-paginator #paginator [length]="dataSource?.data$ | async | length" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" aria-label="Select page"> </mat-paginator>
</div>
