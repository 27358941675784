import { formatDate } from '@angular/common';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, shareReplay, skip, startWith, tap } from 'rxjs/operators';
import { SelectUserCommissionLegacyComponent } from '../../../../dave-commission-module/components/detail-commission/select-user/select-user-commission-legacy.component';
import { EmployeeToVacationEntity, VacationTypeEnum } from '../../../../dave-data-module/entities/employee-to-vacation.entity';
import { EmployeeEntity, EmployeeStatusEnum } from "../../../../dave-data-module/entities/employee.entity";
import { EntityRoleEntity, EntityTypesEnum } from '../../../../dave-data-module/entities/entity-role.entity';
import { UserToCommissionEntity } from '../../../../dave-data-module/entities/user-to-commission.entity';
import { User2CommissionShiftEntity } from '../../../../dave-data-module/entities/user2CommissionShift.entity';
import { CommissionResolver } from '../../../../dave-data-module/guards/commission.resolver';
import { EmployeeResolver } from '../../../../dave-data-module/guards/employee.resolver';
import { EntityRoleResolver } from '../../../../dave-data-module/guards/entity-role.resolver';
import { JobSpecificationResolver } from '../../../../dave-data-module/guards/job-specification.resolver';
import { User2CommissionShiftResolver } from '../../../../dave-data-module/guards/user-to-commission-shift.resolver';
import { UserToCommissionResolver } from '../../../../dave-data-module/guards/user-to-commission.resolver';
import { FrontendDate } from '../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { UserToCommissionActionTypes } from '../../../../dave-data-module/State/actions/user-to-commission.action';
import { UserToCommissionShiftActionTypes } from '../../../../dave-data-module/State/actions/user2CommissionShift.action';
import { getCommissionById, getCommissionDictionary, getCommissionsFetched } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getEmployeeToVacations } from '../../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployees, getEmployeesFetched } from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole, getEntityRoleDictionary, getEntityRoleFetched } from '../../../../dave-data-module/State/selectors/entity-role.selector';
import { getJobSpecificationDictionary, getJobSpecificationFetched } from '../../../../dave-data-module/State/selectors/job-specification.selector';
import { getUserToCommissionFetched, getUserToCommissions } from '../../../../dave-data-module/State/selectors/user-to-commission.selector';
import { getUserToCommissionShiftFetched, getUserToCommissionShifts } from '../../../../dave-data-module/State/selectors/users-to-commission-shift.selectors';
import { BreakpointObserverService } from '../../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { isNotNullOrUndefined, SearchQueriesDebounceTime, stringSearch } from '../../../../helper/helper';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import { ISelectedElement, ITableConfig, TableContentType } from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';
import { CustomLabelService } from "../../../../services/custom-label.service";
export interface ComponentData {
    commissionId: number;
    preFilledStartDate?: Date;
    preFilledEndDate?: Date;
    headlineSuffix?: string;
    defaultFilter?: {
        jobSpecificationIds?: number[];
    }
    hideStartDate?: boolean;
}
export interface SelectUserCommissionDialogComponentDialogData extends ComponentData {}
interface ITableData {
    userId: number;
    jobSpecificationId?: number;
    employeeStaus: EmployeeStatusEnum | null;
    selectedForm: FormControl<boolean>;
    name: TableContentType<any> & { __typename: 'string' };
    jobSpecification: TableContentType<any> & { __typename: 'string' };
    conflicts: TableContentType<any> & { __typename: 'custom'; content$: Observable<null | { label: string; tooltip: string }> };
    timespan: TableContentType<any> & { __typename: 'timespan' };
    role: TableContentType<{ id: number; label: string }> & { __typename: 'select' };
    editor: TableContentType<any> & { __typename: 'boolean' };
}
interface SelectedUserType extends ISelectedElement {
    u2cIds: number[];
    u2csIds: number[];
    userId: number;
    timeSpans: string[];
}

function calculateCollisions(
    startDate: Date,
    endDate: Date,
    u2csByUser: User2CommissionShiftEntity[],
    e2vByEmployee: EmployeeToVacationEntity[],
    commissionId: number,
): {
    commissions: { commissionId: number; user2commissionShifts: User2CommissionShiftEntity[] }[];
    vacation: EmployeeToVacationEntity[];
    absent: EmployeeToVacationEntity[];
    illness: EmployeeToVacationEntity[];
    illnesswithoutcontinuedpay: EmployeeToVacationEntity[];
} {
    const user2CommissionShifts = u2csByUser?.filter((u2c) => {
        return (
            u2c.CommissionId !== commissionId &&
            ((u2c.StartDate <= endDate && u2c.StartDate >= startDate) ||
                (u2c.EndDate <= endDate && u2c.EndDate >= startDate) ||
                (u2c.StartDate <= endDate && u2c.EndDate >= endDate) ||
                (u2c.StartDate <= startDate && u2c.EndDate >= startDate))
        );
    });

    const commissions: { commissionId: number; user2commissionShifts: User2CommissionShiftEntity[] }[] = [];
    user2CommissionShifts.forEach((u2c) => {
        const i = commissions.findIndex((c) => c.commissionId === u2c.CommissionId);
        if (i >= 0) {
            commissions[i].user2commissionShifts.push(u2c);
        } else {
            commissions.push({ commissionId: u2c.CommissionId, user2commissionShifts: [u2c] });
        }
    });
    return {
        vacation: e2vByEmployee?.filter((e) => {
            if (e.Type === VacationTypeEnum.Vacation) {
                return (e.StartDate <= endDate && e.StartDate >= startDate) || (e.EndDate <= endDate && e.EndDate >= startDate) || (e.StartDate <= endDate && e.EndDate >= endDate) || (e.StartDate <= startDate && e.EndDate >= startDate);
            }
        }),
        absent: e2vByEmployee?.filter((e) => {
            if (e.Type === VacationTypeEnum.Absent) {
                return (e.StartDate <= endDate && e.StartDate >= startDate) || (e.EndDate <= endDate && e.EndDate >= startDate) || (e.StartDate <= endDate && e.EndDate >= endDate) || (e.StartDate <= startDate && e.EndDate >= startDate);
            }
        }),
        illness: e2vByEmployee?.filter((e) => {
            if (e.Type === VacationTypeEnum.Illness) {
                return (e.StartDate <= endDate && e.StartDate >= startDate) || (e.EndDate <= endDate && e.EndDate >= startDate) || (e.StartDate <= endDate && e.EndDate >= endDate) || (e.StartDate <= startDate && e.EndDate >= startDate);
            }
        }),
        illnesswithoutcontinuedpay: e2vByEmployee?.filter((e) => {
           if (e.Type === VacationTypeEnum.Illnesswithoutcontinuedpay) {
               return (e.StartDate <= endDate && e.StartDate >= startDate) || (e.EndDate <= endDate && e.EndDate >= startDate) || (e.StartDate <= endDate && e.EndDate >= endDate) || (e.StartDate <= startDate && e.EndDate >= startDate);
           }
        }),
        commissions,
    };
}
@Component({
    selector: 'app-select-user-commission-dialog',
    templateUrl: './select-user-commission-dialog.component.html',
    styleUrls: ['./select-user-commission-dialog.component.scss'],
})
export class SelectUserCommissionDialogComponent extends SelectUserDialogBase implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('collisionCellTemplate') collisionCellTemplate: TemplateRef<any>;
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public TimespanForm = new FormGroup({
        from: new FormControl<Moment>(moment()),
        to: new FormControl<Moment>(moment()),
    });
    public EntityRoleForm = new FormControl<EntityRoleEntity | null>(null);
    private subscriptions: Subscription[] = [];
    SelectedUsers$: Observable<SelectedUserType[]>;

    public TableDataConfig$: Observable<ITableConfig[]>;
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;
    EntityRolesForCommissions$ = this.store.select(getEntityRoleFetched).pipe(
        filter((v) => !!v),
        switchMap(() => this.store.select(getEntityRole)),
        map((roles) => roles.filter((role) => role.Entity === EntityTypesEnum.Commission)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public ContentResolved$: Observable<boolean>;
    @Input() ComponentData: ComponentData = null;
    @Input() PreventDialog = false;

    constructor(
        private store: Store<State>,
        @Optional() @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectUserCommissionDialogComponentDialogData,
        private dialog: MatDialog,
        private actions$: Actions,
        public LS: LoadingService,
        public BS: BreakpointObserverService,
        defaultFilterService: DefaultFilterService,
        employeeRes: EmployeeResolver,
        jobRes: JobSpecificationResolver,
        commissionRes: CommissionResolver,
        userToCommissionRes: UserToCommissionResolver,
        userToCommissionShiftRes: User2CommissionShiftResolver,
        entityRoleRes: EntityRoleResolver,
        protected cls: CustomLabelService,
    ) {
        super(store, defaultFilterService, FilterApps.SelectUserForCommissionPopup);
        this.ContentResolved$ = combineLatest([
            this.store.select(getEmployeesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        employeeRes.resolve();
                    }
                }),
            ),
            this.store.select(getJobSpecificationFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        jobRes.resolve();
                    }
                }),
            ),
            this.store.select(getCommissionsFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        commissionRes.resolve();
                    }
                }),
            ),
            this.store.select(getUserToCommissionFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        userToCommissionRes.resolve();
                    }
                }),
            ),
            this.store.select(getUserToCommissionShiftFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        userToCommissionShiftRes.resolve();
                    }
                }),
            ),
            this.store.select(getEntityRoleFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        entityRoleRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    ngOnDestroy(): void {
        super._onDestroy();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    ngOnInit(): void {
        if (this.Dialogdata && !this.PreventDialog) {
            this.ComponentData = this.Dialogdata;
        }
        const commission$ = this.store.select(getCommissionsFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getCommissionById({ id:  this.ComponentData.commissionId }))),
            tap((c) =>
               this.ComponentData.hideStartDate === true ? this.TimespanForm.reset() :
                   this.TimespanForm.reset({
                    from: moment( this.ComponentData.preFilledStartDate || c.StartDate || c.PlannedStartDate || new Date()),
                    to: moment( this.ComponentData.preFilledEndDate || c.EndDate || c.PlannedEndDate || new Date()),
                }),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        const employeesWithUserId$ = this.store.select(getEmployeesFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getEmployees)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        const activeEmployeesWithUserId$ = employeesWithUserId$.pipe(map(employees => employees.filter(e => e.EmployeeStatus === EmployeeStatusEnum.Aktiv && !e.Deleted)))
        this.SelectedUsers$ = combineLatest([
            this.store.select(getEntityRoleFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getEntityRoleDictionary)),
            ),
            this.store.select(getUserToCommissionFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getUserToCommissions)),
            ),
            this.store.select(getUserToCommissionShiftFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getUserToCommissionShifts)),
            ),
            employeesWithUserId$.pipe(map((employees) => new Map<number, EmployeeEntity>(employees.map((e) => [e.UserId, e])))),
        ]).pipe(
            map(([roles, u2c, u2cs, employeeMap]) => {
                const userIds = new Map<number, { u2cs: User2CommissionShiftEntity[]; u2c: UserToCommissionEntity[] }>();
                u2c.filter((u) => u.CommissionId ===  this.ComponentData.commissionId).forEach((u) => {
                    if (userIds.has(u.UserId)) {
                        userIds.get(u.UserId).u2c.push(u);
                    } else {
                        userIds.set(u.UserId, { u2c: [u], u2cs: [] });
                    }
                });
                u2cs.filter((u) => u.CommissionId ===  this.ComponentData.commissionId).forEach((u) => {
                    if (userIds.has(u.UserId)) {
                        userIds.get(u.UserId).u2cs.push(u);
                    } else {
                        userIds.set(u.UserId, { u2c: [], u2cs: [u] });
                    }
                });
                const ret: SelectedUserType[] = [];
                userIds.forEach((v, k) =>
                    ret.push({
                        userId: k,
                        label:
                            (employeeMap.get(k)?.DisplayName || '') +
                            (v.u2c.length
                                ? ' (' + v.u2c.map((u) => (u.RoleId ? roles[u.RoleId]?.DisplayName : '')).join(', ') + ')'
                                : '') /* + v.u2cs.map(u => `<br/>${formatDate(u.StartDate, 'shortDate', 'de-DE')} - ${formatDate(u.EndDate, 'shortDate', 'de-DE')}`)*/,
                        u2cIds: v.u2c.map((u) => u.Id),
                        u2csIds: v.u2cs.map((u) => u.Id),
                        timeSpans: v.u2cs.map((u) => `${formatDate(u.StartDate, 'shortDate', 'de-DE')} - ${formatDate(u.EndDate, 'shortDate', 'de-DE')}`),
                    }),
                );
                return ret;
            }),
        );
        this.TableData$ = combineLatest([
            activeEmployeesWithUserId$,
            this.store.select(getJobSpecificationFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getJobSpecificationDictionary)),
            ),
            commission$,
            this.EntityRolesForCommissions$.pipe(map((roles) => roles.map((role) => ({ id: role.Id, label: role.DisplayName })))),
        ]).pipe(
            map(([employees, jobs, commission, entityRoleOptions]) =>
                employees
                    .filter((e) => e.UserId)
                    .map<ITableData>((e) => {
                        const timespanForm: FormGroup<{ from: FormControl<Moment>; to: FormControl<Moment> }> = new FormGroup({
                            from: new FormControl<Moment>(moment(commission.StartDate || commission.PlannedStartDate || new Date())),
                            to: new FormControl<Moment>(moment(commission.EndDate || commission.PlannedEndDate || new Date())),
                        });
                        const job = e.JobSpecificationId && jobs[e.JobSpecificationId];
                        return {
                            userId: e.UserId,
                            selectedForm: new FormControl<boolean>(false),
                            jobSpecificationId: e.JobSpecificationId,
                            employeeStaus: e.EmployeeStatus,
                            name: {
                                __typename: 'string',
                                content: e.DisplayName,
                            },
                            jobSpecification: {
                                __typename: 'string',
                                content: job?.Name || '',
                            },
                            conflicts: {
                                __typename: 'custom',
                                content$: combineLatest([
                                    this.store.select(getUserToCommissionShifts).pipe(map((u2cs) => u2cs.filter((u) => u.UserId === e.UserId))),
                                    this.store.select(getEmployeeToVacations).pipe(map((e2v) => e2v.filter((u) => u.EmployeeId === e.Id))),
                                    this.store.select(getCommissionDictionary),
                                    this.cls.getSingle$('Commission'),
                                    this.cls.getMultiple$('Commission'),
                                ]).pipe(
                                    switchMap(([u2cs, e2v, commissions, commissionLabelSingle, commissionLabelMilti]) =>
                                        timespanForm.valueChanges.pipe(
                                            startWith(timespanForm.value),
                                            map(({ from, to }) => (from && to && from.isSameOrBefore(to) ? calculateCollisions(from.toDate(), to.toDate(), u2cs, e2v, commission.Id) : null)),
                                            map((v) => {
                                                if (!v || !(v.commissions.length + v.vacation.length + v.absent.length + v.illness.length + v.illnesswithoutcontinuedpay.length)) {
                                                    return null;
                                                } else {
                                                    const labels: string[] = [];
                                                    const tooltips: string[] = [];
                                                    if (v.commissions.length) {
                                                        labels.push(v.commissions.length + (v.commissions.length === 1 ? (' '+commissionLabelSingle) : (' '+commissionLabelMilti)));
                                                        tooltips.push(
                                                            (commissionLabelMilti+':'),
                                                            ...v.commissions
                                                                .map((c) => [
                                                                    commissions[c.commissionId].GetDisplayName(),
                                                                    ...c.user2commissionShifts.map((v) => '  Zeitraum: ' + formatDate(v.StartDate, 'shortDate', 'de-DE') + ' - ' + formatDate(v.EndDate, 'shortDate', 'de-DE')),
                                                                ])
                                                                .flat(),
                                                        );
                                                    }
                                                    if (v.vacation.length) {
                                                        labels.push(v.vacation.length + (v.vacation.length === 1 ? ' Urlaubsantrag' : ' Urlaubsanträge'));
                                                        tooltips.push('Urlaubsanträge:', ...v.vacation.map((v) => '  Zeitraum: ' + formatDate(v.StartDate, 'shortDate', 'de-DE') + ' - ' + formatDate(v.EndDate, 'shortDate', 'de-DE')));
                                                    }
                                                    if (v.absent.length) {
                                                        labels.push(v.absent.length + (v.absent.length === 1 ? ' Abwesenheit' : ' Abwesenheiten'));
                                                        tooltips.push('Abwesenheiten:', ...v.absent.map((v) => '  Zeitraum: ' + formatDate(v.StartDate, 'shortDate', 'de-DE') + ' - ' + formatDate(v.EndDate, 'shortDate', 'de-DE')));
                                                    }
                                                    if (v.illness.length) {
                                                        labels.push(v.illness.length + (v.illness.length === 1 ? ' Krankheitsausfall' : ' Krankheitsausfälle'));
                                                        tooltips.push('Krankheitsausfälle:', ...v.illness.map((v) => '  Zeitraum: ' + formatDate(v.StartDate, 'shortDate', 'de-DE') + ' - ' + formatDate(v.EndDate, 'shortDate', 'de-DE')));
                                                    }
                                                    if (v.illnesswithoutcontinuedpay.length) {
                                                        labels.push(v.illnesswithoutcontinuedpay.length + (v.illnesswithoutcontinuedpay.length === 1 ? ' Krankheitsausfall ohne Lohnfortzahlung' : ' Krankheitsausfälle ohne Lohnfortzahlung'));
                                                        tooltips.push('Krankheitsausfälle:', ...v.illnesswithoutcontinuedpay.map((v) => '  Zeitraum: ' + formatDate(v.StartDate, 'shortDate', 'de-DE') + ' - ' + formatDate(v.EndDate, 'shortDate', 'de-DE')));
                                                    }
                                                    return {
                                                        label: labels.join(', '),
                                                        tooltip: tooltips.join('\n'),
                                                    };
                                                }
                                            }),
                                            shareReplay({ refCount: true, bufferSize: 1 }),
                                        ),
                                    ),
                                ),
                            },
                            timespan: {
                                __typename: 'timespan',
                                formGroup: timespanForm,
                            },
                            role: {
                                __typename: 'select',
                                options: entityRoleOptions,
                                optionLabel: (o) => o.label,
                                formControl: new FormControl<number | null>(job ? entityRoleOptions.find((er) => er.label === job.Name.trim())?.id || null : null),
                            },
                            editor: {
                                __typename: 'boolean',
                                formControl: new FormControl<boolean>(false),
                            },
                        };
                    }),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(
                    switchMap((filter) =>
                        (filter[FilterTypes.HideKollision] ? combineLatest(rows.map((row) => row.conflicts.content$.pipe(map((content) => (content ? null : row))))).pipe(map(rows => rows.filter(isNotNullOrUndefined))) : of(rows)).pipe(
                            map((rowsFiltered) =>
                                rowsFiltered.filter(r => (!filter[FilterTypes.JobSpecificationIds]?.length || r.jobSpecificationId && filter[FilterTypes.JobSpecificationIds].includes(r.jobSpecificationId + '')) && !filter[FilterTypes.Status]?.length || filter[FilterTypes.Status].some(f => f.id === r.employeeStaus))
                            ),
                        ),
                    ),
                ),
            ),
            switchMap((rows) =>
                this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.name.content, r.jobSpecification.content].filter(isNotNullOrUndefined).some((s) => stringSearch(s, searchString)))),
                ),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.subscriptions.push(
            this.TimespanForm.valueChanges.subscribe((x) => {
                firstValueFrom(this.TableData$).then((data) => {
                    data.forEach((item) => {
                        if (item.timespan.__typename === 'timespan') {
                            item.timespan.formGroup.reset(x);
                        }
                    });
                });
            }),
            this.EntityRoleForm.valueChanges.subscribe((x) => {
                firstValueFrom(this.TableData$).then((data) => {
                    data.forEach((item) => {
                        if (item.role.__typename === 'select') {
                            item.role.formControl.reset(x ? x.Id : null);
                        }
                    });
                });
            }),
        );

        if (this.ComponentData.defaultFilter?.jobSpecificationIds) {
            this.FilterValues$.next({ ...this.FilterValues$.value, [FilterTypes.JobSpecificationIds]: this.ComponentData.defaultFilter.jobSpecificationIds.map(id => id+'') });
        }
    }

    ngAfterViewInit(): void {
        this.TableDataConfig$ = this.BS.MobileQuery.pipe(
            distinctUntilChanged(),
            map((mobile) => {
                return [
                    {
                        header: 'Name',
                        id: 'name',
                        sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
                    },
                    {
                        header: 'Jobbezeichnung',
                        id: mobile ? null : 'jobSpecification',
                        sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
                    },
                    {
                        header: 'Kollisionen',
                        id: mobile ? null : 'conflicts',
                        customCellTemplate: this.collisionCellTemplate,
                    },
                    {
                        header: 'Zeitraum',
                        id: 'timespan',
                        width: '9.5rem',
                    },
                    {
                        header: 'Rolle',
                        id: 'role',
                        width: mobile ? '5rem' : '8rem',
                    },
                    {
                        header: 'Verantwortlich',
                        id: mobile ? null : 'editor',
                        width: '5rem',
                    },
                ].filter((c) => !!c.id);
            }),
        );
    }
    ClickUserToCommission(entity: SelectedUserType) {
        this.dialog.open(SelectUserCommissionLegacyComponent, {
            ...SelectUserCommissionLegacyComponent.DefaultConfig,
            data: { commissionId:  this.ComponentData.commissionId, onlyOneUser: entity.userId },
        });
    }
    RemoveSelected(entity: SelectedUserType) {
        entity.u2csIds.forEach((id) => this.store.dispatch(UserToCommissionShiftActionTypes.DeleteOne({ Payload: id })));
        entity.u2cIds.forEach((id) => this.store.dispatch(UserToCommissionActionTypes.Delete({ Payload: { id: id } })));
    }
    Submit() {
        this.LS.startLoading('add-user-To-commissions');
        firstValueFrom(this.TableData$).then((data) => {
            const userToCommissionActions = [];
            const userToCommissionShiftActions = [];
            data.filter((d) => d.selectedForm.value).forEach((d) => {
                userToCommissionActions.push(
                    UserToCommissionActionTypes.Create({
                        Payload: {
                            userId: d.userId,
                            commissionId:  this.ComponentData.commissionId,
                            roleId: +d.role.formControl.value || undefined,
                            assignee: !!d.editor.formControl.value,
                        },
                    }),
                );
                if (d.timespan.formGroup.value.from && d.timespan.formGroup.value.to && d.timespan.formGroup.value.from.isSameOrBefore(d.timespan.formGroup.value.to)) {
                    const endDate = d.timespan.formGroup.value.to.toDate();
                    endDate.setHours(23, 59, 59, 999);

                    userToCommissionShiftActions.push(
                        UserToCommissionShiftActionTypes.AddUser2CommissionShift({
                            Payload: {
                                commissionId:  this.ComponentData.commissionId,
                                userId: d.userId,
                                startDate: FrontendDate(d.timespan.formGroup.value.from.toDate()),
                                endDate: FrontendDate(endDate),
                            },
                        }),
                    );
                }
            });
            if (userToCommissionActions.length + userToCommissionShiftActions.length) {
                firstValueFrom(
                    this.actions$.pipe(
                        ofType(UserToCommissionShiftActionTypes.UpdateAll, BaseActionTypes.ErrorAction, UserToCommissionActionTypes.UpdateAll),
                        skip(userToCommissionActions.length + userToCommissionShiftActions.length - 1),
                    ),
                ).then(() => {
                    this.LS.endLoading('add-user-To-commissions');
                });
            } else {
                this.LS.endLoading('add-user-To-commissions');
            }
            userToCommissionActions.forEach((a) => this.store.dispatch(a));
            userToCommissionShiftActions.forEach((a) => this.store.dispatch(a));
        });
    }
}
