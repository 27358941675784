import { MatDialogConfig } from '@angular/material/dialog';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { environment } from '../../environments/environment';
import { ClockInEntity, ClockInTypes } from '../dave-data-module/entities/clock-in.entity';
import { FolderEntity } from '../dave-data-module/entities/folder.entity';
import { ViewStyleConfig, ViewStyleSettingEntity, ViewStyleSettingViewEnum } from '../dave-data-module/entities/viewStyleSetting.entity';

/** Filterfunktion, die als Type Guard verwendet werden kann */
export const isNotNullOrUndefined = <T>(it: T | null | undefined | void): it is T => it != null;

/**
 * Hilfsfunktion, die einen sich oft wiederholenden Teil des GraphQL Query Strings zusammenbaut.
 *
 * Bekommt ein Objekt (`action` bzw. `Payload`) und den Namen einer Objekteigenschaft (`Id` etc.)
 * und erstellt, je nachdem ob die Eigenschaft `null` oder `undefined` ist, einen leeren String oder
 * einen String der Form `name: wert` zusammen. Der Wert wird dabei escaped.
 *
 * Da der Name der Eigenschaft und der resultierende String übereinstimmen, eignet sich diese
 * Funktion nur für die Verwendung mit den generierten `Mutation`/`QueryArgs`-Interfaces.
 */
export const stringifyIfNotNullOrUndefined = <T extends {}>(obj: T, key: keyof T) => (obj[key] != null ? `${key.toString()}: ${JSON.stringify(obj[key])}` : '');

export const stringifyIfNotUndefined = <T extends {}>(obj: T, key: keyof T) => (obj[key] !== undefined ? `${key.toString()}: ${JSON.stringify(obj[key])}` : '');
export const DEFAULT_START_TIME = '07:00';
export const Mehrwertsteuer = 0.19;
export const DEFAULT_TAX = 19;
export const ProductName = 'c2go';
export const CompanyName = 'company to cloud GmbH';
export const HomepageBaseUrl = 'https://c2c-erp.de/';
/** Debounce für Suchfelder, in ms */
export const SearchQueriesDebounceTime = 500;

/**
 * Hilfsinterface für die Verwendung von MatTables.
 *
 * Damit die Templates nicht unübersichtlich werden, kann in der zugehörigen
 * Klasse ein Array dieses Interfaces erstellt und im Template mit *ngFor
 * verwendet werden (siehe Beispiel).
 *
 * Der erste Typparameter entspricht den Daten, die die MatTable anzeigen soll.
 * Der optionale zweite Typparameter bestimmt den Typ der `name` Eigenschaft.
 * Standardmäßig erwartet diese Eigenschaftsnamen des Datentyps (`keyof T`),
 * kann aber auch z.B. zu String erweitert werden.
 *
 * Beispiel:
 * - `DataSource` ist ein Array oder eine `MatTableDataSource` des Typs `T`
 * - `Columns` ist ein Array dieses Interfaces
 * - `DisplayedColumns` ist ein Array aus Strings ("name" in diesem Interface)
 *
 * `rowData` ist vom Typ `T`.
 *
 * ```html
 * <table mat-table [dataSource]="DataSource">
 *   <ng-container *ngFor="let column of Columns" [matColumnDef]="column.name">
 *       <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
 *       <td mat-cell *matCellDef="let rowData">
 *         {{ column.cell ? column.cell(rowData) : rowData[column.name] }}
 *       </td>
 *   </ng-container>
 *
 *   <tr mat-header-row *matHeaderRowDef="DisplayedColumns"></tr>
 *   <tr mat-row *matRowDef="let rowData; columns: DisplayedColumns"></tr>
 * </table>
 * ```
 */
export interface TableColumnConfig<T extends {}, K extends PropertyKey = keyof T> {
    /**
     * Mappingfunktion, die zurückgibt, was in dieser Spalte für das aktuelle
     * Arrayelement angezeigt werden soll.
     *
     * Sollte das Arrayelement übergeben bekommen. Wenn nicht gesetzt, sollte
     * beispielsweise `rowData[column.name]` im Template verwendet werden.
     */
    cell?: (rowData: T) => string;
    /** Text, der im Header dieser Spalte angezeigt wird. */
    header: string;
    /**
     * Einzigartiger Name für diese Spalte. Wenn ein `MatSort` zur Sortierung
     * benutzt wird, muss dieser Name dem Namen der Eigenschaft des
     * Arrayelements entsprechen (`keyof T`). Kann dementsprechend auch via
     * `rowData[column.name]` zum Anzeigen verwendet werden.
     */
    name: K;
    width?: string;
}

export interface Address {
    AddressSupplement?: string;
    Street: string;
    PostalCode: string;
    City: string;
    Country: string;
    Longitude?: string;
    Latitude?: string;
}

export const AddressEntityPropertyNames = new Map<keyof Address, string>([
    ['Street', 'Straße'],
    ['PostalCode', 'Postleitzahl'],
    ['City', 'Stadt'],
    ['Country', 'Land'],
    ['Longitude', 'Längengrad'],
    ['Latitude', 'Breitengrad'],
]);
export const getAddressString = (location: Address, withNewLines = true) =>
    [
        `${location?.AddressSupplement ? `${location.AddressSupplement}` : ''}${location?.Street || ''}`,
        `${location?.PostalCode ? location?.PostalCode + ' ' : ''}${location?.City || ''}`,
        `${location?.Country || ''}`
    ].join(withNewLines ? '\n' : ' ').trim();

export const getNavigationURL = (locationFrom: Address, locationTo: Address) => {
    let out = 'https://www.google.com/maps/dir/?api=1';
    if (locationFrom !== null) {
        out +=
            '&origin=' +
            encodeURI(
                (locationFrom.Street ? locationFrom.Street + ', ' : '') +
                    (locationFrom.PostalCode ? locationFrom.PostalCode + ' ' : '') +
                    (locationFrom.City ? locationFrom.City + ' ' : '') +
                    (locationFrom.Country ? locationFrom.Country : ''),
            );
    }
    out +=
        '&destination=' +
        encodeURI((locationTo.Street ? locationTo.Street + ', ' : '') + (locationTo.PostalCode ? locationTo.PostalCode + ' ' : '') + (locationTo.City ? locationTo.City + ' ' : '') + (locationTo.Country ? locationTo.Country : ''));

    return out;
};

export const getMapsUrl = (location: Address) =>
    'https://www.google.com/maps/search/?api=1&query=' +
    (location.Street ? location.Street + '+' : '') +
    (location.PostalCode ? location.PostalCode + '+' : '') +
    (location.City ? location.City + '+' : '') +
    (location.Country ? location.Country : '');

export const linkify = (textToCheck: string) => {
    var urlRegex =
        /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
    var absoluteRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
    return textToCheck?.replace(urlRegex, function (url) {
        return '<a href="' + (absoluteRegex.test(url) ? url : '//' + url) + '">' + url + '</a>';
    });
};

// for JSON parse/stringify Map Support
export function JSONReplacer(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else if (this[key] instanceof Date) {
        return {
            dataType: 'Date',
            value: this[key].toUTCString(),
        };
    } else {
        return value;
    }
}
export function JSONReviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        } else if (value.dataType === 'Date') {
            return new Date(value.value);
        }
    }
    return value;
}
export const sameDay = (a: Date, b: Date) => a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();

export const stringSearch = (value: string, searchString: string, caseSensitive = false) => {
    if (!caseSensitive) {
        value = value.toLowerCase();
        searchString = searchString.toLowerCase();
    }
    const strings = searchString.split(/ |,/).filter((s) => !!s);
    return !strings.some((s) => !value.includes(s));
};

// funktion um duplikate aus arrays zu entfernen
export function uniqArray<T>(a: Array<T>): Array<T> {
    var prims = { boolean: {}, number: {}, string: {} },
        objs = [];

    return a.filter(function (item) {
        var type = typeof item;
        if (type in prims) return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
        else return objs.indexOf(item) >= 0 ? false : objs.push(item);
    });
}
// header-height steht auch in src/app/dave-utils-module/dave-shared-components-module/styles/_base.scss
export const DaveHeaderHeight = '3.5rem';
// list-item-height steht auch in src/app/dave-utils-module/dave-shared-components-module/styles/_clickable-list.scss
export const DaveListItemHeight = '3.5rem';
export function removeFromViewStyle(setting: ViewStyleConfig[], type: string): ViewStyleConfig[] {
    return setting.filter((s) => s.type !== type).map((s) => ({ ...s, elements: s.elements ? removeFromViewStyle(s.elements, type) : s.elements }));
}
export function selectViewStyleSetting(viewStyles: ViewStyleSettingEntity[], view: ViewStyleSettingViewEnum, entityTypeId: number = null, entityId: number = null) {
    let vss = viewStyles.filter((v) => v.View === view);
    if (entityId) {
        const setting = vss.find((v) => v.EntityId === entityId);
        if (setting) {
            return setting;
        }
    }
    if (entityTypeId) {
        let vssByCommissionType = vss.filter((v) => v.EntityTypeId === entityTypeId && !v.EntityId);
        if (vssByCommissionType.some((v) => v.PartnerId)) {
            vssByCommissionType = vssByCommissionType.filter((v) => v.PartnerId);
        }
        if (vssByCommissionType.length) {
            if (vssByCommissionType.length > 1) {
                console.error('ViewStyleSetting konnte nicht eindeutig bestimmt werden', vssByCommissionType);
            }
            return vssByCommissionType[0];
        }
    }
    vss = vss.filter((v) => !v.EntityId && !v.EntityTypeId);
    if (vss.some((v) => v.PartnerId)) {
        vss = vss.filter((v) => v.PartnerId);
    }
    if (vss.length > 1) {
        console.error('ViewStyleSetting konnte nicht eindeutig bestimmt werden', vss);
    }
    return vss[0];
}
export function isoCountryCodeToFlagEmoji(country) {
    return String.fromCodePoint(...[...country.toUpperCase()].map((c) => c.charCodeAt() + 0x1f1a5));
}

export const getSubFolders = (folderId: number, folders: FolderEntity[]): FolderEntity[] => {
    const subFolders = folders.filter((f) => f.ParentId === folderId);
    return subFolders.reduce((prev, curr) => [...prev, ...getSubFolders(curr.Id, folders)], subFolders);
};
export async function OpenHTMLInputPicker(input: HTMLInputElement) {
    try {
        // @ts-ignore
        await input.showPicker();
    } catch (error) {
        input.click();
    }
}

export function b64toBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: dataURI.split(';')[0].split(':')[1] });
}
export type sfdtSafeString = string;
export const sfdtSafeString = (s: string | null): sfdtSafeString | null => (s ? JSON.stringify(s.replace(/\n/g, '\u000b')).slice(1, -1) : null);

export const MY_DATE_FORMAT = {
    parse: {
        dateInput: 'DD.MM.YY', // this is how your date will be parsed from Input
    },
    display: {
        dateInput: 'DD.MM.YY', // this is how your date will get displayed on the Input
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
export const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: environment.gatewayApiUrl + '/file/files',
    maxFilesize: 5000, // in MB
    timeout: 15 * 60000,
    method: 'PUT',

    // dictFileSizeUnits?: any;
    dictDefaultMessage: 'Klicken oder Dateien hinein ziehen',
    dictFallbackMessage: 'Dateien hochladen',
    dictFileTooBig: 'Datei zu groß',
    // dictResponseError: '',
    dictInvalidFileType: 'Falscher Dateityp',
    dictRemoveFile: 'Datei entfernen',
    dictCancelUpload: 'Abbrechen',
    dictUploadCanceled: 'Upload abgebrochen',
    // dictFallbackText: '',
    dictMaxFilesExceeded: 'Maximale Anzahl an Dateien erreicht',
    dictRemoveFileConfirmation: 'Datei wirklich entfernen?',
    dictCancelUploadConfirmation: 'Upload wirklich abbrechen?',
};
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 500,
    hideDelay: 0,
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
};
export const appMatDialogDefaultConfig: Omit<MatDialogConfig, 'panelClass'> & { panelClass: string[] } = { panelClass: ['custom-dialog-class-mobile-fullscreen'] }; // panelClass must be an array because the dialog components use it as an array

export function programmaticDownloadAnchor(href: string, fileName: string) {
    const a = document.createElement('a');
    a.href = href;
    a['download'] = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
export const blobToBase64 = (file: Blob): Promise<string | ArrayBuffer> =>
    new Promise<string | ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
export const getImageDimensionsFromBase64 = (file: string) =>
    new Promise<{ width: number; heigth: number }>((resolved, rejected) => {
        let i = new Image();
        i.onload = () => {
            resolved({ width: i.width, heigth: i.height });
        };
        i.src = file;
    });

export function sortByDate(a: Date, b: Date, isAsc: boolean = true) {
    if (a != null && b != null) {
        return (a.getTime() - b.getTime()) * (isAsc ? 1 : -1);
    } else if (a == null && b != null) {
        return 0;
    } else if (a == null) {
        return -1 * (isAsc ? 1 : -1);
    } else {
        return 1 * (isAsc ? 1 : -1);
    }
}
export function sortByDateProperty<T>(property: keyof T, isAsc = true) {
    return (a: T, b: T) => sortByDate(a[property] as any, b[property] as any, isAsc);
}

export function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function sortClockIns(globalTypeId: number) {
    return (a: ClockInEntity, b: ClockInEntity) => {
        if (a.TimeStamp.getTime() === b.TimeStamp.getTime()) {
            if (a.ClockInTypeId !== b.ClockInTypeId) {
                if (a.ClockInTypeId === globalTypeId) {
                    return a.Type === ClockInTypes.Start ? -1 : 1;
                }
                if (b.ClockInTypeId === globalTypeId) {
                    return b.Type === ClockInTypes.Start ? 1 : -1;
                }
            } else if (a.ClockInTypeId === globalTypeId && a.Type !== b.Type) {
                return a.Type === ClockInTypes.Start ? -1 : 1;
            }
            if (a.Type !== b.Type) {
                return a.Type === ClockInTypes.Start ? 1 : -1;
            }
        }
        return a.TimeStamp.getTime() - b.TimeStamp.getTime();
    };
}
export type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;
export type ObservableElement<T> = T extends Observable<infer X> ? X : never;
export const getMinimumBreakHours = (workingHours: number) => {
    if (workingHours >= 8.5) {
        // müsste eigentlich > 9 sein aber ist dem BE nachgebaut
        return 0.75;
    }
    if (workingHours >= 6) {
        // müsste eigentlich > 6 sein aber ist dem BE nachgebaut
        return 0.5;
    }
    return 0;
};

export const compareEqualityAsJsonString = <T>(a: T, b: T) => {
    return JSON.stringify(a) === JSON.stringify(b);
};
export const compareArrays = (array1: any[], array2: any[]) => {
    if (!array1 || !array2) {
        return false;
    }

    if (array1.length !== array2.length) {
        return false;
    }

    for (let i = 0, l = array1.length; i < l; i++) {
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            if (!compareArrays(array1[i], array2[i])) {
                return false;
            }
        } else if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
};
export const MathRound = (number: number, roundNegativeLikePositive = false) => {
    if (roundNegativeLikePositive) {
        return Math.sign(number) * Math.round(Math.abs(number));
    } else {
        return Math.round(number);
    }
};
export function getMimeTypeMagicNumber(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onerror = reject;
        fileReader.onloadend = (e) => {
            // @ts-ignore
            const arr = new Uint8Array(e.target.result).subarray(0, 4);
            let header = '';
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
            resolve(header);
        };
        fileReader.readAsArrayBuffer(blob);
    });
}
