import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

import { PreviewComponent } from '../../../dave-dms-module/dave-dms/preview/preview.component';

export interface DaveFilePreviewComponentDialogData {
    fileId: number;
    emailEditorDialogData?:PreviewComponent['EmailEditorDialogData'];
    fileExplorerContext?: PreviewComponent['fileExplorerContext'];
    addBusinessVolumeDefaultValues?: PreviewComponent['addBusinessVolumeDefaultValues'];
}
@Component({
    selector: 'app-dave-file-preview',
    templateUrl: './dave-file-preview.component.html',
    styleUrls: ['./dave-file-preview.component.scss'],
})
export class DaveFilePreviewComponent implements OnInit {
    public static DefaultConfig: MatDialogConfig = {
        maxWidth: '100vw',
        hasBackdrop: false,
        panelClass: 'custom-dialog-class-for-overlay-on-right-side',
    };
    public FileId$: BehaviorSubject<number>;
    constructor(
        private dialogRef: MatDialogRef<DaveFilePreviewComponent>,
        @Inject(MAT_DIALOG_DATA) public DialogData: DaveFilePreviewComponentDialogData,
    ) {}
    CloseDialog() {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.FileId$ = new BehaviorSubject(this.DialogData.fileId);
    }
}
