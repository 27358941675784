
import { combineLatest, BehaviorSubject,Subscription } from 'rxjs';
import { filter } from "rxjs/operators";
import { CustomLabelService } from "../../../services/custom-label.service";
import { Injectable, OnDestroy } from "@angular/core";

/**
 * @deprecated use CustomLabelService instead
 */
@Injectable({
    providedIn: 'root',
})
export class CustomerNameService implements OnDestroy {
    protected singe$ = new BehaviorSubject<string>('Kunde');
    protected multiple$ = new BehaviorSubject<string>('Kunden');
    protected subscription: Subscription;

    constructor(service: CustomLabelService) {
        this.subscription = combineLatest([service.getSingle$('Customer'), service.getMultiple$('Customer')])
            .subscribe(([single, multiple]) => {
                this.singe$.next(single);
                this.multiple$.next(multiple);
            });
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public GetSingle() {
        return this.singe$.getValue();
    }

    public GetSingle$() {
        return this.singe$.asObservable();
    }

    public GetMultiple() {
        return this.multiple$.getValue();
    }

    public GetMultiple$() {
        return this.multiple$.asObservable();
    }
}
