import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconPipeModule } from '../icon-pipe/icon-pipe.module';
import { SelectSearchModule } from '../select-search/select-search.module';
import { AppButtonModule } from './../app-button-module/app-button.module';
import { AppFilterComponent } from './app-filter/app-filter.component';
import { FilterCardComponent } from './filter-card/filter-card.component';
import { CustomFormsModule } from '../../custom-forms-module/custom-forms.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
    declarations: [AppFilterComponent, FilterCardComponent],
    exports: [AppFilterComponent, FilterCardComponent],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    FormsModule,
    FontAwesomeModule,
    IconPipeModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    AppButtonModule,
    ReactiveFormsModule,
    SelectSearchModule,
    MatCardModule,
    CustomFormsModule,
    MatTooltipModule,
  ],
})
export class AppFilterModule {
    constructor() {}
}
