import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, switchMap } from 'rxjs';
import { skip } from 'rxjs/operators';
import { NewCustomerDialogComponent, NewCustomerDialogComponentDialogData, NewCustomerDialogComponentDialogReturnData } from '../../../../components/new-view/new-customer/new-customer-dialog/new-customer-dialog.component';
import { newCustomerDefaultValues } from '../../../../components/new-view/new-customer/new-customer-main/new-customer-main.component';
import { Person2EntityEntityTypeEnum } from '../../../../dave-data-module/entities/person2entity.entity';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { Person2EntityActionTypes } from '../../../../dave-data-module/State/actions/person2entity.action';
import { getCustomerDictionary } from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getPerson2Entities } from '../../../../dave-data-module/State/selectors/person2entity.selectors';
import { DaveDoubleIconModule } from '../../../../dave-double-icon/dave-double-icon.module';
import { DaveLoadingModule } from '../../../../dave-loading/dave-loading.module';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../../dave-utils-module/app-filter-module/app-filter.module';
import { CustomerNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { CustomerAdministrationMeta } from '../../../../helper/page-metadata';
import { CustomLabelService } from '../../../../services/custom-label.service';
import { LoadingService } from '../../../../services/loading.service';
import { DaveSelectUserModule } from '../../../dave-select-user.module';
import { ISelectedElement } from '../../multi-select-table/multi-select-table.component';
import { SelectCustomerComponent } from '../select-customer/select-customer.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';

export interface SelectCustomerDialogComponentDialogData {
    personId: number;
    newCustomerDefaultValues?: newCustomerDefaultValues;
    preventNewPersonDialogWhileCreateCustomer?: boolean;
}
interface SelectedCustomerType extends ISelectedElement {
    customerId: number;
    personToEntityIds: number[];
}
@Component({
    selector: 'app-select-customer-person-dialog',
    templateUrl: './select-customer-person-dialog.component.html',
    styleUrls: ['./select-customer-person-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, MatDialogModule, AppButtonModule, DaveSelectUserModule, DaveDoubleIconModule, MatBadgeModule, MatTooltipModule, FontAwesomeModule, AppFilterModule, DaveLoadingModule, SelectCustomerComponent],
})
export class SelectCustomerPersonDialogComponent {
    public static readonly DefaultConfig: MatDialogConfig = SelectUserDialogBase.DefaultConfig;
    @ViewChild(SelectCustomerComponent) selectCustomerComponent: SelectCustomerComponent<SelectedCustomerType>;
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedCustomers$: Observable<SelectedCustomerType[]>;

    public ContentResolved$: Observable<boolean>;
    protected dirty = false;
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectCustomerDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        private dialog: MatDialog,
        protected cls: CustomLabelService,
        private router: Router,
        private dialogRef: MatDialogRef<SelectCustomerPersonDialogComponent>,
    ) {
        this.SelectedCustomers$ = this.store.select(getPerson2Entities).pipe(
            map((entities) => entities.filter((e) => e.PersonId === this.Dialogdata.personId && e.EntityType === Person2EntityEntityTypeEnum.Customer)),
            switchMap((p2es) =>
                this.store.select(getCustomerDictionary).pipe(
                    map((customers) => {
                        const m = new Map<number, number[]>();
                        p2es.forEach((p2e) => {
                            if (m.has(p2e.EntityId)) {
                                m.get(p2e.EntityId).push(p2e.Id);
                            } else {
                                m.set(p2e.EntityId, [p2e.Id]);
                            }
                        });
                        return Array.from(m.entries()).map(([customerId, personToEntityIds]) => ({ customerId, personToEntityIds, label: customers[customerId]?.DisplayName }));
                    }),
                ),
            ),
        );
    }
    RouteToCustomer(entity: SelectedCustomerType) {
        this.router.navigate(['/', CustomerAdministrationMeta.Path, entity.customerId]);
    }
    RemoveSelected(entity: SelectedCustomerType) {
        this.LS.startLoading('remove-selected-customer');
        firstValueFrom(this.actions$.pipe(ofType(Person2EntityActionTypes.RemoveOne, BaseActionTypes.ErrorAction), skip(entity.personToEntityIds.length - 1))).then(() => {
            this.LS.endLoading('remove-selected-customer');
        });
        entity.personToEntityIds.forEach((id) => {
            this.store.dispatch(Person2EntityActionTypes.DeletePerson2Entity({ Payload: { id } }));
        });
    }
    Submit() {
        this.LS.startLoading('set-person-to-customer');
        firstValueFrom(combineLatest([this.selectCustomerComponent.TableData$, this.SelectedCustomers$])).then(([data, selected]) => {
            const toAdd = data.filter((d) => d.selectedForm.value && selected.every((s) => s.customerId !== d.customerId));
            if (!toAdd.length) {
                this.dirty = false;
                this.LS.endLoading('set-person-to-customer');
                return;
            }
            firstValueFrom(this.actions$.pipe(ofType(Person2EntityActionTypes.UpdateMany, BaseActionTypes.ErrorAction), skip(toAdd.length - 1))).then(() => {
                this.dirty = false;
                this.LS.endLoading('set-person-to-customer');
            });
            toAdd.forEach(({ customerId }) =>
                this.store.dispatch(
                    Person2EntityActionTypes.AddPerson2Entity({
                        Payload: {
                            personId: this.Dialogdata.personId,
                            entityType: Person2EntityEntityTypeEnum.Customer,
                            entityId: customerId,
                        },
                    }),
                ),
            );
        });
    }
    newCustomerClick() {
        firstValueFrom(
            this.dialog
                .open<NewCustomerDialogComponent, NewCustomerDialogComponentDialogData, NewCustomerDialogComponentDialogReturnData>(NewCustomerDialogComponent, {
                    ...NewCustomerDialogComponent.DefaultConfig,
                    data: {
                        defaultValues: this.Dialogdata?.newCustomerDefaultValues || null,
                        preventNewPersonDialog: this.Dialogdata?.preventNewPersonDialogWhileCreateCustomer || false,
                    },
                })
                .afterClosed(),
        ).then((customerId) => {
            if (customerId) {
                this.store.dispatch(
                    Person2EntityActionTypes.AddPerson2Entity({
                        Payload: {
                            personId: this.Dialogdata.personId,
                            entityType: Person2EntityEntityTypeEnum.Customer,
                            entityId: customerId,
                        },
                    }),
                );
            }
        });
    }
    closeDialog() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
    protected readonly CustomerAdministrationMeta = CustomerAdministrationMeta;
}
