<h1 mat-dialog-title>{{ headline$ | async }}</h1>
<mat-dialog-content>
    <div class="status-form mat-elevation-z2">
        <app-detail-list-template [Data]="statusData" [Editing]="true" [Inline]="true"></app-detail-list-template>
        <fa-icon class="p-2" icon="info-circle" size="2x"></fa-icon>
    </div>
    <app-detail-list-template style="margin-top: 0.5rem; margin-bottom: 1rem" mat-dialog-content *ngIf="listTemplateData$ | async as data" [Data]="data" [Editing]="true" [Inline]="true"> </app-detail-list-template>
    <span *ngIf="formMilestone.value.ClockInTypeId">Ist Stunden: {{ currHours$ | async | number: '1.0-1' }}</span>
    <app-progress-card-progress-bar *ngIf="formMilestone.value.TargetHours != null" [progress]="currHours$ | async" [progressTarget]="formMilestone.value.TargetHours"></app-progress-card-progress-bar>
    <!--    <div class="mat-elevation-z1 task-wrapper">-->
    <mat-card class="task-wrapper mat-elevation-z4" *ngIf="this.milestone$ | async">
        <mat-card-header>
            <fa-icon matCardAvatar [icon]="TaskPageMeta.Icon"></fa-icon>
            <mat-card-title>Aufgaben</mat-card-title>
            <span class="spacer"></span>
            <button class="ml-2" app-round-button matTooltip="Filter" aria-label="Filter" (click)="filter.Open()" [matBadge]="FilterAmount$ | async" [matBadgeHidden]="!(FilterAmount$ | async)"><fa-icon icon="filter"></fa-icon></button>
            <button class="ml-2" app-round-button matTooltip="Sortieren" aria-label="Sortieren" [matMenuTriggerFor]="sortMenu">
                <fa-icon icon="sort"></fa-icon>
            </button>
            <mat-menu #sortMenu>
                <button mat-menu-item (click)="TaskBoardSort = { sortBy: 'endDate', direction: 'asc' }"><fa-icon icon="chevron-up"></fa-icon> Frist</button>
                <button mat-menu-item (click)="TaskBoardSort = { sortBy: 'endDate', direction: 'dsc' }"><fa-icon icon="chevron-down"></fa-icon> Frist</button>
                <button mat-menu-item (click)="TaskBoardSort = { sortBy: 'priority', direction: 'asc' }"><fa-icon icon="chevron-up"></fa-icon> Priorität</button>
                <button mat-menu-item (click)="TaskBoardSort = { sortBy: 'priority', direction: 'dsc' }"><fa-icon icon="chevron-down"></fa-icon> Priorität</button>
            </mat-menu>
        </mat-card-header>
        <app-tasks-board (FileClicked)="onFileClick($event)" *ngIf="milestone$ | async as milestone" [Filter]="taskFilter$ | async" [NewTaskInitialValues]="{ MilestoneIds: [milestone.Id], CommissionId: milestone.CommissionId }"></app-tasks-board>
        <app-filter-card #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
    </mat-card>
    <!--        <header>-->
    <!--            <h2>Aufgaben</h2>-->
    <!--        </header>-->
    <!--    </div>-->
</mat-dialog-content>

<mat-dialog-actions class="actions">
    <button app-button Inverted mat-dialog-close Color="cancel">Abbrechen</button>
    <button app-round-button Color="red" *ngIf="milestoneId$ | async" (click)="onDeleteClick()" [IsLoading]="ls.IsLoading$ | async" [matTooltip]="'Meilenstein löschen'">
        <fa-icon icon="trash"></fa-icon>
    </button>
    <button app-button [Disabled]="formMilestone.invalid || formMilestone.pristine" [IsLoading]="ls.IsLoading$ | async" (click)="onSaveClick()" Color="green">Speichern</button>
</mat-dialog-actions>
