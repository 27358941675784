import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { ConstructionDiaryEntity, ConstructionDiaryEntityFromBackend } from '../../entities/construction-diary.entity';
import { FolderTypes } from '../../entities/folder.entity';
import { FolderDataService } from '../../services/folder-data.service';
import { HttpService } from '../../services/http.service';
import { BaseActionTypes } from '../actions/base.actions';
import { ConstructionDiaryActionTypes } from '../actions/construction-diary.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';

enum ErrorCodes {
    Load = 'Tagebücher Abrufen fehlgeschlagen',
    Add = 'Tagebuch hinzufügen fehlgeschlagen',
    Modify = 'Tagebuch bearbeiten fehlgeschlagen',
}

@Injectable()
export class ConstructionDiaryEffects {
    GetConstructionDiarys$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ConstructionDiaryActionTypes.Load),
                withLatestFrom(this.store.select(getToken)),
                switchMap(([{ Payload }, token]) => {
                    const query = `{
                constructionDiary${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    ${ConstructionDiaryEntity.GqlFields}
                }
            }`;
                    return this.api.graphQl({ query }, { token, retry: true }).pipe(
                        tap((res) => {
                            if (!Payload?.updatedSince || res.constructionDiary.length) {
                                this.store.dispatch(
                                    res && res.constructionDiary
                                        ? Payload?.updatedSince
                                            ? ConstructionDiaryActionTypes.UpdateMany({
                                                  Payload: res.constructionDiary.map((val) => ConstructionDiaryEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                            : ConstructionDiaryActionTypes.UpdateAll({
                                                  Payload: res.constructionDiary.map((val) => ConstructionDiaryEntityFromBackend(val)),
                                                  updateLatestUpdatedAt: true,
                                              })
                                        : BaseActionTypes.ErrorAction({
                                              Payload: { ToasterMessage: ErrorCodes.Load },
                                          }),
                                );
                            }
                        }),
                        catchError((err, caught) => {
                            this.store.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    AddConstructionDiarys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConstructionDiaryActionTypes.Add),
            map(
                ({ Payload }) => `
        mutation {
                createConstructionDiary(
                    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'generatedFileId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'fileId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'date')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'leistungsergebnisse')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'behinderungenErschwernisse')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'besondereVorkommnisse')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'leistungsaenderungen')}
                    ${Payload.status ? 'status: ' + Payload.status : ''}
                    ${stringifyIfNotNullOrUndefined(Payload, 'eventIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                ) {
                    ${ConstructionDiaryEntity.GqlFields}
                }
            }`,
            ),
            withLatestFrom(this.store.select(getToken)),
            concatMap(([query, token]) => this.api.graphQl({ query }, { token })),
            map((res) => res && res.createConstructionDiary),
            tap((res) => {
                if (res) {
                    this.folderDataService.getFolderFromEntity(res.id, FolderTypes.construction_diary, false);
                    setTimeout(() => {
                        this.folderDataService.getFolderFromEntity(res.id, FolderTypes.construction_diary, false);
                    }, 3000);
                }
            }),
            map((res) => (res ? ConstructionDiaryActionTypes.UpdateOne({ Payload: ConstructionDiaryEntityFromBackend(res) }) : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } }))),
            catchError(
                (err, caught) => (
                    this.store.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                    caught
                ),
            ),
        ),
    );

    ModifyConstructionDiarys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConstructionDiaryActionTypes.Modify),
            map(
                ({ Payload }) => `
        mutation {
                changeConstructionDiary(
                    ${stringifyIfNotNullOrUndefined(Payload, 'id')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'generatedFileId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'fileId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'date')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'leistungsergebnisse')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'behinderungenErschwernisse')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'besondereVorkommnisse')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'leistungsaenderungen')}
                    ${Payload.status ? 'status: ' + Payload.status : ''}
                    ${stringifyIfNotNullOrUndefined(Payload, 'eventIds')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'additionalData')}
                ) {
                    ${ConstructionDiaryEntity.GqlFields}
                }
            }`,
            ),
            withLatestFrom(this.store.select(getToken)),
            concatMap(([query, token]) => this.api.graphQl({ query }, { token })),
            map((res) => res && res.changeConstructionDiary),
            map((res) => (res ? ConstructionDiaryActionTypes.UpdateOne({ Payload: ConstructionDiaryEntityFromBackend(res) }) : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }))),
            catchError(
                (err, caught) => (
                    this.store.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                    caught
                ),
            ),
        ),
    );

    constructor(private actions$: Actions, private api: HttpService, private store: Store<State>, private folderDataService: FolderDataService) {}
}
