import { Component,Inject,OnDestroy,OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog,MatDialogConfig,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
BehaviorSubject,
combineLatest,
firstValueFrom,
map,
merge,
Observable,
of,
Subject,
Subscription,
switchMap
} from 'rxjs';
import { debounceTime,distinctUntilChanged,filter,shareReplay,skip,startWith,takeUntil,tap,withLatestFrom } from 'rxjs/operators';
import { CommissionWizardComponent,CommissionWizardComponentDialogData } from '../../../../dave-commission-module/components/commission-wizard/commission-wizard.component';
import {
createCommissionFormGroup,getCommissionFormSubscriptions,getCommissionLocationOptions,
resetCommissionFormGroup,
saveCommission,
setCommissionDetailListTemplateEntries
} from '../../../../dave-commission-module/components/detail-commission/helper';
import { CommissionResolver } from '../../../../dave-data-module/guards/commission.resolver';
import { CommissionTypeResolver } from '../../../../dave-data-module/guards/commissionType.resolver';
import { CustomerResolver } from '../../../../dave-data-module/guards/customer.resolver';
import { OfficeResolver } from '../../../../dave-data-module/guards/office.resolver';
import { UserToCommissionResolver } from "../../../../dave-data-module/guards/user-to-commission.resolver";
import { getFetched$ } from '../../../../dave-data-module/helper/helper';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { CommissionActionTypes } from '../../../../dave-data-module/State/actions/commission.actions';
import { getCommissionById,getCommissionsFetched,getCommissionSorted } from '../../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypeById,getCommissionTypes,getCommissionTypesFetched } from '../../../../dave-data-module/State/selectors/commissionType.selectors';
import {
getCustomerById,
getCustomers,
getCustomersFetched
} from '../../../../dave-data-module/State/selectors/customers.selectors';
import { getEntityRole } from "../../../../dave-data-module/State/selectors/entity-role.selector";
import { getOffices,getOfficesFetched } from '../../../../dave-data-module/State/selectors/offices.selectors';
import { getPartner } from '../../../../dave-data-module/State/selectors/partners.selectors';
import { getStatusFromBackoffice,getStatusFromBackofficeFetched } from '../../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { getUserToCommissions } from "../../../../dave-data-module/State/selectors/user-to-commission.selector";
import { getUser } from "../../../../dave-data-module/State/selectors/users.selectors";
import { FilterOption,FILTER_TYPE_MULTI_SELECT } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { CommissionNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { CustomerNameService } from '../../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { SelectSearchData } from '../../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { Address,isNotNullOrUndefined,SearchQueriesDebounceTime,stringSearch } from '../../../../helper/helper';
import { CommissionMeta } from '../../../../helper/page-metadata';
import { NewCommissionDialogComponent,NewCommissionDialogComponentDialogData,NewCommissionDialogComponentDialogReturnData } from '../../../../new-commission-dialog/new-commission-dialog.component';
import { CustomLabelService } from "../../../../services/custom-label.service";
import { DefaultFilterService,FilterApps } from '../../../../services/default-filter.service';
import { LoadingService } from '../../../../services/loading.service';
import { ISelectedElement,ITableConfig,TableContentType } from '../../multi-select-table/multi-select-table.component';
import { SelectUserDialogBase } from '../select-user-dialog-base';
import { Router } from '@angular/router';

export interface SelectCommissionDialogComponentDialogData {
    commissionId: number;
}
interface ITableData {
    commissionTypeId: number;
    commissionId: number;
    selectedForm: FormControl<boolean>;
    auftragsnummer: TableContentType<any> & { __typename: 'string' };
    interneNummer: TableContentType<any> & { __typename: 'string' };
    description: TableContentType<any> & { __typename: 'string' };
    type: TableContentType<any> & { __typename: 'string' };
    cssStyle?: string;
    cssClass?: string | string[];
}
interface SelectedCommissionsType extends ISelectedElement {
    commissionId: number;
}
enum filterNames {
    commissionType = 'commissionType',
}
interface IFilter {
    [filterNames.commissionType]: string[];
}
@Component({
    selector: 'app-select-commission-dialog',
    templateUrl: './select-commission-dialog.component.html',
    styleUrls: ['./select-commission-dialog.component.scss'],
})
export class SelectCommissionDialogComponent implements OnInit, OnDestroy {
    protected CommissionMeta = CommissionMeta;
    public static readonly DefaultConfig: MatDialogConfig = SelectUserDialogBase.DefaultConfig;
    public FilterSettings$: Observable<FilterOption[]> = this.store.select(getCommissionTypesFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getCommissionTypes)),
        map((commissionTypes) => {
            const ctValues = {};
            commissionTypes.forEach((c) => {
                ctValues[c.Id.toString()] = c.Name;
            });
            return [
                {
                    Name: filterNames.commissionType,
                    Type: FILTER_TYPE_MULTI_SELECT,
                    Label: 'Auftragsart',
                    Values: ctValues,
                },
            ];
        }),
    );
    public FilterValues$: BehaviorSubject<IFilter> = new BehaviorSubject({
        [filterNames.commissionType]: [],
    });
    public FilterAmount$ = this.FilterValues$.pipe(map((v) => (v[filterNames.commissionType]?.length ? 1 : 0)));
    public SearchString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    SelectedCommissions$: Observable<SelectedCommissionsType[]>;

    TableDataConfig: ITableConfig[] = [
        {
            header: 'Auftragsnummer',
            id: 'auftragsnummer',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Interne Nummer',
            id: 'interneNummer',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Beschreibung',
            id: 'description',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
        {
            header: 'Art',
            id: 'type',
            sortAccessor: (cell: TableContentType<any> & { __typename: 'string' }) => cell.content || '',
        },
    ];
    FilteredTableData$: Observable<ITableData[]>;
    TableData$: Observable<ITableData[]>;

    private subscriptions: Subscription[] = [];
    public ContentResolved$: Observable<boolean>;
    protected dirty = false;
    private customerFromCommission$ = this.store.select(getCommissionById({id: this.Dialogdata.commissionId})).pipe(switchMap(commission => commission?.CustomerId ? getFetched$(this.store, getCustomersFetched, getCustomerById({id: commission.CustomerId}), this.customerRes): of(null)))
    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA) public Dialogdata: SelectCommissionDialogComponentDialogData,
        private actions$: Actions,
        public LS: LoadingService,
        private defaultFilter: DefaultFilterService,
        private dialog: MatDialog,
        public CS: CustomerNameService,
        public CNS: CommissionNameService,
        commissionRes: CommissionResolver,
        commissionTypeRes: CommissionTypeResolver,
        protected customerRes: CustomerResolver,
        protected officeRes: OfficeResolver,
        protected cls: CustomLabelService,
        protected userToCommissionResolver: UserToCommissionResolver,
        private router: Router,
    ) {
        this.ContentResolved$ = combineLatest([
            this.store.select(getCommissionsFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        commissionRes.resolve();
                    }
                }),
            ),
            this.store.select(getCommissionTypesFetched).pipe(
                distinctUntilChanged(),
                tap((fetched) => {
                    if (!fetched) {
                        commissionTypeRes.resolve();
                    }
                }),
            ),
        ]).pipe(
            map((values) => values.every((v) => v)),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.SelectedCommissions$ = this.store.select(getCommissionSorted).pipe(map((commissions) => commissions.filter((c) => c.ParentId === this.Dialogdata.commissionId).map((c) => ({ label: c.DisplayName, commissionId: c.Id }))));
        this.TableData$ = combineLatest([
            this.store.select(getCommissionTypesFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCommissionTypes)),
            ),
            this.store.select(getCommissionsFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getCommissionSorted)),
                map((commissions) => commissions.filter((c) => (!c.ParentId || c.ParentId === this.Dialogdata.commissionId) && c.Id !== this.Dialogdata.commissionId && !c.Deleted)),
            ),
            this.store.select(getStatusFromBackofficeFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getStatusFromBackoffice)),
            ),
        ]).pipe(
            map(([types, commissions, statusBackoffices]) => {
                return commissions.map<ITableData>((c) => {
                    const type = c.CommissionTypeId && types.find((ct) => ct.Id === c.CommissionTypeId);
                    const statusBackoffice = c.StatusBackofficeId && statusBackoffices.find((s) => s.Id === c.StatusBackofficeId);
                    return {
                        cssStyle: `--app-clickable-background-color: ${c.GetBackgroundColor(statusBackoffice?.IsCompleted, statusBackoffice?.Color)};`,
                        cssClass: ['app-clickable-background', 'clickable-list-item'],
                        commissionId: c.Id,
                        commissionTypeId: c.CommissionTypeId,
                        selectedForm: new FormControl<boolean>(false),
                        auftragsnummer: {
                            __typename: 'string',
                            content: c.Auftragsnummer,
                        },
                        interneNummer: {
                            __typename: 'string',
                            content: c.InterneNummer,
                        },
                        description: {
                            __typename: 'string',
                            content: c.Description,
                        },
                        type: {
                            __typename: 'string',
                            content: type?.Name || '',
                        },
                    };
                });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.FilteredTableData$ = this.TableData$.pipe(
            switchMap((rows) =>
                this.FilterValues$.pipe(map((filter) => (filter[filterNames.commissionType]?.length ? rows.filter((row) => row.commissionTypeId && filter[filterNames.commissionType].includes(row.commissionTypeId.toString())) : rows))),
            ),
            switchMap((rows) => {
                return this.SearchString.pipe(
                    debounceTime(SearchQueriesDebounceTime),
                    map((searchString) => (!searchString && rows) || rows.filter((r) => [r.auftragsnummer.content, r.interneNummer.content, r.description.content].filter(isNotNullOrUndefined).some((s) => stringSearch(s, searchString)))),
                );
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }
    ngOnInit(): void {
        firstValueFrom(this.defaultFilter.GetFilterByApp$(FilterApps.SelectCommissionPopup)).then((filterValues) => {
            let temp = {
                [filterNames.commissionType]: isNotNullOrUndefined(filterValues[filterNames.commissionType]) ? filterValues[filterNames.commissionType] : [],
            };
            this.FilterValues$.next(temp);
        });

        this.subscriptions.push(
            this.FilterValues$.pipe(skip(2)).subscribe((val) => {
                if (Object.keys(val).length !== 0) {
                    this.defaultFilter.SetFilterByApp(FilterApps.SelectCommissionPopup, val);
                }
            }),
            this.TableData$.pipe(
                // tap(() => this.dirty = false),
                switchMap((td) => merge(...td.map((t) => t.selectedForm.valueChanges.pipe(map(() => t.selectedForm.dirty))))),
            ).subscribe((v) => {
                if (v) {
                    this.dirty = true;
                }
            }),
            this.TableData$.pipe(
                switchMap((data) =>
                    this.SelectedCommissions$.pipe(
                        tap((selectedCommissions) =>
                            data.forEach((d) => {
                                const isSelected = selectedCommissions.some((u) => u.commissionId === d.commissionId);

                                if (isSelected && (d.selectedForm.enabled || !d.selectedForm.value)) {
                                    d.selectedForm.setValue(true, { emitEvent: false });
                                    d.selectedForm.disable();
                                } else if (!isSelected && (d.selectedForm.disabled || d.selectedForm.value)) {
                                    d.selectedForm.setValue(false, { emitEvent: false });
                                    d.selectedForm.enable();
                                }
                            }),
                        ),
                    ),
                ),
            ).subscribe(),
        );
    }
    RemoveSelected(entity: SelectedCommissionsType) {
        this.LS.startLoading('remove-parent-commission');
        firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateMany, BaseActionTypes.ErrorAction))).then(() => {
            this.LS.endLoading('remove-parent-commission');
        });
        this.store.dispatch(
            CommissionActionTypes.ModifyCommission({
                Payload: {
                    id: entity.commissionId,
                    parentId: null,
                },
            }),
        );
    }
    Submit() {
        this.LS.startLoading('set-commissions-parent-id');
        firstValueFrom(combineLatest([this.TableData$, this.SelectedCommissions$])).then(([data, selected]) => {
            const toAdd = data.filter((d) => d.selectedForm.value && selected.every((s) => s.commissionId !== d.commissionId));
            if (!toAdd.length) {
                this.dirty = false;
                this.LS.endLoading('set-commissions-parent-id');
                return;
            }
            firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateMany, BaseActionTypes.ErrorAction), skip(toAdd.length - 1))).then(() => {
                this.dirty = false;
                this.LS.endLoading('set-commissions-parent-id');
            });
            toAdd.forEach(({ commissionId }) =>
                this.store.dispatch(
                    CommissionActionTypes.ModifyCommission({
                        Payload: {
                            id: commissionId,
                            parentId: this.Dialogdata.commissionId,
                        },
                    }),
                ),
            );
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    async newCommissionClick() {
        const customer = await firstValueFrom(this.customerFromCommission$);
        this.dialog
            .open<NewCommissionDialogComponent, NewCommissionDialogComponentDialogData, NewCommissionDialogComponentDialogReturnData>(NewCommissionDialogComponent, {
                data: {
                    CreateCommissionPayload: {
                        parentId: this.Dialogdata.commissionId,
                        customerId: customer?.Id,
                        auftragseingangDurchAuftraggeber: new Date().toDateString(),
                    },
                    PreventRouting: true,
                },
            })
            .afterClosed()
            .subscribe((ret) => {
                console.log('closed', ret)
                if (ret?.Commission?.Id) {
                    const userToCommissionFromCommission$ = this.store.select(getUserToCommissions).pipe(map((u2cs) => u2cs.filter((u2c) => u2c.CommissionId === ret.Commission.Id)))
                    const commissionEntityRoles$ = combineLatest([userToCommissionFromCommission$, this.store.select(getUser)]).pipe(
                        map(([u2cs, user]) => u2cs?.filter((u) => u.UserId === user.Id)),
                        switchMap((u2c) => this.store.select(getEntityRole).pipe(map((er) => er.filter((e) => u2c.some((u) => u.RoleId === e.Id))))),
                    );
                    firstValueFrom(
                        combineLatest([
                            // this.actions$.pipe(ofType(CommissionActionTypes.UpdateOne, BaseActionTypes.ErrorAction)),
                            this.store.select(getCommissionTypesFetched).pipe(
                                filter((v) => !!v),
                                switchMap(() => this.store.select(getCommissionTypes)),
                            ),
                            this.store.select(getCustomersFetched).pipe(
                                tap((fetched) => {
                                    if (!fetched) {
                                        this.customerRes.resolve();
                                    }
                                }),
                                filter((v) => !!v),
                                switchMap(() => this.store.select(getCustomers)),
                            ),
                            this.store.select(getOfficesFetched).pipe(
                                tap((fetched) => {
                                    if (!fetched) {
                                        this.officeRes.resolve();
                                    }
                                }),
                                filter((v) => !!v),
                                switchMap(() => this.store.select(getOffices)),
                            ),
                            this.store.select(getPartner),
                            this.CS.GetSingle$(),
                            this.userToCommissionResolver.resolve().pipe(
                                switchMap(() => commissionEntityRoles$)
                            )
                        ]),
                    ).then(([commissionTypes, customers, offices, partner, CustomerName, entityRoles]) => {
                        const form = createCommissionFormGroup();
                        const CommissionLocationForm = new FormControl<Address>({
                            Street: '',
                            PostalCode: '',
                            City: '',
                            Country: '',
                        });
                        const SearchLocationForm = new FormControl<SelectSearchData>(null);

                        const dialogBeforeClosed = new Subject<void>();

                        let dialogRef: MatDialogRef<CommissionWizardComponent>;
                        const subs = [...getCommissionFormSubscriptions(form, this.store), /*...getCommissionFormAddressSearchSubscriptions(form, this.store, CommissionLocationForm, SearchLocationForm)*/];
                        dialogBeforeClosed.subscribe(() => {
                            subs.forEach((s) => s.unsubscribe());
                        });
                        combineLatest([
                            this.store.select(getCommissionById({ id: ret.Commission.Id })),
                            form.controls.CommissionTypeId.valueChanges.pipe(
                                startWith(form.controls.CommissionTypeId.value),
                                switchMap((ct) => (ct?.Id ? this.store.select(getCommissionTypeById({ id: ct.Id })) : of(null))),
                            ),
                            this.cls.getSingle$('Commission'),
                            getCommissionLocationOptions(this.store),
                        ])
                            .pipe(takeUntil(dialogBeforeClosed))
                            .subscribe(([commission, currentCommissionTypeEntity, commissionLabel, commissionLocationOptions]) => {
                                const detailListTemplateEntries = {};

                                setCommissionDetailListTemplateEntries(
                                    commission,
                                    detailListTemplateEntries,
                                    [
                                        'AbgabeterminAG',
                                        'AbgabeterminQM',
                                        'LetzteFrist',
                                        'NachlassZulage',
                                        'VorarbeiterBonus',

                                        'Customer',
                                        'CommissionType',
                                        'Sache',
                                        'CommissonNumber',
                                        'CommissonNumberAuto',
                                        'InterneNummer',
                                        'InterneNummerAuto',
                                        'CustomerLocation',
                                        'Location',
                                        'Auftragseingang',
                                        'StartDate',
                                        'PlanedStartDate',
                                        'EndDate',
                                        'PlanedEndDate',
                                        'Schadensdatum',
                                        'Versicherungssumme',
                                        'Schadennummer',
                                        'Versicherungsnummer',
                                        'AutomaticAccountsReceivableLedger',
                                        'AutomaticAccountsReceivableLedgerByClockIns',
                                        'ProjectInfo',
                                        'OrderNoCustomer',
                                        'CompleteBusinessVolume',
                                        'CostsCompleteBusinessVolume',
                                        'PlanedWorkingHours',
                                    ],
                                    form,
                                    entityRoles,
                                    {
                                        CustomerName,
                                        CommissionName: this.CNS.getName,
                                        customers,
                                        offices,
                                        commissionType: null,
                                        commissionTypes,
                                        partner,
                                        currentCommissionTypeEntity,
                                        currentCommissionType: currentCommissionTypeEntity,
                                        SearchLocationForm,
                                        CommissionLocationForm,
                                        commissionLocationOptions,
                                    },
                                    commissionLabel,);
                                if (!dialogRef) {
                                    console.log('open wizard')
                                    dialogRef = this.dialog.open<CommissionWizardComponent, CommissionWizardComponentDialogData>(CommissionWizardComponent, {
                                        ...CommissionWizardComponent.DefaultConfig,
                                        data: {
                                            commissionId: ret.Commission.Id,
                                            detailListTemplateEntries,
                                            statusBackofficeForm: form.controls.StatusBackofficeId,
                                        },
                                    });
                                    dialogRef.beforeClosed().subscribe(() => dialogBeforeClosed.next())
                                    dialogRef.componentInstance.Save.pipe(takeUntil(dialogBeforeClosed), withLatestFrom(this.store.select(getCommissionById({ id: ret.Commission.Id })))).subscribe(([{ wizardPage, routerLink }, commission]) => {
                                        if (routerLink) {
                                            firstValueFrom(this.actions$.pipe(ofType(CommissionActionTypes.UpdateMany, BaseActionTypes.ErrorAction))).then(action => {
                                                if(action.type === CommissionActionTypes.UpdateMany.type) {
                                                    this.router.navigate(routerLink);
                                                }
                                            })
                                        }
                                        saveCommission(this.store, commission, wizardPage, form, CommissionLocationForm);
                                        if (wizardPage === null) {
                                            // wizard finished
                                            dialogRef.close();
                                        }
                                    });
                                }
                            });

                        this.store
                            .select(getCommissionById({ id: ret.Commission.Id }))
                            .pipe(takeUntil(dialogBeforeClosed))
                            .subscribe((commission) => {
                                resetCommissionFormGroup(commission, form);
                            });
                    });
                }

            });
    }
}
