import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined } from '../../../helper/helper';
import { EmployeeEntityFromBackend, EmployeeEntityGqlFields, EmployeeStatusEnum } from '../../entities/employee.entity';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { EmployeeActionTypes } from '../actions/employee.actions';
import { ResolverLoadUser } from '../actions/resolver.actions';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getEmployeeById } from '../selectors/employees.selectors';

enum ErrorCodes {
    Load = 'Mitarbeiter Abrufen fehlgeschlagen',
    Modify = 'Mitarbeiter Bearbeiten fehlgeschlagen',
    Remove = 'Mitarbeiter Löschen fehlgeschlagen',
    Add = 'Mitarbeiter Hinzufügen fehlgeschlagen',
    SetUser = 'Nutzer Zuweisen fehlgeschlagen',
}

@Injectable()
export class EmployeeEffects {

    AddEmployee$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeActionTypes.AddEmployeeRequest),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const status = Payload.employeeStatus != null ? Payload.employeeStatus : EmployeeStatusEnum.Aktiv;
            const queryString = `
                mutation {
                    createEmployee(
                        employeeStatus: ${EmployeeStatusEnum.Aktiv}
                        ${stringifyIfNotNullOrUndefined(Payload, 'lastname')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'firstname')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'phonenumber')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'mobilenumber')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'faxnumber')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'email')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'street')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'postalCode')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'city')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'country')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'longitude')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'latitude')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'employeeNo')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'partnerId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'annualVacationDays')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'remainingVacationDaysFromCurrentYear')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'remainingVacationDaysFromPastYears')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'minimumVoteCount')}
                        ${Payload.overtime ? `overtime: ${(Payload.overtime * (1000 * 60 * 60)).toFixed()}` : ''}
                        ${Payload.weeklyWorkingHours ? `weeklyWorkingHours: ${JSON.stringify(Payload.weeklyWorkingHours.map(ms => parseInt((ms * (1000 * 60 * 60)).toFixed())))}` : ''}
                        ${stringifyIfNotNullOrUndefined(Payload, 'salaryType')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'employmentType')}
                        ${stringifyIfNotNullOrUndefined(Payload, 'jobSpecificationId')}
                        ${Payload.salary ? `salary: ${JSON.stringify(Payload.salary * 100)}` : ''}
                    ${stringifyIfNotNullOrUndefined(Payload, 'department')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'iBAN')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bIC')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'endOfContract')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'durationTrial')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'birthdate')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'dateOfEmployment')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'cancellationPeriod')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'taxNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'healthInsurance')}
                ) {
                    ${EmployeeEntityGqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.createEmployee
                        ? EmployeeActionTypes.AddEmployee({
                              Payload: EmployeeEntityFromBackend(res.createEmployee),
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    ModifyEmployee$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeActionTypes.ModifyEmployeeRequest),
        withLatestFrom(this.store$),
        concatMap(([{ Payload }, store]) => {
            const queryString = `
            mutation {
                changeEmployee(
                    id: ${Payload.id}
                    ${stringifyIfNotNullOrUndefined(Payload, 'lastname')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'firstname')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'phonenumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'mobilenumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'faxnumber')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'email')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'street')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'postalCode')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'city')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'country')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'longitude')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'latitude')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'employeeNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'partnerId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'userId')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'annualVacationDays')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'remainingVacationDaysFromCurrentYear')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'remainingVacationDaysFromPastYears')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'minimumVoteCount')}
                    ${Payload.overtime != null ? `overtime: ${(Payload.overtime * (1000 * 60 * 60)).toFixed(0)}` : ''}
                    ${Payload.weeklyWorkingHours != null ? `weeklyWorkingHours: ${JSON.stringify(Payload.weeklyWorkingHours.map(ms => parseInt((ms * (1000 * 60 * 60)).toFixed(0))))}` : ''}
                    ${stringifyIfNotNullOrUndefined(Payload, 'salaryType')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'employmentType')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'jobSpecificationId')}
                    ${Payload.employeeStatus ? `employeeStatus: ${Payload.employeeStatus}` : ''}
                    ${Payload.salary ? `salary: ${JSON.stringify(Payload.salary * 100)}` : ''}
                    ${stringifyIfNotNullOrUndefined(Payload, 'department')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'iBAN')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'bIC')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'endOfContract')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'durationTrial')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'birthdate')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'dateOfEmployment')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'cancellationPeriod')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'taxNo')}
                    ${stringifyIfNotNullOrUndefined(Payload, 'healthInsurance')}
                ) {
                    ${EmployeeEntityGqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                concatMap(res => {
                    const ret: Action[] = [];
                    if (res && res.changeEmployee) {
                        ret.push(
                            EmployeeActionTypes.UpdateOne({
                                Payload: EmployeeEntityFromBackend(res.changeEmployee),
                            }),
                        );
                        if (res.changeEmployee.userId === store.users.user.Id) {
                            ret.push(new ResolverLoadUser({ id: store.users.user.Id }));
                        }
                    } else {
                        ret.push(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } }));
                    }
                    return ret;
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    GetEmployee$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeActionTypes.Load),
        withLatestFrom(this.store$.select(getToken)),
        concatMap(([{ Payload }, token]) => {
            const queryString = `
            query {
                employee${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    ${EmployeeEntityGqlFields}
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                tap(res => {
                    if (!(Payload?.updatedSince && !res.employee.length)) {
                        this.store$.dispatch(
                            res && res.employee
                                ? Payload?.updatedSince
                                    ? EmployeeActionTypes.UpdateMany({
                                          Payload: res.employee.map(val => EmployeeEntityFromBackend(val)),
                                          updateLatestUpdatedAt: true,
                                      })
                                    : EmployeeActionTypes.UpdateAll({
                                          Payload: res.employee.map(val => EmployeeEntityFromBackend(val)),
                                          updateLatestUpdatedAt: true,
                                      })
                                : BaseActionTypes.ErrorAction({
                                      Payload: { ToasterMessage: ErrorCodes.Load },
                                  }),
                        );
                    }
                }),
                catchError((err, caught) => {
                    this.store$.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    );
                    return EMPTY;
                }),
            );
        }),
    ), { dispatch: false });


    RemoveEmployee$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeActionTypes.DeleteEmployeeRequest),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `
                mutation{
                  deleteEmployee(
                  id: ${action.Payload.EmployeeId.id},
                  ${action.Payload.EmployeeId.forceDelete ? `forceDelete: ${action.Payload.EmployeeId.forceDelete}` : ''})
                }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                map(res => {
                    return res && res.deleteEmployee
                        ? EmployeeActionTypes.DeleteEmployee({
                              Payload: action.Payload.EmployeeId.id,
                          })
                        : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } });
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));


    SetUser$ = createEffect(() => this.actions$.pipe(
        ofType(EmployeeActionTypes.SetEmployeeUser),
        withLatestFrom(this.store$),
        concatMap(([action, store]) => {
            const queryString = `mutation{
                setUser2Employee(
                    userIds: ${JSON.stringify(action.Payload.UserIds)},
                    employeeId: ${action.Payload.EmployeeId}
                ){
                    userId
                }
            }`;

            return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                withLatestFrom(this.store$.select(getEmployeeById({id: action.Payload.EmployeeId}))),
                map(([res, employee]) => {
                    if (res && res.setUser2Employee) {
                        return EmployeeActionTypes.UpdateOne({
                            Payload: employee.Clone({ UserIds: res.setUser2Employee.map(u => u.userId) }),
                        });
                    } else {
                        return BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.SetUser } });
                    }
                }),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.SetUser,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
        }),
    ));

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService) {}
}
