import { CommonModule, getCurrencySymbol } from '@angular/common';
import { Component, DEFAULT_CURRENCY_CODE, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LedgerImportDocumentTypeNames, LedgerImportDocumentTypes, LedgerImportEntity } from '../../../dave-data-module/entities/ledger-import.entity';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes, LedgerImportActionTypes } from '../../../dave-data-module/State/actions/accounting.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { getLedgerImportById, getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, MathRound } from '../../../helper/helper';
import { LoadingService } from '../../../services/loading.service';
import { LedgerImportSelectTableComponent } from '../ledger-import-select-table/ledger-import-select-table.component';

export interface NewPartialInvoiceComponentDialogData {
    FilterLedgerImports?: {
        CommissionId?: number;
        CustomerId?: number;
    };
}
export type NewPartialInvoiceComponentDialogReturnData =
    | undefined
    | {
          LedgerImportCreated: number;
      };
@Component({
    selector: 'app-new-partial-invoice',
    standalone: true,
    imports: [CommonModule, MatDialogModule, LedgerImportSelectTableComponent, AppButtonModule, MatFormFieldModule, MatInputModule, FormsModule],
    templateUrl: './new-partial-invoice.component.html',
    styleUrls: ['./new-partial-invoice.component.scss'],
})
export class NewPartialInvoiceComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    };
    title = LedgerImportDocumentTypeNames.get(LedgerImportDocumentTypes.PartialInvoice) + ' erstellen';
    selectedLedgerImportId$ = new BehaviorSubject<number | null>(null);
    selectedLedgerImport$ = this.selectedLedgerImportId$.pipe(switchMap((id) => (id ? this.store.select(getLedgerImportById({ id })).pipe(shareReplay({ refCount: true, bufferSize: 1 })) : of(null))));
    currencyCode$ = this.selectedLedgerImport$.pipe(map((li) => getCurrencySymbol(li?.ConsolidatedCurrencyCode || this.defaultCurrencyCode, 'narrow')));
    amountForm = new FormGroup({
        decimal: new FormControl<number>(null),
        usePercent: new FormControl<boolean>(true),
        percent: new FormControl<number>(null),
    });
    selectableLedgerImports$: Observable<number[]>;
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) dialogData: NewPartialInvoiceComponentDialogData,
        private dialogRef: MatDialogRef<NewPartialInvoiceComponent, NewPartialInvoiceComponentDialogReturnData>,
        private store: Store<State>,
        protected ls: LoadingService,
        private actions$: Actions,
        @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string,
    ) {
        this.selectableLedgerImports$ = this.store.select(getLedgerImports).pipe(
            map((lis) => lis.filter((l) => l.DocumentType === LedgerImportDocumentTypes.Offer || l.DocumentType === LedgerImportDocumentTypes.OfferConfirmation)),
            map((lis) => {
                let ret = lis;
                if (dialogData?.FilterLedgerImports?.CommissionId) {
                    ret = ret.filter((li) => li.CommissionId === dialogData.FilterLedgerImports.CommissionId);
                }
                if (dialogData?.FilterLedgerImports?.CustomerId) {
                    ret = ret.filter((li) => li.CustomerId === dialogData.FilterLedgerImports.CustomerId);
                }
                return ret;
            }),
            map((lis) => lis.map((l) => l.Id)),
        );
    }
    setPercent(value: number) {
        if (this.selectedLedgerImportId$.value) {
            firstValueFrom(this.selectedLedgerImport$).then((li) => {
                this.amountForm.patchValue({
                    usePercent: true,
                    decimal: MathRound(((value || 0) / 100) * ((li.ConsolidatedNettoAmount || 0) / 100), true),
                    percent: value,
                });
            });
        } else {
            this.amountForm.patchValue({
                usePercent: true,
                percent: value,
            });
        }
    }
    setDecimal(value: number) {
        if (this.selectedLedgerImportId$.value) {
            firstValueFrom(this.selectedLedgerImport$).then((li) => {
                this.amountForm.patchValue({
                    usePercent: false,
                    percent: li.ConsolidatedNettoAmount ? MathRound(((value || 0) / (li.ConsolidatedNettoAmount / 100)) * 100, true) : 0,
                    decimal: value,
                });
            });
        } else {
            this.amountForm.patchValue({
                usePercent: false,
                decimal: value,
            });
        }
    }
    submit() {
        if (this.selectedLedgerImportId$.value) {
            this.ls.startLoading('app-new-partial-invoice-submit');
            firstValueFrom(this.actions$.pipe(ofType(LedgerImportActionTypes.CreatePartialInvoiceSuccess, LedgerImportActionTypes.CreatePartialInvoiceFailure))).then((action) => {
                if (action.type === LedgerImportActionTypes.CreatePartialInvoiceSuccess.type) {
                    firstValueFrom(this.actions$.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, BaseActionTypes.ErrorAction))).then((arlAction) => {
                        this.ls.endLoading('app-new-partial-invoice-submit');
                        if (arlAction.type === AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger.type) {
                            const { Id } = action.Payload as LedgerImportEntity;
                            this.dialogRef.close({
                                LedgerImportCreated: Id,
                            });
                        }
                    });
                } else {
                    this.ls.endLoading('app-new-partial-invoice-submit');
                }
            });

            this.store.dispatch(
                LedgerImportActionTypes.CreatePartialInvoiceRequest({
                    Payload: {
                        rootId: this.selectedLedgerImportId$.value,
                        amount: isNotNullOrUndefined(this.amountForm.value.decimal) && MathRound(this.amountForm.value.decimal * 100, true),
                        percentageAmountInformation: this.amountForm.value.percent,
                    },
                }),
            );
        }
    }

    selectedLedgerImportChange(id: number) {
        if (this.selectedLedgerImportId$.value !== id) {
            this.selectedLedgerImportId$.next(id);
            if (this.amountForm.value.usePercent) {
                if (isNotNullOrUndefined(this.amountForm.value.percent)) {
                    this.setPercent(this.amountForm.value.percent);
                }
            } else {
                if (isNotNullOrUndefined(this.amountForm.value.decimal)) {
                    this.setDecimal(this.amountForm.value.decimal);
                }
            }

        }
    }
}
