import { createAction, props } from '@ngrx/store';
import { ARLTemplateTypeEntity } from '../../entities/a-r-l-template-type.entity';
import { AccountsReceivableLedgerTemplateEntity } from '../../entities/accounts-receivable-ledger-template.entity';
import { AccountsReceivableLedgerEntity } from '../../entities/accounts-receivable-ledger.entity';
import { LedgerImportDocumentTypes, LedgerImportEntity } from '../../entities/ledger-import.entity';
import { QuantityTypeEntity } from '../../entities/quantity-type.entity';
import { SfdtTemplateEntity } from '../../entities/sfdt-template.entity';
import {
    DaveMutationChangeAccountsReceivableLedgerArgs,
    DaveMutationChangeAccountsReceivableLedgerTemplateArgs,
    DaveMutationChangeLedgerImportArgs,
    DaveMutationCreateAccountsReceivableLedgerArgs,
    DaveMutationCreateAccountsReceivableLedgerTemplateArgs,
    DaveMutationCreateArlTemplateTypeTypeArgs, DaveMutationCreateClosingInvoiceFromLedgerImportArgs,
    DaveMutationCreateLedgerImportArgs, DaveMutationCreatePartialInvoiceFromLedgerImportArgs,
    DaveMutationCreateXRechnungFromLedgerImportArgs,
    DaveMutationDeleteAccountsReceivableLedgerArgs,
    DaveMutationDeleteAccountsReceivableLedgerTemplateArgs,
    DaveMutationDeleteArlTemplateTypeTypeArgs,
    QueryLedgerImportArgs, QueryLedgerImportTemplateArgs,
} from '../../graphql-types';

export const AccountsReceivableLedgerTemplateActionTypes = {
    UpdateAccountsReceivableLedgerTemplate: createAction('data/accounts-receivable-ledger-template/update', props<{ Payload: Array<AccountsReceivableLedgerTemplateEntity> }>()),
    GetAccountsReceivableLedgerTemplate: createAction('data/accounts-receivable-ledger-template/get'),
    AddAccountsReceivableLedgerTemplate: createAction('data/accounts-receivable-ledger-template/add', props<{ Payload: DaveMutationCreateAccountsReceivableLedgerTemplateArgs }>()),
    ChangeAccountsReceivableLedgerTemplate: createAction(
        'data/accounts-receivable-ledger-template/change',
        props<{ Payload: DaveMutationChangeAccountsReceivableLedgerTemplateArgs }>(),
    ),
    DeleteAccountsReceivableLedgerTemplate: createAction(
        'data/accounts-receivable-ledger-template/delete',
        props<{ Payload: DaveMutationDeleteAccountsReceivableLedgerTemplateArgs }>(),
    ),
};

export const AccountsReceivableLedgerActionTypes = {
    UpdateSingleAccountsReceivableLedger: createAction('data/accounts-receivable-ledger/updateOne', props<{ Payload: AccountsReceivableLedgerEntity }>()),
    UpdateAccountsReceivableLedger: createAction('data/accounts-receivable-ledger/update', props<{ Payload: Array<AccountsReceivableLedgerEntity> }>()),
    GetAccountsReceivableLedger: createAction('data/accounts-receivable-ledger/get'),
    AddAccountsReceivableLedger: createAction('data/accounts-receivable-ledger/add', props<{ Payload: DaveMutationCreateAccountsReceivableLedgerArgs }>()),
    ChangeAccountsReceivableLedger: createAction('data/accounts-receivable-ledger/change', props<{ Payload: DaveMutationChangeAccountsReceivableLedgerArgs }>()),
    DeleteAccountsReceivableLedger: createAction('data/accounts-receivable-ledger/delete', props<{ Payload: DaveMutationDeleteAccountsReceivableLedgerArgs }>()),
};
const prefix = 'data/ledger-import/';
export type AddLedgerImportWithDefaultsPayload = {
    accountsReceivableLedgerIds: number[];
    customerId?: number;
    commissionId?: number;
    documentType: LedgerImportDocumentTypes;
    ledgerImportId?: number;
    partnerOfficeId?: number;
    eventId?: number;
};
export const LedgerImportActionTypes = {
    UpdateAll: createAction(prefix + 'updateAll', props<{ Payload: Array<LedgerImportEntity>; updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction(prefix + 'updateMany', props<{ Payload: Array<LedgerImportEntity>; updateLatestUpdatedAt?: boolean }>()),
    GetLedgerImports: createAction(prefix + 'get', props<{ Payload: QueryLedgerImportArgs }>()),

    AddLedgerImports: createAction(prefix + 'add', props<{ Payload: DaveMutationCreateLedgerImportArgs }>()),
    AddLedgerImportWithDefaults: createAction(
        prefix + 'add-with-defaults',
        props<{
            Payload: AddLedgerImportWithDefaultsPayload;
        }>(),
    ),
    ChangeLedgerImports: createAction(prefix + 'change', props<{ Payload: DaveMutationChangeLedgerImportArgs }>()),
    CreateXRechnungFromLedgerImport: createAction(prefix + 'createXRechnung', props<{ Payload: DaveMutationCreateXRechnungFromLedgerImportArgs }>()),
    CreateLedgerImportFromLedgerImport: createAction(
        prefix + 'duplicate',
        props<{ Payload: { rootId: number; customerId? : number ; commissionId?: number ; documentType?: LedgerImportDocumentTypes; isTemplate?: boolean; templateName?: string } }>(),
    ),
    DeleteLedgerImport: createAction(
        prefix + 'delete',
        props<{ Payload: { id: number } }>(), // todo use DaveMutationDeleteLedgerImport
    ),
    GetSFDTTemplate: createAction(prefix + 'get/sfdt-template', props<{ Payload?: QueryLedgerImportTemplateArgs, withData?: boolean }>()),
    UpdateSFDTTemplate: createAction(prefix + 'update/sfdt-template', props<{ Payload: SfdtTemplateEntity[] }>()),

    CreatePartialInvoiceRequest: createAction(prefix + 'CreatePartialInvoice/Request', props<{ Payload: DaveMutationCreatePartialInvoiceFromLedgerImportArgs }>()),
    CreatePartialInvoiceSuccess: createAction(prefix + 'CreatePartialInvoice/Success', props<{ Payload: LedgerImportEntity }>()),
    CreatePartialInvoiceFailure: createAction(prefix + 'CreatePartialInvoice/Failure', props<{ Payload: { Errors: any; ToasterMessage?: string } }>()),

    CreateClosingInvoiceRequest: createAction(prefix + 'CreateClosingInvoice/Request', props<{ Payload: DaveMutationCreateClosingInvoiceFromLedgerImportArgs }>()),
    CreateClosingInvoiceSuccess: createAction(prefix + 'CreateClosingInvoice/Success', props<{ Payload: LedgerImportEntity }>()),
    CreateClosingInvoiceFailure: createAction(prefix + 'CreateClosingInvoice/Failure', props<{ Payload: { Errors: any; ToasterMessage?: string } }>()),

};

export const QuantityTypeActions = {
    GetQuantityTypes: createAction('data/quantity-type/get'),
    UpdateQuantityTypes: createAction('data/quantity-type/update', props<{ Payload: Array<QuantityTypeEntity> }>()),
};
export const ARLTemplateTypeActions = {
    GetARLTemplateTypes: createAction('data/ARLTemplateType/get'),
    AddARLTemplateType: createAction('data/ARLTemplateType/add', props<{ Payload: DaveMutationCreateArlTemplateTypeTypeArgs }>()),
    DeleteARLTemplateType: createAction('data/ARLTemplateType/delete', props<{ Payload: DaveMutationDeleteArlTemplateTypeTypeArgs }>()),
    UpdateARLTemplateType: createAction('data/ARLTemplateType/update', props<{ Payload: Array<ARLTemplateTypeEntity> }>()),
};
