<app-detail-view-template [Resizable]="true">
    <ng-container *appDetailViewButtons>
        <div [matTooltipDisabled]="!saveBtn?.Disabled" matTooltip="Ressource wurde gelöscht" *ngIf="!Editing">
            <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Bearbeiten" #saveBtn [Inverted]="true" class="header-button" (click)="Editing = true" [Disabled]="!!(Material$ | async)?.DeletedAt">
                <fa-icon icon="pencil-alt"></fa-icon>
            </button>
        </div>
        <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Speichern" class="header-button" *ngIf="Editing" (click)="onSave()" Color="green" [Disabled]="!(materialData?.FormsValid$ | async)">
            <fa-icon icon="save"></fa-icon>
        </button>
        <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Zurücksetzen" [Inverted]="true" class="header-button" *ngIf="Editing" (click)="onUndo()">
            <fa-icon icon="undo"></fa-icon>
        </button>
        <button [IsLoading]="LS.IsLoading$ | async" app-round-button matTooltip="Löschen" class="header-button" *ngIf="Editing && (MaterialId$ | async)" (click)="onDelete()" Color="red">
            <fa-icon icon="trash"></fa-icon>
        </button>
    </ng-container>
    <ng-container *appDetailViewLeftContent>
        <mat-card>
            <app-material-main-data #materialData [ShowMaterialButton]="false" [FolderId]="FolderId$ | async" [(Editing)]="Editing" [MaterialId]="MaterialId$ | async"></app-material-main-data>
        </mat-card>
        <ng-container *ngIf="!(fileExplorerLoading | async); else loading">
            <app-file-explorer
                *ngIf="FolderId$ | async as id"
                [SelectedFolderId]="id"
                [FileUploadButton]="true"
            ></app-file-explorer>
        </ng-container>
    </ng-container>
    <ng-container *appDetailViewRightContent>
<!--        <app-dave-list-card-->
<!--            [Headline]="cls.getMultiple$('Supplier') | async"-->
<!--            EmptyText="Keine Zuordnungen vorhanden"-->
<!--            [Data]="MaterialToSupplierTableData$ | async"-->
<!--            [DisplayedCollums]="['SupplierName', 'Date', 'Cost', 'ArticleNumber']"-->
<!--            [TableHeaderCollumsMap]="{-->
<!--            SupplierName: cls.getSingle$('Supplier') | async,-->
<!--            Date: 'Aktualisiert am',-->
<!--            Cost: 'EK-Preis',-->
<!--            ArticleNumber: 'Artikelnummer'-->
<!--             }"-->
<!--            [Clickable]="true"-->
<!--            (EntryClicked$)="onAddSupplier($event.entry)"-->
<!--            style="max-height: 50vh"-->
<!--            [IsLoading]="MaterialToSupplierTableDataLoading$ | async"-->
<!--            >-->
<!--            <button app-round-button headerButton onlyExpanded (click)="onAddSupplier(null)" >-->
<!--                <fa-icon [icon]="'plus'"></fa-icon>-->
<!--            </button>-->
<!--        </app-dave-list-card>-->
<!--        <app-dave-list-card-->
<!--            [Headline]="cls.getMultiple$('Costs') | async"-->
<!--            EmptyText="Keine Preise vorhanden"-->
<!--            [Data]="MaterialPriceHistoryTableData$ | async"-->
<!--            [IsLoading]="MaterialPriceHistoryTableDataLoading$ | async"-->
<!--            [DisplayedCollums]="['Costs', 'CreatedAt', 'SupplierName', 'User', 'Icon']"-->
<!--            [TableHeaderCollumsMap]="{-->
<!--                Costs: cls.getSingle$('Costs') | async,-->
<!--                CreatedAt: 'erstellt am',-->
<!--                SupplierName: 'Lieferant',-->
<!--                User: 'Nutzer'-->
<!--              }"-->
<!--            [CustomCellTemplates]="{-->
<!--                Icon: iconcollum-->
<!--            }"-->
<!--            DefaultSortActive="CreatedAt"-->
<!--            DefaultSortDirection="desc"-->
<!--            style="max-height: 50vh"-->
<!--        >-->
<!--        </app-dave-list-card>-->
        <app-material-to-supplier-list-card style="display: block" [materialId]="MaterialId$ | async"></app-material-to-supplier-list-card>
        <app-material-price-history-list-card style="display: block" [materialId]="MaterialId$ | async"></app-material-price-history-list-card>
        <app-material-ledger-import-list-card [ledgerImportDocumentType]="LedgerImportDocumentTypes.Order" [materialId]="MaterialId$ | async"></app-material-ledger-import-list-card>
    </ng-container>
</app-detail-view-template>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>


