<mat-expansion-panel class="custom-mat-expansion-panel" [expanded]="expanded">
    <mat-expansion-panel-header [collapsedHeight]="HeaderHeight">
        <div class="card-header">
            <fa-icon class="icon" icon="users"></fa-icon>

            <h3>Bearbeiter ({{ (ShowSelectedUsers$ | async)?.length }})</h3>

            <!-- ToDo 'a' statt 'button', wenn event.stopImmediatePropagation() tut -->
            <!--                    (click)="OpenUserSelect()"-->
            <button app-round-button matTooltip="Filter" (click)="filter.Open(); $event.stopPropagation();" [matBadge]="FilterAmount$ | async">
                <fa-icon icon="filter"></fa-icon>
            </button>
            <button class="adjustLeft" app-round-button matTooltipPosition="left" (click)="OpenUserSelect(); $event.stopPropagation();" [Disabled]="editDisabled$ | async" matTooltip="Auswählen">
                <app-double-icon Icon="user-cog"></app-double-icon>
            </button>
        </div>
    </mat-expansion-panel-header>
    <mat-card class="card">
        <!-- "clickable"-Klasse ist nur für den inset box shadow -->
        <ngx-simplebar class="clickable-list-container">
            <table mat-table [dataSource]="ShowSelectedUsers$ | async">
                <ng-container matColumnDef="Name">
                    <td mat-cell *matCellDef="let rowData">
                        <label>
                            <fa-icon icon="envelope" *ngIf="rowData.Assignee"></fa-icon>
                            <fa-icon icon="check" *ngIf="!rowData.Assignee"></fa-icon>
                            {{ rowData.User.DisplayName || 'Name' }}&nbsp;:&nbsp;
                            <span>
                                <ng-container *ngFor="let role of rowData.roles; let first = first">
                                    <ng-container *ngIf="!first">&nbsp;|&nbsp;</ng-container>
                                    <i>{{ role.DisplayName }}</i>
                                </ng-container>
                            </span>
                        </label>
                    </td>
                </ng-container>
                <tr mat-row *matRowDef="let rowData; columns: ['Name']" [routerLink]="['/', 'personal_und_urlaub', rowData.User.Id]" routerLinkActive="active"></tr>
            </table>

            <table *ngIf="!((ShowSelectedUsers$ | async)?.length > 0)" style="width: 100%">
                <tr>
                    <td class="empty-table-text">Keine Mitarbeiter zugeordnet</td>
                </tr>
            </table>
        </ngx-simplebar>
    </mat-card>
</mat-expansion-panel>
<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
