import { BooleanInput,coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
AfterViewInit,
ChangeDetectionStrategy,
Component,EventEmitter,
Inject,
Input,
LOCALE_ID,
OnDestroy,
Output,
ViewChild
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginator,MatPaginatorModule } from '@angular/material/paginator';
import { MatSort,MatSortModule } from '@angular/material/sort';
import { MatTable,MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject,firstValueFrom } from 'rxjs';
import {
ClockInExportType
} from '../../../dave-commission-module/components/csv-export-dialog/csv-export-dialog.component';
import { AccountsReceivableLedgerTypeEnum } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { DaveMutationCreateAccountsReceivableLedgerArgs } from '../../../dave-data-module/graphql-types';
import { ArlDataService } from '../../../dave-data-module/services/arl-data.service';
import { State } from '../../../dave-data-module/State';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { LengthPipe } from '../../../helper/length.pipe';
import { BookingPositionMetaIcon } from '../../../helper/page-metadata';
import { LoadingService } from '../../../services/loading.service';
import { ArlFormDataService } from '../arl-calculation/arl-form-data.service';
import {
SimpleArlTableDataSource,
SimpleArlTableItem,
simpleArlTableItemAvailableSortKeys
} from './simple-arl-table-datasource';
import { $e } from 'codelyzer/angular/styles/chars';

@Component({
    selector: 'app-simple-arl-table',
    templateUrl: './simple-arl-table.component.html',
    styleUrls: ['./simple-arl-table.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MatPaginatorModule, MatTableModule, MatSortModule, MatInputModule, ReactiveFormsModule, LengthPipe, MatButtonModule, FontAwesomeModule, AppButtonModule, MatMenuModule, MatDatepickerModule],
})
export class SimpleArlTableComponent implements AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatTable) table!: MatTable<SimpleArlTableItem>;
    private arlIds$ = new BehaviorSubject<number[]>([]);
    @Input() set arlIds(arlIds: number[]) {
        console.log('set arlIds', arlIds.slice());
        this.arlIds$.next(arlIds.slice());
    }
    @Input() disabled = false;
    @Input() AddARLDefaultValues: DaveMutationCreateAccountsReceivableLedgerArgs;
    @Output() arlIdsChange = new EventEmitter<number[]>();
    @Output() FormValueChange = new EventEmitter<void>();
    static subHeaderPrefix = 'sub-header-';
    static subFooterPrefix = 'sub-footer-';
    dataSource: SimpleArlTableDataSource;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns: Array<simpleArlTableItemAvailableSortKeys | 'EditBtn'> = ['Information', 'InvoiceDate', 'AlreadyPayed', 'BaseCost', 'Tax', 'Amount', 'EditBtn'];
    get displayedColumnsCustomSubHeader(): Array<string> {
        return this.displayedColumns.map((c) => SimpleArlTableComponent.subHeaderPrefix + c);
    }
    get displayedColumnsCustomSubFooter(): Array<string> {
        return this.displayedColumns.map((c) => SimpleArlTableComponent.subFooterPrefix + c);
    }

    static ngAcceptInputType_showSubHeader: BooleanInput;
    @Input()
    get showSubHeader() {
        return this._showSubHeader$.value;
    }
    set showSubHeader(value: boolean) {
        this._showSubHeader$.next(coerceBooleanProperty(value));
    }
    protected _showSubHeader$ = new BehaviorSubject<boolean>(false);

    static ngAcceptInputType_showSubFooter: BooleanInput;
    @Input()
    get showSubFooter() {
        return this._showSubFooter$.value;
    }
    set showSubFooter(value: boolean) {
        this._showSubFooter$.next(coerceBooleanProperty(value));
    }
    protected _showSubFooter$ = new BehaviorSubject<boolean>(false);

    static ngAcceptInputType_showFooter: BooleanInput;
    @Input()
    get showFooter() {
        return this._showFooter$.value;
    }
    set showFooter(value: boolean) {
        this._showFooter$.next(coerceBooleanProperty(value));
    }
    protected _showFooter$ = new BehaviorSubject<boolean>(false);

    constructor(store: Store<State>, @Inject(LOCALE_ID) locale: string, arlFormDataService: ArlFormDataService, protected ls: LoadingService, private arlDataService: ArlDataService, private appDialog: AppDialogService) {
        this.arlIds$.subscribe((v) => console.warn('arlIds$ subs', v));
        this.dataSource = new SimpleArlTableDataSource(store, locale, arlFormDataService, this.arlIds$);
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
    }
    ngOnDestroy(): void {
        this.arlIds$.complete();
    }
    onAddArl() {
        this.ls.startLoading('app-simple-arl-table-create-arls');
        this.arlDataService
            .addArls([{ ...this.AddARLDefaultValues, type: AccountsReceivableLedgerTypeEnum.Arl }])
            .then((arls) => {
                console.log('arls', arls.slice());
                firstValueFrom(this.arlIds$).then((arlIds) => {
                    console.log('arlIds', arlIds.slice());
                    const newArlVal = [...arlIds, ...arls.map((a) => a.Id)];
                    console.log('newArlVal', newArlVal.slice());
                    this.arlIds$.next(newArlVal.slice());
                    this.arlIdsChange.emit(newArlVal.slice());
                });
            })
            .finally(() => this.ls.endLoading('app-simple-arl-table-create-arls'));
    }

    onRemoveArl(id: SimpleArlTableItem) {
        this.appDialog
            .OpenConfirmationDialog({
                heading: 'Buchungsposition löschen ?',
                paragraph: 'Möchten sie die Buchungsposition ' + id.form.getRawValue().Information + ' wirklich löschen ?',
                styleDelete: true,
            })
            .subscribe(([res]) => {
                if (res) {
                    this.ls.startLoading('app-simple-arl-table-remove-arl');
                    this.arlDataService
                        .removeArl(id.arlId)
                        .then(({ deletedId }) => {
                            firstValueFrom(this.arlIds$).then((arlIds) => {
                                console.log('arlIds', arlIds.slice());
                                const newArlVal = [...arlIds.filter((id) => id !== deletedId)];
                                console.log('newArlVal', newArlVal.slice());
                                this.arlIds$.next(newArlVal.slice());
                                this.arlIdsChange.emit(newArlVal.slice());
                            });
                        })
                        .finally(() => this.ls.endLoading('app-simple-arl-table-remove-arl'));
                }
            });
    }

    protected readonly BookingPositionMetaIcon = BookingPositionMetaIcon;
    protected readonly ClockInExportType = ClockInExportType;

    onTableRowClick(row, $event: MouseEvent) {
        // console.log($event)
        // this.dataSource.expandRowToggle(row)
        this.dataSource.expandRow(row);
    }
}
