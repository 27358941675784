import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { LedgerImportEntity } from '../../entities/ledger-import.entity';
import { getLatestUpdatedAt } from '../../helper/reducer.helper';
import { LedgerImportActionTypes } from '../actions/accounting.actions';

export interface LedgerImportState extends EntityState<LedgerImportEntity> {
    fetched: boolean;
    latestUpdatedAt: Date | null;
}

export const LEDGER_IMPORT_KEY = 'ledger-import';

const adapter = createEntityAdapter<LedgerImportEntity>({
    selectId: (Entity) => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
    latestUpdatedAt: null,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const ledgerImportReducer = createReducer(
    initialState,
    on(LedgerImportActionTypes.UpdateAll, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setAll(Payload, {
            ...state,
            fetched: true,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(LedgerImportActionTypes.UpdateMany, (state, { Payload, updateLatestUpdatedAt }) =>
        adapter.setMany(Payload, {
            ...state,
            latestUpdatedAt: updateLatestUpdatedAt ? getLatestUpdatedAt(Payload, state.latestUpdatedAt) : state.latestUpdatedAt,
        }),
    ),
    on(LedgerImportActionTypes.CreatePartialInvoiceSuccess, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
    on(LedgerImportActionTypes.CreateClosingInvoiceSuccess, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllLedgerImport = selectAll;
export const selectLedgerImportEntities = selectEntities;

export const selectLedgerImportFetched = (state: LedgerImportState) => state.fetched;
export const selectLedgerImportLatestUpdatedAt = (state: LedgerImportState) => state.latestUpdatedAt;
