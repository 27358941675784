import { CommonModule, formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { Store } from '@ngrx/store';

import {
    bufferCount,
    combineLatest,
    firstValueFrom,
    Observable,
    of,
    reduce,
    ReplaySubject,
    Subject,
    Subscription,
} from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { EventTypeNamesEnum } from '../dave-data-module/entities/event-type.entity';
import { EventEntity, EventStateEnumColorMap } from '../dave-data-module/entities/event.entity';
import { FileDataService } from '../dave-data-module/services/file-data.service';
import { State } from '../dave-data-module/State';
import { getEventTypes } from '../dave-data-module/State/selectors/event-type.selector';
import { getEvents, getEventsSortedByCreatedAt } from '../dave-data-module/State/selectors/events.selectors';
import { PdfEditorModule } from '../dave-dms-module/dave-dms/pdf-editor/pdf-editor.module';
// import { DaveEventListEntryModule } from "../dave-event-list-entry/dave-event-list-entry.module";
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { FileEntity, FileEntityDocumentTypes } from '../dave-data-module/entities/file.entity';
import { FolderTypes } from '../dave-data-module/entities/folder.entity';
import { FolderDataService } from '../dave-data-module/services/folder-data.service';
import { HttpService } from '../dave-data-module/services/http.service';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { CommissionActionTypes } from '../dave-data-module/State/actions/commission.actions';
import { EventsActionTypes } from '../dave-data-module/State/actions/events.actions';
import { FilesActionTypes } from '../dave-data-module/State/actions/files.actions';
import { getToken } from '../dave-data-module/State/selectors/base.selectors';
import { getCommissionById } from '../dave-data-module/State/selectors/commission.selector';
import { DaveEventListEntryModule } from '../dave-event-list-entry/dave-event-list-entry.module';
import { DaveLoadingPopupComponent, DaveLoadingPopupComponentDialogData } from '../dave-loading-popup/dave-loading-popup.component';
import { DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData } from '../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { AppButtonModule } from '../dave-utils-module/app-button-module/app-button.module';
import { DaveHeaderHeight, isNotNullOrUndefined, TableColumnConfig } from '../helper/helper';
import { LeafletWrapperComponent } from '../leaflet-wrapper/leaflet-wrapper.component';
import { PdfToolsService } from '../services/pdf-tools.service';
import { FileOrderDialogComponent, FileOrderDialogComponentDialogData, FileOrderDialogComponentDialogReturnData } from './components/file-order-dialog/file-order-dialog.component';
import {
    SelectFolderDialogComponent,
    SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData,
} from '../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';

type eventTableData = Partial<EventEntity> & { color: string; dateString: string };
export const LeafletMockPlanId = 625;
@Component({
    selector: 'app-blueprint',
    standalone: true,
    imports: [
        CommonModule,
        PdfEditorModule,
        MatCardModule,
        /*DaveEventListEntryModule,*/ MatTabsModule,
        MatListModule,
        DaveEventListEntryModule,
        LeafletWrapperComponent,
        AppButtonModule,
        FontAwesomeModule,
        MatMenuModule,
        MatSidenavModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        MatExpansionModule,
    ],
    templateUrl: './blueprint.component.html',
    styleUrls: ['./blueprint.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlueprintComponent implements OnInit, OnDestroy {
    // @ViewChild('appPdfEditor') pdfEditor: PdfEditorComponent;
    @Input() CommissionId: number;
    @Output() EventMarkerClicked = new EventEmitter<EventEntity>();
    @Output() EventListEntryClicked = new EventEmitter<eventTableData>();
    @ViewChild('fileUploadTemplate') fileUploadTemplate: TemplateRef<any>;
    @ViewChild('singleFileUpload') singleFileUpload: TemplateRef<any>;
    @ViewChild('drawer') drawer: MatDrawer;

    // @ViewChild('leafletWrapperComponent') leafletWrapperComponent: LeafletWrapperComponent;

    public plans$: Observable<Array<{ documentId: number; documentName: string; imageUrl: string }>>;
    protected planSelected$ = new ReplaySubject<{ documentId: number; documentName: string; imageUrl: string }>();
    protected events$: Observable<EventEntity[]> = combineLatest([this.store.select(getEventsSortedByCreatedAt), this.store.select(getEventTypes)]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([events, eventTypes]) => {
            const mangelTypes = eventTypes.filter((e) => [EventTypeNamesEnum.Erschwernis, EventTypeNamesEnum.Mangelanzeige, EventTypeNamesEnum.Bedenkenanzeige, EventTypeNamesEnum.Zusatzarbeit].includes(e.Name as EventTypeNamesEnum));
            return events.filter((e) => mangelTypes.some((mt) => mt.Id === e.EventTypeId) && e.CommissionId === this.CommissionId);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    protected ColumnConfig: TableColumnConfig<eventTableData>[] = [
        {
            header: 'Id',
            name: 'Id',
        },
        {
            header: 'Name',
            name: 'Name',
        },
        {
            header: 'Beschreibung',
            name: 'Description',
        },
        {
            header: 'Datum',
            name: 'dateString',
        },
        {
            header: 'Anhänge',
            name: 'FileCount',
        },
    ];
    protected displayedColumns = this.ColumnConfig.map((c) => c.name);
    protected eventTableDataSource = new MatTableDataSource<eventTableData>([]);
    private subscriptions: Subscription[] = [];
    constructor(
        private vRef: ViewContainerRef,
        protected fileDataService: FileDataService,
        protected store: Store<State>,
        protected api: HttpService,
        protected actions$: Actions,
        protected dialog: MatDialog,
        protected pdfToolsService: PdfToolsService,
        private folderDataService: FolderDataService,
    ) {}

    ngOnInit(): void {
        this.plans$ = this.store.select(getCommissionById({ id: this.CommissionId })).pipe(
            filter(isNotNullOrUndefined),
            map((commission) => commission.PlanIds),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            switchMap((ids) => (ids.length ? this.fileDataService.GetFilesById(ids).pipe(map((files) => ids.map((id) => files.find((f) => f?.Id === id)).filter(isNotNullOrUndefined))) : of([]))),
            withLatestFrom(this.store.select(getToken)),
            map(([files, token]) => files.map((f) => ({ documentId: f.Id, documentName: f.Name, imageUrl: this.api.GetUrl(f.GetLastVersion().GetDownloadLink(token), 'file') }))),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            tap((plans) => this.planSelected$.next(plans[0])),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.subscriptions.push(
            // this.MockPlans$.pipe(
            //     tap((p) => this.plans$.next(p)),
            //     switchMap((f) => this.events$),
            //     debounceTime(1000),
            // ).subscribe((events) => {
            //     events.forEach((e) => this.pdfEditor?.addEventAnnotation(e));
            // }),
            combineLatest([this.store.select(getEvents), this.plans$])
                .pipe(
                    map(([events, plans]) => events.filter((e) => e.AdditionalData?.mapMarker && plans.some((p) => !!e.AdditionalData.mapMarker[p.documentId]?.length))),
                    switchMap((events) => this.planSelected$.pipe(map((plan) => events.filter((e) => !!e.AdditionalData.mapMarker[plan.documentId]?.length)))),
                )
                .subscribe((events) => {
                    this.eventTableDataSource.data = events.map((e) => ({ ...e, color: EventStateEnumColorMap.get(e.State), dateString: formatDate(e.EventDate, 'dd.MM.yyyy', 'de-DE') }));
                    console.log(this.eventTableDataSource.data);
                }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    setEvents2(leafletWrapperComponent: LeafletWrapperComponent, planId: number) {
        this.events$.pipe(takeUntil(leafletWrapperComponent.OnDestroy$)).subscribe((events) => {
            leafletWrapperComponent.clearMarkers();
            events.forEach((e) =>
                e.AdditionalData?.mapMarker[planId]?.forEach((m) =>
                    leafletWrapperComponent.addMarker(
                        m.top,
                        m.left,
                        undefined,
                        e.State ? EventStateEnumColorMap.get(e.State) : undefined,
                        () => {
                            leafletWrapperComponent.closeFullscreen();
                            this.EventMarkerClicked.emit(e);
                        },
                        { content: e.Id + '', opacity: 1 },
                    ),
                ),
            );
        });
    }

    onFileUploadChange() {
        firstValueFrom(this.folderDataService.getFolderFromEntity(this.CommissionId, FolderTypes.commission)).then((folder)=> {
            this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData>(SelectFolderDialogComponent, {
                ...SelectFolderDialogComponent.DefaultConfig,
                data: {
                    ButtonText: 'HOCHLADEN',
                    matTreeNodePaddingIndent: 10,
                    uploadMode: true,
                    synchronUpload: true,
                    folderId: folder?.Id,
                    maxFilesUpload:1,
                },
            }).afterClosed().subscribe((res) => {
                if (res?.synchronUploadedDocuments?.length) {
                    this.fileHandler(res.synchronUploadedDocuments.map((f) => f.Id) , false);
                }
            });
        });


        // if ((event.target as HTMLInputElement).files && (event.target as HTMLInputElement).files.length) {
        //     firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.UploadFileBackendSuccess, FilesActionTypes.UploadFileBackendFailure), bufferCount((event.target as HTMLInputElement).files.length))).then((actions) => {
        //         const ids = actions.filter((a) => a.type === FilesActionTypes.UploadFileBackendSuccess.type).map(({ Payload }) => (Payload as FileEntity).Id);
        //         if (ids.length) {
        //             // firstValueFrom(this.store.select(getCommissionById({ id: this.CommissionId }))).then((c) => {
        //             //     const additionalData = JSON.parse(JSON.stringify(c.AdditionalData || {}));
        //             //     additionalData.bluePrintIds = [...(additionalData.bluePrintIds || []), ...ids];
        //             //     this.store.dispatch(CommissionActionTypes.ModifyCommission({ Payload: { id: this.CommissionId, additionalData: JSON.stringify(additionalData) } }));
        //             // });
        //             this.fileHandler(ids, !fileId, fileId);
        //         }
        //     });
        //     for (const file of (event.target as HTMLInputElement).files) {
        //         this.store.dispatch(
        //             FilesActionTypes.UploadFileBackendRequest({
        //                 Payload: {
        //                     file: file,
        //                     params: new Map([
        //                         ['auto_set_folder', 'true'],
        //                         ['commission_id', JSON.stringify([this.CommissionId])],
        //                         ['metadata', JSON.stringify({ document_type: FileEntityDocumentTypes.blueprint })],
        //                     ]),
        //                 },
        //             }),
        //         );
        //     }
        // }
    }
    onStackOrderButtonClick() {
        firstValueFrom(this.plans$).then((plans) => {
            if (plans.length) {
                const dialogRef = this.dialog.open<FileOrderDialogComponent, FileOrderDialogComponentDialogData, FileOrderDialogComponentDialogReturnData>(FileOrderDialogComponent, {
                    data: {
                        fileIds: plans.map((p) => p.documentId),
                        withVersionUploadButton: true,
                        withDelete: true,
                        deleteDialogData: {
                            paragraph: 'Wollen Sie den Plan wirklich aus der Liste entfernen ?',
                        },
                    },
                });
                dialogRef.componentInstance.versionUploadDms.pipe(takeUntil(dialogRef.afterClosed())).subscribe((fileId) => {
                    this.onDmsFileUploadClick(false, fileId);
                    dialogRef.close(null);
                });
                dialogRef.componentInstance.versionUpload.pipe(takeUntil(dialogRef.afterClosed())).subscribe((fileId) => {
                    this.onFileUploadClick(fileId);
                    dialogRef.close(null);
                });
                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        firstValueFrom(this.store.select(getCommissionById({ id: this.CommissionId }))).then((c) => {
                            if (JSON.stringify(c.PlanIds) !== JSON.stringify(result)) {
                                this.store.dispatch(CommissionActionTypes.ModifyCommission({ Payload: { id: this.CommissionId, planIds: result } }));
                                const removedFiles = plans.filter((p) => !result.includes(p.documentId));
                                if (removedFiles.length) {
                                    firstValueFrom(this.store.select(getEvents)).then((events) => {
                                        events
                                            .filter((e) => e.AdditionalData?.mapMarker && removedFiles.some((f) => e.AdditionalData.mapMarker[f.documentId]?.length))
                                            .forEach((e) => {
                                                const additionalData = JSON.parse(JSON.stringify(e.AdditionalData || {}));
                                                removedFiles.forEach((f) => {
                                                    delete additionalData.mapMarker[f.documentId];
                                                });
                                                this.store.dispatch(EventsActionTypes.ModifyEvent({ Payload: { id: e.Id, additionalData: JSON.stringify(additionalData) } }));
                                            });
                                    });
                                }
                            }
                        });
                    }
                });
            }
        });
    }

    onFileUploadClick(fileId: number) {
        if (fileId) {
            const viewRef = this.vRef.createEmbeddedView(this.singleFileUpload, { fileId });
            console.log(viewRef);
            viewRef.rootNodes[0].click();
        } else {
            alert('not implemented');
        }
    }
    onDmsFileUploadClick(multiplePages: boolean, fileId: number) {
        // if (this.fileDialog?.getState() === MatDialogState.OPEN) {
        //     this.fileDialog.close();
        // }
        firstValueFrom(this.folderDataService.getFolderFromEntity(this.CommissionId, FolderTypes.commission)).then((folder) => {
            /*this.fileDialog = */ this.dialog
                .open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                    ...DaveSelectFileFromDmsComponent.DefaultConfig,
                    data: {
                        moveWarning: '',
                        maxFiles: 1,
                        preSelectedFiles: [],
                        folderId: folder?.Id,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result?.documents?.length) {
                        this.fileHandler(result.documents, multiplePages, fileId);
                    }
                });
        });
    }
    fileHandler(fileIds: number[], multiplePagesAllowed: boolean, replaceFileId?: number) {
        firstValueFrom(combineLatest([this.store.select(getCommissionById({ id: this.CommissionId })), this.fileDataService.GetFilesById(fileIds)])).then(async ([c, files]) => {
            const fileIdsToAdd = [];
            for (const f of files) {
                if (f.MIMEType.startsWith('image/')) {
                    fileIdsToAdd.push(f.Id);
                } else if (f.MIMEType === 'application/pdf') {
                    const loadingDialog = this.dialog.open<DaveLoadingPopupComponent, DaveLoadingPopupComponentDialogData>(DaveLoadingPopupComponent, {
                        data: {
                            text: 'Ihre PDF wird konvertiert',
                            onReady$: new Subject<{ text: string; state: 'success' | 'fail' }>(),
                        },
                    });
                    await this.pdfToolsService.convertPDFToImage({ versionId: f.GetLastVersion().Id, hasOnlyOnePage: !multiplePagesAllowed }).then((res) => {
                        if (!res.hasOnlyOnePage && !multiplePagesAllowed) {
                            loadingDialog.componentInstance.Data.onReady$.next({ text: 'Es sind nur PDF Dateien mit einer Seite erlaubt', state: 'fail' });
                            if (replaceFileId) {
                                fileIdsToAdd.push(replaceFileId);
                            }
                        } else {
                            loadingDialog.close();
                            fileIdsToAdd.push(...res.documentIds);
                        }
                    });
                } else {
                    this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Nur PDF und Bilder erlaubt' } }));
                }
            }
            const replaceIndex = c.PlanIds.indexOf(replaceFileId);
            // const newPlanIds = replaceIndex > -1 ? c.PlanIds.slice().splice(replaceIndex, 1, ...fileIdsToAdd) : [...c.PlanIds, ...fileIdsToAdd];
            const newPlanIds = c.PlanIds.slice();
            if (replaceIndex > -1) {
                newPlanIds.splice(replaceIndex, 1, ...fileIdsToAdd);
            } else {
                newPlanIds.push(...fileIdsToAdd);
            }
            this.store.dispatch(
                CommissionActionTypes.ModifyCommission({
                    Payload: {
                        id: this.CommissionId,
                        planIds: newPlanIds,
                    },
                }),
            );
            if (replaceFileId) {
                if (fileIdsToAdd.length !== 1) {
                    throw new Error('replaceFileId only allowed for one file');
                }
                firstValueFrom(this.store.select(getEvents)).then((events) => {
                    events
                        .filter((e) => e.AdditionalData?.mapMarker && e.AdditionalData.mapMarker[replaceFileId]?.length)
                        .forEach((e) => {
                            const additionalData = JSON.parse(JSON.stringify(e.AdditionalData || {}));
                            const marker = additionalData.mapMarker[replaceFileId].slice();
                            delete additionalData.mapMarker[replaceFileId];
                            additionalData.mapMarker[fileIdsToAdd[0]] = marker;
                            this.store.dispatch(EventsActionTypes.ModifyEvent({ Payload: { id: e.Id, additionalData: JSON.stringify(additionalData) } }));
                        });
                });
            }
        });
    }

    onTableRowClick(row: eventTableData) {
        this.EventListEntryClicked.emit(row);
    }

    protected readonly DaveHeaderHeight = DaveHeaderHeight;
}
