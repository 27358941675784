import { CommonModule } from '@angular/common';
import { Component,EventEmitter,Input,OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { PseudoStepHeaderComponent } from '../pseudo-step/pseudo-step-header.component';
import { StepState } from '@angular/cdk/stepper';

interface step {
    label: string;
    state: StepState;
    disabled: boolean;
}

@Component({
    selector: 'app-pseudo-stepper',
    standalone: true,
    imports: [CommonModule, PseudoStepHeaderComponent, MatDividerModule],
    templateUrl: './pseudo-stepper.component.html',
    styleUrls: ['./pseudo-stepper.component.scss'],
})
export class PseudoStepperComponent {
    @Input() steps: step[];
    protected _activeStep: number = -1;
    @Input() set activeStep(index: number) {
        this._activeStep = index;
    }
    get activeStep() {
        return this._activeStep;
    }
    @Input() activeStepChange = new EventEmitter<number>();

    constructor() {}

    onStepClick(index) {
        this._activeStep = index;
        this.activeStepChange.emit(this._activeStep);
    }
}
