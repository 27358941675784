import { DataSource } from '@angular/cdk/collections';
import { formatCurrency, formatDate, formatNumber, getCurrencySymbol } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getAccountsReceivableLedgerDictionary, getAccountsReceivableLedgers, getAccountsReceivableLedgersFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getCustomerDictionary, getCustomersFetched } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getLedgerImportDictionary, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { _getArlChildren } from '../../../dave-reports/components/arl-calculation/arl-calculation.helper';
import { FilterTypes } from '../../../services/default-filter.service';
import {
    IFilterTypeSearchMultiSelectValue
} from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { LedgerImportDocumentTypes } from '../../../dave-data-module/entities/ledger-import.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { compareArrays, uniqArray } from '../../../helper/helper';

export interface MaterialLedgerImportListCardItemFilter {
    [FilterTypes.Customers]: IFilterTypeSearchMultiSelectValue<number>[];
}
export interface MaterialLedgerImportListCardItem {
    costs: string;
    supplier: string;
    name: string;
    date: string;
    quantity: string;
    costs_sort: number;
    date_sort: number;
    quantity_sort: number;
    supplier_id: number;
}

/**
 * Data source for the MaterialLedgerImportListCard view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class MaterialLedgerImportListCardDataSource extends DataSource<MaterialLedgerImportListCardItem> {
    data$: Observable<MaterialLedgerImportListCardItem[]>;
    sort: MatSort | undefined;
    filter$: BehaviorSubject<MaterialLedgerImportListCardItemFilter>;
    availableCustomersIds$: Observable<number[]>;

    constructor(materialId: number, ledgerImportDocumentType: LedgerImportDocumentTypes, store: Store<State>, locale: string) {
        super();
        this.data$ = combineLatest([
            getFetched$(store, getLedgerImportsFetched, getLedgerImportDictionary),
            getFetched$(store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgers).pipe(map((arls) => arls.filter((a) => a.MaterialId === materialId &&/* a.CustomerId === supplierId &&*/ a.LedgerImportId))),
            getFetched$(store, getCustomersFetched, getCustomerDictionary),
        ]).pipe(
            withLatestFrom(store.select(getAccountsReceivableLedgerDictionary)),
            map(([[ledgerImports, filteredArls, customers], allArls]) => {
                return filteredArls
                    .filter((arl) => {
                        const li = ledgerImports[arl.LedgerImportId];
                        if (!li || ledgerImportDocumentType !== li.DocumentType) {
                            return false;
                        }
                        if (li.AccountsReceivableLedgerIds.includes(arl.Id)) {
                            return true;
                        }
                        return li.AccountsReceivableLedgerIds.some((arlId) => _getArlChildren(arlId, allArls).some((a) => a.Id === arl.Id));
                    })
                    .map<MaterialLedgerImportListCardItem>((arl) => {
                        const li = ledgerImports[arl.LedgerImportId];
                        return {
                            supplier: li.CustomerId ? customers[li.CustomerId]?.Name || '' : '',
                            name: li.ConsolidatedInvoiceId,
                            costs: formatCurrency(arl.BaseCost, locale, getCurrencySymbol(arl.CurrencyCode, 'narrow', locale), arl.CurrencyCode),
                            date: formatDate(li.ConsolidatedDate, 'shortDate', locale),
                            quantity: formatNumber(arl.Quantity, locale),
                            costs_sort: arl.BaseCost,
                            date_sort: li.ConsolidatedDate?.getTime(),
                            quantity_sort: arl.Quantity,
                            supplier_id: li.CustomerId,
                        };
                    });
            }),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.availableCustomersIds$ = this.data$.pipe(
            map(data => uniqArray(data.map(d => d.supplier_id)).sort((a,b) => a - b)),
            distinctUntilChanged((a, b) => compareArrays(a,b)),
        )
    }

    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    connect(): Observable<MaterialLedgerImportListCardItem[]> {
        if (this.filter$) {

            // Combine everything that affects the rendered data into one update
            // stream for the data-table to consume.
            return merge(this.data$, this.filter$).pipe(
                withLatestFrom(this.data$),
                map(([_, data]) => {
                    return this.getFilteredData([...data]);
                }),
            );
        } else {
            throw Error('Please set the filter$ on the data source before connecting.');
        }
    }

    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    disconnect(): void {}

    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getSortedData(data: MaterialLedgerImportListCardItem[]): MaterialLedgerImportListCardItem[] {
        if (!this.sort || !this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort?.direction === 'asc';
            switch (this.sort?.active) {
                case 'supplier':
                    return compare(a.supplier, b.supplier, isAsc);
                case 'name':
                    return compare(a.name, b.name, isAsc);
                case 'costs':
                    return compare(a.costs_sort, b.costs_sort, isAsc);
                case 'date':
                    return compare(a.date_sort, b.date_sort, isAsc);
                case 'quantity':
                    return compare(a.quantity_sort, b.quantity_sort, isAsc);
                default:
                    return 0;
            }
        });
    }
    private getFilteredData(data: MaterialLedgerImportListCardItem[]): MaterialLedgerImportListCardItem[] {
        if (!this.filter$ || !this.filter$.value) {
            return data;
        }

        return this.filter$.value[FilterTypes.Customers].length ? data.filter(d => this.filter$.value[FilterTypes.Customers].some(f => f.id === d.supplier_id)) : data;
    }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
