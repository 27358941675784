<h1 mat-dialog-title *ngIf="this.Dialogdata">{{ (GeneratedDoc$ | async)?.Name || '' }}</h1>
<mat-dialog-content>
    <ng-container *ngIf="GeneratedDoc$ | async; else loading">
        <ng-container *ngIf="EditFormFields; else editorTemplate">
            <mat-tab-group *ngIf="BS.MobileQuery | async" [preserveContent]="false" mat-align-tabs="center" (selectedTabChange)="selectedTabChange($event)">
                <mat-tab label="Schreiben" style="padding: 0.5rem">
                    <ng-container [ngTemplateOutlet]="formsTemplate" [ngTemplateOutletContext]="{smallHeight: true}"></ng-container>
                </mat-tab>
                <mat-tab label="Bericht" style="display: flex" >
                    <ng-container [ngTemplateOutlet]="editorTemplate"></ng-container>
                </mat-tab>
            </mat-tab-group>
            <ng-container *ngIf="!(BS.MobileQuery | async)">
                <div class="flex-wrapper">
                    <ng-container [ngTemplateOutlet]="formsTemplate"></ng-container>
                    <mat-divider vertical></mat-divider>
                    <ng-container [ngTemplateOutlet]="editorTemplate"></ng-container>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</mat-dialog-content>

<mat-dialog-actions *ngIf="this.Dialogdata">
    <button app-button Color="cancel" Inverted (click)="CloseDialog()">abbrechen</button>
    <!--    <button app-button (click)="editFormControlClick()">formcontrolmode</button>-->
    <button app-button Color="green" (click)="SaveClick()" [Disabled]="!(EditorContentDirty$ | async) && !GeneratedDocumentForm.dirty || GeneratedDocumentForm.invalid">speichern</button>
</mat-dialog-actions>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>

<ng-template #formsTemplate let-smallHeight="smallHeight">
    <div class="forms-wrapper" [class.scrollbar]="smallHeight" [class.p05]="smallHeight">
        <div class="routing-buttons" *ngIf="GeneratedDoc$ | async as doc">
            <h1 class="mat-title" *ngIf="!this.Dialogdata">{{ doc?.Name || '' }}</h1>
            <app-go-to-button (click)="CloseDialog()" *ngIf="doc.CommissionId" EntityType="commission" [EntityId]="doc.CommissionId"></app-go-to-button>
            <app-go-to-button (click)="CloseDialog()" *ngIf="doc.CustomerId" EntityType="customer" [EntityId]="doc.CustomerId"></app-go-to-button>
            <app-go-to-button (click)="CloseDialog()" *ngIf="Dialogdata" EntityType="generatedDocument" [EntityId]="doc.Id"></app-go-to-button>
            <!--        todo hier popup öffnen-->
            <button app-round-button *ngIf="doc.DocumentIdFromPdf" (click)="OpenFilePreviewClick(doc.DocumentIdFromPdf)"><fa-icon [icon]="DMSPageMeta.Icon"></fa-icon></button>
<!--            <app-go-to-button (click)="CloseDialog()" *ngIf="doc.DocumentIdFromPdf" EntityType="file" [EntityId]="doc.DocumentIdFromPdf"></app-go-to-button>-->
            <ng-container *ngIf="BS.MobileQuery | async" [ngTemplateOutlet]="stateTemplate"></ng-container>
        </div>
        <ng-container *ngIf="ShowGeneratedDocumentData">
            <div class="flex-row">
                <mat-expansion-panel *ngFor="let listData of GeneratedDocumentData$ | async">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{ listData.headline }}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-detail-list-template [Inline]="true" [Editing]="true" [Data]="listData"></app-detail-list-template>
                </mat-expansion-panel>
            </div>
            <mat-divider></mat-divider>
        </ng-container>
        <p *ngIf="FormsInitialised && CustomFields?.length === 0">Keine Felder zum ausfüllen vorhanden</p>
        <app-custom-form-fields-modal [class.scrollbar]="!smallHeight" [CustomFields]="CustomFields" [CustomFieldsModalTemplates]="(GeneratedDoc$ | async)?.AdditionalData?.CustomFieldsModalTemplates || []" DefaultAlignment="row"></app-custom-form-fields-modal>
    </div>
</ng-template>
<ng-template #editorTemplate>
    <div class="editor-wrapper">
        <app-burger-button-wrapper #burgerButtonWrapperComponent class="editor-buttons">
            <ng-container [ngTemplateOutlet]="stateTemplate"></ng-container>
<!--            <button app-round-button><fa-icon icon="info"></fa-icon></button>-->
            <button app-round-button aria-label="Unterschreiben" matTooltip="Unterschreiben" [IsLoading]="LoadingService.IsLoading$ | async" (click)="SignatureClick()"><fa-icon icon="signature"></fa-icon></button>
            <button app-round-button aria-label="Hochladen" matTooltip="Hochladen" *ngIf="!editorComponent?.DocumentEditorContainer?.documentEditor?.editor.documentHelper.isDocumentProtected" (click)="FileUploadClick()"><fa-icon icon="upload"></fa-icon></button>
            <button app-round-button aria-label="Herunterladen" matTooltip="Herunterladen" [IsLoading]="LoadingService.IsLoading$ | async" [mat-menu-trigger-for]="downloadMenu"><fa-icon icon="download"></fa-icon></button>
            <button app-round-button aria-label="Schreibschutz" matTooltip="Schreibschutz" (click)="RestrictEditingClick()" [Disabled]="(PreventEditing$ | async) || (BS.TouchQuery | async)"><fa-icon [icon]="editorComponent?.DocumentEditorContainer?.documentEditor?.editor.documentHelper.isDocumentProtected ? 'lock-open' : 'lock'"></fa-icon></button>
            <button app-round-button aria-label="Email senden" matTooltip="Email senden" [IsLoading]="LoadingService.IsLoading$ | async" (click)="SendEmailClick()"><fa-icon icon="paper-plane"></fa-icon></button>
            <button app-round-button aria-label="Speichern" matTooltip="Speichern" [IsLoading]="LoadingService.IsLoading$ | async" (click)="SaveClick()" *ngIf="!Dialogdata" [Disabled]="!(EditorContentDirty$ | async) && !GeneratedDocumentForm.dirty || GeneratedDocumentForm.invalid" Color="green"><fa-icon icon="save"></fa-icon></button>
            <button app-round-button aria-label="Löschen" matTooltip="Löschen" [IsLoading]="LoadingService.IsLoading$ | async" (click)="DeleteClick()" Color="red"><fa-icon icon="trash"></fa-icon></button>
        </app-burger-button-wrapper>
        <app-document-editor
            *ngIf="GeneratedDoc$ | async as doc"
            (ContentChange)="EditorContentChange($event)"
            [RestrictEditing]="false"
            [EnableToolbar]="false"
            [Filename]="doc.Name"
            [ToolbarItems]="EditorToolbarItems"
            [EnableAutoFocus]="false"
            #editor
            (OnCreated)="EditorCreated$.next(true)"
        ></app-document-editor>
    </div>
</ng-template>
<mat-menu #downloadMenu="matMenu">
    <a *ngIf="PdfFilePath$ | async as url" mat-menu-item matTooltip="pdf Datei herunterladen" [href]="url" [download]="(PdfFile$ | async)?.Name"><fa-icon icon="file-pdf"></fa-icon> pdf</a>
    <button *ngIf="!(PdfFilePath$ | async)" mat-menu-item matTooltip="pdf herunterladen" (click)="DownloadPdfClick()"><fa-icon icon="file-pdf"></fa-icon> pdf</button>
    <button mat-menu-item matTooltip="Word Datei herunterladen" (click)="DownloadDocxClick()"><fa-icon icon="file-word"></fa-icon> docx</button>
</mat-menu>
<ng-template #stateTemplate>
    <div class="state-wrapper">
        <fa-icon icon="info" size="lg"></fa-icon>
        <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select [formControl]="GeneratedDocumentForm.controls.State">
                <mat-option *ngFor="let s of States" [value]="s.value">
                    {{s.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-template>
