<header>
    <h1 mat-dialog-title>{{cls.getMultiple$('Customer') | async}} zuweisen</h1>
    <span class="spacer"></span>
    <button app-round-button (click)="newCustomerClick()" matTooltip="{{cls.getSingle$('Customer') | async}} anlegen" [IsLoading]="LS.IsLoading$ | async"><app-double-icon [Icon]="CustomerAdministrationMeta.Icon" Icon2="plus"></app-double-icon></button>
</header>
<app-select-customer #selectCustomer [selectedCustomers]="SelectedCustomers$ | async" (deleteClick)="RemoveSelected($event)" (entryClick)="closeDialog(); RouteToCustomer($event)"></app-select-customer>
<mat-dialog-actions>
    <button app-button mat-dialog-close="" Inverted Color="cancel" [IsLoading]="LS.IsLoading$ | async">Abbrechen</button>
    <button app-button (click)="Submit()" [Disabled]="!selectCustomer.dirty" [IsLoading]="LS.IsLoading$ | async" Color="green">Speichern</button>
</mat-dialog-actions>
