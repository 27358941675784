<!--<button mat-button (click)="expanded = !expanded">Kalkulation {{expanded ? '-' : '+'}}</button>-->
<ng-container *ngIf="expanded">
    <mat-slide-toggle *ngIf="inheritFromChildrenForm" [formControl]="inheritFromChildrenForm">Preis kalkulieren</mat-slide-toggle>
    <h2 class="left-space table-headline">Kosten</h2>
    <div class="arl-container scroll-container">
        <div class="item"></div>
        <div class="item"><h3>Art</h3></div>
        <div class="item"><h3>Menge</h3></div>
        <div class="item"><h3>Einheit</h3></div>
        <div class="item"><h3>Bezeichnung</h3></div>
        <div class="item"><h3>EK</h3></div>
        <div class="item"><h3>EK gesamt</h3></div>
        <div class="item"></div>
        <ng-container *ngFor="let arl of arlData$ | async; let i = index">
            <div class="item">
                <h3>{{ positionPrefix }}{{ i + 1 }}</h3>
            </div>
            <div class="item flex">
                <mat-form-field floatLabel="never">
                    <mat-select [formControl]="arl.form.controls.Type" (selectionChange)="onTypeSelectionChange($event,arl)" placeholder="Art">
                        <mat-option *ngFor="let t of selectableArlTypes" [value]="t.value">{{t.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="theme-override {{ResourcePageMeta.Theme}}" *ngIf="false && arl.form.value.ResourceId">
                    <button mat-icon-button color="primary" (click)="onSetEntityId(arl, null)" [matTooltip]="(arl.resource$ | async)?.DisplayName + ' entfernen'"><app-double-icon [Icon]="ResourcePageMeta.Icon" Icon2="link"></app-double-icon></button>
                </div>
            </div>
            <div class="item">
                <mat-form-field floatLabel="never"><input [formControl]="arl.form.controls.Quantity" type="number" matInput placeholder="Menge" /></mat-form-field>
            </div>
            <div class="item">
                <mat-form-field floatLabel="never">
                    <mat-select [formControl]="arl.form.controls.QuantityTypeId" placeholder="Einheit">
                        <mat-option *ngFor="let t of arlCalculationDataService.quantityTypes$ | async" [value]="t.Id">{{t.Name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="item">
                <app-arl-information-input
                    floatLabel="never"
                    [entityId]="
                        arl.form.controls.Type.value === ARLTypeEnum.ResourceArl
                            ? arl.form.value.ResourceId
                            : arl.form.value.Type === ARLTypeEnum.MaterialArl
                            ? arl.form.value.MaterialId
                            : arl.form.value.Type === ARLTypeEnum.EmployeeArl
                            ? arl.form.value.JobSpecificationId
                            : null"
                    [inputMode]="arl.form.value.Type === ARLTypeEnum.ResourceArl || arl.form.value.Type === ARLTypeEnum.MaterialArl || arl.form.value.Type === ARLTypeEnum.EmployeeArl ? 'selectSearch' : 'autocomplete'"
                    [informationControl]="arl.form.controls.Information"
                    [ARLType]="arl.form.value.Type"
                    (entitySelected)="onSetEntityId(arl, $event)"
                ></app-arl-information-input>
            </div>
            <div class="item">
                <mat-form-field floatLabel="never"><app-number-transform-input [factor]="1" [formControl]="arl.form.controls.CostAmount" placeholder="EK"></app-number-transform-input></mat-form-field>
            </div>
            <div class="item">
                <!--                <mat-form-field floatLabel="never"><app-number-transform-input [factor]="1" [formControl]="arl.form.controls.Amount" placeholder="EK gesamt"></app-number-transform-input></mat-form-field>-->
                <!--                <mat-form-field floatLabel="never"><input [formControl]="arl.form.controls.Amount" matInput placeholder="EK gesamt" type="number"/></mat-form-field>-->
                {{ arl.form.value.costAmountWithoutTax | currency: (currencyCode$ | async) }}
            </div>
            <div class="item">
                <button mat-icon-button (click)="removeArl(arl)"><fa-icon icon="times"></fa-icon></button>
            </div>
        </ng-container>
        <div class="item left-bottom">
            <button mat-button (click)="addArl()">+ Kosten hinzufügen</button>
        </div>
        <div class="item right-bottom">
            <strong class="underline">{{amountSum$ | async | currency: (currencyCode$ | async) }}</strong>
        </div>
    </div>
    <h2 class="left-space table-headline">Preis</h2>
    <div class="cost-container left-space">
        <div class="item"><h3>Art</h3></div>
        <div class="item"></div>
        <div class="item"><h3>Summe</h3></div>
        <div class="item"></div>
        <div class="item"><h3>Zuschläge</h3></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item"><h3>VK gesamt</h3></div>

        <ng-container *ngFor="let z of zuschlaege">
            <div class="item"><h4>{{z.name}}</h4></div>
            <div class="item"><h3>=</h3></div>
            <div class="item just-right">{{ z.amountSum$ | async | number: '1.2-2' }}</div>
            <div class="item"><h3>+</h3></div>
            <div class="item">
                <mat-form-field floatLabel="never" [appearance]="!z.form.value.usePercent ? 'outline' : undefined"><input type="number" [ngModel]="z.form.value.decimal" (ngModelChange)="z.settDecimal($event)" matInput placeholder="Zuschläge" /></mat-form-field>
            </div>
            <div class="item">
                <mat-form-field floatLabel="never" [appearance]="z.form.value.usePercent ? 'outline' : undefined"><input type="number" [ngModel]="z.form.value.percent" (ngModelChange)="z.setPercent($event)" matInput placeholder="in %" /></mat-form-field>
            </div>
            <div class="item"><h3>%</h3></div>
            <div class="item"><h3>=</h3></div>
            <div class="item just-right">{{ z.amountSumWithZuschl$ | async | currency }}</div>
        </ng-container>

        <div class="item"><h4>Summe</h4></div>
        <div class="item"><h3>=</h3></div>
        <div class="item just-right"><strong>{{ amountSum$ | async | number: '1.2-2' }}</strong></div>
        <div class="item"></div>
        <div class="item">
        </div>
        <div class="item">
        </div>
        <div class="item"></div>
        <div class="item"></div>
        <div class="item just-right"><strong>{{ amountSumWithZuschl$ | async | currency }}</strong></div>
    </div>
</ng-container>
