<button app-round-button mat-dialog-close class="back-button">
    <fa-icon icon="chevron-right"></fa-icon>
</button>
<app-preview
    *ngIf="FileId$"
    [FileId$]="FileId$"
    [EmailEditorDialogData]="DialogData.emailEditorDialogData"
    [fileExplorerContext]="DialogData.fileExplorerContext"
    [addBusinessVolumeDefaultValues]="DialogData.addBusinessVolumeDefaultValues"
    (ClosePreview)="CloseDialog()"
    [setCheckedButton]="this.DialogData.setCheckedButton"
>
</app-preview>
