<mat-drawer-container>
    <mat-drawer #drawer position="end">
        <app-table-config-form [columns]="TableColumnsSorted$ | async" [selectedColumns]="selectedColumns$ | async" (selectedColumnsChange)="selectedColumnsChange($event)"></app-table-config-form>
    </mat-drawer>
    <app-dave-list
        [ColumnConfig]="TableColumns$ | async"
        [Columns]="displayedColumns$ | async"
        (ColumnsChange)="selectedColumnsChange($event)"
        [ColumnsSmall]="displayedColumns$ | async"
        [DataSource$]="DataSource$"
        [Headline]="cls.getMultiple$('Commission') | async"
        [HeadlineIcon]="CommissionIcon"
        [NewEntryToolTip]="(cls.getSingle$('Commission') | async) + 'Hinzufügen'"
        [EmptyText]="'Keine' + (cls.getMultiple$('Commission') | async) + ' Vorhanden'"
        [FilterValues]="FilterValues$ | async"
        (FilterValuesChange)="FilterValues$.next($event)"
        [FilterSettings]="FilterSettings$ | async"
        [FilterCount]="FilterAmount$ | async"
        [DefaultSortDirection]="DefaultSortDirection"
        [DefaultSortActive]="DefaultSortActive"
        (SortChange)="defaultTableSortingService.setSort('commissionList', $event)"
    >
        <ng-container *appDetailViewButtons>
            <button class="header-button" app-round-button Inverted matTooltip="Demo - Berechnung" (click)="OpenDemo()" *ngIf="AkkordDemoButton">
                <fa-icon icon="euro-sign"></fa-icon>
            </button>
            <!--<button class="header-button" app-round-button Inverted *ngIf="CommissionExportButton" [matTooltip]="(cls.getMultiple$('Commission') | async) + ' als CSV Exportieren'" matTooltipPosition="right" (click)="CsvDownload()">
                <app-double-icon Icon="file-contract" Icon2="download"></app-double-icon>
            </button>
        -->

            <button
                class="header-button"
                app-round-button
                Inverted
                *ngIf="NewCommissionButton"
                [matTooltip]="(cls.getSingle$('Commission') | async) + ' hinzufügen'"
                matTooltipPosition="right"
                (click)="CreateCommission()"
                [Disabled]="!(PS.Has$(PS.Permissions.CreateCommission) | async) || LS.isLoading"
            >
                <app-double-icon Icon="file-contract" Icon2="plus"></app-double-icon>
            </button>
            <button class="header-button2" app-round-button Inverted [matBadge]="disabledColumns$ | async" matTooltip="Tabelle anpassen" (click)="drawer.toggle()">
                <fa-icon icon="cog"></fa-icon>
            </button>
        </ng-container>
    </app-dave-list>
</mat-drawer-container>
