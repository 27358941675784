<app-dave-list-card
    [Headline]="title"
    EmptyText="Keine Bestellungen vorhanden"
    [DisplayedCollums]="displayedColumns"
    [IsLoading]="loading$ | async"
    [TableHeaderCollumsMap]="tableHeaderColumnsMap$ | async"
    DefaultSortActive="CreatedAt"
    DefaultSortDirection="desc"
    style="max-height: 50vh"
    [Data]="tableDataSource$ | async"
>
    <button app-round-button headerButton matTooltip="Filter" (click)="filter.Open()" [matBadge]="(FilterAmount$ | async) || undefined">
        <fa-icon icon="filter"></fa-icon>
    </button>
</app-dave-list-card>
<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="dataSource.filter$ | async" (ValuesChange)="dataSource.filter$.next($event)"></app-filter-card>

<!--        <app-dave-list-card-->
<!--            [Headline]="cls.getMultiple$('Costs') | async"-->
<!--            EmptyText="Keine Preise vorhanden"-->
<!--            [Data]="MaterialPriceHistoryTableData$ | async"-->
<!--            [IsLoading]="MaterialPriceHistoryTableDataLoading$ | async"-->
<!--            [DisplayedCollums]="['Costs', 'CreatedAt', 'SupplierName', 'User', 'Icon']"-->
<!--            [TableHeaderCollumsMap]="{-->
<!--                Costs: cls.getSingle$('Costs') | async,-->
<!--                CreatedAt: 'erstellt am',-->
<!--                SupplierName: 'Lieferant',-->
<!--                User: 'Nutzer'-->
<!--              }"-->
<!--            [CustomCellTemplates]="{-->
<!--                Icon: iconcollum-->
<!--            }"-->
<!--            DefaultSortActive="CreatedAt"-->
<!--            DefaultSortDirection="desc"-->
<!--            style="max-height: 50vh"-->
<!--        >-->
<!--        </app-dave-list-card>-->
