import { CreateDocumentLinkRequest, FileEditRequest, PdfMergeRequest, VersionEditRequest } from "@dave/types";
import { FileDeleteRequest } from '@dave/types/dist/proto/file/file';
import { createAction, props } from '@ngrx/store';
import { FileEntity, UploadFileArgs } from '../../entities/file.entity';
import { VersionEntity } from '../../entities/version.entity';
import { DaveMutationChangeFileArgs } from '../../graphql-types';
import { PartialBy } from '../../helper/helper';
import { GatewayResponseErrorType } from '../../services/app-gateway.service';

export type fileUploadParamsObj = {
    auto_set_folder?: string;
    hidden?: string;
    resource_id?: string;
    folder_id?: string;
    event_id?: string;
    metadata?: string;
    name?: string;
    commission_id?: string;
    document_id?: string;
};
export type FileUploadParams = Map<keyof fileUploadParamsObj, string>;

export const FilesActionTypes = {
    // LoadAllFiles: createAction('data/Files/getAll'),
    LoadFilesFromFolder: createAction('data/Files/getFromFolder', props<{ Payload: number }>()),
    LoadFilesAfter: createAction('data/Files/getAfter', props<{ Payload: number }>()),
    LoadEmailFiles: createAction('data/Files/getEmailFiles', props<{ Payload: number[] }>()),
    DeleteVersion: createAction('data/FileVersion/delete', props<{ Payload: number }>()),
    ModifyVersion: createAction('data/FileVersion/modify', props<{ Payload: VersionEditRequest, parentDocumentId: number }>()),

    ModifyFileRequest: createAction('data/Files/modify/request', props<{ Payload: PartialBy<FileEditRequest, 'TagIds' | 'CustomerIds' | 'CommissionIds' | 'EventIds' | 'TransmissionIds' | 'ResourceIds'> }>()),
    ModifyFileSuccess: createAction('data/Files/modify/success', props<{ Payload: FileEntity }>()),
    ModifyFileFailure: createAction('data/Files/modify/failure', props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>()),

    DeleteFileRequest: createAction('data/File/delete/request', props<{ Payload: FileDeleteRequest }>()),
    DeleteFileSuccess: createAction('data/File/delete/success', props<{ Payload: number }>()),
    DeleteFileFailure: createAction('data/File/delete/failure', props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>()),

    CreateLinkRequest: createAction('data/File/createLink/request', props<{ Payload: CreateDocumentLinkRequest }>()),
    CreateLinkSuccess: createAction('data/File/createLink/success', props<{ Payload: FileEntity }>()),
    CreateLinkFailure: createAction('data/File/createLink/failure', props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>()),

    MergePdfVersionsRequest: createAction('data/File/mergeVersions/request', props<{ Payload: PdfMergeRequest }>()),
    MergePdfVersionsSuccess: createAction('data/File/mergeVersions/success', props<{ Payload: number }>()),
    MergePdfVersionsFailure: createAction('data/File/mergeVersions/failure', props<{ Payload: { Errors: GatewayResponseErrorType; ToasterMessage?: string } }>()),

    /**
     * @deprecated use UploadFileBackendRequest
     */
    UploadFile: createAction('data/File/upload', props<{ Payload: UploadFileArgs }>()),

    UploadFileBackendRequest: createAction('data/File/uploadFileBackend/request', props<{ Payload: { file: Blob | File; params: FileUploadParams } }>()),
    UploadFileBackendSuccess: createAction('data/File/uploadFileBackend/success', props<{ Payload: FileEntity }>()),
    UploadFileBackendFailure: createAction('data/File/uploadFileBackend/failure', props<{ Payload: { Errors: any; ToasterMessage?: string } }>()),

    UpdateMany: createAction('data/Files/UpdateMany', props<{ Payload: Array<FileEntity> }>()),
    UpdateOne: createAction('data/Files/UpdateOne', props<{ Payload: FileEntity }>()),
    UpdateOneVersion: createAction('data/Files/UpdateOneVersion', props<{ Payload: VersionEntity }>()),
    UpdateAll: createAction('data/Files/UpdateAll', props<{ Payload: Array<FileEntity> }>()),
};

// export const FilesActionTypes = createActionGroup({
//     source: 'Files',
//     events: {
//         LoadFilesFromFolder: props<{ Payload: number }>(),
//         LoadFilesAfter: props<{ Payload: number }>(),
//         LoadEmailFiles: props<{ Payload: number[] }>(),
//         DeleteFile: props<{ Payload: number }>(),
//         DeleteVersion: props<{ Payload: number }>(),
//         ModifyFile: props<{ Payload: DaveMutationChangeFileArgs }>(),
//         ModifyVersion: props<{ Payload: VersionEditRequest }>(),
//         CreateLink: props<{ Payload: CreateDocumentLinkRequest    }>(),
//         /**
//          * @deprecated use UploadFileBackend
//          */
//         UploadFile: props<{ Payload: UploadFileArgs }>(),
//         UploadFileBackend: props<{ Payload: { file: Blob | File, params: FileUploadParams } }>(),
//         UpdateMany: props<{ Payload: Array<FileEntity> }>(),
//         UpdateOne: props<{ Payload: FileEntity }>(),
//         UpdateOneVersion: props<{ Payload: VersionEntity }>(),
//         UpdateAll: props<{ Payload: Array<FileEntity> }>(),
//         RemoveFile: props<{ Payload: number }>(),
//     },
// });
