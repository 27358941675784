import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import { exhaustMap, of } from 'rxjs';
import {catchError, concatMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {HttpService} from '../../services/http.service';
import {State} from '../index';
import {DaveActions} from '../actions/actions';
import {RoleEntity} from '../../entities/role.entity';
import {PermissionEntityFromBackend} from '../../entities/permission.entity';
import {PermissionsActionTypes} from '../actions/permissions.actions';
import {EmployeeActionTypes} from '../actions/employee.actions';
import {BaseActionTypes} from '../actions/base.actions';
import { ResolverActionTypes } from '../actions/resolver.actions';
import { getEmployees } from '../selectors/employees.selectors';

enum ErrorCodes {
  Load = 'Rollen Abrufen fehlgeschlagen',
  Add = 'Rolle hinzufügen fehlgeschlagen',
  Remove = 'Rolle entfernen fehlgeschlagen',
  LoadPermission = 'Permissions Abrufen fehlgeschlagen',
}

@Injectable()
export class RoleEffects {
  constructor(
    private actions$: Actions<DaveActions>,
    private store$: Store<State>,
    private gatewayHttpService: HttpService
  ) {
  }


  AddUser2Role = createEffect(() => this.actions$.pipe(
    ofType(PermissionsActionTypes.AddUser2Role),
    withLatestFrom(this.store$),
    concatMap(([action, store]) => {
      const queryString = `
            mutation {
                createAssignmentUser2Role(
                    userId: ${action.Payload.UserId}
                    role: "${action.Payload.Role}"
                ) {
                    role
                    userId
                    productId
                }
            }`;

      return this.gatewayHttpService
        .graphQl({query: queryString}, {token: store.base.token})
        .pipe(
            withLatestFrom(this.store$.select(getEmployees)),
          map(([res, employees]) => {
            if (res && res.createAssignmentUser2Role) {
              const roles = [];
              res.createAssignmentUser2Role.forEach(value => roles.push(value.role));

              return EmployeeActionTypes.UpdateOne({
                Payload: employees.find(employee => employee.UserId === action.Payload.UserId)
                    .Clone({UserRolesList: roles})
              });
            } else {
              return BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Add}});
            }
          }),
          catchError((err, caught) =>
            of(BaseActionTypes.ErrorAction({
              Payload: {
                ToasterMessage: ErrorCodes.Add,
                Err: err,
                Caught: caught,
              }
            }))
          )
        );
    })
  ));


  RemoveUser2Role = createEffect(() => this.actions$.pipe(
    ofType(PermissionsActionTypes.RemoveUser2Role),
    withLatestFrom(this.store$),
    concatMap(([action, store]) => {
      const queryString = `
            mutation {
                deleteAssignmentUser2Role(
                    userId: ${action.Payload.UserId}
                    role: "${action.Payload.Role}"
                ) {
                    role
                    userId
                    productId
                }
            }`;

      return this.gatewayHttpService
        .graphQl({query: queryString}, {token: store.base.token})
        .pipe(
            withLatestFrom(this.store$.select(getEmployees)),
          map(([res, employees]) => {
            if (res && res.deleteAssignmentUser2Role) {
              const roles = [];
              res.deleteAssignmentUser2Role.forEach(value => roles.push(value.role));

              return EmployeeActionTypes.UpdateOne({
                Payload: employees.find(employee => employee.UserId === action.Payload.UserId)
                    .Clone({UserRolesList: roles})
              });
            } else {
              return BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Remove}});
            }
          }),
          catchError((err, caught) =>
            of(BaseActionTypes.ErrorAction({
              Payload: {
                ToasterMessage: ErrorCodes.Remove,
                Err: err,
                Caught: caught,
              }
            }))
          )
        );
    })
  ));


  LoadRoles$ = createEffect(() => this.actions$.pipe(
    ofType(ResolverActionTypes.LoadRoles),
    withLatestFrom(this.store$),
    switchMap(([action, store]) => {
      const queryString = `
            query{
                getrole2permissions{
                    permissionDescription
                    permissionName
                    roleName
                    roleDescription
                    productId
                }
            }`;

      return this.gatewayHttpService
        .graphQl({query: queryString}, {token: store.base.token, retry: true })
        .pipe(
          map(res => {
            if (res && res.getrole2permissions) {
              const roles: Array<RoleEntity> = [];

              res.getrole2permissions.forEach(element => {
                let found = false;
                const permission = PermissionEntityFromBackend(element);

                roles.forEach(role => {
                  if (role.Name === element.roleName) {
                    found = true;
                    role.Permissions.push(permission);
                  }
                });

                if (!found) {
                  const role = new RoleEntity();
                  role.Name = element.roleName;
                  role.Permissions = [permission];
                  role.Description = element.roleDescription;
                  role.ProductId = element.productId;
                  roles.push(role);
                }
              });
              return PermissionsActionTypes.LoadRoles({Payload: roles});
            } else {
              return BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.Load}});
            }
          }),
          catchError((err, caught) =>
            of(BaseActionTypes.ErrorAction({
              Payload: {
                ToasterMessage: ErrorCodes.Load,
                Err: err,
                Caught: caught,
              }
            }))
          )
        );
    })
  ));


  LoadPermissions$ = createEffect(() => this.actions$.pipe(
    ofType(ResolverActionTypes.LoadPermissions),
    withLatestFrom(this.store$),
    exhaustMap(([action, store]) => {
      const queryString = `
            query{
                getuser2Role{
                    permissionName
                    permissionDescription
                }
            }`;

      return this.gatewayHttpService
        .graphQl({query: queryString}, {token: store.base.token, retry: true })
        .pipe(
          map(res =>
            (res && res.getuser2Role)
              ? PermissionsActionTypes.LoadPermissions({Payload: res.getuser2Role.map(p => PermissionEntityFromBackend(p))})
              : BaseActionTypes.ErrorAction({Payload: {ToasterMessage: ErrorCodes.LoadPermission}})
          ),
          catchError((err, caught) =>
            of(BaseActionTypes.ErrorAction({
              Payload: {
                ToasterMessage: ErrorCodes.LoadPermission,
                Err: err,
                Caught: caught,
              }
            }))
          )
        );
    })
  ));
}
