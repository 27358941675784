import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FloatLabelType } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AccountsReceivableLedgerTypeEnum } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import { ArrayElement, ObservableElement, stringSearch } from '../../../helper/helper';
import { ResourcePageMeta } from '../../../helper/page-metadata';
import { ArlCalculationDataService } from '../arl-calculation/arl-calculation-data.service';
import { ResourceEntity } from '../../../dave-data-module/entities/resource-dispo/resource.entity';
import {
    SelectSearchOption
} from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
type option = SelectSearchOption<{
    searchValues?: string[], optionLabel: string, value: string, entityId: number
}>
//todo zum mat form field control machen damit es in einem mat-form-field genutzt werden kann
@Component({
    selector: 'app-arl-information-input',
    standalone: true,
    imports: [CommonModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule, FontAwesomeModule, SelectSearchModule, FormsModule],
    templateUrl: './arl-information-input.component.html',
    styleUrls: ['./arl-information-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArlInformationInputComponent implements OnInit {
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() informationControl!: FormControl<string>;
    private arlType$ = new BehaviorSubject<AccountsReceivableLedgerTypeEnum>(null);
    @Input() set ARLType(type: AccountsReceivableLedgerTypeEnum) {
        this.arlType$.next(type);
    }
    @Output() entitySelected = new EventEmitter<number>();
    @Input() inputMode: 'autocomplete' | 'selectSearch' = 'autocomplete';
    @Input() set entityId(id: number) {
        if (id && id !== this.selectSearchForm.value?.entityId) {
            this.selectSearchForm.setValue({ entityId: id, value: '', optionLabel: '' });
        }
    }
    @Input() FilterMaterialsByCustomerId: number | null = null
    options$: Observable<option[]> ;
    filteredOptions$: typeof this.options$;
    protected selectSearchForm = new FormControl<ArrayElement<ObservableElement<typeof this.options$>>>(null);

    placeholder = 'Bezeichnung';
    matSelectPanelClass$ = this.arlType$.pipe(map(type => type === AccountsReceivableLedgerTypeEnum.MaterialArl ? 'app-arl-information-input-wider-panel-class' : ''))
    constructor(private arlCalculationDataService: ArlCalculationDataService) {
    }

    protected compareOptions = (a: ArrayElement<ObservableElement<typeof this.options$>>, b: ArrayElement<ObservableElement<typeof this.options$>>) => {
        return a?.entityId === b?.entityId;
    };
    protected searchOptionsFunction = (search: string, option: option) => {
        if (option.searchValues?.length) {
           return option.searchValues.some(v => v && stringSearch(v, search));
        } else {
           return option.optionLabel && stringSearch(option.optionLabel, search);
        }
    }

    ngOnInit(): void {
        this.options$ = this.arlType$.pipe(
            switchMap((type) =>
                type === AccountsReceivableLedgerTypeEnum.ResourceArl
                    ? this.arlCalculationDataService.resourceOptions$
                    : type === AccountsReceivableLedgerTypeEnum.MaterialArl
                    ? this.arlCalculationDataService.materialOptionsBySupplierId$(this.FilterMaterialsByCustomerId, this.selectSearchForm.value?.entityId)
                    : type === AccountsReceivableLedgerTypeEnum.EmployeeArl
                    ? this.arlCalculationDataService.employeeOptions$
                    : (of([] as any)),
            ),
        );

        this.filteredOptions$ = this.options$.pipe(
            switchMap((options) =>
                this.informationControl.valueChanges.pipe(
                    startWith(null),
                    map(() => this.informationControl.value),
                    map((value) => {
                        const filterValue = (value || '').toLowerCase();
                        return options.filter((option) => this.searchOptionsFunction(filterValue, option));
                    }),
                ),
            ),
        );
    }

    onDropDownSelected($event: ArrayElement<ObservableElement<typeof this.options$>>) {
        console.log($event);
        this.selectSearchForm.setValue($event);
        this.selectSearchForm.markAsDirty();
        this.informationControl.setValue($event ? $event.value : '');
        this.informationControl.markAsDirty();
        this.entitySelected.emit($event.entityId);
    }
    onAutocompleteSelected($event: MatAutocompleteSelectedEvent) {
        this.entitySelected.emit(+$event.option.id);
    }

    protected readonly ResourcePageMeta = ResourcePageMeta;
}
