import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { FolderDataService } from '../dave-data-module/services/folder-data.service';
import { State } from '../dave-data-module/State';
import { BaseActionTypes } from '../dave-data-module/State/actions/base.actions';
import { FilesActionTypes } from '../dave-data-module/State/actions/files.actions';
import { FolderActionTypes } from '../dave-data-module/State/actions/folder.actions';
import { SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData } from '../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';
import { isNotNullOrUndefined } from '../helper/helper';

@Injectable({
    providedIn: 'root',
})
export class MoveFileFolderService {
    constructor(private store: Store<State>, private dialog: MatDialog, private folderDataService: FolderDataService) {}

    /**
     *
     * @param selectedFileIds files wich should be moved
     * @param selectedFolderIds folders wich should be moved
     * @param folderId folder to start browsing
     */
    public MoveFileFolder(selectedFileIds: number[], selectedFolderIds: number[], folderId?: number) {
        const dialogRef = this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData>(SelectFolderDialogComponent, {
            ...SelectFolderDialogComponent.DefaultConfig,
            data: {
                disabledFolderIds: selectedFolderIds,
                folderId,
                matTreeNodePaddingIndent: 10,
            },
        });
        dialogRef
            .afterClosed()
            .pipe(
                take(1),
                tap((ret) => {
                    const id = ret.folderId;
                    if (id && selectedFolderIds) {
                        selectedFolderIds.forEach((f) => this.store.dispatch(FolderActionTypes.ModifyFolder({ Payload: { id: f, parentId: id } })));
                    }
                    if (id && selectedFileIds) {
                        selectedFileIds.forEach((f) => this.store.dispatch(FilesActionTypes.ModifyFileRequest({ Payload: { DocumentId: f.toString(), FolderId: id.toString(), Hidden: false } })));
                    }
                }),
            )
            .subscribe();
        this.folderDataService
            .getFolderByIds(selectedFolderIds)
            .pipe(filter(isNotNullOrUndefined), takeUntil(dialogRef.beforeClosed()))
            .subscribe((folders) => {
                const selectedFolders = folders.filter((f) => selectedFolderIds.includes(f.Id));
                if (selectedFolders?.length && selectedFolders.find((f) => f.PartnerId !== selectedFolders[0].PartnerId)) {
                    this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'Sie können "geteilte" und "nicht geteilte" Ordner nicht gleichzeitig verschieben' } }));
                }
            });
    }
}
