import { CommonModule } from '@angular/common';
import { Component,Inject,Input } from '@angular/core';
import { FormControl,FormGroup } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDialogConfig, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import moment,{ Moment } from 'moment/moment';
import { BehaviorSubject,combineLatest,Observable,switchMap } from 'rxjs';
import { map,shareReplay,startWith } from 'rxjs/operators';
import { ITaskPriority } from '../../../../../dave-data-module/entities/event.entity';
import { MilestoneEntity,MilestoneEntityState,MilestoneEntityStateNames } from '../../../../../dave-data-module/entities/milestone.entity';
import { DaveMutationCreateMilestoneArgs } from '../../../../../dave-data-module/graphql-types';
import { FrontendDate } from '../../../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getFetched$ } from '../../../../../dave-data-module/helper/helper';
import { State } from '../../../../../dave-data-module/State';
import { MilestoneActionTypes } from '../../../../../dave-data-module/State/actions/milestone.actions';
import { getClockInTypes,getClockInTypesFetched } from '../../../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getMilestoneById } from '../../../../../dave-data-module/State/selectors/milestone.selector';
import { getWorkedTimes,getWorkedTimesFetched } from '../../../../../dave-data-module/State/selectors/worked-times.selectors';
import {
ITaskBoardSort,
TaskBoardFilter
} from '../../../../../dave-tasks/components/tasks-board/tasks-board.component';
import { DaveTasksModule } from '../../../../../dave-tasks/dave-tasks.module';
import { TaskFilterService } from '../../../../../dave-tasks/services/task-filter.service';
import { AppButtonModule } from '../../../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../../../dave-utils-module/app-filter-module/app-filter.module';
import { IDetailListTemplateData } from '../../../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { DaveSharedComponentsModule } from '../../../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { SelectSearchOption } from '../../../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { clacEventFilterAmount,getEventFilterValues,IEventFilterType } from '../../../../../helper/event.helper';
import { appMatDialogDefaultConfig,isNotNullOrUndefined } from '../../../../../helper/helper';
import { InvoiceEditorMeta,TaskPageMeta } from '../../../../../helper/page-metadata';
import { ProgressCardProgressBarComponent } from '../../../../../progress-bar/components/progress-card-progress-bar/progress-card-progress-bar.component';
import { FilterTypes } from '../../../../../services/default-filter.service';
import {
    DaveFilePreviewComponent,
    DaveFilePreviewComponentDialogData,
} from '../../../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { LoadingService } from '../../../../../services/loading.service';
export interface MilestoneModalComponentDialogData {
    milestone?: MilestoneEntity;
    createMilestoneMutationDefault: Partial<DaveMutationCreateMilestoneArgs>;
}
@Component({
    selector: 'app-milestone-modal',
    standalone: true,
    imports: [
        CommonModule,
        AppButtonModule,
        DaveSharedComponentsModule,
        FontAwesomeModule,
        MatDialogModule,
        MatTooltipModule,
        ProgressCardProgressBarComponent,
        DaveTasksModule,
        MatCardModule,
        MatMenuModule,
        AppFilterModule,
        MatBadgeModule,
    ],
    templateUrl: './milestone-modal.component.html',
    styleUrls: ['./milestone-modal.component.scss'],
})
export class MilestoneModalComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,

        width: '90%',
        // height: '90%',
    };
    public formMilestone = new FormGroup({
        Name: new FormControl<string>(null),
        Description: new FormControl<string>(null),
        Deadline: new FormControl<Moment>(null),
        State: new FormControl<MilestoneEntityState>(null),
        TargetHours: new FormControl<number>(null),
        ClockInTypeId: new FormControl<SelectSearchOption & { Id: number }>(null),
    });
    protected milestoneId$ = new BehaviorSubject<number | null>(null);
    @Input() set milestone(v: MilestoneEntity | null) {
        if ((v?.Id || null) !== this.milestoneId$.value) {
            this.milestoneId$.next(v?.Id || null);
        }
        this.setForm(v);
    }
    @Input() createMilestoneMutationDefault: Partial<DaveMutationCreateMilestoneArgs>;

    protected milestone$ = this.milestoneId$.pipe(
        switchMap((id) => this.store.select(getMilestoneById({ id }))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected headline$ = this.milestone$.pipe(map((m) => (m ? m.Name + ' bearbeiten' : 'Meilenstein erstellen')));
    protected currHours$ = combineLatest([getFetched$(this.store, getWorkedTimesFetched, getWorkedTimes), this.milestone$, this.formMilestone.controls.ClockInTypeId.valueChanges.pipe(startWith(null))]).pipe(
        map(([workedTimes, milestone]) =>
            this.formMilestone.controls.ClockInTypeId.value?.Id
                ? workedTimes.filter((item) => item.CommissionId === milestone.CommissionId && item.ClockInTypeId === this.formMilestone.controls.ClockInTypeId.value?.Id).reduce((prev, curr) => prev + curr.Timespan, 0) / 1000 / 60 / 60
                : 0,
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public listTemplateData$: Observable<IDetailListTemplateData> = getFetched$(this.store, getClockInTypesFetched, getClockInTypes).pipe(
        map((clockInTypes) => {
            return {
                Properties: [
                    { key: 'Name', formControl: this.formMilestone.controls.Name },
                    // {
                    //     key: 'Beschreibung',
                    //     formControl: this.formMilestone.controls.Description,
                    //     options: {
                    //         specialInput: {
                    //             textArea: { Fill: false },
                    //         },
                    //     },
                    // },
                    {
                        key: 'Frist',
                        formControl: this.formMilestone.controls.Deadline,
                        options: {
                            specialInput: { date: true },
                        },
                    },
                    // {
                    //     key: 'Status',
                    //     formControl: this.formMilestone.controls.State,
                    //     options: {
                    //         specialInput: { select: Object.values(MilestoneEntityState).map((val) => ({ optionLabel: MilestoneEntityStateNames.get(val), optionValue: val })) },
                    //     },
                    // },
                    {
                        key: 'Soll Stunden',
                        formControl: this.formMilestone.controls.TargetHours,
                        options: {
                            specialInput: {
                                number: true,
                            },
                            suffix: 'Std.',
                        },
                    },
                    {
                        key: 'Stempeltyp',
                        formControl: this.formMilestone.controls.ClockInTypeId,
                        options: {
                            specialInput: {
                                singleSelectSearch: {
                                    options: clockInTypes.map((c) => ({
                                        optionLabel: c.Name,
                                        Id: c.Id,
                                    })),
                                    compareOptions: (a, b) => a.Id === b.Id,
                                },
                            },
                        },
                    },
                ],
            };
        }),
    );
    statusData: IDetailListTemplateData = {
        Properties: [
            {
                key: 'Status',
                formControl: this.formMilestone.controls.State,
                options: {
                    specialInput: { select: Object.values(MilestoneEntityState).map((val) => ({ optionLabel: MilestoneEntityStateNames.get(val), optionValue: val })) },
                },
            },
        ],
    };
    public FilterValues$: BehaviorSubject<IEventFilterType> = getEventFilterValues();
    protected taskFilter$: Observable<TaskBoardFilter> = combineLatest([this.FilterValues$, this.milestoneId$]).pipe(
        map(([v, milestoneId]) => ({
            CommissionIds: v[FilterTypes.CommissionId]?.map((u) => +u.id) || null,
            UserId: v[FilterTypes.UserId]?.map((u) => +u.id) || null,
            AutorId: v[FilterTypes.AutorId]?.map((u) => +u.id) || null,
            Priority: v.priority?.length ? v.priority.map((p) => +p.id as ITaskPriority) : null,
            MinEventEndDate: v[FilterTypes.EndDate]?.from ? v[FilterTypes.EndDate].from.toDate() : null,
            MaxEventEndDate: v[FilterTypes.EndDate]?.to ? v[FilterTypes.EndDate].to.toDate() : null,
            MilestoneIds: milestoneId ? [milestoneId] : null,
        })),
    );
    public FilterSettings$ = this.filterService.filterSettings$.pipe(map(settings => settings?.filter(s => s.Name !== FilterTypes.CommissionId)));
    public FilterAmount$ = this.FilterValues$.pipe(map(clacEventFilterAmount), shareReplay({ refCount: true, bufferSize: 1 }));

    public TaskBoardSort: ITaskBoardSort = { sortBy: 'endDate', direction: 'dsc' };
    constructor(private dialog: MatDialog, private filterService: TaskFilterService, private store: Store<State>, private dialogRef: MatDialogRef<MilestoneModalComponent>, @Inject(MAT_DIALOG_DATA) dialogData: MilestoneModalComponentDialogData, protected ls: LoadingService) {
        if (dialogData) {
            this.milestone = dialogData.milestone || null;
            this.createMilestoneMutationDefault = dialogData.createMilestoneMutationDefault;
        }
    }
    setForm(v: MilestoneEntity) {
        if (v) {
            this.formMilestone.setValue({
                Name: v.Name,
                Description: v.Description,
                Deadline: v.Deadline ? moment(v.Deadline) : null,
                State: v.State,
                TargetHours: v.TargetHours,
                ClockInTypeId: v.ClockInTypeId ? { Id: v.ClockInTypeId, optionLabel: '' } : null,
            });
        } else {
            this.formMilestone.setValue({
                Name: null,
                Description: null,
                Deadline: null,
                State: null,
                TargetHours: null,
                ClockInTypeId: null,
            });
        }
        this.formMilestone.markAsPristine();
    }

    onDeleteClick() {
        this.store.dispatch(
            MilestoneActionTypes.Delete({
                Payload: {
                    id: this.milestoneId$.value,
                },
            }),
        );
        this.dialogRef.close();
    }

    onSaveClick() {
        if (this.milestoneId$.value) {
            this.store.dispatch(
                MilestoneActionTypes.Change({
                    Payload: {
                        id: this.milestoneId$.value,
                        deadline: this.formMilestone.value.Deadline && FrontendDate(this.formMilestone.value.Deadline.toDate()),
                        description: this.formMilestone.value.Description,
                        name: this.formMilestone.value.Name,
                        state: this.formMilestone.value.State as any,
                        targetHours: isNotNullOrUndefined(this.formMilestone.value.TargetHours) ? this.formMilestone.value.TargetHours : null,
                        clockInTypeId: this.formMilestone.value.ClockInTypeId?.Id || null,
                    },
                }),
            );
        } else {
            this.store.dispatch(
                MilestoneActionTypes.Create({
                    Payload: {
                        ...this.createMilestoneMutationDefault,
                        deadline: this.formMilestone.value.Deadline && FrontendDate(this.formMilestone.value.Deadline.toDate()),
                        description: this.formMilestone.value.Description,
                        name: this.formMilestone.value.Name,
                        state: this.formMilestone.value.State as any,
                        targetHours: isNotNullOrUndefined(this.formMilestone.value.TargetHours) ? this.formMilestone.value.TargetHours : null,
                        clockInTypeId: this.formMilestone.value.ClockInTypeId?.Id || null,
                    },
                }),
            );
        }
        this.dialogRef.close();
    }

    protected readonly InvoiceEditorMeta = InvoiceEditorMeta;
    protected readonly TaskPageMeta = TaskPageMeta;

    private fileDialogRef: MatDialogRef<DaveFilePreviewComponent>;
    onFileClick(fileId: number) {
        this.fileDialogRef?.close();
        this.fileDialogRef = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }
}
