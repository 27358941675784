import { PdfViewerModule } from '@syncfusion/ej2-angular-pdfviewer';
import {NgModule} from '@angular/core';
import { PdfEditorComponent } from './components/pdf-editor.component';
import { FormsModule } from '@angular/forms';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  imports: [PdfViewerModule, FormsModule, AppButtonModule, FontAwesomeModule, CommonModule, MatTooltipModule],
    declarations: [PdfEditorComponent],
    exports: [
        PdfEditorComponent,
    ],
})
export class PdfEditorModule {
}
