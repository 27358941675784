import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppGatewayService } from '../../services/app-gateway.service';
import { DaveActions } from '../actions/actions';

import { MaterialToSupplierDataService } from '../../services/material-to-supplier-data.service';
import { State } from '../index';

@Injectable()
export class MaterialToSupplierEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayService: AppGatewayService, private materialToSupplierDataService: MaterialToSupplierDataService) {}
}
