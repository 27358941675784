import { Address } from '../../helper/helper';
import { EmployeeType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import {JobSpecificationEntity} from "./job-specification.entity";
import { Permissions } from "@dave/types";
export enum SalaryTypes {
    Flatreate = 'Gehalt',
    PerHour = 'Lohn'
}
export enum EmployeeStatusEnum {
    Aktiv = 'AKTIV',
    Elternzeit = 'ELTERNZEIT',
    Inaktiv = 'INAKTIV'
  }
const salaryTypeValues: string[] = Object.values(SalaryTypes);
export const SalaryTypeNames: Map<SalaryTypes, string> = new Map<SalaryTypes, string>(
    [
        [SalaryTypes.Flatreate, 'Gehalt'],
        [SalaryTypes.PerHour, 'Lohn'],
    ]
);
export const EmployeeStatusNames: Map<EmployeeStatusEnum, string> = new Map<EmployeeStatusEnum, string>(
    [
        [EmployeeStatusEnum.Aktiv, 'Aktiv'],
        [EmployeeStatusEnum.Elternzeit, 'Elternzeit'],
        [EmployeeStatusEnum.Inaktiv, 'Inaktiv'],
    ]
);
export enum EmploymentTypes {
    Full = 'Vollzeit',
    Half = 'Teilzeit',
}
const employeementTypeValues: string[] = Object.values(EmploymentTypes);
export const EmploymentTypeNames: Map<EmploymentTypes, string> = new Map<EmploymentTypes, string>(
    [
        [EmploymentTypes.Full, 'Vollzeit'],
        [EmploymentTypes.Half, 'Teilzeit'],
    ]
);
export const EmployeeEntityGqlFields = `
annualVacationDays
bIC
birthdate
cancellationPeriod
canCreate
canDelete
canEdit
canRoleEdit
canSeeOvertime
city
country
dateOfEmployment
deleted
department
durationTrial
email
employeeNo
employmentType
endOfContract
faxnumber
firstname
iBAN
id
lastname
latitude
longitude
minimumVoteCount
mobilenumber
overtime

partnerId
phonenumber
postalCode
remainingVacationDaysFromCurrentYear
remainingVacationDaysFromPastYears
salary
salaryType
street

user2employee{userId}
userId

userRolesList
weeklyWorkingHours
jobSpecificationId
updatedAt
employeeStatus
permissionsFromEntityRoles
taxNo
healthInsurance`;

// todo: wenn backend fertig: workingDays
export class EmployeeEntity implements Address {
    constructor(
        public Id: number = null,
        public Lastname: string = null,
        public Firstname: string = null,
        public Email: string = null,
        public Phonenumber: string = null,
        public Mobile: string = null,
        public Faxnumber: string = null,
        public Street: string = null,
        public PostalCode: string = null,
        public City: string = null,
        public Country: string = null,
        public PartnerId: number = null,
        public Deleted: boolean = null,
        public UserId: number = null,
        public Longitude: string = null,
        public Latitude: string = null,
        public EmployeeNo: string = null,
        public CanDelete: boolean = null,
        public CanEdit: boolean = null,
        public CanRoleEdit: boolean = null,
        public CanSeeOvertime: boolean = null,
        public CanSeeSalary: boolean = null,
        public UserRolesList: string[] = [],
        public UserIds: number[] = [],
        public RemainingVacationDaysFromPastYears: number = null,
        public RemainingVacationDaysFromCurrentYear: number = null,
        public MinimumVoteCount: number = null,
        public AnnualVacationDays: number = null,
        public Overtime: number = null,
        public WeeklyWorkingHours: number[] = null, // (mo, di, ... sa, so) nullable für freelancer,
        public SalaryType: SalaryTypes = null,
        public EmploymentType: EmploymentTypes = null,
        public JobSpecificationId: number = null,
        public UpdatedAt: Date = null,
        public EmployeeStatus: EmployeeStatusEnum = null,
        public Salary: number = null,
        public Department: string = null,
        public IBAN: string = null,
        public BIC: string = null,
        public CancellationPeriod: number = null,
        public DateOfEmployment: Date = null,
        public Birthdate: Date = null,
        public EndOfContract: Date = null,
        public DurationTrial: Date = null,
        public TaxNo: string = null,
        public HealthInsurance: string = null,
        public PermissionsFromEntityRoles: Permissions[] = null,
    ) {}
    get DisplayName() {
        return `${this.Lastname ? this.Lastname + ', ' : ''}${this.Firstname || ''}`;
    }

    public Clone(override: Partial<Omit<EmployeeEntity, 'DisplayName' | 'Clone'>> = {}): EmployeeEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EmployeeEntity(), attr);
    }
}

export function EmployeeEntityFromBackend(res: EmployeeType): EmployeeEntity {
    if (res.weeklyWorkingHours && res.weeklyWorkingHours.length !== 7) {
        console.error('weeklyWorkingHours invalid', res);
    }
    return new EmployeeEntity(
        res.id,
        res.lastname,
        res.firstname,
        res.email,
        res.phonenumber,
        res.mobilenumber,
        res.faxnumber,
        res.street,
        res.postalCode,
        res.city,
        res.country,
        res.partnerId,
        false,
        res.userId,
        `${res.longitude || ''}`,
        `${res.latitude || ''}`,
        res.employeeNo,
        res.canDelete,
        res.canEdit,
        res.canRoleEdit,
        res.canSeeOvertime,
        res.canSeeSalary,
        res.userRolesList,
        res.user2employee.map(c => c.userId),
        res.remainingVacationDaysFromPastYears,
        res.remainingVacationDaysFromCurrentYear,
        res.minimumVoteCount,
        res.annualVacationDays,
        res.overtime != null ? res.overtime / (1000 * 60 * 60) : null,
        res.weeklyWorkingHours != null ? res.weeklyWorkingHours.map(ms => ms / (1000 * 60 * 60)) : null,
        salaryTypeValues.includes(res.salaryType) ? res.salaryType as SalaryTypes : null,
        employeementTypeValues.includes(res.employmentType) ? res.employmentType as EmploymentTypes : null,
        res.jobSpecificationId,
        BackendDate(res.updatedAt),
        res.employeeStatus,
        res.salary != null ? res.salary / 100 : null,
        res.department,
        res.iBAN,
        res.bIC,
        res.cancellationPeriod,
        res.dateOfEmployment && BackendDate(res.dateOfEmployment),
        res.birthdate && BackendDate(res.birthdate),
        res.endOfContract && BackendDate(res.endOfContract),
        res.durationTrial && BackendDate(res.durationTrial),
        res.taxNo,
        res.healthInsurance,
        res.permissionsFromEntityRoles as Permissions[]
    );
}
