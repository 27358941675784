import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ResolveData } from '@angular/router';
import { isArray } from 'chart.js/helpers';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skip, take, takeUntil } from 'rxjs/operators';
import { ITaskPriority } from '../../../dave-data-module/entities/event.entity';
import { BookmarkResolver } from '../../../dave-data-module/guards/bookmark.resolver';
import { CommentResolver } from '../../../dave-data-module/guards/comment.resolver';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { EventTypeResolver } from '../../../dave-data-module/guards/event-type.resolver';
import { EventResolver } from '../../../dave-data-module/guards/event.resolver';
import { MilestoneResolver } from '../../../dave-data-module/guards/milestone.resolver';
import { PartnerResolver } from '../../../dave-data-module/guards/partner.resolver';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../../../dave-data-module/guards/person2Entity.resolver';
import { StatusFromBackofficeResolver } from '../../../dave-data-module/guards/statusFromBackoffice.resolver';
import { UserResolver } from '../../../dave-data-module/guards/user.resolver';
import { BOOKMARK_KEY } from '../../../dave-data-module/State/reducers/bookmarks.reducer';
import { COMMENTS_FEATURE_KEY } from '../../../dave-data-module/State/reducers/comment.reducer';
import { commissionsFeatureKey } from '../../../dave-data-module/State/reducers/commission.reducer';
import { customersFeatureKey } from '../../../dave-data-module/State/reducers/customers.reducer';
import { employeesFeatureKey } from '../../../dave-data-module/State/reducers/employees.reducer';
import { EVENT_TYPE_FEATURE_KEY } from '../../../dave-data-module/State/reducers/event-types.reducer';
import { eventsFeatureKey } from '../../../dave-data-module/State/reducers/events.reducer';
import { Milestone_KEY } from '../../../dave-data-module/State/reducers/milestone.reducer';
import { personsFeatureKey } from '../../../dave-data-module/State/reducers/person.reducer';
import { person2EntityFeatureKey } from '../../../dave-data-module/State/reducers/person2Entity.reducer';
import { StatusFromBackofficeFeatureKey } from '../../../dave-data-module/State/reducers/statusFromBackoffice.reducer';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { clacEventFilterAmount, getEventFilterValues, IEventFilterType } from '../../../helper/event.helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../services/default-filter.service';
import { TaskFilterService } from '../../services/task-filter.service';
import { ITaskBoardSort, TaskBoardFilter } from '../tasks-board/tasks-board.component';

@Component({
    selector: 'app-task-page',
    templateUrl: './task-page.component.html',
    styleUrls: ['./task-page.component.scss'],
})
export class TaskPageComponent implements OnInit, OnDestroy {
    @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;
    public SearchBarActive = false;
    public SearchForm = new FormControl<string>('');
    public FilterValues$: BehaviorSubject<IEventFilterType> = getEventFilterValues();
    private onDestroy$: Subject<void> = new Subject<void>();
    public TaskId$ = this.route.paramMap.pipe(
        map((paramMap) => +paramMap.get('taskId')),
        filter((v) => !!v),
        distinctUntilChanged(),
    );
    public TaskFilter$: Observable<TaskBoardFilter> = this.FilterValues$.pipe(
        map((v) => ({
            CommissionIds: v[FilterTypes.CommissionId]?.map((u) => +u.id) || null,
            UserId: v[FilterTypes.UserId]?.map((u) => +u.id) || null,
            AutorId: v[FilterTypes.AutorId]?.map((u) => +u.id) || null,
            Priority: v.priority?.length ? v.priority.map((p) => +p.id as ITaskPriority) : null,
            MinEventEndDate: v[FilterTypes.EndDate]?.from ? v[FilterTypes.EndDate].from.toDate() : null,
            MaxEventEndDate: v[FilterTypes.EndDate]?.to ? v[FilterTypes.EndDate].to.toDate() : null,
            MilestoneIds: v[FilterTypes.Milestones]?.map((u) => +u.id) || null,
        })),
    );
    public FilterSettings$ = this.filterService
        .filterSettingsWithMilestones$(
            this.FilterValues$.pipe(
                map((f) => (f[FilterTypes.CommissionId]?.length === 1 ? +f[FilterTypes.CommissionId][0].id : null)),
                distinctUntilChanged(),
            ),
        )
        .pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    public FilterAmount$ = this.FilterValues$.pipe(map(clacEventFilterAmount), shareReplay({ refCount: true, bufferSize: 1 }));
    public TaskBoardSort: ITaskBoardSort = { sortBy: 'endDate', direction: 'dsc' };
    static RequiredResolvers: ResolveData = {
        users: UserResolver,
        [EVENT_TYPE_FEATURE_KEY]: EventTypeResolver,
        [customersFeatureKey]: CustomerResolver,
        [personsFeatureKey]: PersonResolver,
        [BOOKMARK_KEY]: BookmarkResolver,
        partner: PartnerResolver,
        [commissionsFeatureKey]: CommissionResolver,
        [person2EntityFeatureKey]: Person2EntityResolver,
        [employeesFeatureKey]: EmployeeResolver,
        [StatusFromBackofficeFeatureKey]: StatusFromBackofficeResolver,
        [eventsFeatureKey]: EventResolver,
        [COMMENTS_FEATURE_KEY]: CommentResolver,
        [Milestone_KEY]: MilestoneResolver,
    };
    constructor(private filterService: TaskFilterService, private dialog: MatDialog, private route: ActivatedRoute, private defaultFilterService: DefaultFilterService, protected cls: CustomLabelService) {
        this.TaskId$.pipe(takeUntil(this.onDestroy$)).subscribe((taskId) => {
            this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
                ...DetailTasksComponent.DefaultConfig,
                data: {
                    EventId: taskId || null,
                },
            });
        });
        this.FilterValues$.pipe(takeUntil(this.onDestroy$), skip(2)).subscribe((val) => {
            if (Object.keys(val).length !== 0) {
                this.defaultFilterService.SetFilterByApp(FilterApps.TaskPage, val);
            }
        });
    }

    ngOnInit(): void {
        combineLatest([this.FilterSettings$, this.defaultFilterService.GetFilterByApp$(FilterApps.TaskPage)])
            .pipe(take(1))
            .subscribe(([val, filterValues]) => {
                this.FilterValues$.next({
                    [FilterTypes.Priority]: isArray(filterValues[FilterTypes.Priority]) ? filterValues[FilterTypes.Priority] : null,
                    [FilterTypes.Date]:
                        filterValues[FilterTypes.Date] && isArray(filterValues[FilterTypes.Date])
                            ? { from: filterValues[FilterTypes.Date].from && moment(filterValues[FilterTypes.Date].from), to: filterValues[FilterTypes.Date].to && moment(filterValues[FilterTypes.Date].to) }
                            : null,
                    [FilterTypes.CommissionId]: isArray(filterValues[FilterTypes.CommissionId]) ? filterValues[FilterTypes.CommissionId] : null,
                    [FilterTypes.UserId]: isArray(filterValues[FilterTypes.UserId]) ? filterValues[FilterTypes.UserId] : null,
                    [FilterTypes.AutorId]: isArray(filterValues[FilterTypes.AutorId]) ? filterValues[FilterTypes.AutorId] : null,
                    [FilterTypes.EndDate]: null,
                    [FilterTypes.Persons]: null,
                    [FilterTypes.EventType]: null,
                    [FilterTypes.Milestones]: null,
                });
            });
    }
    ngOnDestroy() {
        this.onDestroy$.next();
    }
    OpenFile(fileId: number) {
        this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }
    SearchButtonClicked() {
        if (!this.SearchBarActive) {
            this.SearchBarActive = true;
            setTimeout(() => this.inputRef?.nativeElement.focus(), 30);
        } else {
            if (this.SearchForm.value.length === 0) {
                this.SearchBarActive = false;
            }
        }
    }
}
