<!--<app-burger-button-wrapper>-->
<!--    <button app-round-button dave-menu-item *ngFor="let button of Buttons; let i = index;">-->
<!--        <fa-icon *ngIf="button.icon" [icon]="button.icon"></fa-icon>{{ button.icon ? '' : button.content }}-->
<!--    </button>-->
<!--    <button app-round-button aria-label="LABEL works !" (click)="alert('TUT')"><fa-icon [icon]="'paper-plane'"></fa-icon></button>-->
<!--</app-burger-button-wrapper>-->
<!--<button (click)="Buttons.push({content: 'new'})">-->
<!--    +-->
<!--</button>-->
<!--<button (click)="Buttons.pop()">-->
<!--    - -->
<!--</button>-->
<div class="wrapper">
    <ng-container *ngIf="Verification === null">
        <div *ngIf="LoggedIn && !NewPasswordDialog && !(IsLoading$ | async)" class="logout">
            <mat-card>
                Sie sind als {{ GetUserName() }} angemeldet<br />
                <br />
                <mat-card-actions class="button-wrapper">
                    <button app-round-button matTooltip="Ausloggen" (click)="Logout()">
                        <fa-icon icon="sign-out-alt"></fa-icon>
                    </button>
                    <button app-round-button matTooltip="Passwort ändern" (click)="ToggleNewPasswordDialog(true)">
                        <fa-icon icon="exchange-alt"></fa-icon>
                    </button>
                    <a app-round-button matTooltip="Zum Dashboard" [routerLink]="'/'">
                        <fa-icon icon="home"></fa-icon>
                    </a>
                </mat-card-actions>
            </mat-card>
        </div>

        <!--Passwort ändern-->
        <div class="formDialog" *ngIf="LoggedIn && NewPasswordDialog">
            <div class="form">
                <mat-card>
                    <h2>Passwort ändern</h2>
                    <form [formGroup]="NewPasswordFormGroup" (ngSubmit)="ChangePassword()">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="key"></fa-icon>
                                Altes Passwort</mat-label
                            >
                            <input matInput type="password" formControlName="oldPassword" autocomplete="current-password" />
                            <mat-error *ngIf="OldPasswordForm.errors">
                                {{ GetFormErrorMessage(OldPasswordForm) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="key"></fa-icon>
                                Neues Passwort</mat-label
                            >
                            <input matInput type="password" formControlName="newPassword" autocomplete="new-password" />
                            <mat-error *ngIf="NewPasswordForm.errors">
                                {{ GetFormErrorMessage(NewPasswordForm) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="key"></fa-icon>
                                Neues Passwort bestätigen</mat-label
                            >
                            <input matInput type="password" formControlName="confirmNewPassword" autocomplete="new-password" />
                            <mat-error *ngIf="NewPasswordFormGroup.hasError('passwordsDoNotMatch')">
                                Passwörter stimmen nicht überein!
                            </mat-error>
                            <mat-error *ngIf="ConfirmNewPasswordForm.errors">
                                {{ GetFormErrorMessage(ConfirmNewPasswordForm) }}
                            </mat-error>
                        </mat-form-field>
                        <div class="buttons">
                            <button app-round-button Inverted class="action-button" matTooltip="Abbrechen" type="button" (click)="ToggleNewPasswordDialog(false)">
                                <fa-icon icon="times"></fa-icon>
                            </button>

                            <button app-round-button class="action-button" matTooltip="Passwort ändern" type="submit" [Disabled]="NewPasswordFormGroup.invalid">
                                <fa-icon icon="sign-in-alt"></fa-icon>
                            </button>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>

        <!--Login-->
        <div class="formDialog" *ngIf="!LoggedIn && !ResetPasswordDialog">
            <div class="form">
                <mat-card>
                    <h2>Login</h2>
                    <form [formGroup]="LoginFormGroup" (ngSubmit)="LogIn()" id="login">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="user"></fa-icon>
                                E-Mail</mat-label
                            >
                            <input matInput type="email" name="username" id="username" formControlName="username" autocomplete="username" />
                            <mat-error *ngIf="UsernameForm.errors">
                                {{ GetFormErrorMessage(UsernameForm) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="key"></fa-icon>
                                Passwort</mat-label
                            >
                            <button matSuffix mat-icon-button type="button" (click)="HidePassword = !HidePassword">
                                <fa-icon [icon]="HidePassword ? 'eye-slash' : 'eye'"></fa-icon>
                            </button>
                            <input matInput [type]="HidePassword ? 'password' : 'text'" formControlName="password" name="password" id="password" autocomplete="current-password" />
                            <mat-error *ngIf="PasswordForm.errors">
                                {{ GetFormErrorMessage(PasswordForm) }}
                            </mat-error>
                        </mat-form-field>
                        <div class="buttons">
                            <a (click)="ToggleResetDialog(true)">Passwort vergessen</a>

                            <button app-round-button class="action-button" matTooltip="Einloggen" type="submit" [Disabled]="LoginFormGroup.invalid">
                                <fa-icon icon="sign-in-alt"></fa-icon>
                            </button>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>

        <!--Passwort zurücksetzen-->
        <div class="formDialog" *ngIf="!LoggedIn && ResetPasswordDialog">
            <div class="form">
                <mat-card>
                    <h2>Passwort zurücksetzen</h2>
                    <form [formGroup]="ResetPasswordFormGroup" (ngSubmit)="ResetPassword()">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="user"></fa-icon>
                                E-Mail</mat-label
                            >
                            <input matInput type="text" formControlName="usernameReset" autocomplete="username" />
                            <mat-error *ngIf="UsernameResetForm.errors">
                                {{ GetFormErrorMessage(UsernameResetForm) }}
                            </mat-error>
                        </mat-form-field>
                        <div class="buttons">
                            <div class="cancel-button-wrapper">
                                <button app-round-button Inverted class="action-button" matTooltip="Abbrechen" type="button" (click)="ToggleResetDialog(false)">
                                    <fa-icon icon="times"></fa-icon>
                                </button>
                            </div>
                            <button app-round-button class="action-button" matTooltip="Passwort zurücksetzen" type="submit" [Disabled]="ResetPasswordFormGroup.invalid">
                                <fa-icon icon="envelope"></fa-icon>
                            </button>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="Verification !== null">
        <div class="formDialog">
            <h2>Hallo {{ Verification.Name }}, legen Sie Ihr Passwort fest um Ihr Konto zu aktivieren.</h2>
            <div class="form">
                <mat-card>
                    <form [formGroup]="ActivateUserFormGroup" (ngSubmit)="ActivateUser()">
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="key"></fa-icon>
                                Neues Passwort</mat-label
                            >
                            <input matInput type="password" formControlName="newPassword" autocomplete="new-password" />
                            <mat-error *ngIf="ActivateUserNewPasswordForm.errors">
                                {{ GetFormErrorMessage(ActivateUserNewPasswordForm) }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>
                                <fa-icon icon="key"></fa-icon>
                                Neues Passwort bestätigen</mat-label
                            >
                            <input matInput type="password" formControlName="repeatNewPassword" autocomplete="new-password" />
                            <mat-error *ngIf="RepeatNewPasswordForm.errors">
                                {{ GetFormErrorMessage(RepeatNewPasswordForm) }}
                            </mat-error>
                        </mat-form-field>
                        <div class="buttons">
                            <button app-round-button class="action-button" matTooltip="Konto aktivieren" type="submit" [Disabled]="ActivateUserFormGroup.invalid">
                                <fa-icon icon="sign-in-alt"></fa-icon>
                            </button>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </ng-container>

    <div *ngIf="IsLoading$ | async" class="loading-page">
        <app-loading></app-loading>
    </div>
</div>
