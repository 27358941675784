<div class="container">
    <!--    Card Buttons-->
    <div class="button-wrapper" style="">
        <div *ngFor="let Card of QuickNavigateCategories$ | async; let i = index" class="theme-override" [class]="Card.cssClass">
            <button mat-flat-button class="button" [ngClass]="(selectedType$ | async) === Card.Type ? 'button-selected' : ''" (click)="selectedType$.next(Card.Type)" color="primary-lighter">
                <div class="button-content">
                    <fa-icon class="icon" size="2x" [icon]="Card.Image"></fa-icon>
                    <span class="label">{{ Card.Title }}</span>
                </div>
            </button>
        </div>
    </div>
    <!-- Search Field -->
    <input type="search" class="search-field" placeholder="{{ selectedTypeLabel$ | async }} durchsuchen" #searchString (keyup)="DoSearch(searchString.value)" />
    <!-- Expandable Folder List -->
    <div #foldersWrapperScrollbar class="scroll-wrapper-one">
        <mat-accordion #accordion multi="false" *ngIf="pagedQuickNavigationFolders$ | async as folders; else loading">
            <mat-expansion-panel #panel *ngFor="let folder of folders">
                <mat-expansion-panel-header collapsedHeight="48">
                    <mat-panel-title>{{ folder.title }}</mat-panel-title>
                    <mat-panel-description *ngIf="folder.description">{{ folder.description }}</mat-panel-description>
                    <button app-round-button style="margin-right: 0.5rem" matTooltip="Ordner erstellen" aria-label="Ordner erstellen" *ngIf="panel.expanded && folder.rootFolderId$ | async as root" (click)="createChildFolder(root); $event.stopPropagation()">
                        <fa-icon class="icon" icon="plus"></fa-icon>
                    </button>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <!--                        {{ folder.Id }}-->
                    <ng-container *ngIf="panel.expanded">
                        <app-select-folder
                            *ngIf="(folder.rootFolderId$ | async) != null; else noFolder"
                            [dataSource]="treeDataSource"
                            (SelectedFolderId)="onFolderSelect($event)"
                            [matTreeNodePaddingIndent]="Data.matTreeNodePaddingIndent || 40"
                            [rootFolderId]="folder.rootFolderId$ | async"
                            [SourceFolderId]="folder.rootFolderId$ | async"
                            [disabledFolderIds]="disabledFolderIds"
                        ></app-select-folder>
                        <ng-template #noFolder>
                            <p>Kein Ordner vorhanden</p>
                        </ng-template>
                    </ng-container>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <mat-paginator #paginator [length]="(QuickNavigationFolders$ | async)?.length" [pageSize]="initialPageSize" [pageSizeOptions]="[50, 100, 250]" (page)="page$.next($event)" aria-label="Seite auswählen"> </mat-paginator>
    <!-- File Upload -->
    <div class="scroll-wrapper-two" *ngIf="Data.uploadMode">
        <app-new-document-view-component
            [acceptedFiles]="Data.acceptedFiles"
            [maxFiles]="Data.maxFilesUpload"
            #newDocumentViewComponent
            [ShowSaveButton]="false"
            [SynchronUpload]="SynchronUpload"
            [FolderId]="selectedFolderId"
            class="new-document-view"
        ></app-new-document-view-component>
    </div>
</div>
<mat-dialog-actions>
    <div class="cancel-button-wrapper">
        <button app-button Inverted mat-dialog-close="">abbrechen</button>
    </div>
    <button app-button [Disabled]="selectedFolderId === null" (click)="Submit()">{{ Data.ButtonText || 'verschieben' }}</button>
</mat-dialog-actions>
<ng-template #loading><app-loading></app-loading></ng-template>
