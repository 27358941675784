import { GetTimeFromTimestamp, MaterialToSupplier } from '@dave/types';


export class MaterialToSupplierEntity {
    constructor(
        public MaterialId: number = null,
        public SupplierId: number = null,
        public Cost: number = null,
        public SellPrice: number = null,
        public QuantityTypeId: number = null,
        public ArticleNumber: string = null,
        public MaterialGroupId: number = null,
        public PartnerId: number = null,
        public UserId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,
    ) {}

    public static EntityName = 'MaterialToSupplier';
    public Clone(override: Partial<MaterialToSupplierEntity> = {}): MaterialToSupplierEntity {
        const attr = { ...this, ...override };
        return Object.assign(new MaterialToSupplierEntity(), attr);
    }

}

export function MaterialToSupplierEntityFromBackend(res: MaterialToSupplier): MaterialToSupplierEntity {
    return new MaterialToSupplierEntity(
        res.MaterialId && +res.MaterialId,
        res.SupplierId && +res.SupplierId,
        res.Cost && +res.Cost,
        res.SellPrice && +res.SellPrice,
        res.QuantityTypeId && +res.QuantityTypeId,
        res.ArticleNumber,
        res.MaterialGroupId && +res.MaterialGroupId,
        res.PartnerId && +res.PartnerId,
        res.UserId && +res.UserId,
        res.CreatedAt && GetTimeFromTimestamp(+res.CreatedAt),
        res.UpdatedAt && GetTimeFromTimestamp(+res.UpdatedAt),
        res.DeletedAt && GetTimeFromTimestamp(+res.DeletedAt),
    );
}
