import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { NewsEntity } from '../../entities/news.entity';
import { NewsActionTypes } from '../actions/news.actions';
export const NEWS_KEY = 'news';

export interface NewsState extends EntityState<NewsEntity> {
    fetched: boolean;
}

const adapter = createEntityAdapter<NewsEntity>({
    selectId: Entity => Entity.Id,
});

const initialState = adapter.getInitialState({
    fetched: false,
});

/* ############# */
/* ## REDUCER ## */
/* ############# */

export const newsReducer = createReducer(
    initialState,
        on(NewsActionTypes.UpdateAll, (state, { Payload }) => adapter.setAll(Payload, { ...state, fetched: true })),
        on(NewsActionTypes.SetNewsSeenSuccess, (state, { Payload }) =>adapter.setMany(Payload, { ...state })),
        on(NewsActionTypes.UpdateOne, (state, { Payload }) => adapter.setOne(Payload, { ...state })),
        on(NewsActionTypes.RemoveOne, (state, { Payload }) => adapter.removeOne(Payload, { ...state })),
);

/* ############### */
/* ## SELECTORS ## */
/* ############### */

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllNews = selectAll;
export const selectNewsEntities = selectEntities;

export const selectNewsFetched = (state: NewsState) => state.fetched;
