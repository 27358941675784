<div #treeWrapperScrollbar class="scroll-wrapper">
    <mat-tree *ngIf="filteredDataSource as ds" [dataSource]="ds" [treeControl]="this.treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <!--        <mat-tree-node-->
        <!--            class="explorer-tree-node"-->
        <!--            [class.disabled-node]="node.disabled"-->
        <!--            [class.selected]="(TargetFolderId$ | async) === node.item.entityId"-->
        <!--            [matTreeNodePaddingIndent]="matTreeNodePaddingIndent"-->
        <!--            *matTreeNodeDef="let node"-->
        <!--            matTreeNodePadding-->
        <!--            (click)="ClickNode(node)"-->
        <!--        >-->
        <!--            &lt;!&ndash; use a disabled button to provide padding for tree leaf &ndash;&gt;-->
        <!--            <button mat-icon-button disabled></button>-->
        <!--            <fa-icon [icon]="node.item.isFolder ? 'folder' : 'file'" style="margin-right: 0.5rem"></fa-icon>{{ node.item.name }}-->
        <!--            <button app-round-button class="folder-button" style="margin-right:.5rem" *ngIf="(TargetFolderId$ | async) === node.item.entityId" (click)="createChildFolder(node.item.entityId); $event.stopPropagation()">-->
        <!--                <fa-icon class="icon" icon="plus"></fa-icon>-->
        <!--            </button>-->
        <!--        </mat-tree-node>-->
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node
            class="explorer-tree-node"
            [class.disabled-node]="node.disabled"
            [class.selected]="(TargetFolderId$ | async) === node.item.entityId"
            [matTreeNodePaddingIndent]="matTreeNodePaddingIndent"
            *matTreeNodeDef="let node"
            matTreeNodePadding
            (click)="ClickNode(node)"
        >
            <div class="toggle-placeholder">
                <button mat-icon-button matTreeNodeToggle [disabled]="node.disabled" [attr.aria-label]="'toggle ' + (node.item.name$ | async)" *ngIf="node.expandable">
                    <fa-icon [icon]="this.treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"> </fa-icon>
                </button>
            </div>
            <fa-icon [icon]="node.item.isFolder ? 'folder' : 'file'" style="margin-right: 0.5rem"></fa-icon>
            {{ node.item.name$ | async }}

            <button app-round-button class="folder-button" style="margin-right: 0.5rem" matTooltip="Ordner erstellen" aria-label="Ordner erstellen" *ngIf="(TargetFolderId$ | async) === node.item.entityId" (click)="createChildFolder(node.item.entityId); $event.stopPropagation()">
                <fa-icon class="icon" icon="plus"></fa-icon>
            </button>
            <mat-progress-bar mode="indeterminate" class="progress-bar" *ngIf="node.isLoading"></mat-progress-bar>
        </mat-tree-node>
    </mat-tree>
</div>
