import { createAction, props } from '@ngrx/store';
import { FolderEntity } from '../../entities/folder.entity';
import { DaveMutationChangeFoldersArgs, DaveMutationCreateFoldersArgs } from '../../graphql-types';

export const FolderActionTypes = {
    Load: createAction('data/Folder/getAll', props<{ Payload?: { updatedSince?: string } }>()),
    LoadFoldersFromFolder: createAction('data/Folder/getFromFolder', props<{ Payload: number }>()),
    DeleteFolder: createAction('data/Folder/delete', props<{ Payload: number }>()),
    ModifyFolder: createAction('data/Folder/modify', props<{ Payload: DaveMutationChangeFoldersArgs }>()),
    AddFolder: createAction('data/Folder/add', props<{ Payload: DaveMutationCreateFoldersArgs }>()),
    UpdateAll: createAction('data/Folder/updateAll', props<{ Payload: Array<FolderEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateMany: createAction('data/Folder/updateMany', props<{ Payload: Array<FolderEntity>, updateLatestUpdatedAt?: boolean }>()),
    UpdateOne: createAction('data/Folder/updateOne', props<{ Payload: FolderEntity }>()),
    RemoveOne: createAction('data/Folder/removeOne', props<{ Payload: number }>()),
    Poll: createAction('data/Folder/poll'),
};
