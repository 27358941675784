import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    selectAllUserToCommission, selectUserToCommissionCommissionIdsMap,
    selectUserToCommissionEntities,
    selectUserToCommissionFetched, selectUserToCommissionLatestUpdatedAt, USER_TO_COMMISSION_KEY,
    UserToCommissionState,
} from '../reducers/user-to-commission.reducer';


const selectState = createFeatureSelector< UserToCommissionState>(USER_TO_COMMISSION_KEY);
export const getUserToCommissionDictionary = createSelector(selectState, selectUserToCommissionEntities);

export const getUserToCommissions = createSelector(selectState, selectAllUserToCommission);
export const getUserToCommissionFetched = createSelector(selectState, selectUserToCommissionFetched);
export const getUserToCommissionLatestUpdatedAt = createSelector(selectState, selectUserToCommissionLatestUpdatedAt);
export const getUserToCommissionCommissionIdsMap = createSelector(selectState, selectUserToCommissionCommissionIdsMap);
export const getUserToCommissionById = (props: { id: number }) => createSelector(getUserToCommissionDictionary, (dictionary) => props?.id && dictionary[props.id]);
export const getUserToCommissionByCommissionId = (props: { commissionId: number }) => createSelector(getUserToCommissionCommissionIdsMap, getUserToCommissionDictionary, (cIdU2c, dictionary ) => props?.commissionId && cIdU2c.get(props.commissionId).map(id => dictionary[id]));
