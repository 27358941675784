import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Subject, Subscription, switchMap } from 'rxjs';
import { filter, map, skip } from 'rxjs/operators';
import { LedgerImportDocumentTypeNames, LedgerImportDocumentTypes, LedgerImportEntity } from '../../../dave-data-module/entities/ledger-import.entity';
import { ProductNames } from '../../../dave-data-module/entities/license.entity';
import { UserRoles } from '../../../dave-data-module/entities/user.entity';
import { LedgerImportResolver } from '../../../dave-data-module/guards/ledger-import.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { AccountsReceivableLedgerActionTypes, AddLedgerImportWithDefaultsPayload, LedgerImportActionTypes } from '../../../dave-data-module/State/actions/accounting.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { getLedgerImports, getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getPartner, getPartnerFetched } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getLicenses, getUserProducts } from '../../../dave-data-module/State/selectors/products.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { AllReportsMeta, InvoiceEditorMeta, ReportsPageMeta } from '../../../helper/page-metadata';
import { LoadingService } from '../../../services/loading.service';
import { SelectLedgerImportDialogComponent } from '../select-ledger-import-dialog/select-ledger-import-dialog.component';

export interface NewReportDialogComponentDialogData {
    DefaultValues: Partial<AddLedgerImportWithDefaultsPayload>;
}
@Component({
    selector: 'app-new-report-dialog',
    templateUrl: './new-report-dialog.component.html',
    styleUrls: ['./new-report-dialog.component.scss'],
    providers: [DatePipe],
})
export class NewReportDialogComponent implements OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        autoFocus: false,
        maxWidth: '50rem',
        width: '90%',
        height: '90%',
    };
    public ReportsIcon = ReportsPageMeta.Icon as IconProp;
    public LedgerImportDocumentTypeNames = LedgerImportDocumentTypeNames;
    public LedgerImportDocumentTypes = Object.values(LedgerImportDocumentTypes);
    public CreateDocumentFromType$ = new Subject<LedgerImportDocumentTypes | ''>();
    public SelectedDocumentType$ = new BehaviorSubject<LedgerImportDocumentTypes | ''>(this.LedgerImportDocumentTypes[0]);
    public SelectedTemplate$ = new BehaviorSubject<LedgerImportEntity | null>(null);
    public LITemplates$ = combineLatest([
        this.store.select(getLedgerImportsFetched).pipe(
            filter((fetched) => fetched),
            switchMap(() => this.store.select(getLedgerImports)),
        ),
        this.SelectedDocumentType$,
    ]).pipe(map(([lis, docType]) => lis.filter((l) => l.IsTemplate /* && l.DocumentType === docType */)));
    public LITemplatesTableData$ = this.LITemplates$.pipe(
        map((lis) => [
            { ConsolidatedInvoiceId: 'Ohne Vorlage fortfahren', NoTemplate: true },
            ...lis.map((li) => ({
                ...li,
                DisplayType: LedgerImportDocumentTypeNames.get(li.DocumentType),
                DisplayCreatedAt: this.datePipe.transform(li.CreatedAt, 'dd.MM.yy') + ', ' + this.datePipe.transform(li.CreatedAt, 'mediumTime'),
                suffixButton: {
                    icon: 'pencil-alt' as IconProp,
                    routerLink: (li: LedgerImportEntity) => ['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, li.Id],
                    tooltip: 'Bearbeiten',
                },
            })),
        ]),
    );
    private subs: Subscription[] = [];
    public HasAccountingLicense$ = combineLatest([this.store.select(getUserProducts), this.store.select(getLicenses)]).pipe(
        map(([userProducts, licenses]) => {
            const license = licenses.find((l) => l.ProductName === ProductNames.Accounting);
            const userProduct = userProducts.find((l) => l.Name === ProductNames.Accounting);
            return isNotNullOrUndefined(license) && isNotNullOrUndefined(userProduct);
        }),
    );
    public HasAccountingRole$ = combineLatest([this.store.select(getUser)]).pipe(
        map(([user]) => {
            return isNotNullOrUndefined(user.Roles.find((r) => r == UserRoles.AccountingUser || r == UserRoles.AccountingAdmin));
        }),
    );

    constructor(
        private store: Store<State>,
        private router: Router,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private actions$: Actions,
        public LS: LoadingService,
        @Inject(MAT_DIALOG_DATA) protected dialogData: NewReportDialogComponentDialogData,
        liRes: LedgerImportResolver,
        private dialogRef: MatDialogRef<NewReportDialogComponent>,
    ) {
        firstValueFrom(this.store.select(getLedgerImportsFetched)).then((fetched) => {
            if (!fetched) {
                liRes.resolve();
            }
        });
        if (dialogData?.DefaultValues?.documentType) {
            this.SelectedDocumentType$.next(dialogData.DefaultValues.documentType);
        } else {
            firstValueFrom(getFetched$(this.store, getPartnerFetched, getPartner)).then((partner) => {
                const defaultDocType = isNotNullOrUndefined(partner.AdditionalData?.defaultLedgerImportDocumentType) ? partner.AdditionalData?.defaultLedgerImportDocumentType : LedgerImportDocumentTypes.Invoice;
                if (defaultDocType !== this.SelectedDocumentType$.value) {
                    this.SelectedDocumentType$.next(defaultDocType);
                }
            });
        }

        this.subs.push(
            this.CreateDocumentFromType$.asObservable()
                .pipe(
                    filter((type) => !!type),
                    // concatMap(),
                )
                .subscribe((res) => {
                    this.LS.startLoading('app-new-report-dialog_CreateDocumentFromType');
                    if (res === LedgerImportDocumentTypes.PartialInvoice || res === LedgerImportDocumentTypes.ClosingInvoice) {
                        this.dialog
                            .open(SelectLedgerImportDialogComponent)
                            .afterClosed()
                            .subscribe((ledgerImportId) => {
                                if (ledgerImportId != null) {
                                    this.store.dispatch(
                                        LedgerImportActionTypes.AddLedgerImportWithDefaults({
                                            Payload: {
                                                accountsReceivableLedgerIds: [],
                                                ...(dialogData?.DefaultValues || {}),
                                                documentType: res as LedgerImportDocumentTypes,
                                                ledgerImportId: ledgerImportId > 0 ? ledgerImportId : null,
                                            },
                                        }),
                                    );
                                    firstValueFrom(this.store.select(getLedgerImports).pipe(skip(1))).then((li) => {
                                        if (li) {
                                            const newGenFileNo = li.reduce((highestId, entity) => (entity.Id > highestId ? entity.Id : highestId), -1);
                                            // this.router.navigate([InvoiceEditorMeta.Path, newGenFileNo], {
                                            //     relativeTo: route,
                                            // });
                                            this.LS.endLoading('app-new-report-dialog_CreateDocumentFromType')
                                            this.router.navigate(['/rechnungen_und_angebote/alle/rechnungseditor', newGenFileNo]);
                                            this.dialogRef.close('submit');
                                        }
                                    });
                                }
                            });
                    } else {
                        this.store.dispatch(
                            LedgerImportActionTypes.AddLedgerImportWithDefaults({
                                Payload: {
                                    accountsReceivableLedgerIds: [],
                                    ...(dialogData?.DefaultValues || {}),
                                    documentType: res as LedgerImportDocumentTypes,
                                },
                            }),
                        );
                        firstValueFrom(this.store.select(getLedgerImports).pipe(skip(1))).then((li) => {
                            if (li) {
                                const newGenFileNo = li.reduce((highestId, entity) => (entity.Id > highestId ? entity.Id : highestId), -1);
                                // this.router.navigate([InvoiceEditorMeta.Path, newGenFileNo], {
                                //     relativeTo: route,
                                // });
                                this.LS.endLoading('app-new-report-dialog_CreateDocumentFromType')
                                this.router.navigate(['/rechnungen_und_angebote/alle/rechnungseditor', newGenFileNo]);
                                this.dialogRef.close('submit');
                            }
                        });
                    }
                }),
        );
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
    CreateDocumentFromTemplate(res: LedgerImportEntity) {
        this.LS.startLoading('addLedgerImport');
        firstValueFrom(this.actions$.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, BaseActionTypes.ErrorAction))).then((val) => {
            if (val.type === AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger.type) {
                firstValueFrom(this.store.select(getLedgerImports)).then((li) => {
                    const newGenFileNo = li.reduce((highestId, entity) => (entity.Id > highestId ? entity.Id : highestId), -1);
                    this.LS.endLoading('addLedgerImport');
                    this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, newGenFileNo]);
                    this.dialogRef.close('submit');
                });
            } else {
                this.LS.endLoading('addLedgerImport');
            }
        });
        this.store.dispatch(
            LedgerImportActionTypes.CreateLedgerImportFromLedgerImport({
                Payload: {
                    rootId: res.Id,
                    customerId: this.dialogData.DefaultValues?.customerId || (this.dialogData.DefaultValues?.commissionId ? null : undefined),
                    commissionId: this.dialogData.DefaultValues?.commissionId || undefined,
                    isTemplate: false,
                    documentType: res.DocumentType,
                },
            }),
        );
    }
}
