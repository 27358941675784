import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from "@angular/material/dialog";
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { EmployeeEntity } from '../../../dave-data-module/entities/employee.entity';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getEmployeesActive, getEmployeesFetched } from '../../../dave-data-module/State/selectors/employees.selectors';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from "../../../helper/helper";
import { arrayLengthValidator } from "../../../helper/validation.helper";
import { LoadingService } from "../../../services/loading.service";
import { BaseActionTypes } from "../../../dave-data-module/State/actions/base.actions";

export enum ClockInExportType {
    all,
    hourList,
}
export interface CsvExportDialogComponentDialogData {
    exportType: ClockInExportType;
}
@Component({
    selector: 'app-csv-export-dialog',
    templateUrl: './csv-export-dialog.component.html',
    styleUrls: ['./csv-export-dialog.component.scss'],
    providers: [DatePipe],
})
export class CsvExportDialogComponent {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        maxWidth: '100%',
        width: '30%',
        minWidth: '320px',
        maxHeight: 'calc(100vh - 3.5rem)',
    }
    protected initialised$ = new BehaviorSubject(false);
    public Form = new FormGroup({
        FromDate: new FormControl<Moment>(moment(), [Validators.required]),
        ToDate: new FormControl<Moment>(moment(), [Validators.required]),
        Employees: new FormControl<EmployeeEntity[]>([], [arrayLengthValidator(1)]),
    });
    TemplateData: IDetailListTemplateData = {
        Properties: [
            {
                key: 'Zeitraum',
                formControl: this.Form,
                options: {
                    specialInput: {
                        timeSpan: {
                            formControlFrom: this.Form.controls.FromDate,
                            formControlTo: this.Form.controls.ToDate,
                        },
                    },
                },
            },
            {
                key: 'Mitarbeiter',
                formControl: this.Form.controls.Employees,
                options: {
                    specialInput: {
                        chipAutocomplete: {
                            MapFn: (option: EmployeeEntity) => option.DisplayName,
                            CompareFn: (a: EmployeeEntity, b: EmployeeEntity) => a.Id === b.Id,
                            Options$: this.store.select(getEmployeesActive),
                            initialPatchDefaultValue: true,
                        },
                    },
                },
            },
        ],
    };
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public DialogData: CsvExportDialogComponentDialogData,
        private store: Store<State>,
        private httpService: HttpService,
        private datePipe: DatePipe,
        private dialogRef: MatDialogRef<CsvExportDialogComponent>,
        protected ls: LoadingService,
        employeeResolver: EmployeeResolver,
    ) {
        firstValueFrom(getFetched$(store, getEmployeesFetched, getEmployeesActive, employeeResolver)).then((employees) => {
            this.Form.controls.Employees.reset(employees);
            this.initialised$.next(true);
        });
    }
    Export() {
        this.ls.startLoading('export-clock-ins-csv');
        this.store
            .select(getToken)
            .pipe(filter(isNotNullOrUndefined), take(1))
            .subscribe((token) => {
                const documentData = {
                    FromDate: FrontendDate(this.Form.value.FromDate.toDate(), true),
                    ToDate: FrontendDate(this.Form.value.ToDate.toDate(), true),
                    EmployeeIds: this.Form.value.Employees.map(e => e.Id),
                };
                const httpRequest = new XMLHttpRequest();
                httpRequest.open('Post', this.httpService.GetUrl('Files/', 'gateway') + 'ClockIn/ExportCSV/' + (this.DialogData.exportType === ClockInExportType.all ? 'All' : 'HourList'), true);
                httpRequest.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                httpRequest.setRequestHeader('Authorization', 'Bearer ' + token);
                httpRequest.responseType = 'blob';
                httpRequest.onreadystatechange = () => {
                    if (httpRequest.readyState === 4) {
                        this.ls.endLoading('export-clock-ins-csv');
                        if (httpRequest.status === 200 || httpRequest.status === 304) {
                            saveAs(httpRequest.response, 'Arbeitszeitexport-' + this.datePipe.transform(Date.now(), 'yyyy-MM-dd') + (this.DialogData.exportType === ClockInExportType.all ? '.csv' : '.zip'));
                            this.dialogRef.close();
                        } else {
                            this.store.dispatch(BaseActionTypes.ErrorAction({Payload: {ToasterMessage: 'Zeiten exportieren fehlgeschlagen.'}}))
                        }
                    }
                };
                httpRequest.send(JSON.stringify(documentData));
            });
    }
}
