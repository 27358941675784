import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subscription, switchMap } from 'rxjs';
import { delay, distinctUntilChanged, filter, first, map, shareReplay, skip, takeUntil, tap } from 'rxjs/operators';
import { NewDocumentViewComponent } from '../../../components/templates/new-document-view/component/new-document-view.component';
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderEntity, FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { State } from '../../../dave-data-module/State';
import { getCommissions, getCommissionsFetched } from '../../../dave-data-module/State/selectors/commission.selector';
import {
    getCustomers,
    getCustomersFetched,
    getNotDeletedCustomers,
} from '../../../dave-data-module/State/selectors/customers.selectors';
import {
    getEmployees,
    getEmployeesActive,
    getEmployeesFetched,
} from '../../../dave-data-module/State/selectors/employees.selectors';
import { getResources, getResourcesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { appMatDialogDefaultConfig, isNotNullOrUndefined, stringSearch } from '../../../helper/helper';
import { CommissionMeta, CustomerAdministrationMeta, DMSPageMeta, ResourcePageMeta, UserAdministrationMeta } from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { DynamicFolderTreeDataService, DynamicFolderTreeFlatNode } from '../../dynamic-folder-tree-data.service';
import { FolderTreeControl, sortByParent } from '../file-explorer/folder-tree-control';
import { DynamicFolderTreeDataSource } from '../file-explorer/folder-tree-datasource';
import { NewFolderDialogComponent, NewFolderDialogComponentDialogData } from '../new-folder-dialog/new-folder-dialog.component';

type NavigationFolder = { Id: number; Name: string; CreatedAt: Date };
export type SelectFolderDialogComponentDialogReturnData = {
    folderId: number;
    synchronUploadedDocuments?: FileEntity[];
};
export interface SelectFolderDialogComponentDialogData {
    ButtonText?: string;
    folderId?: number | null;
    matTreeNodePaddingIndent: number;
    uploadMode?: boolean;
    synchronUpload?: boolean;
    message?: string;
    maxFilesUpload?: number;
    acceptedFiles?: string;
    disabledFolderIds?: number[];
}
export type QuickNavigationFoldersData = {
    Title: string;
    Image: IconProp;
    cssClass: string;
    // data$: Observable<{title: string, description: string, rootFolder$: Observable<FolderEntity[]> }[]>
    Type: 'commission' | 'customer' | 'resource' | 'employee' | 'dms';
};

const initialPageEvent = new PageEvent();
initialPageEvent.pageSize = 50;
initialPageEvent.pageIndex = 0;
initialPageEvent.previousPageIndex = 0;
@Component({
    selector: 'app-select-folder-dialog',
    templateUrl: './select-folder-dialog.component.html',
    styleUrls: ['./select-folder-dialog.component.scss'],
})
export class SelectFolderDialogComponent implements AfterViewInit, OnDestroy {
    @ViewChild('foldersWrapperScrollbar') private foldersWrapperScrollbar?: ElementRef;
    @ViewChild('accordion', { static: false, read: MatAccordion }) accordion: MatAccordion;
    @ViewChildren('panel', { read: MatExpansionPanel }) panels: QueryList<MatExpansionPanel>;
    @ViewChild('newDocumentViewComponent') private newDocumentViewComponent: NewDocumentViewComponent;
    protected dynamicTreeControl: FolderTreeControl;
    protected treeDataSource: DynamicFolderTreeDataSource;

    @Input() set TreeDataSource(value: DynamicFolderTreeDataSource) {
        this.treeDataSource = value;
    }

    @Input() SynchronUpload = false;
    private searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    // Available filter categories for quick navigation
    private commissionData$ = getFetched$(this.store, getCommissionsFetched, getCommissions, this.commissionRes).pipe(
        map((commissions) =>
            commissions
                .filter((c) => !c.Deleted)
                .map((c) => ({
                    title: c.DisplayName,
                    entityId: c.Id,
                    folderType: FolderTypes.commission,
                    description: '',
                    searchStrings: [c.Description, c.InterneNummer, c.Auftragsnummer].filter(isNotNullOrUndefined),
                    rootFolderId$: this.folderDataService.getFolderFromEntity(c.Id, FolderTypes.commission).pipe(
                        filter(isNotNullOrUndefined),
                        map((f) => f.Id),
                        distinctUntilChanged(),
                        shareReplay({ refCount: true, bufferSize: 1 }),
                    ),
                })).sort((a, b) => (a.title || '').localeCompare(b.title || '')),
        ),
    );
    private customerData$ = getFetched$(this.store, getCustomersFetched, getNotDeletedCustomers, this.customerRes).pipe(
        map((customers) =>
            customers.map((c) => ({
                title: c.DisplayInformation,
                description: c.Description,
                entityId: c.Id,
                folderType: FolderTypes.customer,
                searchStrings: [c.Description, c.Name, c.CustomerNo].filter(isNotNullOrUndefined),
                rootFolderId$: this.folderDataService.getFolderFromEntity(c.Id, FolderTypes.customer).pipe(
                    filter(isNotNullOrUndefined),
                    map((f) => f.Id),
                    distinctUntilChanged(),
                    shareReplay({ refCount: true, bufferSize: 1 }),
                ),
            })).sort((a, b) => (a.title || '').localeCompare(b.title || '')),
        ),
    );
    private employeeData$ = getFetched$(this.store, getEmployeesFetched, getEmployeesActive, this.employeeRes).pipe(
        map((employees) =>
            employees.map((e) => ({
                title: e.DisplayName,
                description: e.Email,
                folderType: FolderTypes.employee,
                entityId: e.Id,
                searchStrings: [e.Firstname, e.Lastname, e.Email].filter(isNotNullOrUndefined),
                rootFolderId$: this.folderDataService.getFolderFromEntity(e.Id, FolderTypes.employee).pipe(
                    filter(isNotNullOrUndefined),
                    map((f) => f.Id),
                    distinctUntilChanged(),
                    shareReplay({ refCount: true, bufferSize: 1 }),
                ),
            })).sort((a, b) => (a.title || '').localeCompare(b.title || '')),
        ),
    );
    private resourceData$ = getFetched$(this.store, getResourcesFetched, getResources, this.resourceRes).pipe(
        map((resources) =>
            resources.map((r) => ({
                title: r.DisplayName,
                description: r.Manufacturer,
                entityId: r.Id,
                folderType: FolderTypes.resource,
                searchStrings: [r.ArticleNumber, r.Name, r.Manufacturer].filter(isNotNullOrUndefined),
                rootFolderId$: this.folderDataService.getFolderFromEntity(r.Id, FolderTypes.resource).pipe(
                    filter(isNotNullOrUndefined),
                    map((f) => f.Id),
                    distinctUntilChanged(),
                    shareReplay({ refCount: true, bufferSize: 1 }),
                ),
            })).sort((a, b) => (a.title || '').localeCompare(b.title || '')),
        ),
    );
    private dmsData$ = this.folderDataService.getFolderByParent(null).pipe(
        map((rootFolders) => {
            const sortedFolders = rootFolders.sort((a, b) => a.Name.localeCompare(b.Name));
            return sortedFolders.map((f) => ({
                title: f.Name,
                description: '',
                folderType: 'dms_placeholder' as FolderTypes | 'dms_placeholder',
                entityId: f.Id,
                searchStrings: [f.Name].filter(isNotNullOrUndefined),
                rootFolderId$: of(f.Id),
            }));
        }),
    );
    public QuickNavigateCategories$: Observable<QuickNavigationFoldersData[]> = combineLatest([this.cls.getMultiple$('Commission'), this.cls.getMultiple$('Customer')]).pipe(
        map(([commissionLabel, customerLabel]) => [
            {
                Title: customerLabel,
                Image: CustomerAdministrationMeta.Icon,
                Type: 'customer',
                cssClass: CustomerAdministrationMeta.Theme
            },
            { Title: commissionLabel, Image: CommissionMeta.Icon, Type: 'commission', cssClass: CommissionMeta.Theme },
            {
                Title: 'Personal & Urlaub',
                Image: UserAdministrationMeta.Icon,
                Type: 'employee',
                cssClass: UserAdministrationMeta.Theme
            },
            { Title: 'Ressourcen', Image: ResourcePageMeta.Icon, Type: 'resource', cssClass: ResourcePageMeta.Theme },
            { Title: DMSPageMeta.Breadcrumb, Image: DMSPageMeta.Icon, Type: 'dms', cssClass: DMSPageMeta.Theme },
        ]),
    );
    // Active filter category in quick navigation
    public selectedType$ = new BehaviorSubject<'commission' | 'customer' | 'resource' | 'employee' | 'dms'>('commission');
    protected selectedTypeLabel$ = combineLatest([this.QuickNavigateCategories$, this.selectedType$]).pipe(map(([cats, type]) => cats.find((c) => c.Type === type)?.Title));
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        autoFocus: false,
    };
    @ViewChild('paginator') private matPaginator: MatPaginator;

    protected page$ = new BehaviorSubject<PageEvent>(initialPageEvent);
    protected initialPageSize = initialPageEvent.pageSize;
    // All folders of Active filter category
    public QuickNavigationFolders$: Observable<{
        title: string;
        description: string;
        folderType: FolderTypes | 'dms_placeholder';
        entityId: number;
        rootFolderId$: Observable<number | null>
    }[]> = this.selectedType$.pipe(
        distinctUntilChanged(),
        switchMap((type) => {
            switch (type) {
                case 'commission':
                    return this.commissionData$/*.pipe(startWith(null))*/;
                case 'customer':
                    return this.customerData$/*.pipe(startWith(null))*/;
                case 'employee':
                    return this.employeeData$/*.pipe(startWith(null))*/;
                case 'resource':
                    return this.resourceData$/*.pipe(startWith(null))*/;
                case 'dms':
                    return this.dmsData$/*.pipe(startWith(null))*/;
            }
        }),
        switchMap((folders) => this.searchString.pipe(map((searchString) => (searchString ? folders.filter((folder) => folder.searchStrings.some((s) => stringSearch(s, searchString))) : folders)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected pagedQuickNavigationFolders$: typeof this.QuickNavigationFolders$ = this.QuickNavigationFolders$.pipe(
        tap((qnf) => {
            const page = new PageEvent();
            page.pageSize = initialPageEvent.pageSize;
            page.pageIndex = initialPageEvent.pageIndex;
            page.previousPageIndex = initialPageEvent.previousPageIndex;
            page.length = qnf.length;
            this.page$.next(page);
        }),
        tap(() => this.matPaginator?.firstPage()),
        switchMap((value) =>
            this.page$.pipe(
                map((page) => {
                    return value && value.slice(page.pageIndex * page.pageSize, page.pageIndex * page.pageSize + page.pageSize);
                }),
            ),
        ),
    );
    private subscriptions: Subscription[] = [];
    protected selectedFolderId: number;
    protected disabledFolderIds: number[] = [];

    constructor(
        protected bs: BreakpointObserverService,
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public Data: SelectFolderDialogComponentDialogData,
        protected cls: CustomLabelService,
        private dialog: MatDialog,
        private folderDataService: FolderDataService,
        private dialogRef: MatDialogRef<SelectFolderDialogComponent, SelectFolderDialogComponentDialogReturnData>,
        folderTreeDataService: DynamicFolderTreeDataService,
        private commissionRes: CommissionResolver,
        private customerRes: CustomerResolver,
        private employeeRes: EmployeeResolver,
        private resourceRes: ResourceResolver,
        private cdRef: ChangeDetectorRef
    ) {
        // folderResolver.resolve();
        if (Data) {
            this.SynchronUpload = !!Data.synchronUpload;
            this.disabledFolderIds = Data.disabledFolderIds;
        }
        this.dynamicTreeControl = new FolderTreeControl(
            this.folderDataService,
            (node: DynamicFolderTreeFlatNode) => node.level,
            (node: DynamicFolderTreeFlatNode) => node.expandable,
        );
        this.treeDataSource = new DynamicFolderTreeDataSource(this.dynamicTreeControl, folderTreeDataService);
        this.treeDataSource.data = folderTreeDataService.initialData();
    }

    public Path: FolderEntity[] = [];

    ngAfterViewInit(): void {
        if (this.Data.folderId) {
            this.openFolderById(this.Data.folderId);
        }
        setTimeout(() => this.ScrollToLastSelected(), 1);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    public DoSearch(value: string | null) {
        return this.searchString.next(value);
    }

    ScrollToLastSelected() {
        if (!this.foldersWrapperScrollbar) {
            return;
        }
        const scrollelement = this.foldersWrapperScrollbar.nativeElement;
        const tree = this.foldersWrapperScrollbar.nativeElement.children[0]; //.children[0];
        if (scrollelement && tree) {
            const htmlNodes: HTMLCollection = tree.children;
            const nodeArr = Array.from(htmlNodes);
            const lastNode: any = nodeArr
                .slice()
                .reverse()
                .find((c) => c.classList.contains('mat-expanded'));
            if (lastNode) {
                scrollelement.scrollTop = lastNode.offsetTop - 200;
            }
        }
    }

    createChildFolder(rootFolderId: number) {
        this.dialog.open(NewFolderDialogComponent, {
            ...NewFolderDialogComponent.DefaultConfig,
            data: {
                rootFolderId: rootFolderId,
            } as NewFolderDialogComponentDialogData,
        });
    }

    public Submit() {
        if (this.Data.uploadMode) {
            this.newDocumentViewComponent.refreshUploadParams();

            setTimeout(() => {
                if (this.SynchronUpload) {
                    const files: FileEntity[] = [];
                    this.newDocumentViewComponent.Drpzone.dropzoneUploadSuccess.pipe(takeUntil(this.newDocumentViewComponent.CloseViewEvent)).subscribe((ret) => files.push(...ret));

                    firstValueFrom(this.newDocumentViewComponent.CloseViewEvent).then((res) => {
                        this.dialogRef.close({
                            folderId: this.selectedFolderId,
                            synchronUploadedDocuments: files,
                        });
                    });
                } else {
                    firstValueFrom(this.newDocumentViewComponent.CloseViewEvent).then((res) => {
                        this.dialogRef.close({
                            folderId: this.selectedFolderId,
                        });
                    });
                }

                this.newDocumentViewComponent.Save();
            }, 1);
        } else {
            this.dialogRef.close({
                folderId: this.selectedFolderId,
            });
        }
    }

    onFolderSelect(folderId: number) {
        this.selectedFolderId = folderId;
    }

    openFolderById(folderId: number) {
        firstValueFrom(this.folderDataService.getFolderAndParentsById(folderId)).then((folders) => {
            if (folders && folders.every(isNotNullOrUndefined) && folders.length > 0) {
                const path = sortByParent(folders);
                path.reverse();
                const targetFolder = path.find((f) => [FolderTypes.commission, FolderTypes.customer, FolderTypes.employee, FolderTypes.resource].includes(f.Type));
                const targetFolderType = targetFolder?.Type;

                switch (targetFolderType) {
                    case FolderTypes.commission:
                        this.selectedType$.next('commission');
                        break;
                    case FolderTypes.customer:
                        this.selectedType$.next('customer');
                        break;
                    case FolderTypes.resource:
                        this.selectedType$.next('resource');
                        break;
                    case FolderTypes.employee:
                        this.selectedType$.next('employee');
                        break;
                    default:
                        this.selectedType$.next('dms');
                }

                this.QuickNavigationFolders$.pipe(
                    map((q) => {
                        if (targetFolderType) {
                            return q.findIndex((e) => e.entityId === targetFolder.EntityId && e.folderType === targetFolderType);
                        }
                        return q.findIndex((e) => path[path.length - 1].Id === e.entityId && e.folderType === 'dms_placeholder');
                    }),
                    filter((index) => index > -1),
                    first(),
                    delay(100),
                    switchMap((index) =>
                        this.page$.pipe(
                            filter((page) => isNotNullOrUndefined(page.length)),
                            first(),
                            map((page) => {
                                this.matPaginator.pageIndex = Math.floor(index / page.pageSize);
                                this.matPaginator._changePageSize(this.matPaginator.pageSize);
                                this.cdRef.detectChanges();
                                return { index, pageSize: page.pageSize };
                            })
                        )
                    ),
                    delay(200) // zusätzliche Wartezeit für den Seitenwechsel
                ).subscribe(({ index, pageSize }) => {
                    if (this.panels) {
                        const panel = this.panels.get(index % pageSize);
                        if (panel) {
                            panel.open();
                            setTimeout(() => {
                                this.ScrollToLastSelected();
                                this.cdRef.detectChanges();
                            }, 200);
                        }
                    }
                });
            }
        });
    }
}
