import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, firstValueFrom, from, mergeMap, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MaterialActionTypes } from '../actions/material.actions';
import { MaterialEntityFromBackend } from '../../entities/material.entity';
import { BaseActionTypes } from '../actions/base.actions';
import { isNotNullOrUndefined } from '../../../helper/helper';
import { getMaterialDictionary } from '../selectors/material.selector';
import { GatewayResponse, GetTimestampFromTime, MaterialWoDescription } from '@dave/types';
import { DaveActions } from '../actions/actions';
import { State } from '../index';
import { AppGatewayService } from '../../services/app-gateway.service';
import { MaterialGetRequest } from '@dave/types/dist/proto/erp/material';

enum ErrorCodes {
    Add = 'Material Hinzufügen fehlgeschlagen',
    Load = 'Material Abrufen fehlgeschlagen',
    Modify = 'Material Bearbeiten fehlgeschlagen',
    Remove = 'Material Löschen fehlgeschlagen',
}

@Injectable()
export class MaterialEffects {

    ModifyMaterial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialActionTypes.Change),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialEdit: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialActionTypes.UpdateOne({ Payload: MaterialEntityFromBackend(res.ErpMaterialEdit.Material) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Modify,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );

    DeleteMaterial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialActionTypes.Delete),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialDelete: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialActionTypes.RemoveOne({ Payload: +Payload.Id });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Remove,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );


    AddMaterial$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MaterialActionTypes.Create),
            concatMap(({ Payload }) =>
                this.gatewayService
                    .Request({ ErpMaterialAdd: Payload })
                    .then((res) => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            return MaterialActionTypes.UpdateOne({ Payload: MaterialEntityFromBackend(res.ErpMaterialAdd.Material) });
                        } else {
                            throw res.Errors;
                        }
                    })
                    .catch((err) =>
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Add,
                                Err: err,
                            },
                        }),
                    ),
            ),
        ),
    );



    LoadMaterials = createEffect(() => {
        return this.actions$.pipe(
            ofType(MaterialActionTypes.Load),
            switchMap(({ Payload }) => {
                const requestPayload: Omit<MaterialGetRequest, 'Page'> = {
                    UpdatedSince: isNotNullOrUndefined(Payload?.updatedSince) ? Payload.updatedSince : undefined,
                    WithDeleted: true,
                    PageSize: 10000,
                }
                const requests$ =  new Promise<{ErpMaterialGet: { materials: GatewayResponse['ErpMaterialGet']['Materials'] }, Errors: GatewayResponse['Errors'] }>(async (resolve, reject) => {

                    let page = 1;
                    const results: GatewayResponse['ErpMaterialGet']['Materials'] = [];
                    let errors = {};
                    let lastPageReached = false;

                    const getPage = async (page: number) => {
                        const res = await this.gatewayService.Request({
                            ErpMaterialGet: {
                                ...requestPayload,
                                Page: page,
                            },
                        });
                        if (res.ErpMaterialGet?.Materials) {
                            results.push(...res.ErpMaterialGet.Materials);
                        }
                        if (res.Errors) {
                            errors = {...errors, ...res.Errors}
                        }
                        if (page >= res.ErpMaterialGet.PageCount) {
                            lastPageReached = true;
                        }
                        return res;
                    }
                    while (!lastPageReached) {
                        await getPage(page)
                        page++
                    }
                    resolve({ ErpMaterialGet: {materials: results}, Errors: errors })
                })
                return from(requests$).pipe(
                    switchMap(res => {
                        if (Object.keys(res?.Errors || {}).length === 0) {
                            if (!isNotNullOrUndefined(Payload.updatedSince)) {
                                return of(MaterialActionTypes.UpdateAll({
                                    Payload: res.ErpMaterialGet.materials.map(val => MaterialEntityFromBackend(val)),
                                    updateLatestUpdatedAt: true,
                                }));
                            } else if (res.ErpMaterialGet.materials.length) {
                                return from(firstValueFrom(this.store$.select(getMaterialDictionary))).pipe(
                                    mergeMap(Materials => {
                                        const filteredMaterials = res.ErpMaterialGet.materials.filter(Material => {
                                            const fromState = Materials[Material.Id];
                                            return !fromState || GetTimestampFromTime(fromState.UpdatedAt).toString() !== Material.UpdatedAt;
                                        });

                                        if (filteredMaterials.length) {
                                            return of(MaterialActionTypes.UpdateMany({
                                                Payload: filteredMaterials.map(val => MaterialEntityFromBackend(val)),
                                                updateLatestUpdatedAt: true,
                                            }));
                                        } else {
                                            return EMPTY;
                                        }
                                    }),
                                );
                            }
                            return EMPTY;
                        } else {
                            throw res.Errors;
                        }
                    }),
                    catchError(err =>
                        of(BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.Load,
                                Err: err,
                            },
                        }))
                    ),
                );
            }),
        );
    });

    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>,  private gatewayService: AppGatewayService) {}
}
