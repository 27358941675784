import { CustomerSpecificationTypeType } from '../graphql-types';
import {
    SelectSearchData
} from '../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';

export class CustomerSpecificationTypeEntity implements SelectSearchData{
    constructor(
        public Id: number = null,
        public Name: string = null,
        public PartnerId: number = null,
    ) {}

    public Clone(override: Partial<CustomerSpecificationTypeEntity> = {}): CustomerSpecificationTypeEntity {
        const attr = {...this, ...override};
        return Object.assign(new CustomerSpecificationTypeEntity(), attr);
    }
}

export function CustomerSpecificationTypeEntityFromBackend(res: CustomerSpecificationTypeType): CustomerSpecificationTypeEntity {
    return new CustomerSpecificationTypeEntity(
        res.id,
        res.name,
        res.partnerId,
    );
}
