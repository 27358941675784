import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { filter, map, shareReplay, skip, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { EmailEntity } from '../../../dave-data-module/entities/email.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import {
    Person2EntityEntity,
    Person2EntityEntityTypeEnum,
} from '../../../dave-data-module/entities/person2entity.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { Person2EntityActionTypes } from '../../../dave-data-module/State/actions/person2entity.action';
import {
    getCommissionById,
    getCommissionDictionary,
    getCommissions,
    getCommissionsActive,
} from '../../../dave-data-module/State/selectors/commission.selector';
import {
    getCustomerById,
    getCustomerDictionary,
    getCustomers,
    getCustomersFetched, getNotDeletedCustomers,
} from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEmailConnections } from '../../../dave-data-module/State/selectors/email-connection.selectors';
import { getEvents } from '../../../dave-data-module/State/selectors/events.selectors';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import {getPersonById, getPersonDictionary, getPersons} from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import {getUser, getUserDictionary, getUsers, getUsersActive} from '../../../dave-data-module/State/selectors/users.selectors';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { getErrorMessage } from '../../../helper/validation.helper';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import {EmailActionTypes} from "../../../dave-data-module/State/actions/email.actions";
import { LoadingService } from '../../../services/loading.service';
import { CustomerAdministrationMeta, NewPersonMeta, PersonMeta } from '../../../helper/page-metadata';
import {
    getProcessDictionary,
    getProcesses,
    getProcessFetched,
} from '../../../dave-data-module/State/selectors/process.selector';
import {
    SelectSearchOption
} from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { ProcessEntity } from '../../../dave-data-module/entities/process.entity';
import { ProcessResolver } from '../../../dave-data-module/guards/process.resolver';
import {SelectPersonService} from "../../../services/select-person.service";
import { CustomLabelService } from "../../../services/custom-label.service";
import { isNullOrUndef } from 'chart.js/helpers';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { getFetched$ } from '../../../dave-data-module/helper/helper';

export interface EventFromEmailComponentDialogData {
    Email: EmailEntity;
}
@Component({
    selector: 'app-event-from-email',
    templateUrl: 'event-from-email.component.html',
    styleUrls: ['event-from-email.component.scss'],
})
export class EventFromEmailComponent implements OnInit, OnDestroy {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        hasBackdrop: false,
        width: '500px',
    };
    public ChipListData$: Observable<IDetailListTemplateData>;
    private selectedPerson2Entity: Person2EntityEntity[] = [];
    public PersonList: Array<PersonEntity> = [];
    public Customer: CustomerEntity;
    public GetErrorMessage = getErrorMessage;
    public NewEventForm = new FormGroup({
        Customer: new FormControl<SelectSearchOption<CustomerEntity>>(null),
        Commission: new FormControl<SelectSearchOption<CommissionEntity>>(null),
        PersonId: new FormControl<PersonEntity>(null),
        Note: new FormControl<string>('', {
            updateOn: 'blur',
        }),
        Name: new FormControl<string>('E-Mail', {
            updateOn: 'blur',
        }),
        Users: new FormControl<UserEntity[]>(null),
        BeiteiligtePersons: new FormControl<PersonEntity[]>(null),
        Process: new FormControl<SelectSearchOption<ProcessEntity>>(null)
    });
    public EventType: EventTypeEntity = null;
    public UserId: number = null;
    public Event$ = this.store.select(getEvents).pipe(map(events => events.find(e => e.EmailId === this.Dialogdata.Email.Id)),
        shareReplay({bufferSize: 1, refCount: true}),
    );
    public IsEditing$ = this.Event$.pipe(map(e => !!e));

    private subscriptions: Subscription[] = [];
    protected processOptions$ = this.store.select(getProcessFetched).pipe(
        tap(f =>{
            if (!f) {
                this.processResolver.resolve()
            }
        }),
        filter(f => f),
        switchMap(() => this.store.select(getProcesses)),
    );
    protected customerOptions$ = getFetched$(this.store, getCustomersFetched, getNotDeletedCustomers)

    constructor(
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: EventFromEmailComponentDialogData,
        public CS: CustomerNameService,
        public LS: LoadingService,
        private dialogRef: MatDialogRef<EventFromEmailComponent>,
        private cdr: ChangeDetectorRef,
        private sp: SelectPersonService,
        protected cls: CustomLabelService,
        protected processResolver: ProcessResolver,
    ) {}

    ngOnInit() {
        firstValueFrom(this.store.select(getUser)).then( user => this.UserId = user.Id);
        this.subscriptions.push(
            combineLatest([
                combineLatest([
                    this.store.select(getPersons).pipe(map(p => p.filter(pe => !pe.Deleted))),
                    this.store.select(getPerson2Entities).pipe(map(pes => pes.filter(pe => pe.EntityType === Person2EntityEntityTypeEnum.Customer || pe.EntityType === Person2EntityEntityTypeEnum.Commission))),
                    this.NewEventForm.controls.Customer.valueChanges,
                    this.NewEventForm.controls.Commission.valueChanges,
                ]).pipe(
                    map(([persons, person2Entities, customerId, commissionId/*, personId*/]) =>
                        customerId?.Id || commissionId?.Id || this.NewEventForm.controls.PersonId.value || this.Dialogdata.Email.PersonId
                            ? persons.filter(p =>
                            p.Id == this.Dialogdata.Email.PersonId ||
                                  person2Entities.some(
                                      pe =>
                                          pe.PersonId === p.Id &&
                                          ((pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.EntityId === customerId?.Id) ||
                                              (pe.EntityType === Person2EntityEntityTypeEnum.Commission && pe.EntityId === commissionId?.Id)),
                                  ),
                              )
                            : []
                    ),
                ),
                this.Event$,
            ]).subscribe(([c, e]) => {
                this.PersonList = c.filter(a => !a.Deleted).map( p => p);
            }),


            this.NewEventForm.controls.Commission.valueChanges.pipe(
                    switchMap(c => c?.CustomerId ? this.store.select(getCustomerById({id: c.CustomerId})) : of(null)),
                ).subscribe(c => {
                let currentCommission = this.NewEventForm.controls.Commission.value;
                let currentCustomer = this.NewEventForm.controls.Customer.value;
                if (isNotNullOrUndefined(currentCommission) && isNullOrUndef(currentCustomer) && c?.Id) {
                    this.NewEventForm.controls.Customer.setValue(c || null);
                }
            }),

            combineLatest([this.NewEventForm.controls?.Customer.valueChanges, this.store.select(getCustomerDictionary)]).subscribe(([customer, customers]) => {
                if (customer && this.NewEventForm.controls.Commission.value && this.NewEventForm.controls.Commission.value.CustomerId !== customer.Id) {
                    this.NewEventForm.controls.Commission.setValue(null, {emitEvent: false})
                }
                this.Customer = customer ? customers[customer.Id] : null;
            }),
            combineLatest([this.store.select(getEventTypes), this.store.select(getEmailConnections)]).subscribe(([types, connections]) => {
                let connection = connections?.find(c => c.Id == this.Dialogdata.Email.EmailConnectionId);
                if (connection) {
                    this.EventType = types?.find(t => t.Id == connection?.EventTypeId);
                }
            }),
            this.Event$.pipe(
                filter(isNotNullOrUndefined),
                withLatestFrom(this.store.select(getCustomerDictionary),
                    this.store.select(getCommissionDictionary),
                    this.store.select(getPersonDictionary),
                    this.store.select(getPerson2Entities),
                    this.store.select(getUserDictionary),
                    this.store.select(getProcessDictionary),
                ))
                .subscribe(([event, customers, commissions, persons, person2Entities, users, processes]) => {

                const commission = event.CommissionId ? commissions[event.CommissionId] : null;
                this.store.select(getPersonById({ id: event.PersonId})).pipe(take(1)).subscribe( p => {
                    this.NewEventForm.setValue({
                        Customer: event.CustomerId ? customers[event.CustomerId] : null,
                        Commission: commission || null,
                        PersonId: p ? p : null,
                        Note: event.Description ? event.Description : '',
                        Name: 'E-Mail',
                        Users: event.UserIds?.map(uId => users[uId]).filter(isNotNullOrUndefined),
                        BeiteiligtePersons: person2Entities.filter(p2e => p2e.EntityType === Person2EntityEntityTypeEnum.Event && p2e.EntityId === event.Id).map(p2e => persons[p2e.PersonId]).filter(isNotNullOrUndefined),
                        Process: event.ProcessId ? processes[event.ProcessId] : null,
                    })
                })
            }),
            combineLatest([
                this.store.select(getPersons).pipe(filter(isNotNullOrUndefined)),
                this.Event$,
                this.store.select(getPerson2Entities).pipe(filter(isNotNullOrUndefined)),
                this.store.select(getCustomers).pipe(filter(isNotNullOrUndefined)),
            ])
                .pipe()
                .subscribe(([persons, event, person2Entities, customer]) => {
                    this.selectedPerson2Entity = event ? person2Entities?.filter(p2e => p2e.EntityType === Person2EntityEntityTypeEnum.Event && p2e.EntityId === event.Id) : [];
                    if(this.PersonList?.length == 0 && this.Dialogdata.Email.PersonId != null){
                        this.PersonList = persons.filter(p => p.Id == this.Dialogdata.Email.PersonId).map( pl => pl);
                    }
                    if (!event) {
                        this.store.select(getPersonById({ id: this.Dialogdata.Email.PersonId })).subscribe( p => {
                            this.NewEventForm.controls.PersonId.setValue(
                                p ? p : null
                            )
                        });
                        this.NewEventForm.controls.Users.setValue([])
                        this.NewEventForm.controls.Customer.setValue(customer.find(c => c.Id == this.Dialogdata.Email.CustomerId));
                        if(!isNotNullOrUndefined(this.NewEventForm.controls.Customer.value) && isNotNullOrUndefined(this.NewEventForm.controls.PersonId.value)) {

                            const person2cu = person2Entities.filter(p2e=> p2e.EntityType == Person2EntityEntityTypeEnum.Customer && p2e.PersonId == this.NewEventForm.value.PersonId.Id);
                            const cu = customer.filter(c=> !c.Deleted && isNotNullOrUndefined(person2cu.find(p2cu=> p2cu.EntityId == c.Id)));
                            if(cu.length == 1){

                                this.NewEventForm.controls.Customer.setValue(customer.find(c => c.Id == cu[0].Id));
                            }
                        }
                    }
                    this.cdr.detectChanges();
                }),
        );
        this.ChipListData$ = combineLatest([
            this.store.select(getUsers).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getPersons).pipe(filter(isNotNullOrUndefined)),
            this.store.select(getPartner).pipe(filter(isNotNullOrUndefined)),
            this.Event$,
            this.cls.getSingle$('Commission')
        ]).pipe(
            map(([users, persons, partner, event, commissionLabel]) => {
                return{
                    Properties: [
                        {
                            key: commissionLabel,
                            formControl: this.NewEventForm.controls.Commission,

                            options: {
                                specialInput: {
                                    singleSelectSearch: {
                                        options$: combineLatest([
                                                this.store.select(getCommissionsActive),
                                                this.NewEventForm.controls.Customer.valueChanges.pipe(startWith(''), map(() => this.NewEventForm.controls.Customer.value)),
                                            ]).pipe(
                                                map(([commissions, customer]) => (customer?.Id ? commissions.filter(c => c.CustomerId === customer.Id) : commissions)),
                                            ),
                                        compareOptions: (a, b) => a.Id === b.Id,
                                    },
                                },
                            },
                        },
                        {
                            key: 'Name',
                            formControl: this.NewEventForm.controls.Name,
                        },{
                            key: 'Notiz',
                            formControl: this.NewEventForm.controls.Note,
                            options: {
                                specialInput: {
                                    textArea: { Fill: true },
                                },
                            },
                        },
                        {
                            key: 'Zugewiesene Mitarbeiter',
                            formControl: this.NewEventForm.controls.Users,
                            options: {
                                specialInput: {
                                    chipAutocomplete: {
                                        MapFn: (option: UserEntity) => [option.DisplayName, option.Email].filter(isNotNullOrUndefined).join(', '),
                                        Options: users.filter(u => u.PartnerId === partner.Id) || [],
                                        initialPatchDefaultValue: true,
                                    },
                                },
                            },
                        },
                        {
                            key: 'Beteiligte Personen',
                            formControl: this.NewEventForm.controls.BeiteiligtePersons,
                            options: {
                                specialInput: {
                                    chipAutocomplete: {
                                        MapFn: (option: PersonEntity) => [option.DisplayName, option.Email].filter(isNotNullOrUndefined).join(', '),
                                        Options: persons.filter(u => u.PartnerId === partner.Id) || [],
                                        initialPatchDefaultValue: true,
                                    },
                                },
                            },
                        },
                    ],
                };
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public Submit() {
        if (this.NewEventForm.invalid) {
            return;
        }
        const { PersonId, Note, Customer, Commission, BeiteiligtePersons, Users } = this.NewEventForm.value;
        this.LS.startLoading('submitEventForEmail');
        firstValueFrom(this.Event$).then(event => {
            if (event) {
                this.store.dispatch(
                    EventsActionTypes.ModifyEvent({
                        Payload: {
                            id: event.Id,
                            personId: PersonId?.Id || null,
                            customerId: Customer?.Id || null,
                            commissionId: Commission?.Id || null,
                            description: Note || '',
                            userIds: isNotNullOrUndefined(Users) ? Users.map(u => u.Id) : null,
                        },
                    }),
                );

                this.store.dispatch(EmailActionTypes.ModifyEmail({
                    Payload: {
                        id: this.Dialogdata.Email.Id,
                        customerId: Customer?.Id || null,
                        commissionId:  Commission?.Id || null,
                    }
                }));

                //adding and deleting person2Entity
                let addIds: number[] = [];
                let delIds: number[] = [];
                BeiteiligtePersons.forEach(p => {
                    if (this.selectedPerson2Entity.filter(pe => pe.PersonId === p.Id).length === 0) {
                        addIds.push(p.Id);
                    }
                });

                this.selectedPerson2Entity.forEach(pe => {
                    if (BeiteiligtePersons.filter(p => p.Id === pe.PersonId).length === 0) {
                        delIds.push(pe.Id);
                    }
                });
                addIds.forEach(pId => {
                    this.store.dispatch(
                        Person2EntityActionTypes.AddPerson2Entity({
                            Payload: {
                                personId: pId,
                                entityId: event.Id,
                                entityType: Person2EntityEntityTypeEnum.Event,
                            },
                        }),
                    );
                });

                delIds.forEach(id => {
                    this.store.dispatch(
                        Person2EntityActionTypes.DeletePerson2Entity({
                            Payload: {
                                id: id,
                            },
                        }),
                    );
                });
            } else {
                this.store.dispatch(
                    EventsActionTypes.AddEvent({
                        Payload: {
                            personId: PersonId?.Id,
                            customerId: Customer?.Id  || null,
                            hint: null,
                            street: null,
                            city: null,
                            postalCode: null,
                            commissionId: Commission?.Id  || null,
                            eventTypeId: this.EventType.Id,
                            eventDate: FrontendDate(this.Dialogdata.Email?.SendDate),
                            eventEndDate: FrontendDate(this.Dialogdata.Email?.SendDate),
                            description: Note || '',
                            name: this.NewEventForm.value.Name,
                            private: false,
                            emailId: this.Dialogdata.Email.Id,
                            userIds: isNotNullOrUndefined(Users) ? [...Users.filter(user => user.Id !== this.UserId).map(u => u.Id), this.UserId] : this.UserId ? [this.UserId] : null,
                            personIds: isNotNullOrUndefined(BeiteiligtePersons) ? BeiteiligtePersons.map(p => p.Id) : null,
                            documentIds: this.Dialogdata.Email.DocumentIds,
                        },
                    })
                );
                this.store.dispatch(EmailActionTypes.ModifyEmail({
                    Payload: {
                        id: this.Dialogdata.Email.Id,
                        customerId: Customer?.Id  || null,
                        commissionId:  Commission?.Id  || null,
                    }
                }));
            }
            this.LS.endLoading('submitEventForEmail');
            this.dialogRef.close();
        });
    }

    public NewContactPerson() {
        firstValueFrom(this.store.select(getCustomerById({ id: this.NewEventForm.value.Customer?.Id}))).then( value => this.sp.OpenPersonDialog(null, value))

    }
    public compareById = (a: {Id}, b: {Id}) => a.Id === b.Id;
}
