<mat-horizontal-stepper [linear]="false">
    <mat-step *ngFor="let page of [page1, page2, page3]; let i = index">
        <!--        <ng-template matStepLabel>Seite {{i + 1}}</ng-template>-->
        <div class="scrollable">
            <ng-template [ngTemplateOutlet]="page">Seite {{ i + 1 }}</ng-template>
        </div>
        <div class="footer">
            <h1 class="text-center salary-text">
                Mein StundenLohn <br *ngIf="!(BS.MobileQuery | async)" />
                {{ Salary$ | async | currency }}
            </h1>
            <div class="buttons">
                <div class="cancel-button-wrapper">
                    <button *ngIf="i === 0" app-button Inverted (click)="DialogRef.close()" matTooltip="Abbrechen">Abbrechen</button>
                    <button *ngIf="i !== 0" app-button Inverted matStepperPrevious matTooltip="Zurück">Zurück</button>
                </div>
                <button *ngIf="i !== 2" app-button matStepperNext matTooltip="Weiter">Weiter</button>
            </div>
        </div>
    </mat-step>
    <ng-template matStepperIcon="edit">
        <fa-icon icon="pencil-alt"></fa-icon>
    </ng-template>
    <!-- <ng-template matStepperIcon="number">
        <fa-icon icon="pencil-alt"></fa-icon>
    </ng-template> -->
    <ng-template matStepperIcon="done">
        <fa-icon icon="check"></fa-icon>
    </ng-template>
    <ng-template matStepperIcon="error">
        <fa-icon icon="exclamation-triangle"></fa-icon>
    </ng-template>
</mat-horizontal-stepper>

<ng-template #page1>
    <h2>Mitarbeiter anlegen</h2>
    <div class="employee-list-wrapper">
        <mat-list>
            <mat-list-item *ngFor="let employee of EmployeeForms.controls; let i = index" class="overflow-y-hidden">
                <span *ngIf="i === 0" class="mr-05">{{(User$ | async).DisplayName}}</span>
                <span *ngIf="i !== 0" class="mr-05">Mitarbeiter {{ i + 1 }}</span>
                <mat-form-field appearance="standard" class="number-input ml">
                    <input matInput type="number" [formControl]="employee.controls.percentage" placeholder="Anteil" />
                    <div matSuffix>%</div>
                </mat-form-field>
                <button app-round-button class="list-button" *ngIf="i !== 0" matTooltip="Mitarbeiter entfernen" aria-label="Mitarbeiter entfernen" (click)="EmployeeForms.removeAt(i)" Inverted><fa-icon icon="minus"></fa-icon></button>
                <button app-round-button class="list-button" *ngIf="i === 0" matTooltip="Mitarbeiter hinzufügen" aria-label="Mitarbeiter hinzufügen" (click)="AddEmployeeLine()"><fa-icon icon="plus"></fa-icon></button>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="flex-center">
        <h3>Nachlass / Zulage</h3>
        <mat-form-field appearance="standard" class="number-input">
            <input matInput type="number" [formControl]="CommissionForm.controls.nachlass" placeholder="Anteil" />
            <div matSuffix>%</div>
        </mat-form-field>
    </div>
    <div class="flex-center">
        <h3>Vorarbeiter Bonus</h3>
        <mat-form-field appearance="standard" class="number-input">
            <input matInput type="number" [formControl]="CommissionForm.controls.bonus" placeholder="Anteil" />
            <div matSuffix>%</div>
        </mat-form-field>
    </div>
</ng-template>
<ng-template #page2>
    <h2>Aufgaben auswählen</h2>
    <mat-list class="task-list" *ngIf="!(Loading$ | async); else loading">
        <h4 *ngIf="(Tasks$ | async)?.length === 0" class="text-center">Keine Aufgaben für die Akkordberechnung vorhanden</h4>
        <mat-list-item *ngFor="let task of Tasks$ | async" class="list-item-with-input">
            <div class="name">{{ task.Name }}</div>
            <mat-form-field appearance="fill" class="number-input">
                <input matInput type="number" [formControl]="task.quantityForm" />
                <div matSuffix>{{ task.QuantityType }}</div>
            </mat-form-field>
            <div class="cell">{{(task.Price * task.quantityForm.value) / 100 | currency}}</div>
        </mat-list-item>
    </mat-list>
</ng-template>
<ng-template #page3>
    <h2>Stunden eintragen</h2>
    <mat-list>
        <ng-container *ngFor="let form of EmployeeForms.controls; let i = index">
            <mat-list-item class="overflow-y-hidden">
                <span *ngIf="i === 0" class="mr-05">{{(User$ | async).DisplayName}}</span>
                <span *ngIf="i !== 0" class="mr-05">Mitarbeiter {{ i + 1 }}</span>
                <mat-form-field appearance="standard" class="number-input ml">
                    <input matInput type="number" [formControl]="form.controls.time" placeholder="Stunden" />
                    <div matSuffix>Std.</div>
                </mat-form-field>
            </mat-list-item>
        </ng-container>
    </mat-list>
</ng-template>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
