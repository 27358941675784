import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SimplebarAngularModule } from 'simplebar-angular';
import { EntityRoleEntity, EntityTypesEnum } from '../../../../dave-data-module/entities/entity-role.entity';
import { UserToCommissionEntity } from '../../../../dave-data-module/entities/user-to-commission.entity';
import { getFetched$ } from '../../../../dave-data-module/helper/helper';
import { State } from '../../../../dave-data-module/State';
import { getCommissionById } from '../../../../dave-data-module/State/selectors/commission.selector';
import {
    getActiveEmployeesSortedByNameStartWithMe,
    getEmployeesSortedByNameStartWithMe,
} from '../../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole, getEntityRoleFetched } from '../../../../dave-data-module/State/selectors/entity-role.selector';
import { DaveDoubleIconModule } from '../../../../dave-double-icon/dave-double-icon.module';
import { SelectUserCommissionDialogComponent, SelectUserCommissionDialogComponentDialogData } from '../../../../dave-select-user/components/select-user-dialogs/select-user-commission-dialog/select-user-commission-dialog.component';
import { AppButtonModule } from '../../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../../dave-utils-module/app-filter-module/app-filter.module';
import { FILTER_TYPE_SEARCH_MULTI_SELECT, IFilterTypeSearchMultiSelectValue } from '../../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { DaveSharedComponentsModule } from '../../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { PermissionService } from '../../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { DaveHeaderHeight, isNotNullOrUndefined } from '../../../../helper/helper';
import { FilterTypes } from '../../../../services/default-filter.service';
import { EmployeeEntity } from '../../../../dave-data-module/entities/employee.entity';

@Component({
    selector: 'app-commission-user-list-card[userToCommissionFromCommission][commissionId]',
    standalone: true,
    imports: [
        CommonModule,
        AppButtonModule,
        DaveDoubleIconModule,
        DaveSharedComponentsModule,
        FontAwesomeModule,
        MatCardModule,
        MatExpansionModule,
        MatTableModule,
        MatTooltipModule,
        RouterLinkActive,
        SimplebarAngularModule,
        RouterLink,
        AppFilterModule,
        MatBadgeModule,
    ],
    templateUrl: './commission-user-list-card.component.html',
    styleUrls: ['./commission-user-list-card.component.scss'],
})
export class CommissionUserListCardComponent {
    private userToCommissionFromCommission$ = new BehaviorSubject<UserToCommissionEntity[]>([]);
    @Input() set userToCommissionFromCommission(value: UserToCommissionEntity[]) {
        this.userToCommissionFromCommission$.next(value);
    }
    get userToCommissionFromCommission() {
        return this.userToCommissionFromCommission$.value;
    }

    private commissionId$ = new BehaviorSubject<number>(null);
    @Input() set commissionId(value: number) {
        if (value !== this.commissionId$.value) {
            this.commissionId$.next(value);
            this.resetFilter();
        }
    }
    get commissionId() {
        return this.commissionId$.value;
    }
    @Input() expanded: boolean;
    private commissionEntityRoles$ = getFetched$(this.store, getEntityRoleFetched, getEntityRole).pipe(map((roles) => roles.filter((r) => r.Entity === EntityTypesEnum.Commission)));
    FilterValues$ = new BehaviorSubject<{ [FilterTypes.Roles]: IFilterTypeSearchMultiSelectValue<number>[] }>({ [FilterTypes.Roles]: [] });
    FilterSettings$ = this.commissionEntityRoles$.pipe(
        map((roles) => {
            return [
                {
                    Name: FilterTypes.Roles,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Rolle',
                    Icon: null,
                    Values: roles.map<IFilterTypeSearchMultiSelectValue>((t) => ({ id: t.Id, label: t.DisplayName })),
                },
            ];
        }),
    );
    FilterAmount$ = this.FilterValues$.pipe(map((filterValues) => (filterValues[FilterTypes.Roles]?.length ? 1 : null)));

    protected readonly HeaderHeight = DaveHeaderHeight;
    public ShowSelectedUsers$: Observable< {
        Assignee: boolean;
        User: EmployeeEntity;
        roles: EntityRoleEntity[]
    }[]> = combineLatest([this.store.select(getActiveEmployeesSortedByNameStartWithMe), this.commissionId$, this.store.select(getEntityRole), this.userToCommissionFromCommission$]).pipe(
        map(([employees, commissionId, er, u2c]) => {
            return employees
                .filter((e) => u2c.some((u) => u.UserId === e.UserId))
                .map((e) => {
                    return {
                        User: e,
                        Assignee: u2c.some((u) => u.UserId === e.UserId && u.Assignee),
                        roles: u2c
                            .filter((u2) => u2.UserId === e.UserId && commissionId === u2.CommissionId)
                            .map((item) => er?.find((x) => x?.Id === item?.RoleId))
                            .filter(isNotNullOrUndefined),
                    };
                });
        }),
        switchMap((entrys) =>
            this.FilterValues$.pipe(
                map((filter) => {
                    if (filter[FilterTypes.Roles].length) {
                        return entrys.filter((e) => e.roles.some((r) => filter[FilterTypes.Roles].some((f) => f.id === r.Id)));
                    }
                    return entrys;
                }),
            ),
        ),
        shareReplay({refCount: true, bufferSize: 1})
    );
    editDisabled$: Observable<boolean> = this.commissionId$.pipe(
        switchMap((id) =>
            id ? this.store.select(getCommissionById({ id })).pipe(switchMap((commission) => (commission.Deleted ? of(true) : this.PS.Has$(this.PS.Permissions.EditRoleCommissionManagement).pipe(map((v) => !v))))) : of(true),
        ),
    );
    constructor(private store: Store<State>, protected PS: PermissionService, private dialog: MatDialog) {}

    public OpenUserSelect() {
        firstValueFrom(this.commissionId$).then((commissionId) => {
            this.dialog.open<SelectUserCommissionDialogComponent, SelectUserCommissionDialogComponentDialogData>(SelectUserCommissionDialogComponent, {
                ...SelectUserCommissionDialogComponent.DefaultConfig,
                data: {
                    commissionId: commissionId,
                    hideStartDate: true,
                },
            });
        });
    }
    resetFilter() {
        firstValueFrom(this.commissionEntityRoles$).then((roles) => {
            this.FilterValues$.next({ [FilterTypes.Roles]: roles.filter((r) => r.DisplayName !== 'Admin').map((r) => ({ id: r.Id, label: r.DisplayName })) });
        });
    }
}
