import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subscription, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { SelectResourceComponent } from 'src/app/resource-dispo/components/select-resource/select-resource.component';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CommissionTypeNameEnum, CommissionWizardSteps } from '../../../dave-data-module/entities/commissionType.entity';
import { State } from '../../../dave-data-module/State';
import {
    getCommissionById,
    getCommissionDictionary,
} from '../../../dave-data-module/State/selectors/commission.selector';
import {
    getCommissionTypeById,
    getCommissionTypeDictionary,
    getCommissionTypes,
} from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getUserToCommissions } from '../../../dave-data-module/State/selectors/user-to-commission.selector';
import { hasRequiredField, IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { appMatDialogDefaultConfig, isNotNullOrUndefined } from '../../../helper/helper';
import { ICommissionDetailListTemplateEntrys, ICommissionForm } from '../detail-commission/helper';

import { Color, MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { Actions, ofType } from '@ngrx/effects';
import moment, { Moment } from 'moment';
import { PartnerSpecificationType, PartnerTypeEnum } from 'src/app/dave-data-module/entities/partner.entity';
import { getPartner } from 'src/app/dave-data-module/State/selectors/partners.selectors';
import { ActivityEntity } from '../../../dave-data-module/entities/activity.entity';
import { JobSpecificationEntity } from '../../../dave-data-module/entities/job-specification.entity';
import { EmployeeResourceScheduleEntity } from '../../../dave-data-module/entities/resource-dispo/employee-resource-schedule.entity';
import { ActivityResolver } from '../../../dave-data-module/guards/activity.resolver';
import { EmployeeResourceScheduleResolver } from '../../../dave-data-module/guards/resource-dispo/employee-resource-scheduler.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { ActivityActions } from '../../../dave-data-module/State/actions/activity.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { EmployeeResourceScheduleActionTypes } from '../../../dave-data-module/State/actions/resource-dispo/employee-resource-schedule.actions';
import { getActivities, getActivitiesActive, getActivityDictionary, getActivityFetched } from '../../../dave-data-module/State/selectors/activity.selector';
import { getJobSpecification, getJobSpecificationDictionary } from '../../../dave-data-module/State/selectors/job-specification.selector';
import { getEmployeeResourceSchedules, getEmployeeResourceSchedulesFetched } from '../../../dave-data-module/State/selectors/resource-dispo/employee-resource-schedule.selectors';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { getErrorMessage } from '../../../helper/validation.helper';
import { CustomLabelService } from '../../../services/custom-label.service';
import { getCustomerById } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getOfficeByCustomerId } from '../../../dave-data-module/State/selectors/offices.selectors';
import {CommissionMeta} from '../../../helper/page-metadata';
import { getStatusFromBackoffice } from '../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { StatusFromBackofficeNames } from '../../../dave-data-module/entities/statusFromBackoffice.entity';
export interface CommissionWizardComponentDialogData {
    commissionId: number;
    detailListTemplateEntries: ICommissionDetailListTemplateEntrys;
    statusBackofficeForm: ICommissionForm['StatusBackofficeId'];
}

@Component({
    selector: 'app-commission-wizard',
    templateUrl: './commission-wizard.component.html',
    styleUrls: ['./commission-wizard.component.scss'],
    providers: [CurrencyPipe, DecimalPipe, DatePipe, { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
})
export class CommissionWizardComponent implements OnInit, OnDestroy, AfterViewInit {
    public static readonly DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
    }
    @ViewChild('selectTeamTemplate') private selectTeamTemplate: TemplateRef<any>;
    @ViewChild('capacityTemplate') private capacityTemplate: TemplateRef<any>;
    @ViewChild('resourceTemplate') private resourceTemplate: TemplateRef<any>;
    @ViewChild('addressTemplate') private addressTemplate: TemplateRef<any>;
    @ViewChild('pageOne') private stepOneTemplate: TemplateRef<any>;
    @ViewChild('selectResourceComponent') private SelectResourceComponent: SelectResourceComponent;
    @ViewChild('Stepper') public Stepper: MatStepper;

    @Input()
    set CommissionId(id: number) {
        this.CommissionId$.next(id);
    }
    get CommissionId() {
        return this.CommissionId$.value;
    }

    @Input() DetailListTemplateEntrys: ICommissionDetailListTemplateEntrys;
    @Output() Save = new EventEmitter<{wizardPage: number | null, routerLink: any[]}>();
    @Input() StatusBackofficeForm: ICommissionForm['StatusBackofficeId'];

    protected addressFrom: 'parent-commission' | 'customer' = 'customer';
    public IsBauAuftrag$: Observable<boolean>;
    public Commission$ = new BehaviorSubject<CommissionEntity | null>(null);
    public CommissionId$ = new BehaviorSubject<number | null>(null);
    public CommissionPath = CommissionMeta.Path;
    public Headline$: Observable<string>;
    protected parentCommission$ = this.Commission$.pipe(switchMap(c => c?.ParentId ? this.store.select(getCommissionById({id: c.ParentId})) : of(null)))

    protected addressFromValues$: Observable<{label: string, value: typeof this.addressFrom}[]> = combineLatest([
        this.cls.getSingle$('Customer'),
        this.parentCommission$.pipe(filter(isNotNullOrUndefined)),
        this.store.select(getCommissionTypeDictionary),
    ]).pipe(
        map(([customer, parentCommission, commissionTypes]) => {
            return [
                {
                    label: commissionTypes[parentCommission.CommissionTypeId].Name,
                    value: 'parent-commission',
                },
                {
                    label: customer,
                    value: 'customer',
                }
            ]
        })
    );
    public UserToCommission$ = this.CommissionId$.pipe(
        switchMap((commissionId) => this.store.select(getUserToCommissions).pipe(map((u2cs) => u2cs.filter((u2c) => u2c.CommissionId === commissionId)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Steps$: Observable<{ customSave?: () => void; name: string; customTemplate?: TemplateRef<any>; data?: Observable<IDetailListTemplateData>; controls?: FormArray }[]>;
    protected subscriptions: Subscription[] = [];
    public EmployeeResources: {
        entity?: EmployeeResourceScheduleEntity;
        availableActivities$: Observable<ActivityEntity[]>;
        form: FormGroup<{
            from: FormControl<Moment>;
            to: FormControl<Moment>;
            amount: FormControl<number>;
            jobSpecification: FormControl<JobSpecificationEntity>;
            color: FormControl<Color>;
            name: FormControl<string>;
            activities: FormControl<ActivityEntity[]>;
        }>;
    }[] = [];
    public JobSpecifications$ = this.store.select(getJobSpecification);
    private employeeResScheduleFromCommission$ = this.Commission$.pipe(
        switchMap((c) =>
            this.store.select(getEmployeeResourceSchedulesFetched).pipe(
                tap((fetched) => {
                    if (!fetched) {
                        this.employeeResourceScheduleResolver.resolve();
                    }
                }),
                filter((res) => res),
                switchMap(() => this.store.select(getEmployeeResourceSchedules)),
                map((ers) => ers.filter((e) => e.CommissionId === c.Id)),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    Activities$ = this.store.select(getActivityFetched).pipe(
        tap((fetched) => {
            if (!fetched) {
                this.activityRes.resolve();
            }
        }),
        filter((fetched) => fetched),
        switchMap(() => this.store.select(getActivities)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(
        protected store: Store<State>,
        protected dialog: MatDialog,
        protected appDialog: AppDialogService,
        protected fb: UntypedFormBuilder,
        public BS: BreakpointObserverService,
        private employeeResourceScheduleResolver: EmployeeResourceScheduleResolver,
        private activityRes: ActivityResolver,
        private actions$: Actions,
        protected cls: CustomLabelService,
        @Optional() @Inject(MAT_DIALOG_DATA) public DialogData: CommissionWizardComponentDialogData,
    ) {
        if (DialogData) {
            this.CommissionId = DialogData.commissionId;
            this.DetailListTemplateEntrys = DialogData.detailListTemplateEntries;
            this.StatusBackofficeForm = DialogData.statusBackofficeForm;
        }
    }

    ngOnInit(): void {
        this.Headline$ = merge(
            this.DetailListTemplateEntrys.CommissionType.formControl.valueChanges as Observable<SelectSearchData>,
            this.Commission$.pipe(switchMap((c) => this.store.select(getCommissionTypeById({ id: c.CommissionTypeId })))),
        ).pipe(
            withLatestFrom(this.cls.getSingle$('Commission')),
            map(([ct, commissionLabel]) => (ct?.Name || commissionLabel) + ' anlegen'),
        );
        this.subscriptions.push(
            this.CommissionId$.pipe(
                filter(isNotNullOrUndefined),
                switchMap((id) => this.store.select(getCommissionById({ id }))),
            ).subscribe((commission) => {
                this.Commission$.next(commission);
            }),
            combineLatest([
                this.employeeResScheduleFromCommission$,
                this.Activities$.pipe(
                    first(),
                    switchMap(() => this.store.select(getActivityDictionary)),
                    first(),
                ),
                this.store.select(getJobSpecificationDictionary),
            ]).subscribe(([ers, activities, jobs]) => {
                this.EmployeeResources = ers.map((e) => {
                    const selectedActivities = e.ActivityIds?.map((id) => activities[id]) || [];
                    return {
                        entity: e,
                        availableActivities$: this.store.select(getActivities).pipe(map((activities) => activities.filter((a) => !a.DeletedAt || selectedActivities.some((b) => b.Id === a.Id)))),
                        form: new FormGroup({
                            from: new FormControl<Moment>(moment(e.StartDate)),
                            to: new FormControl<Moment>(moment(e.EndDate)),
                            amount: new FormControl<number>(e.Amount),
                            jobSpecification: new FormControl<JobSpecificationEntity>(e.JobSpecificationId && jobs[e.JobSpecificationId]),
                            color: new FormControl<Color>(e.Color && new Color(e.Color.Red, e.Color.Green, e.Color.Blue)),
                            name: new FormControl<string>(e.Name),
                            activities: new FormControl<ActivityEntity[]>(e.ActivityIds?.map((id) => activities[id])),
                        }),
                    };
                });
                this.EmployeeResources.forEach(({ form }) => this.onEmployeeResourceJobSelectionChange(form));
                if (this.EmployeeResources.length === 0) {
                    this.AddResourceEmployee();
                }
            }),
        );
        this.IsBauAuftrag$ = combineLatest([
            merge(this.Commission$.pipe(map((commission) => ({ Id: commission.CommissionTypeId }))), this.DetailListTemplateEntrys.CommissionType.formControl.valueChanges),
            this.store.select(getCommissionTypes),
        ]).pipe(
            map(([commissionType, commissionTypes]) => commissionType?.Id && commissionType.Id === commissionTypes.find((cT) => cT?.Name === CommissionTypeNameEnum.Bauprojekt)?.Id),
            distinctUntilChanged(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngAfterViewInit(): void {
        this.Steps$ = combineLatest([
            this.Commission$.pipe(
                tap(commission => console.log(commission)),
                switchMap((c) => (c.CommissionTypeId ? this.store.select(getCommissionTypeById({ id: c.CommissionTypeId })).pipe(map((ct) => ct.AdditionalData?.WizardSteps)) : of(null))),
                map(
                    (steps) =>
                        steps || [
                            CommissionWizardSteps.Stammdaten,
                            CommissionWizardSteps.Adresse,
                            CommissionWizardSteps.Terminierung,
                            CommissionWizardSteps.Kapazitaet,
                            CommissionWizardSteps.Ressourcen,
                            CommissionWizardSteps.Fristen,
                            CommissionWizardSteps.Planungsdaten,
                            CommissionWizardSteps.Mitarbeiter,
                            CommissionWizardSteps.AllgDaten,
                        ],
                ),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            ),
            this.store.select(getPartner),
            combineLatest([
                this.Commission$,
                this.store.select(getCommissionTypeDictionary),
            ]).pipe(
                map(([comm, commissionTypeDictionary]) => commissionTypeDictionary[comm.CommissionTypeId]?.AdditionalData?.CanHaveChildCommissions),
                distinctUntilChanged(),
            )
        ]).pipe(
            map(([steps, partner, commissionTypeCanHaveChildren]) => {
                const Steps: { customSave?: () => void; name: string; customTemplate?: TemplateRef<any>; data?: Observable<IDetailListTemplateData>; controls?: FormArray }[] = [
                    steps.includes(CommissionWizardSteps.Stammdaten)
                        ? {
                              name: partner.PartnerTypeId === PartnerTypeEnum.TSB && commissionTypeCanHaveChildren ? 'Name Einsatzort': partner.PartnerTypeId === PartnerTypeEnum.TSB ? 'Kunde' : 'Stammdaten',
                              data: merge(this.Commission$.pipe(distinctUntilChanged((a, b) => a.CommissionTypeId === b.CommissionTypeId)), this.DetailListTemplateEntrys.CommissionType.formControl.valueChanges).pipe(
                                  map(() => ({
                                      Properties: [
                                          this.DetailListTemplateEntrys.Customer,
                                          // this.DetailListTemplateEntrys.CommissionType,
                                          // this.DetailListTemplateEntrys.InterneNummer,
                                          // this.DetailListTemplateEntrys.InterneNummerAuto,
                                          // this.DetailListTemplateEntrys.CommissonNumber,
                                          // this.DetailListTemplateEntrys.CommissonNumberAuto,
                                          this.DetailListTemplateEntrys.Sache,
                                          this.DetailListTemplateEntrys.ProjectInfo,
                                      ].filter(isNotNullOrUndefined),
                                  })),
                              ),
                            customTemplate: this.stepOneTemplate,
                              // controls: this.fb.array([this.DetailListTemplateEntrys.CommissionType.formControl]),
                          }
                        : undefined,
                    steps.includes(CommissionWizardSteps.Adresse)
                        ? {
                              name: partner.PartnerTypeId === PartnerTypeEnum.TSB ? 'Einsatzort' : 'Adresse',
                              customTemplate: this.addressTemplate,
                              data: of({
                                  Properties: [
                                      /*this.DetailListTemplateEntrys.CustomerLocation,*/
                                      /*this.DetailListTemplateEntrys.Location*/
                                  ].filter(isNotNullOrUndefined),
                              }),
                          }
                        : undefined,
                    steps.includes(CommissionWizardSteps.Terminierung)
                        ? {
                              name: 'Terminierung',
                              data: of({
                                  Properties: [
                                      this.DetailListTemplateEntrys.Auftragseingang,
                                      this.DetailListTemplateEntrys.HandoverDate,
                                      this.DetailListTemplateEntrys.StartDate,
                                      this.DetailListTemplateEntrys.PlanedStartDate,
                                      this.DetailListTemplateEntrys.EndDate,
                                      this.DetailListTemplateEntrys.PlanedEndDate,
                                      partner.PartnerSpecificationTypeId === PartnerSpecificationType.Construction ? this.DetailListTemplateEntrys.LetzteFrist : null,
                                  ].filter(isNotNullOrUndefined),
                              }),
                          }
                        : undefined,
                    steps.includes(CommissionWizardSteps.Kapazitaet)
                        ? {
                              name: 'Kapazität',
                              customSave: () => this.SaveResourceEmployees(),
                              customTemplate: this.capacityTemplate,
                          }
                        : undefined,
                    steps.includes(CommissionWizardSteps.Ressourcen)
                        ? {
                              name: 'Geräte',
                              customSave: () => this.SelectResourceComponent.Save(),
                              customTemplate: this.resourceTemplate,
                          }
                        : undefined,
                ].filter(isNotNullOrUndefined);
                const stepFristen = {
                    name: 'Fristen',
                    data: of({
                        Properties: [this.DetailListTemplateEntrys.AbgabeterminAG, this.DetailListTemplateEntrys.AbgabeterminQM, this.DetailListTemplateEntrys.LetzteFrist].filter(isNotNullOrUndefined),
                    }),
                };
                const stepPlanning = {
                    name: 'Planungsdaten',
                    data: of({
                        Properties: [this.DetailListTemplateEntrys.PlanedWorkingHours, this.DetailListTemplateEntrys.CompleteBusinessVolume, this.DetailListTemplateEntrys.CostsCompleteBusinessVolume].filter(isNotNullOrUndefined),
                    }),
                };
                if (steps.includes(CommissionWizardSteps.Fristen) && partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser) {
                    Steps.push(stepFristen);
                }
                if (steps.includes(CommissionWizardSteps.Planungsdaten) && partner.PartnerSpecificationTypeId === PartnerSpecificationType.Construction) {
                    Steps.push(stepPlanning);
                }
                if (steps.includes(CommissionWizardSteps.Mitarbeiter)) {
                    const stepMitarbeiter = {
                        name: 'Mitarbeiter',
                        customTemplate: this.selectTeamTemplate,
                    };
                    Steps.push(stepMitarbeiter);
                }
                if (steps.includes(CommissionWizardSteps.AllgDaten)) {
                    const stepAlgDaten = {
                        name: 'Allg. Daten',
                        data: this.IsBauAuftrag$.pipe(
                            map((isBauauftrag) => {
                                return {
                                    Properties: [
                                        this.DetailListTemplateEntrys.Versicherungssumme,
                                        partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? this.DetailListTemplateEntrys.Schadensdatum : null,
                                        partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? this.DetailListTemplateEntrys.Schadennummer : null,
                                        partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? this.DetailListTemplateEntrys.Versicherungsnummer : null,
                                        this.DetailListTemplateEntrys.AutomaticAccountsReceivableLedger,
                                        this.DetailListTemplateEntrys.AutomaticAccountsReceivableLedgerByClockIns,
                                        isBauauftrag ? this.DetailListTemplateEntrys.VorarbeiterBonus : null,
                                        isBauauftrag ? this.DetailListTemplateEntrys.NachlassZulage : null,
                                    ].filter(isNotNullOrUndefined),
                                };
                            }),
                        ),
                    };
                    Steps.push(stepAlgDaten);
                }
                return Steps;
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
        this.subscriptions.push(
            this.Commission$.pipe(
                filter(isNotNullOrUndefined),
                distinctUntilChanged((a, b) => a.Id === b.Id),
            ).subscribe((c) => {
                if (c.ParentId) {
                    firstValueFrom(this.store.select(getPartner)).then(partner => {
                        if (
                            partner.PartnerTypeId === PartnerTypeEnum.TSB
                        ) {
                            if (
                                !this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.value.Street &&
                                !this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.value.PostalCode &&
                                !this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.value.City &&
                                !this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.value.Country
                            ) {
                                this.onAddressFromChange('parent-commission')
                            } else {
                                // only set for frontend without setting address forms
                                this.addressFrom = 'parent-commission';
                            }
                        }
                    })
                }
                // ugly fix um die richtige seite aus zu wählen, den selectedIndex vom stepper setzen reicht nicht, da die vorherigen steps dann nicht completed sind und deshalb nicht weiter geklickt werden kann
                // der stepper ist zwar auch ohne den timeout schon da scheinbar wird der noch einmal ausgetauscht
                setTimeout(() => {
                    for (let i = 0; i < c.AdditionalData.wizardPage - 1; i++) {
                        this.Stepper.next();
                    }
                }, 100);
            }),
        );
    }

    public RemoveResourceEmployee(index: number) {
        this.EmployeeResources.splice(index, 1);
    }
    public AddResourceEmployee() {
        this.Commission$.pipe(filter(isNotNullOrUndefined), first()).subscribe((c) => {
            this.EmployeeResources.push({
                availableActivities$: this.store.select(getActivitiesActive),
                form: new FormGroup({
                    from: new FormControl(c.StartDate ? moment(c.StartDate) : c.PlannedStartDate ? moment(c.PlannedStartDate) : moment()),
                    to: new FormControl(c.EndDate ? moment(c.EndDate) : c.PlannedEndDate ? moment(c.PlannedEndDate) : moment()),
                    amount: new FormControl(null),
                    name: new FormControl(null),
                    jobSpecification: new FormControl(null),
                    color: new FormControl(null),
                    activities: new FormControl(null),
                }),
            });
        });
    }
    public SaveResourceEmployees() {
        this.employeeResScheduleFromCommission$.pipe(first()).subscribe((ers) => {
            const add = this.EmployeeResources.filter((e) => !e.entity && e.form.valid && e.form.value.amount);
            const change = this.EmployeeResources.filter((e) => e.entity && e.form.dirty);
            const remove = ers.filter((er) => !this.EmployeeResources.some((e) => e.entity?.Id === er.Id));

            add.forEach((e) => {
                const StartDate = e.form.value.from.toDate();
                StartDate.setHours(0, 0, 0, 0);
                const EndDate = e.form.value.to.toDate();
                EndDate.setHours(23, 59, 59, 999);

                this.store.dispatch(
                    EmployeeResourceScheduleActionTypes.Create({
                        Payload: {
                            amount: e.form.value.amount,
                            commissionId: this.CommissionId,
                            endDate: FrontendDate(EndDate),
                            startDate: FrontendDate(StartDate),
                            color: e.form.value.color ? { red: e.form.value.color.r, green: e.form.value.color.g, blue: e.form.value.color.b, opacity: e.form.value.color.a } : null,
                            name: e.form.value.name,
                            jobSpecificationId: e.form.value.jobSpecification?.Id || null,
                            activityIds: e.form.value.activities?.map((a) => a.Id),
                        },
                    }),
                );
            });
            change.forEach((e) => {
                const StartDate = e.form.value.from.toDate();
                StartDate.setHours(0, 0, 0, 0);
                const EndDate = e.form.value.to.toDate();
                EndDate.setHours(23, 59, 59, 999);
                this.store.dispatch(
                    EmployeeResourceScheduleActionTypes.Change({
                        Payload: {
                            amount: e.form.value.amount,
                            id: e.entity.Id,
                            endDate: FrontendDate(EndDate),
                            startDate: FrontendDate(StartDate),
                            color: e.form.value.color ? { red: e.form.value.color.r, green: e.form.value.color.g, blue: e.form.value.color.b, opacity: e.form.value.color.a } : null,
                            name: e.form.value.name,
                            jobSpecificationId: e.form.value.jobSpecification?.Id || null,
                            activityIds: e.form.value.activities?.map((a) => a.Id),
                        },
                    }),
                );
            });
            remove.forEach((e) => this.store.dispatch(EmployeeResourceScheduleActionTypes.Delete({ Payload: e.Id })));
        });
    }
    NextAndDetailsClick(steps) {
        if (this.Stepper.selectedIndex === steps.length - 1) {
            firstValueFrom(combineLatest([
                this.store.select(getPartner),
                this.store.select(getStatusFromBackoffice),
            ])).then(([partner, states]) => {
                if (partner.PartnerTypeId === PartnerTypeEnum.TSB) {
                    const state = states.find(s => s.Name === StatusFromBackofficeNames.ZurPlanung);
                    console.log('setStateToPlanung')
                    if (state) {
                        this.StatusBackofficeForm.setValue({
                            Name: '',
                            Id: state.Id,
                        }, {emitEvent: false})
                    }
                }
                this.NextClick(steps, true, ['/', this.CommissionPath, 'alle', this.CommissionId]);
            })
        } else {
            this.NextClick(steps, true, ['/', this.CommissionPath, 'alle', this.CommissionId]);
        }
    }
    NextClick(steps, closeAllDialogs = false, routerLink: any[] = null) {
        if (steps[this.Stepper.selectedIndex].customSave) {
            steps[this.Stepper.selectedIndex].customSave();
        }
        if (this.Stepper.selectedIndex !== steps.length - 1) {
            this.Save.emit({wizardPage: this.Stepper.selectedIndex + 2, routerLink});
            this.Stepper.next();
        } else {
            this.Save.emit({wizardPage: null, routerLink});
            if (closeAllDialogs) {
                // wait for someone's reaction on Save.emit()
                setTimeout(() => this.dialog.closeAll(), 1);
            }
        }
    }

    protected readonly getErrorMessage = getErrorMessage;
    activityToLabel = (a: ActivityEntity) => a.Name;

    addNewActivity(name: string, activities: FormControl<ActivityEntity[]>) {
        activities.disable();
        firstValueFrom(this.actions$.pipe(ofType(ActivityActions.updateOne, BaseActionTypes.ErrorAction))).then((a) => {
            activities.enable();
            if (a.type === ActivityActions.updateOne.type) {
                activities.setValue([...activities.value, a.Payload]);
            }
        });
        this.store.dispatch(ActivityActions.add({ Payload: { name } }));
    }

    onEmployeeResourceJobSelectionChange(
        formGroup: FormGroup<{
            from: FormControl<moment.Moment>;
            to: FormControl<moment.Moment>;
            amount: FormControl<number>;
            jobSpecification: FormControl<JobSpecificationEntity>;
            color: FormControl<Color>;
            name: FormControl<string>;
            activities: FormControl<ActivityEntity[]>;
        }>,
    ) {
        if (formGroup.controls.jobSpecification.value) {
            formGroup.controls.color.setValue(null);
            formGroup.controls.color.disable();
            formGroup.controls.name.setValue(null);
            formGroup.controls.name.disable();
        } else {
            formGroup.controls.color.enable();
            formGroup.controls.name.enable();
        }
    }

    HasRequiredField = hasRequiredField;
    SearchJobSpecification = (search: string, option: JobSpecificationEntity) => option.Name.toLowerCase().includes(search.toLowerCase());

    DeleteActivity(Option: ActivityEntity) {
        this.appDialog
            .OpenConfirmationDialog({
                heading: 'Aktivität löschen ?',
                paragraph: 'Möchten Sie die Tätigkeit ' + Option.Name + ' wirklich löschen?',
                styleDelete: true,
            })
            .subscribe(([del, s]) => {
                if (del) {
                    this.store.dispatch(ActivityActions.delete({ Payload: { id: Option.Id } }));
                }
            });
    }

    onAddressFromChange($event: typeof this.addressFrom) {
        this.addressFrom = $event;
        switch (this.addressFrom) {
            case 'customer':
                this.setAddressFromCustomer();
                break;
            case 'parent-commission':
                this.setAddressFromParentCommission();
                break;
        }
    }

    setAddressFromCustomer() {
        // firstValueFrom(this.Commission$).then(commission => {
            if (this.DetailListTemplateEntrys.Customer?.formControl.value?.Id) {
                firstValueFrom(this.store.select(getOfficeByCustomerId({id: this.DetailListTemplateEntrys.Customer.formControl.value.Id}))).then(customer => {
                    this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.setValue({
                        Street: customer.Street,
                        PostalCode: customer.PostalCode,
                        City: customer.City,
                        Country: customer.Country,
                    })
                })
            } else {
                this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.setValue({
                    Street: null,
                    PostalCode: null,
                    City: null,
                    Country: null,
                })
            }
        // })
    }
    setAddressFromParentCommission() {
        firstValueFrom(this.parentCommission$).then(commission => {
            if (commission) {
                this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.setValue({
                    Street: commission.Street,
                    PostalCode: commission.PostalCode,
                    City: commission.City,
                    Country: commission.Country,
                })
            }
            else {
                this.DetailListTemplateEntrys.Location.options.specialInput.location.formGroup.setValue({
                    Street: null,
                    PostalCode: null,
                    City: null,
                    Country: null,
                })
            }
        })
    }
}
