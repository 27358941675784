<div class="scroll-wrapper">
    <table mat-table class="full-width-table" matSort aria-label="Elements">
        <ng-container matColumnDef="Information">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Rechnungsaufstellung</th>
            <td mat-cell *matCellDef="let row">
                <mat-form-field floatLabel="never" *ngIf="row.expanded$ | async; else value" (click)="$event.stopPropagation()">
                    <input matInput [formControl]="row.form.controls.Information" />
                </mat-form-field>
                <ng-template #value>{{ row.form.controls.Information.value }}</ng-template>
            </td>
            <td mat-footer-cell *matFooterCellDef><ng-content select="[footer-Information]"></ng-content></td>
        </ng-container>

        <ng-container matColumnDef="BaseCost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Summe Netto</th>
            <td mat-cell *matCellDef="let row">
                <mat-form-field floatLabel="never" *ngIf="row.expanded$ | async; else value" (click)="$event.stopPropagation()">
                    <input matInput type="number" [formControl]="row.form.controls.BaseCost" />
                    <ng-container matSuffix>{{ row.currencySymbol }}</ng-container>
                </mat-form-field>
                <ng-template #value>{{ row.form.controls.BaseCost.value | currency: row.currencyCode }}</ng-template>
            </td>
            <td mat-footer-cell *matFooterCellDef><ng-content select="[footer-BaseCost]"></ng-content></td>
        </ng-container>
        <ng-container matColumnDef="Tax">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>MwSt.</th>
            <td mat-cell *matCellDef="let row">
                <mat-form-field floatLabel="never" *ngIf="row.expanded$ | async; else value" (click)="$event.stopPropagation()" class="small">
                    <input matInput type="number" [formControl]="row.form.controls.Tax" />
                    <ng-container matSuffix>%</ng-container>
                </mat-form-field>
                <ng-template #value>{{row.Tax$ | async | currency: row.currencyCode }} ({{ row.form.controls.Tax.value | number }} %)</ng-template>
            </td>
            <td mat-footer-cell *matFooterCellDef><ng-content select="[footer-Tax]"></ng-content></td>
        </ng-container>

        <ng-container matColumnDef="Amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Endsumme</th>
            <td mat-cell *matCellDef="let row">{{ row.Amount$ | async | currency: row.currencyCode }}</td>
            <td mat-footer-cell *matFooterCellDef><ng-content select="[footer-Amount]"></ng-content></td>
        </ng-container>
        <ng-container matColumnDef="AlreadyPayed">
            <th mat-header-cell *matHeaderCellDef>Bezahlte Summe (Netto)</th>
            <td mat-cell *matCellDef="let row">
                <mat-form-field floatLabel="never" *ngIf="row.expanded$ | async; else value" (click)="$event.stopPropagation()">
                    <input matInput type="number" [formControl]="row.form.controls.AlreadyPayed" />
                    <ng-container matSuffix>{{ row.currencySymbol }}</ng-container>
                </mat-form-field>
                <ng-template #value>{{ row.form.controls.AlreadyPayed.value | currency: row.currencyCode }}</ng-template>
            </td>

            <td mat-footer-cell *matFooterCellDef><ng-content select="[footer-AlreadyPayed]"></ng-content></td>
        </ng-container>
        <ng-container matColumnDef="InvoiceDate">
            <th mat-header-cell *matHeaderCellDef>Rechnungsdatum</th>
            <td mat-cell *matCellDef="let row">
                <mat-form-field floatLabel="never" *ngIf="row.expanded$ | async; else value" (click)="$event.stopPropagation(); $event.preventDefault()">
                    <input matInput [matDatepicker]="picker" [formControl]="row.form.controls.Date" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <ng-template #value>{{ row.form.controls.Date.value | date }}</ng-template>
            </td>
            <td mat-footer-cell *matFooterCellDef><ng-content select="[footer-InvoiceDate]"></ng-content></td>
        </ng-container>
        <ng-container matColumnDef="EditBtn">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <div class="btn-wrapper">
                    <button mat-icon-button *ngIf="row.expanded$ | async" (click)="$event.stopPropagation(); dataSource.expandRowToggle(row);"><fa-icon icon="chevron-up"></fa-icon></button>
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"><fa-icon icon="ellipsis-vertical"></fa-icon></button>
                    <mat-menu #menu>
                         <button mat-menu-item (click)="onRemoveArl(row); $event.stopPropagation()"><fa-icon icon="trash"></fa-icon> <span>löschen</span></button>
                    </mat-menu>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef><ng-content select="[footer-EditBtn]"></ng-content></td>
        </ng-container>
        <ng-container *ngFor="let colName of displayedColumnsCustomSubHeader" [matColumnDef]="colName">
            <td mat-cell *matHeaderCellDef>
                <ng-container [ngSwitch]="colName">
                    <ng-content *ngSwitchCase="'sub-header-Information'"    select="'[sub-header-Information]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-header-InvoiceDate'"    select="'[sub-header-InvoiceDate]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-header-BaseCost'"       select="'[sub-header-BaseCost]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-header-Tax'"            select="'[sub-header-Tax]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-header-Amount'"         select="'[sub-header-Amount]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-header-AlreadyPayed'"   select="'[sub-header-AlreadyPayed]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-header-EditBtn'"        select="'[sub-header-EditBtn]'"></ng-content>
                </ng-container>
            </td>
        </ng-container>

        <ng-container *ngFor="let colName of displayedColumnsCustomSubFooter" [matColumnDef]="colName">
            <td mat-footer-cell *matFooterCellDef>
                <ng-container [ngSwitch]="colName">
                    <ng-content *ngSwitchCase="'sub-footer-Information'"    select="'[sub-footer-Information]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-footer-InvoiceDate'"    select="'[sub-footer-InvoiceDate]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-footer-BaseCost'"       select="'[sub-footer-BaseCost]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-footer-Tax'"            select="'[sub-footer-Tax]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-footer-Amount'"         select="'[sub-footer-Amount]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-footer-AlreadyPayed'"   select="'[sub-footer-AlreadyPayed]'"></ng-content>
                    <ng-content *ngSwitchCase="'sub-footer-EditBtn'"        select="'[sub-footer-EditBtn]'"></ng-content>
                </ng-container>
            </td>
        </ng-container>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns?.length">
                <span>Keine Buchungspositionen vorhanden</span>
            </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="_showSubHeader$ | async">
            <tr mat-header-row *matHeaderRowDef="displayedColumnsCustomSubHeader"></tr>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="onTableRowClick(row, $event)" class="clickable-list-item" [class.is-highlighted]="row.expanded$ | async"></tr>
        <ng-container *ngIf="_showFooter$ | async">
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </ng-container>
        <ng-container *ngIf="_showSubFooter$ | async">
            <tr mat-footer-row *matFooterRowDef="displayedColumnsCustomSubFooter"></tr>
        </ng-container>
    </table>
</div>
<mat-paginator #paginator [length]="dataSource?.data$ | async | length" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" aria-label="Seite wählen"> </mat-paginator>
