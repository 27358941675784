<app-multi-select-table
    mat-dialog-content
    *ngIf="ContentResolved$ | async; else loading"
    [TableData]="FilteredTableData$ | async"
    [TableDataConfig]="TableDataConfig"
    [Selected]="selectedCustomers$ | async"
    (ClickSelected)="clickSelected($event)"
    (RemoveSelected)="RemoveSelected($event)"
    [EmptyText]="'Keine ' + (cls.getMultiple$('Customer') | async) + ' gefunden'"
    Headline=""
    [SearchPlaceHolder]="'nach ' + (cls.getMultiple$('Customer', 'Dativ') | async) + ' suchen'"
    [SelectedHeadline]="'Zugewiesene ' + (cls.getMultiple$('Customer') | async)"
    (SearchStringChange)="SearchString.next($event)"
    (RowClick)="$event.selectedForm.disabled ? {} : $event.selectedForm.setValue(!$event.selectedForm.value); this.dirty = true;"
>
    <div class="filter-wrapper">
        <button
            app-round-button
            Inverted
            [matBadge]="FilterAmount$ | async"
            matTooltip="Filter"
            [matBadgeOverlap]="true"
            [matBadgeHidden]="!(FilterAmount$ | async)"
            (click)="filter.Open()"
        >
            <fa-icon icon="filter"></fa-icon>
        </button>
        <app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings$ | async" [Values]="FilterValues$ | async" (ValuesChange)="FilterValues$.next($event)"></app-filter-card>
    </div>
</app-multi-select-table>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
