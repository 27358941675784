<h3 mat-dialog-title><fa-icon icon="info-circle"></fa-icon> Support</h3>
<mat-dialog-content>
    <p>Bei Fragen und Problemen melden Sie sich bei uns.</p>
    <mat-card class="card">
        <mat-card-header>
            <img
                mat-card-avatar
                src="{{HomepageBaseUrl}}storage/app/media/c2c/Team/Team-c2c-AB.jpg"
                alt=""
            />
            <mat-card-title>Alexander Bogdanow</mat-card-title>
            <mat-card-subtitle>Geschäftsführer</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="card-content">
            <a [href]="'tel:+491743003867'" class="link"><fa-icon icon="phone"></fa-icon>+49 174 3003867</a>
            <a [href]="'mailto:ab@c2c-erp.de'" class="link"><fa-icon icon="envelope"></fa-icon>ab@c2c-erp.de</a>
        </mat-card-content>
    </mat-card>
<!--    <mat-card class="card">-->
<!--        <mat-card-header>-->
<!--            <img-->
<!--                mat-card-avatar-->
<!--                src="{{HomepageBaseUrl}}storage/app/media/c2c/Team/Team-c2c-PG.jpg"-->
<!--                alt=""-->
<!--            />-->
<!--            <mat-card-title>Philipp Gauert</mat-card-title>-->
<!--            <mat-card-subtitle>Produktmanager</mat-card-subtitle>-->
<!--        </mat-card-header>-->
<!--        <mat-card-content class="card-content">-->
<!--            <a [href]="'tel:+491718146578'" class="link"><fa-icon icon="phone"></fa-icon>+49 171 8146578</a>-->
<!--            <a [href]="'mailto:pg@c2c-erp.de'" class="link"><fa-icon icon="envelope"></fa-icon>pg@c2c-erp.de</a>-->
<!--        </mat-card-content>-->
<!--    </mat-card>-->
<!--    <mat-card class="card">-->
<!--        <mat-card-header>-->
<!--            <img-->
<!--                mat-card-avatar-->
<!--                src="{{HomepageBaseUrl}}storage/app/media/c2c/Team/Team-c2c-MSB.jpg"-->
<!--                alt=""-->
<!--            />-->
<!--            <mat-card-title>Marcelo Schäfer-Buric</mat-card-title>-->
<!--            <mat-card-subtitle>Head of Sales & Marketing</mat-card-subtitle>-->
<!--        </mat-card-header>-->
<!--        <mat-card-content class="card-content">-->
<!--            <a [href]="'tel:+4915222373903'" class="link"><fa-icon icon="phone"></fa-icon>+49 152 22373903</a>-->
<!--            <a [href]="'mailto:msb@c2c-erp.de'" class="link"><fa-icon icon="envelope"></fa-icon>msb@c2c-erp.de</a>-->
<!--        </mat-card-content>-->
<!--    </mat-card>-->
    <!--<a href="{{HomepageBaseUrl}}tutorials" class="tutorials" target="_blank">
        <mat-card class="card">
            <mat-card-header>
                <fa-icon mat-card-avatar icon="film" size="2x"></fa-icon>
                <mat-card-title>Videotutorials</mat-card-title>
            </mat-card-header>
        </mat-card>
    </a>-->
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button app-button mat-dialog-close>OK</button>
</mat-dialog-actions>
