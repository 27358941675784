import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { FileDataService } from '../../../../../dave-data-module/services/file-data.service';
import { HttpService } from '../../../../../dave-data-module/services/http.service';
import { State } from '../../../../../dave-data-module/State';
import { Address } from '../../../../../helper/helper';
import { getErrorMessage } from '../../../../../helper/validation.helper';
import { SelectSearchData } from '../../../../select-search/components/select-search-legacy/select-search-legacy.component';
import { FormGroupTyped } from '../../../../typings';
import { MapDialogComponent } from '../../dialogs/map-dialog/map-dialog.component';
import { ChartOfAccountEntity } from '../../../../../dave-data-module/entities/chart-of-Account.entity';
import {
    EmailEditorComponent, EmailEditorComponentDialogConfig,
    EmailEditorComponentDialogData,
} from '../../../../../dave-email-module/components/email-editor/email-editor.component';

export enum CustomPropertyType {
    Mailto,
    Tel,
    Link,
    Title,
    Subtitle,
    Location,
    Datum,
    Boolean,
    DetailDialog,
    /**
     * range from 0% to 100% by value from 0 to 1
     */
    Percentage,
    Jobspecification,
    colorPicker,
    RouterLink,
}

export interface ProfileTemplateDataPropertySpecialInputType {
    select?: { optionValue: any; optionLabel: string }[];
    selectSearch?: { optionValue: any; optionLabel: string }[];
    location?: { value: Address; formGroup?: FormGroupTyped<Address> | AbstractControlTyped<Address> };
    chipAutocomplete?: { MapFn: (option) => string; Options: ReadonlyArray<any> };
    date?: boolean | { matDatepickerFilter?: (date) => boolean };
    boolean?: boolean;
    number?: boolean;
    customTemplate?: TemplateRef<any>;
    weekDaySelector?: 'checkbox' | 'number';
}
export interface IProfileTemplateDataProperty {
    icon?: IconProp;
    value?: string | number ;
    key?: string;
    formControl?: UntypedFormControl | AbstractControlTyped<any>;
    hideFormControl?: boolean;
    options?: {
        type?: CustomPropertyType;
        specialInput?: ProfileTemplateDataPropertySpecialInputType;
        showHint?: string;
        suffix?: string;
        pattern?: string | RegExp;
        routerLinkPayload?: {
            routerLink?: any[] | string | null;
            queryParams?: {[key: string]: any};
        }
    };
}
export interface IProfileTemplateData {
    Icon?: IconProp;
    Properties?: IProfileTemplateDataProperty[];
}

@Component({
    selector: 'app-profile-template',
    templateUrl: './profile-template.component.html',
    styleUrls: ['./profile-template.component.scss'],
})
export class ProfileTemplateComponent implements OnChanges {
    @Input() Data: IProfileTemplateData;
    @Input() Editing = false;
    @Input() EditedImage: SafeUrl;
    @Output() Submit = new EventEmitter<void>();
    // nur für das html form
    public FormGroup = new UntypedFormGroup({});
    public WeekDayNames = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

    public DataWithValues: IProfileTemplateData;
    public PropertyType = CustomPropertyType;
    public Title: string;
    public Subtitle: string;
    public EditableProperties$ = new ReplaySubject<IProfileTemplateData['Properties']>(1);
    public CustomPropertyType = CustomPropertyType;
    public ImagePath$: Observable<string>;

    GetErrorMessage = (formControl) => getErrorMessage(formControl);
    constructor(public Dialog: MatDialog, private store: Store<State>, private api: HttpService, private fileDataService: FileDataService) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.Data && changes.Data.currentValue && changes.Data.currentValue) {
            if (changes.Data.currentValue.Properties) {
                const subtitle = changes.Data.currentValue.Properties.find((p) => p.options && p.options.type === CustomPropertyType.Subtitle);
                const title = changes.Data.currentValue.Properties.find((p) => p.options && p.options.type === CustomPropertyType.Title);
                this.Title = title && title.value;
                this.Subtitle = subtitle && subtitle.value;
            }
            this.DataWithValues = {
                ...changes.Data.currentValue,
                Properties: changes.Data.currentValue.Properties
                    ? changes.Data.currentValue.Properties.filter((p) => p.value && (!p.options || p.options.type !== CustomPropertyType.Subtitle) && (!p.options || p.options.type !== CustomPropertyType.Title))
                    : null,
            };
        }

        if (changes.Data?.currentValue?.Properties) {
            this.EditableProperties$.next(
                (changes.Data.currentValue as ProfileTemplateComponent['Data']).Properties.filter(
                    (property) => (property.formControl || property.options?.specialInput?.location?.formGroup || property.options?.specialInput?.customTemplate) && property.key,
                ),
            );
        }
    }

    public OpenMapDialog(location: Address) {
        this.Dialog.open(MapDialogComponent, {
            ...MapDialogComponent.DefaultConfig,
            data: location,
        });
    }
    public OpenMailDialog(targets: string[]) {
        this.Dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
            ...EmailEditorComponentDialogConfig,
            data: {
                TargetEmails:targets.filter(v => !!v),
            },
        });
    }
    public MapSelectInputToSelectSearchInput(values: { optionValue: any; optionLabel: string }[]) {
        return values.map((v) => {
            return { Name: v.optionLabel, Id: typeof v.optionValue === 'number' ? v.optionValue : -1 } as SelectSearchData;
        });
    }
    public UpdateArrayValue(formControl: UntypedFormControl, index: number, value: any) {
        const arr: any[] = formControl.value.slice();
        arr[index] = value;
        formControl.setValue(arr);
    }
}
