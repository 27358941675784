import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResolveData, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay, skip, take, tap } from 'rxjs/operators';
import { State } from '../../../dave-data-module/State';
import { getResourceTypeDictionary, getResourceTypes } from '../../../dave-data-module/State/selectors/resource-dispo/resource-type.selectors';
import {
    getResourcesActive,
} from '../../../dave-data-module/State/selectors/resource-dispo/resource.selectors';
import { DaveListTableData } from '../../../dave-list/components/dave-list/dave-list.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import {FilterOption, FILTER_TYPE_MULTI_SELECT, IFilterTypeSearchMultiSelectValue, FILTER_TYPE_SEARCH_MULTI_SELECT} from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { isNotNullOrUndefined, stringSearch, TableColumnConfig } from '../../../helper/helper';
import { NewResourcePageMeta, ResourcePageMeta } from '../../../helper/page-metadata';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../services/default-filter.service';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import { RESOURCE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource.reducer';
import { ResourceResolver } from '../../../dave-data-module/guards/resource-dispo/resource.resolver';
import { RESOURCE_TYPE_KEY } from '../../../dave-data-module/State/reducers/resource-dispo/resource-type.reducer';
import { ResourceTypeResolver } from '../../../dave-data-module/guards/resource-dispo/resource-type.resolver';
import {isArray} from "chart.js/helpers";
import * as Sentry from '@sentry/angular-ivy';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import {
    getQuantityTypeDictionary,
    getQuantityTypesFetched,
} from '../../../dave-data-module/State/selectors/accounting.selector';
import { formatCurrency } from '@angular/common';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { getSetting, getSettingFetched } from '../../../dave-data-module/State/selectors/users.selectors';
import { getPartner, getPartnerFetched } from '../../../dave-data-module/State/selectors/partners.selectors';
import { ChangeSettings } from '../../../dave-data-module/State/actions/settings.actions';

interface TableData extends DaveListTableData {
    Name: string;
    Type: string;
    Kostenstelle: string;
    Manufacturer: string;
    ArticleNumber: string;
    routerLink: string | string[];
    id: number;
    cssClass?: string | string[] | Set<string> | { [klass: string]: any };
    clickable: boolean;
    Cost: string;
    QuantityType: string;
}
@Component({
    selector: 'app-resource-list',
    templateUrl: './resource-list.component.html',
    styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent implements OnInit, AfterViewInit {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        [RESOURCE_KEY]: ResourceResolver,
        [RESOURCE_TYPE_KEY]: ResourceTypeResolver,
    };
    private availableColumns: Array<keyof TableData> = ['Name', 'Kostenstelle', 'ArticleNumber', 'Type', 'Cost', 'QuantityType', 'Manufacturer'];
    public PageMeta = ResourcePageMeta;
    public NewPageMeta = NewResourcePageMeta;
    public DataSource$: Observable<TableVirtualScrollDataSource<TableData>>;
    /** Gibt ein Mal einen Wert aus, wenn der ngAfterViewInit Hook ausgelöst wird */
    private afterViewInit$ = new Subject<void>();
    public TableColumns: TableColumnConfig<TableData>[] = [
        { header: 'Name', name: 'Name' },
        { header: 'Art', name: 'Type' },
        { header: 'Kostenstelle', name: 'Kostenstelle' },
        { header: 'Hersteller', name: 'Manufacturer' },
        { header: 'Artikelnummer', name: 'ArticleNumber' },
        { header: 'Max. Verrechnungssatz', name: 'Cost'},
        { header: 'Verkaufseinheit', name: 'QuantityType'}
    ];
    public DisplayedColumns: Array<keyof TableData> = [
        'ArticleNumber',
        'Name',
        'Cost',
        'QuantityType',
        'Type',
        'Kostenstelle',
        'Manufacturer',
    ];
    public DisplayedColumnsSmall: Array<keyof TableData> = ['ArticleNumber', 'Name',];

    public FilterValues$: BehaviorSubject<{
        [FilterTypes.TypeIds]: IFilterTypeSearchMultiSelectValue<number>[];
    }> = new BehaviorSubject({
        [FilterTypes.TypeIds]: [],
    });
    public FilterCount$ = this.FilterValues$.pipe(map((filter) => (filter[FilterTypes.TypeIds]?.length ? 1 : 0)));

    public FilterSettings$: Observable<FilterOption[]> = this.store.select(getResourceTypes).pipe(
        map((types) => {

            let typeValues: IFilterTypeSearchMultiSelectValue<number>[] = types.map((t) => ({
                id: t.Id,
                label: t.Name,
            }));
            return [
                {
                    Name: FilterTypes.TypeIds,
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Art',
                    Values: typeValues,
                    // todo add Icon,
                },
            ];
        }),
    );
    private subs: Subscription[] = [];
    constructor(
        private store: Store<State>,
        private router: Router,
        private dialog: MatDialog,
        private dialogService: AppDialogService,
        private defaultFilterService: DefaultFilterService,
        quantityTypeResolver: QuantityTypeResolver,
    ) {
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then(fetched => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        })
        this.subs.push(
            this.FilterValues$.pipe(skip(2)).subscribe((val) => {
                if (Object.keys(val)?.length) {
                    defaultFilterService.SetFilterByApp(FilterApps.ResourceList, val);
                }
            }),
        );
        this.DataSource$ = combineLatest([
            this.store.select(getResourcesActive),
            this.store.select(getResourceTypeDictionary),
            // this.afterViewInit$, // gibt erst etwas aus, nachdem Angular this.matSort gesetzt hat
            getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeDictionary),
            this.FilterValues$,
        ]).pipe(
            map(([resources, types, quantityType, filter]) => {
                return new TableVirtualScrollDataSource(
                    resources
                        .filter((p) => !filter[FilterTypes.TypeIds]?.length || filter[FilterTypes.TypeIds].some( ti => ti.id === p.ResourceTypeId))
                        .map<TableData>((resource) => {
                            if (!types[resource.ResourceTypeId]) {
                                const msg = 'ResourceType mit id ' + resource.ResourceTypeId + ' nicht gefunden';
                                console.error(msg, resource)
                                Sentry.captureException(msg, {
                                    level: 'error',
                                    contexts: {
                                        auth: { Bearer: localStorage.getItem('token') },
                                    },
                                    extra: {
                                        resourceEntity: resource,
                                    },
                                });
                            }
                            return {
                                Type: types[resource.ResourceTypeId]?.Name || '',
                                Name: resource.Name,
                                Kostenstelle: resource.Kostenstelle,
                                Manufacturer: resource.Manufacturer,
                                ArticleNumber: resource.ArticleNumber,
                                id: resource.Id,
                                routerLink: resource.Id + '',
                                clickable: true,
                                tooltip: null,
                                Cost: resource.Cost && formatCurrency(resource.Cost / 100, 'de-DE', '€'),
                                QuantityType: resource?.QuantityTypeId ? quantityType[resource.QuantityTypeId]?.Name : null,
                            };
                        }),
                );
            }),

            // Der Standard-`sortingDataAccessor` kommt mit numerischen Strings - wie der
            // Vertragsnummer - nicht klar und sortiert nicht. Workaround:
            tap(
                (dataSource) =>
                    (dataSource.sortingDataAccessor = (object, key) => {
                        switch (key) {
                            default:
                                return `${object[key]}`.trim().toLowerCase();
                        }
                    }),
            ),

            // Vergleichsfunktion zum Freitext-Filtern
            tap((dataSource) => (dataSource.filterPredicate = (data, searchTerm) => [
                data.Name,
                data.Type,
                data.Kostenstelle,
                data.Manufacturer,
                data.ArticleNumber,
            ].some((value) => stringSearch(value || '', searchTerm)))),
        );
    }

    ngOnInit(): void {
        this.defaultFilterService
            .GetFilterByApp$(FilterApps.ResourceList)
            .pipe(take(1))
            .subscribe((filterValues) => {
                this.FilterValues$.next({
                    [FilterTypes.TypeIds]: (isNotNullOrUndefined(filterValues[FilterTypes.TypeIds]) && isArray(filterValues[FilterTypes.TypeIds])) ? filterValues[FilterTypes.TypeIds] : [],
                });
            });
    }
    ngAfterViewInit() {
        this.afterViewInit$.next();
    }
    selectedColumns$: Observable<Array<keyof TableData>> = combineLatest([getFetched$(this.store, getSettingFetched, getSetting), getFetched$(this.store, getPartnerFetched, getPartner)]).pipe(
        map(([settings, partner]) => {
            if (settings.ResourceListComponentDisplayedColumns) {
                return settings.ResourceListComponentDisplayedColumns.map((k) => this.availableColumns.find((column) => column === k)).filter(isNotNullOrUndefined);
            } else if (partner.AdditionalData?.ResourceListComponentDisplayedColumns) {
                return partner.AdditionalData.ResourceListComponentDisplayedColumns.map((k) => this.availableColumns.find((column) => column === k)).filter(isNotNullOrUndefined);
            } else {
                return <Array<keyof TableData>>['ArticleNumber',
                    'Name',
                    'Cost',
                    'QuantityType',
                    'Type',
                    'Kostenstelle',
                    'Manufacturer'];
            }
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
    );

    public TableColumnsSorted$: Observable<TableColumnConfig<TableData>[]> = this.selectedColumns$.pipe(
        map((selected) => {
            return this.TableColumns.sort((a, b) => selected.indexOf(a.name) - selected.indexOf(b.name));
        }),
    );

    selectedColumnsChange(selectedColumns: Array<keyof TableData>) {
        this.store.dispatch(
            new ChangeSettings({
                ResourceListComponentDisplayedColumns: selectedColumns,
            }),
        );
    }

    disabledColumns$ = combineLatest([this.TableColumnsSorted$, this.selectedColumns$]).pipe(map(([all, selected]) => all.length - selected.length || undefined));

}
