export const getEmailFromAuthor = (author: string) => {
    const mailMatch = author?.match('<(.*?)>');
    if (mailMatch && mailMatch[1]) {
        return mailMatch[1];
    }
    return author;
}
export function parseEmailHeaderFrom(message: string) {
    const header = message.trim();

    // 1. John Miranda <john@gmail.com>
    // 2. john@gmail.com

    const extract = { name: '', email: '' };

    const emails = header.match(/[^@<\s]+@[^@\s>]+/g);

    if (emails) {
        extract.email = emails[0];
    }

    const names = header.split(/\s+/);

    if (names.length > 1) {
        names.pop();
        extract.name = names.join(' ').replace(/"/g, '');
    }
    return extract
}
