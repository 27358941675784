<h1 mat-dialog-title>{{ title }}</h1>
<mat-dialog-content>
    <h4 class="mt-0">Angebot auswählen</h4>
    <app-ledger-import-select-table [selectableLedgerImportIds]="selectableLedgerImports$ | async" (selectedLedgerImports)="selectedLedgerImportChange($event.length ? $event[0] : undefined)"></app-ledger-import-select-table>

    <h4 class="mb-0">Abschlag auswählen</h4>
    <div class="sum-inputs">
        <mat-form-field [appearance]="!amountForm.value.usePercent ? 'outline' : undefined">
            <mat-label>Abschlag ({{ currencyCode$ | async }})</mat-label>
            <input type="number" [ngModel]="amountForm.value.decimal" (ngModelChange)="setDecimal($event)" matInput />
            <span matSuffix>{{ currencyCode$ | async }}</span>
        </mat-form-field>
        <mat-form-field [appearance]="amountForm.value.usePercent ? 'outline' : undefined">
            <mat-label>Abschlag (%)</mat-label>
            <input type="number" [ngModel]="amountForm.value.percent" (ngModelChange)="setPercent($event)" matInput />
            <span matSuffix>%</span>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button type="button" app-button Color="cancel" [mat-dialog-close]="undefined" Inverted>abbrechen</button>
    <button type="button" app-button Color="green" [IsLoading]="ls.IsLoading$ | async" [Disabled]="!(selectedLedgerImportId$ | async)" (click)="submit()">Speichern</button>
</mat-dialog-actions>
