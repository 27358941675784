import { DatePipe, formatCurrency, formatNumber } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Store } from '@ngrx/store';
import { ChartData, ChartDataset, LegendItem, TooltipItem } from 'chart.js';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, switchMap } from "rxjs";
import { distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';
import { MilestoneEntity, MilestoneEntityState } from 'src/app/dave-data-module/entities/milestone.entity';
import { MilestoneResolver } from 'src/app/dave-data-module/guards/milestone.resolver';
import { getMilestonesActive, getMilestonesFetched } from 'src/app/dave-data-module/State/selectors/milestone.selector';
import { BusinessVolumeCostStateEnum, BusinessVolumeStatusEnum, BusinessVolumeStatusEnumNameMap } from '../../../dave-data-module/entities/business-volume.entity';
import { ClockInTypeEntity, EnumClockInTypeSlug } from '../../../dave-data-module/entities/clock-in-type.entity';
import {
    EventEntity,
    EventStateEnum,
    EventStateEnumNameMap,
    taskEventStates,
} from '../../../dave-data-module/entities/event.entity';
import {
    LedgerImportDocumentTypes,
    LedgerImportStatusEnum
} from "../../../dave-data-module/entities/ledger-import.entity";
import { QuantityTypeNames } from '../../../dave-data-module/entities/quantity-type.entity';
import { BusinessVolumeResolver } from '../../../dave-data-module/guards/business-volume.resolver';
import { ClockInTypeResolver } from '../../../dave-data-module/guards/clock-in-type.resolver';
import { WorkedTimesResolver } from '../../../dave-data-module/guards/worked-times.resolver';
import { State } from '../../../dave-data-module/State';
import { getAccountsReceivableLedgerById, getQuantityTypes } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getBVCustomNameFetched, getBVCustomNamesActive } from '../../../dave-data-module/State/selectors/b-v-custom-name.selector';
import { getBusinessVolume, getBusinessVolumeFetched } from '../../../dave-data-module/State/selectors/business-volume.selector';
import { getClockInTypes, getClockInTypesFetched } from '../../../dave-data-module/State/selectors/clock-in-type.selectors';
import { getCommissionById } from '../../../dave-data-module/State/selectors/commission.selector';
import { getConstructionDiaries } from '../../../dave-data-module/State/selectors/construction-diary.selectors';
import {
    getEventDictionary,
    getEventFetched,
    getTasks,
} from '../../../dave-data-module/State/selectors/events.selectors';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getWorkDayDictionary } from '../../../dave-data-module/State/selectors/work-day.selectors';
import { getWorkedTimes, getWorkedTimesFetched } from '../../../dave-data-module/State/selectors/worked-times.selectors';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { HourListComponent, HourListComponentDialogData } from '../../../dave-time-tracker/components/hour-list/hour-list.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT, IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { isNotNullOrUndefined, sameDay } from '../../../helper/helper';
import { CalendarPageMeta, CommissionMeta, ConstructionDiaryIcon, TaskPageMeta, UserAdministrationMeta } from '../../../helper/page-metadata';
import { ProgressBarEvent } from '../../../progress-bar/progress-timeline.component';
import { CommissionBusinesVolumeListComponent, CommissionBusinesVolumeListComponentDialogData } from '../commission-busines-volume-list/commission-busines-volume-list.component';
import { CustomDoughnutChartOptions } from './components/doughnut-chart/doughnut-chart.component';
import { DoughnutChartWrapperComponent, DoughnutChartWrapperComponentDialogData } from './components/doughnut-wrapper-dialog/doughnut-chart-wrapper.component';
import { TaskBoardPopupComponent, TaskBoardPopupComponentDialogData } from './components/task-board-popup/task-board-popup.component';
import { FilterCardComponent } from "../../../dave-utils-module/app-filter-module/filter-card/filter-card.component";
import { BVCustomNameResolver } from "../../../dave-data-module/guards/b-v-custom-name.resolver";
import { WorkedTimesEntity } from "../../../dave-data-module/entities/worked-times.entity";
import { getFetched$ } from "../../../dave-data-module/helper/helper";
import {
    MilestoneModalComponent,
    MilestoneModalComponentDialogData
} from "./components/milestone-modal/milestone-modal.component";
import {
    PermissionService
} from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { Dictionary } from '@ngrx/entity/src/models';

interface bvFilter {
    customNames: IFilterTypeSearchMultiSelectValue[];
}
const dayCount = 7;
const colors = {
    red: 'rgb(192,19,19)',
    orange: 'rgb(239,172,50)',
    yellow: 'rgb(189,204,53)',
    green: 'rgb(109,255,86)',
    darkGreen: 'rgb(80,155,56)',
    blue: 'rgb(82,166,248)',
    grey: '#a4a4a4',
    border: '#fafafa',
    Cyan: 'rgb(184,254,253)',
    pastellLavendel: 'rgb(214,151,245)',
};
const clockInTypeColorSlugMap = new Map<EnumClockInTypeSlug, string>([
    [EnumClockInTypeSlug.workTime, colors.green],
    [EnumClockInTypeSlug.badWeather, colors.orange],
    [EnumClockInTypeSlug.drivingTime, colors.yellow],
    [EnumClockInTypeSlug.businessTrip, colors.blue],
    [EnumClockInTypeSlug.break, colors.grey],
    [EnumClockInTypeSlug.commissionWorkTime, colors.green],
    [EnumClockInTypeSlug.waitingTime, colors.Cyan],
    [EnumClockInTypeSlug.other, colors.pastellLavendel],
]);
function getColorClockInType(ct: ClockInTypeEntity) {
    if (ct.Slug && clockInTypeColorSlugMap.has(ct.Slug)) {
        return clockInTypeColorSlugMap.get(ct.Slug);
    }
    return colors.darkGreen;
}
const defaultDataset: Partial<ChartDataset<'doughnut', number[]>> = {
    hoverOffset: 4,
    borderWidth: 1,
    borderColor: colors.border,
};
@Component({
    selector: 'app-commission-overview',
    templateUrl: './commission-overview.component.html',
    styleUrls: ['./commission-overview.component.scss'],
    providers: [DatePipe],
})
export class CommissionOverviewComponent {
    @Input() set CommissionId(id: number) {
        this.CommissionId$.next(id);
    }
    get CommissionId() {
        return this.CommissionId$.value;
    }
    @ViewChild('taskChart') taskChart: DoughnutChartWrapperComponent;
    @ViewChild('timeChart') timeChart: DoughnutChartWrapperComponent;
    @ViewChild('bvChart') bvChart: DoughnutChartWrapperComponent;
    @ViewChild('bvCostChart') bvCostChart: DoughnutChartWrapperComponent;
    @ViewChild('listCardDialogFooter') listCardDialogFooter: TemplateRef<any>;
    @ViewChild('customNameIdsFormTemplate') customNameIdsFormTemplate: TemplateRef<any>;
    @ViewChild('bvFilter') bvFilter: FilterCardComponent;
    @ViewChild('costFilter') costFilter: FilterCardComponent;

    public TaskIcon = TaskPageMeta.Icon;
    public DayCount = dayCount;
    public ToWeek = moment().isoWeek();
    public FromWeek = moment().add(-dayCount, 'day').isoWeek();
    private filePreviewDialog: MatDialogRef<any>;
    public BVListDialogRef: MatDialogRef<any>;

    private taskIndexState = [
        {
            label: EventStateEnumNameMap.get(EventStateEnum.New),
            state: EventStateEnum.New,
            backgroundColor: colors.blue,
            borderColor: colors.border,
            onClick: () => this.openTaskPopup(EventStateEnum.New),
        },
        {
            label: EventStateEnumNameMap.get(EventStateEnum.InProgress),
            state: EventStateEnum.InProgress,
            backgroundColor: colors.orange,
            borderColor: colors.border,
            onClick: () => this.openTaskPopup(EventStateEnum.InProgress),
        },
        {
            label: EventStateEnumNameMap.get(EventStateEnum.Done),
            state: EventStateEnum.Done,
            backgroundColor: colors.green,
            borderColor: colors.border,
            onClick: () => this.openTaskPopup(EventStateEnum.Done),
        },
        {
            label: 'Überfällig',
            state: null,
            backgroundColor: colors.red,
            borderColor: colors.border,
            onClick: () => this.openTaskPopup(null, true),
        },
        {
            label: null,
            state: null,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            onClick: () => {},
        },
    ];
    private businessVolumeIndexState = [
        {
            label: BusinessVolumeStatusEnumNameMap.get(BusinessVolumeStatusEnum.Booked),
            state: BusinessVolumeStatusEnum.Booked,
            backgroundColor: colors.green,
            borderColor: colors.border,
            onClick: (type: BusinessVolumeCostStateEnum) => this.openBVPopup(BusinessVolumeStatusEnum.Booked, type),
        },
        {
            label: BusinessVolumeStatusEnumNameMap.get(BusinessVolumeStatusEnum.Open),
            state: BusinessVolumeStatusEnum.Open,
            backgroundColor: colors.blue,
            borderColor: colors.border,
            onClick: (type: BusinessVolumeCostStateEnum) => this.openBVPopup(BusinessVolumeStatusEnum.Open, type),
        },
        {
            label: BusinessVolumeStatusEnumNameMap.get(BusinessVolumeStatusEnum.Notbooked),
            state: BusinessVolumeStatusEnum.Notbooked,
            backgroundColor: colors.orange,
            borderColor: colors.border,
            onClick: (type: BusinessVolumeCostStateEnum) => this.openBVPopup(BusinessVolumeStatusEnum.Notbooked, type),
        },
        {
            label: 'noch zu leisten',
            state: null,
            backgroundColor: colors.grey,
            borderColor: colors.border,
            onClick: null,
        },
        {
            label: 'Abweichung',
            state: null,
            backgroundColor: colors.red,
            borderColor: colors.border,
            onClick: null,
        },
        {
            label: null,
            state: null,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            onClick: (type: BusinessVolumeCostStateEnum) => {},
        },
    ];
    private businessVolumeCostIndexState = [
        {
            label: BusinessVolumeStatusEnumNameMap.get(BusinessVolumeStatusEnum.Payed),
            state: BusinessVolumeStatusEnum.Payed,
            backgroundColor: colors.green,
            borderColor: colors.border,
            onClick: (type: BusinessVolumeCostStateEnum) => this.openBVPopup(BusinessVolumeStatusEnum.Payed, type),
        },
        {
            label: BusinessVolumeStatusEnumNameMap.get(BusinessVolumeStatusEnum.Open),
            state: BusinessVolumeStatusEnum.Open,
            backgroundColor: colors.blue,
            borderColor: colors.border,
            onClick: (type: BusinessVolumeCostStateEnum) => this.openBVPopup(BusinessVolumeStatusEnum.Open, type),
        },
        {
            label: 'Abweichung',
            state: null,
            backgroundColor: colors.red,
            borderColor: colors.border,
            onClick: null,
        },
        {
            label: null,
            state: null,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            onClick: null,
        },
    ];

    private clockInTypes$ = this.store.select(getClockInTypesFetched).pipe(
        tap((fetched) => {
            if (!fetched) {
                this.clockInTypeResolver.resolve();
            }
        }),
        filter((fetched) => !!fetched),
        switchMap(() => this.store.select(getClockInTypes)),
        map((types) => types.filter((t) => t.IsWorkingTime)),
    );
    private timeIndexState$ = this.clockInTypes$.pipe(
        map((types) => [
            ...types
                .filter((t) => t.Slug !== EnumClockInTypeSlug.commissionWorkTime)
                .sort((a, b) => (a.Slug === EnumClockInTypeSlug.workTime ? -1 : b.Slug === EnumClockInTypeSlug.workTime ? 1 : 0))
                .map((t) => ({
                    label: t.Name,
                    backgroundColor: getColorClockInType(t),
                    borderColor: colors.border,
                })),
            {
                label: 'Offen',
                backgroundColor: colors.grey,
                borderColor: colors.border,
            },
            {
                label: 'Abweichung',
                backgroundColor: colors.red,
                borderColor: colors.border,
            },
            {
                label: null,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
            },
        ]),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public CommissionId$ = new BehaviorSubject<number>(null);
    public TimelineFillWidth$: Observable<{
        start: Date;
        end: Date;
        fillDate: Date;
        pointer$: Observable<ProgressBarEvent[]>;
    }> = this.CommissionId$.pipe(
        switchMap((id) => this.store.select(getCommissionById({ id }))),
        filter(isNotNullOrUndefined),
        map((commission) => {
            const start = commission.StartDate || commission.PlannedStartDate;
            const end = commission.EndDate || commission.PlannedEndDate;
            const now = new Date();
            if (start && end) {
                 var state = (date: Date): number => {
                    const v = 100 - ((end.getTime() - date.getTime()) / (end.getTime() - start.getTime())) * 100;
                    return v > 100 ? 100 : v < 0 ? 0 : v;
                };
                const d = new Date(start);
                const months: Date[] = [];
                if (start.getTime() < end.getTime()) {
                    while (d.getTime() < end.getTime()) {
                        d.setMonth(d.getMonth() + 1);
                        if (d.getTime() < end.getTime()) {
                            months.push(new Date(d.getFullYear(), d.getMonth()));
                        }
                    }
                }
            }
            else {
                var state = (date: Date): number => 0
            }
            const getPointer = (milestones: MilestoneEntity[], workTimes: WorkedTimesEntity[], events: Dictionary<EventEntity>): ProgressBarEvent[] => {
                return (
                    milestones
                        //.filter((e) => moment(e.Deadline).isBetween(start, end))
                        .sort((a, b) => {
                            if (a.Deadline == null && b.Deadline == null) {
                                return a.CreatedAt.getTime() - b.CreatedAt.getTime();
                            } else if (a.Deadline == null) {
                                return 1;
                            } else if (b.Deadline == null) {
                               return -1;
                            }
                            return a.Deadline.getTime() - b.Deadline.getTime()
                        })
                        .map((e, index) => {
                            const showProgress = e.ClockInTypeId && isNotNullOrUndefined(e.TargetHours);
                            const workedTimespan = showProgress && workTimes.filter(w => w.ClockInTypeId === e.ClockInTypeId && w.CommissionId === e.CommissionId).reduce((prev, curr) => prev + curr.Timespan, 0);
                            const workedHours = isNotNullOrUndefined(workedTimespan) ? workedTimespan / 1000 / 60 / 60 : null;
                            const tasks = e.EventIds?.map(id => events[id]).filter(e => e?.IsTask);
                            const doneTasks = tasks?.filter(t => t.State === EventStateEnum.Done);
                            const hasSubEvents = tasks?.length > 0;
                            return {
                                label: index + 1,
                                date: e.Deadline,
                                tooltip: e.Name,
                                ready: e.State === MilestoneEntityState.Done,
                                desc: e.Description,
                                entity: e,
                                progress: showProgress && workedHours,
                                progressTarget: e.TargetHours,
                                progressLabel: showProgress ? formatNumber(workedHours, this.locale, '1.0-1') + '/' + formatNumber(e.TargetHours, this.locale, '1.0-1') + ' Std.' : null,
                                subEventIcon: hasSubEvents ? TaskPageMeta.Icon : undefined,
                                subEventCount: hasSubEvents ? doneTasks?.length : undefined,
                                subEventCountTarget: hasSubEvents ? tasks?.length : undefined,
                            };
                        })
                    );
                }
                return {
                    fillDate: now,
                    fillState: state(now),
                    start,
                    end,

                    pointer$: combineLatest([
                        getFetched$(this.store, getMilestonesFetched, getMilestonesActive, this.mstr).pipe(map( milestones => milestones.filter((item) => item.CommissionId === commission.Id))),
                        getFetched$(this.store, getWorkedTimesFetched, getWorkedTimes).pipe(map(workedTimes => workedTimes.filter((item) => item.CommissionId === commission.Id))),
                        getFetched$(this.store, getEventFetched, getEventDictionary)
                    ]).pipe(
                        map(([filteredMilestones, workedTimesFromCommission, events]) => {
                            return getPointer(filteredMilestones, workedTimesFromCommission, events);
                        }),
                    ),
                };
            }
        ),
    );
    public MilestoneClicked(conf?: MilestoneEntity) {
        this.dialog.open<MilestoneModalComponent, MilestoneModalComponentDialogData>(MilestoneModalComponent, {
            ...MilestoneModalComponent.DefaultConfig,
            data: {
                milestone: conf,
                createMilestoneMutationDefault: { commissionId: this.CommissionId }
            }
        })
        // const formMilestone = new FormGroup({
        //     Name: new FormControl<string>(conf?.Name),
        //     Description: new FormControl<string>(conf?.Description),
        //     Deadline: new FormControl<Moment>(moment(conf?.Deadline)),
        //     State: new FormControl<MilestoneEntityState>(conf?.State),
        // });
        // const ref = this.dialog.open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
        //     data: {
        //         Editing: true,
        //         DeleteButton: !!conf,
        //         DeleteButtonTooltip: 'Meilenstein löschen',
        //         Data: {
        //             Headline: conf ? conf.Name + ' bearbeiten' : 'Meilenstein erstellen',
        //             Properties: [
        //                 { key: 'Name', formControl: formMilestone.controls.Name },
        //                 {
        //                     key: 'Beschreibung',
        //                     formControl: formMilestone.controls.Description,
        //                     options: {
        //                         specialInput: {
        //                             textArea: { Fill: false },
        //                         },
        //                     },
        //                 },
        //                 {
        //                     key: 'Frist',
        //                     formControl: formMilestone.controls.Deadline,
        //                     options: {
        //                         specialInput: { date: true },
        //                     },
        //                 },
        //                 {
        //                     key: 'Status',
        //                     formControl: formMilestone.controls.State,
        //                     options: {
        //                         specialInput: { select: Object.values(MilestoneEntityState).map((val) => ({ optionLabel: MilestoneEntityStateNames.get(val), optionValue: val })) },
        //                     },
        //                 },
        //             ],
        //         },
        //     },
        // });
        // ref.afterClosed().subscribe((data) => {
        //     if (data?.Action === 'save') {
        //         if (conf) {
        //             this.store.dispatch(
        //                 MilestoneActionTypes.Change({
        //                     Payload: {
        //                         id: conf.Id,
        //                         deadline: formMilestone.value.Deadline && FrontendDate(formMilestone.value.Deadline.toDate()),
        //                         description: formMilestone.value.Description,
        //                         name: formMilestone.value.Name,
        //                         state: formMilestone.value.State as any,
        //                     },
        //                 }),
        //             );
        //         } else {
        //             this.store.dispatch(
        //                 MilestoneActionTypes.Create({
        //                     Payload: {
        //                         deadline: formMilestone.value.Deadline && FrontendDate(formMilestone.value.Deadline.toDate()),
        //                         description: formMilestone.value.Description,
        //                         name: formMilestone.value.Name,
        //                         state: formMilestone.value.State as any,
        //                         commissionId: this.CommissionId,
        //                     },
        //                 }),
        //             );
        //         }
        //     } else if (data?.Action === 'delete') {
        //         this.store.dispatch(
        //             MilestoneActionTypes.Delete({
        //                 Payload: {
        //                     id: conf.Id,
        //                 },
        //             }),
        //         );
        //     }
        // });
    }
    public BtgbTable$: Observable<{
        columns: { text?: string; colorDot?: string; subLine?: string; onClick?: () => void }[][];
        rows: { icon: IconProp; theme: string; label: string; onClick: () => void }[];
    }> = this.CommissionId$.pipe(
        switchMap((cId) =>
            combineLatest([
                this.store.select(getConstructionDiaries).pipe(map((diaries) => diaries.filter((d) => d.CommissionId === cId))),
                this.store.select(getWorkedTimes).pipe(map((wt) => wt.filter((w) => w.CommissionId === cId))),
                this.store.select(getWorkDayDictionary),
            ]),
        ),
        map(([diaries, workedTimes, workdays]) => {
            const now = moment();
            const columns: { text?: string; suffixText?: string; tooltip?: string; colorDot?: string; subLine?: string; onClick?: () => void }[][] = [];
            for (let i = 0; i < dayCount; i++) {
                const day = now.clone().add(-i, 'days').toDate();
                const diary = diaries.find((d) => sameDay(day, d.Date));

                const timespan = workedTimes.filter((wt) => sameDay(day, workdays[wt.WorkDayId].Timestamp)).reduce((prev, wt) => prev + wt.Timespan, 0) / 1000 / 60 / 60;
                columns.unshift([
                    { subLine: this.datePipe.transform(day, 'd.M'), text: this.datePipe.transform(day, 'EE') },
                    {
                        colorDot: diary?.getStatusColor() || 'red',
                        tooltip: diary?.getStatusDescription() || 'kein Bautagebuch',
                        onClick: () => this.dialogService.OpenDetailConstructionDiaryPopup(moment(day), this.CommissionId$.value),
                    },
                    {
                        text: formatNumber(timespan, 'de-DE', '1.0-2'),
                        suffixText: ' Std.',
                    },
                ]);
            }
            return {
                columns,
                rows: [
                    {
                        icon: CalendarPageMeta.Icon,
                        theme: CalendarPageMeta.Theme,
                        label: 'Kalender',
                        onClick: () => {},
                    },
                    {
                        icon: ConstructionDiaryIcon,
                        theme: CommissionMeta.Theme,
                        label: 'Bautagebuch',
                        onClick: () => this.dialogService.OpenConstructionDiaryDatepickerDialog(this.CommissionId$.value),
                    },
                    {
                        icon: 'stopwatch',
                        theme: UserAdministrationMeta.Theme,
                        label: 'Zeiten',
                        onClick: () => this.OpenTimeTracker(),
                    },
                ],
            };
        }),
    );
    public TaskLabels = this.taskIndexState.map((t) => t.label).filter((v) => !!v);
    public TaskDataSets$: Observable<ChartDataset<'doughnut', number[]>[]> = combineLatest([this.store.select(getTasks), this.CommissionId$]).pipe(
        map(([tasks, commissionId]) => tasks.filter((t) => t.CommissionId === commissionId).sort((a, b) => a.Id - b.Id)),
        distinctUntilChanged((a, b) => a.length === b.length && a.every((v, i) => v.UpdatedAt.getTime() === b[i].UpdatedAt.getTime())),
        map((tasks) => {
            const notDoneStates = Object.values(taskEventStates).filter((v) => v !== EventStateEnum.Done);
            const now = new Date().getTime();
            const oldTasksCount = tasks.filter((t) => notDoneStates.includes(t.State) && t.EventEndDate && t.EventEndDate.getTime() < now).length;
            const ret: ChartDataset<'doughnut', number[]>[] = tasks.length
                ? [
                      {
                          ...defaultDataset,
                          data: [
                              tasks.filter((t) => t.State === this.taskIndexState[0].state).length,
                              tasks.filter((t) => t.State === this.taskIndexState[1].state).length,
                              tasks.filter((t) => t.State === this.taskIndexState[2].state).length,
                              0,
                              0,
                          ] as any,
                          backgroundColor: this.taskIndexState.map((t) => t.backgroundColor),
                          borderColor: this.taskIndexState.map((t) => t.borderColor),
                      },
                  ]
                : null;
            if (oldTasksCount) {
                ret.push({
                    ...defaultDataset,
                    data: [0, 0, 0, oldTasksCount, tasks.length - oldTasksCount] as any,
                    backgroundColor: this.taskIndexState.map((t) => t.backgroundColor),
                    borderColor: this.taskIndexState.map((t) => t.borderColor),
                    weight: 0.75,
                });
            }
            return ret;
        }),
    );
    public TaskChartOptions: CustomDoughnutChartOptions = {
        responsive: true,
        onHover: function (e, elements) {
            (e.native.target as HTMLCanvasElement).style.cursor = elements[0] ? 'pointer' : 'default';
        },
        onClick: (e, el, chart) => {
            console.log(e)
            const activePoints = chart.getElementsAtEventForMode(
                e as any,
                'nearest',
                {
                    intersect: true,
                },
                false,
            );
            if (activePoints?.length) {
                const [{ index, datasetIndex }] = activePoints;
                this.taskIndexState[index].onClick();
            } else {
                this.taskChart?.OpenMeInPopUp();
            }
        },
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                filter: (e: TooltipItem<'doughnut'>, index: number, array: TooltipItem<'doughnut'>[], data: ChartData) => {
                    return !!e.label;
                },
            },
            legend: {
                display: false,
            },
            htmlLegend: {
                display: true,
                showValue: true,
            },
        },
    };
    public BusinessVolumeLabels = this.businessVolumeIndexState.map((t) => t.label).filter((v) => !!v);
    public BusinessVolumeCostsLabels = this.businessVolumeCostIndexState.map((t) => t.label).filter((v) => !!v);
    private bvsByCommission$ = this.store.select(getBusinessVolumeFetched).pipe(
        distinctUntilChanged(),
        tap((fetched) => {
            if (!fetched) {
                this.businessVolumeResolver.resolve();
            }
        }),
        filter((v) => !!v),
        switchMap(() => this.CommissionId$),
        switchMap((commissionId) => this.store.select(getBusinessVolume).pipe(map((bvs) => bvs.filter((bv) => bv.CommissionId === commissionId)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public Commission$ = this.CommissionId$.pipe(
        switchMap((id) => this.store.select(getCommissionById({ id }))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected bvFilterValues$ = new BehaviorSubject<bvFilter>({
        customNames: [],
    });
    protected bvCostFilterValues$ = new BehaviorSubject<bvFilter>({
        customNames: [],
    });
    protected bvFilterCount$ = this.bvFilterValues$.pipe(map((filter) => (filter?.customNames?.length ? 1 : null)));
    protected bvCostFilterCount$ = this.bvCostFilterValues$.pipe(map((filter) => (filter?.customNames?.length ? 1 : null)));
    protected bvFilterSettings$: Observable<FilterOption<keyof bvFilter>[]> = this.store.select(getBVCustomNameFetched).pipe(
        filter((f) => f),
        switchMap(() => this.store.select(getBVCustomNamesActive)),
        map((customNames) => {
            const ret: FilterOption<keyof bvFilter>[] = [
                {
                    Name: 'customNames',
                    Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                    Label: 'Art',
                    // Icon: ContactBookMeta.Icon,
                    Values: customNames.map((r) => ({
                        label: r.Name,
                        id: r.Id,
                    })),
                },
            ];
            return ret;
        }),
    );
    protected bvCostFilterSettings$ = this.bvFilterSettings$;

    public BusinessVolumeSum$ = this.bvsByCommission$.pipe(
        map(
            (bvs) =>
                bvs
                    .filter((f) => f.BVType === BusinessVolumeCostStateEnum.Income)
                    .reduce((sum, bv) => {
                        if (bv.Status === BusinessVolumeStatusEnum.Booked) {
                            return sum + (bv.Amount || 0);
                        } else if (bv.Status === BusinessVolumeStatusEnum.Open) {
                            return sum + (bv.PayedAmount || 0);
                        }
                        return sum;
                    }, 0) / 100,
        ),
    );
    public BusinessVolumeCostSum$ = this.bvsByCommission$.pipe(
        map(
            (bvs) =>
                bvs
                    .filter((f) => f.BVType === BusinessVolumeCostStateEnum.Costs)
                    .reduce((sum, bv) => {
                        if (bv.Status === BusinessVolumeStatusEnum.Payed) {
                            return sum + (bv.Amount || 0);
                        } else if (bv.Status === BusinessVolumeStatusEnum.Open) {
                            return sum + (bv.PayedAmount || 0);
                        }
                        return sum;
                    }, 0) / 100,
        ),
    );

    public BusinessVolumeDataSets$: Observable<ChartDataset<'doughnut', number[]>[]> = combineLatest([
        this.Commission$,
        this.bvsByCommission$.pipe(
            map((bv) => bv.filter((b) => b.BVType === BusinessVolumeCostStateEnum.Income)),
            distinctUntilChanged((a, b) => a.length === b.length && a.every((entry, i) => entry.Id === b[i].Id && entry.UpdatedAt === b[i].UpdatedAt)),
            switchMap((bvs) => this.bvFilterValues$.pipe(map((filter) => (filter.customNames?.length ? bvs?.filter((bv) => bv.BVCustomNamesIds?.some((id) => filter.customNames.some((cn) => cn.id === id))) : bvs)))),
        ),
    ]).pipe(
        map(([commission, bvs]) => {
            const data1 = bvs.filter((t) => t.Status === this.businessVolumeIndexState[0].state).reduce((sum, bv) => sum + bv.Amount, 0);
            const data2 = bvs.filter((t) => t.Status === this.businessVolumeIndexState[1].state).reduce((sum, bv) => sum + bv.Amount, 0);
            const data3 = bvs.filter((t) => t.Status === this.businessVolumeIndexState[2].state).reduce((sum, bv) => sum + bv.Amount, 0);
            const target = commission.CompleteBusinessVolume || 0;
            const missing = (target - (data1 + data2 + data3)) / 100;
            const toMuch = (data1 + data2 + data3 - target) / 100;
            return [
                {
                    ...defaultDataset,
                    data: [data1 / 100, data2 / 100, data3 / 100, missing < 0 ? 0 : missing, 0, 0] as any,
                    backgroundColor: this.businessVolumeIndexState.map((t) => t.backgroundColor),
                    borderColor: colors.border,
                },
                {
                    ...defaultDataset,
                    data: [0, 0, 0, 0, toMuch > 0 ? toMuch : 0, target / 100] as any,
                    backgroundColor: this.businessVolumeIndexState.map((t) => t.backgroundColor),
                    borderColor: colors.border,
                    weight: 0.75,
                },
            ];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public BusinessVolumeCostDataSets$: Observable<ChartDataset<'doughnut', number[]>[]> = combineLatest([
        this.Commission$,
        this.bvsByCommission$.pipe(
            map((bv) => bv.filter((b) => b.BVType === BusinessVolumeCostStateEnum.Costs)),
            distinctUntilChanged((a, b) => a.length === b.length && a.every((entry, i) => entry.Id === b[i].Id && entry.UpdatedAt === b[i].UpdatedAt)),
            switchMap((bvs) => this.bvCostFilterValues$.pipe(map((filter) => (filter.customNames?.length ? bvs?.filter((bv) => bv.BVCustomNamesIds?.some((id) => filter.customNames.some((cn) => cn.id === id))) : bvs)))),
        ),
    ]).pipe(
        map(([commission, bvs]) => {
            const data1 = bvs.filter((t) => t.Status === this.businessVolumeCostIndexState[0].state).reduce((sum, bv) => sum + bv.Amount, 0);
            const data2 = bvs.filter((t) => t.Status === this.businessVolumeCostIndexState[1].state).reduce((sum, bv) => sum + bv.Amount, 0);
            const target = commission.CostsCompleteBusinessVolume || 0;
            const missing = (target - (data1 + data2)) / 100;
            const toMuch = missing * -1;

            return [
                {
                    ...defaultDataset,
                    data: [data1 / 100, data2 / 100, 0, missing < 0 ? 0 : missing] as any,
                    backgroundColor: this.businessVolumeCostIndexState.map((t) => t.backgroundColor),
                    borderColor: colors.border,
                },
                {
                    ...defaultDataset,
                    data: [0, 0, toMuch > 0 ? toMuch : 0, target / 100 - toMuch > 0 ? target / 100 - toMuch : 0] as any,
                    backgroundColor: this.businessVolumeCostIndexState.map((t) => t.backgroundColor),
                    borderColor: this.businessVolumeCostIndexState.map((t) => t.borderColor),
                    weight: 0.75,
                },
            ];
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    private businessVolumeChartOptions = (type: BusinessVolumeCostStateEnum) => ({
        onHover: (e, elements) => {
            (e.native.target as HTMLCanvasElement).style.cursor =
                elements[0] && (type === BusinessVolumeCostStateEnum.Income ? this.businessVolumeIndexState : this.businessVolumeCostIndexState)[elements[0].index].onClick ? 'pointer' : 'default';
        },
        onClick: (e, el, chart) => {
            console.log({ e, el, chart });
            const activePoints = chart.getElementsAtEventForMode(
                e as any,
                'nearest',
                {
                    intersect: true,
                },
                false,
            );
            if (activePoints?.length) {
                const [{ index, datasetIndex }] = activePoints;
                if ((type === BusinessVolumeCostStateEnum.Income ? this.businessVolumeIndexState : this.businessVolumeCostIndexState)[index].onClick) {
                    (type === BusinessVolumeCostStateEnum.Income ? this.businessVolumeIndexState : this.businessVolumeCostIndexState)[index].onClick(type);
                }
            } else {
                if (type === BusinessVolumeCostStateEnum.Income) {
                    this.bvChart?.OpenMeInPopUp();
                } else if (type === BusinessVolumeCostStateEnum.Costs) {
                    this.bvCostChart?.OpenMeInPopUp();
                }
            }
        },
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                filter: (e: TooltipItem<'doughnut'>, index: number, array: TooltipItem<'doughnut'>[], data: ChartData) => {
                    return !!e.label;
                },
                callbacks: {
                    afterLabel: (ctx) => '',
                },
            },
            legend: {
                display: false,
            },
            htmlLegend: {
                display: true,
                showValue: true,
                callbacks: {
                    value: (v) => formatCurrency(v, 'de-DE', '€'),
                },
            },
        },
    });
    public BusinessVolumeChartOptions: CustomDoughnutChartOptions = this.businessVolumeChartOptions(BusinessVolumeCostStateEnum.Income);
    public BusinessVolumeCostChartOptions: CustomDoughnutChartOptions = this.businessVolumeChartOptions(BusinessVolumeCostStateEnum.Costs);

    public TimeChartOptions$: Observable<CustomDoughnutChartOptions> = this.timeIndexState$.pipe(
        map((tis) => ({
            responsive: true,
            onClick: (e, el, chart) => {
                const activePoints = chart.getElementsAtEventForMode(
                    e as any,
                    'nearest',
                    {
                        intersect: true,
                    },
                    false,
                );
                if (activePoints?.length) {
                } else {
                    this.timeChart?.OpenMeInPopUp();
                }
            },
            plugins: {
                title: {
                    display: false,
                },
                tooltip: {
                    filter: (e: TooltipItem<'doughnut'>, index: number, array: TooltipItem<'doughnut'>[], data: ChartData) => {
                        return !!e.label;
                    },
                    callbacks: {
                        afterLabel: (ctx) => ' Std.',
                    },
                },
                legend: {
                    display: false,
                },
                htmlLegend: {
                    display: true,
                    showValue: true,
                    callbacks: {
                        afterValue: (ctx: LegendItem) => {
                            return ' Std.';
                        },
                        value: (v) => formatNumber(v, 'de-DE', '1.0-2'),
                    },
                },
            },
        })),
    );

    public TimeLabels$ = this.timeIndexState$.pipe(map((tis) => tis.map((t) => t.label).filter((v) => !!v)));
    public TargetTime$ = this.Commission$.pipe(
        switchMap((commission) =>
            commission.AdditionalData?.planedWorkingHours != null
                ? of(commission.AdditionalData.planedWorkingHours)
                : this.store.select(getLedgerImports).pipe(
                      filter(isNotNullOrUndefined),
                      map((lis) =>
                          lis
                              .filter((l) => l.CommissionId === commission.Id && l.DocumentType === LedgerImportDocumentTypes.Offer && l.Status === LedgerImportStatusEnum.AngebotAkzeptiert)
                              .map((l) => l.AccountsReceivableLedgerIds)
                              .flat(),
                      ),
                      switchMap((arlIds) => combineLatest(arlIds.map((id) => this.store.select(getAccountsReceivableLedgerById({ id })).pipe(filter(isNotNullOrUndefined))))),
                      switchMap((arls) =>
                          this.store.select(getQuantityTypes).pipe(
                              filter(isNotNullOrUndefined),
                              map((quantityTypes) => {
                                  const hourType = quantityTypes.find((q) => q.Name === QuantityTypeNames.Hours);
                                  return arls.filter((a) => a.QuantityTypeId === hourType?.Id).reduce((sum, v) => sum + v.Quantity, 0) * 1000 * 60 * 60;
                              }),
                          ),
                      ),
                  ),
        ),
        tap((v) => console.log('target,', v)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public TimeSum$ = new BehaviorSubject<number>(null);
    public TimeDataSets$: Observable<ChartDataset<'doughnut', number[]>[]> = this.Commission$.pipe(
        switchMap((commission) =>
            combineLatest([
                this.store.select(getWorkedTimesFetched).pipe(
                    tap((fetched) => {
                        if (!fetched) {
                            this.workedTimesResolver.resolve();
                        }
                    }),
                    filter((fetched) => !!fetched),
                    switchMap(() => this.store.select(getWorkedTimes)),
                    map((wt) => wt.filter((w) => w.CommissionId === commission.Id)),
                ),
                this.TargetTime$,
                this.clockInTypes$,
                this.timeIndexState$,
            ]).pipe(
                map(([workedTimes, targetTime, clockInTypes, timeIndexState]) => {
                    const workedTimesByClockInType = clockInTypes
                        .filter((t) => t.Slug !== EnumClockInTypeSlug.workTime && t.Slug !== EnumClockInTypeSlug.commissionWorkTime )
                        .map((ct) => workedTimes.filter((wt) => wt.ClockInTypeId === ct.Id).reduce((sum, v) => sum + v.Timespan, 0));
                    workedTimesByClockInType.unshift(
                        clockInTypes
                            .filter((t) => t.Slug === EnumClockInTypeSlug.workTime || t.Slug === EnumClockInTypeSlug.commissionWorkTime)
                            .map((ct) => workedTimes.filter((wt) => wt.ClockInTypeId === ct.Id).reduce((sum, v) => sum + v.Timespan, 0))
                            .reduce((sum, v) => sum + v, 0),
                    );
                    const sum = workedTimesByClockInType.reduce((sum, v) => sum + v, 0);
                    this.TimeSum$.next(sum);
                    const open = targetTime - sum;
                    const ret: ChartDataset<'doughnut', number[]>[] = [
                        {
                            ...defaultDataset,
                            data: [...workedTimesByClockInType.map((ms) => ms), open < 0 ? 0 : open, 0, 0].map((v) => v / 1000 / 60 / 60) as any,
                            backgroundColor: timeIndexState.map((t) => t.backgroundColor),
                            borderColor: timeIndexState.map((t) => t.borderColor),
                        },
                    ];
                    if (open < 0) {
                        ret.push({
                            ...defaultDataset,
                            data: [...workedTimesByClockInType.map(() => 0), 0, open * -1, sum + open].map((v) => v / 1000 / 60 / 60) as any,
                            backgroundColor: timeIndexState.map((t) => t.backgroundColor),
                            borderColor: timeIndexState.map((t) => t.borderColor),
                            weight: 0.75,
                        });
                    }
                    return ret;
                }),
            ),
        ),
    );
    public Mobile$ = this.bs.MobileQuery;
    constructor(
        private store: Store<State>,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        public dialogService: AppDialogService,
        private businessVolumeResolver: BusinessVolumeResolver,
        private clockInTypeResolver: ClockInTypeResolver,
        private workedTimesResolver: WorkedTimesResolver,
        private bs: BreakpointObserverService,
        private mstr: MilestoneResolver,
        bVCNRes: BVCustomNameResolver,
        @Inject(LOCALE_ID) private locale: string,
        protected ps: PermissionService,
    ) {
        firstValueFrom(this.store.select(getBVCustomNameFetched)).then(f =>{
            if (!f) {
                bVCNRes.resolve();
            }
        })
    }
    private openTaskPopup(state?: EventStateEnum, onlyLate?: boolean) {
        this.dialog.open<TaskBoardPopupComponent, TaskBoardPopupComponentDialogData>(TaskBoardPopupComponent, {
            ...TaskBoardPopupComponent.DefaultConfig,
            data: {
                filter: {
                    CommissionIds: [this.CommissionId],
                    State: state ? [state] : onlyLate ? Object.values(taskEventStates).filter((v) => v !== EventStateEnum.Done) : null,
                    MaxEventEndDate: onlyLate ? new Date() : null,
                },
            },
        });
    }
    public OpenTimeTracker() {
        this.dialog.open<HourListComponent, HourListComponentDialogData>(HourListComponent, {
            ...HourListComponent.DefaultConfig,
            data: {
                CommissionId: this.CommissionId$.value,
            },
        });
    }
    OnTaskIconClick(event) {
        event.stopPropagation();
        this.dialog.open<TaskBoardPopupComponent, TaskBoardPopupComponentDialogData>(TaskBoardPopupComponent, {
            ...TaskBoardPopupComponent.DefaultConfig,
            data: {
                filter: {
                    CommissionIds: [this.CommissionId],
                },
                showSortButton: true,
            },
        });
    }

    protected openBVPopup(status: BusinessVolumeStatusEnum, type: BusinessVolumeCostStateEnum) {
        this.BVListDialogRef = this.dialog.open<CommissionBusinesVolumeListComponent, CommissionBusinesVolumeListComponentDialogData>(CommissionBusinesVolumeListComponent, {
            ...CommissionBusinesVolumeListComponent.DefaultConfig,
            data: {
                commissionId: this.CommissionId,
                defaultFilter: {
                    type,
                    status: status ? [{ id: status, label: BusinessVolumeStatusEnumNameMap.get(status) }] : [],
                },
            },
        });
    }
    public OpenChartPopUp(chart: DoughnutChartWrapperComponent) {
        console.log({ chart });
        const dialogRef = this.dialog.open<DoughnutChartWrapperComponent, DoughnutChartWrapperComponentDialogData>(DoughnutChartWrapperComponent, {
            ...DoughnutChartWrapperComponent.DefaultConfig,
            data: {
                Datasets: chart.Datasets,
                CenterTemplate: chart.CenterTemplate,
                Labels: chart.Labels,
                Options: chart.Options,
                Headline: chart.Headline,
                Subline: chart.Subline,
            },
        });
    }
    OpenFilePreview(fileId: number) {
        if (this.filePreviewDialog?.getState() === MatDialogState.OPEN) {
            this.filePreviewDialog.close();
        }
        this.filePreviewDialog = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }

    protected readonly BusinessVolumeCostStateEnum = BusinessVolumeCostStateEnum;
}
