import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
    LedgerImportDocumentTypeNames,
    LedgerImportDocumentTypes,
} from '../../../dave-data-module/entities/ledger-import.entity';
import { AccountsReceivableLedgerResolver } from '../../../dave-data-module/guards/accounts-receivable-ledger.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { LedgerImportResolver } from '../../../dave-data-module/guards/ledger-import.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { getAccountsReceivableLedgersFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import {
    getCustomerById,
    getCustomersFetched,
    getNotDeletedCustomers,
} from '../../../dave-data-module/State/selectors/customers.selectors';
import { getLedgerImportsFetched } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { DaveListCardComponent } from '../../../dave-list-card/dave-list-card.component';
import { DaveListCardModule } from '../../../dave-list-card/dave-list-card.module';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { AppFilterModule } from '../../../dave-utils-module/app-filter-module/app-filter.module';
import {
    FILTER_TYPE_SEARCH_MULTI_SELECT,
    FilterOption,
    IFilterTypeSearchMultiSelectValue,
} from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { CustomLabelService } from '../../../services/custom-label.service';
import { FilterTypes } from '../../../services/default-filter.service';
import {
    MaterialLedgerImportListCardDataSource,
    MaterialLedgerImportListCardItem,
} from './material-ledger-import-list-card-datasource';
import { isNotNullOrUndefined } from '../../../helper/helper';

@Component({
    selector: 'app-material-ledger-import-list-card[materialId][ledgerImportDocumentType]',
    templateUrl: './material-ledger-import-list-card.component.html',
    styleUrls: ['./material-ledger-import-list-card.component.scss'],
    standalone: true,
    imports: [CommonModule, DaveListCardModule, AppButtonModule, FontAwesomeModule, MatTooltipModule, AppFilterModule, MatBadgeModule],
})
export class MaterialLedgerImportListCardComponent implements AfterViewInit, OnInit {
    @Input() materialId: number;
    @Input() supplierId: number;
    @Input() title = 'Bestellhistorie';
    private ledgerImportDocumentType$ = new BehaviorSubject<LedgerImportDocumentTypes>(null);
    @Input() set ledgerImportDocumentType(ledgerImportDocumentType: LedgerImportDocumentTypes) {
        if (this.ledgerImportDocumentType$.value !== ledgerImportDocumentType) {
            this.ledgerImportDocumentType$.next(ledgerImportDocumentType);
        }
    }
    get ledgerImportDocumentType() {
        return this.ledgerImportDocumentType$.value;
    }

    @ViewChild(DaveListCardComponent) listCard: DaveListCardComponent<MaterialLedgerImportListCardItem>;
    dataSource: MaterialLedgerImportListCardDataSource;
    loading$: Observable<boolean>;
    tableDataSource$: Observable<MatTableDataSource<MaterialLedgerImportListCardItem>>;
    displayedColumns = ['supplier', 'name', 'date', 'costs', 'quantity'];
    tableHeaderColumnsMap$ = combineLatest([this.ledgerImportDocumentType$.pipe(filter(isNotNullOrUndefined)), this.cls.getSingle$('Costs'), this.cls.getSingle$('Customer')]).pipe(
        map(([docType, costsLabel, customerLabel]) => {
            const isOrder = docType === LedgerImportDocumentTypes.Order;
            return {
                costs: isOrder ? costsLabel : 'Verkaufspreis',
                supplier: isOrder ? 'Lieferant' : customerLabel,
                name: LedgerImportDocumentTypeNames.get(docType),
                date: 'Datum',
                quantity: 'Menge',
            };
        }),
    );
    // FilterValues$ = new BehaviorSubject<{  }>({ [FilterTypes.Customers]: [] });
    FilterSettings$: Observable<FilterOption[]>;
    FilterAmount$: Observable<number>;

    constructor(@Inject(LOCALE_ID) private locale: string, protected cls: CustomLabelService, private store: Store<State>, ledgerImportRes: LedgerImportResolver, arlRes: AccountsReceivableLedgerResolver, customerRes: CustomerResolver) {
        this.loading$ = combineLatest([store.select(getLedgerImportsFetched), store.select(getAccountsReceivableLedgersFetched), store.select(getCustomersFetched)]).pipe(map((fetched) => fetched.some((f) => !f)));
        firstValueFrom(store.select(getLedgerImportsFetched)).then((f) => {
            if (!f) {
                ledgerImportRes.resolve();
            }
        });
        firstValueFrom(store.select(getAccountsReceivableLedgersFetched)).then((f) => {
            if (!f) {
                arlRes.resolve();
            }
        });
        firstValueFrom(store.select(getCustomersFetched)).then((f) => {
            if (!f) {
                customerRes.resolve();
            }
        });
    }

    ngAfterViewInit(): void {
        this.tableDataSource$ = this.dataSource.connect().pipe(
            map((elements) => {
                const d = new MatTableDataSource(elements);
                d.sortingDataAccessor = (object, key) => {
                    switch (key) {
                        case 'supplier':
                            return object.supplier;
                        case 'name':
                            return object.name;
                        case 'costs':
                            return object.costs_sort;
                        case 'date':
                            return object.date_sort;
                        case 'quantity':
                            return object.quantity_sort;
                        default:
                            return object[key];
                    }
                };
                return d;
            }),
        );
    }

    ngOnInit(): void {
        this.dataSource = new MaterialLedgerImportListCardDataSource(this.materialId, this.ledgerImportDocumentType, this.store, this.locale);
        this.dataSource.filter$ = new BehaviorSubject({ [FilterTypes.Customers]: this.supplierId ? [{ id: this.supplierId, label: '' }] : [] });
        if (this.supplierId) {
            firstValueFrom(getFetched$(this.store, getCustomersFetched, getCustomerById({ id: this.supplierId }))).then((customer) => {
                this.dataSource.filter$.next({ ...this.dataSource.filter$.value, [FilterTypes.Customers]: [...this.dataSource.filter$.value[FilterTypes.Customers].filter(c => c.id !== customer.Id), {id: this.supplierId, label: customer.Name}] });
            });
        }
        this.FilterAmount$ = this.dataSource.filter$.pipe(map((filterValues) => (filterValues[FilterTypes.Customers]?.length ? 1 : 0)));
        this.FilterSettings$ = combineLatest([getFetched$(this.store, getCustomersFetched, getNotDeletedCustomers), this.dataSource.availableCustomersIds$]).pipe(
            map(([customers, customerIds]) => {
                return [
                    {
                        Name: FilterTypes.Customers,
                        Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                        Label: this.ledgerImportDocumentType === LedgerImportDocumentTypes.Order ? 'Lieferant' : 'Kunde',
                        Icon: null,
                        Values: customers
                            .filter((c) => /*c.IsSupplier === (this.ledgerImportDocumentType === LedgerImportDocumentTypes.Order) &&*/ customerIds.includes(c.Id))
                            .map<IFilterTypeSearchMultiSelectValue>((t) => ({ id: t.Id, label: t.Name })),
                    },
                ];
            }),
        );
    }
}
