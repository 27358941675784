<div class="editor-dz-wrapper">
    <app-ck-editor [CKEditorConfig]="CKEditorConfig" [(Content)]="InlineEditorContent"></app-ck-editor>

    <!--            <ckeditor [(ngModel)]="InlineEditorContent" [config]="CKEditorConfig" [editor]="InlineEditor"></ckeditor>-->
    <ng-container [ngTemplateOutlet]="dropzoneTemplate" [ngTemplateOutletContext]="{ canUpload: !!FileUploadParams || GetFileUploadParamsBeforeSaveComment }"></ng-container>
</div>
<button app-round-button [Disabled]="!InlineEditorContent && (!dropzone || !(fileCount$ | async))" [IsLoading]="LS.IsLoading$ | async" matTooltip="Kommentar hinzufügen" aria-label="Kommentar hinzufügen" (click)="SaveComment()"><fa-icon icon="paper-plane"></fa-icon></button>
<ng-template #dropzoneTemplate let-canUpload="canUpload">
    <ng-container *ngIf="canUpload">
        <div
            *ngIf="dropzoneConfig$ | async as config"
            class="dropzone dz-wrapper"
            [class.hidden]="HideDropzone"
            #dropzone="ngxDropzone"
            [dropzone]="config"
            (error)="dropzoneError($event)"
            (success)="dropzoneSuccess($event)"
            (addedFile)="calcFileCount()"
            (addedFiles)="calcFileCount()"
            (removedFile)="calcFileCount()"
            (uploadProgress)="dropzoneUploadProcess($event)"
        >
            <div class="dz-message">
                <div class="dz-text">{{ config.dictDefaultMessage }}</div>
            </div>
        </div>
    </ng-container>
</ng-template>
